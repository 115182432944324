/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
export interface DetailPageHeaderProps {
  children: React.ReactNode
}

export const DetailPageHeader: React.FC<DetailPageHeaderProps> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '10rem',
        flexShrink: 0,
      }}
    >
      {children}
    </Box>
  )
}
