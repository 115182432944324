import { useNotifications, AllMyStars, GetProfileDetailQuery } from '@riverscapes/react-common'
import { useFetchAllWithPendingItems } from '../data'

import {
  useGetProfileStarsCollectionsQuery,
  useGetProfileStarsOrganizationsQuery,
  useGetProfileStarsProjectsQuery,
  useGetProfileStarsUsersQuery,
} from '@riverscapes/react-common/dist/schema/operations'

export interface UseAllMyStars {
  profile: GetProfileDetailQuery['profile']
  skip?: boolean
}

export const useAllMyStars = ({ profile, skip }: UseAllMyStars): AllMyStars => {
  const { catchError } = useNotifications()

  const {
    data: profileStarsProjectsData,
    loading: profileStarsProjectsLoading,
    fetchMore: fetchMoreProfileStarsProjects,
  } = useGetProfileStarsProjectsQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get bookmarked projects', false),
    skip: !profile || skip,
  })
  const {
    data: profileStarsCollectionsData,
    loading: profileStarsCollectionsLoading,
    fetchMore: fetchMoreProfileStarsCollections,
  } = useGetProfileStarsCollectionsQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get bookmarked projects', false),
    skip: !profile || skip,
  })
  const {
    data: profileStarsOrganizationsData,
    loading: profileStarsOrganizationsLoading,
    fetchMore: fetchMoreProfileStarsOrganizations,
  } = useGetProfileStarsOrganizationsQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get bookmarked projects', false),
    skip: !profile || skip,
  })
  const {
    data: profileStarsUsersData,
    loading: profileStarsUsersLoading,
    fetchMore: fetchMoreProfileStarsUsers,
  } = useGetProfileStarsUsersQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get bookmarked projects', false),
    skip: !profile || skip,
  })

  const profileStarsProjects = useFetchAllWithPendingItems(
    fetchMoreProfileStarsProjects,
    profileStarsProjectsData?.profile?.stars.projects.items,
    profile?.stars.projects.total,
    0
  )
  const profileStarsCollections = useFetchAllWithPendingItems(
    fetchMoreProfileStarsCollections,
    profileStarsCollectionsData?.profile?.stars.collections.items,
    profile?.stars.collections.total,
    0
  )
  const profileStarsOrganizations = useFetchAllWithPendingItems(
    fetchMoreProfileStarsOrganizations,
    profileStarsOrganizationsData?.profile?.stars.organizations.items,
    profile?.stars.organizations.total,
    0
  )
  const profileStarsUsers = useFetchAllWithPendingItems(
    fetchMoreProfileStarsUsers,
    profileStarsUsersData?.profile?.stars.users.items,
    profile?.stars.users.total,
    0
  )

  return {
    projects: profileStarsProjects,
    collections: profileStarsCollections,
    organizations: profileStarsOrganizations,
    users: profileStarsUsers,
    loading:
      profileStarsProjectsLoading ||
      profileStarsCollectionsLoading ||
      profileStarsOrganizationsLoading ||
      profileStarsUsersLoading,
  }
}
