/** @jsxImportSource @emotion/react */
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { someValues, useFields, useNotifications, validate } from '@riverscapes/react-common'
import { useUpdateProfileNameMutation } from '../data'
import { useProfile } from '../lib'
import { useAuth } from '@riverscapes/react-api'

export const InitializeUser: React.FC = () => {
  const profile = useProfile()
  const api = useAuth()
  const { catchError } = useNotifications()

  const [fields, setFields, preparedFields] = useFields({ name: '' }, ({ name }) => ({ name: name.trim() }))

  const errors = someValues({
    name: validate.name(preparedFields.name),
  })

  const [updateProfileName, { loading }] = useUpdateProfileNameMutation()
  const handleNameChange = (newName: string) =>
    updateProfileName({ variables: { name: newName } }).catch(catchError('Failed to update profile name', false))

  const handleSave = () => {
    handleNameChange(preparedFields.name)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!errors && e.key === 'Enter') handleSave()
  }

  if (!api.isAuthenticated) return null

  const isUnintialized = !!profile && !profile.initialized

  return (
    <Backdrop open={isUnintialized}>
      {/* {!profile && <CircularProgress />} */}
      <Dialog open={isUnintialized} maxWidth="sm">
        <DialogTitle>Welcome to Riverscapes!</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Typography>
              Please enter your full name to continue. It will be visible to other users, and you may change it later in
              your Profile.
            </Typography>
            <TextField
              placeholder="Full Name"
              autoFocus
              value={fields.name}
              onChange={(e) => setFields.$.name(e.target.value)}
              error={errors?.name}
              onKeyDown={handleKeyDown}
              fullWidth
              disabled={loading}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} disabled={!!errors || loading}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  )
}
