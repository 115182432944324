import { useMemo } from 'react'
import { GetProjectQaqcQuery, QaqcStateEnum } from '../../schema/base'
import { Box, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { PendingItem, PendingCellRenderer } from '../PendingItem'
import { QaFailedIcon, QaPassedIcon } from '../Icons'
import { useFormats } from '../../lib'
import { useTheme } from '@mui/system'

type QaqcItem = Extract<
  NonNullable<GetProjectQaqcQuery['project']>['qaqc']['items'][number],
  { __typename: 'QAQCEvent' }
>

type Item = QaqcItem | PendingItem

export type QaqcProps = {
  items: Item[]
}

const StateRenderer = ({ value }: GridRenderCellParams<QaqcItem['state'] | undefined, Item>) => {
  switch (value) {
    case QaqcStateEnum.Passed: {
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <QaPassedIcon /> Passed
        </Stack>
      )
    }
    case QaqcStateEnum.Provisional: {
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <QaPassedIcon /> Provisional
        </Stack>
      )
    }
    case QaqcStateEnum.Failed: {
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <QaFailedIcon /> Failed
        </Stack>
      )
    }
  }
}

export const Qaqc = ({ items }: QaqcProps): React.ReactElement => {
  const { formatDate } = useFormats()
  const theme = useTheme()

  const colDefs = useMemo<GridColDef<Item>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: PendingCellRenderer,
      },
      {
        field: 'summary',
        headerName: 'Summary',
        flex: 4,
      },
      {
        field: 'datePerformed',
        headerName: 'Date',
        width: 150,
        valueFormatter: (cell) => cell.value && formatDate(cell.value, true),
      },
      {
        field: 'performedBy',
        headerName: 'Performed By',
        flex: 1,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 150,
        renderCell: StateRenderer,
      },
    ],
    [formatDate]
  )

  return (
    <Box sx={{ height: '100%' }}>
      <DataGrid
        rows={items}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        }}
        columns={colDefs}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => <Typography sx={{ fontStyle: 'italic', m: 2 }}>No QA/QC events.</Typography>,
        }}
      />
    </Box>
  )
}
