import React from 'react'
import {
  useTheme,
  useMediaQuery,
  Stack,
  Typography,
  SxProps,
  Theme,
  Button,
  CardContent,
  Card,
  Link,
} from '@mui/material'

import { useFormats, validate } from '../../lib'
import { GetProjectTypeDetailQuery } from '../../schema/base'
import { EditableTypography } from '../EditableTypography'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { OverviewCard } from '../Overview/OverviewCard'
import { OverviewBasicProperties } from '../Overview/OverviewBasicProperties'
import { Link as LinkIcon, OpenInNew } from '@mui/icons-material'

export interface OverviewPanelProps {
  projectType: NonNullable<GetProjectTypeDetailQuery['projectType']>
  numProjects: number
  onGotoSearch: () => void
}

export const OverviewPanel: React.FC<OverviewPanelProps> = ({ projectType, numProjects, onGotoSearch }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { formatDateTime, formatNumber } = useFormats()

  const scrollContainer: SxProps<Theme> = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
        // NO TABS SO ADD A LITTLE MORE SPACE
        pt: 3,
      }
    : {}
  const scrollObjInner: SxProps<Theme> = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
      }
    : {}

  return (
    <Grid2 container spacing={3} sx={scrollContainer}>
      <Grid2 xs={12} sm={12} md={8} sx={scrollObjInner}>
        <OverviewCard title="Basic Properties">
          <OverviewBasicProperties
            propTable={[
              { label: 'Full Name', value: projectType.summary },
              { label: 'Short Name', value: projectType.name },
              {
                label: 'Machine Name',
                value: (
                  <code>
                    <pre>{projectType.machineName}</pre>
                  </code>
                ),
              },
              {
                label: 'Website',
                value: projectType.url ? (
                  <Button
                    startIcon={<LinkIcon />}
                    endIcon={<OpenInNew />}
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    href={projectType.url}
                    target="_blank"
                  >
                    {projectType.url}
                  </Button>
                ) : (
                  <Typography variant="caption">No website.</Typography>
                ),
              },
              {
                label: 'Created',
                value: <Typography variant="caption">{formatDateTime(projectType.createdOn)}</Typography>,
              },
            ]}
          />
        </OverviewCard>
        <OverviewCard title="Description">
          <EditableTypography
            canEdit={false}
            content={projectType.description}
            getError={validate.description}
            multiline
            name="Description"
            // onChange={onDescriptionChange}
            placeholder={'No description.'}
            variant="markdown"
          />
        </OverviewCard>
      </Grid2>
      <Grid2 xs={12} sm={12} md={4}>
        <Card>
          <CardContent sx={{ pt: 3 }}>
            <Stack
              sx={{ minWidth: 70, height: 50, flexShrink: 0 }}
              alignItems="center"
              justifyContent="space-around"
              direction={'row'}
            >
              <Link onClick={onGotoSearch} sx={{ cursor: 'pointer' }}>
                <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
                  <Typography variant="overline">Projects</Typography>
                  <Typography variant="h3">{formatNumber(numProjects)}</Typography>
                  {/* <Typography variant="overline">{pluralize('project', collection.projects.total)}</Typography> */}
                </Stack>
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  )
}
