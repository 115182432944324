import { times } from 'lodash'

export type PendingItem = {
  __typename: 'PendingItem'
  id: string
  name: undefined // match shape of other items, even though is always undefined
}

export const pendingItems = (qty: number): PendingItem[] =>
  times(qty).map((i) => ({
    __typename: 'PendingItem',
    id: `PendingItem_${i}`,
    name: undefined,
  }))
