import { Box, SxProps, Theme, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import SplitPane, { Size } from 'react-split-pane'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    height: '100%',
    position: 'relative',
    '& .webrave-splitter-resizer': {
      background: '#000',
      opacity: 0.2,
      zIndex: 1,
      boxSizing: 'border-box',
      backgroundClip: 'padding-box',
      '&:hover': {
        transition: 'all 2s ease',
      },
      '&.horizontal': {
        height: 11,
        margin: '-5px 0',
        borderTop: '5px solid rgba(255, 255, 255, 0)',
        borderBottom: '5px solid rgba(255, 255, 255, 0)',
        cursor: 'row-resize',
        width: '100%',
        '&:hover': {
          borderTop: '5px solid rgba(0, 0, 0, 0.5)',
          borderBottom: '5px solid rgba(0, 0, 0, 0.5)',
        },
      },
      '&.vertical': {
        width: 11,
        margin: '0 -5px',
        borderLeft: '5px solid rgba(255, 255, 255, 0)',
        borderRight: '5px solid rgba(255, 255, 255, 0)',
        cursor: 'col-resize',
        height: '100%',
        '&:hover': {
          borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
          borderRight: '5px solid rgba(0, 0, 0, 0.5)',
        },
      },
      '&.disabled': {
        cursor: 'not-allowed',
        '&:hover': {
          borderColor: 'transparent',
        },
      },
    },
  },
})

export interface AppSplitterProps {
  open: boolean
  child1: React.ReactNode
  child2: React.ReactNode
  split?: 'horizontal' | 'vertical'
  primary?: 'first' | 'second'
  defaultSize?: Size
  minSize?: Size
  maxSize?: Size
}

const AppSplitter: React.FC<AppSplitterProps> = ({
  open,
  child1,
  child2,
  split,
  primary,
  defaultSize,
  minSize,
  maxSize,
}: AppSplitterProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const child1Memoized = useMemo(() => child1, [child1])
  const child2Memoized = useMemo(() => child2, [child2])
  if (!open) return <Box sx={styles.root}>{!primary || primary === 'first' ? child1Memoized : child2Memoized}</Box>
  else {
    return (
      <>
        <Box sx={styles.root}>
          {/* this lib is incompatible with react18. To fix     // children: React.ReactNode; needs to be added to SplitPaneProps.
        https://github.com/tomkp/react-split-pane/issues/826
     // @ts-ignore TS2322 */}
          <SplitPane
            className="webrave-splitter"
            resizerClassName="webrave-splitter-resizer"
            split={split}
            primary={primary}
            defaultSize={defaultSize}
            minSize={minSize}
            maxSize={maxSize}
            pane1Style={{ overflow: 'hidden' }}
            pane2Style={{ overflow: 'hidden' }}
          >
            {child1Memoized}
            {child2Memoized}
          </SplitPane>
        </Box>
      </>
    )
  }
}

export default AppSplitter
