/** @jsxImportSource @emotion/react */
import { ButtonProps, IconButton } from '@mui/material'
import { ShareDialog } from '../Share'
import { useBooleanState, withTooltip } from '../../lib'
import { ShareIcon } from '../Icons'

export interface ShareButtonProps extends ButtonProps {
  className?: string
  url: string
  title: string
}

export const ShareButton: React.FC<ShareButtonProps> = ({ title, url, className, ...restProps }) => {
  const [isOpen, openDialog, closeDialog] = useBooleanState()

  return (
    <>
      {withTooltip(
        <IconButton {...restProps} onClick={openDialog} color="primary" size="large">
          <ShareIcon className={className} />
        </IconButton>,
        `Share ${title}`
      )}
      <ShareDialog title={title} open={isOpen} onClose={closeDialog} url={url} />
    </>
  )
}
