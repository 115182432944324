import { ApolloLink, from, Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import log from 'loglevel'

const makeLogLink = (logFn: (...args: any[]) => void): ApolloLink => {
  const logOperation = (operation: Operation, response: any, errors: any) => {
    const queryBody = operation.query.loc?.source?.body
    const queryMatch = queryBody?.match(/(query|mutation) (\w+).+/)
    if (queryMatch) {
      const [_wholeMatch, type, name] = queryMatch
      const logObject: any = {
        query: queryBody?.trim(),
        variables: operation.variables,
        context: operation.getContext(),
      }
      if (response) logObject.response = response
      if (errors) logObject.errors = errors

      logObject.summary = `

Operation:
\`\`\`gql
${logObject.query}
\`\`\`
\`\`\`json
${JSON.stringify(operation.variables, undefined, 2)}
\`\`\`
Returns:
\`\`\`json
${JSON.stringify(response, undefined, 2)}
\`\`\`

`.trim()
      if (response || errors)
        logFn(
          `%c${type} ${name}%c %c${errors ? 'ERROR' : ''}`,
          type === 'mutation' ? 'background-color: #ff8c00; color: white' : 'background-color: grey; color: white',
          'background-color: unset, color: unset',
          'background-color: red; color: white',
          logObject
        )
    }
  }

  return from([
    new ApolloLink((operation, forward) => {
      return forward(operation).map((data) => {
        logOperation(operation, data, data.errors)
        return data
      })
    }),
    onError(({ operation, networkError }) => {
      logOperation(operation, undefined, networkError)
    }),
  ])
}

export const logLink = makeLogLink(log.debug)
