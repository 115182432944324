import {
  AutocompleteSelectOption,
  Lookups,
  itemToSelectOption,
  MissingDataError,
  useDeconstructedPromise,
  isCollection,
  isProject,
  isUser,
} from '@riverscapes/react-common'
import {
  useGetCollectionByIdLazyQuery,
  useGetCollectionsByNameLazyQuery,
  useGetProjectTypesLazyQuery,
  useGetProjectsByNameLazyQuery,
  useGetUserByIdLazyQuery,
  useGetUsersByNameLazyQuery,
  useGetImageUploadStatusLazyQuery,
  useGetOrganizationsByNameLazyQuery,
  useGetOrganizationByIdLazyQuery,
} from '../data'
import { useCallback, useEffect } from 'react'
import { extractItem } from './extractors'
import { useActivity } from '../lib'
import {
  collectionDetail,
  organizationDetail,
  profileDetail,
  projectDetail,
  projectTypeDetail,
  savedSearchDetail,
  userDetail,
} from '../routing'
import { useAuth } from '@riverscapes/react-api'

const ACTIVITIES_LIMIT = 8 // number of locally-stored activities to return

export const useLookups = (): Lookups => {
  const { activities } = useActivity()
  const { isAuthenticated } = useAuth()

  // urls

  const getProjectUrlById = (id: string, tab?: string) => projectDetail.getUrl({ id, tab })
  const getCollectionUrlById = (id: string, tab?: string) => collectionDetail.getUrl({ id, tab })
  const getOrganizationUrlById = (id: string, tab?: string) => organizationDetail.getUrl({ id, tab })
  const getProjectTypeUrlById = (id: string) => projectTypeDetail.getUrl({ id })
  const getUserUrlById = (id: string, tab?: string) => userDetail.getUrl({ id, tab })
  const getSavedSearchUrlById = (id: string, tab?: string) => savedSearchDetail.getUrl({ id, tab })
  const getProfileUrl = () => profileDetail.getUrl({})
  const getWebRaveUrl = (id: string) => `/rv/${id}`

  // collections

  const [getCollectionById] = useGetCollectionByIdLazyQuery()
  const getCollectionOptionById = useCallback(async (id: string) => {
    const result = await getCollectionById({ variables: { id } })
    if (!result.data?.collection) return null
    return itemToSelectOption(result.data.collection)
  }, [])

  const [getCollectionsByName] = useGetCollectionsByNameLazyQuery()
  const getCollectionOptionsByName = useCallback(
    async (name: string) => {
      if (!name)
        return activities.map(extractItem).filter(isCollection).slice(0, ACTIVITIES_LIMIT).map(itemToSelectOption)
      const result = await getCollectionsByName({ variables: { name } })
      return (result.data?.searchCollections.results.map(extractItem) ?? []).map(itemToSelectOption)
    },
    [activities]
  )

  const getEditableCollectionOptionsByName = useCallback(
    async (name: string) => {
      if (!name)
        return activities
          .map(extractItem)
          .filter(isCollection)
          .filter((collection) => collection.permissions.update)
          .slice(0, ACTIVITIES_LIMIT)
          .map(itemToSelectOption)
      const result = await getCollectionsByName({ variables: { name, editableOnly: true } })
      return (result.data?.searchCollections.results.map(extractItem) ?? []).map(itemToSelectOption)
    },
    [activities]
  )

  // projects

  const [getProjectsByName] = useGetProjectsByNameLazyQuery()
  const getProjectOptionsByName = useCallback(
    async (name: string) => {
      if (!name) return activities.map(extractItem).filter(isProject).slice(0, ACTIVITIES_LIMIT).map(itemToSelectOption)
      const result = await getProjectsByName({ variables: { name } })
      return (result.data?.searchProjects.results.map(extractItem) ?? []).map(itemToSelectOption)
    },
    [activities]
  )

  // project types

  const [allProjectTypeOptionsPromise, resolveProjectTypeOptionsPromise] =
    useDeconstructedPromise<AutocompleteSelectOption[]>()
  const [getProjectTypes] = useGetProjectTypesLazyQuery()
  useEffect(() => {
    if (!isAuthenticated) return
    getProjectTypes().then(
      ({ data }) => resolveProjectTypeOptionsPromise(data?.projectTypes?.items.map(itemToSelectOption) ?? []) // TODO: this is paginated, check total and continue queries
    )
  }, [isAuthenticated])

  const getProjectTypeOptionById = (id: string) =>
    allProjectTypeOptionsPromise.then(
      (allProjectTypeOptions) => allProjectTypeOptions.find(({ value }) => value === id) ?? null
    )

  const getProjectTypeOptionsByName = (name: string) =>
    allProjectTypeOptionsPromise.then((allProjectTypeOptions) => {
      if (!name.trim()) return allProjectTypeOptions
      const nameLowercase = name.toLowerCase()
      return allProjectTypeOptions.filter(({ text }) => text.toLowerCase().includes(nameLowercase))
    })

  // organizations

  const [getOrganizationById] = useGetOrganizationByIdLazyQuery()
  const getOrganizationOptionById = useCallback(async (id: string) => {
    const result = await getOrganizationById({ variables: { id } })
    if (!result.data?.organization) return null
    return itemToSelectOption(result.data.organization)
  }, [])

  const [getOrganizationsByName] = useGetOrganizationsByNameLazyQuery()
  const getOrganizationOptionsByName = useCallback(async (name: string) => {
    const result = await getOrganizationsByName({ variables: { name } })
    return (result.data?.searchOrganizations.results.map(extractItem) ?? []).map(itemToSelectOption)
  }, [])

  // users

  const [getUserById] = useGetUserByIdLazyQuery()
  const getUserOptionById = useCallback(async (id: string) => {
    const result = await getUserById({ variables: { id } })
    if (!result.data?.user) return null
    return itemToSelectOption(result.data.user)
  }, [])

  const [getUsersByName] = useGetUsersByNameLazyQuery()
  const getUserOptionsByName = useCallback(async (name: string) => {
    if (!name) return activities.map(extractItem).filter(isUser).slice(0, ACTIVITIES_LIMIT).map(itemToSelectOption)
    const result = await getUsersByName({ variables: { name } })
    return (result.data?.searchUsers.results.map(extractItem) ?? []).map(itemToSelectOption)
  }, [])

  // images

  const [_getImageUploadStatus] = useGetImageUploadStatusLazyQuery()
  const getImageUploadStatus = useCallback(async (token: string) => {
    const { data } = await _getImageUploadStatus({ variables: { token }, fetchPolicy: 'no-cache' })
    if (!data?.checkUpload) throw new MissingDataError()
    return {
      errors: data.checkUpload.errors || undefined,
      status: data.checkUpload.status || undefined,
    }
  }, [])

  const putFile = async (url: string, fields: Record<string, any>): Promise<unknown> => {
    const { file, ...restFields } = fields
    const formData = new FormData()
    Object.entries(restFields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('file', file) // N.B. AWS requires that file is last!
    return fetch(url, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
  }

  // RETURN

  return {
    getCollectionOptionById,
    getCollectionOptionsByName,
    getCollectionUrlById,
    getEditableCollectionOptionsByName,
    getImageUploadStatus,
    getOrganizationOptionById,
    getOrganizationOptionsByName,
    getOrganizationUrlById,
    getProjectTypeUrlById,
    getProfileUrl,
    getProjectOptionsByName,
    getProjectTypeOptionById,
    getProjectTypeOptionsByName,
    getProjectUrlById,
    getSavedSearchUrlById,
    getUserOptionById,
    getUserOptionsByName,
    getUserUrlById,
    getWebRaveUrl,
    putFile,
  }
}
