import React from 'react'
import { useTheme, Box, Typography, Grid, TableRow, Table, TableCell, TableBody } from '@mui/material'

export interface OverviewBasicPropertiesProps {
  propTable: { label: React.ReactNode; value: React.ReactNode }[]
}

export const OverviewBasicProperties: React.FC<OverviewBasicPropertiesProps> = ({ propTable }) => {
  const theme = useTheme()
  return (
    <Table padding="none">
      <TableBody>
        {propTable.map(({ label, value }, i) => {
          return (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: 'right', py: 0.5, width: '1%', whiteSpace: 'nowrap' }}>
                <Typography variant="overline">{label}</Typography>
              </TableCell>
              <TableCell sx={{ py: 0.5 }}>
                <Box>{value}</Box>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
