import { Symbology, SymbologyStateEnum } from '@riverscapes/react-common'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { projectAtom, symbologyAtomFamily, SymbologyKey } from '../recoil'
import { useGetWebSymbologyQuery } from '../schema/operations'

/**
 * This function creates a lunk between the Apollo cache and the recoil state
 * @param param0
 * @returns
 */
export const MapSymbologiesConnect: React.FC<{ symbologyKey: SymbologyKey }> = ({ symbologyKey }) => {
  const setSymbology = useSetRecoilState(symbologyAtomFamily(symbologyKey))
  const project = useRecoilValue(projectAtom)

  useGetWebSymbologyQuery({
    variables: {
      name: symbologyKey[0],
      isRaster: symbologyKey[1],
      projectTypeId: project?.projectType?.id,
    },
    // We don't want to poll for symbologies. They are static: Either they are there or they aren't
    onError: (error) => {
      if (error.message.includes('could not be found')) {
        setSymbology({
          name: symbologyKey[0],
          error: error.message,
          state: SymbologyStateEnum.Missing,
          __typename: 'Symbology',
        })
      } else {
        setSymbology({
          name: symbologyKey[0],
          error: error.message,
          state: SymbologyStateEnum.Error,
          __typename: 'Symbology',
        })
      }
    },
    // When the query completes, update the symbology atom
    onCompleted: (data) => {
      setSymbology(data.getWebSymbology as Symbology)
    },
    // Don't run the query if we don't have a project type
    skip: !project?.projectType?.id || !symbologyKey,
  })
  return null
}
