import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  StackProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { identity, isEqual, remove, uniqueId } from 'lodash'

import { DateWithinEnum, SearchSpec } from './types'
import { AutocompleteSelectField } from '../AutocompleteSelectField'
import { dropUndefined, LogicError, someValues, useFields, withTooltip } from '../../lib'
import produce from 'immer'
import { DateField, DATE_FIELD_INVALID_VALUE, DateFieldValue } from '../DateField'
import { OwnerInputTypesEnum } from '../../schema/base'
import { ClearIcon } from '../Icons'
import { Lookups } from '../lookups'
import { ProjectSearchSpecParams } from './types'
import { OtherSearchSpecParams } from './types'
import { FilterList, Help, RestartAlt, Search as SearchIcon } from '@mui/icons-material'

export type ModifySearchProps = SearchSpec & {
  canSearchClean?: boolean
  disabled?: {
    type?: boolean
    collection?: boolean
  }
  lookups: Lookups
  onClose: () => void
  onSearch: (newSearch: SearchSpec) => void
  open: boolean
}

const RowWrapper: React.FC<StackProps> = (props) => (
  <Stack direction="row" sx={{ my: '1rem', minHeight: '2.5rem' }} {...props} />
)

const CriterionLabel = ({
  active,
  children,
  hints,
  helpNode,
}: {
  active?: boolean
  children: React.ReactNode
  hints?: string
  helpNode?: React.ReactNode
}) => {
  const theme = useTheme()
  const [helpOpen, setHelpOpen] = React.useState(false)

  return (
    <>
      <Typography
        component="div"
        sx={{
          minWidth: '12rem',
          lineHeight: '2.5rem',
          position: 'relative',
          fontWeight: active ? 'bold' : 'normal',
          '&:after': {
            content: hints ? `" (${hints})"` : undefined,
            fontSize: '0.6rem',
            fontStyle: 'italic',
            color: 'text.secondary',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'absolute',
            left: 0,
            top: '1rem',
          },
        }}
      >
        {children}
        {helpNode && (
          <IconButton
            onClick={() => setHelpOpen(true)}
            size="small"
            sx={{
              color: theme.palette.info.light,
              fontSize: '1rem',
              position: 'absolute',
              right: 0,
              top: 7,
            }}
          >
            <Help sx={{ fontSize: '1rem' }} />
          </IconButton>
        )}
      </Typography>
      {helpNode && (
        <Dialog open={helpOpen} onClose={() => setHelpOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle
            sx={{
              color: theme.palette.info.contrastText,
              backgroundColor: theme.palette.info.main,
              mb: 1,
            }}
          >
            {children} Help
          </DialogTitle>
          <DialogContent
            sx={{
              '& code': {
                backgroundColor: theme.palette.grey[200],
                padding: '0.1rem 0.3rem',
                borderRadius: '0.2rem',
              },
            }}
          >
            {helpNode}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHelpOpen(false)}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const emptyMetaRow = () => ({ rowId: uniqueId(), key: '', value: '' })

const getFieldsFromSearchSpec = ({ type, params }: SearchSpec) => {
  return {
    isMapBounded: type === 'Project' && params.bounded,
    isBoundsBounded: type === 'Project' && !!params.boundsId,
    collection: (type === 'Project' && params.collection) || null,
    keywords: params.keywords ?? '',
    meta: [...(params.meta ?? []).map(({ key, value }) => ({ key, value, rowId: uniqueId() })), emptyMetaRow()],
    name: params.name ?? '',
    ownedByType: params.ownedBy?.type || OwnerInputTypesEnum.Organization,
    ownedById: params.ownedBy?.id || null,
    projectTypeId: (type === 'Project' && params.projectTypeId) || null,
    tagsText: params.tags?.join(', ') ?? '',
    type: type,
    createdWithin: params.createdWithin,
    createdFrom: (params.createdOn?.from ?? null) as DateFieldValue,
    createdTo: (params.createdOn?.to ?? null) as DateFieldValue,
    updatedFrom: (params.updatedOn?.from ?? null) as DateFieldValue,
    updatedTo: (params.updatedOn?.to ?? null) as DateFieldValue,
  }
}

export const ModifySearchDialog: React.FC<ModifySearchProps> = ({
  canSearchClean,
  disabled,
  lookups: {
    getCollectionOptionById,
    getCollectionOptionsByName,
    getProjectTypeOptionById,
    getProjectTypeOptionsByName,
    getUserOptionById,
    getUserOptionsByName,
    getOrganizationOptionById,
    getOrganizationOptionsByName,
  },
  onClose,
  onSearch,
  open,
  params,
  type: typeProp,
}) => {
  const theme = useTheme()
  // STATE

  const [fields, setFields, preparedFields, isDirty] = useFields(
    getFieldsFromSearchSpec({
      type: typeProp,
      params,
    }),
    ({
      isMapBounded,
      isBoundsBounded: isBoundsIdBounded,
      collection,
      keywords,
      meta,
      name,
      ownedById,
      ownedByType,
      projectTypeId,
      tagsText,
      type,
      createdWithin,
      createdFrom,
      createdTo,
      updatedFrom,
      updatedTo,
    }) => {
      const tagsArray = tagsText
        .split(',')
        .map((s) => s.trim())
        .filter(identity)

      const metaArray = meta
        .filter(({ key, value }) => key.trim() && value.trim())
        .map(({ key, value }) => ({ key, value }))

      const createdOn = (() => {
        if (!createdFrom && !createdTo) return undefined
        if (createdFrom === DATE_FIELD_INVALID_VALUE || createdTo === DATE_FIELD_INVALID_VALUE) return undefined
        return {
          from: createdFrom,
          to: createdTo,
        }
      })()

      const updatedOn = (() => {
        if (!updatedFrom && !updatedTo) return undefined
        if (updatedFrom === DATE_FIELD_INVALID_VALUE || updatedTo === DATE_FIELD_INVALID_VALUE) return undefined
        return {
          from: updatedFrom,
          to: updatedTo,
        }
      })()

      const commonParams: ProjectSearchSpecParams & OtherSearchSpecParams = {
        createdWithin,
        createdOn,
        keywords: keywords.replace(/\s{2,}/g, ' ').trim() || undefined,
        meta: metaArray.length ? metaArray : undefined,
        name: name.replace(/\s{2,}/g, ' ').trim() || undefined,
        ownedBy:
          (ownedById && {
            id: ownedById,
            type: ownedByType,
          }) ||
          undefined,
        projectTypeId: (type === 'Project' && projectTypeId) || undefined,
        tags: tagsArray.length ? tagsArray : undefined,
        updatedOn,
      }

      const projectParams: ProjectSearchSpecParams = {
        ...commonParams,
        bounded: (type === 'Project' && isMapBounded) || undefined,
        boundsId: (typeProp === 'Project' && isBoundsIdBounded && params.boundsId) || undefined,
        collection: (type === 'Project' && collection) || undefined,
      }

      const prepared: SearchSpec = {
        type,
        params: {
          ...commonParams,
          ...(type === 'Project' ? projectParams : {}),
        },
      }

      return dropUndefined(prepared)
    },
    isEqual,
    [open]
  )

  const errors = someValues(() => {
    const { createdFrom, createdTo, updatedFrom, updatedTo, meta } = fields
    const invertedCreatedDates =
      typeof createdFrom === 'string' && typeof createdTo === 'string' && createdFrom >= createdTo
    const invertedUpdatedDates =
      typeof updatedFrom === 'string' && typeof updatedTo === 'string' && updatedFrom >= updatedTo
    return {
      meta: meta.some(({ key, value }) => {
        const hasKey = !!key.trim()
        const hasValue = !!value.trim()
        return (hasKey && !hasValue) || (!hasKey && hasValue)
      }),
      createdFrom: createdFrom === DATE_FIELD_INVALID_VALUE || invertedCreatedDates,
      createdTo: createdTo === DATE_FIELD_INVALID_VALUE || invertedCreatedDates,
      updatedFrom: updatedFrom === DATE_FIELD_INVALID_VALUE || invertedUpdatedDates,
      updatedTo: updatedTo === DATE_FIELD_INVALID_VALUE || invertedUpdatedDates,
      noCriteria: Object.keys(preparedFields).length === 0,
    }
  })

  // HANDLERS

  const handleSearchClick = () => {
    const { type, params } = preparedFields
    onSearch({
      type,
      params,
    })
    onClose()
  }

  const handleReset = () => setFields(getFieldsFromSearchSpec({ type: fields.type, params: {} }))

  // RENDER

  const {
    isMapBounded,
    isBoundsBounded,
    collection,
    keywords,
    meta,
    name,
    ownedById,
    // ownedByType,
    projectTypeId,
    tagsText,
    type,
  } = fields

  const disableSearch = (!canSearchClean && !isDirty) || !!errors
  const searchTooltip = (() => {
    if (!disableSearch) return
    if (errors?.meta) return 'Metadata is missing a key or value.'
    if (errors?.createdFrom || errors?.createdTo) return 'Created date field(s) contain invalid date(s).'
    if (errors?.updatedFrom || errors?.updatedTo) return 'Updated date field(s) contain invalid date(s).'
    if (errors?.noCriteria) return 'No criteria to search for.'
    if (!isDirty) return 'Criteria has not changed.'
  })()

  const disableReset = Object.values(preparedFields).length === 1 // type is always present

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          mb: 1,
          fontSize: '1.5rem',
          display: 'flex',
        }}
      >
        <SearchIcon sx={{ fontSize: '2rem', mr: 1 }} />
        Advanced Search
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          value={type}
          onChange={(event) => setFields.$.type(event.target.value as ModifySearchProps['type'])}
        >
          <FormControlLabel
            disabled={disabled?.type && type !== 'Project'}
            value="Project"
            control={<Radio />}
            label="Projects"
          />
          <FormControlLabel
            disabled={disabled?.type && type !== 'Collection'}
            value="Collection"
            control={<Radio />}
            label="Collections"
          />
          <FormControlLabel
            disabled={disabled?.type && type !== 'Organization'}
            value="Organization"
            control={<Radio />}
            label="Organizations"
          />
          <FormControlLabel
            disabled={disabled?.type && type !== 'User'}
            value="User"
            control={<Radio />}
            label="Users"
          />
          <FormControlLabel
            disabled
            // disabled={disabled?.type && type !== 'SavedSearch'}
            value="SavedSearch"
            control={<Radio />}
            label="Saved Searches"
          />
        </RadioGroup>
        {/* </Box> */}
        <Divider />
        <Box sx={{ maxWidth: '50rem', mx: 'auto' }}>
          {preparedFields.type === 'Project' && (
            <RowWrapper>
              <CriterionLabel active={!!preparedFields.params.projectTypeId}>Project type</CriterionLabel>
              <AutocompleteSelectField
                placeholder="VBET, BRAT, etc."
                value={projectTypeId}
                getOptionFromValue={getProjectTypeOptionById}
                getOptionsFromText={getProjectTypeOptionsByName}
                onChange={setFields.$.projectTypeId}
              />
            </RowWrapper>
          )}
          <RowWrapper>
            <CriterionLabel active={!!preparedFields.params.keywords}>Keywords</CriterionLabel>
            <TextField
              fullWidth
              placeholder="Keywords in title, summary, description, etc."
              value={keywords}
              onChange={(event) => setFields.$.keywords(event.target.value)}
            />
          </RowWrapper>
          <RowWrapper>
            <CriterionLabel active={!!preparedFields.params.name}>Name</CriterionLabel>
            <TextField
              fullWidth
              placeholder="Name contains"
              value={name}
              onChange={(event) => setFields.$.name(event.target.value)}
            />
          </RowWrapper>
          <RowWrapper>
            <CriterionLabel active={!!preparedFields.params.tags} helpNode={tagHelpNode}>
              Tags
            </CriterionLabel>
            <TextField
              fullWidth
              placeholder="TagA, TagB, ..."
              value={tagsText}
              onChange={(event) => setFields.$.tagsText(event.target.value)}
            />
          </RowWrapper>
          <RowWrapper>
            <CriterionLabel
              active={!!preparedFields.params.meta}
              hints="HUC, ModelVersion etc."
              helpNode={metaHelpNode}
            >
              Metadata
            </CriterionLabel>
            <Stack direction="column" gap={2} width="100%">
              {meta.map(({ rowId, key, value }, index) => {
                const hasKey = key.trim() ? true : undefined
                const hasValue = value.trim() ? true : undefined
                return (
                  <Stack direction="row" key={rowId} width="100%">
                    <Autocomplete
                      freeSolo
                      sx={{ flex: 1 }}
                      options={['HUC', 'ModelVersion']}
                      value={key}
                      onInputChange={(e, newValue) => {
                        setFields(
                          produce((draft) => {
                            const draftRow = draft.meta.find((row) => row.rowId === rowId)
                            if (!draftRow) throw new LogicError()
                            draftRow.key = newValue || ''
                            if (index === meta.length - 1 && hasValue) draft.meta.push(emptyMetaRow())
                          })
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ flex: 1 }}
                          error={!hasKey && hasValue}
                          placeholder="Meta Key (exact, case-insensitive)"
                          label={hasKey && 'Key'}
                        />
                      )}
                    />
                    &nbsp;
                    <TextField
                      sx={{ flex: 1 }}
                      placeholder="Meta Value (exact, case-insensitive)"
                      label={hasValue && 'Value'}
                      value={value}
                      error={hasKey && !hasValue}
                      onChange={(e) => {
                        setFields(
                          produce((draft) => {
                            const draftRow = draft.meta.find((row) => row.rowId === rowId)
                            if (!draftRow) throw new LogicError()
                            draftRow.value = e.target.value
                            if (index === meta.length - 1 && hasKey) draft.meta.push(emptyMetaRow())
                          })
                        )
                      }}
                    />
                    <IconButton
                      disabled={index === meta.length - 1 && !hasKey && !hasValue}
                      onClick={() =>
                        setFields(
                          produce((draft) => {
                            if (index === meta.length - 1) {
                              draft.meta[index] = emptyMetaRow()
                            } else {
                              remove(draft.meta, (row) => row.rowId === rowId)
                            }
                          })
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                )
              })}
            </Stack>
          </RowWrapper>
          {preparedFields.type === 'Project' && (
            <RowWrapper>
              <CriterionLabel active={!!preparedFields.params.collection}>Collection</CriterionLabel>
              <AutocompleteSelectField
                placeholder={'Only projects within a collection'}
                disabled={disabled?.collection}
                value={collection}
                getOptionFromValue={getCollectionOptionById}
                getOptionsFromText={getCollectionOptionsByName}
                onChange={setFields.$.collection}
              />
            </RowWrapper>
          )}
          {preparedFields.type !== 'User' && (
            <RowWrapper>
              <CriterionLabel active={!!preparedFields.params.ownedBy}>Owned by</CriterionLabel>
              <AutocompleteSelectField
                placeholder="Owner"
                value={ownedById}
                getOptionFromValue={(value: string) => {
                  if (fields.ownedByType === OwnerInputTypesEnum.Organization) return getOrganizationOptionById(value)
                  return getUserOptionById(value)
                }}
                getOptionsFromText={(text: string) => {
                  return Promise.all([getOrganizationOptionsByName(text), getUserOptionsByName(text)]).then(
                    ([orgs, users]) => {
                      // Sort orgs and users before returning them
                      const sortedOrgs = [...orgs].sort((a, b) => a.data.name.localeCompare(b.data.name))
                      const sortedUsers = [...users].sort((a, b) => a.data.name.localeCompare(b.data.name))
                      return [...sortedOrgs, ...sortedUsers]
                    }
                  )
                }}
                onChange={(newValue, selectedOption) => {
                  setFields.$.ownedById(newValue)
                  if (!selectedOption) return // nothing selected
                  const newOwnedByType =
                    OwnerInputTypesEnum[selectedOption.data?.__typename as keyof typeof OwnerInputTypesEnum]
                  if (!newOwnedByType) throw new LogicError()
                  setFields.$.ownedByType(newOwnedByType)
                }}
              />
            </RowWrapper>
          )}
          <RowWrapper>
            <CriterionLabel active={!!preparedFields.params.createdWithin}>Created within</CriterionLabel>
            <div style={{ flex: 1, position: 'relative' }}>
              {!fields.createdWithin && (
                <TextField
                  fullWidth
                  placeholder="Last day, last week, etc."
                  value=""
                  style={{ position: 'absolute' }}
                />
              )}
              <Select
                size="small"
                fullWidth
                value={fields.createdWithin || ''}
                onChange={(e) => {
                  setFields.$.createdFrom(null)
                  setFields.$.createdTo(null)
                  setFields.$.createdWithin((e.target.value || undefined) as DateWithinEnum | undefined)
                }}
              >
                <MenuItem value={''}>
                  <em>any</em>
                </MenuItem>
                <MenuItem value={DateWithinEnum.ONE_DAY}>last day</MenuItem>
                <MenuItem value={DateWithinEnum.ONE_WEEK}>last week</MenuItem>
                <MenuItem value={DateWithinEnum.ONE_MONTH}>last month</MenuItem>
                <MenuItem value={DateWithinEnum.SIX_MONTHS}>last 6 months</MenuItem>
              </Select>
            </div>
          </RowWrapper>
          <RowWrapper>
            <CriterionLabel active={!!preparedFields.params.createdOn}>Created date</CriterionLabel>
            <Stack direction="row" alignItems="center">
              <span style={{ margin: '0 0.5em', opacity: fields.createdFrom || fields.createdTo ? 1 : 0.4 }}>
                between
              </span>{' '}
              <DateField
                error={errors?.createdFrom}
                sx={{ flex: 1 }}
                value={fields.createdFrom}
                onChange={(v) => {
                  setFields.$.createdWithin(undefined)
                  setFields.$.createdFrom(v)
                }}
                placeholder={fields.createdTo ? 'any time' : undefined}
              />
              <span style={{ margin: '0 0.5em', opacity: fields.createdFrom || fields.createdTo ? 1 : 0.4 }}>and</span>
              <DateField
                error={errors?.createdTo}
                sx={{ flex: 1 }}
                endOfDay
                value={fields.createdTo}
                onChange={(v) => {
                  setFields.$.createdWithin(undefined)
                  setFields.$.createdTo(v)
                }}
                placeholder={fields.createdFrom ? 'any time' : undefined}
              />
            </Stack>
          </RowWrapper>
          <RowWrapper>
            <CriterionLabel active={!!preparedFields.params.updatedOn}>Last updated date</CriterionLabel>
            <Stack direction="row" alignItems="center">
              <span style={{ margin: '0 0.5em', opacity: fields.updatedFrom || fields.updatedTo ? 1 : 0.4 }}>
                between
              </span>{' '}
              <DateField
                error={errors?.updatedFrom}
                value={fields.updatedFrom}
                onChange={setFields.$.updatedFrom}
                placeholder={fields.updatedTo ? 'any time' : undefined}
              />
              <span style={{ margin: '0 0.5em', opacity: fields.updatedFrom || fields.updatedTo ? 1 : 0.4 }}>and</span>
              <DateField
                error={errors?.updatedTo}
                endOfDay
                value={fields.updatedTo}
                onChange={setFields.$.updatedTo}
                placeholder={fields.updatedFrom ? 'any time' : undefined}
              />
            </Stack>
          </RowWrapper>
          {preparedFields.type === 'Project' && (
            <RowWrapper>
              <CriterionLabel
                active={!!preparedFields.params.bounded || !!preparedFields.params.boundsId}
                helpNode={metaLocationHelpNode}
              >
                Location
              </CriterionLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMapBounded || false}
                    onChange={(e) => {
                      const newValue = e.target.checked
                      setFields.$.isMapBounded(newValue)
                      if (newValue && isBoundsBounded) setFields.$.isBoundsBounded(false)
                    }}
                  />
                }
                label="Within map area"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBoundsBounded || false}
                    onChange={(e) => {
                      const newValue = e.target.checked
                      setFields.$.isBoundsBounded(newValue)
                      if (newValue && isMapBounded) setFields.$.isMapBounded(false)
                    }}
                    disabled={typeProp !== 'Project' || !params.boundsId}
                  />
                }
                label="With a specific bounds polygon"
              />
            </RowWrapper>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button startIcon={<RestartAlt />} onClick={handleReset} disabled={disableReset} color="warning">
          Reset Fields
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {withTooltip(
          <Button variant="contained" onClick={handleSearchClick} disabled={disableSearch} sx={{ ml: 2 }}>
            Search
          </Button>,
          searchTooltip,
          disableSearch
        )}
      </DialogActions>
    </Dialog>
  )
}

const metaHelpNode = (
  <Box>
    <Typography paragraph>
      Metadata is a key-value pair that can be used to add context to a project. Project metadata is stored in the XML
      file that is uploaded to the data exchange.
    </Typography>
    <Typography paragraph>
      Metadata searches are exact and case-insensitive for the key will return values case-sensitive that begin with the
      value. For example, searching for <code>key: HUC, value: 123456</code> will not return projects with the keys{' '}
      <code>HUC10, HUCS, cHUCk</code> or the values
      <code>1234, 12345</code> etc. However, searching for <code>key: HUC, value: 123456</code> will return projects
      with the keys <code>huc, HuC, huC</code> and the values <code>123456, 1234567, 123456 abc</code> etc.
    </Typography>
    <Typography paragraph>
      Multiple pairs of key-value criteria can be searched for. This search uses AND logic so it will only return
      results for which all of the keys and all of their corresponding values match,.
    </Typography>
    <Typography variant="h5">Examples</Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Explanation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>ModelVersion</TableCell>
            <TableCell>1.0.2</TableCell>
            <TableCell>Search for this exact model version</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>HUC</TableCell>
            <TableCell>0401010101</TableCell>
            <TableCell>Search for this exact HUC code</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
)

const metaLocationHelpNode = (
  <Box>
    <Typography paragraph>
      <strong>Within Map Area</strong>: Check this box to search for projects that are within the current map area.
    </Typography>
    <Typography paragraph>
      <strong>With a specific bounds polygon</strong>: If you have filtered the map to a specific bounds polygon using
      the <FilterList /> button, you can uncheck this box to reset the map area filter.{' '}
      <em>Note: This box cannot be checked. Only un-checked.</em>
    </Typography>
  </Box>
)

const tagHelpNode = (
  <Box>
    <Typography paragraph>
      Tags are an attribute of the data exchange and are a way to categorize projects and make them findable.
    </Typography>
    <Typography paragraph>
      Tag searches are exact and case-insensitive. For example, searching for <code>VBE</code> will not return projects
      with the tag <code>TEST</code>. However, searching for <code>VBET</code> will return projects with the tag{' '}
      <code>vbet, vBEt, vbET</code> etc.
    </Typography>
    <Typography paragraph>
      Multiple tags can be searched for by separating them with commas. For example, searching for{' '}
      <code>TEST, RUN</code> will only return projects that include both of those tags.
    </Typography>
  </Box>
)
