/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react'
import { OrganizationInviteRoleEnum, OrganizationInviteStateEnum, OrganizationRoleEnum } from '../../schema/base'
import { DataGrid } from '@mui/x-data-grid'
import { isPendingItem, LogicError, someValues, useFields, withTooltip } from '../../lib'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { AcceptIcon, DeleteIcon, EditIcon, EmailIcon } from '../Icons'
import { ItemRepresentation } from '../ItemImage'
import { getRoleDescriptor, organizationMembersColumns, organizationInvitesColumns, Toolbar } from './components'
import { RoleField } from '../Role/RoleField'
import { AutocompleteSelectField } from '../AutocompleteSelectField'
import { PendingItem } from '../PendingItem'
import { Lookups } from '../lookups'
import { ActionableRow } from './types'
import { OrganizationMemberRow, OrganizationInviteRow, isOrganizationInviteRow, isOrganizationMemberRow } from './types'
import { Box, useTheme } from '@mui/system'

export interface UsersTableProps {
  className?: string
  lookups: Lookups
  myId: string
  myRole: OrganizationRoleEnum
  organizationName: string
  rowsByType: {
    members: (OrganizationMemberRow | PendingItem)[]
    invites: (OrganizationInviteRow | PendingItem)[]
  }
  onInviteCreate: (invite: { userId?: string; email?: string; role: OrganizationInviteRoleEnum }) => Promise<unknown>
  onInviteResend: (invite: { id: string }) => Promise<unknown>
  onInviteDelete: (invite: { id: string }) => Promise<unknown>
  onInviteAction: (invite: { id: string; accept: boolean; role?: OrganizationInviteRoleEnum }) => Promise<unknown>
  onMemberUpdate: (user: { id: string; role: OrganizationRoleEnum }) => Promise<unknown>
  onMemberRemove: (user: { id: string }) => Promise<unknown>
}

export const UsersTable: React.FC<UsersTableProps> = ({
  className,
  lookups,
  myId,
  myRole,
  onInviteAction,
  onInviteCreate,
  onInviteDelete,
  onInviteResend,
  onMemberRemove,
  onMemberUpdate,
  organizationName,
  rowsByType,
}) => {
  const theme = useTheme()
  const [mode, setMode] = useState<'members' | 'requests'>('members')

  const [promptAction, setPromptAction] = useState<
    | 'deleteMember'
    | 'deleteInvite'
    | 'deleteRequest'
    | 'editMember'
    | 'resendInvite'
    | 'acceptRequest'
    | 'createInvite'
    | null
  >(null)
  const closePrompt = () => setPromptAction(null)
  const [targetRow, setTargetRow] = useState<OrganizationMemberRow | OrganizationInviteRow | null>(null)

  const [fields, setFields, preparedFields] = useFields(
    {
      userId: null as null | string,
      email: '',
      inviteBy: 'userId' as 'userId' | 'email',
      memberRole: targetRow?.role ?? OrganizationRoleEnum.Viewer,
      inviteRole: isOrganizationInviteRow(targetRow) ? targetRow.role : OrganizationInviteRoleEnum.Viewer,
    },
    ({ userId, email, inviteBy, memberRole, inviteRole }) => ({
      inviteUserId: inviteBy === 'userId' && userId ? userId : undefined,
      inviteEmail: inviteBy === 'email' ? email : undefined,
      memberRole,
      inviteRole,
    }),
    [promptAction]
  )

  const errors = someValues(() => {
    if (promptAction === 'createInvite') {
      return {
        email: fields.inviteBy === 'email' && !preparedFields.inviteEmail?.match(/.+/), // TODO: validate email
        userId: fields.inviteBy === 'userId' && !preparedFields.inviteUserId,
      }
    }
  })

  const decoratedMemberRows: ((OrganizationMemberRow & ActionableRow) | PendingItem)[] = useMemo(() => {
    return rowsByType.members.map((row) => {
      if (isPendingItem(row)) return row
      return {
        ...row,
        actions: row.user.id !== myId && (
          <div css={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            {withTooltip(
              <IconButton
                onClick={() => {
                  setTargetRow(row)
                  setPromptAction('editMember')
                }}
              >
                <EditIcon color="primary" />
              </IconButton>,
              'Edit User Role'
            )}
            {withTooltip(
              <IconButton
                onClick={() => {
                  setTargetRow(row)
                  setPromptAction('deleteMember')
                }}
              >
                <DeleteIcon />
              </IconButton>,
              'Revoke User Membership'
            )}
          </div>
        ),
        lookups,
      }
    })
  }, [rowsByType, mode])

  const decoratedInviteRows: ((OrganizationInviteRow & ActionableRow) | PendingItem)[] = useMemo(() => {
    return rowsByType.invites.map((row) => {
      if (isPendingItem(row)) return row
      return {
        ...row,
        actions: (
          <div css={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            {row.state === OrganizationInviteStateEnum.Requested &&
              withTooltip(
                <IconButton
                  onClick={() => {
                    setTargetRow(row)
                    setPromptAction('acceptRequest')
                  }}
                >
                  <AcceptIcon />
                </IconButton>,
                'Accept User Request'
              )}
            {row.state === OrganizationInviteStateEnum.Expired &&
              withTooltip(
                <IconButton
                  onClick={() => {
                    setTargetRow(row)
                    setPromptAction('resendInvite')
                  }}
                >
                  <EmailIcon />
                </IconButton>,
                'Resend Expired Invitation'
              )}
            {(() => {
              switch (row.state) {
                case OrganizationInviteStateEnum.Requested:
                  return withTooltip(
                    <IconButton
                      onClick={() => {
                        setTargetRow(row)
                        setPromptAction('deleteRequest')
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>,
                    'Reject User Request'
                  )
                case OrganizationInviteStateEnum.Expired:
                  return withTooltip(
                    <IconButton
                      onClick={() => {
                        // delete no prompt
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>,
                    'Delete Expired Invitation'
                  )
                case OrganizationInviteStateEnum.Invited:
                  return withTooltip(
                    <IconButton
                      onClick={() => {
                        setTargetRow(row)
                        setPromptAction('deleteInvite')
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>,
                    'Withdraw Invitation'
                  )
                case OrganizationInviteStateEnum.Rejected:
                  return withTooltip(
                    <IconButton
                      onClick={() => {
                        onInviteDelete({ id: row.id })
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>,
                    'Delete Rejected Invitation'
                  )
                default:
                  return null
              }
            })()}
          </div>
        ),
        lookups,
      }
    })
  }, [rowsByType, mode])

  return (
    <>
      <div className={className}>
        {mode === 'members' ? (
          <DataGrid
            rows={decoratedMemberRows}
            columns={organizationMembersColumns}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            disableColumnSelector
            components={{
              Toolbar: () => (
                <Toolbar
                  onInviteClick={() => setPromptAction('createInvite')}
                  mode={mode}
                  setMode={setMode}
                  counts={{ members: rowsByType.members.length, requests: rowsByType.invites.length }}
                />
              ),
            }}
          />
        ) : (
          <DataGrid
            rows={decoratedInviteRows}
            columns={organizationInvitesColumns}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            disableColumnSelector
            components={{
              Toolbar: () => (
                <Toolbar
                  onInviteClick={() => setPromptAction('createInvite')}
                  mode={mode}
                  setMode={setMode}
                  counts={{ members: rowsByType.members.length, requests: rowsByType.invites.length }}
                />
              ),
            }}
          />
        )}
      </div>
      <Dialog open={!!promptAction} onClose={closePrompt}>
        {(() => {
          if (!promptAction) return null
          if (promptAction === 'createInvite')
            return (
              <>
                <DialogTitle
                  sx={{
                    color: theme.palette.secondary.contrastText,
                    backgroundColor: theme.palette.secondary.main,
                    mb: 2,
                  }}
                >
                  Invite User
                </DialogTitle>
                <DialogContent>
                  <Stack gap={1}>
                    <FormControl fullWidth>
                      <FormLabel>User</FormLabel>
                      <RadioGroup
                        value={fields.inviteBy}
                        onChange={(e, newValue) => setFields.$.inviteBy(newValue as typeof fields.inviteBy)}
                      >
                        <Stack gap={1}>
                          <FormControlLabel
                            control={<Radio />}
                            value="userId"
                            componentsProps={{ typography: { sx: { flex: 1 } } }}
                            label={
                              <AutocompleteSelectField
                                label="Existing User"
                                value={fields.inviteBy === 'userId' ? fields.userId : null}
                                onChange={setFields.$.userId}
                                getOptionsFromText={lookups.getUserOptionsByName}
                                disabled={fields.inviteBy !== 'userId'}
                                noOptionsText="No users found"
                                error={errors?.userId}
                              />
                            }
                          />
                          <FormControlLabel
                            value="email"
                            control={<Radio />}
                            componentsProps={{ typography: { sx: { flex: 1 } } }}
                            label={
                              <TextField
                                fullWidth
                                disabled={fields.inviteBy !== 'email'}
                                label="Email Address"
                                value={fields.email}
                                onChange={(e) => {
                                  setFields.$.email(e.target.value)
                                }}
                              />
                            }
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                    <RoleField isInvite value={fields.inviteRole} onChange={setFields.$.inviteRole} />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closePrompt}>Cancel</Button>
                  <Box sx={{ flex: 1 }} />
                  <Button
                    onClick={() => {
                      onInviteCreate({
                        email: preparedFields.inviteEmail,
                        userId: preparedFields.inviteUserId,
                        role: preparedFields.inviteRole,
                      })
                      closePrompt()
                      setMode('requests')
                    }}
                    disabled={!!errors}
                  >
                    Send
                  </Button>
                </DialogActions>
              </>
            )

          if (!targetRow) throw new LogicError()
          switch (promptAction) {
            case 'acceptRequest':
              if (!isOrganizationInviteRow(targetRow)) throw new LogicError()
              return (
                <>
                  <DialogTitle
                    sx={{
                      color: theme.palette.secondary.contrastText,
                      backgroundColor: theme.palette.secondary.main,
                      mb: 2,
                    }}
                  >
                    Accept User Request
                  </DialogTitle>
                  <DialogContent>
                    <Stack gap={1}>
                      <Typography>
                        <ItemRepresentation item={{ __typename: 'User', id: 'invitee', ...targetRow.invitee }} /> has
                        requested membership to {organizationName}.
                      </Typography>
                      <RoleField isInvite value={fields.inviteRole} onChange={setFields.$.inviteRole} />
                    </Stack>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closePrompt}>Cancel</Button>
                    <Box sx={{ flex: 1 }} />
                    <Button
                      onClick={() => {
                        onInviteAction({ id: targetRow.id, accept: true, role: preparedFields.inviteRole })
                        closePrompt()
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              )
            case 'editMember':
              if (!isOrganizationMemberRow(targetRow)) throw new LogicError()
              return (
                <>
                  <DialogTitle
                    sx={{
                      color: theme.palette.secondary.contrastText,
                      backgroundColor: theme.palette.secondary.main,
                      mb: 2,
                    }}
                  >
                    Edit User Role
                  </DialogTitle>
                  <DialogContent>
                    <Stack gap={1}>
                      <Typography>
                        <ItemRepresentation item={{ __typename: 'User', ...targetRow.user }} /> currently has the role
                        of {getRoleDescriptor(targetRow.role)}.
                      </Typography>
                      <RoleField
                        allowOwner={myRole === OrganizationRoleEnum.Owner}
                        value={fields.memberRole}
                        onChange={setFields.$.memberRole}
                      />
                    </Stack>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closePrompt}>Cancel</Button>
                    <Box sx={{ flex: 1 }} />
                    <Button
                      onClick={() => {
                        onMemberUpdate({
                          id: targetRow.user.id,
                          role: preparedFields.memberRole,
                        })
                        closePrompt()
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              )
            case 'resendInvite':
              if (!isOrganizationInviteRow(targetRow)) throw new LogicError()
              return (
                <>
                  <DialogTitle>Resend Expired Invitation</DialogTitle>
                  <DialogContent>
                    <Typography>
                      {targetRow.invitee.name} was invited to {organizationName} but did not respond before the
                      invitation expired. Invitations may be resent up to five times.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closePrompt}>Cancel</Button>
                    <Button
                      onClick={() => {
                        onInviteResend({
                          id: targetRow.id,
                        })
                        closePrompt()
                      }}
                    >
                      Resend Invitation
                    </Button>
                  </DialogActions>
                </>
              )
            case 'deleteInvite':
              if (!isOrganizationInviteRow(targetRow)) throw new LogicError()
              return (
                <>
                  <DialogTitle>Revoke Invitation</DialogTitle>
                  <DialogContent>
                    {targetRow.invitee.name} has not yet responded to their invitation for {organizationName}.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closePrompt}>Cancel</Button>
                    <Button
                      onClick={() => {
                        onInviteDelete({ id: targetRow.id })
                        closePrompt()
                      }}
                    >
                      Revoke Invitation
                    </Button>
                  </DialogActions>
                </>
              )
            case 'deleteMember':
              if (!isOrganizationMemberRow(targetRow)) throw new LogicError()
              return (
                <>
                  <DialogTitle>Revoke User Membership</DialogTitle>
                  <DialogContent>
                    <ItemRepresentation item={{ __typename: 'User', ...targetRow.user }} /> currently has the role of{' '}
                    {getRoleDescriptor(targetRow.role)}. Revoking user membership means the user will only be able to
                    see the organization&apos;s public content.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closePrompt}>Cancel</Button>
                    <Button
                      onClick={() => {
                        onMemberRemove({ id: targetRow.user.id })
                        closePrompt()
                      }}
                    >
                      Revoke
                    </Button>
                  </DialogActions>
                </>
              )
            case 'deleteRequest':
              if (!isOrganizationInviteRow(targetRow)) throw new LogicError()
              return (
                <>
                  <DialogTitle>Reject User Request</DialogTitle>
                  <DialogContent>
                    <ItemRepresentation item={{ __typename: 'User', id: 'invitee', ...targetRow.invitee }} /> has
                    requested membership to {organizationName}.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closePrompt}>Cancel</Button>
                    <Button
                      onClick={() => {
                        onInviteAction({ id: targetRow.id, accept: false })
                        closePrompt()
                      }}
                    >
                      Reject Request
                    </Button>
                  </DialogActions>
                </>
              )
          }
        })()}
      </Dialog>
    </>
  )
}
