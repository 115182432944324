import React, { useEffect } from 'react'
import Legend from '../components/legend/Legend'
import { useRecoilState, useRecoilValue } from 'recoil'
import useMapLayers from '../hooks/useMapLayers'
import {
  baseLayerAtom,
  activeViewAtom,
  projectAtom,
  layerStateSelectorFamily,
  activeLeavesSortedSelector,
  leafRandomColAtomFamily,
  treeLeafSelectorFamily,
  mapErrorAtom,
} from '../recoil'
import useZoomExtents from '../hooks/useZoomExtents'
import { legendColors } from '../config'
import baseLayers from '../mapStyles'
import { ProjectTreeView, SymbologyStateEnum, TilingStateEnum } from '@riverscapes/react-common'
import LegendLayerItem from '../components/legend/LegendLayerItem'
import useViews from '../hooks/useViews'
import { BaseLayer, BoundsObj } from '../types'
import { useSnackbar } from 'notistack'
import log from 'loglevel'

const LegendConnect: React.FC = () => {
  const proj = useRecoilValue(projectAtom)
  const [mapError, setMapError] = useRecoilState(mapErrorAtom)
  const { enqueueSnackbar } = useSnackbar()
  const activeLeaves = useRecoilValue(activeLeavesSortedSelector)
  const [baseLayer, _setBaseLayer] = useRecoilState(baseLayerAtom)
  const currentView = useRecoilValue(activeViewAtom)

  const { setView } = useViews()

  const setBaseLayer = (bl: BaseLayer): void => {
    _setBaseLayer(bl)
  }

  useEffect(() => {
    if (mapError && mapError.length > 0) {
      enqueueSnackbar(mapError, { variant: 'error', autoHideDuration: 3000 })
      setMapError('')
    }
  }, [mapError])

  if (!proj) return null

  const views: ProjectTreeView[] = proj.tree?.views ? (proj.tree.views as ProjectTreeView[]) || [] : []
  const defaultView = views.length > 0 && proj.tree?.defaultView ? proj.tree.defaultView : ''
  return (
    <Legend
      views={views}
      defaultView={defaultView}
      baseLayer={baseLayer}
      baseLayers={baseLayers}
      setBaseLayer={setBaseLayer}
      viewId={currentView}
      setViewId={setView}
    >
      {activeLeaves.map(({ id }, idx) => (
        <LegendItemConnect key={`lgdLyr-${idx}`} lid={id} />
      ))}
    </Legend>
  )
}

export default LegendConnect

interface ItemProps {
  lid: number
}

const LegendItemConnect: React.FC<ItemProps> = ({ lid }: ItemProps) => {
  const leaf = useRecoilValue(treeLeafSelectorFamily(lid))
  const layer = useRecoilValue(layerStateSelectorFamily(lid))
  const rndColIdx = useRecoilValue(leafRandomColAtomFamily(lid))
  const zoomLayerExtent = useZoomExtents()
  const { removeLayer } = useMapLayers()

  const rasterStats: Record<string, unknown> = (layer.tiles?.rasterStats as Record<string, unknown>) || {}
  const stats = rasterStats.stats as Record<string, unknown>
  const { id, layerType, label } = leaf

  let max: number | undefined = undefined
  let min: number | undefined = undefined
  let subLabel: string | undefined = undefined

  if (stats) {
    try {
      max = (stats.max as number) || (stats.maximum as number) || undefined
      min = (stats.min as number) || (stats.minimum as number) || undefined
      if (max && max > 0 && min && min > 0) subLabel = `min: ${min?.toFixed(2)} max: ${max?.toFixed(2)}`
    } catch {
      log.error(`Could not get stats using object: ${JSON.stringify(stats)}`)
    }
  }
  const overZoomed = true
  return (
    <LegendLayerItem
      leafId={id}
      layerType={layerType}
      symbology={layer.symbology}
      max={max}
      min={min}
      label={label}
      subLabel={subLabel}
      overZoomed={overZoomed}
      zoomLayerExtent={() => zoomLayerExtent(layer.tiles?.bounds as BoundsObj)}
      fallbackColor={rndColIdx !== null ? legendColors[rndColIdx] : undefined}
      loading={layer.tileState === TilingStateEnum.Fetching || layer.symbologyState === SymbologyStateEnum.Fetching}
      removeLayer={removeLayer}
    />
  )
}
