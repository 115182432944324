/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { GetProjectDetailQuery } from '../../schema/base'
import { Datasets, DatasetsProps } from '../Datasets'
import { Lookups } from '../lookups'

export interface DatasetsPanelProps {
  project: NonNullable<GetProjectDetailQuery['project']>
  items: DatasetsProps['items']
  lookups: Lookups
}

export const DatasetsPanel: React.FC<DatasetsPanelProps> = ({ project, items, lookups }) => {
  return (
    <Box sx={{ height: '100%' }}>
      <Datasets project={project} items={items} lookups={lookups} />
    </Box>
  )
}
