import { useCallback, useEffect, useMemo, memo, ReactElement } from 'react'
import { atom, useRecoilState } from 'recoil'
import produce from 'immer'

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'

import { MetaData, MetaDataInput, MetaDataTypeEnum } from '../../schema/base'
import { LogicError, localStorageAtomEffect, useBooleanState, useFields, useFormats, withTooltip } from '../../lib'
import { CancelEditIcon, ClearIcon, ContentCopy, EditIcon, LockIcon, SaveEditIcon } from '../Icons'
import { MetadataTypeField } from './MetadataTypeField'
import { validateMetadata } from './validateMetadata'
import { identity, remove, uniqueId } from 'lodash'
import { Markdown } from '../Markdown'
import { MetadataKeyField } from './MetadataKeyField'
import { AddCircle, Key, Shield } from '@mui/icons-material'
import { Button, alpha, useTheme } from '@mui/material'
import { useNotifications } from '../AppNotifications'

export type MetadataTableProps = {
  value: MetaDataInput[] // using MetaDataInput vs MetaData to avoid __typename
} & (
  | {
      canEdit?: false
      onChange?: (newValue: MetaDataInput[]) => void
    }
  | {
      canEdit: true
      onChange: (newValue: MetaDataInput[]) => void
    }
)

const BadType = ({ hint, children }: { hint: string; children: React.ReactNode }) => {
  return (
    <Box sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted', textDecorationColor: 'red' }}>
      <Tooltip title={hint}>
        <span>{children}</span>
      </Tooltip>
    </Box>
  )
}

const isMultilineType = (type?: MetaDataTypeEnum | null) =>
  [MetaDataTypeEnum.Json, MetaDataTypeEnum.String, MetaDataTypeEnum.Richtext].includes(type as any)

const isNumberType = (type?: MetaDataTypeEnum | null) =>
  [MetaDataTypeEnum.Int, MetaDataTypeEnum.Float].includes(type as any)

const MetadataValue: React.FC<Pick<MetaData, 'type' | 'value'> & { numberPadStart?: number }> = ({
  type,
  value,
  numberPadStart = 0,
}) => {
  const { formatDateTime } = useFormats()

  const formatLink = (value: string) => (
    <a href={value} target="_blank" rel="noreferrer">
      {value}
    </a>
  )

  const Pre: React.FC<React.PropsWithChildren> = ({ children }) => <pre style={{ margin: 0 }}>{children}</pre>

  const formatNumber = (value: string) => {
    const [wholePart, fractionalPart] = value.split('.')
    return (
      <Pre>
        {wholePart.padStart(numberPadStart)}
        {fractionalPart && '.'}
        {fractionalPart}
      </Pre>
    )
  }

  const formatMetadata: Record<MetaDataTypeEnum, (value: string) => ReactElement> = {
    [MetaDataTypeEnum.Boolean]: (value: string) => {
      return <Pre>{value.toUpperCase()}</Pre>
    },
    [MetaDataTypeEnum.Filepath]: (value: string) => <Pre>{value}</Pre>,
    [MetaDataTypeEnum.Float]: formatNumber,
    [MetaDataTypeEnum.Guid]: (value: string) => <Pre>{value}</Pre>,
    [MetaDataTypeEnum.Hidden]: (value: string) => <Pre>{value}</Pre>,
    [MetaDataTypeEnum.Int]: formatNumber,
    [MetaDataTypeEnum.Isodate]: (value: string) => <span>{formatDateTime(value)}</span>,
    [MetaDataTypeEnum.Image]: formatLink,
    [MetaDataTypeEnum.Json]: (value: string) => <Pre>{JSON.stringify(JSON.parse(value), undefined, 2)}</Pre>,
    [MetaDataTypeEnum.Markdown]: (value: string) => <Markdown content={value} />,
    [MetaDataTypeEnum.Richtext]: (value: string) => <span>{value}</span>, // nothing special for now
    [MetaDataTypeEnum.String]: (value: string) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
    [MetaDataTypeEnum.Timestamp]: (value: string) => <span>{formatDateTime(new Date(Number(value)).toString())}</span>,
    [MetaDataTypeEnum.Url]: formatLink,
    [MetaDataTypeEnum.Hidden]: (value: string) => <Pre>{value}</Pre>,
    [MetaDataTypeEnum.Video]: formatLink,
  }

  if (type) {
    const errorMessage = validateMetadata[type](value)
    if (errorMessage) return <BadType hint={errorMessage}>{value}</BadType>
  }

  return formatMetadata[type ?? MetaDataTypeEnum.String](value)
}

type MetadataRow = MetaDataInput & { rowKey: string; unpersisted: boolean; hidden: boolean }

const EditableMetadataTableRow = memo(function EditableMetadataTableRow({
  value,
  onChange,
  onDelete,
  canDelete,
  isLast,
  errors,
}: {
  value: MetadataRow
  onChange: (newValue: MetadataRow) => void
  onDelete: (targetValue: MetadataRow) => void
  canDelete: boolean
  isLast: boolean
  errors?: {
    key: string | false
    value: string | false
  }
}) {
  const theme = useTheme()
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        '& td, & th': {
          borderTop: isLast ? `1px solid ${theme.palette.info.main}` : undefined,
          backgroundColor: isLast ? alpha(theme.palette.info.light, 0.2) : undefined,
        },
      }}
    >
      <TableCell component="th" scope="row" sx={{ maxWidth: '6rem' }}>
        {isLast && (
          <Tooltip title="Add New Metadata Row">
            <AddCircle color="info" style={{ marginLeft: '0.25em' }} />
          </Tooltip>
        )}
        <Tooltip title={value.type === MetaDataTypeEnum.Hidden ? 'System Metadata' : undefined}>
          <Shield
            style={{ marginLeft: '0.25em', opacity: value.type === MetaDataTypeEnum.Hidden ? 0.5 : 0 }}
            fontSize="small"
          />
        </Tooltip>
        <Tooltip title={value.locked ? 'Metadata Locked' : undefined}>
          <Key style={{ marginLeft: '0.25em', opacity: value.locked ? 0.5 : 0 }} fontSize="small" />
        </Tooltip>
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top', maxWidth: '6em' }}>
        <MetadataKeyField
          disabled={!!value.locked}
          value={value.key}
          onChange={(newKey) => {
            onChange({ ...value, key: newKey })
          }}
          error={errors?.key}
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top', maxWidth: '6em' }}>
        <MetadataTypeField
          value={value.type ?? null}
          disabled={!!value.locked}
          onChange={(newType) => {
            onChange({ ...value, type: newType })
          }}
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <TextField
          disabled={!!value.locked}
          multiline={isMultilineType(value.type)}
          fullWidth
          value={value.value}
          onChange={(e) => {
            onChange({ ...value, value: e.target.value })
          }}
          error={!!errors?.value}
          helperText={errors?.value}
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top', width: '1.5em' }}>
        <IconButton onClick={() => onDelete(value)} disabled={value.locked || !canDelete}>
          {value.locked ? <LockIcon /> : <ClearIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  )
})

const emptyRow = (): MetadataRow => ({
  rowKey: uniqueId(),
  unpersisted: true,
  key: '',
  type: null,
  value: '',
  hidden: false,
})

const metadataTablePrefsAtom = atom({
  key: 'metadataTablePrefsAtom',
  default: {
    showHidden: false,
  },
  effects: [localStorageAtomEffect('metadataTablePrefsAtom')],
})

export const MetadataTable: React.FC<MetadataTableProps> = ({ value, onChange, canEdit }) => {
  const theme = useTheme()
  const { pluralize } = useFormats()
  const { show } = useNotifications()

  const [isEditing, startEditing, stopEditing] = useBooleanState(false)
  const [prefs, setPrefs] = useRecoilState(metadataTablePrefsAtom)

  const { showHidden } = prefs
  const setShowHidden = (nextValue: boolean) => setPrefs({ ...prefs, showHidden: nextValue })

  const [rows, setRows, preparedRows] = useFields(
    [
      ...value.map<MetadataRow>((valueRow) => ({
        ...valueRow,
        rowKey: uniqueId(),
        unpersisted: false,
        hidden: !showHidden && valueRow.type === MetaDataTypeEnum.Hidden,
      })),
      emptyRow(),
    ],
    (rows) =>
      rows.map<MetaDataInput>(
        ({
          key,
          type,
          value,
          ext,
          locked,
          // ...rest dropped
        }) => ({ key, type, value, ext, locked })
      ),
    [value, isEditing, showHidden]
  )

  const errors = (() => {
    const rowErrors = preparedRows.map((row, index) => {
      const { unpersisted } = rows[index]
      if (unpersisted && !row.key && !row.value) return undefined

      const keyError: string | false = (() => {
        if (!row.key) return 'Key is required.'
        if (preparedRows.filter(({ key }) => key === row.key).length > 1) return 'Keys must be unique.'
        return false
      })()

      const valueError = (() => {
        const originalRow = value.find((cleanRow) => cleanRow.key === row.key)
        if (originalRow && row.type === originalRow.type && row.value === originalRow.value) return false // clean value, do not validate
        if (!row.value) return 'Value is required.'
        return !!row.type && validateMetadata[row.type](row.value)
      })()

      if (keyError || valueError)
        return {
          key: keyError,
          value: valueError,
        }

      return undefined
    })
    return rowErrors.some(identity) ? rowErrors : undefined
  })()

  useEffect(() => {
    const lastRow = rows[rows.length - 1]
    if (lastRow.key || lastRow.value)
      setRows(
        produce((draft) => {
          draft.push(emptyRow())
        })
      )
  }, [rows])

  const handleSave = () => {
    if (!onChange) throw new LogicError()
    onChange(preparedRows.filter(({ key, value }) => key && value))
    stopEditing()
  }

  const numberPadStart = useMemo(() => {
    return value
      .filter(({ type }) => isNumberType(type))
      .map(({ value }) => value.split('.')[0].length)
      .reduce((acc, numberLength) => Math.max(acc, numberLength), 0)
  }, [value])

  const handleRowChange = useCallback((updatedRow: MetadataRow) => {
    setRows(
      produce((draft) => {
        const rowIndex = draft.findIndex((draftRow) => draftRow.rowKey === updatedRow.rowKey)
        if (rowIndex === -1) throw new LogicError()
        draft[rowIndex] = updatedRow
      })
    )
  }, [])

  const handleRowDelete = useCallback((targetRow: MetadataRow) => {
    setRows(
      produce((draft) => {
        remove(draft, (row) => row.rowKey === targetRow.rowKey)
      })
    )
  }, [])

  // RENDER

  const hiddenCount = value.filter(({ type }) => type === MetaDataTypeEnum.Hidden).length

  const table = isEditing ? (
    <TableContainer component={Paper} sx={{ position: 'relative', height: '100%' }} elevation={2}>
      <Table stickyHeader>
        <TableHead
          sx={{
            '& th': {
              fontWeight: 'bold',
              color: theme.palette.warning.contrastText,
              backgroundColor: theme.palette.warning.main,
            },
          }}
        >
          <TableRow>
            <TableCell sx={{ maxWidth: '6em' }}>Status</TableCell>
            <TableCell sx={{ maxWidth: '6em' }}>Key</TableCell>
            <TableCell sx={{ maxWidth: '6em' }}>Type</TableCell>
            <TableCell colSpan={2} sx={{ verticalAlign: 'center' }}>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                Value
                <Box sx={{ marginLeft: 'auto' }}>
                  {withTooltip(
                    <IconButton onClick={stopEditing} color="inherit">
                      <CancelEditIcon />
                    </IconButton>,
                    'Discard Changes'
                  )}
                  {withTooltip(
                    <IconButton disabled={!!errors} onClick={handleSave} color="inherit">
                      <SaveEditIcon />
                    </IconButton>,
                    'Save Changes',
                    !!errors
                  )}
                </Box>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            if (row.hidden) return null // do not filter as row index is used
            return (
              <EditableMetadataTableRow
                key={row.rowKey}
                value={row}
                onChange={handleRowChange}
                onDelete={handleRowDelete}
                errors={errors?.[index]}
                canDelete={index < rows.length - 1}
                isLast={index === rows.length - 1}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer component={Paper} sx={{ height: '100%' }} elevation={2}>
      <Table stickyHeader>
        <TableHead
          sx={{
            '& th': {
              fontWeight: 'bold',
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          <TableRow>
            <TableCell sx={{ maxWidth: '6rem' }}>Status</TableCell>
            <TableCell sx={{ maxWidth: '6rem' }}>Key</TableCell>
            <TableCell sx={{ verticalAlign: 'center' }}>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                Value
                <Box sx={{ marginLeft: 'auto' }}>
                  {canEdit &&
                    withTooltip(
                      <IconButton onClick={startEditing} color="inherit">
                        <EditIcon />
                      </IconButton>,
                      'Edit MetaData'
                    )}
                </Box>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {value.length && (showHidden || value.some(({ type }) => type !== MetaDataTypeEnum.Hidden)) ? (
            value.map((row) => {
              let backgroundColor: string | undefined = undefined
              if (row.type === MetaDataTypeEnum.Hidden) backgroundColor = alpha(theme.palette.background.default, 0.8)
              else if (row.locked) backgroundColor = alpha(theme.palette.background.default, 0.4)

              if (!showHidden && row.type === MetaDataTypeEnum.Hidden) return null
              return (
                <TableRow
                  key={row.key}
                  sx={{
                    '& td, & th': {
                      backgroundColor,
                    },
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ maxWidth: '6rem' }}>
                    <Tooltip title={row.type === MetaDataTypeEnum.Hidden ? 'System Metadata' : undefined}>
                      <Shield
                        style={{ marginLeft: '0.25em', opacity: row.type === MetaDataTypeEnum.Hidden ? 0.5 : 0 }}
                        fontSize="small"
                      />
                    </Tooltip>
                    <Tooltip title={row.locked ? 'Metadata Locked' : undefined}>
                      <Key style={{ marginLeft: '0.25em', opacity: row.locked ? 0.5 : 0 }} fontSize="small" />
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.key}
                  </TableCell>
                  <TableCell>
                    <MetadataValue type={row.type} value={row.value} numberPadStart={numberPadStart} />
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell component="th" scope="row" colSpan={3}>
                <em>No metadata.</em>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Box
      sx={{
        //
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <FormControlLabel
          disabled={isEditing}
          control={<Switch disabled={isEditing} checked={showHidden} onChange={() => setShowHidden(!showHidden)} />}
          label={`Show ${hiddenCount} system metadata ${pluralize('row', hiddenCount)}`}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title={`Copy meta to clipboard (as JSON)`}>
          <Button
            startIcon={<ContentCopy />}
            sx={{}}
            color="inherit"
            aria-label="copy"
            onClick={() => {
              if (!value) return
              // Copy all metadata to clipboard
              const meta = value.map(({ key, value, ext, locked, type }) => ({ key, value, ext, locked, type }))
              const metadataString = JSON.stringify(meta, null, 2)
              navigator.clipboard.writeText(metadataString)
              show('Metadata copied to clipboard!', { variant: 'info', autoHideDuration: 1500 })
            }}
          >
            Copy as JSON
          </Button>
        </Tooltip>
      </Stack>
      {table}
    </Box>
  )
}
