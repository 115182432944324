import Satellite from './SatStreets.json'
// import Satellite from './Satellite.json'
import BasicMap from './BasicMap.json'
// import TopoMap from './TopoMap.json'
// import GrayscaleHS from './GrayscaleHS.json'
import RiverscapesTopo from './RiverscapesTopo.json'
import { BaseLayer } from '../types'
import { fixMapboxExport } from '../lib/MapManager'
import MapboxGL from 'mapbox-gl'

import {
  // Satellite as SatIcon,
  SatelliteOutlined,
  // Terrain,
  // DirectionsCar,
  DirectionsCarOutlined,
  TerrainOutlined,
} from '@mui/icons-material'

const mapStyles: BaseLayer[] = [
  {
    id: 'SATELLITE',
    title: 'Satellite Imagery',
    toolTip: 'Satellite Imagery',
    Icon: SatelliteOutlined,
    color: 'green',
    ...fixMapboxExport('SAT', Satellite as MapboxGL.Style),
  },
  {
    id: 'HILLSHADE',
    title: 'Topo',
    toolTip: 'OSM Topo Map',
    Icon: TerrainOutlined,
    color: 'blue',
    ...fixMapboxExport('HS', RiverscapesTopo as MapboxGL.Style),
  },
  {
    id: 'STREET',
    title: 'Street Map',
    toolTip: 'Street Map',
    Icon: DirectionsCarOutlined,
    color: 'blue',
    ...fixMapboxExport('ST', BasicMap as MapboxGL.Style),
  },
  // {
  //     id: 'HILLSHADE',
  //     title: 'Hillshade',
  //     toolTip: 'Hillshade',
  //     Icon: TerrainOutlined,
  //     color: 'blue',
  //     ...fixMapboxExport('HS', GrayscaleHS as MapboxGL.Style)
  // }
]

export default mapStyles
