import { GetProfileDetailQuery } from '@riverscapes/react-common'
import { useGetProfileDetailQuery } from '../data'
import { useAuth } from '@riverscapes/react-api'

const session = `?s=${Date.now()}` // to cache-bust per-session

export const useProfile = (): NonNullable<GetProfileDetailQuery['profile']> | undefined => {
  const { isAuthenticated } = useAuth()
  const { data } = useGetProfileDetailQuery({
    skip: !isAuthenticated,
  })

  const avatar = data?.profile?.avatar ? `${data?.profile?.avatar}${session}` : undefined

  if (data?.profile) {
    return {
      ...data.profile,
      avatar,
    }
  }

  return
}
