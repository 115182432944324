import React from 'react'
import { Box, Container, Paper, Typography, useTheme } from '@mui/material'
import { PageTitle, Footer, RsLogo } from '@riverscapes/react-common'
import { NotFound } from './NotFound'

export interface LoginSuccessProps extends JSX.IntrinsicAttributes {
  secret: string
}

export const LoginSuccess: React.FC<LoginSuccessProps> = ({ secret }) => {
  const theme = useTheme()
  // This is a little dump but we just need to make this URL a little obfuscated.
  // ZERO security concerns here. Just a little obfuscation.
  if (!secret || secret.length < 10) return <NotFound id="login-success" descriptor="Page" />

  return (
    <>
      <PageTitle title="Riverscapes Login Success" />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.background.default,
        }}
      >
        <Container maxWidth="md" sx={{ p: 2, height: '100%' }}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ textAlign: 'center', width: '100%' }}>
              <RsLogo size={200} />
              <Typography variant="h3" paragraph>
                Logged-in
              </Typography>
              <Typography variant="h5" paragraph>
                You are now logged into the Riverscapes Data Exchange.
              </Typography>
              <Typography variant="body1" paragraph>
                You can safely close this browser tab if you want.
              </Typography>
            </Box>
          </Paper>
        </Container>
        <Footer />
      </Box>
    </>
  )
}
