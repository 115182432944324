import React from 'react'
import { SearchSpec, GeoParams, SearchViewTypeEnum } from '@riverscapes/react-common'
import { search, useGotoRoute, webRaveDetail } from '../../routing'
import { useLookups } from '../../lib'
import { ProjectResultsContainer } from './ProjectResultsContainer'
import {
  CollectionResultsContainer,
  OrganizationResultsContainer,
  UserResultsContainer,
  SavedSearchResultsContainer,
} from './OtherResultsContainers'

export type SearchProps = JSX.IntrinsicAttributes &
  SearchSpec & {
    view?: SearchViewTypeEnum
    geo?: GeoParams
  }

export const Search: React.FC<SearchProps> = ({ page, params, type, geo, sort, view }) => {
  const gotoSearch = useGotoRoute(search)
  const gotoWebRave = useGotoRoute(webRaveDetail)

  const lookups = useLookups()

  const commonProps = {
    gotoSearch,
    lookups,
    page,
    params,
    sort: sort ?? null,
  }

  switch (type) {
    case 'Project':
      return (
        <ProjectResultsContainer
          {...commonProps}
          type={type}
          geo={geo}
          onGotoWebRave={(id: string) => gotoWebRave({ id })}
          viewType={(view as SearchViewTypeEnum) || SearchViewTypeEnum.Map}
          onViewTypeChange={(newViewType: SearchViewTypeEnum) =>
            gotoSearch(
              {
                page,
                params,
                type,
                geo,
                sort,
                view: newViewType,
              },
              true
            )
          }
        />
      )
    case 'Collection':
      return <CollectionResultsContainer {...commonProps} type={type} />
    case 'Organization':
      return <OrganizationResultsContainer {...commonProps} type={type} />
    case 'User':
      return <UserResultsContainer {...commonProps} type={type} />
    case 'SavedSearch':
      return <SavedSearchResultsContainer {...commonProps} type={type} />
  }
}
