import { Box, IconButton, PaletteColor, useTheme } from '@mui/material'
import { useBooleanState, withTooltip } from '../../lib'
import { ImageManagerDialog } from '../ImageManager'
import { Lookups } from '../lookups'
import { ImageManagerDialogProps } from '../ImageManager'
import { ImageIcon } from '../Icons'
import { AddPhotoAlternate } from '@mui/icons-material'

export interface HeroImageProps {
  className?: string
  url?: string | null
  color?: PaletteColor
}

export type HeroImageEditButtonProps = HeroImageProps & {
  lookups: Lookups
  sx?: React.CSSProperties
  onChange: (newToken: string | null) => Promise<unknown>
  getUploadTarget: ImageManagerDialogProps['getUploadTarget']
}

// TODO: what is default image?
const session = `?s=${Date.now()}` // to cache-bust per-session

export const HeroImage: React.FC<HeroImageProps> = ({ className, url, color }) => {
  const theme = useTheme()
  const finalUrl = url ? `${url}${session}` : process.env.PUBLIC_URL + '/bg/background-hero.jpg'
  return (
    <div
      className={className}
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: -1,
        background: color ? color.main : theme.palette.primary.main,
        color: color ? color.contrastText : theme.palette.primary.contrastText,
      }}
    >
      {finalUrl && <img src={finalUrl} style={{ objectFit: 'cover', height: '100%', width: '100%' }} />}
    </div>
  )
}

export interface HeroColorProps {
  color?: PaletteColor
}

export const HeroColor: React.FC<HeroColorProps> = ({ color }) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: -1,
        color: color ? color.contrastText : '#444444',
        backgroundColor: color ? color.main : '#eeeeee',
      }}
    />
  )
}

export const HeroImageEditButton: React.FC<HeroImageEditButtonProps> = ({
  sx,
  lookups,
  getUploadTarget,
  onChange,
  url,
}) => {
  const [isEditing, startEditing, stopEditing] = useBooleanState()
  return (
    <>
      {/* <ImageIcon css={{ opacity: 0.5, position: 'absolute', bottom: 0, left: -5 }} /> */}
      {withTooltip(
        <IconButton onClick={startEditing} sx={{ color: 'white', opacity: 0.5, ...(sx || {}) }}>
          <AddPhotoAlternate />
        </IconButton>,
        'Edit Header Image'
      )}
      <ImageManagerDialog
        title="Edit Header Image"
        getUploadTarget={getUploadTarget}
        open={isEditing}
        onClose={stopEditing}
        lookups={lookups}
        Preview={({ src }: { src: string }) => (
          <img src={src} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        )}
        onTokenChange={onChange}
        url={url || undefined}
      />
    </>
  )
}
