import { ProjectCardFragment } from '../schema/base'

// some metadata is special
export function getSpecialMeta(project: ProjectCardFragment): Record<string, string | null> {
  const modelVersion =
    project.meta?.find(({ key }) => key === 'ModelVersion')?.value ??
    project.meta?.find(({ key }) => key.match(/version/i))?.value

  const huc = project.meta?.find(({ key }) => key === 'HUC')?.value

  return {
    modelVersion: modelVersion ?? null,
    huc: huc ?? null,
  }
}
