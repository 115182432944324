import React from 'react'

import AppSplitter from '../AppSplitter'
import { debounce } from 'lodash'
import { Box, InputAdornment, SxProps, TextField, Theme, useTheme } from '@mui/material'
import { TreeView } from '@mui/lab'
import { ChevronRight, ExpandMore, Folder, FolderOpen, Search } from '@mui/icons-material'
// import log from 'loglevel'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  folderIcon: {
    color: theme.palette.secondary.main,
    mr: 1,
  },
  tree: {
    py: 0.5,
    px: 1,
    flexGrow: 1,
    overflow: 'scroll',
  },
  searchBox: {
    flexGrow: 0,
    p: 0.5,
    '& .MuiInputBase-input': {
      px: 0.5,
      py: 0.5,
      fontSize: '0.8rem',
    },
  },
})

interface TreeControlProps {
  lyrInfoOpen: boolean
  setSearchText: (text: string) => void
  expanded: string[]
  ctxMenu: React.ReactElement | null
  lyrInfo: React.ReactElement | null
  selected: string[]
  setExpanded: (ids: string[]) => void
  setSelected: (id: string[]) => void
  children: React.ReactNode | React.ReactNode[]
}

const TreeControl: React.FC<TreeControlProps> = ({
  lyrInfoOpen,
  setSearchText,
  expanded,
  ctxMenu,
  lyrInfo,
  selected,
  setExpanded,
  setSelected,
  children,
}: TreeControlProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const onNodeToggle = (_e, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const onNodeSelect = (_e, nodeIds: string | string[]) => {
    setSelected(Array.isArray(nodeIds) ? nodeIds : [nodeIds])
  }
  const setSearchDebounced = debounce((stext: string) => setSearchText(stext), 100)

  return (
    <AppSplitter
      open={lyrInfoOpen}
      split="horizontal"
      primary="first"
      defaultSize="60%"
      maxSize="80%"
      minSize="30%"
      child1={
        <Box sx={styles.container}>
          <TextField
            fullWidth
            sx={styles.searchBox}
            size="small"
            placeholder="Filter Project Layers..."
            variant="outlined"
            onChange={(event) => {
              setSearchDebounced((event.target.value as string).toLowerCase().trim())
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <TreeView
            sx={styles.tree}
            defaultCollapseIcon={
              <>
                <ExpandMore />
                <FolderOpen sx={styles.folderIcon} />
              </>
            }
            defaultExpandIcon={
              <>
                <ChevronRight />
                <Folder sx={styles.folderIcon} />
              </>
            }
            onNodeToggle={onNodeToggle}
            onNodeSelect={onNodeSelect}
            selected={selected}
            expanded={expanded}
          >
            {children}
          </TreeView>
          {ctxMenu}
        </Box>
      }
      child2={lyrInfo || <Box />}
    />
  )
}

export default TreeControl
