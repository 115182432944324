/** @jsxImportSource @emotion/react */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useBooleanState } from '../../lib'

export const Welcome: React.FC = () => {
  const [isOpen, _openDialog, closeDialog] = useBooleanState(true)
  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>Welcome to Riverscapes Alpha!</DialogTitle>
      <DialogContent>
        <Typography mb={2}>
          This is for demonstration purposes only and has many missing, incomplete, or buggy features.
        </Typography>
        <Typography>
          As all data will be periodically reloaded, any changes made are temporary and you are encouraged to
          experiment!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}
