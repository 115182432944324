import React from 'react'
import { Box, Tab, Tabs } from '@mui/material'

interface Tab {
  name: string
  label?: string
  content: React.ReactNode
  padding?: number
}

export interface DetailPageTabsProps {
  value: string
  onChange: (newValue: string) => void
  tabs: (Tab | false | null | undefined)[]
  scrollable?: boolean
}

const unknownTab: Tab = { name: 'unknown', content: <div /> }

export const DetailPageTabs: React.FC<DetailPageTabsProps> = ({ value, onChange, tabs, scrollable }) => {
  const currentTab = tabs.find((tab) => tab && tab.name === value) || unknownTab

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={(el, value) => onChange(value)} scrollButtons="auto" variant="scrollable">
          {tabs.map((tab) => {
            if (!tab) return null
            const { name, label } = tab
            return <Tab key={name} label={label ?? name} value={name} />
          })}
        </Tabs>
      </Box>
      <Box
        role="tabpanel"
        sx={{
          //
          flex: 1,
          // border: '2px solid yellow',
          height: scrollable ? '100%' : 0,
          overflow: scrollable ? 'hidden' : undefined,
          overflowY: scrollable ? 'scroll' : undefined,
          p: currentTab.padding ?? 2,
        }}
      >
        {currentTab.content}
      </Box>
    </>
  )
}
