import React, { useEffect, useRef } from 'react'
import { Box, SxProps, Theme, useTheme } from '@mui/material'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  wrapper: {
    color: 'white',
    backgroundColor: 'rgb(0,18,88)', // fallback so we can still see something while loading
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
  },
})

// In order to use the Freshworks widget, we need to declare the global
declare global {
  interface Window {
    fwSettings: {
      widget_id: number
      locale: 'en'
      // Add other properties if needed
    }
    FreshworksWidget: (...args: any[]) => void
  }
}

export const HelpWidget: React.FC = () => {
  const theme = useTheme()
  const boxRef = useRef<HTMLDivElement>(null) // Create a ref for the <Box> element
  const styles = stylesThunk(theme)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  // const social = data.site.siteMetadata?.social
  const helpWidgetId = '153000000178'

  useEffect(() => {
    if (!helpWidgetId) return
    // Configure Freshworks widget settings
    window.fwSettings = {
      widget_id: 153000000178,
      locale: 'en',
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://widget.freshworks.com/widgets/${helpWidgetId}.js`
    script.async = true
    script.defer = true

    // Append the script to the DOM element inside the <Box>
    if (boxRef.current) {
      boxRef.current.appendChild(script)
    }

    return () => {
      // Clean up the script when the component is unmounted
      if (boxRef.current) {
        boxRef.current.removeChild(script)
      }
      // Destroy the Freshworks widget
      if (window.FreshworksWidget) {
        window.FreshworksWidget('destroy')
      }
    }
  }, [helpWidgetId]) // Empty dependency array means this effect runs once after the initial render

  return <Box ref={boxRef} />
}
