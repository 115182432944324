import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import { GetProfileDetailQuery, GetUserDetailQuery } from '../../schema/base'
import { Lookups } from '../lookups'
import { ItemRepresentation } from '../ItemImage'
import { StarredModesEnum } from '../Stars/StarredFilters'
import { useStarredFilters } from '../Stars/useStarredFilters'
import { AllMyStars } from '../../types'
import { useTheme } from '@mui/system'

export type StarredPanelProps = {
  lookups: Lookups
  allStarred: AllMyStars
  user: NonNullable<GetUserDetailQuery['user']> | NonNullable<GetProfileDetailQuery['profile']>
}

export const StarredPanel: React.FC<StarredPanelProps> = ({ lookups, allStarred }) => {
  const theme = useTheme()
  const { filterControl, filteredItems, activeFilter } = useStarredFilters(allStarred)
  return (
    <>
      <Stack sx={{ height: '100%', gap: 1 }}>
        {filterControl}
        <List dense>
          {filteredItems.map((item, idx) => (
            <Box
              key={`${item.id}-${idx}`}
              sx={{ display: 'flex', backgroundColor: idx % 2 === 0 ? undefined : theme.palette.grey[100] }}
            >
              <ListItem key={`${item.id}-${idx}`}>
                <ItemRepresentation item={item} lookups={lookups} link />
              </ListItem>
            </Box>
          ))}
          {filteredItems.length === 0 && (
            <Typography variant="caption" component="div" sx={{ fontStyle: 'italic', textAlign: 'center', py: 2 }}>
              You have no bookmarked{' '}
              {activeFilter === StarredModesEnum.ALL ? 'items' : activeFilter.toLocaleLowerCase()}
            </Typography>
          )}
        </List>
      </Stack>
    </>
  )
}
