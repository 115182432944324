/** @jsxImportSource @emotion/react */
import { TextField } from '@mui/material'
export interface MetadataKeyFieldProps {
  value: string
  disabled?: boolean
  onChange: (newValue: string) => void
  error?: string | false
}

export const MetadataKeyField: React.FC<MetadataKeyFieldProps> = ({ value, disabled, onChange, error }) => {
  return (
    <TextField
      disabled={disabled}
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={!!error}
      helperText={error}
    />
  )
}
