// import log from 'loglevel'
import xPathLib from 'xpath'
import { DOMParser } from '@xmldom/xmldom'
import { ProjectTreeLayerTypeEnum, ProjectTreeView } from '../gen/schema.types'
import { BusinessLogicInterface, BusinessLogicNode } from '../types'
import { getChildNodeText, getNodeAttrDict } from '../lib/xml'

export class BusinessLogic implements BusinessLogicInterface {
  private dom: any
  name: string
  description?: string
  projectType: string
  node: BusinessLogicNode
  defaultView?: string
  views?: ProjectTreeView[]
  // TODO: Implement
  leaves?: any
  branches?: any
  private projectNode: any

  /**
   * The constructor is parsing what xml2js give us
   */
  constructor(projectType: string, xmlRaw: string) {
    // These will get populated by parseNode
    this.projectType = projectType
    this.dom = new DOMParser().parseFromString(xmlRaw, 'text/xml')
    this.parse()
  }

  private parse = (): void => {
    this.projectNode = xPathLib.select1('Project', this.dom) as Node
    this.name = getChildNodeText('Name', this.projectNode)
    this.description = getChildNodeText('Description', this.projectNode)
    this.projectType = getChildNodeText('ProjectType', this.projectNode)
    // These will get populated by parseNode
    this.node = this.parseNode(xPathLib.select1('Node', this.projectNode) as Element)
    this.views = this.parseViews()
    this.defaultView = this.getDefaultView()
  }

  /**
   * Converting businessLogic XML to Typescript is pretty tedious
   * @param inNode
   * @param tileService
   * @param isRepeater
   * @returns
   */
  parseNode = (inNode: Element, isRepeater = false): BusinessLogicNode => {
    const { id, label, xpath, xpathlabel, symbology, type: datatype, transparency } = getNodeAttrDict(inNode)
    const blnode: BusinessLogicNode = {
      id,
      label,
      xpath,
      xpathlabel,
      symbology,
      isRepeater,
    }
    // Note we changed this enumeration to lowercase so let's add a little extra checking here
    if (datatype) {
      blnode.datatype = Object.values(ProjectTreeLayerTypeEnum).find((val) => val === datatype.toUpperCase())
    }
    if (transparency) blnode.transparency = parseInt(transparency, 10)
    const children = xPathLib.select1('Children', inNode) as Element
    const tileService = getChildNodeText('Tileservice', inNode)
    if (tileService && tileService.length > 0) blnode.tileservice = tileService
    if (children) {
      const childrenAttr = getNodeAttrDict(children)
      const nodes = xPathLib.select('Node', children) as Element[]
      blnode.collapsed = Boolean(
        childrenAttr && childrenAttr.collapsed && childrenAttr.collapsed.toLowerCase() === 'true'
      )
      const repeaters = xPathLib.select('Repeater', children as Element) as Element[]

      blnode.children = [
        ...nodes.map((n) => this.parseNode(n, false)),
        ...repeaters.map((n) => this.parseNode(n, true)),
      ]
    }
    // Repeaters don't have explicit children
    else if (isRepeater) {
      blnode.children = [this.parseNode(xPathLib.select1('Node', inNode) as Element)]
    }
    // clean up any undefined values
    for (const i in blnode) {
      if (typeof blnode[i] === 'undefined') {
        delete blnode[i]
      }
    }
    return blnode
  }

  /**
   * Extract the default view from the <Views> Node
   * @param viewsNode
   * @returns
   */
  getDefaultView = (): string => {
    const viewsNode = xPathLib.select1('Views', this.projectNode) as Element
    if (!viewsNode) return
    let defaultViewId = viewsNode.getAttribute('default')
    if (!defaultViewId) {
      const firstNode = xPathLib.select1('View', viewsNode) as Element
      defaultViewId = firstNode && firstNode.getAttribute('id')
    }
    return defaultViewId
  }

  getSymbologies = (): [string, string][] => {
    // Get all nodes with a symbology attribute
    const nodeElements = this.dom.getElementsByTagName('Node').filter((node) => node.getAttribute('symbology'))
    const flatList: [string, string][] = []

    for (let i = 0; i < nodeElements.length; i++) {
      const node = nodeElements[i]
      if (node.getAttribute('symbology') === 'none' || node.getAttribute('symbology').trim().length === 0) continue

      const parentXPaths = []
      let parent = node.parentNode
      while (parent && parent.tagName !== 'Document') {
        if (parent.getAttribute && parent.getAttribute('xpath')) parentXPaths.unshift(parent.getAttribute('xpath'))
        parent = parent.parentNode
      }
      if (node.getAttribute('xpath')) parentXPaths.push(node.getAttribute('xpath'))

      const symbology = node.getAttribute('symbology')
      flatList.push([parentXPaths.join('/'), symbology])
    }

    return flatList
  }

  /**
   * Pull the <Views> out of the businesslogic XML and parse it
   * @param viewNodes
   * @returns
   */
  parseViews = (): ProjectTreeView[] => {
    const viewNodes = xPathLib.select('Views/View', this.projectNode)
    return viewNodes.map((vNode: Element) => {
      return {
        id: vNode.getAttribute('id'),
        name: vNode.getAttribute('name'),
        description: getChildNodeText('Description', vNode),
        layers: xPathLib.select('Layers/Layer', vNode).map((lyrNode: Element) => {
          const attrs = getNodeAttrDict(lyrNode)
          return {
            id: attrs.id,
            visible: !(attrs.visible && attrs.visible.toLowerCase() === 'false'),
          }
        }),
      }
    })
  }
}
