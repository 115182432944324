import React from 'react'

import TreeNodeLabel from './TreeNodeLabel'
import { LayerIcon } from '@riverscapes/react-common'
import { Box, CircularProgress, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { TreeItem } from '@mui/lab'
import { ProjectTreeLayerTileTypes, ProjectTreeLayerTypeEnum, TilingStateEnum } from '@riverscapes/react-common'
// import log from 'loglevel'

export interface TreeLeafProps {
  id: number // This is the leaf id
  layerType: ProjectTreeLayerTypeEnum
  label: string
  tilesState: TilingStateEnum
  active?: boolean
  hidden?: boolean
  searchText?: string
  handleContext: (lid: number, mousex: number, mousey: number) => void
  handleClick: (lid: number) => void
  handleDoubleClick: (lid: number) => void
}
// const FADE_TIME = 500

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  leaf: {
    cursor: 'context-menu',
    ml: theme.spacing(1),
  },
  active: {
    fontWeight: 'bold',
  },
  treeLeafLabel: {
    display: 'flex',
  },
  innerLabel: {
    fontSize: '0.8rem',
    flexGrow: 0,
  },
  spacer: {
    flexGrow: 1,
  },
  metaIcon: {
    margin: theme.spacing(0.25),
    opacity: 0.2,
    fontSize: theme.spacing(2),
    lineHeight: '1rem',
  },
  loadingSpinner: {
    color: theme.palette.text.secondary,
  },
  tileStatus: {
    flexGrow: 0,
    fontStyle: 'italic',
  },
  leafQUEUED: {
    color: '#999999',
    fontStyle: 'italic',
  },
  leafNO_GEOMETRIES: {
    color: '#666666',
  },
  leafCREATING: {
    color: 'orange',
  },
  leafTILING_ERROR: {
    color: 'red',
    fontStyle: 'italic',
  },
  leafTIMEOUT: {
    color: '#999999',
    fontStyle: 'italic',
  },
  leafFETCH_ERROR: {
    color: 'red',
    fontStyle: 'italic',
  },
  leafNOT_FOUND: {
    color: 'red',
    fontStyle: 'italic',
  },
  leafINDEX_NOT_FOUND: {
    color: 'red',
    fontStyle: 'italic',
  },
  leafLAYER_NOT_FOUND: {
    color: 'red',
    fontStyle: 'italic',
  },
  leafUNKNOWN: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  },
  leafFETCHING: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  },
  leafSUCCESS: {},
})

export const TreeLeaf: React.FC<TreeLeafProps> = ({
  id,
  layerType,
  label,
  active,
  hidden,
  searchText,
  tilesState,
  handleContext,
  handleClick,
  handleDoubleClick,
}: TreeLeafProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const onCtxMenu = (event) => {
    event.preventDefault()
    handleContext(id, event.clientX - 2, event.clientY - 4)
  }
  let tileStatusStr: React.ReactElement | string | null = null
  if (ProjectTreeLayerTileTypes.indexOf(layerType) > -1 || layerType === ProjectTreeLayerTypeEnum.Report) {
    switch (tilesState) {
      case TilingStateEnum.Success:
        // tileStatusStr = 'Available'
        break
      case TilingStateEnum.Queued:
        tileStatusStr = 'Queued for tiling...'
        break
      case TilingStateEnum.Creating:
        tileStatusStr = 'Building tiles...'
        break
      case TilingStateEnum.TilingError:
        tileStatusStr = 'Tiling error'
        break
      case TilingStateEnum.Timeout:
        tileStatusStr = 'Tile timeout'
        break
      case TilingStateEnum.IndexNotFound:
        tileStatusStr = 'Missing tiles'
        break
      case TilingStateEnum.NoGeometries:
        tileStatusStr = 'Empty geometry'
        break
      case TilingStateEnum.LayerNotFound:
        tileStatusStr = 'Layer missing'
        break
      case TilingStateEnum.FetchError:
        tileStatusStr = 'Fetch error'
        break
      case TilingStateEnum.Unknown:
        tileStatusStr = <CircularProgress sx={styles.loadingSpinner} color="inherit" thickness={3} size={15} />
        break
      case TilingStateEnum.Fetching:
        tileStatusStr = <CircularProgress sx={styles.loadingSpinner} color="inherit" thickness={3} size={15} />
        break
      default:
        break
    }
  }

  const activeLeafState = active ? (styles[`leaf${tilesState}`] as SxProps<Theme>) : {}
  const leafSx = { ...styles.leaf, ...activeLeafState }

  const labelSx = { ...styles.innerLabel, ...((active && styles.active) || {}) }

  return (
    <TreeItem
      // this is IMPORTANT! 'l-##' denotes a branch
      nodeId={`l-${id}`}
      sx={leafSx}
      hidden={hidden}
      onClick={() => handleClick(id)}
      onDoubleClick={() => handleDoubleClick(id)}
      label={
        <Box sx={styles.treeLeafLabel}>
          <Typography variant="body2" color="inherit" sx={labelSx}>
            <TreeNodeLabel text={label} query={searchText} />
          </Typography>
          <Box sx={styles.spacer} />
          {tileStatusStr && (
            <Typography variant="caption" color="inherit" sx={labelSx}>
              {tileStatusStr}
            </Typography>
          )}
        </Box>
      }
      onContextMenu={onCtxMenu}
      icon={<LayerIcon layerType={layerType} />}
    />
  )
}

export default TreeLeaf
