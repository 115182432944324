import { useMemo, useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { SearchSortEnum } from '../../schema/base'
import { MenuButtonIcon } from '../Icons'

export interface SortOrderFieldProps {
  sort: SearchSortEnum | null
  onSortChange: (newValue: SearchSortEnum | null) => void
}

const options = [
  {
    optionLabel: 'Score (Default)',
    selectedLabel: 'Score (Default)',
    value: null,
  },
  // {
  //   optionLabel: 'My items first',
  //   selectedLabel: 'My items',
  //   value: SearchSortEnum.Mine,
  // }, // TODO: disable until supported
  {
    optionLabel: 'Alphabetical (A-Z)',
    selectedLabel: 'A-Z',
    value: SearchSortEnum.NameAsc,
  },
  {
    optionLabel: 'Reverse Alphabetical (Z-A)',
    selectedLabel: 'Z-A',
    value: SearchSortEnum.NameDesc,
  },
  {
    // TODO: We're setting this as default even though it's not the default in the API
    optionLabel: 'Created date (newest first) (Default)',
    selectedLabel: 'Newest (Default)',
    value: SearchSortEnum.DateCreatedDesc,
  },
  {
    optionLabel: 'Created date (oldest first)',
    selectedLabel: 'Oldest',
    value: SearchSortEnum.DateCreatedAsc,
  },
  {
    optionLabel: 'Updated date (newest first)',
    selectedLabel: 'Recently updated',
    value: SearchSortEnum.DateUpdatedDesc,
  },
  {
    optionLabel: 'Updated date (oldest first)',
    selectedLabel: 'Least recently updated',
    value: SearchSortEnum.DateUpdatedAsc,
  },
]

export const SortOrderField: React.FC<SortOrderFieldProps> = ({ onSortChange, sort }) => {
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(null)
  const handleSortMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortMenuAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setSortMenuAnchorEl(null)
  }

  const selectedLabel = useMemo(() => {
    const selected = options.find((option) => option.value === sort)
    return (
      <span>
        Sort By:&nbsp;<strong>{selected?.selectedLabel ?? selected?.optionLabel}</strong>
      </span>
    )
  }, [sort])

  return (
    <>
      <Button variant="outlined" size="small" onClick={handleSortMenuClick} endIcon={<MenuButtonIcon />}>
        {selectedLabel}
      </Button>
      <Menu
        anchorEl={sortMenuAnchorEl}
        open={!!sortMenuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map(({ optionLabel, value: optionValue }) => (
          <MenuItem
            key={optionValue}
            selected={sort === optionValue}
            onClick={() => {
              onSortChange(optionValue)
              handleClose()
            }}
          >
            {optionLabel}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
