import { PendingItem } from './components/PendingItem'
import {
  CollectionCardFragment,
  OrganizationCardFragment,
  ProjectCardFragment,
  ProjectTreeLayerTypeEnum,
  UserCardFragment,
} from './schema/base'

export type PendingZips = { [key: string]: number }

export const ProjectTreeLayerTileTypes: ProjectTreeLayerTypeEnum[] = [
  ProjectTreeLayerTypeEnum.Raster,
  ProjectTreeLayerTypeEnum.Polygon,
  ProjectTreeLayerTypeEnum.Line,
  ProjectTreeLayerTypeEnum.Point,
  ProjectTreeLayerTypeEnum.Report,
]

export type AnyStarItem =
  | PendingItem
  | ProjectCardFragment
  | CollectionCardFragment
  | OrganizationCardFragment
  | UserCardFragment

export type AllMyStars = {
  projects: (PendingItem | ProjectCardFragment)[]
  collections: (PendingItem | CollectionCardFragment)[]
  organizations: (PendingItem | OrganizationCardFragment)[]
  users: (PendingItem | UserCardFragment)[]
  loading: boolean
}
