import { Box, CardContent, CardHeader, Typography, useTheme } from '@mui/material'

import { HomeCard } from './HomeCard'
import { AllMyStars } from '../../types'
import { Lookups } from '../lookups'
import { ItemRepresentation } from '../ItemImage'
import React from 'react'
import { useStarredFilters } from '../Stars/useStarredFilters'
import { StarredModesEnum } from '../Stars/StarredFilters'
import { Bookmark } from '@mui/icons-material'

export interface BookmarkedCardProps {
  allBookmarked: AllMyStars
  lookups: Lookups
}

export const BookmarkedCard: React.FC<BookmarkedCardProps> = ({ allBookmarked, lookups }) => {
  const theme = useTheme()
  const { filterControl, filteredItems, activeFilter, totalItems } = useStarredFilters(allBookmarked)

  if (totalItems === 0) return null
  return (
    <HomeCard gridWidths={2}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Bookmark sx={{ mr: 1 }} /> Bookmarked Items
          </Box>
        }
      />

      <CardContent
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {filterControl}
        <Box
          sx={{
            flex: 1,
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          {filteredItems.map((item, idx) => (
            <Box
              key={`${item.id}-${idx}`}
              sx={{ display: 'flex', backgroundColor: idx % 2 === 0 ? undefined : theme.palette.grey[100] }}
            >
              <ItemRepresentation item={item} lookups={lookups} link />
            </Box>
          ))}
          {filteredItems.length === 0 && (
            <Typography variant="caption" component="div" sx={{ fontStyle: 'italic', textAlign: 'center', py: 2 }}>
              You have no bookmarked{' '}
              {activeFilter && activeFilter === StarredModesEnum.ALL ? 'items' : activeFilter.toLocaleLowerCase()}
            </Typography>
          )}
        </Box>
      </CardContent>
    </HomeCard>
  )
}
