import { Header } from './Header'
import { MetadataPanel } from './MetadataPanel'
import { OverviewPanel } from './OverviewPanel'
import { ProjectsPanel } from './ProjectsPanel'
import { SettingsPanel } from './SettingsPanel'
import { RemoveProjectDialog } from './RemoveProjectDialog'

const CollectionDetail = {
  Header,
  MetadataPanel,
  OverviewPanel,
  ProjectsPanel,
  SettingsPanel,
}
export { CollectionDetail, RemoveProjectDialog }
