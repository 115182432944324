import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import numeral from 'numeral'
import { useMemo } from 'react'
dayjs.extend(localizedFormat)

interface UseFormatsReturn {
  formatBytes: (bytes: number) => string
  formatDateTime: (dateTime: string) => string
  formatDate: (dateTime: string, showTimezone?: boolean) => string
  formatNumber: (number: number) => string
  formatTimeAgo: (dateTime: string) => string
  pluralize: (word: string, qty?: number, includeQty?: boolean) => string
}

const plurals: Record<string, string | false> = {
  search: 'searches',
  'saved search': 'saved searches',
}

const getBrowserTimezoneCode = () => {
  try {
    const offset = new Date().getTimezoneOffset()
    const o = Math.abs(offset)
    return ` (${offset < 0 ? '+' : '-'}${('00' + Math.floor(o / 60)).slice(-2)}:${('00' + (o % 60)).slice(-2)} ${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    })`
  } catch (e) {
    return ''
  }
}

export const useFormats = (): UseFormatsReturn =>
  useMemo(() => {
    const formatBytes = (bytes: number) => {
      // use numeral to format bytes
      return numeral(bytes).format('0.0 b')
      // if (bytes < 1000) return '0 MB'
      // if (bytes < 1000000) return `<1 MB`
      // return `${numeral(bytes / 1000000).format('0.0')} MB`
    }
    const formatDateTime = (dateTime: string) => {
      return dayjs(dateTime).format('L LT') + getBrowserTimezoneCode()
    }
    const formatDate = (dateTime: string, showTimezone?: boolean) => {
      return dayjs(dateTime).format('L') + (showTimezone ? getBrowserTimezoneCode() : '')
    }
    const formatNumber = (number: number) => number.toLocaleString()
    const formatTimeAgo = (dateTime: string) => dayjs(dateTime).fromNow()
    const pluralize = (word: string, qty?: number, includeQty?: boolean): string => {
      const dictionaryPlural = plurals[word]
      const modifiedWord =
        (typeof qty === 'number' && qty === 1) || dictionaryPlural === false ? word : dictionaryPlural ?? `${word}s`
      const displayQty = (includeQty && typeof qty === 'number' && `${formatNumber(qty)} `) || ''
      return `${displayQty}${modifiedWord}`
    }

    return {
      formatBytes,
      formatNumber,
      formatDate,
      formatDateTime,
      formatTimeAgo,
      pluralize,
    }
  }, [])
