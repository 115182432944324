import { CollectionsPanel } from './CollectionsPanel'
import { DatasetsPanel } from './DatasetsPanel'
import { Header } from './Header'
import { MetadataPanel } from './MetadataPanel'
import { OverviewPanel } from './OverviewPanel'
import { QaPanel } from './QaPanel'
import { SettingsPanel } from './SettingsPanel'
import { ProjectDetailSkeleton } from './ProjectDetailSkeleton'

const ProjectDetail = { CollectionsPanel, DatasetsPanel, Header, MetadataPanel, OverviewPanel, QaPanel, SettingsPanel }

export { ProjectDetail, ProjectDetailSkeleton }
