import { ReactElement } from 'react'
import MuiTooltip from '@mui/material/Tooltip'

export const withTooltip = (
  content: ReactElement,
  tooltip: string | React.ReactNode | undefined,
  disabled?: boolean
): ReactElement => {
  if (!tooltip) return content
  if (disabled)
    return (
      <MuiTooltip title={tooltip} PopperProps={{ sx: { pointerEvents: 'none' } }}>
        <span>{content}</span>
      </MuiTooltip>
    )
  return (
    <MuiTooltip title={tooltip} PopperProps={{ sx: { pointerEvents: 'none' } }}>
      {content}
    </MuiTooltip>
  )
}
