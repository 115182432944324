import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme } from '@mui/material'

import { useAppVersion } from '../lib'
import { RsLogo } from '@riverscapes/react-common'

interface AboutDialogProps {
  open: boolean
  onClose: () => void
}

export const AboutDialog: React.FC<AboutDialogProps> = ({ open, onClose }) => {
  const theme = useTheme()
  const { version, commit } = useAppVersion()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(${process.env.PUBLIC_URL + '/banner.jpg'})`,
          backgroundPosition: '80% 100%',
          backgroundSize: 'cover',
          padding: 2,
          gap: 2,
        }}
      >
        <Stack direction="row" alignItems="center" sx={{}}>
          <RsLogo size={40} />
          <img
            src={`${process.env.PUBLIC_URL + '/data-exchange-title-white.svg'}`}
            style={{ height: 18, marginLeft: 20 }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography paragraph sx={{ mt: 2 }}>
          The RC Data Exchange is a free portal where riverscape practitioners can exchange data related to riverscapes.
          Our goal is to make it easier to catalogue, discover, share and retrieve data related to riverscapes health.
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            border: '1px solid',
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            p: 2,
            m: 2,
            fontFamily: 'monospace',
          }}
        >
          Version info: {version}
          {commit && ` [${commit}]`}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center' }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
