import { Box, Container, Skeleton, Stack, Typography } from '@mui/material'

export const ProjectDetailSkeleton: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Stack>
        <Box height={190}>
          <Stack justifyContent="center" p={2} spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Skeleton width={60} height={60} variant="rectangular" />
              <Typography variant="h2">
                <Skeleton width="15em" />
              </Typography>
            </Stack>
            <Stack>
              <Skeleton width="30em" />
              <Skeleton width="30em" />
              <Skeleton width="30em" />
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Skeleton width="100%" height={40} variant="rectangular" />
        </Box>
        <Box p={2}>
          <Skeleton width="100%" height={200} variant="rectangular" />
        </Box>
      </Stack>
    </Container>
  )
}
