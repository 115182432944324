import React from 'react'
import {
  Stack,
  Typography,
  Card,
  CardContent,
  Link,
  useTheme,
  CardHeader,
  useMediaQuery,
  Box,
  Button,
} from '@mui/material'
import { ProjectGroupVisibilityEnum } from '@riverscapes/common'

import { GetCollectionDetailQuery } from '../../schema/base'
import { EditableTypography } from '../EditableTypography'
import { useFormats, validate } from '../../lib'
import { TagChips } from '../Tags'
import { Lookups } from '../lookups'
import { ItemRepresentation } from '../ItemImage'
import { VisibilityChip } from '../Visibility'
import { CSSProperties } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { OverviewCard } from '../Overview/OverviewCard'
import { OverviewBasicProperties } from '../Overview/OverviewBasicProperties'
import { useDefaultCollectionCitation } from '../ProjectDetail/defaultCitation'
import { CopyIcon } from '../Icons'
import { useNotifications } from '../AppNotifications'

export interface OverviewPanelProps {
  collection: NonNullable<GetCollectionDetailQuery['collection']>
  onDescriptionChange: (newDescription: string) => Promise<unknown>
  onCitationChange: (newCitation: string) => Promise<unknown>
  onTagsChange: (newValue: string[]) => void
  onGotoTagSearch: (tag: string) => void
  onVisibilityChange: (newValue: ProjectGroupVisibilityEnum) => void
  lookups: Lookups
}

export const OverviewPanel: React.FC<OverviewPanelProps> = ({
  collection,
  onDescriptionChange,
  onCitationChange,
  onVisibilityChange,
  onGotoTagSearch,
  onTagsChange,
  lookups,
}) => {
  const theme = useTheme()
  const { show } = useNotifications()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [citationText, setCitationText] = React.useState<string>('')
  const copyRef = React.useRef<HTMLDivElement>(null)
  const canEdit = Boolean(collection.permissions.update)
  const { formatDateTime, formatNumber, pluralize } = useFormats()
  const defaultCitation = useDefaultCollectionCitation({
    collection,
    getCollectionUrlById: lookups.getCollectionUrlById,
    getUserUrlById: lookups.getUserUrlById,
  })

  const collectionProjectsUrl = lookups.getCollectionUrlById(collection.id, 'projects')
  const hasCitation = collection.citation && collection.citation.trim().length > 0
  const citation: string = hasCitation ? (collection.citation as string) : defaultCitation.markdown

  React.useEffect(() => {
    if (copyRef.current) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(copyRef.current.innerHTML, 'text/html')
      const text = doc.body.textContent || ''
      setCitationText(text)
    }
  }, [citation])

  const scrollContainer: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
      }
    : {}
  const scrollObjInner: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
      }
    : {}

  return (
    <Grid2 container spacing={3} sx={scrollContainer}>
      <Grid2 xs={12} sm={12} md={8} sx={scrollObjInner}>
        <OverviewCard title="Basic Properties">
          <OverviewBasicProperties
            propTable={[
              { label: 'Collection Name', value: collection.name },
              {
                label: 'Visibility',
                value: (
                  <VisibilityChip
                    canEdit={canEdit}
                    type="Collection"
                    value={collection.visibility}
                    onChange={onVisibilityChange}
                  />
                ),
              },
              {
                label: 'Owned By',
                value: (
                  <ItemRepresentation
                    lookups={lookups}
                    item={collection.ownedBy}
                    link
                    tooltipPrefix="Collection owned by:"
                  />
                ),
              },
              {
                label: 'Created by',
                value: (
                  <ItemRepresentation
                    lookups={lookups}
                    item={collection.createdBy}
                    link
                    tooltipPrefix="Collection created by:"
                  />
                ),
              },
              {
                label: 'Updated by',
                value: (
                  <ItemRepresentation
                    lookups={lookups}
                    item={collection.updatedBy}
                    link
                    tooltipPrefix="Collection updated by:"
                  />
                ),
              },
            ]}
          />
        </OverviewCard>
        <OverviewCard title="Tags">
          <TagChips
            value={collection.tags}
            canEdit={canEdit}
            gotoTagSearch={onGotoTagSearch}
            onChange={onTagsChange}
            noTags={<em>No tags.</em>}
          />
        </OverviewCard>
        <OverviewCard title="Citation">
          <Box ref={copyRef} sx={{ width: '100%', display: 'flex' }}>
            <EditableTypography
              canEdit={canEdit}
              content={citation || ''}
              getError={validate.citation}
              multiline
              name="Citation"
              typographySx={{
                fontFamily: 'Roboto Mono, Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
                whiteSpace: 'pre-line',
                fontSize: '0.7rem',
                borderRadius: 2,
                px: 1,
                py: 1,
                backgroundColor: '#f5f5f5',
                border: '1px solid #AAA',
              }}
              onChange={onCitationChange}
              placeholder={'No citation.'}
              variant="markdown"
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              {hasCitation
                ? 'Note: This is a custom citation created by the project owner'
                : 'Note: This is a default citation created by the Riverscapes Data Exchange.'}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={<CopyIcon />}
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(citation)
                show('Copied to clipboard as Markdown.', {
                  variant: 'info',
                  autoHideDuration: 2000,
                })
              }}
            >
              Markdown
            </Button>
            <Button
              startIcon={<CopyIcon />}
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(citationText)
                show('Copied to clipboard as plain text.', {
                  variant: 'info',
                  autoHideDuration: 2000,
                })
              }}
            >
              TXT
            </Button>
          </Stack>
        </OverviewCard>
        <OverviewCard title="Description">
          <EditableTypography
            canEdit={canEdit}
            content={collection.description}
            getError={validate.description}
            multiline
            name="Description"
            onChange={onDescriptionChange}
            placeholder={'No description.'}
            variant="markdown"
          />
        </OverviewCard>
      </Grid2>
      <Grid2 xs={12} sm={12} md={4}>
        <Card>
          <CardContent sx={{ pt: 3 }}>
            <Link href={collectionProjectsUrl}>
              <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
                <Typography variant="overline">Projects in collection</Typography>
                <Typography variant="h3">{formatNumber(collection.projects.total)}</Typography>
                {/* <Typography variant="overline">{pluralize('project', collection.projects.total)}</Typography> */}
              </Stack>
            </Link>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  )
}
