import { atom, atomFamily, selector, selectorFamily, noWait } from 'recoil'
import {
  ProjectTreeLeaf,
  TileService,
  Symbology,
  SymbologyStateEnum,
  TilingStateEnum,
  ProjectTreeLayerTileTypes,
  ProjectTreeLayerTypeEnum,
} from '@riverscapes/react-common'
// import log from 'loglevel'
import { projectAtom, activeIdsAtom } from './project'
import { treeLeafSelectorFamily, treeSelectedSelector } from './tree'
import { tilesAtomFamily } from './map'
import { legendColors } from '../config'
import { symbologyAtomFamily, symbologyChangeCounterAtomFamily } from './symbology'
import { MetaData } from '@riverscapes/react-common'
import { DSProjectRef } from '../types'

export const assignedRandomColorsAtom = atom<number[]>({
  key: 'map/assignedColors',
  default: [],
})
export const leafRandomColAtomFamily = atomFamily<number | null, number>({
  key: 'map/leafRandomCol',
  default: null,
})
export const selectNextColor = selector<number>({
  key: 'map/selectNextColor',
  get: ({ get }) => {
    const current = get(assignedRandomColorsAtom)
    // Little sloppy here but after all the colours are used up we just get the same color over and over
    // This shouldn't be an issue because we can't have more than 5 map layers active at a time usually.
    for (let idx = 0; idx < legendColors.length; idx++) {
      if (current.indexOf(idx) < 0) return idx
    }
    return 0
  },
})

/**
 * Layers are derivative state objects with things that are commonly grouped together
 * THis is used PRETTY MUCH EVERYWHERE
 */

export type MapLayerState = {
  leaf: ProjectTreeLeaf
  tiles?: TileService
  // Symbology can be null for rasters
  symbology?: Symbology | null
  symbologyCounter?: number
  treeSelected: boolean
  legendActive: boolean
  renderable: boolean
  dsMeta: MetaData[]
  dsProjectRef?: DSProjectRef | null
  symbologyState: SymbologyStateEnum
  tileState: TilingStateEnum
}
export const layerStateSelectorFamily = selectorFamily<MapLayerState, number>({
  key: 'map/layers',
  get:
    (leafid) =>
    ({ get }): MapLayerState => {
      const leaf = get(treeLeafSelectorFamily(leafid)) as ProjectTreeLeaf
      const selectedIds = get(treeSelectedSelector)
      const project = get(projectAtom)

      const isMapAddable = ProjectTreeLayerTileTypes.indexOf(leaf.layerType) > -1
      const isOnTileServer = isMapAddable || leaf.layerType === ProjectTreeLayerTypeEnum.Report

      // It can be active on the legend without being renderable
      const legendActive = get(activeIdsAtom).indexOf(leafid) > -1

      const treeSelected = Boolean(selectedIds.leaves && selectedIds.leaves.indexOf(leafid) > -1)
      const ds = project?.datasets.items.find(({ rsXPath }) => rsXPath === leaf.rsXPath)
      const dsMeta = ds?.meta || []
      const dsProjectRef = ds?.refProject
      const retVal: MapLayerState = {
        leaf,
        treeSelected,
        legendActive,
        renderable: false,
        dsMeta,
        dsProjectRef,
        tileState: TilingStateEnum.NotApplicable,
        symbologyState: SymbologyStateEnum.NotApplicable,
      }

      if (isOnTileServer) {
        const tileServLoadable = get(noWait(tilesAtomFamily(leaf.rsXPath)))
        if (isMapAddable) {
          const symbologyLoadable = get(
            noWait(symbologyAtomFamily([leaf.symbology as string, leaf.layerType === ProjectTreeLayerTypeEnum.Raster]))
          )
          const symbologyCounter = get(
            symbologyChangeCounterAtomFamily([
              leaf.symbology as string,
              leaf.layerType === ProjectTreeLayerTypeEnum.Raster,
            ])
          )
          retVal.symbologyCounter = symbologyCounter || 0
          retVal.symbologyState =
            symbologyLoadable.state !== 'hasValue'
              ? SymbologyStateEnum.Fetching
              : symbologyLoadable.contents?.state || SymbologyStateEnum.NotApplicable
          if (symbologyLoadable.state === 'hasValue') retVal.symbology = symbologyLoadable.contents
        }

        retVal.tileState =
          !tileServLoadable.contents || tileServLoadable.state !== 'hasValue'
            ? TilingStateEnum.Fetching
            : tileServLoadable.contents.state
        // Files are a direct download so they just get an automatic pass because there's nothing to tile
        // NOTE: THis is not true for TilingStatus.REPORT that still needs to be copied
        if (leaf.layerType === ProjectTreeLayerTypeEnum.File) retVal.tileState === TilingStateEnum.Success

        retVal.renderable =
          retVal.tileState === TilingStateEnum.Success && retVal.symbologyState !== SymbologyStateEnum.Fetching
        if (tileServLoadable.contents && tileServLoadable.state === 'hasValue') retVal.tiles = tileServLoadable.contents
      }

      return retVal
    },
})

/**
 * Pull out the map leaf ids for a given view
 */
export const viewProjLeafIdsSelector = selector<Record<string, number[]>>({
  key: 'map/viewProjLeafIds',
  get: ({ get }): Record<string, number[]> => {
    const proj = get(projectAtom)
    if (!proj || !proj.tree || !proj.tree.views) return {}
    const retVal = proj.tree.views.reduce<Record<string, number[]>>((acc, view) => {
      const viewIds = view.layers.reduce<number[]>((acc, vl) => {
        const ptl = proj.tree.leaves.find(({ blLayerId }) => blLayerId && blLayerId === vl.id)
        if (!ptl) return acc
        else return [...acc, ptl.id]
      }, [])
      return {
        ...acc,
        [view.id]: viewIds,
      }
    }, {})

    return retVal
  },
})
