import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
export interface RemoveProjectDialogProps {
  collectionName: string
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

export const RemoveProjectDialog: React.FC<RemoveProjectDialogProps> = ({
  collectionName,
  onConfirm,
  onClose,
  open,
}) => {
  const theme = useTheme()
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.main,
          mb: 2,
        }}
      >
        Remove Project from collection: <strong>{collectionName}</strong>
      </DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Typography>
          Are you sure you want to remove this project from the collection: <strong>{collectionName}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleConfirm} color="error">
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}
