import { mock } from './index'
import {
  Collection,
  StarrableTypesEnum,
  Project,
  UpdateStarMutationVariables,
  CreateSavedSearchMutationVariables,
} from '../schema/base'
import {
  GetProjectDetailDocument,
  GetCollectionDetailDocument,
  UpdateStarDocument,
  GetSmartSearchResultsDocument,
  GetProjectSearchResultsDocument,
  GetProjectTypesDocument,
  GetProfileOrganizationsDocument,
  CreateSavedSearchDocument,
  CreateSavedSearchMutationResult,
  UpdateStarMutationResult,
  GetUserDetailDocument,
  GetOrganizationDetailDocument,
  GetProfileDetailDocument,
} from '../schema/operations'

export const mockResponses = [
  {
    request: {
      query: GetProfileDetailDocument,
    },
    result: {
      data: {
        profile: mock.makeProfile(),
      },
    },
  },
  {
    request: {
      query: GetProjectDetailDocument,
      variables: {
        id: 'proj1',
      },
    },
    result: {
      data: {
        project: mock.makeProjects(1)[0],
      },
    },
  },
  {
    request: {
      query: GetCollectionDetailDocument,
      variables: {
        id: 'coll1',
      },
    },
    result: {
      data: {
        collection: mock.makeCollections(1)[0],
      },
    },
  },
  {
    request: {
      query: GetUserDetailDocument,
      variables: {
        id: 'user1',
      },
    },
    result: {
      data: {
        user: mock.user,
      },
    },
  },
  {
    request: {
      query: GetOrganizationDetailDocument,
      variables: {
        id: 'org1',
      },
    },
    result: {
      data: {
        organization: mock.organization,
      },
    },
  },
  {
    request: {
      query: UpdateStarDocument,
      variables: {
        id: 'proj1',
        type: StarrableTypesEnum.Project,
        starred: true,
      },
    },
    result: {
      data: {
        updateStar: true,
      },
    },
  },
  {
    request: {
      query: GetSmartSearchResultsDocument,
      variables: {
        text: 'test',
      },
    },
    result: {
      data: {
        searchProjects: {
          results: [
            {
              item: mock.makeProjects(1)[0],
              highlights: {
                name: ['Project <span>Test</span>ing'],
              },
            },
          ],
        },
        searchCollections: {
          results: [
            {
              item: mock.makeCollections(1)[0],
              highlights: {
                name: ['Some <span>Test</span> Collection'],
              },
            },
          ],
        },
        searchOrganizations: {
          results: [],
        },
      },
    },
  },
  {
    request: {
      query: GetProjectSearchResultsDocument,
      variables: {
        limit: 10,
        offset: 0,
        sort: null,
        params: {
          keywords: 'test',
        },
      },
    },
    result: {
      data: {
        searchProjects: {
          total: 12,
          results: [
            {
              item: {
                __typename: 'Project',
                id: 'proj1',
                name: 'Project Testing',
                projectType: {
                  id: 'vbet',
                  name: 'VBET',
                },
              } as Partial<Project>,
            },
          ],
        },
      },
    },
  },
  {
    request: {
      query: GetProjectTypesDocument,
      // variables: {
      //   limit: 10,
      //   offset: 0,
      // },
    },
    result: {
      data: {
        projectTypes: {
          total: 1,
          items: [
            {
              __typename: 'ProjectType',
              id: 'vbet',
              name: 'VBET',
            },
            {
              __typename: 'ProjectType',
              id: 'other',
              name: 'Some Other Type',
            },
          ],
        },
      },
    },
  },
  {
    request: {
      query: GetProfileOrganizationsDocument,
    },
    result: {
      data: {
        profile: {
          organizations: {
            items: [
              {
                __typename: 'Organization',
                id: 'org1',
                name: 'My Organization',
              },
            ],
          },
        },
      },
    },
  },
  {
    request: {
      query: CreateSavedSearchDocument,
      variables: {
        savedSearch: {
          name: 'test',
          searchParams: {
            bounded: undefined,
            collection: undefined,
            keywords: 'test',
            name: undefined,
            projectTypeId: undefined,
            tags: undefined,
          },
          defaultSort: undefined,
          orgId: undefined,
        },
      } as CreateSavedSearchMutationVariables,
    },
    result: {
      data: {
        createSavedSearch: {
          __typename: 'SavedSearch',
          id: 'ss1',
          name: 'test',
        },
      },
    } as CreateSavedSearchMutationResult,
  },
]
