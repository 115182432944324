import React, { useState } from 'react'
import { ProjectTreeLayerTypeEnum, RampTypeEnum, Symbology } from '@riverscapes/react-common'

import {
  Avatar,
  CircularProgress,
  Color,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { Cancel, ChevronRight, ExpandMore, ZoomOutMap } from '@mui/icons-material'
import ColorRamp from './ColorRamp'
import { LayerIcon } from '@riverscapes/react-common'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  container: {},
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 6,
  },
  listItemActive: {
    '& *': {
      cursor: 'pointer',
    },
  },
  listItemAvatar: {
    minWidth: theme.spacing(3.5),
  },
  expandIcon: {
    minWidth: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '& svg': {
      height: '0.7em',
      width: '0.7em',
    },
  },
  expandIconButton: {
    padding: 0,
    '& svg': {
      width: '0.7em',
      height: '0.7em',
    },
  },
  expandDisabled: {
    color: 'rgba(0,0,0,0.05)!important',
  },
  text: {
    '& .MuiListItemText-primary .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiListItemText-secondary .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  secondary: {
    right: 0,
  },
  root: {
    mt: theme.spacing(2),
  },
  heading: {
    mt: theme.spacing(1),
  },
  loadingSpinner: {
    color: theme.palette.text.secondary,
  },
  layerItem: {
    display: 'flex',
  },
  legendName: {
    flexGrow: 1,
  },
  deleteButtonHover: {},
  legendColor: {
    // border: '1px solid black',
    // width: '20px',
    // display: 'inline-block',
    // height: '14px',
    // mr: '5px',
  },
  deleteButton: {
    // flexGrow: 0,
    // color: '#CCCCCC',
    // padding: 0,
    // mr: 0,
    // '& < svg': {
    //   width: '0.8em',
    //   height: '0.8em',
    // },
  },
  zoomButton: {
    // flexGrow: 0,
    // color: '#CCCCCC',
    // padding: 0,
    // mr: 0,
    // '& < svg': {
    //   width: '0.8em',
    //   height: '0.8em',
    // },
  },
})

export interface LegendLayerItemProps {
  label: string
  leafId: number
  layerType: ProjectTreeLayerTypeEnum
  subLabel?: string
  overZoomed?: boolean
  max?: number
  min?: number
  fallbackColor?: Color
  loading?: boolean
  zoomLayerExtent?: () => void
  symbology?: Symbology | null
  removeLayer: (lid: number) => void
}

const LegendLayerItem: React.FC<LegendLayerItemProps> = ({
  label,
  leafId,
  loading,
  max,
  min,
  subLabel,
  fallbackColor,
  zoomLayerExtent,
  symbology,
  layerType,
  removeLayer,
}: LegendLayerItemProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const [open, setOpen] = useState<boolean>(true)

  const hasLegend = Boolean(symbology && symbology.legend && symbology.legend.length > 1)
  const hasOneItemLegend = Boolean(symbology && symbology.legend && symbology.legend.length === 1)

  // const legendColor = symbology?.legend[0][0] as string
  const legendColor: string = (symbology?.legend?.[0]?.[0] as string) || '#fb00ff'

  let fgColor = theme.palette.text.primary
  let bgColor = theme.palette.background.default
  if (hasOneItemLegend && symbology?.legend) {
    if (layerType === ProjectTreeLayerTypeEnum.Polygon || layerType === ProjectTreeLayerTypeEnum.Raster) {
      bgColor = legendColor
      fgColor = theme.palette.getContrastText(bgColor)
    } else {
      fgColor = legendColor
      bgColor = theme.palette.background.default
    }
    // fgColor = symbology?.legend?[0][0]
  } else if (fallbackColor) {
    if (layerType === ProjectTreeLayerTypeEnum.Polygon || layerType === ProjectTreeLayerTypeEnum.Raster) {
      fgColor = fallbackColor[500]
      bgColor = fallbackColor[100]
    } else {
      fgColor = fallbackColor[500]
      bgColor = theme.palette.background.default
    }
  }

  const toggle = (): void => (hasLegend ? setOpen(!open) : undefined)

  const listItemStyle = {
    ...styles.listItem,
    ...(hasLegend ? styles.listItemActive : {}),
  }

  return (
    <React.Fragment>
      <ListItem dense sx={listItemStyle} disableGutters disablePadding>
        <ListItemIcon sx={styles.expandIcon}>
          <IconButton size="small" onClick={toggle} disabled={!hasLegend} sx={styles.expandIconButton}>
            {hasLegend && open ? <ExpandMore /> : <ChevronRight />}
          </IconButton>
        </ListItemIcon>
        <ListItemAvatar sx={styles.listItemAvatar}>
          <Avatar sx={styles.avatar} style={{ background: bgColor }} onClick={toggle}>
            <LayerIcon
              layerType={layerType}
              iconProps={{
                style: { color: fgColor },
              }}
            />
          </Avatar>
        </ListItemAvatar>
        <Tooltip
          enterDelay={1500}
          title={
            <React.Fragment>
              <Typography color="inherit">{label}</Typography>
              {subLabel && <Typography variant="caption">{subLabel}</Typography>}
            </React.Fragment>
          }
        >
          <ListItemText primary={label} secondary={subLabel} onClick={toggle} sx={styles.text} />
        </Tooltip>
        <ListItemSecondaryAction sx={styles.secondary}>
          {loading && <CircularProgress sx={styles.loadingSpinner} color="inherit" thickness={3} size={15} />}
          {!loading && (
            <>
              {zoomLayerExtent && (
                <Tooltip enterDelay={1500} title="Zoom layer extents">
                  <IconButton
                    edge="end"
                    sx={styles.zoomButton}
                    aria-label="zoomExtent"
                    onClick={(event) => {
                      event.stopPropagation()
                      zoomLayerExtent()
                    }}
                  >
                    <ZoomOutMap />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip enterDelay={1500} title="Remove map layer">
                <IconButton
                  edge="end"
                  sx={styles.deleteButton}
                  aria-label="delete"
                  onClick={(event) => {
                    event.stopPropagation()
                    removeLayer(leafId)
                  }}
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {hasLegend && open && (
        <ColorRamp
          max={max}
          min={min}
          rampType={symbology?.rampType as RampTypeEnum}
          legend={symbology?.legend as [string, string][]}
        />
      )}
    </React.Fragment>
  )
}

export default LegendLayerItem
