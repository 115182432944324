import { Typography } from '@mui/material'
import React from 'react'

interface Props {
  error?: string
}

const LegendError: React.FC<Props> = ({ error }: Props) => {
  return <Typography color="error">{error || ' '}</Typography>
}

export default LegendError
