import { isNull } from 'lodash'
import { JSONObject } from '../types'

/**
 * Pad a string on either side. Useful for command-line progress bars
 * @param padChar
 * @param totalLength
 * @param str
 * @param padLeft
 * @returns
 */
export function padString(padChar: string, totalLength: number, str: string, padLeft = false): string {
  const pad = totalLength > str.length ? padChar.repeat(2 + totalLength - str.length) : ''
  if (padLeft) {
    return (pad + str).slice(-totalLength)
  } else {
    return (str + pad).substring(0, totalLength)
  }
}

/**
 * Check if a value is numeric
 * @param num
 * @returns
 */
export const isNumeric = (num: unknown): boolean => {
  try {
    if (isNull(num)) return false
    if (typeof num === 'number') return !isNaN(num)
    return !isNaN(Number(num)) && isFinite(Number(num))
  } catch (e) {
    return false
  }
}

/**
 * Useful function for comparing API urls to make sure we're uploading/downling to/from the same servers
 * @param url1
 * @param url2
 * @returns
 */
export function compareUrls(url1Str: string, url2Str: string): boolean {
  try {
    const url1 = new URL(url1Str)
    const url2 = new URL(url2Str)
    const path1 = url1.pathname.replace(/\/$/, '')
    const path2 = url2.pathname.replace(/\/$/, '')
    return url1.origin === url2.origin && url1.port === url2.port && path1 === path2
  } catch {
    return false
  }
}

/**
 * Encode a json object as a base64 string
 * @param {*} text
 */
export function jsonToBase64(json: object): string {
  const jsonString = JSON.stringify(json)
  return Buffer.from(jsonString).toString('base64')
}
/**
 * Decode a base64 string back to a json object
 * @param {*} b64String
 */
export function base64ToJson(base64: string): JSONObject {
  return JSON.parse(Buffer.from(base64, 'base64').toString())
}
