import {
  ApolloCache,
  ApolloQueryResult,
  DocumentNode,
  QueryOptions,
  useApolloClient,
  OperationVariables,
} from '@apollo/client'
import { Exact, PendingItem, pendingItems } from '@riverscapes/react-common'
import produce, { Draft } from 'immer'
import { useEffect, useMemo } from 'react'

export const useFetchAllWithPendingItems = <T>(
  fetchMore: ({ variables: { offset } }) => void, // Apollo fetchMore function
  currentItems: T[] | undefined, // what items are already retrieved
  expectedCount: number | undefined, // how many items are we expecting?
  defaultExpectedCount = 1
): (T | PendingItem)[] => {
  const currentItemsCount = currentItems?.length

  useEffect(() => {
    if (typeof currentItemsCount === 'undefined' || typeof expectedCount === 'undefined') return
    if (currentItemsCount < expectedCount) fetchMore({ variables: { offset: currentItemsCount } })
  }, [currentItemsCount, expectedCount])

  return useMemo(() => {
    if (typeof expectedCount === 'undefined') return pendingItems(defaultExpectedCount)
    if (!currentItems) return pendingItems(expectedCount)
    return [...currentItems, ...pendingItems(expectedCount - currentItems.length)]
  }, [currentItems, expectedCount, defaultExpectedCount])
}

export const makeImperativeQueryHook = <QueryVariablesT extends OperationVariables, QueryT>(
  documentNode: DocumentNode
) => {
  return (): ((
    params: Omit<NonNullable<QueryOptions<QueryVariablesT, QueryT>>, 'query'>
  ) => Promise<ApolloQueryResult<QueryT>['data']>) => {
    const client = useApolloClient()
    return (params) => {
      return client
        .query<QueryT, QueryVariablesT>({
          ...params,
          query: documentNode,
        })
        .then((response) => {
          if (response.error) throw response.error
          return response.data
        })
    }
  }
}

export const updateCache = <TQuery, TQueryVariables extends Record<string, unknown>>({
  cache,
  query,
  variables,
  update,
}: {
  cache: ApolloCache<any>
  query: DocumentNode
  variables: Exact<TQueryVariables>
  update: (draft: Draft<TQuery>) => void
}): void => {
  const cacheData = cache.readQuery<TQuery, TQueryVariables>({
    query,
    variables,
  })
  if (!cacheData) return
  cache.writeQuery<TQuery, TQueryVariables>({
    query,
    variables,
    data: produce(cacheData, update),
  })
}

type NoInfer<T> = [T][T extends any ? 0 : never]

/*
Use for strong typing in mutation refetchQueries arrays.
*/
export const refetchQuery = <TQueryVariables>(
  query: DocumentNode,
  variables: NoInfer<TQueryVariables>
): QueryOptions<NoInfer<TQueryVariables>> => ({
  query,
  variables,
})
