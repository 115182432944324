import { LogicError, operations } from '@riverscapes/react-common'
import { evictAllQueries } from '../cache'

const {
  GetCollectionDetailDocument,
  GetCollectionProjectsDocument,
  GetProjectCollectionsDocument,
  GetProjectDetailDocument,
  GetUserCollectionsDocument,
  GetOrganizationCollectionsDocument,
  useAddCollectionProjectsMutation: _useAddCollectionProjectsMutation,
  useCreateCollectionMutation: _useCreateCollectionMutation,
  useDeleteCollectionMutation: _useDeleteCollectionMutation,
  useGetCollectionByIdLazyQuery,
  useGetCollectionDetailQuery,
  useGetCollectionMapBoundsLazyQuery,
  useGetCollectionMapBoundsQuery,
  useGetCollectionMapClustersLazyQuery,
  useGetCollectionMapClustersQuery,
  useGetCollectionProjectsQuery,
  useGetCollectionSearchResultsQuery,
  useGetCollectionsByNameLazyQuery,
  useGetOrganizationCollectionsQuery,
  useRemoveCollectionProjectsMutation: _useRemoveCollectionProjectsMutation,
  useUpdateCollectionDescriptionMutation: _useUpdateCollectionDescriptionMutation,
  useUpdateCollectionHeroImageMutation,
  useUpdateCollectionMetaMutation: _useUpdateCollectionMetaMutation,
  useUpdateCollectionNameMutation: _useUpdateCollectionNameMutation,
  useUpdateCollectionSummaryMutation: _useUpdateCollectionSummaryMutation,
  useUpdateCollectionTagsMutation: _useUpdateCollectionTagsMutation,
  useUpdateCollectionVisibilityMutation: _useUpdateCollectionVisibilityMutation,
} = operations

export {
  useGetCollectionByIdLazyQuery,
  useGetCollectionDetailQuery,
  useGetCollectionMapBoundsLazyQuery,
  useGetCollectionMapBoundsQuery,
  useGetCollectionMapClustersLazyQuery,
  useGetCollectionMapClustersQuery,
  useGetCollectionProjectsQuery,
  useGetCollectionSearchResultsQuery,
  useGetCollectionsByNameLazyQuery,
  useUpdateCollectionHeroImageMutation,
  useGetOrganizationCollectionsQuery,
}

export const useCreateCollectionMutation = (): ReturnType<typeof _useCreateCollectionMutation> =>
  _useCreateCollectionMutation({
    refetchQueries: [GetOrganizationCollectionsDocument, GetUserCollectionsDocument],
    update: evictAllQueries,
  })

export const useAddCollectionProjectsMutation = (): ReturnType<typeof _useAddCollectionProjectsMutation> =>
  _useAddCollectionProjectsMutation({
    refetchQueries: [
      GetCollectionDetailDocument,
      GetCollectionProjectsDocument,
      GetProjectCollectionsDocument,
      GetProjectDetailDocument,
    ],
  })

export const useRemoveCollectionProjectsMutation = (): ReturnType<typeof _useRemoveCollectionProjectsMutation> =>
  _useRemoveCollectionProjectsMutation({
    refetchQueries: [
      GetCollectionDetailDocument,
      GetCollectionProjectsDocument,
      GetProjectCollectionsDocument,
      GetProjectDetailDocument,
    ],
  })

export const useUpdateCollectionDescriptionMutation = (): ReturnType<typeof _useUpdateCollectionDescriptionMutation> =>
  _useUpdateCollectionDescriptionMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateCollection: {
        __typename: 'Collection',
        ...variables,
      },
    }),
  })

export const useUpdateCollectionNameMutation = (): ReturnType<typeof _useUpdateCollectionNameMutation> =>
  _useUpdateCollectionNameMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateCollection: {
        __typename: 'Collection',
        ...variables,
      },
    }),
  })

export const useUpdateCollectionSummaryMutation = (): ReturnType<typeof _useUpdateCollectionSummaryMutation> =>
  _useUpdateCollectionSummaryMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateCollection: {
        __typename: 'Collection',
        ...variables,
      },
    }),
  })

export const useUpdateCollectionTagsMutation = (): ReturnType<typeof _useUpdateCollectionTagsMutation> =>
  _useUpdateCollectionTagsMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateCollection: {
        __typename: 'Collection',
        ...variables,
      },
    }),
  })

export const useUpdateCollectionVisibilityMutation = (): ReturnType<typeof _useUpdateCollectionVisibilityMutation> =>
  _useUpdateCollectionVisibilityMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateCollection: {
        __typename: 'Collection',
        ...variables,
      },
    }),
  })

export const useDeleteCollectionMutation = (): ReturnType<typeof _useDeleteCollectionMutation> =>
  _useDeleteCollectionMutation({
    update: (cache, _, { variables }) => {
      if (!variables?.id) throw new LogicError()
      cache.evict({
        id: cache.identify({ __typename: 'Collection', id: variables.id }),
      })
      cache.gc()
    },
  })

export const useUpdateCollectionMetaMutation = (): ReturnType<typeof _useUpdateCollectionMetaMutation> =>
  _useUpdateCollectionMetaMutation({
    optimisticResponse: ({ id, meta }) => ({
      __typename: 'Mutation',
      updateCollection: {
        __typename: 'Collection',
        id,
        meta: meta.map((row) => ({ __typename: 'MetaData', ...row })),
      },
    }),
  })
