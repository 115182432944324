import { Box } from '@mui/system'
import { Avatar, useTheme } from '@mui/material'
import { ProjectTypeTooltipCard } from '../ProjectTypeDetail'
import { ProjectTypeItemFragment } from '../../schema/base'

export interface ProjectTypeImageProps {
  item: Partial<ProjectTypeItemFragment>
  onClick?: () => void
  size: number
  tooltip?: boolean
}

export const ProjectTypeImage: React.FC<ProjectTypeImageProps> = ({ item, size, tooltip, onClick }) => {
  const theme = useTheme()

  if (item.logo) {
    const imgEl = (
      <Box
        onClick={(event) => {
          if (onClick) {
            onClick()
            event.preventDefault()
            event.stopPropagation()
          }
        }}
        sx={{
          cursor: onClick ? 'pointer' : 'default',
          height: size,
          width: size,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={item.logo}
          style={{
            objectFit: 'contain',
            objectPosition: 'center',
            maxHeight: '100%',
            maxWidth: '100%',
          }}
          alt={item.name}
        />
      </Box>
    )
    if (tooltip) return <ProjectTypeTooltipCard projectType={item}>{imgEl}</ProjectTypeTooltipCard>
    else return imgEl
  }

  const shortName = (item.name || '')
    .replace(/\(.*\)/g, '')
    .split(' ')
    .map((c) => c[0] || '')
    .join('')
    .toUpperCase()
  const multiplier = shortName.length > 0 ? 1 / shortName.length : 1
  return (
    <Avatar
      onClick={(event) => {
        if (onClick) {
          onClick()
          event.preventDefault()
          event.stopPropagation()
        }
      }}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        border: `1px solid ${theme.palette.secondary.contrastText}`,
        color: theme.palette.secondary.contrastText,
        background: theme.palette.secondary.main,
        height: size,
        width: size,
        fontSize: Math.max(size * multiplier * 0.8, 10),
      }}
    >
      {shortName}
    </Avatar>
  )
}
