import React, { useMemo } from 'react'
import {
  Typography,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Link,
  IconButton,
  CardHeader,
  useTheme,
  Menu,
  MenuItem,
  Tooltip,
  CardProps,
  Divider,
} from '@mui/material'
import { ProjectCardFragment } from '../../schema/base'
import { ItemRepresentation, ProjectTypeImage } from '../ItemImage'
import { VisibilityChip } from '../Visibility'
import { ProjectBoundsThumb, ProjectBoundsThumbProps } from './ProjectBoundsThumb'
import { Bbox } from '../InteractiveMap'
import { Lookups } from '../lookups'
import { OpenProjectIcon, RemoveIcon, ZoomFitIcon } from '../Icons'
import { useDownload, useFormats } from '../../lib'
import { TagChips } from '../Tags'
import { getSpecialMeta } from '../../lib/meta'
import { Box } from '@mui/system'
import { Article, Download, FilterList, MoreVert } from '@mui/icons-material'
import { DownloadButton } from '../Download'
import { DownloadTooltip } from '../Download/DownloadButton'
import { isUndefined } from 'lodash'

export interface ProjectCardProps {
  className?: string
  project: ProjectCardFragment
  lookups: Lookups
  isGrid?: boolean
  onGotoWebRave?: (id: string) => void
  getProjectBoundsThumbProps?: (bounds: {
    id: string
    polygonUrl: string
    bbox: Bbox
  }) => Partial<ProjectBoundsThumbProps>
  cardProps?: CardProps
  onRemoveClick?: ((projectId: string) => void) | null
}

const THUMB_SIZE = 75

export const ProjectCard: React.FC<ProjectCardProps> = ({
  className,
  onGotoWebRave,
  isGrid,
  getProjectBoundsThumbProps,
  lookups,
  project,
  cardProps,
  onRemoveClick,
}) => {
  const theme = useTheme()
  const { downloadZipFile, pendingZips } = useDownload()
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const showMenu = Boolean(menuAnchorEl)
  const { formatBytes, formatTimeAgo } = useFormats()

  const { huc, modelVersion } = useMemo(() => getSpecialMeta(project), [project.meta])
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget)
  const handleMenuClose = () => setMenuAnchorEl(null)
  const projectBoundsProps = useMemo(() => {
    if (project.bounds) {
      if (!getProjectBoundsThumbProps) return
      return getProjectBoundsThumbProps({
        id: project.bounds.id,
        polygonUrl: project.bounds.polygonUrl,
        bbox: project.bounds.bbox as Bbox,
      }) as ProjectBoundsThumbProps
    }
  }, [project.bounds])

  const downloadPendingSince = project && pendingZips[project.id]
  const downloadPending = Boolean(downloadPendingSince)

  const metadataRow = useMemo(() => {
    const row = [`Updated ${formatTimeAgo(project.updatedOn)}`]

    if (modelVersion) row.push(`Model Version: ${modelVersion}`)
    if (project.totalSize) row.push(formatBytes(project.totalSize))
    return row
  }, [project])

  const heroImageUrl = project.heroImage?.sm

  return (
    <Card
      className={className}
      sx={{
        minWidth: '15rem',
        maxWidth: '40em',
        boxShadow: theme.shadows[2],
      }}
      {...cardProps}
    >
      <CardHeader
        component={Link}
        href={lookups.getProjectUrlById(project.id)}
        sx={{
          color: 'white',
          backgroundColor: theme.palette.primary.light,
          backgroundImage: heroImageUrl && `url(${heroImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '& .MuiTypography-root': {
            fontSize: '1rem',
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            textDecoration: 'none',
          },
        }}
        title={
          <Stack direction="row" alignItems={'center'} gap={1} sx={{ color: 'white', width: '100%' }}>
            <Stack sx={{ flexGrow: 1 }} alignItems={'center'} direction={'row'} gap={1}>
              <ProjectTypeImage size={30} item={project.projectType} tooltip />
              <Stack direction="row" alignItems="center" gap={1}>
                <Tooltip title={`Go to Riverscapes project detail page`}>
                  <Typography variant="h6" color="white" className={heroImageUrl ? 'heroText' : undefined}>
                    {project.name}
                  </Typography>
                </Tooltip>
              </Stack>
            </Stack>
            <IconButton
              size="small"
              color="inherit"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleMenuOpen(e)
              }}
            >
              <MoreVert color="inherit" />
            </IconButton>
          </Stack>
        }
      />
      <CardContent
        sx={{
          '&:last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <Stack direction="column" gap={1}>
          <Stack direction="row" gap={1} sx={{ width: '100%' }}>
            <Stack
              direction="column"
              spacing={0.2}
              justifyContent="space-between"
              sx={{ flex: '1 1', overflow: 'hidden' }}
            >
              <Stack
                direction={'row'}
                gap={1}
                alignItems={'center'}
                sx={{
                  flex: '1 1',
                  overflow: 'hidden',
                }}
              >
                <VisibilityChip size="small" canEdit={false} value={project.visibility} type="Project" />
                <ItemRepresentation
                  size="small"
                  item={project.ownedBy}
                  ellipses
                  lookups={lookups}
                  link
                  tooltipPrefix="Project owned by:"
                />
              </Stack>
              <TagChips
                value={project.tags}
                size="small"
                color="success"
                startElement={<Typography variant="caption">Tags:</Typography>}
              />
              <Typography variant="caption" sx={{}}>
                {project.summary}
                {huc && `${project.summary.length && !project.summary.endsWith('.') ? '. ' : ''}HUC: ${huc}`}
              </Typography>
            </Stack>
            {project.bounds && getProjectBoundsThumbProps && (
              <Box
                style={{
                  height: THUMB_SIZE,
                  width: THUMB_SIZE,
                  margin: '0 0 2px 2px',
                  flex: `0 0 ${THUMB_SIZE}px`,
                }}
              >
                <ProjectBoundsThumb
                  id={project.bounds.id}
                  polygonUrl={project.bounds.polygonUrl}
                  {...projectBoundsProps}
                />
              </Box>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '0.5em' }}>
            {metadataRow.map((dataCell, index) => (
              <Typography key={index} variant="caption" color="text.secondary" sx={{ flexGrow: '0' }}>
                {dataCell}
              </Typography>
            ))}
            <DownloadButton
              onClick={() => downloadZipFile(project.id)}
              pendingSince={downloadPendingSince}
              title={project.name}
              // size="small"
              sizePx={16}
            />
          </Stack>
        </Stack>
      </CardContent>

      {/* Context menu */}
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={showMenu}
        onClose={handleMenuClose}
        sx={{
          '& svg': {
            mr: 2,
          },
        }}
      >
        <MenuItem component={Link} href={lookups.getProjectUrlById(project.id)}>
          <Article /> Riverscape Project Detail Page
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose()
            downloadZipFile(project.id)
          }}
        >
          <Tooltip title={<DownloadTooltip btnPending={downloadPending} pendingSince={downloadPendingSince} />}>
            <>
              <Download /> {downloadPending ? 'Preparing Download...' : 'Download Project Zip'}
            </>
          </Tooltip>
        </MenuItem>
        {onGotoWebRave && (
          <MenuItem
            onClick={() => {
              handleMenuClose()
              onGotoWebRave(project.id)
            }}
          >
            <OpenProjectIcon /> Open Web Viewer
          </MenuItem>
        )}
        {projectBoundsProps && (
          <MenuItem
            disabled={!project.bounds || !projectBoundsProps || !getProjectBoundsThumbProps}
            onClick={() => {
              projectBoundsProps?.onZoomClick && projectBoundsProps?.onZoomClick()
              handleMenuClose()
            }}
          >
            <ZoomFitIcon /> Zoom Map to Project Extent
          </MenuItem>
        )}
        {projectBoundsProps && (
          <MenuItem
            disabled={!project.bounds || !projectBoundsProps}
            onClick={() => {
              handleMenuClose()
              projectBoundsProps?.onAddClick && projectBoundsProps?.onAddClick()
            }}
          >
            <FilterList /> Filter to projects with this bounding polygon
          </MenuItem>
        )}
        {!isUndefined(onRemoveClick) && <Divider />}
        {!isUndefined(onRemoveClick) && (
          <MenuItem
            color="error"
            sx={{
              color: theme.palette.error.main,
            }}
            disabled={onRemoveClick === null}
            onClick={() => {
              handleMenuClose()
              onRemoveClick && onRemoveClick(project.id)
            }}
          >
            <RemoveIcon color="error" /> Remove project from collection
          </MenuItem>
        )}
      </Menu>
    </Card>
  )
}

export interface ProjectCardSkelProps {
  cardProps?: CardProps
}

export const ProjectCardSkeleton: React.FC<ProjectCardSkelProps> = ({ cardProps }) => {
  return (
    <Card
      {...cardProps}
      // sx={{ minWidth: '20rem', maxWidth: '40rem' }}
    >
      <CardContent>
        <Skeleton />
        <Skeleton />
        <Typography>&nbsp;</Typography>
        <Typography>&nbsp;</Typography>
      </CardContent>
    </Card>
  )
}
