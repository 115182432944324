import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { viewProjLeafIdsSelector, activeViewAtom, activeIdsAtom, projectAtom } from '../recoil'

export interface UseViewsReturn {
  setView: (viewId: string) => void
  resetView: () => void
}

const useViews = (): UseViewsReturn => {
  const [currView, setCurrView] = useRecoilState(activeViewAtom)
  const viewLeafIds = useRecoilValue(viewProjLeafIdsSelector)
  const proj = useRecoilValue(projectAtom)
  const setActiveMapIds = useSetRecoilState(activeIdsAtom)

  const setView = (viewId: string) => {
    if (viewId === null || currView === viewId || !viewLeafIds[viewId]) return
    setCurrView(viewId)
    setActiveMapIds(viewLeafIds[viewId])
  }
  const resetView = () => {
    if (proj && proj.tree.defaultView) setView(proj.tree.defaultView)
  }

  return { setView, resetView }
}

export default useViews
