import { useCallback, useState } from 'react'

export const useBooleanState = (initialValue = false): [value: boolean, setTrue: () => void, setFalse: () => void] => {
  const [value, setValue] = useState(initialValue)
  const setTrue = useCallback(() => {
    setValue(true)
  }, [])
  const setFalse = useCallback(() => {
    setValue(false)
  }, [])
  return [value, setTrue, setFalse]
}
