import { DOMParser } from '@xmldom/xmldom'
import { DatasetContainerTypesEnum, DatasetTypeEnum } from '../gen/schema.types'
import { FileParts, RSXPath } from '../types'

/**
 * Very specific regexes to tease out all possible datasets
 */
const idPat = '#([A-Za-z0-9_-]{3,64})'
// use non-capturing groups "(?:PATTERN)" so they don't show up
const dsMatch = `(?:${Object.values(DatasetTypeEnum).join('|')})${idPat}`

const realMatch = `Realizations/Realization${idPat}`
const analysisMatch = `(Analyses)/Analysis${idPat}`
export const RSDSRegexes = [
  `^Project/(CommonDatasets)/${dsMatch}$`,
  `^Project/${realMatch}/(Logs|Datasets|Inputs|Outputs|Intermediates)/${dsMatch}$`,
  `^Project/${realMatch}/${analysisMatch}/(Configuration|Products)/${dsMatch}$`,
].map((ds) => new RegExp(ds))

/**
 * Real xpaths to pull all dataset nodes out of an XML file
 */
export const DSXpaths = [
  'Project/CommonDatasets/*',
  'Project/Realizations/Realization/Logs/*',
  'Project/Realizations/Realization/Datasets/*',
  'Project/Realizations/Realization/Inputs/*',
  'Project/Realizations/Realization/Outputs/*',
  'Project/Realizations/Realization/Intermediates/*',
  'Project/Realizations/Realization/Analyses/Analysis/Configuration/*',
  'Project/Realizations/Realization/Analyses/Analysis/Products/*',
]

const dsMatchCapture = `(${Object.values(DatasetTypeEnum).join('|')})${idPat}`

/**
 * Matchers for when we start with an rsXPath
 */
const DatasetContainersMatch: Record<DatasetContainerTypesEnum, RegExp> = {
  CommonDatasets: new RegExp(`Project/(CommonDatasets)/${dsMatchCapture}`), // Project/CommonDatasets/AuxInstrumentFile#AuxFile
  Configuration: new RegExp(`Project/${realMatch}/${analysisMatch}/(Configuration)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Analyses/Analysis#analysis_0/Configuration/ConfigFile#layer_ID1
  Products: new RegExp(`Project/${realMatch}/${analysisMatch}/(Products)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Analyses/Analysis#analysis_0/Products/Vector#Tier1_83360
  Datasets: new RegExp(`Project/${realMatch}/(Datasets)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Datasets/Vector#BFCL
  Inputs: new RegExp(`Project/${realMatch}/(Inputs)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Inputs/Vector#BFCL
  Intermediates: new RegExp(`Project/${realMatch}/(Intermediates)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Intermediates/Vector#BFCL
  Logs: new RegExp(`Project/${realMatch}/(Logs)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Logs/Vector#BFCL
  Outputs: new RegExp(`Project/${realMatch}/(Outputs)/${dsMatchCapture}`), // Project/Realizations/Realization#GUT_2012/Outputs/Vector#BFCL
}

/**
 * For geo datasets the file extension is a bit tricky so we handle it specially
 */
export const localPathParse = (localPath: string): FileParts => {
  if (!localPath || localPath.trim().length === 0) throw new Error('localPath is mandatory')
  else if (localPath.trim().length !== localPath.length)
    throw new Error(`localPath has illegal whitespace: "${localPath}"`)
  else if (localPath.match(/[\\]/)) throw new Error(`Illegal windows slash found: ${localPath}`)
  else if (localPath.match(/^(?:[\\/]+|[a-z]:).*/i)) throw new Error(`Illegal absolute path found: ${localPath}`)

  // We trim trailing
  const cleanedPath = localPath[localPath.length - 1] === '/' ? localPath.slice(0, -1) : localPath

  const pathLower = cleanedPath.toLowerCase()
  // Get the file extension without using path. There might not be an extension
  const extMatch = cleanedPath.match(/\w+\.([.\w]+)+$/)
  const ext = extMatch ? extMatch[1] : null
  // Full path with the extension removed
  const noExt = ext ? cleanedPath.slice(0, -ext.length - 1) : cleanedPath

  return {
    ext,
    extLower: ext ? ext.toLowerCase() : null,
    pathLower,
    pathNoExt: noExt,
  }
}

/**
 * Translate from our proprietary reduced xPath format (see getXPath above) to a path on S3
 * Takes the form: PROJ_ORIGIN_GUID/REALIZATION_ID/DATASET_ID
 * NOTE: this is ALWAYS un-prefixed
 * to our DB format
 * @param rsXPath
 */
export const s3PathFromRSXPath = (projGuid: string, rsXPath: string): string => {
  const check: RegExpMatchArray[] = RSDSRegexes.map((dsRegex) => rsXPath.match(dsRegex)).filter((m) => Boolean(m))
  if (!projGuid || projGuid.length < 10) throw new Error(`Invalid project guid: ${projGuid}`)
  if (check.length === 0)
    throw new Error(`Dataset with xPath: ${rsXPath} did not match any of the valid patterns: \n${RSDSRegexes}`)
  else if (check.length > 1) throw new Error('Multiple DS regex matches should not be possible')
  const xPathReduce = check[0].slice(1).join('/')

  return `${projGuid}/${xPathReduce}`
}

/**
 * Parse an rsXPath string and pull out useful information in the form of the RsxPath object
 * @param rsXPath
 * @returns
 */
export const rsXpath2Obj = (rsXPath: string): RSXPath => {
  const datasetContainer: DatasetContainerTypesEnum = Object.keys(DatasetContainersMatch).find((dsRegKey) =>
    rsXPath.match(DatasetContainersMatch[dsRegKey])
  ) as DatasetContainerTypesEnum
  if (!datasetContainer) {
    throw new Error(`Could not determine dataset container from rsXPath: ${rsXPath}`)
  }
  const matcher = rsXPath.match(DatasetContainersMatch[datasetContainer])

  let analysisId: string
  let datasetXMLId: string
  let realizationXMLId: string
  let datasetType: DatasetTypeEnum

  switch (datasetContainer) {
    case DatasetContainerTypesEnum.CommonDatasets:
      datasetType = matcher[2] as DatasetTypeEnum
      datasetXMLId = matcher[3]
      break
    case DatasetContainerTypesEnum.Configuration:
    case DatasetContainerTypesEnum.Products:
      realizationXMLId = matcher[1]
      analysisId = matcher[3]
      datasetType = matcher[5] as DatasetTypeEnum
      datasetXMLId = matcher[6]
      break
    // These all work the same way
    case DatasetContainerTypesEnum.Datasets:
    case DatasetContainerTypesEnum.Inputs:
    case DatasetContainerTypesEnum.Intermediates:
    case DatasetContainerTypesEnum.Outputs:
    case DatasetContainerTypesEnum.Logs:
      realizationXMLId = matcher[1]
      datasetType = matcher[3] as DatasetTypeEnum
      datasetXMLId = matcher[4]
      break
    default:
      throw new Error(`Could not determine dataset type from rsXpath: ${rsXPath}`)
  }

  return {
    datasetContainer,
    datasetType,
    datasetXMLId,
    realizationXMLId,
    analysisId,
  }
}

/**
 * This isn't a REAL xPath. It's a truncated form that reduces all Riverscapes DS in a project to a unique
 * path-like string of the form NODENAME#NODEID/NODENAME#NODEID/NODENAME#NODEID/NODENAME#NODEID etc.
 *
 * WARNING: This method should not be used for generic XML applications
 * @param xmlNode
 * @returns
 */
export const getRSXPath = (xmlNode: Element): string => {
  let rsXPath = ''
  let currNode = xmlNode
  while (currNode.nodeName !== '#document') {
    const nodeID = currNode.getAttribute('id')
    const nodeIDStr = nodeID ? `#${nodeID}` : ''
    const sep = rsXPath.length > 0 ? '/' : ''
    rsXPath = `${currNode.nodeName}${nodeIDStr}${sep}${rsXPath}`
    currNode = currNode.parentNode as Element
  }

  // Project/Realizations/Realization#CAD_Export_001/Outputs/CSV#control_csv
  const check = RSDSRegexes.find((dsRegex) => rsXPath.match(dsRegex))
  if (!check)
    throw new Error(
      `Dataset with xPath: ${rsXPath} did not match any of the valid patterns: \n${JSON.stringify(
        RSDSRegexes.map((ds) => ds.toString()),
        null,
        2
      )}`
    )

  return rsXPath
}

/**
 * This rehydrates an RSXPath into a dom tree so that we can search it using xpath
 * @param path
 * @returns
 */
export function getRSXPathDOM(path: string): Element {
  const segments = path.split('/')
  const parser = new DOMParser()
  const xml = parser.parseFromString('<Project/>', 'text/xml')
  let currentNode = xml.documentElement

  for (const segment of segments) {
    const idIndex = segment.indexOf('#')
    const nodeName = idIndex === -1 ? segment : segment.slice(0, idIndex)
    const node = xml.createElement(nodeName)

    if (idIndex !== -1) {
      const id = segment.slice(idIndex + 1)
      node.setAttribute('id', id)
    }

    currentNode.appendChild(node)
    currentNode = node
  }

  return xml.documentElement
}
