import log from 'loglevel'

/*
  MissingDataError: Data is missing from a query/mutation
*/
export class MissingDataError extends Error {
  constructor() {
    super('Missing data')
  }
}

/*
  LogicError: This error is truly unexpected as other code should have prevented this error
*/
export class LogicError extends Error {
  constructor() {
    log.error('LogicError: unexpected error due to flaw in code logic')
    super('Code logic error')
  }
}

/*
  UserCancel: Voluntariy user cancellation 
*/
export class UserCancel extends Error {
  constructor() {
    super('User cancelled')
  }
}
