import * as types from './base'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WebRaveProjectDocument = gql`
    query webRaveProject($id: ID!, $dsLimit: Int!, $dsOffset: Int!) {
  project(id: $id) {
    id
    name
    summary
    deleted
    createdOn
    updatedOn
    tags
    visibility
    projectType {
      id
      name
    }
    meta {
      key
      value
      type
      locked
      ext
    }
    ownedBy {
      ... on User {
        id
        name
        avatar
      }
      ... on Organization {
        id
        name
        logo
      }
      __typename
    }
    bounds {
      bbox
      centroid
      __typename
    }
    datasets(limit: $dsLimit, offset: $dsOffset) {
      items {
        id
        rsXPath
        refProject {
          id
          name
          projectType {
            id
            name
          }
        }
        meta {
          key
          value
          type
          locked
          ext
        }
      }
      limit
      offset
      total
    }
    tree {
      defaultView
      description
      leaves {
        id
        pid
        label
        labelxpath
        nodeId
        filePath
        layerType
        blLayerId
        symbology
        transparency
        rsXPath
        lyrName
      }
      branches {
        bid
        collapsed
        label
        pid
      }
      defaultView
      views {
        id
        name
        description
        layers {
          id
          visible
        }
      }
    }
  }
}
    `;

/**
 * __useWebRaveProjectQuery__
 *
 * To run a query within a React component, call `useWebRaveProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebRaveProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebRaveProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dsLimit: // value for 'dsLimit'
 *      dsOffset: // value for 'dsOffset'
 *   },
 * });
 */
export function useWebRaveProjectQuery(baseOptions: Apollo.QueryHookOptions<types.WebRaveProjectQuery, types.WebRaveProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.WebRaveProjectQuery, types.WebRaveProjectQueryVariables>(WebRaveProjectDocument, options);
      }
export function useWebRaveProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.WebRaveProjectQuery, types.WebRaveProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.WebRaveProjectQuery, types.WebRaveProjectQueryVariables>(WebRaveProjectDocument, options);
        }
export type WebRaveProjectQueryHookResult = ReturnType<typeof useWebRaveProjectQuery>;
export type WebRaveProjectLazyQueryHookResult = ReturnType<typeof useWebRaveProjectLazyQuery>;
export type WebRaveProjectQueryResult = Apollo.QueryResult<types.WebRaveProjectQuery, types.WebRaveProjectQueryVariables>;
export const GetWebSymbologyDocument = gql`
    query getWebSymbology($projectTypeId: String, $name: String!, $isRaster: Boolean) {
  getWebSymbology(projectTypeId: $projectTypeId, name: $name, isRaster: $isRaster) {
    name
    state
    legend
    rampType
    mapboxJson
    url
    error
  }
}
    `;

/**
 * __useGetWebSymbologyQuery__
 *
 * To run a query within a React component, call `useGetWebSymbologyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebSymbologyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebSymbologyQuery({
 *   variables: {
 *      projectTypeId: // value for 'projectTypeId'
 *      name: // value for 'name'
 *      isRaster: // value for 'isRaster'
 *   },
 * });
 */
export function useGetWebSymbologyQuery(baseOptions: Apollo.QueryHookOptions<types.GetWebSymbologyQuery, types.GetWebSymbologyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetWebSymbologyQuery, types.GetWebSymbologyQueryVariables>(GetWebSymbologyDocument, options);
      }
export function useGetWebSymbologyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetWebSymbologyQuery, types.GetWebSymbologyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetWebSymbologyQuery, types.GetWebSymbologyQueryVariables>(GetWebSymbologyDocument, options);
        }
export type GetWebSymbologyQueryHookResult = ReturnType<typeof useGetWebSymbologyQuery>;
export type GetWebSymbologyLazyQueryHookResult = ReturnType<typeof useGetWebSymbologyLazyQuery>;
export type GetWebSymbologyQueryResult = Apollo.QueryResult<types.GetWebSymbologyQuery, types.GetWebSymbologyQueryVariables>;
export const GetLayerTilesDocument = gql`
    query getLayerTiles($rsXPath: String!, $projectId: ID!, $projectTypeId: String!) {
  getLayerTiles(
    rsXPath: $rsXPath
    projectId: $projectId
    projectTypeId: $projectTypeId
  ) {
    url
    indexUrl
    state
    errorMsg
    rasterStats
    numFiles
    layers
    format
    originFile {
      contentType
      etag
      localPath
      size
    }
    rsXPath
    tileType
    symbologies
    lastState
    started
    duration
    bounds
    maxZoom
    minZoom
  }
}
    `;

/**
 * __useGetLayerTilesQuery__
 *
 * To run a query within a React component, call `useGetLayerTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayerTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayerTilesQuery({
 *   variables: {
 *      rsXPath: // value for 'rsXPath'
 *      projectId: // value for 'projectId'
 *      projectTypeId: // value for 'projectTypeId'
 *   },
 * });
 */
export function useGetLayerTilesQuery(baseOptions: Apollo.QueryHookOptions<types.GetLayerTilesQuery, types.GetLayerTilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLayerTilesQuery, types.GetLayerTilesQueryVariables>(GetLayerTilesDocument, options);
      }
export function useGetLayerTilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLayerTilesQuery, types.GetLayerTilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLayerTilesQuery, types.GetLayerTilesQueryVariables>(GetLayerTilesDocument, options);
        }
export type GetLayerTilesQueryHookResult = ReturnType<typeof useGetLayerTilesQuery>;
export type GetLayerTilesLazyQueryHookResult = ReturnType<typeof useGetLayerTilesLazyQuery>;
export type GetLayerTilesQueryResult = Apollo.QueryResult<types.GetLayerTilesQuery, types.GetLayerTilesQueryVariables>;