import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { imagesUrl } from '../../config'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {},
  title: {
    m: 0,
    p: 2,
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  actions: {
    m: 0,
    p: 1,
  },
  content: {
    p: 2,
  },
  rasterImgsContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
})

export interface OverZoomedHelpProps {
  open: boolean
  onClose: () => void
}

const OverZoomedHelp: React.FC<OverZoomedHelpProps> = ({ onClose, open }: OverZoomedHelpProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={styles.title}>
        <Typography variant="h6">Map Zoom exceeds raster tile resolution</Typography>
        <IconButton aria-label="close" sx={styles.closeButton} onClick={onClose} color="inherit">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>
          {"For a smoother web experience we've capped the maximum zoom level for rasters "}
          {'we serve on the web. As a result you may notice some rasters get a little blurry '}
          {'at higher zoom levels.'}
        </Typography>
        <Box sx={styles.rasterImgsContainer}>
          <Box sx={styles.rasterImgsContainerCol}>
            <Typography variant="h6">Original</Typography>
            <img src={`${imagesUrl}/desktop_raster.png`} style={{ width: '100%', height: 'auto' }} />
          </Box>
          <Box sx={styles.rasterImgsContainerCol}>
            <Typography variant="h6">Web</Typography>
            <img src={`${imagesUrl}/web_raster.png`} style={{ width: '100%', height: 'auto' }} />
          </Box>
        </Box>
        <Typography paragraph>
          {
            'If you find that you need the extra clarity at high zoom levels please consider downloading this project from the '
          }
          {'"Project Info" tab on the left.'}
        </Typography>
        <Typography paragraph>
          {'You can then use one of our '}
          <Link href="http://rave.riverscapes.net/" target="_blank">
            desktop plugins
          </Link>
          {' (QRAVE for QGIS, ArcRAVE for ESRI ArcGIS) '}
          {'to view these datasets at their maximum resolution.'}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default OverZoomedHelp
