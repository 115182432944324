import { CardContent, CardMedia, Link, Typography } from '@mui/material'

// import log from 'loglevel'
import { HomeCard } from './HomeCard'
import ReactMarkdown from 'react-markdown'
import { fontFamilies } from '@riverscapes/react-theme'

export interface WelcomeCardProps {
  //
}

export const WelcomeCard: React.FC<WelcomeCardProps> = () => {
  return (
    <HomeCard gridWidths={2}>
      {/* <CardHeader title="Welcome to the Riverscapes Data Exchange" /> */}
      <CardMedia component="img" height="140" image={`${process.env.PUBLIC_URL}/bg/mj1.jpg`} />
      <CardContent sx={{ flexGrow: 1, px: 2 }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            // zIndex: 1000,
            // color: 'white',
            // position: 'absolute',
            // top: 15,
            // left: 15,
            // textShadow: '2px 2px 4px #000000',
            fontSize: '1.2rem',
            fontFamily: fontFamilies.jetBrains,
          }}
        >
          Welcome to the Riverscapes Data Exchange
        </Typography>
        <Typography variant="body2" color="text.secondary" component="div">
          <ReactMarkdown
            components={{
              a: ({ children, ...props }) => (
                <Link {...props} target="_blank">
                  {children}
                </Link>
              ),
            }}
          >
            This Data Exchange is a free portal where practitioners can exchange data related to riverscapes, brought to
            you by the [Riverscapes Consortium](https://riverscapes.net). Improving riverscape health takes a community
            committed to creating thriving, healthy ecosystems. Our goal is to make it easier to catalogue, discover,
            share and retrieve data related to riverscapes health. Learn more on our [Knowledge
            Base](https://riverscapes.freshdesk.com/support/solutions/folders/153000068951) or reach out to our
            Application Support Specialists at
            [support@riverscapes.freshdesk.com](mailto:support@riverscapes.freshdesk.com)
          </ReactMarkdown>
        </Typography>
      </CardContent>
    </HomeCard>
  )
}
