import React, { useEffect, useRef, useState } from 'react'
import { useMap } from 'react-map-gl'
import { useRecoilValue } from 'recoil'
import { baseLayerAtom, renderableMapLayersSelector } from '../recoil'
import { changeBaseLayer, userLayerRecalc, verifyLayerOrder } from '../lib/MapManager'
import { isEqual } from 'lodash'
import log from 'loglevel'

const MapLayerConnect: React.FC = () => {
  const { current: map } = useMap()
  const baseLayer = useRecoilValue(baseLayerAtom)
  const renderableMapLayers = useRecoilValue(renderableMapLayersSelector)
  const mapRef = useRef(map)
  const [refreshNeeded, setRefreshNeeded] = useState<{ check: number; base: number; layers: number }>({
    check: 0,
    base: 0,
    layers: 0,
  })

  useEffect(() => {
    if (!mapRef.current) return
    const { check, base, layers } = refreshNeeded
    const newState = { check, base, layers }
    if (base > 0) {
      const c_success = changeBaseLayer(mapRef.current, baseLayer)
      log.debug(`MapLayers::changeBaseLayer: ${c_success}`)
      if (c_success) {
        newState.base = 0
        newState.check += 1 // If we succeeded then we immediately queue up a check
      } else newState.base += 1
    }
    if (layers > 0) {
      const l_success = userLayerRecalc(mapRef.current, renderableMapLayers)
      log.debug(`MapLayers::userLayerRecalc: ${l_success}`)
      if (l_success) {
        newState.layers = 0
        newState.check += 1
      } else newState.layers += 1
    }
    if (check > 0) {
      const v_success = verifyLayerOrder(mapRef.current, renderableMapLayers)
      log.debug(`MapLayers::verifyLayerOrder: ${v_success}`)
      if (v_success) newState.check = 0
      else newState.check += 1
    }
    if (!isEqual(newState, refreshNeeded)) {
      setTimeout(() => {
        log.debug(`MapLayers::setRefreshNeeded`, refreshNeeded, newState)
        setRefreshNeeded(newState)
      }, 500)
    }
  }, [refreshNeeded, baseLayer, renderableMapLayers])

  // Every 3 seconds we refresh the map based on what should be there. This is a bit hack-ey but it
  // prevents a lot of de-sync issues with the map and the tree getting out of date
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setRefreshNeeded({ ...refreshNeeded, check: refreshNeeded.check + 1 })
    }, 3000)
    return () => clearInterval(refreshInterval)
  }, [])

  useEffect(() => {
    setRefreshNeeded({ ...refreshNeeded, base: refreshNeeded.base + 1 })
  }, [baseLayer])
  useEffect(() => {
    setRefreshNeeded({ ...refreshNeeded, layers: refreshNeeded.layers + 1 })
  }, [renderableMapLayers])

  // This is kind of a bogus react component. We need it though in order
  // to execute rebuilding of layers inside the MapContext
  return null
}

export default MapLayerConnect
