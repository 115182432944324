import { Header } from './Header'
import { OverviewPanel } from './OverviewPanel'
import { SettingsPanel } from './SettingsPanel'
import { CollectionsPanel } from './CollectionsPanel'
import { ProjectsPanel } from './ProjectsPanel'

const OrganizationDetail = { Header, OverviewPanel, ProjectsPanel, SettingsPanel, CollectionsPanel }

export { OrganizationDetail }
export { CreateOrganizationDialog } from './CreateOrganizationDialog'
