import { forwardRef } from 'react'
import { theme } from '@riverscapes/react-theme'
import { ThemeProvider, CssBaseline, createTheme, Box } from '@mui/material'
import { deepmerge } from '@mui/utils'
import { ApiProvider, AuthLink, AuthProvider } from '@riverscapes/react-api'
import { BrowserRouter as Router, useRoutes, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

import { RecoilRoot } from 'recoil'

import { AppNotificationsProvider, ErrorBoundary, Welcome } from '@riverscapes/react-common'

import { Header } from './components/Header'
import { routes } from './routing'
import { showWelcome } from './config'
import { cache } from './data'
import log from 'loglevel'
import { InitializeUser } from './components/InitializeUser'
import { AppLoader } from './components/AppLoader'

const LinkComponent = forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  ({ href, ...rest }, ref) => {
    if (!href) return <a {...rest} /> // React Router Link does not tolerate link without "to" prop and throws cryptic error
    return <RouterLink ref={ref} to={href} {...rest} />
  }
)
LinkComponent.displayName = 'Link'

const themeWithRouterLink = createTheme(
  deepmerge(theme, {
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkComponent,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkComponent,
        },
      },
    },
  })
)

export const authLink = new AuthLink(
  {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  },
  {
    onError: (error) => {
      log.warn('authLink error', error)
    },
  }
)

export const App: React.FC = () => {
  return (
    <RecoilRoot>
      {/* <MockedProvider mocks={mockResponses}> */}
      <AuthProvider authLink={authLink} loader={<AppLoader />}>
        <ApiProvider cache={cache} authLink={authLink}>
          <Router>
            <CssBaseline />
            <ThemeProvider theme={themeWithRouterLink}>
              <AppNotificationsProvider>
                {showWelcome && <Welcome />}
                <Box
                  sx={{
                    height: '100%',
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    // border: {
                    //   sm: '2px solid red',
                    //   md: '2px solid green',
                    //   lg: '2px solid blue',
                    //   xl: '2px solid yellow',
                    // },
                  }}
                >
                  <Header />
                  <Box sx={{ flex: 1, height: '0%', overflow: 'scroll' }}>
                    <ErrorBoundary>
                      <InitializeUser />
                      <Routes />
                    </ErrorBoundary>
                  </Box>
                </Box>
              </AppNotificationsProvider>
            </ThemeProvider>
          </Router>
        </ApiProvider>
      </AuthProvider>
      {/* </MockedProvider> */}
    </RecoilRoot>
  )
}

const Routes = () => {
  // NB: THIS IS OUR REDIRECTOR FROM THE OLD WAREHOUSE
  // Basically anything that coles in with a hash route will be redirected to the new redirector component
  if (window.location.hash.match(/^#/))
    window.location.replace(['redirect', ...window.location.hash.split('/').slice(1)].join('/'))
  return useRoutes(routes)
}
