import { useEffect } from 'react'
import log from 'loglevel'
import { atom, useRecoilState } from 'recoil'
import {
  GetCollectionDetailQuery,
  GetOrganizationDetailQuery,
  GetProjectDetailQuery,
  GetUserDetailQuery,
} from '@riverscapes/react-common'

export interface Activity {
  operation: 'view' | 'edit'
  item: NonNullable<
    | GetProjectDetailQuery['project']
    | GetCollectionDetailQuery['collection']
    | GetOrganizationDetailQuery['organization']
    | GetUserDetailQuery['user']
  >
}

interface useActivityReturn {
  activities: Activity[]
  addActivity: (newActivity: Activity) => void
  purgeById: (targetId: string) => void
}

const MAX_ACTIVITIES = 100
const STORAGE_KEY = 'warehouse-app.activities'
const FORMAT_VERSION = '2022-07-26' // change to invalidate and overwrite old storage

const activitiesAtom = atom<Activity[]>({
  key: 'activitiesAtom',
  default: [],
})

export const useActivity = (): useActivityReturn => {
  const [activities, setActivities] = useRecoilState(activitiesAtom)

  useEffect(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY)
      if (!stored) return
      const { version, activities } = JSON.parse(stored)
      if (version !== FORMAT_VERSION) return
      setActivities(activities)
    } catch (err) {
      log.error(`Failed to parse localStorage ${STORAGE_KEY}`)
    }
  }, [])

  useEffect(() => {
    if (!activities.length) return // prevent overwrite of actual contents
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ version: FORMAT_VERSION, activities }))
  }, [activities])

  const addActivity = (incoming: Activity) => {
    setActivities((currentActivities) => {
      const newActivities = currentActivities.filter(
        (existing) =>
          !(
            existing.item.__typename === incoming.item.__typename &&
            existing.item.id === incoming.item.id &&
            existing.operation === incoming.operation
          )
      )
      newActivities.unshift(incoming)
      newActivities.length = Math.min(newActivities.length, MAX_ACTIVITIES)
      return newActivities
    })
  }

  const purgeById = (targetId: string) => {
    setActivities((currentActivities) => currentActivities.filter(({ item }) => item.id !== targetId))
  }

  return { activities, addActivity, purgeById }
}
