/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Box, BoxProps } from '@mui/material'
import qrcode from 'qrcode'

export interface QrCodeProps extends BoxProps {
  url: string
  borderCorners?: string
}

export const QrCode: React.FC<QrCodeProps> = ({ url, borderCorners, ...boxProps }) => {
  const [uri, setUri] = useState('')

  useEffect(() => {
    qrcode.toDataURL(url).then(setUri)
  }, [url])

  if (!uri) return null

  return (
    <Box position="relative" {...boxProps}>
      {borderCorners && (
        <>
          <div
            css={{
              position: 'absolute',
              width: '15%',
              height: '15%',
              borderTop: borderCorners,
              borderLeft: borderCorners,
              top: 0,
              left: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              width: '15%',
              height: '15%',
              borderTop: borderCorners,
              borderRight: borderCorners,
              top: 0,
              right: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              width: '15%',
              height: '15%',
              borderBottom: borderCorners,
              borderRight: borderCorners,
              bottom: 0,
              right: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              width: '15%',
              height: '15%',
              borderBottom: borderCorners,
              borderLeft: borderCorners,
              bottom: 0,
              left: 0,
            }}
          />
        </>
      )}
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={uri}
      />
    </Box>
  )
}
