import { Skeleton, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { PendingItem } from './PendingItem'

export const PendingCell: React.FC = () => {
  return (
    <Typography sx={{ width: '100%' }}>
      <Skeleton />
    </Typography>
  )
}

export const PendingCellRenderer: React.FC<GridRenderCellParams<any, PendingItem | any>> = ({ value, row }) => {
  if (row.__typename === 'PendingItem') return <PendingCell />
  return value
}
