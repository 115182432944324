import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <React.Fragment>
    <path
      d=" M 5.932 4.519 C 5.976 4.353 6 4.179 6 4 C 6 2.896 5.104 2 4 2 C 2.896 2 2 2.896 2 4 L 2 4 L 2 4 L 2 4 L 2 4 C 2 4.74 2.402 5.386 3 5.732 L 3 18.268 C 2.402 18.614 2 19.26 2 20 L 2 20 L 2 20 L 2 20 L 2 20 C 2 21.104 2.896 22 4 22 C 4.74 22 5.386 21.598 5.732 21 L 18.268 21 L 18.268 21 C 18.614 21.598 19.26 22 20 22 C 21.104 22 22 21.104 22 20 C 22 19.26 21.598 18.614 21 18.268 L 21 9.732 L 21 9.732 C 21.598 9.386 22 8.74 22 8 C 22 6.896 21.104 6 20 6 C 18.937 6 18.067 6.831 18.004 7.878 L 13.1 10.33 C 12.784 10.121 12.406 10 12 10 C 11.822 10 11.65 10.023 11.486 10.067 L 5.932 4.519 Z  M 5 18.268 C 5.303 18.444 5.556 18.697 5.732 19 L 18.268 19 C 18.444 18.697 18.697 18.444 19 18.268 L 19 9.732 C 18.966 9.712 18.933 9.692 18.9 9.67 L 13.996 12.122 C 13.933 13.169 13.063 14 12 14 C 10.896 14 10 13.104 10 12 L 10 12 C 10 11.822 10.023 11.649 10.067 11.485 L 5 6.41 L 5 18.268 Z "
      fillRule="evenodd"
    />
  </React.Fragment>,
  'Polygon'
)
