import { Stack } from '@mui/material'
import { GetProfileDetailQuery, GetUserDetailQuery } from '../../schema/base'
import { ChangePassword, ChangePasswordProps } from '../ChangePassword'

export interface SettingsPanelProps {
  user: NonNullable<GetUserDetailQuery['user']> | NonNullable<GetProfileDetailQuery['profile']>
  changePassword: ChangePasswordProps['changePassword']
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({ changePassword }) => {
  return (
    <Stack direction="row">
      <ChangePassword changePassword={changePassword} />
    </Stack>
  )
}
