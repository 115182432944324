export const isProject = <T>(item: T): item is Extract<T, { __typename: 'Project' }> => {
  if ((item as any).__typename === 'Project') return true
  return false
}

export const isCollection = <T>(item: T): item is Extract<T, { __typename: 'Collection' }> => {
  if ((item as any).__typename === 'Collection') return true
  return false
}

export const isOrganization = <T>(item: T): item is Extract<T, { __typename: 'Organization' }> => {
  if ((item as any).__typename === 'Organization') return true
  return false
}

export const isOrganizationInvite = <T>(item: T): item is Extract<T, { __typename: 'OrganizationInvite' }> => {
  if ((item as any).__typename === 'OrganizationInvite') return true
  return false
}

export const isUser = <T>(item: T): item is Extract<T, { __typename: 'User' }> => {
  if ((item as any).__typename === 'User') return true
  return false
}

export const isProfile = <T>(item: T): item is Extract<T, { __typename: 'Profile' }> => {
  if ((item as any).__typename === 'User') return true
  return false
}

export const isSavedSearch = <T>(item: T): item is Extract<T, { __typename: 'SavedSearch' }> => {
  if ((item as any).__typename === 'SavedSearch') return true
  return false
}

export const isProjectType = <T>(item: T): item is Extract<T, { __typename: 'ProjectType' }> => {
  if ((item as any).__typename === 'ProjectType') return true
  return false
}

export const isDataset = <T>(item: T): item is Extract<T, { __typename: 'Dataset' }> => {
  if ((item as any).__typename === 'Dataset') return true
  return false
}

export const isQaqcEvent = <T>(item: T): item is Extract<T, { __typename: 'QAQCEvent' }> => {
  if ((item as any).__typename === 'QAQCEvent') return true
  return false
}

export const isProjectSearchMeta = <T>(item: T): item is Extract<T, { __typename: 'ProjectSearchMeta' }> => {
  if ((item as any).__typename === 'ProjectSearchMeta') return true
  return false
}

export const isPendingItem = <T>(item: T): item is Extract<T, { __typename: 'PendingItem' }> => {
  if ((item as any).__typename === 'PendingItem') return true
  return false
}
