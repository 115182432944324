import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { GetProfileDetailQuery, GetUserDetailQuery } from '../../schema/base'
import { DetailPageHeader } from '../DetailPage'
import { AvatarImage } from '../ItemImage'
import { HeroColor } from '../HeroImage'
import { ImageManagerDialogProps } from '../ImageManager'
import { Lookups } from '../lookups'
import { EditableTypography } from '../EditableTypography'
import { validate } from '../../lib'

interface UserHeaderProps {
  user: NonNullable<GetUserDetailQuery['user']>
  profile: NonNullable<GetProfileDetailQuery['profile']>
  lookups: Lookups
}

interface ProfileHeaderProps {
  user: NonNullable<GetProfileDetailQuery['profile']>
  getAvatarUploadTarget: ImageManagerDialogProps['getUploadTarget']
  lookups: Lookups
  email?: string
  onAvatarChange: (newToken: string | null) => Promise<unknown>
  onNameChange: (newValue: string) => void
  onSummaryChange: (newValue: string) => void
}

export type HeaderProps = UserHeaderProps | ProfileHeaderProps

const isProfileHeaderProps = (props: HeaderProps): props is ProfileHeaderProps => {
  return props.user.__typename === 'Profile'
}

export const Header: React.FC<HeaderProps> = (props) => {
  const isProfile = isProfileHeaderProps(props)

  const { user, lookups } = props
  const { email } = props as ProfileHeaderProps
  return (
    <DetailPageHeader>
      <HeroColor />
      <Container maxWidth="lg">
        <Stack>
          <Box sx={{ minHeight: 45 }}>
            {isProfile && <Button href={lookups.getUserUrlById(user.id)}>View Public User</Button>}
            {!isProfile && props.profile.id === user.id && (
              <Button href={lookups.getProfileUrl()}>Go To Profile</Button>
            )}
          </Box>
          <Stack direction="row" spacing={2} alignItems="flex-start" px={2}>
            {isProfile ? (
              <AvatarImage
                item={user}
                size={90}
                canEdit={true}
                getUploadTarget={props.getAvatarUploadTarget}
                onChange={props.onAvatarChange}
                lookups={props.lookups}
              />
            ) : (
              <AvatarImage item={user} size={90} />
            )}
            <Box>
              <EditableTypography
                canEdit={isProfile}
                content={user.name}
                getError={validate.name}
                name="Name"
                onChange={(props as ProfileHeaderProps).onNameChange}
                variant="h2"
              />
              {email && <Typography variant="subtitle1">{email}</Typography>}
              <EditableTypography
                content={user.summary}
                canEdit={isProfile}
                getError={validate.summary}
                name="Short Bio"
                onChange={(props as ProfileHeaderProps).onSummaryChange}
                // headerMode
                placeholder={isProfile && <></>}
              />
            </Box>
          </Stack>
        </Stack>
      </Container>
    </DetailPageHeader>
  )
}
