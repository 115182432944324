import { Stack, Card, CardContent, Link, Typography, useMediaQuery } from '@mui/material'
import { GetProfileDetailQuery, GetUserDetailQuery } from '../../schema/base'
import { useFormats, validate } from '../../lib'
import { Lookups } from '../lookups'
import { useTheme } from '@mui/system'
import { CSSProperties } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { EditableTypography } from '../EditableTypography'
import { OverviewCard } from '../Overview/OverviewCard'
import { OverviewBasicProperties } from '../Overview/OverviewBasicProperties'

export interface OverviewPanelProps {
  user: NonNullable<GetUserDetailQuery['user']> | NonNullable<GetProfileDetailQuery['profile']>
  lookups: Lookups
  onDescriptionChange?: (newDescription: string) => Promise<unknown>
}

export const OverviewPanel: React.FC<OverviewPanelProps> = ({ user, lookups, onDescriptionChange }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const { formatNumber, formatDateTime, formatTimeAgo } = useFormats()
  const canEdit = user && user.__typename === 'Profile'

  const userProjectsUrl = lookups.getUserUrlById(user.id, 'projects')
  const userCollectionsUrl = lookups.getUserUrlById(user.id, 'collections')

  const scrollContainer: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
      }
    : {}
  const scrollObjInner: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
      }
    : {}

  return (
    <Grid2 container spacing={3} sx={scrollContainer}>
      <Grid2 xs={12} sm={12} md={8} sx={scrollObjInner}>
        <OverviewCard title="User Details">
          <OverviewBasicProperties
            propTable={[
              { label: 'User Name', value: user.name },
              { label: 'User Id', value: <code>{user.id}</code> },
              { label: 'Member Since', value: formatDateTime(user.createdOn) },
            ]}
          />
        </OverviewCard>
        <OverviewCard title="Bio">
          <EditableTypography
            canEdit={canEdit}
            content={user.description}
            getError={validate.description}
            multiline
            name="Bio"
            onChange={onDescriptionChange}
            placeholder={'No Bio.'}
            variant="markdown"
          />
        </OverviewCard>
      </Grid2>
      <Grid2 xs={12} sm={12} md={4}>
        <Card>
          <CardContent sx={{ pt: 3 }}>
            <Stack
              sx={{ minWidth: 70, height: 50, flexShrink: 0 }}
              alignItems="center"
              justifyContent="space-around"
              direction={'row'}
            >
              <Link href={userProjectsUrl}>
                <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
                  <Typography variant="overline">Projects Owned</Typography>
                  <Typography variant="h3">{formatNumber(user.projects.total)}</Typography>
                  {/* <Typography variant="overline">{pluralize('project', collection.projects.total)}</Typography> */}
                </Stack>
              </Link>
              <Link href={userCollectionsUrl}>
                <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
                  <Typography variant="overline">Collections</Typography>
                  <Typography variant="h3">{formatNumber(user.collections.total)}</Typography>
                  {/* <Typography variant="overline">{pluralize('project', collection.projects.total)}</Typography> */}
                </Stack>
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  )
}
