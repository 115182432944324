import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { SymbologyKey, symbologyAtomFamily, symbologyChangeCounterAtomFamily } from '../recoil'
import SymbologyTool from '../components/SymbologyTool'
import { Symbology } from '@riverscapes/react-common'

interface Props {
  symbologyKey: SymbologyKey
  handleClose: () => void
}

const SymbologyToolConnect: React.FC<Props> = ({ symbologyKey, handleClose }: Props) => {
  const [symbology, _setSymbology] = useRecoilState(symbologyAtomFamily(symbologyKey))
  const setCount = useSetRecoilState(symbologyChangeCounterAtomFamily(symbologyKey))

  const setSymbology = (newSymb: Symbology) => {
    _setSymbology(newSymb)
    setCount((symbologyCount) => symbologyCount + 1)
  }
  return <SymbologyTool handleClose={handleClose} symbology={symbology as Symbology} setSymbology={setSymbology} />
}

export default SymbologyToolConnect
