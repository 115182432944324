import { useContext, createContext } from 'react'
import log from 'loglevel'
import { Lookups } from '@riverscapes/react-common'

export type ProjectContextType = {
  projectId?: string
  lookups: Lookups
  gotoWHProject: (projId: string) => void
}

export const ProjectContext = createContext<ProjectContextType>({
  lookups: {} as Lookups,
  gotoWHProject: (projId: string) => {
    log.error('No gotoWarehouse function provided')
  },
})

export const useProjectContext = (): ProjectContextType => {
  const ctx = useContext<ProjectContextType>(ProjectContext)
  return ctx
}
