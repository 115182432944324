import { Color } from '@mui/material'
import { blue, brown, cyan, green, lime, orange, purple, red } from '@mui/material/colors'
// import log from 'loglevel'
import MapboxGL from 'mapbox-gl'
import baseLayers from './mapStyles'
import { BoundsObj } from './types'

export const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development'
export const version = process.env.REACT_APP_VERSION

export const endpoints = {
  graphql: `${process.env.REACT_APP_GRAPHQL}` as string,
}

export const warehouseIconUrl = process.env.PUBLIC_URL + '/data-exchange-icon.svg'
export const warehouseUrl = process.env.REACT_APP_WAREHOUSE_URL
export const imagesUrl = process.env.PUBLIC_URL + '/static'

/**
 * Here are the App defaults we might want to change
 */
export const appDefaults = {
  appName: 'Riverscapes Project Viewer (webRAVE)',
  zoomRange: [2, 18], // Think of this like the min and max possible zooms
  apiTimeout: 20000, // ms timeout for API Queries
  defaultViewport: {
    zoom: 8,
  },
  worldBounds: [-141, 23, -50, 70] as BoundsObj, // the maximum possible bounds
  baseLayer: baseLayers[0],
  drawerOpen: true,
  init: false,
  minFilterTextLength: 3,
  maxMapLayers: 5,
  retryDelayS: 2,
  refreshDelayS: 20,
}

export const initMapStyle: Partial<MapboxGL.Style> = {
  version: 8,
  name: 'WebRaveStyle',
  sprite: 'mapbox://sprites/northarrowresearch/ckmuw9x2e5f0417pe3vy7y0cz/55jsegcrpjhdm8ibxtin56tly',
  glyphs: 'mapbox://fonts/northarrowresearch/{fontstack}/{range}.pbf',
}

export const appRouterPath = '/:programId/:projectId/:config?'

export const mandatoryMetaColors: Color[] = [red, green, blue, orange, purple, cyan, lime, brown]
// These are used if the symbology fails to load
export const legendColors: Color[] = [red, green, blue, orange, purple, cyan, lime, brown]

export const redirectUrl = new URL(
  (process.env.PUBLIC_URL as string).endsWith('/')
    ? (process.env.PUBLIC_URL as string)
    : (process.env.PUBLIC_URL as string) + '/',
  window.location.origin
)
