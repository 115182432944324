/**
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_(JavaScript/ActionScript,_etc.)
 */
type BoundingBox = [number, number, number, number]

const toDegrees = (radians) => (radians * 180) / Math.PI

const tile2Lon = (x: number, z: number): number => {
  return (x / Math.pow(2.0, z)) * 360.0 - 180
}

const tile2Lat = (y: number, z: number): number => {
  const n = Math.PI - (2.0 * Math.PI * y) / Math.pow(2.0, z)
  return toDegrees(Math.atan(Math.sinh(n)))
}

function lon2xtile(lon: number, z: number): number {
  return Math.floor(((lon + 180) / 360) * Math.pow(2, z))
}
function lat2ytile(lat: number, z: number): number {
  return Math.floor(
    ((1 - Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) / 2) *
      Math.pow(2, z)
  )
}

/**
 *  [minLng, minLat, maxLng, maxLat]
 * @param x
 * @param y
 * @param zoom
 * @returns
 */
export const tile2BoundingBox = (x: number, y: number, z: number): BoundingBox => {
  return [tile2Lon(x, z), tile2Lat(y, z), tile2Lon(x + 1, z), tile2Lat(y + 1, z)]
}

export const bounds2Tiles = (bounds: BoundingBox, z: number): [number, number, number][] => {
  const minXTile = lon2xtile(bounds[0], z)
  const maxXTile = lon2xtile(bounds[2], z)
  const minYTile = lat2ytile(bounds[1], z)
  const maxYTile = lat2ytile(bounds[3], z)
  const tiles = []

  for (let x = minXTile; x < maxXTile; x++) {
    for (let y = minYTile; y < maxYTile; y++) {
      tiles.push([x, y, z])
    }
  }
  if (tiles.length === 0) {
    tiles.push([minXTile, minYTile, z])
  }
  return tiles
}
