import { LibraryBooks } from '@mui/icons-material'

export interface CollectionImageProps {
  alt?: string
  size: number
}

export const CollectionImage: React.FC<CollectionImageProps> = ({ size, alt }) => {
  // return <img src={DEFAULT_IMG_URL} css={{ height: size, width: size, backgroundColor: 'white' }} alt={alt} />
  return <LibraryBooks color="inherit" sx={{ height: size, width: size }} />
}
