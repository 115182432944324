import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { EmailIcon, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share'
import { useBooleanState, withTooltip } from '../../lib'
import { CopyIcon, ShareIcon } from '../Icons'
import { QrCode } from '../QrCode'
import { useTheme } from '@mui/system'

export interface ShareDialogProps {
  onClose: () => void
  open: boolean
  title: React.ReactNode
  url: string
}

const ICON_SIZE = 40

export const ShareDialog: React.FC<ShareDialogProps> = ({ open, onClose, title, url }) => {
  const theme = useTheme()
  const handleShareWindowClose = () => {
    // TODO: auto-close?
  }

  const [isTooltipOpen, openTooltip, closeTooltip] = useBooleanState()

  const fullUrl = url.match(/https?:\/\//) ? url : `${window.location.origin}${url}`

  const handleCopy = () => {
    navigator.clipboard.writeText(fullUrl)
    openTooltip()
    window.setTimeout(closeTooltip, 2000)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
          mb: 1,
          pl: 1,
          display: 'flex',
        }}
      >
        <ShareIcon sx={{ mr: 1 }} />
        <Typography variant="h5">Share: {title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={2}>
          <QrCode url={fullUrl} style={{ width: '200px', height: '200px' }} borderCorners="1px solid black" />
          <Stack direction="column" gap={2} flex={1}>
            <Stack direction="row" alignItems="center">
              <div style={{ padding: '0.4em', border: '1px solid grey', flex: 1 }}>{fullUrl}</div>
              {withTooltip(
                <IconButton onClick={handleCopy}>
                  <CopyIcon />
                </IconButton>,
                isTooltipOpen ? 'Link Copied!' : 'Copy Link To Clipboard'
              )}
            </Stack>
            <Stack direction="row" gap={1}>
              <FacebookShareButton url={fullUrl} onShareWindowClose={handleShareWindowClose}>
                <FacebookIcon size={ICON_SIZE} />
              </FacebookShareButton>
              <LinkedinShareButton url={fullUrl} onShareWindowClose={handleShareWindowClose}>
                <LinkedinIcon size={ICON_SIZE} />
              </LinkedinShareButton>
              <a
                href={`mailto:?body=${encodeURIComponent(fullUrl)}&subject=${encodeURIComponent(`Riverscapes`)}`}
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon size={ICON_SIZE} />
              </a>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
