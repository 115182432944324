import { useState } from 'react'
import { useRef } from 'react'
import { useMemo } from 'react'

type SetFieldsSingleSetters<TFields> = {
  [FieldKey in keyof TFields]: (value: React.SetStateAction<TFields[FieldKey]>) => void
}

type SetFields<TFields> = {
  (value: React.SetStateAction<TFields>): void
} & {
  $: SetFieldsSingleSetters<TFields>
  reset: () => void
}

type ProcessFieldsFn<TFields, TProcessedFields> = (fields: TFields) => TProcessedFields
type IsEqualFn<TProcessedFields> = (
  processedFields: TProcessedFields,
  cleanProcessedFields: TProcessedFields
) => boolean
type Deps = any[]

type UseFieldsReturn<TFields, TProcessedFields> = [
  fields: TFields,
  setFields: SetFields<TFields>,
  processedFields: TProcessedFields,
  isDirty: boolean
]

export function useFields<TFields extends Record<string, any>>(
  fields: TFields,
  deps?: Deps
): UseFieldsReturn<TFields, TFields>
export function useFields<TFields extends Record<string, any>, TProcessedFields>(
  fields: TFields,
  processFields: ProcessFieldsFn<TFields, TProcessedFields>,
  deps?: Deps
): UseFieldsReturn<TFields, TProcessedFields>
export function useFields<TFields extends Record<string, any>, TProcessedFields>(
  fields: TFields,
  processFields: ProcessFieldsFn<TFields, TProcessedFields>,
  isEqualFn: IsEqualFn<TProcessedFields>,
  deps?: Deps
): UseFieldsReturn<TFields, TProcessedFields>

export function useFields<TFields extends Record<string, any>, TProcessedFields>(
  fields: TFields,
  processFieldsOrMaybeDeps?: ProcessFieldsFn<TFields, TProcessedFields> | Deps,
  isEqualFnOrMaybeDeps?: IsEqualFn<TProcessedFields> | Deps,
  maybeDeps?: Deps
): UseFieldsReturn<TFields, TProcessedFields> {
  const [initialFields, processFields, isEqualFn, deps] = (() => {
    const defaultProcessFields: ProcessFieldsFn<TFields, TProcessedFields> = (fields) =>
      fields as any as TProcessedFields
    const defaultIsEqualFn: IsEqualFn<TProcessedFields> = (processedFields, cleanProcessedFields): boolean =>
      processedFields === cleanProcessedFields
    const defaultDeps: Deps = []

    if (typeof processFieldsOrMaybeDeps === 'function' && typeof isEqualFnOrMaybeDeps === 'function')
      return [fields, processFieldsOrMaybeDeps, isEqualFnOrMaybeDeps, maybeDeps ?? defaultDeps]

    if (typeof processFieldsOrMaybeDeps === 'function' && typeof isEqualFnOrMaybeDeps !== 'function')
      return [fields, processFieldsOrMaybeDeps, defaultIsEqualFn, isEqualFnOrMaybeDeps ?? defaultDeps]

    if (typeof processFieldsOrMaybeDeps !== 'function' && typeof isEqualFnOrMaybeDeps !== 'function')
      return [fields, defaultProcessFields, defaultIsEqualFn, processFieldsOrMaybeDeps ?? defaultDeps]

    throw new Error('Invalid arguments')
  })()

  // clean state
  const getCleanState = () => {
    const initialprocessedFields = processFields(initialFields)
    return {
      fields: initialFields,
      processedFields: initialprocessedFields,
      cleanProcessedFields: initialprocessedFields,
    }
  }

  // state
  const [_, setRenderKey] = useState({})
  const reRender = () => setRenderKey({})
  const stateRef = useRef({} as ReturnType<typeof getCleanState>)
  useMemo(() => {
    stateRef.current = getCleanState()
  }, deps)
  const setState = (nextState: typeof stateRef.current) => {
    stateRef.current = nextState
    reRender()
  }

  // setFields
  const setFields = useMemo<SetFields<TFields>>(() => {
    // setFields main
    const setFieldsFn = (nextFieldsOrCallback: TFields | ((prevFields: TFields) => TFields)) => {
      const fields =
        typeof nextFieldsOrCallback === 'function'
          ? nextFieldsOrCallback(stateRef.current.fields)
          : nextFieldsOrCallback
      const processedFields = processFields(fields)
      setState({
        ...stateRef.current,
        fields,
        processedFields,
      })
    }

    // setFields.$ single setters
    setFieldsFn.$ = Object.keys(stateRef.current.fields).reduce((acc, fieldKey) => {
      return {
        ...acc,
        [fieldKey]: (newValueOrCallback: any | ((prevValue: any) => any)) => {
          const nextFieldValue =
            typeof newValueOrCallback === 'function'
              ? newValueOrCallback(stateRef.current.fields[fieldKey])
              : newValueOrCallback

          const fields = { ...stateRef.current.fields, [fieldKey]: nextFieldValue }
          const processedFields = processFields(fields)
          setState({
            ...stateRef.current,
            fields,
            processedFields,
          })
        },
      }
    }, {} as SetFieldsSingleSetters<TFields>)

    // setFields.reset
    setFieldsFn.reset = () => {
      stateRef.current = getCleanState()
      reRender()
    }

    return setFieldsFn
  }, [])

  // isDirty
  const isDirty = !isEqualFn(stateRef.current.processedFields, stateRef.current.cleanProcessedFields)

  return [stateRef.current.fields, setFields, stateRef.current.processedFields, isDirty]
}

export const someValues = <T extends Record<string, any>>(objectOrCallback: T | (() => T)): T | undefined => {
  const o = typeof objectOrCallback === 'function' ? objectOrCallback() : objectOrCallback
  if (!o) return undefined
  return Object.values(o).some((value) => value) ? o : undefined
}
