import { Box } from '@mui/material'
import { Lookups, Project, useDownload } from '@riverscapes/react-common'
import React, { useEffect, useMemo } from 'react'
import { useRecoilValue, RecoilRoot, useSetRecoilState, useRecoilState } from 'recoil'
import AppSplitter from './components/AppSplitter'
import useColourFallback from './hooks/useColourFallback'
import {
  activeIdsAtom,
  drawerOpenAtom,
  projectAtom,
  projectDSXPathsSelector,
  projectSymbologiesSelector,
  treeExpandedIdsAtom,
} from './recoil'
import { useWebRaveProjectQuery } from './schema/operations'
import TreeDrawer from './components/TreeDrawer'
import TreeControlConnect from './connectors/Treecontrol.connect'
import ProjectInfoTab from './components/ProjectInfoTab'
import MapConnect from './connectors/Map.connect'
import useViews from './hooks/useViews'
import MapTilesConnect from './connectors/MapTiles.connect'
import { MapSymbologiesConnect } from './connectors/MapSymbologies.connext'
import { useFetchAllWithPendingItems } from './hooks/useFetchAllWithPending'
import log from 'loglevel'
import { ProjectContext, useProjectContext } from './hooks/useProjectContext'
import { AccountTree, Info } from '@mui/icons-material'
import { MapProvider } from 'react-map-gl'

const Inner: React.FC = () => {
  const { projectId, gotoWHProject } = useProjectContext()
  const drawerOpen = useRecoilValue(drawerOpenAtom)
  const { downloadZipFile, pendingZips } = useDownload()
  const [project, setProject] = useRecoilState(projectAtom)
  const projectDSXPaths = useRecoilValue(projectDSXPathsSelector)
  const projectSymbologies = useRecoilValue(projectSymbologiesSelector)
  useColourFallback()
  const setActiveIds = useSetRecoilState(activeIdsAtom)

  const setExpanded = useSetRecoilState(treeExpandedIdsAtom)
  const { resetView } = useViews()

  const { data, fetchMore } = useWebRaveProjectQuery({
    variables: {
      id: projectId as string,
      dsLimit: 50,
      dsOffset: 0,
    },
    skip: !projectId,
    onCompleted: (data) => {
      setProject(data.project as Project)
    },
  })
  // Make sure we paginate everything
  useFetchAllWithPendingItems(fetchMore, data?.project?.datasets.items, data?.project?.datasets.total)

  // Here we set the default expanded nodes when the project loads
  useEffect(() => {
    if (!project || !project.tree) {
      setExpanded([])
      document.title = 'RAVE'
    } else {
      if (project.name) {
        document.title = project.name
      }
      setActiveIds([])
      resetView() // reset to the default view
      setExpanded(project.tree.branches.filter((br) => !br.collapsed).map((br) => `b-${br.bid}`))
    }
  }, [project])

  const mapComponent = useMemo(() => <MapConnect />, [])
  const treeComponent = useMemo(
    () => (
      <TreeDrawer
        backToProject={() => project?.id && gotoWHProject(project?.id)}
        project={project}
        loading={!project}
        tabs={[
          {
            label: 'Layers',
            icon: <AccountTree />,
            value: <TreeControlConnect />,
          },
          {
            label: 'Info',
            icon: <Info />,
            value: project ? (
              <ProjectInfoTab
                project={project}
                downloadZipFile={() => downloadZipFile(projectId as string)}
                pendingZips={pendingZips}
              />
            ) : (
              <Box />
            ),
          },
        ]}
      />
    ),
    [project, pendingZips, downloadZipFile, gotoWHProject, projectId]
  )

  // A bit of a cop-out but if the project is deleted we just go back to the WH project and let that
  // handle the error
  if (project && project.deleted) {
    gotoWHProject(project.id)
  }

  return (
    <MapProvider>
      <Box sx={{ height: '100%' }}>
        {projectSymbologies.map((skey) => (
          <MapSymbologiesConnect key={`symbology-${skey[0]}`} symbologyKey={skey} />
        ))}
        {projectDSXPaths.map((rsXPath) => (
          <MapTilesConnect key={`plp-${rsXPath}`} rsXPath={rsXPath} />
        ))}
        <AppSplitter
          open={drawerOpen}
          split="vertical"
          primary="second"
          minSize={500}
          maxSize="85%"
          defaultSize="75%"
          child1={treeComponent}
          child2={mapComponent}
        />
      </Box>
    </MapProvider>
  )
}

export type WebRaveProps = {
  id: string
  lookups: Lookups
  gotoProject: (projId: string) => void
}

/**
 * WebRAVE lives in its own little world so we need to wrap it in a RecoilRoot
 * This state should remain isolated from the data exchange state
 * @param param0
 * @returns
 */
export const WebRave: React.FC<WebRaveProps> = ({ id, gotoProject, lookups }) => {
  return (
    <ProjectContext.Provider
      value={{
        lookups,
        projectId: id,
        gotoWHProject: gotoProject,
      }}
    >
      <RecoilRoot>
        <Inner />
      </RecoilRoot>
    </ProjectContext.Provider>
  )
}
