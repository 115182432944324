import { WebRave } from '@riverscapes/react-webrave'
import { projectDetail, useGotoRoute } from '../routing'
import { useLookups } from '../lib'

export const WebRaveDetail: React.FC<WebRaveDetailProps> = ({ id }) => {
  const gotoProject = useGotoRoute(projectDetail)
  const lookups = useLookups()
  return <WebRave id={id} gotoProject={(projId: string) => gotoProject({ id: projId })} lookups={lookups} />
}

export interface WebRaveDetailProps extends JSX.IntrinsicAttributes {
  id: string
}
