/** @jsxImportSource @emotion/react */
export const ShiftRight: React.FC = () => {
  return (
    <div
      style={{
        marginLeft: 'auto', // this is deliberately style to take high precedence
      }}
    />
  )
}
