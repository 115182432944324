import { atom, selector, atomFamily } from 'recoil'
import { appDefaults } from '../config'
import { BaseLayer, BoundsObj } from '../types'
import { newBounds } from '../lib/util'
import { layerStateSelectorFamily, leafRandomColAtomFamily, MapLayerState } from './layers'
import { activeIdsAtom, activeLeavesSortedSelector, projectAtom } from './project'
import { MapSize } from '../types'
import { ViewState } from 'react-map-gl'
import { TileService, TilingStateEnum } from '@riverscapes/react-common'
import log from 'loglevel'

/****************************************
 * Misc Map State
 ****************************************/

export const baseLayerAtom = atom<BaseLayer>({
  key: 'map/baseLayer',
  default: appDefaults.baseLayer,
})

/**
 * What state the viewport is currently in. I'd like this to be ZOOM and LAT/LNG so we can code it into the url
 */
export const viewportAtom = atom<ViewState>({
  key: 'map/viewport',
  default: appDefaults.defaultViewport as ViewState,
})

export const mapSizeAtom = atom<MapSize | null>({
  key: 'map/mapSize',
  default: null,
})

export const mapErrorAtom = atom<string>({
  key: 'map/mapError',
  default: '',
})

export const mapLoadedAtom = atom<boolean>({
  key: 'map/mapLoaded',
  default: false,
})

export interface RenderableMapLayer extends MapLayerState {
  randomColor: number | null
  lid: number
}

/**
 * This selector aims to find the difference between what's requested on the map and return only
 * what's actually renderable. This is important because if 'beforeId' references a dud layer
 * there can be problems so it's important that Mapbox only try to render layers we can actually show
 */
export const renderableMapLayersSelector = selector<RenderableMapLayer[]>({
  key: 'map/renderableMapLayers',
  get: ({ get }) => {
    // TODO: If we want to allow layer re-ordering we need to not sort like this

    const activeLeavesSorted = get(activeLeavesSortedSelector)
    const usableLayers = activeLeavesSorted
      .map(({ id }) => get(layerStateSelectorFamily(id)))
      .filter(({ tileState }) => tileState === TilingStateEnum.Success)
    const rndCols = usableLayers.map(({ leaf: { id } }) => get(leafRandomColAtomFamily(id)))

    const retVal = usableLayers.reduce<RenderableMapLayer[]>((acc, lyr, idx) => {
      const id = lyr.leaf.id
      const retVal: RenderableMapLayer = {
        ...lyr,
        randomColor: rndCols[idx],
        lid: id,
      }
      return [...acc, retVal]
    }, [])
    return retVal
  },
})

/**
 * Which view is currently active.\
 */
export const activeViewAtom = atom<string | null>({
  key: 'map/view',
  default: null,
})

/**
 * The zoom bound limits [minz, maxz]
 */
export const zoomBoundsAtom = atom<[number, number]>({
  key: 'map/zoomBounds',
  default: [appDefaults.zoomRange[0], appDefaults.zoomRange[1]],
})
/**
 * THis selector is what the map will use to read the bounds. It has two functions
 * 1. It will always return something valid (even though bounds can be null)
 * 2. it groups bounds and zoombounds together since we use them that way a lot
 */
export const boundsSelector = selector<{ zoom: [number, number]; bounds: BoundsObj | null }>({
  key: 'bounds',
  get: ({ get }) => {
    const proj = get(projectAtom)
    const zoomBounds = get(zoomBoundsAtom)
    // The ideal case is for bounds to include the bounds from layers on the map
    const activeLayerBounds: BoundsObj | null = get(activeIdsAtom)
      .map((id) => get(layerStateSelectorFamily(id)))
      .filter(({ legendActive, tiles }) => legendActive === true && tiles?.bounds)
      .map(({ tiles, leaf }) => {
        log.debug('boundsSelector::FoundTile', leaf.label, tiles?.bounds)
        return tiles?.bounds as BoundsObj
      })
      .reduce((acc, bounds) => newBounds(acc, bounds), null as BoundsObj | null)

    // The final fallback will be the project bounds or just the whole world
    const projBounds = (proj?.bounds?.bbox as BoundsObj) || null
    // Here are the 3 fallbacks for bounds
    const finalBounds = {
      zoom: zoomBounds,
      // Let's always join the project bounds to the activeLayer bounds just in case
      bounds: newBounds(activeLayerBounds, projBounds) || appDefaults.worldBounds,
    }

    log.debug('boundsSelector', finalBounds)
    return finalBounds
  },
})

/****************************************
 * Tile Service Library
 ****************************************/

export const usableIdsAtom = atom<number[]>({
  key: 'tiles/usableIds',
  default: [],
})

export const tilesAtomFamily = atomFamily<TileService | null, string>({
  key: 'tiles/tile',
  default: null,
})
