import { AllMyStars, AnyStarItem } from '../../types'
import React, { useMemo, useState } from 'react'
import { StarredFilters, StarredModesEnum } from './StarredFilters'

export interface UseStarredFiltersProps {
  allStarred: AllMyStars
}
export type UseStarredFiltersReturn = {
  filterControl: React.ReactNode
  filteredItems: AnyStarItem[]
  activeFilter: StarredModesEnum
  totalItems: number
}

export const useStarredFilters = (allStarred): UseStarredFiltersReturn => {
  const [activeFilter, setActiveFilter] = useState<StarredModesEnum>(StarredModesEnum.ALL)

  const [filteredItems, totalItems]: [AnyStarItem[], number] = useMemo(() => {
    let items: AnyStarItem[] = []
    const totalItems = [
      ...allStarred.projects,
      ...allStarred.collections,
      ...allStarred.organizations,
      ...allStarred.users,
    ].length
    switch (activeFilter) {
      case StarredModesEnum.ALL:
        items = [...allStarred.projects, ...allStarred.collections, ...allStarred.organizations, ...allStarred.users]
        break
      case StarredModesEnum.PROJECTS:
        items = allStarred.projects
        break
      case StarredModesEnum.COLLECTIONS:
        items = allStarred.collections
        break
      case StarredModesEnum.ORGANIZATIONS:
        items = allStarred.organizations
        break
      case StarredModesEnum.USERS:
        items = allStarred.users
        break
    }
    items.sort(({ name: aName }, { name: bName }) => {
      // Alphabetically sort by name if there is one. non-named items should go at the end
      if (aName && bName) {
        return aName.localeCompare(bName)
      } else if (aName) {
        return -1
      } else if (bName) {
        return 1
      } else {
        return 0
      }
    })
    return [items, totalItems]
  }, [allStarred, activeFilter])

  const filterControl: React.ReactNode = (
    <StarredFilters filter={activeFilter} onChange={(newFilter) => setActiveFilter(newFilter)} />
  )

  return {
    filterControl,
    filteredItems,
    activeFilter,
    totalItems,
  }
}
