import { atom, selector, selectorFamily } from 'recoil'
import { appDefaults } from '../config'
import {
  ProjectTreeLeaf,
  TilingStateEnum,
  ProjectTreeBranch,
  ProjectTreeLayerTypeEnum,
} from '@riverscapes/react-common'
import { treeNodeIDsParse, TreeNodeID, projectLeafSearchAncestry } from '../lib/util'
// import log from 'loglevel'

import { projectAtom, activeIdsAtom } from './project'
import { layerStateSelectorFamily } from './layers'

export const treeFilterTextAtom = atom<string>({
  key: 'treeFilterText',
  default: '',
})

export const projectLeafHierarchySelector = selector<Record<number, [number, string][]>>({
  key: 'projectLeafHierarchy',
  get: ({ get }) => {
    const proj = get(projectAtom)
    if (!proj || !proj.tree) return {}
    const leafHier = proj.tree.leaves.reduce<Record<number, [number, string][]>>((acc, leaf) => {
      return {
        ...acc,
        [leaf.id]: projectLeafSearchAncestry(proj, leaf),
      }
    }, {})
    return leafHier
  },
})

export const treeInfoPaneIdAtom = atom<number | null>({
  key: 'tree/treeInfoPaneId',
  default: null,
})
export const treeInfoPaneOpenAtom = atom<boolean>({
  key: 'tree/treeInfoPaneOpen',
  default: false,
})

/**
 * Keep track of all the ProjectTreeLeaf objects in the system
 */
export const treeLeafSelectorFamily = selectorFamily<ProjectTreeLeaf, number>({
  key: 'treeLeafSelectorFamily',
  get:
    (ptid) =>
    ({ get }) => {
      const proj = get(projectAtom)
      if (!proj) throw new Error('treeLeafSelectorFamily: No project found')

      const leaf = proj.tree.leaves.find(({ id }) => id === ptid)
      if (!leaf) throw new Error('treeLeafSelectorFamily: No leaf found')

      return leaf
    },
})

/**
 * Keep track of the selected tree item.
 * MAterial tree control uses strings for ids so we use the convention 'b-##' and 'l-##' to separate leaves and branches
 */
export const treeSelectedIdsAtom = atom<string[]>({
  key: 'tree/selectedIds',
  default: [],
})

// This is a convenience selector because the above ids are hard to parse
export const treeSelectedSelector = selector<TreeNodeID>({
  key: 'tree/selectedIdsSelect',
  get: ({ get }) => {
    const selectedIds = get(treeSelectedIdsAtom)
    return treeNodeIDsParse(selectedIds)
  },
})

/**
 * Precalc the hidden nodes every time the search text changes
 */
export type HiddenNodes = { branches: number[]; leaves: number[] }
export const projectHiddenNodesSelector = selector<HiddenNodes>({
  key: 'projectHiddenNodes',
  get: ({ get }) => {
    const proj = get(projectAtom)
    const retVal: HiddenNodes = { branches: [], leaves: [] }
    if (!proj) return retVal
    const hierarchy = get(projectLeafHierarchySelector)
    const filterText = get(treeFilterTextAtom).toLowerCase().trim()

    // Return nothing if the search text is less than (n) characters
    if (filterText.length < appDefaults.minFilterTextLength) return retVal

    // Split words by spaces and filter out anything empties
    const filterTextArr = filterText.split(' ').filter((w) => w.length > 0)

    // Return nothing if there aren't any words
    if (filterTextArr.length < 1) return retVal

    // First we figure out which leaves are left
    const allowedLeaves = proj.tree.leaves
      .filter((leaf) => {
        const leafHierarchy = hierarchy[leaf.id]
        // Figure out if all our search words are present somewhere in the leaf's ancestry
        return filterTextArr.every((word) => {
          // Easy case: the leaf contains the word
          if (leaf.label.toLowerCase().indexOf(word) > -1) return true
          // Harder case: the branch contains the work
          return leafHierarchy.find(([, bText]) => bText.indexOf(word) > -1)
        })
      })
      .map(({ id }) => id)

    // Now we figure out which branches have children that re allowed
    const allowedBranches = Array.from(
      new Set(allowedLeaves.reduce<number[]>((acc, lid) => [...acc, ...hierarchy[lid].map(([bid]) => bid)], []))
    )
    // Then we remove everything else
    retVal.leaves = proj.tree.leaves.map(({ id }) => id).filter((lid) => allowedLeaves.indexOf(lid) < 0)
    retVal.branches = proj.tree.branches.map(({ bid }) => bid).filter((bid) => allowedBranches.indexOf(bid) < 0)
    return retVal
  },
})

export type TreeLeafState = {
  leaf: ProjectTreeLeaf
  reportUrl?: string
  tilesState: TilingStateEnum
  treeAllowed: boolean
  renderable: boolean
  hidden: boolean
  active: boolean
}

export const treeLeafStateSelector = selectorFamily<TreeLeafState, number>({
  key: 'treeLeafStateFamily',
  get:
    (ptid) =>
    ({ get }) => {
      const layer = get(layerStateSelectorFamily(ptid))
      const hidden = get(projectHiddenNodesSelector).leaves.indexOf(ptid) > -1
      const active = get(activeIdsAtom).indexOf(ptid) > -1
      const reportUrl =
        layer.leaf.layerType === ProjectTreeLayerTypeEnum.Report &&
        layer.tileState === TilingStateEnum.Success &&
        layer.tiles?.url + 'index.html'
      return {
        leaf: layer.leaf,
        tilesState: layer.tileState,
        treeAllowed: true,
        reportUrl: reportUrl || undefined,
        renderable: layer.renderable,
        hidden,
        active,
      }
    },
})

export const treeRootBranchId = selector<number | null>({
  key: 'tree/rootBranchId',
  get: ({ get }) => {
    const proj = get(projectAtom)
    if (!proj || !proj.tree) return null
    const branch = proj?.tree?.branches?.find((br) => br.pid === -1) as ProjectTreeBranch
    if (!branch) return null
    return branch.bid
  },
})

/**
 * This is going to let us build a tree in a lazy-loading way
 */
/**
 * This is just the raw, static treebranch
 */
export type TreeBranchSelectorReturn = {
  branch: ProjectTreeBranch
  children: {
    // We store these as ids to keep the memory footprint small
    branches: number[]
    leaves: number[]
  }
}

export const treeBranchSelectorFamily = selectorFamily<TreeBranchSelectorReturn, number>({
  key: 'tree/branches',
  get:
    (bid) =>
    ({ get }) => {
      const proj = get(projectAtom)
      const branch = proj?.tree.branches.find((br) => br.bid === bid) as ProjectTreeBranch

      if (!proj) throw new Error('treeBranchSelectorFamily: No project found')
      if (!branch) throw new Error('treeBranchSelectorFamily: No branch found')

      return {
        branch,
        children: {
          branches: proj.tree.branches.filter((br) => br.pid === bid).map((br) => br.bid),
          leaves: proj.tree.leaves.filter((lf) => lf.pid === bid).map(({ id }) => id),
        },
      }
    },
})

/**
 * Branch state is things like collapsed and selected. We keep that separete so we don't need to worry about re-recursing the tree
 */
export type TreeBranchStateReturn = {
  branch: TreeBranchSelectorReturn
  hidden: boolean
  selected: boolean
  collapsed: boolean
}
export const treeBranchStateSelectorFamily = selectorFamily<TreeBranchStateReturn, number>({
  key: 'tree/branchState',
  get:
    (bid) =>
    ({ get }) => {
      const selectedIds = get(treeSelectedSelector)
      const branch = get(treeBranchSelectorFamily(bid))
      const hiddenNodes = get(projectHiddenNodesSelector)

      const collapsed = Boolean(get(treeExpandedSelector).indexOf(bid) > -1)

      const selected = Boolean(selectedIds.branches.indexOf(bid) > -1)

      // Testing to see if this tree node should be hidden
      const hidden = hiddenNodes.branches.indexOf(bid) > -1

      return {
        branch,
        hidden,
        selected,
        collapsed,
      }
    },
})

/****************************************
 * Misc Tree State
 ****************************************/

export type TreeCtxState = { leafId: number; position: { top: number; left: number } }

export const treeContextMenuAtom = atom<TreeCtxState | null>({
  key: 'tree/contextMenu',
  default: null,
})

/**
 * Keep track of which branches are expanded
 * NOTE: We use 'collapsed' in the xml so this is inverted and a little messy
 * TODO: Not sure this needs to be externalized. Might be a good case for useState
 */
export const treeExpandedIdsAtom = atom<string[]>({
  key: 'tree/expandedIds',
  default: [],
})
// This is a convenience selector because the above ids are hard to parse
export const treeExpandedSelector = selector<number[]>({
  key: 'tree/expandedIdsSelect',
  get: ({ get }) => {
    return get(treeExpandedIdsAtom)
      .filter((ids) => ids.indexOf('b-') > -1)
      .map((ids) => parseInt(ids.replace('b-', ''), 10))
  },
})
// Just get all ids in the project
export const treeAllIdsSelector = selector<string[]>({
  key: 'tree/treeAllIds',
  get: ({ get }) => {
    const proj = get(projectAtom)
    if (!proj || !proj.tree) return []
    return [...proj.tree.branches.map(({ bid }) => `b-${bid}`), ...proj.tree.leaves.map(({ id }) => `l-${id}`)]
  },
})
