/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import MuiLinearProgress, { LinearProgressProps as MuiLinearProgressProps } from '@mui/material/LinearProgress'

export interface LinearProgressProps extends MuiLinearProgressProps {
  delayMs?: number
}

export const LinearProgress: React.FC<LinearProgressProps> = ({ delayMs = 0, ...rest }) => {
  const timeoutRef = useRef<number | undefined>(undefined)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      setIsActive(true)
    }, delayMs)
    return () => {
      window.clearTimeout(timeoutRef.current)
    }
  }, [])

  return isActive ? <MuiLinearProgress {...rest} /> : null
}
