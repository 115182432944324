import React from 'react'
import { IconButton, Box } from '@mui/material'
import { useBooleanState, withTooltip } from '../../lib'
import { EditIcon } from '../Icons'
import { ImageManagerDialog, ImageManagerDialogProps } from '../ImageManager'
import { Lookups } from '../lookups'
import { Groups } from '@mui/icons-material'

export type LogoImageProps = {
  item: {
    __typename: 'Organization'
    name: string
    logo?: string | null
  }
  size: number
  tooltip?: boolean
} & (
  | { canEdit?: false }
  | {
      canEdit: true
      getUploadTarget: ImageManagerDialogProps['getUploadTarget']
      lookups: Lookups
      onChange: (newToken: string | null) => Promise<unknown>
    }
)

const session = `?s=${Date.now()}` // to cache-bust per-session

export const LogoImage: React.FC<LogoImageProps> = ({ item: { name, logo }, size, tooltip, ...editProps }) => {
  const [isEditing, startEditing, stopEditing] = useBooleanState()
  const [isHovering, startHovering, stopHovering] = useBooleanState()

  const logoUrl = logo ? `${logo}${session}` : undefined
  const imageCss: React.CSSProperties = {
    objectFit: 'contain',
    objectPosition: 'center',
    maxHeight: '100%',
    maxWidth: '100%',
    color: 'inherit',
  }

  return (
    <>
      {withTooltip(
        <div style={{ position: 'relative' }} onMouseOver={startHovering} onMouseOut={stopHovering}>
          <Box
            sx={{
              height: size,
              width: size,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {logoUrl ? (
              <img src={logoUrl} style={imageCss} alt={name} />
            ) : (
              <Groups
                sx={{
                  fontSize: size,
                  color: 'inherit',
                }}
              />
            )}
          </Box>
          {editProps.canEdit && (
            <div
              style={{
                position: 'absolute',
                top: '-0.5em',
                right: '-0.5em',
                opacity: isHovering ? 1 : 0,
                transition: '250ms opacity',
              }}
            >
              {withTooltip(
                <IconButton onClick={startEditing}>
                  <EditIcon color="primary" />
                </IconButton>,
                'Edit Logo Image'
              )}
            </div>
          )}
        </div>,
        tooltip ? name : undefined
      )}
      {editProps.canEdit && (
        <ImageManagerDialog
          title="Edit Logo Image"
          getUploadTarget={editProps.getUploadTarget}
          open={isEditing}
          onClose={stopEditing}
          lookups={editProps.lookups}
          Preview={({ src }: { src: string }) => (
            <img
              src={src}
              style={{
                height: 200,
                width: 200,
                borderRadius: 200 * 0.1,
                border: '1px solid black',
                objectFit: 'cover',
              }}
            />
          )}
          onTokenChange={editProps.onChange}
          url={logoUrl}
        />
      )}
    </>
  )
}
