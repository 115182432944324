import React from 'react'
import { Copyright } from './Copyright'
import { Box, Container, IconButton, Link, Stack, Typography, useTheme } from '@mui/material'
import { Twitter } from '@mui/icons-material'
import { HelpWidget } from './HelpWidget'

export const Footer: React.FC = () => {
  const theme = useTheme()

  return (
    <Box
      component="footer"
      sx={{
        color: 'white',
        backgroundColor: 'rgb(0,18,88)', // fallback so we can still see something while loading
        position: 'relative',
        overflow: 'hidden',
        '& .MuiTypography-h3': {
          fontFamily: '"JetBrains Mono", "Courier New", sans-serif',
          fontSize: '1.2rem',
        },
      }}
    >
      <img
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
        src={`${process.env.PUBLIC_URL}/footer/background.jpg`}
        alt="Wave Pattern"
      />
      <Container
        maxWidth={'xl'}
        sx={{
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          spacing={4}
          gap={4}
          sx={{ width: '100%', my: 8 }}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
        >
          <Box
            sx={{
              flex: 0,
              width: 200,
            }}
          >
            <Link href="https://riverscapes.net" target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/footer/riverscapes.png`}
                width={200}
                alt="Riverscapes Consortium logo"
                placeholder="none"
              />
            </Link>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h3" paragraph sx={{ color: 'inherit' }}>
              Support & Contact
            </Typography>
            <Link href={`mailto:support@riverscapes.freshdesk.com`} sx={{ color: 'inherit' }} target="_blank">
              <Typography variant="body1" component="div">
                support@riverscapes.freshdesk.com
              </Typography>
            </Link>
            <Link
              href={`https://riverscapes.freshdesk.com/support/solutions/folders/153000068951`}
              sx={{ color: 'inherit' }}
              target="_blank"
            >
              <Typography variant="body1" component="div">
                Search Knowledge Base
              </Typography>
            </Link>
          </Box>
          <Box>
            <Typography variant="h3" paragraph sx={{ color: 'inherit' }}>
              Follow Us
            </Typography>
            <IconButton
              onClick={() => window.open(`https://twitter.com/RiverscapesC` || '', '_blank')}
              sx={{ color: 'inherit' }}
            >
              <Twitter />
            </IconButton>
          </Box>
          {/* UNIVERSAL FRESHDESK WIDGET */}
          <HelpWidget />
        </Stack>
        <Copyright />
      </Container>
    </Box>
  )
}
