import { Chip } from '@mui/material'
import { OrganizationInviteRoleEnum, OrganizationRoleEnum } from '../../schema/base'
import { withTooltip } from '../../lib'
import { theme } from '@riverscapes/react-theme'
import React from 'react'
import { Cancel, Visibility } from '@mui/icons-material'

export interface RoleChipProps {
  small?: boolean
  value: OrganizationRoleEnum | OrganizationInviteRoleEnum
}

const backgroundColor: Record<OrganizationRoleEnum, string> = {
  [OrganizationRoleEnum.None]: 'transparent',
  [OrganizationRoleEnum.Viewer]: theme.palette.info.main,
  [OrganizationRoleEnum.Contributor]: theme.palette.warning.main,
  [OrganizationRoleEnum.Admin]: theme.palette.error.main,
  [OrganizationRoleEnum.Owner]: 'black',
}
const borderColor: Record<OrganizationRoleEnum, string> = {
  [OrganizationRoleEnum.None]: '#44444455',
  [OrganizationRoleEnum.Viewer]: theme.palette.info.contrastText,
  [OrganizationRoleEnum.Contributor]: theme.palette.warning.contrastText,
  [OrganizationRoleEnum.Admin]: theme.palette.error.contrastText,
  [OrganizationRoleEnum.Owner]: 'white',
}
const fgColor: Record<OrganizationRoleEnum, string> = {
  [OrganizationRoleEnum.None]: '#44444455',
  [OrganizationRoleEnum.Viewer]: theme.palette.info.contrastText,
  [OrganizationRoleEnum.Contributor]: theme.palette.warning.contrastText,
  [OrganizationRoleEnum.Admin]: theme.palette.error.contrastText,
  [OrganizationRoleEnum.Owner]: 'white',
}

const shortName: Record<OrganizationRoleEnum, string> = {
  [OrganizationRoleEnum.None]: 'NONE',
  [OrganizationRoleEnum.Viewer]: 'VIEW',
  [OrganizationRoleEnum.Contributor]: 'CONTRIB',
  [OrganizationRoleEnum.Admin]: 'ADMIN',
  [OrganizationRoleEnum.Owner]: 'OWNER',
}

const tooltips: Record<OrganizationRoleEnum, string> = {
  [OrganizationRoleEnum.None]: 'You are not a member of this organization',
  [OrganizationRoleEnum.Viewer]: 'Viewers can see projects and collections in this organization.',
  [OrganizationRoleEnum.Contributor]: 'Contributors can create and edit projects and collections in this organization.',
  [OrganizationRoleEnum.Admin]: 'Admins can manage members and settings for this organization.',
  [OrganizationRoleEnum.Owner]:
    'Owners can manage members and settings for this organization AND delete the organization.',
}

export const RoleChip: React.FC<RoleChipProps> = ({ value, small }) => {
  if (value === OrganizationRoleEnum.None) return null
  return withTooltip(
    <Chip
      label={small ? shortName[value].toUpperCase() : value.toUpperCase()}
      sx={{
        '& .MuiChip-label': {
          px: 0.7,
          py: 0,
          fontSize: small ? '0.6rem' : '0.75rem',
        },
        height: 25,
        width: small ? 60 : undefined,
        flexShrink: 0,
        flexGrow: 0,
        fontWeight: 'bold',
        color: fgColor[value],
        backgroundColor: backgroundColor[value],
        borderStyle: 'solid',
        borderRadius: 2,
        borderWidth: 1,
        borderColor: borderColor[value],
      }}
    />,
    tooltips[value]
  )
}
