import { useRecoilState } from 'recoil'
import { activeIdsAtom, activeViewAtom, mapErrorAtom } from '../recoil'
import { appDefaults } from '../config'

interface UseMapLayersReturn {
  removeLayer: (lid: number) => void
  addLayer: (lid: number) => void
  toggleLayer: (lid: number) => void
}

/**
 * This is everything you'd ever want to do to manipulate layers on the map
 * @returns
 */
const useMapLayers = (): UseMapLayersReturn => {
  const [currView, setCurrView] = useRecoilState(activeViewAtom)
  const [activeIds, setActiveIds] = useRecoilState(activeIdsAtom)
  const [error, setError] = useRecoilState(mapErrorAtom)

  const removeLayer = (lid: number): void => {
    if (error) setError('')
    if (currView !== null) setCurrView(null)
    setActiveIds(activeIds.filter((id) => id !== lid))
  }
  const addLayer = (lid: number): void => {
    if (activeIds.length === appDefaults.maxMapLayers) {
      setError(`Only ${appDefaults.maxMapLayers} layers are allowed at a time.`)
      return
    }

    if (error) setError('')
    if (currView !== null) setCurrView(null)
    setActiveIds(Array.from(new Set([...activeIds, lid])))
  }
  const toggleLayer = (lid: number): void => {
    if (activeIds.indexOf(lid) > -1) removeLayer(lid)
    else addLayer(lid)
  }

  return { removeLayer, addLayer, toggleLayer }
}

export default useMapLayers
