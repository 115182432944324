import { Layers, LayersClear, ZoomOutMap } from '@mui/icons-material'
import { Fab, SxProps, Theme, Tooltip, useTheme } from '@mui/material'
import React from 'react'
import { BoundsObj } from '../types'

interface Props {
  drawerOpen?: boolean
  toggleDrawer: () => void
  bounds: BoundsObj | null
  zoomExtents: () => void
}

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  toggleTree: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  zoomExtent: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(7),
  },
})

const ProjectLoading: React.FC<Props> = ({ drawerOpen, toggleDrawer, zoomExtents, bounds }: Props) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  return (
    <>
      <Tooltip title="Toggle the tree sidebar">
        <Fab color="primary" aria-label="add" onClick={toggleDrawer} size="small" sx={styles.toggleTree}>
          {drawerOpen ? <LayersClear /> : <Layers />}
        </Fab>
      </Tooltip>
      {/* <Tooltip title={!bounds ? 'Cannot zoom to map extent. No bounding box found' : 'Zoom to active layer extent'}> */}
      <Fab
        color="secondary"
        aria-label="zoomOut"
        onClick={() => zoomExtents()}
        size="small"
        disabled={!bounds}
        sx={styles.zoomExtent}
      >
        <ZoomOutMap />
      </Fab>
      {/* </Tooltip> */}
    </>
  )
}

export default ProjectLoading
