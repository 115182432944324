import { useEffect, useMemo, useState } from 'react'
import {
  DetailPageTabs,
  EntitiesWithImagesEnum,
  MissingDataError,
  PAGE_SIZE,
  ProjectDetail as Components,
  ProjectVisibilityEnum,
  SortOrderFieldProps,
  StarrableTypesEnum,
  useActiveResults,
  pendingItems,
  PageTitle,
  useNotifications,
  ProjectDetailSkeleton,
  MetaDataInput,
  CollectionInput,
  itemToSelectOption,
  LogicError,
  OwnerInput,
  OrganizationRoleEnum,
  Footer,
  useDownload,
} from '@riverscapes/react-common'
import {
  evictAllQueries,
  useAddCollectionProjectsMutation,
  useChangeProjectOwnerMutation,
  useCreateCollectionMutation,
  useDeleteProjectMutation,
  useFetchAllWithPendingItems,
  useGetImageUploadTargetLazyQuery,
  useGetProfileOrganizationsQuery,
  useGetProjectCollectionsQuery,
  useGetProjectDatasetsQuery,
  useGetProjectDetailQuery,
  useGetProjectQaqcQuery,
  useRemoveCollectionProjectsMutation,
  useUpdateProjectCitationMutation,
  useUpdateProjectDescriptionMutation,
  useUpdateProjectHeroImageMutation,
  useUpdateProjectMetaMutation,
  useUpdateProjectNameMutation,
  useUpdateProjectStarMutation,
  useUpdateProjectSummaryMutation,
  useUpdateProjectTagsMutation,
  useUpdateProjectVisibilityMutation,
} from '../data'
import { projectDetail, search, useGoBackAfterDelete, useGotoRoute } from '../routing'
import { Box, Container, useTheme } from '@mui/material'
import { useLookups, useProfile } from '../lib'
import { useActivity } from '../lib'
import { NoPermission, NotFound } from './NotFound'

const { CollectionsPanel, DatasetsPanel, Header, MetadataPanel, OverviewPanel, QaPanel, SettingsPanel } = Components

export interface ProjectDetailProps extends JSX.IntrinsicAttributes {
  id: string
  tab?: string
}

export const ProjectDetail: React.FC<ProjectDetailProps> = ({ id, tab = 'overview' }) => {
  const gotoSelf = useGotoRoute(projectDetail)
  const gotoSearch = useGotoRoute(search)
  const { downloadZipFile, pendingZips } = useDownload()
  const goBackAfterDelete = useGoBackAfterDelete()
  const theme = useTheme()
  const lookups = useLookups()
  const profile = useProfile()

  const { catchError } = useNotifications()

  // LOOKUPS

  const {
    data: profileOrganizationsData,
    fetchMore: fetchMoreProfileOrganizations,
    loading: profileOrganizationsDataLoading,
  } = useGetProfileOrganizationsQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get organizations', false),
  })

  const profileOrganizations = useFetchAllWithPendingItems(
    fetchMoreProfileOrganizations,
    profileOrganizationsData?.profile?.organizations.items,
    profile?.organizations.total,
    1
  )

  const organizationOptions = useMemo(
    () =>
      profileOrganizations.map(itemToSelectOption).filter((item) => {
        return (
          item.data.myRole === OrganizationRoleEnum.Owner ||
          item.data.myRole === OrganizationRoleEnum.Admin ||
          item.data.myRole === OrganizationRoleEnum.Contributor
        )
      }),
    [profileOrganizations, profileOrganizationsDataLoading]
  )

  // TABS

  const setTab = (newTab: string) => gotoSelf({ id, tab: newTab }, true)

  // PROJECT QUERY

  const { data, loading, error } = useGetProjectDetailQuery({ variables: { id } })

  // Curate the list of organizations that can be transferred to (we repurpose the collections creation options here and filter out the current owner)
  const projectTransferOrganizationOptions = useMemo(
    () =>
      organizationOptions.filter((item) => {
        // Filter out the current owner
        if (!data?.project || !data?.project?.ownedBy) return false
        if (data?.project.ownedBy.__typename === 'Organization' && item.data.id === data?.project.ownedBy.id)
          return false
        return true
      }),
    [organizationOptions, data?.project]
  )

  // ACTIVITY

  const { addActivity } = useActivity()
  useEffect(() => {
    if (!data?.project) return
    addActivity({ operation: 'view', item: data.project })
  }, [data?.project])

  // HEADER

  // queries
  const [getImageUploadTarget] = useGetImageUploadTargetLazyQuery()
  const getHeroUploadTarget = async () => {
    const result = await getImageUploadTarget({
      variables: { entityId: id, entityType: EntitiesWithImagesEnum.Project },
    })
    const target = result.data?.requestUploadImage
    if (!target) throw new MissingDataError()
    return target
  }

  // mutations

  const [updateStar] = useUpdateProjectStarMutation()
  const handleStarChange = (newValue: boolean) => {
    updateStar({
      variables: { type: StarrableTypesEnum.Project, id, starred: newValue },
    }).catch(catchError('Failed to update star', false))
  }

  const [updateTags] = useUpdateProjectTagsMutation()
  const handleTagsChange = (newValue: string[]) => {
    updateTags({
      variables: { id, tags: newValue },
    }).catch(catchError('Failed to update tags', false))
  }

  const [updateProjectName] = useUpdateProjectNameMutation()
  const handleNameChange = (newName: string) =>
    updateProjectName({ variables: { id, name: newName } }).catch(catchError('Failed to update project name', false))

  const [updateProjectSummary] = useUpdateProjectSummaryMutation()
  const handleSummaryChange = (newSummary: string) =>
    updateProjectSummary({ variables: { id, summary: newSummary } }).catch(
      catchError('Failed to update project summary', false)
    )

  const [updateProjectDescription] = useUpdateProjectDescriptionMutation()
  const handleDescriptionChange = (newDescription: string) =>
    updateProjectDescription({ variables: { id, description: newDescription } }).catch(
      catchError('Failed to update project description', false)
    )

  const [updateProjectCitation] = useUpdateProjectCitationMutation()
  const handleCitationChange = (newCitation: string) =>
    updateProjectCitation({ variables: { id, citation: newCitation } }).catch(
      catchError('Failed to update project citation', false)
    )

  const [updateProjectHeroImage] = useUpdateProjectHeroImageMutation()
  const handleHeroChange = (newToken: string | null) =>
    updateProjectHeroImage({ variables: { id, token: newToken as string | undefined, clear: !newToken } }).catch(
      catchError(`Failed to ${newToken ? 'update' : 'remove'} project image`, false)
    )

  const [deleteProject] = useDeleteProjectMutation()
  const handleDeleteProject = () =>
    deleteProject({
      variables: { id },
    })
      //
      .then(goBackAfterDelete)
      .catch(catchError('Failed to delete project', false))

  const [changeProjectOwner] = useChangeProjectOwnerMutation()
  const handleChangeProjectOwner = (owner: OwnerInput) =>
    changeProjectOwner({ variables: { id, owner } }).catch(catchError('Failed to change project ownership', false))

  // COLLECTIONS PANEL

  const [collectionsPage, setCollectionsPage] = useState(1)
  const [collectionsSort, setCollectionsSort] = useState<SortOrderFieldProps['sort']>(null)

  const { data: collectionsData } = useGetProjectCollectionsQuery({
    variables: {
      id,
      limit: PAGE_SIZE,
      offset: (collectionsPage - 1) * PAGE_SIZE,
      sort: collectionsSort && [collectionsSort],
    },
    skip: tab !== 'collections',
    onError: catchError('Failed to get project collections', false),
  })

  const collectionStats = useActiveResults({ page: collectionsPage }, data?.project?.collections.total)

  const collections = collectionsData?.project?.collections.items ?? pendingItems(collectionStats.expectedCount)

  // mutations
  const [addCollectionProjects] = useAddCollectionProjectsMutation()
  const handleAddtoCollectionById = (collectionId: string) => {
    addCollectionProjects({
      variables: { collectionId, projectIds: [id] },
    }).catch(catchError('Failed to add project to collection', false))
  }

  const [createCollection] = useCreateCollectionMutation()
  const handleCreateCollection = async ({
    orgId,
    ...restCollection
  }: { orgId?: string } & Partial<CollectionInput>) => {
    let collectionId: string | undefined

    // create collection
    try {
      const newCollection = await createCollection({
        variables: {
          collection: restCollection,
          orgId: orgId,
        },
        update: evictAllQueries,
      })
      if (!newCollection.data?.createCollection) throw new MissingDataError()
      collectionId = newCollection.data.createCollection.id
    } catch (err) {
      catchError('Failed to create new collection', true)(err)
    }

    if (!collectionId) throw new LogicError()

    // populate collection
    try {
      await addCollectionProjects({
        variables: {
          collectionId,
          projectIds: [id],
        },
      })
    } catch (err) {
      catchError('Failed to populate new collection', true)(err)
    }
  }

  const [updateProjectVisibility] = useUpdateProjectVisibilityMutation()
  const handleVisibilityChange = (newVisibility: ProjectVisibilityEnum) =>
    updateProjectVisibility({ variables: { id, visibility: newVisibility } }).catch(
      catchError('Failed to update collection visibility', false)
    )

  const [removeCollectionProjects] = useRemoveCollectionProjectsMutation()
  const handleRemoveProjectByCollectionId = (collectionId: string) => {
    removeCollectionProjects({
      variables: { collectionId: collectionId, projectIds: [project.id] },
    }).catch(catchError('Failed to remove project from collection', false))
  }

  // DATASETS PANEL

  const { data: datasetsData, fetchMore: fetchMoreDatasets } = useGetProjectDatasetsQuery({
    variables: {
      id,
      limit: 10,
      offset: 0,
    },
    skip: tab !== 'datasets',
    onError: catchError('Failed to get project datasets', false),
  })

  const datasets = useFetchAllWithPendingItems(
    fetchMoreDatasets,
    datasetsData?.project?.datasets.items,
    data?.project?.datasets.total
  )

  // QA PANEL

  const { data: qaqcData, fetchMore: fetchMoreQaqc } = useGetProjectQaqcQuery({
    variables: {
      id,
      limit: 10,
      offset: 0,
    },
    skip: tab !== 'qa',
    onError: catchError('Failed to get project QA/QC events', false),
  })

  const qaqc = useFetchAllWithPendingItems(fetchMoreQaqc, qaqcData?.project?.qaqc.items, data?.project?.qaqc.total)

  // METADATA PANEL

  const [updateMeta] = useUpdateProjectMetaMutation()
  const handleMetadataChange = (newValue: MetaDataInput[]) =>
    updateMeta({
      variables: { id, meta: newValue },
    }).catch(catchError('Failed to update metadata', false))

  // RENDER
  if (error?.message === 'Project not found' || data?.project?.deleted) return <NotFound id={id} descriptor="Project" />
  if (error?.message.includes('does not have permission')) return <NoPermission errorMsg={error.message} />
  if (error) throw error
  if (loading || !profile) return <ProjectDetailSkeleton />
  if (!data?.project) throw new MissingDataError()

  const { project } = data

  return (
    <>
      <PageTitle title={project.name} />
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header
          getHeroUploadTarget={getHeroUploadTarget}
          lookups={lookups}
          onHeroChange={handleHeroChange}
          onStarChange={handleStarChange}
          onNameChange={handleNameChange}
          onSummaryChange={handleSummaryChange}
          downloadZipFile={() => downloadZipFile(project.id as string)}
          pendingZips={pendingZips}
          project={project}
        />
        <Container
          maxWidth="lg"
          sx={{
            flex: '1 1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DetailPageTabs
            value={tab}
            onChange={setTab}
            tabs={[
              {
                name: 'overview',
                content: (
                  <OverviewPanel
                    project={project}
                    onNameChange={handleNameChange}
                    onTagsChange={handleTagsChange}
                    onVisibilityChange={handleVisibilityChange}
                    onDescriptionChange={handleDescriptionChange}
                    onCitationChange={handleCitationChange}
                    onGotoTagSearch={(tag: string) => gotoSearch({ type: 'Project', params: { tags: [tag] } })}
                    lookups={lookups}
                  />
                ),
              },
              {
                name: 'metadata',
                content: <MetadataPanel project={project} onMetadataChange={handleMetadataChange} />,
              },
              { name: 'datasets', content: <DatasetsPanel project={project} items={datasets} lookups={lookups} /> },
              {
                name: 'collections',
                padding: 0,
                content: (
                  <CollectionsPanel
                    onAddToCollectionById={handleAddtoCollectionById}
                    onCreateCollection={handleCreateCollection}
                    onRemoveProjectFromCollection={handleRemoveProjectByCollectionId}
                    organizationOptions={organizationOptions}
                    items={collections}
                    lookups={lookups}
                    onPageChange={setCollectionsPage}
                    onSortChange={setCollectionsSort}
                    page={collectionsPage}
                    pageCount={collectionStats.pageCount}
                    project={project}
                    sort={collectionsSort}
                  />
                ),
              },
              { name: 'qa', label: 'QA/QC', content: <QaPanel project={project} items={qaqc} /> },
              project.permissions.update && {
                name: 'settings',
                content: (
                  <SettingsPanel
                    lookups={lookups}
                    profile={profile}
                    project={project}
                    onDeleteProject={handleDeleteProject}
                    onOwnerChange={handleChangeProjectOwner}
                    organizationTransferOptions={projectTransferOrganizationOptions}
                  />
                ),
              },
            ]}
          />
        </Container>
        <Footer />
      </Box>
    </>
  )
}
