import { AvatarImage } from './AvatarImage'
import { LogoImage } from './OrgImage'
import { Link, Skeleton, Typography, useTheme } from '@mui/material'
import { Lookups } from '../lookups'
import { ProjectTypeImage } from './ProjectTypeImage'
import { CollectionImage } from './CollectionImage'
import { PendingItem } from '../PendingItem'
import { withTooltip } from '../../lib'
import { CSSProperties } from 'react'
import { Stack } from '@mui/system'

export type ItemRepresentationProps = {
  size?: 'small' | 'medium' | 'large'
  ellipses?: boolean
  tooltipPrefix?: string
  noTooltip?: boolean
  color?: string
  hideName?: boolean
  item:
    | {
        __typename: 'User' | 'Profile'
        id: string
        name: string
        avatar?: string | null
      }
    | {
        __typename: 'Organization'
        id: string
        name: string
        logo?: string | null
      }
    | {
        __typename: 'Collection'
        id: string
        name: string
      }
    | {
        __typename: 'Project'
        id: string
        name: string
        projectType: {
          __typename: 'ProjectType'
          name: string
          logo?: string | null
        }
      }
    | {
        __typename: 'ProjectType'
        id: string
        name: string
        logo?: string | null
      }
    | PendingItem
} & ({ lookups?: Lookups; link?: false } | { link: true; lookups: Lookups })

export const ItemRepresentation: React.FC<ItemRepresentationProps> = ({
  item,
  size = 'medium',
  color,
  tooltipPrefix,
  noTooltip,
  ellipses,
  hideName,
  ...linkProps
}) => {
  const tooltip = !!hideName
  const theme = useTheme()
  const imageSize = {
    small: 16,
    medium: 21,
    large: 45,
  }[size]

  const image = (() => {
    switch (item.__typename) {
      case 'PendingItem':
        return <Skeleton variant="rectangular" height={imageSize} width={imageSize} />
      case 'Project':
        return <ProjectTypeImage size={imageSize} item={item.projectType} />
      case 'Collection':
        return <CollectionImage size={imageSize} />
      case 'Organization':
        return <LogoImage size={imageSize} item={item} tooltip={tooltip} />
      case 'User':
      case 'Profile':
        return <AvatarImage size={imageSize} item={item} tooltip={tooltip} />
      case 'ProjectType':
        return <ProjectTypeImage size={imageSize} item={item} tooltip />
    }
  })()

  const isPendingItem = item.__typename === 'PendingItem'

  const ellipsesObj: CSSProperties = ellipses
    ? {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    : {}

  const span = hideName
    ? image
    : withTooltip(
        <Stack
          spacing={1}
          direction="row"
          sx={{
            flex: '1 1',
            overflow: ellipses ? 'hidden' : undefined,
            width: '100%',
            // Vertically align the image with the text
            alignItems: 'center',
            // border: '1px solid red',
          }}
        >
          {image}{' '}
          {isPendingItem ? (
            <Skeleton variant="text" width="8em" sx={{ flex: 1 }} />
          ) : (
            <Typography
              component="div"
              variant={size === 'small' ? 'body2' : undefined}
              sx={{
                flex: 1,
                ...ellipsesObj,
              }}
            >
              {item.name}
            </Typography>
          )}
        </Stack>,
        noTooltip ? null : `${tooltipPrefix ? tooltipPrefix + ' ' : ''}${item.name}`
      )

  if (!linkProps.link || isPendingItem) return span

  const { lookups } = linkProps

  const url = (() => {
    switch (item.__typename) {
      case 'Project':
        return lookups.getProjectUrlById(item.id)
      case 'Collection':
        return lookups.getCollectionUrlById(item.id)
      case 'Organization':
        return lookups.getOrganizationUrlById(item.id)
      case 'User':
      case 'Profile':
        return lookups.getUserUrlById(item.id)
      case 'ProjectType':
        return '' // TODO: link to ProjectType
    }
  })()

  return (
    <Link href={url} sx={{ color: color || theme.palette.primary.main, overflow: ellipses ? 'hidden' : undefined }}>
      {span}
    </Link>
  )
}
