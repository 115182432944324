import { identity, uniq } from 'lodash'
import { MapGeoJSONFeature } from 'react-map-gl'
import { Bbox } from './useInteractiveMapController'
import geohash from 'ngeohash'
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'

export const expandBbox = (bbox: Bbox, expansion: number): Bbox => {
  const [minLng, minLat, maxLng, maxLat] = bbox
  const lngExpansion = (maxLng - minLng) * expansion
  const latExpansion = (maxLat - minLat) * expansion
  return [minLng - lngExpansion, minLat - latExpansion, maxLng + lngExpansion, maxLat + latExpansion] as Bbox
}

export const getIdsFromGeoJsonFeatures = (features: MapGeoJSONFeature[] | undefined): string[] => {
  if (!features) return []
  const ids: string[] = []
  features.forEach((feature) => {
    if (feature.properties?.id) ids.push(feature.properties.id)
    if (feature.properties?.ids) ids.push(...feature.properties.ids.split(',')) // only primitives are allowed in properties, so we are using comma-delimited string for multiple IDs
  })
  const uniqueIds = uniq(ids.filter(identity))
  return uniqueIds
}

export const getAllHashes = (bbox: Bbox, precision: number): string[] => {
  const [minLng, minLat, maxLng, maxLat] = bbox
  return geohash.bboxes(minLat, minLng, maxLat, maxLng, precision) // note alternative order to bbox in geohash
}

export const getHashGridSize = (
  bbox: Bbox,
  precision: number
): [horizontalProportion: number, verticalProportion: number] => {
  console.time('getGridSize')
  const [minLng, minLat, maxLng, maxLat] = bbox
  // const centerLng = (maxLng - minLng) / 2
  // const centerLat = (maxLat - minLat) / 2
  const totalLng = maxLng - minLng
  // const totalLat = maxLat - minLat

  const southwestHash = geohash.encode(minLat, minLng, precision)
  // const southwestHashNorthernNeighbor = geohash.neighbor(southwestHash, [1, 0])
  const southwestHashEasternNeighbor = geohash.neighbor(southwestHash, [0, 1])

  const northeastHash = geohash.encode(maxLat, maxLng, precision)
  // const northeastHashSoutherNeighbor = geohash.neighbor(northeastHash, [-1, 0])
  const northeastHashWesternNeighbor = geohash.neighbor(northeastHash, [0, -1])

  // const southwestLatitudeDistance =
  //   geohash.decode(southwestHashNorthernNeighbor).latitude - geohash.decode(southwestHash).latitude
  const southwestLongitudeDistance =
    geohash.decode(southwestHashEasternNeighbor).longitude - geohash.decode(southwestHash).longitude

  // const northeastLatitudeDistance =
  //   geohash.decode(northeastHash).latitude - geohash.decode(northeastHashSoutherNeighbor).latitude
  const northeastLongitudeDistance =
    geohash.decode(northeastHash).longitude - geohash.decode(northeastHashWesternNeighbor).longitude

  const maxLongitudeDistance = Math.max(southwestLongitudeDistance, northeastLongitudeDistance)
  // const maxLatitudeDistance = Math.max(southwestLatitudeDistance, northeastLatitudeDistance)

  // const neHash = geohash.encode(maxLat, maxLng, precision)
  // const eastOfCenterHash = geohash.neighbor(southwestHash, [0, 1])
  // const northOfCenterHash = geohash.neighbor(centerHash, [1, 0])
  // const { longitude: eastLng } = geohash.decode(eastOfCenterHash)
  // const { latitude: northLat } = geohash.decode(northOfCenterHash)
  // const gridLng = eastLng - centerLng
  // const gridLat = northLat - centerLat

  console.timeEnd('getGridSize')
  // return [gridLng / totalLng, gridLat / totalLat]
  return [maxLongitudeDistance / totalLng, 0]
}

export const getTargetPrecision = (bbox: Bbox, targetQty: number): [precision: number, maxHashCount: number] => {
  let thisPrecision = 2
  let thisMaxHashCount = -1
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const nextPrecision = thisPrecision + 1
    if (nextPrecision > 12) break // maxed out precision
    const nextMaxHashCount = getAllHashes(bbox, nextPrecision).length
    if (nextMaxHashCount > targetQty) break // exceeded max
    thisMaxHashCount = nextMaxHashCount
    thisPrecision = nextPrecision
    if (thisMaxHashCount * 16 > targetQty) break // optimization (don't test next precision)
  }
  return [thisPrecision, thisMaxHashCount]
}

export const getIsFeatureCollectionEdgeOutsideBbox = (
  featureCollection: FeatureCollection<Geometry, GeoJsonProperties>,
  bbox: Bbox
): boolean | undefined => {
  if (featureCollection.features.length !== 1) return undefined
  const { geometry } = featureCollection.features[0]
  if (geometry.type !== 'Polygon') return undefined
  const [minLng, minLat, maxLng, maxLat] = bbox
  const isEdgeWithinBbox = geometry.coordinates[0].some(
    ([pointLng, pointLat]) => pointLng > minLng && pointLng < maxLng && pointLat > minLat && pointLat < maxLat
  )
  return !isEdgeWithinBbox
}
