import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { ProjectGroupVisibilityEnum, ProjectVisibilityEnum } from '../../schema/base'

export type VisibilityFieldProps =
  | {
      type: 'Project'
      value: ProjectVisibilityEnum
      onChange: (newValue: ProjectVisibilityEnum) => void
    }
  | {
      type: 'Collection' | 'SavedSearch'
      value: ProjectGroupVisibilityEnum
      onChange: (newValue: ProjectGroupVisibilityEnum) => void
    }

const Explainer = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" sx={{ ml: '2rem' }}>
    {children}
  </Typography>
)

export const VisibilityField: React.FC<VisibilityFieldProps> = ({ type, value, onChange }) => {
  if (type === 'Project')
    return (
      <FormControl>
        <FormLabel>Project Visibility</FormLabel>
        <RadioGroup value={value} onChange={(e, newValue) => onChange(newValue as ProjectVisibilityEnum)}>
          <FormControlLabel value={ProjectVisibilityEnum.Public} control={<Radio />} label="Public" />
          <Explainer>Anyone can see and download this project.</Explainer>
          <FormControlLabel value={ProjectVisibilityEnum.Private} control={<Radio />} label="Private" />
          <Explainer>Anyone can see this project, but specific access is required to download it.</Explainer>
          <FormControlLabel value={ProjectVisibilityEnum.Secret} control={<Radio />} label="Secret" />
          <Explainer>Only those with specific access can see or download this project.</Explainer>
        </RadioGroup>
      </FormControl>
    )

  const descriptor = type === 'Collection' ? 'Collection' : 'Saved Search'
  return (
    <FormControl>
      <FormLabel>{descriptor} Visibility</FormLabel>
      <RadioGroup value={value} onChange={(e, newValue) => onChange(newValue as ProjectGroupVisibilityEnum)}>
        <FormControlLabel value={ProjectGroupVisibilityEnum.Public} control={<Radio />} label="Public" />
        <Explainer>Anyone can see this {descriptor.toLowerCase()}.</Explainer>
        <FormControlLabel value={ProjectGroupVisibilityEnum.Secret} control={<Radio />} label="Secret" />
        <Explainer>Only those with specific access can see this {descriptor.toLowerCase()}.</Explainer>
      </RadioGroup>
    </FormControl>
  )
}
