/** @jsxImportSource @emotion/react */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useState } from 'react'
import { LogicError } from '../../lib'
import { AutocompleteSelectField, AutocompleteSelectOption } from '../AutocompleteSelectField'
import { Box, useTheme } from '@mui/system'

export interface AddProjectDialogProps {
  collectionName: string
  getProjectOptionsByName: (name: string) => Promise<AutocompleteSelectOption[]>
  onChoose: (projectId: string) => void
  onClose: () => void
  open: boolean
}

export const AddProjectDialog: React.FC<AddProjectDialogProps> = ({
  collectionName,
  getProjectOptionsByName,
  onChoose,
  onClose,
  open,
}) => {
  const theme = useTheme()
  const [projectId, setProjectId] = useState<string | null>(null)

  const handleChoose = () => {
    if (!projectId) throw new LogicError()
    onChoose(projectId)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          mb: 2,
        }}
      >
        Add Project to {collectionName}
      </DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <AutocompleteSelectField
          placeholder="Search for project..."
          noOptionsText="No projects found"
          onChange={setProjectId}
          value={projectId}
          getOptionsFromText={getProjectOptionsByName}
        />
        <Typography variant="body2" mt={1}>
          Note: If collection already contains the project no changes will be made.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleChoose} disabled={!projectId}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
