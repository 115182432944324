import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactMapGL, {
  MapRef,
  NavigationControl,
  ViewStateChangeEvent,
  MapboxStyle,
  MapLayerMouseEvent,
} from 'react-map-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { InteractiveMapController, InteractiveMapControllerContext, MAX_ZOOM } from './useInteractiveMapController'
import { Box, Fab, Tooltip, useTheme } from '@mui/material'
import { LogicError } from '../../lib'
import { getIdsFromGeoJsonFeatures } from './util'
import { StyleControl } from './StyleControl'
import { MapStyleKey, mapStyles } from './styles'
import { atom, useRecoilState } from 'recoil'
import { Home } from '@mui/icons-material'
// import { StyleControl } from './StyleControl'

const mapboxToken = process.env.STORYBOOK_MAPBOX_TOKEN || process.env.REACT_APP_MAPBOX_TOKEN || ''

const mapStyleKeyAtom = atom<MapStyleKey>({
  key: 'InteractiveMap/mapStyleAtom',
  default: 'topo',
})

type ObjectValues<T> = T[keyof T]
export const InteractivityEnum = {
  NONE: 'none',
  ZOOM_ONLY: 'zoom-only',
  ALL: 'all',
} as const
export type InteractivityEnum = ObjectValues<typeof InteractivityEnum>

export type InteractiveMapEventTarget = { x: number; y: number; ids: string[] }

export type InteractiveMapProps = {
  children?: React.ReactNode
  controller: InteractiveMapController
  interactive?: InteractivityEnum
  hasHomeButton?: boolean
  hasGeoCoder?: boolean
  onClick?: (target: InteractiveMapEventTarget) => void
  onHover?: (target: InteractiveMapEventTarget | null) => void
}

export const InteractiveMap: React.FC<InteractiveMapProps> = ({
  controller,
  children,
  // mapboxToken,
  hasHomeButton,
  hasGeoCoder = true,
  onClick,
  interactive = InteractivityEnum.ALL,
  onHover,
}) => {
  const theme = useTheme()
  // STATE

  const mapRef = useRef<MapRef | null>(null)
  const [mapStyleKey, setMapStyleKey] = useRecoilState(mapStyleKeyAtom)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loaded, setLoaded] = useState(false) // loaded is unused; state change is used to trigger initial setBbox once ref is ready
  const [cursor, setCursor] = useState<string | undefined>(undefined)

  const { viewState, setViewState, onBboxChange, onReady, interactiveLayerIds, suspend, initialBbox } = controller

  // EVENTS

  const handleLoad = () => {
    setLoaded(true)

    if (!mapRef.current) throw new LogicError()
    onReady(mapRef.current)

    // geocoder
    if (interactive === InteractivityEnum.ALL || interactive === InteractivityEnum.ZOOM_ONLY) {
      if (!mapRef.current) throw new LogicError()
      const mapboxgl = mapRef.current.getMap()
      if (hasGeoCoder) {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxToken,
          mapboxgl: window.mapboxgl,
          marker: false,
          placeholder: 'Find a place',
          clearAndBlurOnEsc: true,
          clearOnBlur: true,
          trackProximity: true,
          zoom: 10,
          flyTo: {
            duration: 1000,
          },
        })
        mapboxgl.addControl(geocoder, 'top-left')
      }
    }
  }

  const handleMove = (event: ViewStateChangeEvent) => {
    setViewState(event.viewState)
  }

  const handleMouseMove = useCallback(
    ({ features, point: { x, y } }: MapLayerMouseEvent) => {
      if (!onHover) return
      const ids = getIdsFromGeoJsonFeatures(features)
      onHover({ x, y, ids })
      setCursor('pointer')
    },
    [onHover]
  )

  const handleMouseLeave = useCallback(() => {
    if (!onHover) return
    onHover(null)
    setCursor('unset')
  }, [onHover])

  const handleClick = useCallback(
    ({ features, point: { x, y } }: MapLayerMouseEvent) => {
      if (!onClick) return
      const ids = getIdsFromGeoJsonFeatures(features)
      onClick({ x, y, ids })
    },
    [onClick, []]
  )

  const bbox = (() => {
    if (!mapRef.current) return [undefined, undefined, undefined, undefined]
    const mapBounds = mapRef.current.getBounds()
    const sw = mapBounds.getSouthWest()
    const ne = mapBounds.getNorthEast()
    return [sw.lng, sw.lat, ne.lng, ne.lat]
  })()

  useEffect(() => {
    const [minLng, minLat, maxLng, maxLat] = bbox
    if (
      typeof minLng === 'undefined' ||
      typeof minLat === 'undefined' ||
      typeof maxLng === 'undefined' ||
      typeof maxLat === 'undefined'
    )
      return
    onBboxChange([minLng, minLat, maxLng, maxLat])
  }, [...bbox])

  // RENDER

  if (!mapboxToken) return <div>No Mapbox token found.</div>

  const { registerLayerId, unregisterLayerId, fitBboxes, flyTo } = controller
  const controllerContextMethods = useMemo(() => {
    return { registerLayerId, unregisterLayerId, fitBboxes, flyTo }
  }, [registerLayerId, unregisterLayerId])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <ReactMapGL
        ref={mapRef}
        onMove={handleMove}
        onLoad={handleLoad}
        mapboxAccessToken={mapboxToken}
        maxZoom={MAX_ZOOM}
        mapStyle={mapStyles[mapStyleKey] as MapboxStyle}
        // mapStyle="mapbox://styles/mapbox/streets-v9"
        onClick={handleClick}
        // onDblClick={() => log.debug('doubleclick')}
        interactiveLayerIds={interactiveLayerIds}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        cursor={cursor}
        interactive={interactive !== InteractivityEnum.NONE}
        dragRotate={false}
        dragPan={interactive !== InteractivityEnum.NONE}
        pitchWithRotate={false}
        touchZoomRotate={false}
        touchPitch={false}
        {...viewState}
      >
        <NavigationControl
          position="top-right"
          showCompass={false}
          style={{
            position: 'absolute',
            top: 18,
            right: 8,
          }}
          showZoom={true}
        />
        <InteractiveMapControllerContext.Provider value={controllerContextMethods}>
          {children}
          <div style={{ position: 'absolute', bottom: 15, left: 8 }}>
            <StyleControl value={mapStyleKey} onChange={setMapStyleKey} />
          </div>
          {hasHomeButton && (
            <div style={{ position: 'absolute', bottom: 8, right: 10, zIndex: 100 }}>
              <Tooltip title="Reset view">
                <Fab
                  size="small"
                  sx={{
                    width: 30,
                    height: 30,
                    minHeight: 30,
                    border: `2px solid ${theme.palette.secondary.dark}}`,
                    boxShadow: theme.shadows[5],
                  }}
                  onClick={() => initialBbox && fitBboxes([initialBbox])}
                  color="secondary"
                >
                  <Home
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </Fab>
              </Tooltip>
            </div>
          )}
        </InteractiveMapControllerContext.Provider>
      </ReactMapGL>
      {suspend && (
        <div style={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: 0, background: '#cccccc' }} />
      )}
    </Box>
  )
}
