import { useEffect, useMemo } from 'react'
import {
  Exact,
  Lookups,
  ModifySearchDialog,
  OtherResults,
  OtherResultsProps,
  OtherSearchSpecParams,
  PAGE_SIZE,
  PageTitle,
  SearchParamsInput,
  SearchSpec,
  SearchSpecExplainer,
  pendingItems,
  useActiveResults,
  useBooleanState,
  useFormats,
} from '@riverscapes/react-common'
import {
  useGetCollectionSearchResultsQuery,
  useGetOrganizationSearchResultsQuery,
  useGetUserSearchResultsQuery,
  useGetSavedSearchSearchResultsQuery,
} from '../../data'
import { extractItem } from '../../lib'
import { Box, Link, Stack } from '@mui/material'
import { SearchProps } from './Search'
import { searchDateInputOrWithin } from './searchDateInputOrWithin'

type OtherResultsContainerProps = Omit<
  OtherResultsProps,
  keyof {
    NoResults: never
    onPageChange: never
    onSortChange: never
  }
> & {
  lookups: Lookups
  loading?: boolean
  gotoSearch: (props: SearchProps, replace?: boolean) => void
  params: OtherSearchSpecParams
}

const OtherResultsContainer: React.FC<OtherResultsContainerProps> = (props) => {
  const { lookups, gotoSearch, items, page, pageCount, params, resultsCount, sort, type, loading } = props

  // STATE

  const [isModifyOpen, openModify, closeModify] = useBooleanState(false)
  useEffect(closeModify, [type, params])

  // HANDLERS

  const gotoSelfMerged = (mergeProps: Partial<SearchProps>) => {
    gotoSearch({ type, params, page, sort: sort || undefined, ...mergeProps }, true)
  }

  const handleModifySearch = (newSearch: SearchSpec) => {
    gotoSearch({ sort: sort || undefined, ...newSearch })
  }

  const handlePageChange = (newPage: number) => {
    gotoSelfMerged({
      page: newPage,
    })
  }

  const handleSortChange = (newSort: OtherResultsProps['sort']) => {
    gotoSelfMerged({
      sort: newSort || undefined,
    })
  }

  // RENDER

  const { pluralize } = useFormats()

  return (
    <>
      <PageTitle title={`${pluralize(type)} Search Results`} />
      <Stack sx={{ height: '100%', gap: 1 }}>
        <Stack direction="row" sx={{ p: 2, borderBottom: 1, gap: 2, borderColor: 'divider' }}>
          <Stack direction="column" sx={{ flex: 1 }} gap={1}>
            <SearchSpecExplainer
              lookups={lookups}
              loading={false}
              onSearchChange={handleModifySearch}
              onOpenModify={openModify}
              params={params}
              resultsCount={resultsCount}
              type={type}
            />
          </Stack>
        </Stack>
        <Box sx={{ flex: 1, height: 0 }}>
          <OtherResults
            items={items}
            lookups={lookups}
            NoResults={() => (
              <div>
                There were no results for your search. Try{' '}
                <Link onClick={openModify} sx={{ cursor: 'pointer' }}>
                  modifying your search.
                </Link>
              </div>
            )}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            page={page}
            pageCount={pageCount}
            resultsCount={resultsCount}
            sort={sort}
            type={type}
          />
        </Box>
      </Stack>
      <ModifySearchDialog
        lookups={lookups}
        onClose={closeModify}
        onSearch={handleModifySearch}
        open={isModifyOpen}
        params={params}
        type={type}
      />
    </>
  )
}

const useSearchParams = (params: OtherSearchSpecParams) =>
  useMemo<Exact<SearchParamsInput>>(() => {
    const { createdWithin, ...apiParams } = params
    const createdOn = searchDateInputOrWithin(apiParams.createdOn, createdWithin)

    const result: Exact<SearchParamsInput> = {
      ...apiParams,
      createdOn,
    }
    return result
  }, [params])

export const CollectionResultsContainer: React.FC<
  Omit<OtherResultsContainerProps, keyof { items: never; getItemUrlById: never }>
> = (props) => {
  const { type, page, sort, params } = props
  const searchParams = useSearchParams(params)

  const { data, loading, error } = useGetCollectionSearchResultsQuery({
    variables: {
      limit: PAGE_SIZE,
      offset: ((page || 1) - 1) * PAGE_SIZE,
      sort: sort && [sort],
      params: searchParams,
    },
  })

  const { resultsCount, pageCount, expectedCount } = useActiveResults(
    { type, params, page },
    data?.searchCollections.total
  )

  const items = data?.searchCollections.results.map(extractItem) ?? pendingItems(expectedCount)

  if (error) throw error

  return (
    <OtherResultsContainer
      {...props}
      items={items}
      pageCount={pageCount}
      resultsCount={resultsCount}
      loading={loading}
    />
  )
}

export const OrganizationResultsContainer: React.FC<
  Omit<OtherResultsContainerProps, keyof { items: never; getItemUrlById: never }>
> = (props) => {
  const { type, page, sort, params } = props
  const searchParams = useSearchParams(params)

  const { data, loading, error } = useGetOrganizationSearchResultsQuery({
    variables: {
      limit: PAGE_SIZE,
      offset: ((page || 1) - 1) * PAGE_SIZE,
      sort: sort && [sort],
      params: searchParams,
    },
  })

  const { resultsCount, pageCount, expectedCount } = useActiveResults(
    { type, params, page },
    data?.searchOrganizations.total
  )

  const items = data?.searchOrganizations.results.map(extractItem) ?? pendingItems(expectedCount)

  if (error) throw error

  return (
    <OtherResultsContainer
      {...props}
      items={items}
      pageCount={pageCount}
      resultsCount={resultsCount}
      loading={loading}
    />
  )
}

export const UserResultsContainer: React.FC<
  Omit<OtherResultsContainerProps, keyof { items: never; getItemUrlById: never }>
> = (props) => {
  const { type, page, sort, params } = props
  const searchParams = useSearchParams(params)

  const { data, loading, error } = useGetUserSearchResultsQuery({
    variables: {
      limit: PAGE_SIZE,
      offset: ((page || 1) - 1) * PAGE_SIZE,
      sort: sort && [sort],
      params: searchParams,
    },
  })

  const { resultsCount, pageCount, expectedCount } = useActiveResults({ type, params, page }, data?.searchUsers.total)

  const items = data?.searchUsers.results.map(extractItem) ?? pendingItems(expectedCount)

  if (error) throw error

  return (
    <OtherResultsContainer
      {...props}
      items={items}
      pageCount={pageCount}
      resultsCount={resultsCount}
      loading={loading}
    />
  )
}

export const SavedSearchResultsContainer: React.FC<
  Omit<OtherResultsContainerProps, keyof { items: never; getItemUrlById: never }>
> = (props) => {
  const { type, page, sort, params } = props
  const searchParams = useSearchParams(params)

  const { data, loading, error } = useGetSavedSearchSearchResultsQuery({
    variables: {
      limit: PAGE_SIZE,
      offset: ((page || 1) - 1) * PAGE_SIZE,
      sort: sort && [sort],
      params: searchParams,
    },
  })

  const { resultsCount, pageCount, expectedCount } = useActiveResults(
    { type, params, page },
    data?.searchSavedSearches.total
  )

  const items = data?.searchSavedSearches.results.map(extractItem) ?? pendingItems(expectedCount)

  if (error) throw error

  return (
    <OtherResultsContainer
      {...props}
      items={items}
      pageCount={pageCount}
      resultsCount={resultsCount}
      loading={loading}
    />
  )
}
