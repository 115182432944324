import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { noop } from 'lodash'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFields } from '../../lib'
import { JobStatusEnum } from '../../schema/base'
import { Lookups } from '../lookups'
import { DeleteIcon } from '../Icons'
import { useNotifications } from '../AppNotifications'
import log from 'loglevel'

export interface ImageManagerDialogProps {
  Preview: React.FC<{ src: string }>
  getUploadTarget: () => Promise<{ token: string; url: string; fields?: Record<string, string> }>
  lookups: Lookups
  onClose: () => void
  onTokenChange: (token: string | null) => Promise<unknown>
  open: boolean
  title: React.ReactNode
  url?: string
}

const POLL_INTERVAL_MS = 3 * 1000
const PROCESSING_TIMEOUT_MS = 60 * 1000

export const ImageManagerDialog: React.FC<ImageManagerDialogProps> = ({
  Preview,
  getUploadTarget,
  lookups,
  onClose,
  onTokenChange,
  open,
  title,
  url,
}) => {
  const { getImageUploadStatus, putFile } = lookups
  const { show, catchError } = useNotifications()

  // STATE

  const [fields, setFields, _, isDirty] = useFields(
    {
      previewSrc: url ?? '',
      uploadFile: null as any,
      status: 'user' as 'user' | 'uploading' | 'processing' | 'removing' | 'success',
    },
    (f) => f,
    (f, c) => f.previewSrc === c.previewSrc,
    [open]
  )
  const { previewSrc, uploadFile, status } = fields

  // HANDLERS

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 1) {
      show('Only a single file is allowed.', { variant: 'error' })
      return
    }

    const file = acceptedFiles[0]
    const fileReader = new FileReader()

    fileReader.onabort = () => {
      show('Upload aborted.', { variant: 'error' })
    }
    fileReader.onerror = () => {
      show('File could not be read.', { variant: 'error' })
    }
    fileReader.onload = async () => {
      const dataUrl = fileReader.result
      if (!dataUrl) throw new Error()

      setFields.$.previewSrc(dataUrl.toString())
      setFields.$.uploadFile(file)
    }

    fileReader.readAsDataURL(file)
  }, [])

  const handleSave = async () => {
    try {
      if (previewSrc) {
        setFields.$.status('uploading')
        const { url, token, fields = {} } = await getUploadTarget()
        await putFile(url, { file: uploadFile, ...fields })

        setFields.$.status('processing')

        const startTime = Date.now()
        let done = false
        while (!done) {
          if (Date.now() - startTime > PROCESSING_TIMEOUT_MS) throw new Error('Timed out')
          await new Promise((resolve) => {
            window.setTimeout(resolve, POLL_INTERVAL_MS)
          })
          const check = await getImageUploadStatus(token)
          switch (check?.status) {
            case JobStatusEnum.Success:
              done = true
              break
            case JobStatusEnum.Processing:
              log.debug('processing image', `${(Date.now() - startTime) / 1000}s`)
              break
            case JobStatusEnum.Failed:
            case JobStatusEnum.Unknown:
            default:
              throw new Error(check?.error ?? 'Unknown error')
          }
        }

        await onTokenChange(token)
      } else {
        setFields.$.status('removing')
        await onTokenChange(null)
      }

      setFields.$.status('success')
      onClose()
    } catch (err) {
      catchError('Failed to set image', false)(err)
      setFields.$.status('user')
    }
  }

  const handleDelete = () => {
    setFields.$.previewSrc('')
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': ['.png', '.jpg'],
    },
    maxFiles: 1,
  })

  // RENDER

  const isWorking = status !== 'user'
  const handleClose = isWorking ? noop : onClose
  const hasImage = !!previewSrc
  const theme = useTheme()
  const primaryColor = theme.palette.primary

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          mb: 3,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ height: 500 }}>
        {hasImage ? (
          <Box sx={{ height: '100%', width: '100%' }}>
            <Stack sx={{ height: 'calc(100% - 30px)', width: '100%' }} alignItems="center" justifyContent="center">
              <Preview src={previewSrc} />
            </Stack>
            <Box sx={{ height: 30, display: 'flex', justifyContent: 'center' }}>
              <Button startIcon={<DeleteIcon />} onClick={handleDelete} disabled={isWorking}>
                Remove Image
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: '100%', position: 'relative' }}>
            <Box
              {...getRootProps()}
              sx={{
                border: isDragActive ? `2px solid ${primaryColor.main}` : '2px dashed grey',
                background: isDragActive ? primaryColor.light : 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                padding: '2rem',
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />

              {isDragActive ? (
                <Typography>drop image here</Typography>
              ) : (
                <>
                  <Typography sx={{ fontSize: '120%' }}>No Image</Typography>
                  <Typography>drag image here or click to select file</Typography>
                </>
              )}
            </Box>
          </Box>
        )}
        <Box sx={{ height: 10 }}>{isWorking && <LinearProgress />}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isWorking} color="error">
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleSave} disabled={!isDirty || isWorking}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
