/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { GetCollectionDetailQuery, MetaDataInput } from '../../schema/base'

import { MetadataTable } from '../../index'

export interface MetadataPanelProps {
  collection: NonNullable<GetCollectionDetailQuery['collection']>
  onMetadataChange: (newValue: MetaDataInput[]) => Promise<unknown>
}

export const MetadataPanel: React.FC<MetadataPanelProps> = ({ collection, onMetadataChange }) => {
  const canEdit = Boolean(collection.permissions.update)

  return (
    <Box>
      <MetadataTable value={collection.meta} canEdit={canEdit} onChange={onMetadataChange} />
    </Box>
  )
}
