import { Box, CardProps, SxProps, Theme, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Project, ProjectCard, ProjectCardFragment, ProjectCardSkeleton } from '@riverscapes/react-common'
import { useProjectContext } from '../hooks/useProjectContext'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  appBar: {
    flex: '0 0',
  },
  tabs: {
    // height: theme.spacing(12),
    // flex: '0 0',
  },
  tab: {
    // height: theme.spacing(12),
    // flex: '0 0',
    flex: '1 1',
  },
  tabContents: {
    position: 'relative',
    overflow: 'hidden',
    flex: '1 1',
  },
  closeButton: {
    zIndex: 1000,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

interface TreeDrawerProps {
  backToProject: () => void
  project: Project | null
  loading?: boolean
  tabs: { label: string | React.ReactNode; icon: React.ReactElement; value: React.ReactNode }[]
}

const TreeDrawer: React.FC<TreeDrawerProps> = ({ tabs, backToProject, project, loading }: TreeDrawerProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const { lookups } = useProjectContext()
  const [activeTab, setValue] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    setValue(newValue)
  }
  const cardProps: CardProps = {
    elevation: 11,
  }

  return (
    <Box sx={styles.root}>
      {/* <Tabs value={activeTab} sx={styles.tabs} onChange={handleChange}>
        <Tab
          key={`tab-back`}
          sx={styles.tab}
          icon={<ArrowBack />}
          label="Back"
          value={'back'}
          onClick={backToProject}
          iconPosition="start"
        />
        {tabs.map((tab, idx) => (
          <Tab key={`tab-${idx}`} sx={styles.tab} label={tab.label} value={idx} icon={tab.icon} iconPosition="start" />
        ))}
      </Tabs> */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Typography variant="overline" component="div" sx={{ padding: theme.spacing(1) }}>
          Map Viewer for Riverscapes Project:
        </Typography>

        <Box px={1} pb={2}>
          {!project || loading ? (
            // Box prevents card collapse
            <ProjectCardSkeleton {...cardProps} />
          ) : (
            // Box prevents card collapse
            <ProjectCard project={project as ProjectCardFragment} lookups={lookups} {...cardProps} />
          )}
        </Box>
      </Box>
      {tabs.map(
        ({ value }, tid) =>
          tid === activeTab && (
            <Box key={`tab-${tid}`} sx={styles.tabContents}>
              {value}
            </Box>
          )
      )}
    </Box>
  )
}

export default TreeDrawer
