import React from 'react'
import { MetaData, MetaDataTypeEnum, Project } from '@riverscapes/react-common'
import MetaDataComponent from './meta/MetaData'
import { Box, Divider, Paper, SxProps, Theme, Typography, useTheme } from '@mui/material'
import DownloadZipBtn from './DownloadZipBtn'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { PendingZips } from '@riverscapes/react-common'
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    p: 1,
    height: '100%',
    overflowY: 'scroll',
    userSelect: 'text',
    '& *': {
      userSelect: 'text',
    },
  },
  btnGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&>*': {
      flex: '0 0 calc(50% - 0.5em)',
    },
    m: [1, 1, 3, 1],
  },
  divider: {
    mt: 3,
  },
})

interface ProjectInfoTabProps {
  project: Project
  downloadZipFile: () => Promise<void>
  pendingZips: PendingZips
}

const ProjectInfoTab: React.FC<ProjectInfoTabProps> = ({
  project,
  downloadZipFile,
  pendingZips,
}: ProjectInfoTabProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const projectInfo = {
    'Data Exchange ID': project.id,
    'Data Exchange Tags': Array.isArray(project.tags) ? project.tags.join(', ') : '<None>',
  }
  const projectinfoMeta: MetaData[] = Object.keys(projectInfo).map((key) => ({
    key,
    value: projectInfo[key],
    __typename: 'MetaData',
  }))
  const pendingSince = project && pendingZips[project.id]
  return (
    <Paper sx={styles.root}>
      <Divider />
      <Typography component="div" paragraph variant="body1">
        This project exists within the Riverscapes Data Exchange. You can download this project and view it using free{' '}
        <a href="https://viewer.riverscapes.net" target="_blank" rel="noreferrer">
          Riverscapes Viewer plugins for ArcMap 10.x and QGIS
        </a>{' '}
        in desktop GIS. Note that a viewer does not yet exist for ArcGIS Pro (but it is planned for 2024).
      </Typography>
      <Box sx={styles.btnGroup}>
        <DownloadZipBtn downloadZipFile={downloadZipFile} pendingSince={pendingSince} title="Download" />
      </Box>
      <MetaDataComponent title="Project Information" meta={projectinfoMeta} />
      <MetaDataComponent
        title="Project Metadata"
        meta={project.meta.filter(({ type }) => type !== MetaDataTypeEnum.Hidden)}
      />

      <Divider sx={styles.divider} />
      <a href="http://riverscapes.net" target="_blank" rel="noreferrer">
        <img alt="footer" src={`${process.env.PUBLIC_URL}/cc-watermarks-riverscapes_orig.png`} />
      </a>
    </Paper>
  )
}

export default ProjectInfoTab
