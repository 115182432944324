import React, { useEffect, useRef } from 'react'
import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import { isUndefined } from 'lodash'

export type InfiniscrollerWidgetProps = {
  hasMore?: boolean
  loading?: boolean
  // By comparing the total number of results to the offset of the last result, we can determine if there are more results to fetch
  itemsTotal?: number
  pageTotal?: number
  pageOffset?: number
  onFetchMore: () => void
}

export const InfiniscrollerWidget: React.FC<InfiniscrollerWidgetProps> = ({
  hasMore,
  loading,
  onFetchMore,
  itemsTotal,
  pageTotal,
  pageOffset,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!hasMore || loading) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onFetchMore()
        }
      },
      { rootMargin: '100px' } // Load more when the widget is 100px from the viewport
    )

    if (scrollRef.current) {
      observer.observe(scrollRef.current)
    }

    return () => {
      if (scrollRef.current) {
        observer.unobserve(scrollRef.current)
      }
    }
  }, [hasMore, loading, onFetchMore])

  let showingText
  if (!isUndefined(itemsTotal) && (isUndefined(pageTotal) || isUndefined(pageOffset))) {
    showingText = `Showing ${itemsTotal} results`
  } else if (!isUndefined(pageTotal) && !isUndefined(pageOffset)) {
    if (pageTotal === pageOffset) {
      showingText = `Showing ${pageTotal} results`
    } else {
      showingText = `Showing ${pageTotal} results (${pageOffset - pageTotal} are invisible to you)`
    }
  }

  return (
    <Box
      ref={scrollRef}
      sx={{
        textAlign: 'center',
        '& .MuiTypography-root': {
          fontStyle: 'italic',
        },
      }}
    >
      {loading ? (
        <>
          <LinearProgress />
          <Typography variant="caption" color="text.secondary">
            {showingText}. Loading more results...
          </Typography>
        </>
      ) : !hasMore ? (
        <>
          <Divider />
          <Typography variant="caption" color="text.secondary">
            {showingText}. No more results to load.
          </Typography>
        </>
      ) : (
        <>
          <LinearProgress />
          <Typography variant="caption" color="text.secondary">
            {showingText}. Loading more results...
          </Typography>
        </>
      )}
    </Box>
  )
}
