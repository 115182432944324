import { memo } from 'react'
import { FeatureCollection, Feature } from 'geojson'
import { Layer, Source, CircleLayer } from 'react-map-gl'

export interface PointsProps {
  points: {
    id: string
    coords: number[]
  }[]
}

const pointPaint: CircleLayer['paint'] = {
  'circle-radius': 5,
  'circle-color': '#ff00ff',
  'circle-opacity': 0.8,
}

export const Points = memo<PointsProps>(function Points({ points }) {
  const data: FeatureCollection = {
    type: 'FeatureCollection',
    features: points.map<Feature>(({ id, coords }) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: coords,
      },
    })),
  }

  return (
    <Source id="points" type="geojson" data={data}>
      <Layer id="points" type="circle" paint={pointPaint} />
    </Source>
  )
})
