import React from 'react'
import { Menu, MenuItem, SxProps, Theme, useTheme } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {},
  item: {},
  menuItemIcon: {
    mr: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
})

export type CtxMenuItemType = {
  label: string
  error?: boolean
  Icon?: SvgIconComponent
  handleClick?: () => void
  disabled?: boolean
}[]

export interface TreeControlProps {
  position?: { top: number; left: number }
  open: boolean
  onClose: () => void
  menuItems: CtxMenuItemType
}

const TreeContextMenu: React.FC<TreeControlProps> = ({ open, position, onClose, menuItems }: TreeControlProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  return (
    <Menu
      keepMounted
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={position}
      sx={styles.root}
    >
      {menuItems.map(({ label, Icon, handleClick, disabled, error }, idx) => {
        const menuItemStyles = {
          ...styles.item,
          ...(error ? styles.error : {}),
        }
        return (
          <MenuItem key={`ctxMenuIt-${idx}`} onClick={handleClick} disabled={disabled} sx={menuItemStyles}>
            {Icon && <Icon sx={styles.menuItemIcon} color="inherit" />}
            {label}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default TreeContextMenu
