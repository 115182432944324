import {
  Typography,
  Card,
  CardContent,
  Link,
  Skeleton,
  Stack,
  CardHeader,
  useTheme,
  Box,
  IconButton,
} from '@mui/material'

import { CollectionCardFragment } from '../../schema/base'
import { CollectionImage } from '../ItemImage'
import { VisibilityChip } from '../Visibility'
// import Clamp from 'react-multiline-clamp'
import { useBooleanState, useFormats, withTooltip } from '../../lib'
import { Lookups } from '../lookups'
import { RemoveIcon } from '../Icons'
import { RemoveProjectDialog } from '../CollectionDetail/RemoveProjectDialog'

export interface CollectionCardProps {
  className?: string
  onRemoveFromCollectionClick?: (collectionId: string) => void
  collection: CollectionCardFragment
  lookups: Lookups
}

export const CollectionCard: React.FC<CollectionCardProps> = ({
  className,
  collection,
  lookups,
  onRemoveFromCollectionClick,
}) => {
  const { formatNumber, pluralize } = useFormats()
  const theme = useTheme()
  const primary = useTheme().palette.primary.main
  const [isRemoveProjectOpen, openRemoveProject, closeRemoveProject] = useBooleanState()

  const heroImageUrl = collection.heroImage?.sm

  return (
    <Card sx={{ minWidth: '15rem', mb: 2 }} className={className} variant="elevation" elevation={2}>
      <CardHeader
        sx={{
          backgroundColor: primary,
          backgroundImage: heroImageUrl && `url(${heroImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        subheader={
          <Box sx={{ position: 'relative' }}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Link href={lookups.getCollectionUrlById(collection.id)}>
                <Stack direction="row" alignItems="center" gap={1} sx={{ color: theme.palette.primary.contrastText }}>
                  <CollectionImage size={30} />
                  <Typography variant="h6" color="white" className={heroImageUrl ? 'heroText' : undefined}>
                    {collection.name}
                  </Typography>
                </Stack>
              </Link>
              <Box sx={{ flexGrow: 1 }} />
              {onRemoveFromCollectionClick &&
                withTooltip(
                  <IconButton
                    size="small"
                    sx={{ marginLeft: 'auto', color: 'white' }}
                    onClick={openRemoveProject}
                    disabled={!collection.permissions.update}
                  >
                    <RemoveIcon color="inherit" />
                  </IconButton>,
                  collection.permissions.update
                    ? 'Remove this project from collection'
                    : `You don't have permission to remove this project from the collection`,
                  !collection.permissions.update
                )}
            </Stack>
          </Box>
        }
      />
      <CardContent>
        <Stack gap={1} direction="row">
          <Box>
            <VisibilityChip canEdit={false} value={collection.visibility} type="Collection" />
          </Box>
          <Stack flex={1}>
            <Typography>
              {/* <Clamp>{collection.summary}</Clamp> */}
              {collection.summary}
            </Typography>
          </Stack>
          <Link href={lookups.getCollectionUrlById(collection.id, 'projects')}>
            <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
              <Typography variant="h4">{formatNumber(collection.projects.total)}</Typography>
              <Typography variant="body2">{pluralize('project', collection.projects.total)}</Typography>
            </Stack>
          </Link>
        </Stack>
      </CardContent>
      {onRemoveFromCollectionClick && (
        <RemoveProjectDialog
          collectionName={collection.name}
          open={isRemoveProjectOpen}
          onClose={closeRemoveProject}
          onConfirm={() => onRemoveFromCollectionClick(collection.id)}
        />
      )}
    </Card>
  )
}

export const CollectionCardSkeleton: React.FC = () => {
  return (
    <Card
      sx={{
        minWidth: '15rem',
        maxWidth: '40rem',
      }}
    >
      <CardContent>
        <Skeleton />
        <Skeleton />
        <Typography>&nbsp;</Typography>
        <Typography>&nbsp;</Typography>
      </CardContent>
    </Card>
  )
}
