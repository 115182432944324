import {
  GetProjectSearchResultsQuery,
  GetProjectSearchResultsQueryVariables,
  GetProjectSearchResultsIdsQuery,
  GetProjectSearchResultsIdsQueryVariables,
  operations,
} from '@riverscapes/react-common'
import { makeImperativeQueryHook } from '../helpers'

const {
  GetProjectSearchResultsDocument,
  GetProjectSearchResultsIdsDocument,
  useGetOrganizationSearchResultsQuery,
  useGetSavedSearchSearchResultsQuery,
  useGetSearchMapBoundsQuery,
  useGetSearchMapClustersQuery,
  useGetSmartSearchResultsQuery,
  useGetSmartSearchResultsLazyQuery,
  useGetUserSearchResultsQuery,
} = operations

export {
  GetProjectSearchResultsDocument,
  useGetOrganizationSearchResultsQuery,
  useGetSavedSearchSearchResultsQuery,
  useGetSearchMapBoundsQuery,
  useGetSearchMapClustersQuery,
  useGetSmartSearchResultsQuery,
  useGetSmartSearchResultsLazyQuery,
  useGetUserSearchResultsQuery,
}

export const useGetProjectSearchResultsImperativeQuery = makeImperativeQueryHook<
  GetProjectSearchResultsQueryVariables,
  GetProjectSearchResultsQuery
>(GetProjectSearchResultsDocument)

export const useGetProjectSearchResultsIdsImperativeQuery = makeImperativeQueryHook<
  GetProjectSearchResultsIdsQueryVariables,
  GetProjectSearchResultsIdsQuery
>(GetProjectSearchResultsIdsDocument)
