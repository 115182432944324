import React from 'react'
import { Alert, AlertTitle, Container, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useActivity } from '../../lib'

export interface NotFoundProps {
  id: string
  descriptor: string
}

export const NotFound: React.FC<NotFoundProps> = ({ id, descriptor }) => {
  const { purgeById } = useActivity()

  useEffect(() => {
    purgeById(id)
  }, [])

  return (
    <Container maxWidth="md" sx={{ p: 2, height: '100%', maxHeight: 500 }}>
      <Alert severity="error">
        <AlertTitle>Not Found</AlertTitle>
        <Typography variant="h2" paragraph>
          {descriptor} not found.
        </Typography>
        <Typography variant="body1" paragraph>
          The {descriptor} you are looking for does not exist or has been deleted.
        </Typography>
      </Alert>
    </Container>
  )
}
