import { useState, useRef } from 'react'
import { PendingZips } from '../types'
import _ from 'lodash'
import log from 'loglevel'
import { useDownloadFileLazyQuery, useDownloadZipLazyQuery } from '../schema/operations'

interface UseDownloadReturn {
  pendingZips: PendingZips
  downloadFileFn: (projectId: string, fkey: string) => Promise<void>
  downloadZipFile: (projectId: string) => Promise<void>
}

export const useDownload = (): UseDownloadReturn => {
  const [pendingZips, setPendingZips] = useState<PendingZips>({})
  const pendingZipsRef = useRef<PendingZips>()
  pendingZipsRef.current = pendingZips

  const [downloadZipLazyQuery] = useDownloadZipLazyQuery()
  const [downloadFileLazyQuery] = useDownloadFileLazyQuery()

  const downloadFileFn = async (projectId: string, fkey: string): Promise<void> =>
    downloadFileLazyQuery({
      variables: {
        projectId,
        filePath: fkey,
      },
      fetchPolicy: 'network-only',
    })
      .then((res) => {
        if (res.data?.downloadFile?.downloadUrl) {
          window.open(res.data?.downloadFile?.downloadUrl, '_self')
        } else throw new Error('Could not download file')
      })
      .catch((err) => {
        log.error('DOWNLOAD err', err)
      })

  const downloadZipFile = (projectId: string): Promise<void> => {
    const checkZipFunc = (): Promise<void> =>
      downloadZipLazyQuery({
        variables: {
          projectId,
        },
        fetchPolicy: 'network-only',
      })
        .then((res) => {
          try {
            if (res.data && !res.data.downloadZip?.pendingSince && res.data.downloadZip?.downloadUrl) {
              setPendingZips(_.pickBy(pendingZipsRef.current, (val, key) => key !== projectId))
              window.open(res.data.downloadZip?.downloadUrl, '_self')
            } else {
              setPendingZips({
                ...pendingZipsRef.current,
                [projectId]: new Date(res.data?.downloadZip?.pendingSince || new Date()).getTime(),
              })
              setTimeout(checkZipFunc, 5000)
            }
          } catch (err) {
            log.error('DOWNLOAD err', err)
          }
        })
        .catch((err) => {
          log.error('DOWNLOAD err', err)
        })
    // try once
    return checkZipFunc()
  }

  return {
    pendingZips: pendingZipsRef.current,
    downloadFileFn,
    downloadZipFile,
  }
}
