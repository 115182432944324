import { useEffect, useState } from 'react'
import { SearchSpec } from '../components/Search'

export const PAGE_SIZE = 10 // TODO: make this configurable? Move?
export const INFINISCROLLER_PAGE_SIZE = 20 // TODO: make this configurable? Move?

export const useActiveResults = (
  { type, params, page = 1 }: Partial<SearchSpec>,
  queryResultsTotal: number | undefined
): {
  pageCount: number
  expectedCount: number
  resultsCount?: number
} => {
  const [resultsCount, setResultsCount] = useState<number | undefined>(queryResultsTotal)

  const searchSpecKey = JSON.stringify({ type, params })

  useEffect(() => {
    setResultsCount(undefined)
  }, [searchSpecKey])

  useEffect(() => {
    if (typeof queryResultsTotal !== 'undefined') setResultsCount(queryResultsTotal)
  }, [queryResultsTotal])

  const pageCount = getPageCount(resultsCount)

  const expectedCount = (() => {
    if (typeof resultsCount === 'undefined') return 3 // 3 = (arbitrary) helpful number of pending items (skeletons) to show search in progress
    return Math.min(PAGE_SIZE, resultsCount - (page - 1) * PAGE_SIZE) // show exactly as many pending items as are expected to appear
  })()

  return { pageCount, expectedCount, resultsCount }
}

export const getPageCount = (resultsCount = 0): number => {
  return Math.ceil(resultsCount / PAGE_SIZE)
}
