import React from 'react'
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'

// import log from 'loglevel'
import { useNavigate } from 'react-router-dom'
import { useFormats } from '../../lib'
import { HomeCard } from './HomeCard'
import { Groups, LibraryBooks, Map, Person, Search as SearchIcon } from '@mui/icons-material'
import { SearchSpec } from '../Search'

export interface ExploreCardProps {
  totalProjects: number
  getSearchUrl: (props: SearchSpec) => string
}

export const ExploreCard: React.FC<ExploreCardProps> = ({ getSearchUrl, totalProjects }) => {
  const theme = useTheme()
  const { formatNumber } = useFormats()
  const navigate = useNavigate()

  return (
    <HomeCard
      cardProps={{
        sx: {
          cursor: 'pointer',
        },
      }}
    >
      <CardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchIcon sx={{ mr: 1 }} /> Explore, Search, Find
          </Box>
        }
      />

      {/* <CardMedia component="img" height="140" image={`${process.env.PUBLIC_URL}/bg/mj1.jpg`} /> */}
      <CardContent sx={{ flexGrow: 1 }}>
        <List
          dense
          disablePadding
          sx={{
            '& .MuiListItemText-root': {
              my: 0,
            },
          }}
        >
          <ListItemButton
            disableGutters
            href={getSearchUrl({
              type: 'Project',
              params: {},
            })}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                <Map />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Explore our Project Map"
              secondary={
                <>
                  Interactive map with all {totalProjects > 0 ? <strong>{formatNumber(totalProjects)}</strong> : ''}{' '}
                  projects.
                </>
              }
              secondaryTypographyProps={{ variant: 'caption', fontSize: '0.7rem' }}
            />
          </ListItemButton>
          {/* <ListItemButton disableGutters>
            <ListItemAvatar>
              <Avatar>
                <Article />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Project Search"
              secondary={`Search for one of our ${formatNumber(totalProjects)} Projects`}
              secondaryTypographyProps={{ variant: 'caption', fontSize: '0.65rem' }}
            />
          </ListItemButton> */}
          <ListItemButton
            disableGutters
            href={getSearchUrl({
              type: 'Collection',
              params: {},
            })}
          >
            <ListItemAvatar>
              <Avatar>
                <LibraryBooks />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Collection Search"
              secondary={`Search for collections of projects with a common theme or purpose.`}
              secondaryTypographyProps={{ variant: 'caption', fontSize: '0.7rem' }}
            />
          </ListItemButton>
          <ListItemButton
            disableGutters
            href={getSearchUrl({
              type: 'Organization',
              params: {},
            })}
          >
            <ListItemAvatar>
              <Avatar>
                <Groups />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Organization Search"
              secondary={`Find Organizations that have contributed projects.`}
              secondaryTypographyProps={{ variant: 'caption', fontSize: '0.7rem' }}
            />
          </ListItemButton>
          <ListItemButton
            disableGutters
            href={getSearchUrl({
              type: 'User',
              params: {},
            })}
          >
            <ListItemAvatar>
              <Avatar>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="User Search"
              secondary={`Find individuals who have contributed projects.`}
              secondaryTypographyProps={{ variant: 'caption', fontSize: '0.7rem' }}
            />
          </ListItemButton>
        </List>
      </CardContent>
      {/* <CardActions>
        <Button href={exploreUrl}>Explore Map</Button>
      </CardActions> */}
    </HomeCard>
  )
}
