import { ContentCopy, WarningOutlined } from '@mui/icons-material'
import { Box, Chip, IconButton, SxProps, Theme, Tooltip, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { ViewState } from 'react-map-gl'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    display: 'flex',
    '& .MuiChip-label': {
      display: 'flex',
    },
  },
  normalBG: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.light,
    '&:hover,&.focus': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.dark,
    },
  },
  '@keyframes overzoomFade': {
    '0%': {
      backgroundColor: theme.palette.warning.dark,
    },
    '50%': {
      backgroundColor: theme.palette.warning.light,
    },
    '100%': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  normalButtons: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.dark,
  },
  overZBG: {
    color: theme.palette.warning.contrastText,
    background: theme.palette.warning.main,
    '&:hover,&.focus': {
      color: theme.palette.warning.contrastText,
      background: theme.palette.warning.dark,
    },
    animation: '$overzoomFade 2s infinite',
  },
  overZButtons: {
    color: theme.palette.warning.contrastText,
    background: theme.palette.warning.dark,
  },
  labelIcon: {
    background: 'transparent',
    '&:hover,&.focus': {
      background: 'transparent',
    },
  },
  warnText: {
    color: theme.palette.warning.light,
  },
  chipIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    fontSize: theme.typography.caption.fontSize,
    padding: 0,
    '& svg': {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  coordsTxt: {
    mr: theme.spacing(1),
    ml: theme.spacing(1),
  },
  avatar: {},
  expanded: {},
  helpIcon: { ml: theme.spacing(1) },
  overZoomed: {
    color: theme.palette.warning.contrastText,
    background: theme.palette.warning.main,
  },
})

interface ZoomIndicatorProps {
  viewport: ViewState
  overZoomed?: boolean
  onHelpClick: () => void
  onCopyClick: () => void
  sx?: SxProps<Theme>
}

const ZoomIndicator: React.FC<ZoomIndicatorProps> = ({
  viewport,
  overZoomed,
  onHelpClick,
  onCopyClick,
  sx,
}: ZoomIndicatorProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const [expanded, setExpanded] = useState<boolean>(false)

  const preventDefault = (cb?: () => unknown) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (cb) cb()
  }
  const cc = {
    bg: overZoomed ? styles.overZBG : styles.normalBG,
    btn: overZoomed ? styles.overZButtons : styles.normalButtons,
  }

  const rootStyle = {
    ...styles.root,
    ...cc.bg,
    ...(sx || {}),
  }

  const iconSx = { ...styles.chipIcon, ...cc.btn, ...styles.helpIcon }

  return (
    <Chip
      size="small"
      sx={rootStyle}
      onClick={() => setExpanded(!expanded)}
      clickable={false}
      color="primary"
      label={
        <>
          <Tooltip
            title={
              <>
                <Box>{!expanded ? 'Map zoom (click to expand)' : 'Map zoom & coords'}</Box>
                {overZoomed && <Box sx={styles.warnText}>Warning: Map Zoom exceeds raster tile resolution</Box>}
              </>
            }
          >
            <Box sx={{ display: 'flex' }}>
              {expanded && `Zoom: `}
              {viewport.zoom ? viewport.zoom.toFixed(1) : '?'}
              {expanded && (
                <Box sx={styles.coordsTxt}>
                  {' Center: '}( {viewport.longitude?.toFixed(5)}, {viewport.latitude?.toFixed(5)} ){' '}
                </Box>
              )}
            </Box>
          </Tooltip>
          {expanded && (
            <Tooltip title="Copy map coordinates to clipboard">
              <IconButton onMouseOver={preventDefault()} onClick={preventDefault(onCopyClick)} sx={iconSx}>
                <ContentCopy />
              </IconButton>
            </Tooltip>
          )}
          {overZoomed && (
            <Tooltip title="Click to learn more about this warning.">
              <IconButton onMouseOver={preventDefault()} onClick={preventDefault(onHelpClick)} sx={iconSx}>
                <WarningOutlined />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
    />
  )
}

export default ZoomIndicator
