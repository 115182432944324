import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FavoriteStar } from '../FavoriteStar'
import { ShiftRight } from '../ShiftRight'
import { ShareButton } from '../Share'
import { GetCollectionDetailQuery } from '../../schema/base'
import { HeroImage, HeroImageEditButton, HeroImageEditButtonProps } from '../HeroImage'
import { DetailPageHeader } from '../DetailPage'
import { CollectionImage, ItemRepresentation } from '../ItemImage'
import { EditableTypography } from '../EditableTypography'
import { Lookups } from '../lookups'
import { validate } from '../../lib'

export interface HeaderProps {
  collection: NonNullable<GetCollectionDetailQuery['collection']>
  getHeroUploadTarget: HeroImageEditButtonProps['getUploadTarget']
  lookups: Lookups
  onHeroChange: HeroImageEditButtonProps['onChange']
  onNameChange: (newValue: string) => Promise<unknown>
  onStarChange: (newValue: boolean) => void
  onSummaryChange: (newValue: string) => Promise<unknown>
}

export const Header: React.FC<HeaderProps> = ({
  collection,
  getHeroUploadTarget,
  lookups,
  onHeroChange,
  onNameChange,
  onStarChange,
  onSummaryChange,
}) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const canEdit = Boolean(collection.permissions.update)

  return (
    <DetailPageHeader>
      <HeroImage url={collection.heroImage?.lg} color={theme.palette.secondary} />
      <Container maxWidth="lg">
        <Typography variant="overline" sx={{ p: 0, m: 0 }} className="heroText">
          Collection
        </Typography>
        <Stack direction="column" justifyContent="center" p={2} spacing={2} height="100%">
          <Stack
            direction={isMdUp ? 'row' : 'column'}
            spacing={1}
            alignItems="center"
            sx={{ width: '100%', color: theme.palette.primary.contrastText }}
          >
            <CollectionImage size={60} />
            <EditableTypography
              className="heroText"
              canEdit={canEdit}
              content={collection.name}
              getError={validate.name}
              name="Name"
              headerMode
              onChange={onNameChange}
              variant="h3"
            />
            <ShiftRight />
            <Stack direction="row">
              <FavoriteStar value={collection.starred} size={32} onChange={onStarChange} className="heroIcon" />
              <ShareButton
                title={collection.name}
                url={lookups.getCollectionUrlById(collection.id)}
                className="heroIcon"
              />
            </Stack>
          </Stack>
          <Stack direction={isMdUp ? 'row' : 'column'} spacing={2}>
            {collection.contact && (
              <Stack direction="column" alignItems="center">
                <Typography variant="caption" className="heroText">
                  Contact
                </Typography>
                <ItemRepresentation
                  lookups={lookups}
                  item={collection.contact}
                  link
                  size="large"
                  hideName
                  tooltipPrefix="Collection Contact:"
                />
              </Stack>
            )}
            <Stack direction="column" alignItems="center">
              <Typography variant="caption" className="heroText">
                Owner
              </Typography>
              <ItemRepresentation
                lookups={lookups}
                item={collection.ownedBy}
                link
                size="large"
                hideName
                color="white"
                tooltipPrefix="Collection owned by:"
              />
            </Stack>
            <EditableTypography
              className="heroText"
              content={collection.summary}
              canEdit={canEdit}
              getError={validate.summary}
              name="Summary"
              onChange={onSummaryChange}
              headerMode
              placeholder={canEdit && 'No summary.'}
            />
          </Stack>
        </Stack>
      </Container>
      {canEdit && (
        <HeroImageEditButton
          className="heroIcon"
          sx={{ position: 'absolute', right: 0, top: 0 }}
          getUploadTarget={getHeroUploadTarget}
          lookups={lookups}
          onChange={onHeroChange}
          url={collection.heroImage?.lg}
        />
      )}
    </DetailPageHeader>
  )
}
