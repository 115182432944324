import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

export interface ChangePasswordProps {
  changePassword: () => Promise<boolean>
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({ changePassword }) => {
  const theme = useTheme()
  return (
    <Grid2 container>
      <Grid2 xs={12} sm={6}>
        <Card>
          <CardHeader
            sx={{
              color: theme.palette.warning.contrastText,
              backgroundColor: theme.palette.warning.main,
            }}
            title="Change Password"
          />

          <CardContent>
            <Typography variant="body1">
              When you click the button below you will receive an email with a link and instructions on how to change
              your password.
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={changePassword} variant="contained" color="warning" fullWidth>
              {"Request 'Change Password' Email"}
            </Button>
          </CardActions>
        </Card>
      </Grid2>
    </Grid2>
  )
}
