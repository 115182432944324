import React from 'react'
import { Box, Button, IconButton, List, ListItemButton, useTheme } from '@mui/material'

import { ProjectTypeItemFragment } from '../../schema/base'
import { Stack, alpha } from '@mui/system'
import { ItemRepresentation } from '../ItemImage'
import { Map, OpenInNew } from '@mui/icons-material'
import { useFormats, withTooltip } from '../../lib'
import { ProjectTypeTooltipCard } from './ProjectTypeTooltipCard'
import { PendingItem } from '../PendingItem'

export interface ProjectTypeListProps {
  projectTypes: (PendingItem | ProjectTypeItemFragment)[]
  projectTypesSummary: Record<string, number>
  onGotoProjectType: (projectTypeId: string) => void
  onGotoSearch: (projectTypeId: string) => void
}

export const ProjectTypeList: React.FC<ProjectTypeListProps> = ({
  projectTypes,
  projectTypesSummary,
  onGotoProjectType,
  onGotoSearch,
}) => {
  const theme = useTheme()
  const { formatNumber } = useFormats()
  const sortedProjectTypes = [...projectTypes].sort((a, b) => {
    const aNum = projectTypesSummary[a.id] || 0
    const bNum = projectTypesSummary[b.id] || 0
    if (aNum === bNum) {
      return (a?.name || '').localeCompare(b?.name || '')
    }
    return bNum - aNum
  })
  return (
    <List
      dense
      sx={{
        flex: '1 1 auto',
        overflowY: 'scroll',
      }}
    >
      {sortedProjectTypes.map((projectType) => {
        const ptypeNum = projectTypesSummary[projectType.id] || 0
        // if The Type is pending itme then we jst return itemrep
        if (projectType.__typename === 'PendingItem') {
          return (
            <ListItemButton
              key={projectType.id}
              onClick={() => onGotoProjectType(projectType.id)}
              sx={{
                // Zebra stripes
                '&:nth-of-type(odd)': {
                  backgroundColor: alpha(theme.palette.action.hover, 0.05),
                },
              }}
            >
              <ItemRepresentation item={projectType} noTooltip />
            </ListItemButton>
          )
        }

        return (
          <ListItemButton
            key={projectType.id}
            onClick={() => onGotoProjectType(projectType.id)}
            sx={{
              // Zebra stripes
              '&:nth-of-type(odd)': {
                backgroundColor: alpha(theme.palette.action.hover, 0.05),
              },
            }}
          >
            <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
              <ProjectTypeTooltipCard projectType={projectType} projectCount={ptypeNum}>
                <Box
                  sx={{
                    flex: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'center',
                  }}
                >
                  <ItemRepresentation item={projectType} noTooltip />
                </Box>
              </ProjectTypeTooltipCard>
              {withTooltip(
                <Button
                  color="primary"
                  onMouseOver={(e) => e.stopPropagation()}
                  size="small"
                  disabled={!ptypeNum}
                  endIcon={<Map />}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onGotoSearch(projectType.id)
                  }}
                >
                  {formatNumber(ptypeNum)}
                </Button>,
                !ptypeNum ? undefined : `Show ${ptypeNum} projects of type ${projectType.name} on the search map.`
              )}
              {withTooltip(
                <IconButton
                  color="primary"
                  size="small"
                  disabled={!projectType.url}
                  onMouseOver={(e) => e.stopPropagation()}
                  href={projectType.url || ''}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <OpenInNew />
                </IconButton>,
                !projectType.url ? undefined : `External ${projectType.name} documentation site.`
              )}
            </Stack>
          </ListItemButton>
        )
      })}
    </List>
  )
}
