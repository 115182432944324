import React from 'react'
import { useRecoilValue } from 'recoil'
import { layerStateSelectorFamily, projectAtom } from '../recoil'
import LayerInfo from '../components/meta/LayerInfo'
import { useProjectContext } from '../hooks/useProjectContext'

interface Props {
  leafID: number
  handleClose: () => void
}

const TreeLayerInfo: React.FC<Props> = ({ leafID, handleClose }: Props) => {
  const { gotoWHProject } = useProjectContext()
  const layer = useRecoilValue(layerStateSelectorFamily(leafID))
  const project = useRecoilValue(projectAtom)

  return (
    <LayerInfo
      leaf={layer.leaf}
      tiles={layer.tiles}
      handleClose={handleClose}
      dsMeta={layer.dsMeta}
      dsProjectRef={layer.dsProjectRef}
      project={project}
      gotoWHProject={gotoWHProject}
    />
  )
}

export default TreeLayerInfo
