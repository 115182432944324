import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Stack, Button, IconButton, Box, Link, CircularProgress, useTheme } from '@mui/material'
import {
  AddToCollectionFromResultsDialog,
  Bbox,
  CollectionInput,
  CreateCollectionDialog,
  CreateSavedSearchDialog,
  LogicError,
  Lookups,
  Menu,
  MissingDataError,
  ModifySearchDialog,
  MoreMenuIcon,
  OtherResultsProps,
  PageTitle,
  ProjectBoundsThumbProps,
  ProjectResults,
  ProjectResultsProps,
  ProjectSearchSpecParams,
  SavedSearchInput,
  SearchSpec,
  SearchSpecExplainer,
  UserCancel,
  isProjectSearchMeta,
  itemToSelectOption,
  pendingItems,
  useBooleanState,
  useFields,
  useInteractiveMapController,
  useNotifications,
  ProjectSearchParamsInput,
  Exact,
  OrganizationRoleEnum,
  INFINISCROLLER_PAGE_SIZE,
  RemoveProjectDialog,
} from '@riverscapes/react-common'
import { ViewState } from 'react-map-gl'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import {
  evictAllQueries,
  useAddCollectionProjectsMutation,
  useCreateCollectionMutation,
  useCreateSavedSearchMutation,
  useFetchAllWithPendingItems,
  useGetCollectionDetailQuery,
  useGetProfileOrganizationsQuery,
  useGetProjectSearchResultsIdsImperativeQuery,
  useGetProjectSearchResultsQuery,
  useRemoveCollectionProjectsMutation,
} from '../../data'
import { extractId, extractItem, useProfile } from '../../lib'
import { savedSearchDetail, useGotoRoute, webRaveDetail } from '../../routing'
import { SearchProps } from './Search'
import { useProjectsMap } from './useProjectsMap'
import { showDevTools } from '../../config'
import { searchDateInputOrWithin } from './searchDateInputOrWithin'
import { AddCircle, AddToPhotos, Warning } from '@mui/icons-material'

const FETCH_PROJECT_IDS_LIMIT = 500
const ADD_TO_COLLECTION_LIMIT = 1000

export type ProjectResultsContainerProps = Omit<
  ProjectResultsProps,
  keyof {
    NoResults: never
    onFetchMoreProjects: never
    getProjectBoundsThumbProps: never
    getProjectUrlById: never
    items: never
    mapController: never
    onAddBoundsId: never
    onPageChange: never
    onSortChange: never
    pageCount: never
    resultsCount: never
    showBounds: never
  }
> & {
  lookups: Lookups
  gotoSearch: (props: SearchProps, replace?: boolean) => void
  params: ProjectSearchSpecParams
  type: 'Project'
}

type CollectionNotificationProps = {
  name: string
  verb: 'Creating' | 'Created' | 'Adding to' | 'Added to'
  onAction?: () => void
} & (
  | {
      isDone: false
      collectionUrl?: never
    }
  | {
      isDone: true
      collectionUrl: string
    }
)

const CollectionNotification: React.FC<CollectionNotificationProps> = (props) => {
  return (
    <div>
      {props.verb} collection &ldquo;{props.name}&rdquo;
      {props.isDone ? (
        <Button href={props.collectionUrl} onClick={props.onAction}>
          Go To Collection
        </Button>
      ) : (
        <Button onClick={props.onAction} disabled={!props.onAction}>
          Cancel
        </Button>
      )}
    </div>
  )
}

export const ProjectResultsContainer: React.FC<ProjectResultsContainerProps> = ({
  geo,
  gotoSearch,
  lookups,
  params,
  viewType,
  onViewTypeChange,
  sort,
  type,
}) => {
  const theme = useTheme()
  const { create, catchError } = useNotifications()
  const gotoSavedSearch = useGotoRoute(savedSearchDetail)
  const gotoWebRave = useGotoRoute(webRaveDetail)
  const [projectPaginationOffset, setProjectPaginationOffset] = useState(INFINISCROLLER_PAGE_SIZE) // Remember that this doesn't get used until page 2

  const profile = useProfile()

  // BBOX STATE, SEARCH PARAMS
  const [bbox, setBbox] = useState<Bbox | undefined>()

  const { bounded, createdWithin, ...apiParams } = params

  const projectSearchParamsInput = useMemo<Exact<ProjectSearchParamsInput>>(() => {
    const createdOn = searchDateInputOrWithin(apiParams.createdOn, createdWithin)

    const result: Exact<ProjectSearchParamsInput> = {
      ...apiParams,
      createdOn,
      bbox: bounded ? bbox : undefined,
    }
    return result
  }, [apiParams, createdWithin, bounded, bbox])

  // GENERAL STATE

  const [isModifyOpen, openModify, closeModify] = useBooleanState()
  useEffect(closeModify, [JSON.stringify({ type, params })])

  const [isCreateSavedSearchOpen, _openCreateSavedSearch, closeCreateSavedSearch] = useBooleanState()
  const [isCreateCollectionOpen, openCreateCollection, closeCreateCollection] = useBooleanState()
  const [isAddToCollectionOpen, openAddToCollection, closeAddToCollection] = useBooleanState()
  const [removeProjectId, setRemoveProjectId] = useState<string | undefined>()
  const [isOperating, setIsOperating] = useState(false)

  // HANDLERS

  const gotoSelfMerged = (mergeProps: Partial<SearchProps>) => {
    gotoSearch({ type, params, geo, sort: sort || undefined, view: viewType, ...mergeProps }, true)
  }

  const handleNewSearch = (newSearch: SearchSpec) => {
    gotoSearch({ geo, sort: sort || undefined, view: viewType, ...newSearch })
  }

  const handleAdjustedSearch = (newSearch: SearchSpec) => {
    gotoSearch({ geo, sort: sort || undefined, view: viewType, ...newSearch }, true)
  }

  const handleSortChange = (newSort: OtherResultsProps['sort']) => {
    gotoSelfMerged({
      sort: newSort || undefined,
    })
  }

  const getProjectSearchResultsIds = useGetProjectSearchResultsIdsImperativeQuery()
  const [createCollection] = useCreateCollectionMutation()
  const [addCollectionProjects] = useAddCollectionProjectsMutation()

  const handleCreateCollection = async ({
    orgId,
    ...restCollection
  }: { orgId?: string } & Partial<CollectionInput>) => {
    const notification = create({ persist: true })
    const name = restCollection.name ?? ''

    try {
      setIsOperating(true)
      const projectIds: string[] = []
      let collectionId: string | undefined

      let cancel = false

      notification.render(
        <CollectionNotification
          name={name}
          verb="Creating"
          isDone={false}
          onAction={() => {
            notification.close()
            notification.release()
            cancel = true
          }}
        />
      )

      // fetch projects
      try {
        let total = Infinity
        let offset = 0
        const limit = FETCH_PROJECT_IDS_LIMIT

        while (projectIds.length < total && !cancel) {
          const page = await getProjectSearchResultsIds({
            variables: {
              limit,
              offset,
              params: projectSearchParamsInput,
              sort: sort ? [sort] : undefined,
            },
          })
          projectIds.push(...page.searchProjects.results.map(extractItem).map(extractId))
          total = page.searchProjects.total
          offset += limit
        }
      } catch (err) {
        catchError('Failed to determine projects for new collection', true)(err)
      }

      if (cancel) throw new UserCancel()

      notification.render(<CollectionNotification name={name} verb="Creating" isDone={false} />)

      // create collection
      try {
        const newCollection = await createCollection({
          variables: {
            collection: restCollection,
            orgId: orgId,
          },
          update: evictAllQueries,
        })
        if (!newCollection.data?.createCollection) throw new MissingDataError()
        collectionId = newCollection.data.createCollection.id
      } catch (err) {
        catchError('Failed to create new collection', true)(err)
      }

      if (!collectionId) throw new LogicError()

      // populate collection
      try {
        await addCollectionProjects({
          variables: {
            collectionId,
            projectIds,
          },
        })
      } catch (err) {
        catchError('Failed to populate new collection', true)(err)
      }

      notification.render(
        <CollectionNotification
          name={name}
          verb="Created"
          isDone={true}
          collectionUrl={lookups.getCollectionUrlById(collectionId)}
          onAction={() => {
            notification.close()
          }}
        />
      )
    } catch (err) {
      // errors handled individually above
      notification.close()
    } finally {
      setIsOperating(false)
      notification.release()
    }
  }

  const handleAddToCollection = async ({ collectionId }: { collectionId: string }) => {
    const notification = create({ persist: true })

    try {
      setIsOperating(true)
      const projectIds: string[] = []

      let cancel = false

      const name = await lookups.getCollectionOptionById(collectionId).then((option) => option?.text)
      if (!name) {
        catchError('Failed to find collection', true)('msg')
        return
      }

      notification.render(
        <CollectionNotification
          name={name}
          verb="Adding to"
          isDone={false}
          onAction={() => {
            notification.close()
            notification.release()
            cancel = true
          }}
        />
      )

      // fetch projects
      try {
        let total = Infinity
        let offset = 0
        const limit = FETCH_PROJECT_IDS_LIMIT

        while (projectIds.length < total && !cancel) {
          const page = await getProjectSearchResultsIds({
            variables: {
              limit,
              offset,
              params: projectSearchParamsInput,
              sort: sort ? [sort] : undefined,
            },
          })
          projectIds.push(...page.searchProjects.results.map(extractItem).map(extractId))
          total = page.searchProjects.total
          offset += limit
        }
      } catch (err) {
        catchError('Failed to determine projects for collection', true)(err)
      }

      if (cancel) throw new UserCancel()

      notification.render(<CollectionNotification name={name} verb="Adding to" isDone={false} />)

      // populate collection
      try {
        await addCollectionProjects({
          variables: {
            collectionId,
            projectIds,
          },
        })
      } catch (err) {
        catchError('Failed to add to collection', true)(err)
      }

      notification.render(
        <CollectionNotification
          name={name}
          verb="Added to"
          isDone={true}
          collectionUrl={lookups.getCollectionUrlById(collectionId)}
          onAction={() => {
            notification.close()
          }}
        />
      )
    } catch (err) {
      // errors handled individually above
      notification.close()
    } finally {
      setIsOperating(false)
      notification.release()
    }
  }

  const handleViewStateChange = (newState: ViewState) => {
    gotoSelfMerged({
      geo: {
        lng: newState.longitude,
        lat: newState.latitude,
        zoom: newState.zoom,
      },
    })
  }

  const [createSavedSearch] = useCreateSavedSearchMutation()
  const handleCreateSavedSearch = ({ orgId, ...rest }: { orgId?: string } & Partial<SavedSearchInput>) => {
    createSavedSearch({
      variables: {
        savedSearch: {
          ...rest,
          searchParams: apiParams, // TODO: should this be apiParams or projectSearchParamsInput?
          defaultSort: sort ? [sort] : undefined,
        },
        orgId,
      },
    })
      .then(({ data }) => {
        if (!data?.createSavedSearch) throw new Error()
        gotoSavedSearch({ id: data.createSavedSearch.id })
      })
      .catch(catchError('Failed to create saved search', false))
  }

  // CONTROL OVERRIDES (visible when showDevTools is true)

  const [overrides, setOverrides] = useFields({
    precision: 0,
    skipQueries: false,
    suppressClientCluster: false,
    mode: 'auto' as 'auto' | 'clusters' | 'bounds',
    clusterRadius: 0,
    showClustersGrid: false,
    showClustersBbox: false,
    showBoundsBbox: false,
  })

  // PROJECTS MAP STATE

  const mapController = useInteractiveMapController({
    initialViewState: {
      latitude: geo?.lat,
      longitude: geo?.lng,
      zoom: geo?.zoom,
    },
    onViewStateChange: handleViewStateChange,
    onBboxChange: setBbox,
  })

  const mapBoxRef = useRef<HTMLDivElement>(null)

  const [hoveredBoundsIds, setHoveredBoundsIds] = useState<string[]>([])

  const getProjectBoundsThumbProps = useCallback(
    (bounds: { id: string; polygonUrl: string; bbox: Bbox }): Partial<ProjectBoundsThumbProps> => ({
      onAddClick: params.boundsId
        ? undefined
        : () => {
            gotoSelfMerged({
              params: {
                ...params,
                bounded: false,
                boundsId: bounds.id,
              },
            })
            // mapController.fitBboxes([bounds.bbox]) // disabled zoom for now
          },
      onZoomClick: () => mapController.fitBboxes([bounds.bbox]),
      onHoverChange: (newHover: boolean) => {
        setHoveredBoundsIds(newHover ? [bounds.id] : [])
      },
      highlight: hoveredBoundsIds.includes(bounds.id),
      visibilityBbox: bbox,
    }),
    [hoveredBoundsIds, params, bbox]
  )

  const {
    info,
    handleMapClick,
    handleMapHover,
    mapElements,
    popupState: { ids: popupBoundsIds },
  } = useProjectsMap({
    params: projectSearchParamsInput,
    overrides,
    mapBoxRef,
    lookups,
    bbox,
    mapController,
    getProjectBoundsThumbProps,
    highlightBoundsIds: hoveredBoundsIds,
  })

  useEffect(() => {
    setHoveredBoundsIds(popupBoundsIds)
  }, [popupBoundsIds])

  const hasCollection = type === 'Project' && !!params.collection
  const collectionId: string | undefined = hasCollection
    ? ((params as ProjectSearchSpecParams).collection as string)
    : undefined

  const { data: collectionData } = useGetCollectionDetailQuery({
    variables: { id: collectionId as string },
    skip: !collectionId,
  })

  const [removeCollectionProjects] = useRemoveCollectionProjectsMutation()
  const handleRemoveProjectByCollectionId = (projectId: string) => {
    if (!collectionId) return
    removeCollectionProjects({
      variables: { collectionId: collectionId, projectIds: [projectId] },
    }).catch(catchError('Failed to remove project from collection', false))
  }
  const onProjectRemoveClick = collectionData?.collection
    ? collectionData?.collection.permissions.update
      ? handleRemoveProjectByCollectionId
      : null
    : undefined

  // LOOKUPS

  const { data: profileOrganizationsData, fetchMore: fetchMoreProfileOrganizations } = useGetProfileOrganizationsQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get organizations', false),
  })

  const profileOrganizations = useFetchAllWithPendingItems(
    fetchMoreProfileOrganizations,
    profileOrganizationsData?.profile?.organizations.items,
    profile?.organizations.total,
    1
  )

  const organizationOptions = useMemo(
    () =>
      profileOrganizations.map(itemToSelectOption).filter((item) => {
        return (
          item.data.myRole === OrganizationRoleEnum.Owner ||
          item.data.myRole === OrganizationRoleEnum.Admin ||
          item.data.myRole === OrganizationRoleEnum.Contributor
        )
      }),
    [profileOrganizations]
  )

  // DATA

  // projects (paged)

  const {
    data: projectsData,
    fetchMore,
    loading: searchLoading,
  } = useGetProjectSearchResultsQuery({
    variables: {
      limit: INFINISCROLLER_PAGE_SIZE,
      offset: 0,
      sort: sort && [sort],
      params: projectSearchParamsInput,
    },
    notifyOnNetworkStatusChange: true,
    skip: bounded && !bbox,
    onError: catchError('Failed to get project search results', false),
  })
  // Fetch the next page of projects
  const theoreticalTotal = projectsData?.searchProjects?.total ?? 0
  const projectsHasNext = Boolean(theoreticalTotal > projectPaginationOffset)
  const fetchNextPage = () => {
    if (projectsHasNext && !searchLoading) {
      fetchMore({
        variables: {
          offset: projectPaginationOffset,
        },
      }).then(() => {
        setProjectPaginationOffset((prevOffset) => {
          return prevOffset + INFINISCROLLER_PAGE_SIZE > theoreticalTotal
            ? theoreticalTotal
            : prevOffset + INFINISCROLLER_PAGE_SIZE
        })
      })
    }
  }

  const projects = projectsData?.searchProjects.results.map(extractItem)

  const resultsCount = projectsData?.searchProjects.total || 0
  const displayItems = projects ?? pendingItems(10)

  // MAP

  // track map to routing
  useEffect(() => {
    if (!geo) return
    const { viewState } = mapController
    const potentialViewState = { ...viewState, longitude: geo.lng, latitude: geo.lat, zoom: geo.zoom }
    if (isEqual(viewState, potentialViewState)) return
    mapController.setViewState(potentialViewState)
  }, [geo?.lng, geo?.lat, geo?.zoom])

  // fly to new search results
  const [isFirstResults, setIsFirstResults] = useState(true)
  useEffect(() => {
    if (!projectsData) return // no data
    if (isFirstResults) {
      // do not fly on first results so that we use the map geo (could be returning to page)
      setIsFirstResults(false)
      return
    }
    if (bounded) return // results are bounded, do not move
    const { stats } = projectsData.searchProjects
    if (!stats || !isProjectSearchMeta(stats) || !stats.bbox) return
    // const newBbox = stats.bbox as Bbox
    // setBbox(newBbox) // set early to get new search underway; will change again after map settles post-zoom
    // mapController.fitBboxes([newBbox], { duration: 2000 }) // fly to bbox (slower than other fits)
  }, [projectsData])

  // RENDER
  const canAddToCollection = resultsCount && resultsCount > 0 && resultsCount <= ADD_TO_COLLECTION_LIMIT

  return (
    <>
      <PageTitle title="Projects Search" />
      <Stack sx={{ height: '100%', gap: 1 }}>
        {showDevTools && (
          <Stack>
            <Stack direction="row" gap={1}>
              <div>
                <strong>Map</strong>
              </div>
              <div>Zoom: {mapController.viewState.zoom}</div>
              <div>BBox: {JSON.stringify(bbox)}</div>
              <div>
                Size:{' '}
                {(() => {
                  if (!mapBoxRef.current) return null
                  const { width, height } = mapBoxRef.current.getBoundingClientRect()
                  return `${width}, ${height}`
                })()}
              </div>
            </Stack>
            <Stack direction="row" gap={1}>
              <div>
                <strong>Controls</strong>
              </div>
              <div>
                Pause Queries:{' '}
                <input
                  type="checkbox"
                  value={overrides.skipQueries ? 'checked' : undefined}
                  onChange={(e) => setOverrides.$.skipQueries(e.target.checked)}
                />
              </div>
              <div>
                Mode:{' '}
                <select
                  value={overrides.mode}
                  onChange={(e) => setOverrides.$.mode(e.target.value as 'auto' | 'bounds' | 'clusters')}
                >
                  <option value="auto">Auto</option>
                  <option value="clusters">Clusters</option>
                  <option value="bounds">Bounds</option>
                </select>
              </div>
              <div>
                Suppress Client Cluster:{' '}
                <input
                  type="checkbox"
                  value={overrides.suppressClientCluster ? 'checked' : undefined}
                  onChange={(e) => setOverrides.$.suppressClientCluster(e.target.checked)}
                />
              </div>
              <div>
                Cluster Radius: {info.clusters.radius}{' '}
                <input
                  type="number"
                  step={20}
                  value={overrides.clusterRadius}
                  onChange={(e) => setOverrides.$.clusterRadius(Number(e.target.value))}
                  style={{ width: '5em' }}
                />
              </div>
            </Stack>
            <Stack direction="row" gap={1}>
              <div>
                <strong>Clusters</strong>
              </div>
              <Box sx={{ opacity: info.isBoundsMode ? 0 : 1 }}>⬅</Box>
              <Box sx={{ width: '1em' }}>{info.clusters.loading && <CircularProgress size={16} />}</Box>
              <div>
                Precision: {info.clusters.precision}{' '}
                <input
                  type="number"
                  value={overrides.precision}
                  onChange={(e) => setOverrides.$.precision(Number(e.target.value))}
                  style={{ width: '4em' }}
                />
              </div>
              <div>
                Count: {info.clusters.count} (possible: {info.clusters.possibleCount})
              </div>
              <div>Remaining: {info.clusters.remaining}</div>
              <div>Projects: {info.clusters.projects}</div>
              <div>
                Grid:{' '}
                <input
                  type="checkbox"
                  value={overrides.showClustersGrid ? 'checked' : undefined}
                  onChange={(e) => setOverrides.$.showClustersGrid(e.target.checked)}
                />
                {overrides.showClustersBbox && JSON.stringify(info.clusters.bbox)}
              </div>
              <div>
                BBox:{' '}
                <input
                  type="checkbox"
                  value={overrides.showClustersBbox ? 'checked' : undefined}
                  onChange={(e) => setOverrides.$.showClustersBbox(e.target.checked)}
                />
                {overrides.showClustersBbox && JSON.stringify(info.clusters.bbox)}
              </div>
            </Stack>
            <Stack direction="row" gap={1}>
              <div>
                <strong>Bounds</strong>
              </div>
              <Box sx={{ opacity: info.isBoundsMode ? 1 : 0 }}>⬅</Box>
              <Box sx={{ width: '1em' }}>{info.bounds.loading && <CircularProgress size={16} />}</Box>
              <div>Count: {info.bounds.count}</div>
              <div>Remaining: {info.bounds.remaining}</div>
              <div>
                BBox:{' '}
                <input
                  type="checkbox"
                  value={overrides.showBoundsBbox ? 'checked' : undefined}
                  onChange={(e) => setOverrides.$.showBoundsBbox(e.target.checked)}
                />
                {overrides.showBoundsBbox && JSON.stringify(info.bounds.bbox)}
              </div>
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          sx={{
            p: 2,
            borderBottom: 1,
            gap: 2,
            borderColor: 'divider',
          }}
        >
          <Stack direction="column" sx={{ flex: 1 }} gap={1}>
            <SearchSpecExplainer
              lookups={lookups}
              collection={collectionData?.collection}
              loading={searchLoading}
              onSearchChange={handleAdjustedSearch}
              onOpenModify={openModify}
              params={params}
              resultsCount={resultsCount}
              type="Project"
            />
          </Stack>
          <Box>
            <Menu
              disabled={!projectsData}
              Button={(buttonProps) => (
                <IconButton {...buttonProps}>
                  <MoreMenuIcon />
                </IconButton>
              )}
              options={[
                {
                  key: 'openCreateCollection',
                  label: (
                    <>
                      {canAddToCollection ? (
                        <AddCircle color="primary" sx={{ mr: 1 }} />
                      ) : (
                        <Warning color="error" sx={{ mr: 1 }} />
                      )}{' '}
                      {canAddToCollection
                        ? `New collection from these ${resultsCount || ''} search results`
                        : `Cannot create collection with > ${ADD_TO_COLLECTION_LIMIT} projects`}
                    </>
                  ),
                  onClick: openCreateCollection,
                  disabled: !resultsCount || isOperating || !canAddToCollection,
                },
                {
                  key: 'openAddToCollection',
                  label: (
                    <>
                      {canAddToCollection ? (
                        <AddToPhotos color="secondary" sx={{ mr: 1 }} />
                      ) : (
                        <Warning color="error" sx={{ mr: 1 }} />
                      )}{' '}
                      {canAddToCollection
                        ? `Add these ${resultsCount || ''} search results to an existing collection`
                        : `Cannot add > ${ADD_TO_COLLECTION_LIMIT} projects to an existing collection`}
                    </>
                  ),
                  onClick: openAddToCollection,
                  disabled: !resultsCount || isOperating || !canAddToCollection,
                },
                // // TODO: enable once Saved Searches finished
                // {
                //   key: 'openCreateSavedSearch',
                //   onClick: openCreateSavedSearch,
                //   label: 'Save this search',
                //   disabled: isExploreMode,
                // },
              ]}
            />
          </Box>
        </Stack>
        <Box sx={{ flex: 1, height: 0 }}>
          <ProjectResults
            NoResults={() => (
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '2em',
                }}
              >
                There were no results for your search. Try{' '}
                <Link onClick={openModify} sx={{ cursor: 'pointer' }}>
                  modifying your search.
                </Link>
              </Box>
            )}
            onProjectRemoveClick={onProjectRemoveClick}
            onFetchMoreProjects={fetchNextPage}
            loadingHasMore={projectsHasNext}
            loadingProjects={searchLoading}
            onGotoWebRave={(id: string) => gotoWebRave({ id })}
            getProjectBoundsThumbProps={getProjectBoundsThumbProps}
            items={displayItems}
            pageOffset={projectPaginationOffset > theoreticalTotal ? theoreticalTotal : projectPaginationOffset}
            lookups={lookups}
            mapBoxRef={mapBoxRef}
            mapController={mapController}
            onMapClick={handleMapClick}
            onMapHover={handleMapHover}
            onSortChange={handleSortChange}
            sort={sort}
            viewType={viewType}
            onViewTypeChange={onViewTypeChange}
            type="Project"
          >
            {mapElements}
          </ProjectResults>
        </Box>
      </Stack>
      <ModifySearchDialog
        lookups={lookups}
        onClose={closeModify}
        onSearch={handleNewSearch}
        open={isModifyOpen}
        params={params}
        type={type}
      />
      <CreateCollectionDialog
        onClose={closeCreateCollection}
        onConfirm={handleCreateCollection}
        open={isCreateCollectionOpen}
        organizationOptions={organizationOptions}
      />
      <AddToCollectionFromResultsDialog
        onClose={closeAddToCollection}
        onConfirm={handleAddToCollection}
        open={isAddToCollectionOpen}
        lookups={lookups}
      />
      <CreateSavedSearchDialog
        onClose={closeCreateSavedSearch}
        onConfirm={handleCreateSavedSearch}
        open={isCreateSavedSearchOpen}
        organizationOptions={organizationOptions}
      />
      {collectionData?.collection && (
        <RemoveProjectDialog
          collectionName={collectionData?.collection.name}
          open={Boolean(removeProjectId)}
          onClose={() => setRemoveProjectId(undefined)}
          onConfirm={() => handleRemoveProjectByCollectionId(removeProjectId as string)}
        />
      )}
    </>
  )
}
