import xpath from 'xpath'
import { MetaDataExtEnum, MetaDataInput, MetaDataTypeEnum } from '../gen/schema.types'
import log from 'loglevel'

/**
 * Extract Metadata from the XML node and return it in our database GQL-friendly form
 * @param nodeWithMeta
 * @returns
 */
export const getMetaFromNode = (nodeWithMeta: Node): MetaDataInput[] => {
  try {
    const metaNodes = xpath.select('MetaData/Meta', nodeWithMeta)
    const keyCheck: string[] = []
    const metaInputs: MetaDataInput[] = metaNodes.map((nd) => {
      const rawType = (nd as Element).getAttribute('type')
      const rawLocked = (nd as Element).getAttribute('locked')
      const rawExt = (nd as Element).getAttribute('ext')
      let typeVal
      if (rawType && Object.values(MetaDataTypeEnum).includes(rawType.trim() as MetaDataTypeEnum))
        throw new Error(`MetaDataType not found: ${rawType}`)
      else if (rawType) typeVal = rawType.trim().toUpperCase() as MetaDataTypeEnum

      let lockedVal = false
      if (rawLocked && rawLocked.trim().toLowerCase() !== 'true' && rawLocked.trim().toLowerCase() !== 'false')
        throw new Error(`locked is not either 'true' or 'false' got: "${rawLocked}"`)
      else if (rawLocked && rawLocked.trim().toLowerCase() === 'true') lockedVal = true

      let extVal: MetaDataExtEnum
      if (rawExt && rawExt.trim().length > 0) {
        const lwrExt = rawExt.trim().toUpperCase()
        if (lwrExt && Object.values(MetaDataExtEnum).includes(rawExt.trim() as MetaDataExtEnum))
          throw new Error(`MetaDataExtEnum not found: ${rawExt}`)
        else if (rawExt) extVal = rawExt.trim().toUpperCase() as MetaDataExtEnum
      }
      const key = (nd as Element).getAttribute('name')
      if (!key) throw new Error('Error retriving name from XML node')
      if (keyCheck.includes(key)) throw new Error(`Duplicate key found: ${key}`)
      keyCheck.push(key)

      return {
        key,
        type: typeVal,
        value: (nd as Element).textContent.trim(),
        locked: lockedVal,
        ext: extVal,
      }
    })
    return metaInputs
  } catch (e) {
    if (e.message.includes('Duplicate key found')) throw e
    if (e.message.includes('Error retriving name')) throw e

    // If we can't find the MetaData element then log and fail silently
    log.warn('Could not find MetaData element in XML')
    return []
  }
}

/**
 * Harvest the text from an XML Node
 * @param tagName
 * @param parentNode
 * @returns
 */
export const getChildNodeText = (tagName: string, parentNode: Node): string | null => {
  const tagNode = xpath.select1(tagName, parentNode)
  if (!tagNode) return null
  return (tagNode as Node).textContent.trim()
}

export const getNodeText = (parentNode: Node): string | null => {
  return (parentNode as Node).textContent.trim()
}

/**
 * Harvest id from an XML Node
 * @param tagName
 * @param parentNode
 * @returns
 */
export const getChildNodeAttr = (node: Element, attrName: string, allowNull = true): string | null => {
  const id = node.getAttribute(attrName)
  if (!id && !allowNull) throw new Error('Error retriving id from XML node')
  return id.length > 0 ? id : null
}

/**
 *
 * @param node
 * @returns
 */
export const getNodeAttrDict = (node: Element): Record<string, string> => {
  return Array.from(node.attributes).reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})
}
