import { GetCollectionDetailQuery, GetProjectDetailQuery } from '../../schema/base'

export interface DefaultProjectCitationProps {
  project: NonNullable<GetProjectDetailQuery['project']>
  getProjectUrlById: (id: string) => string
  getProjectTypeUrlById: (id: string) => string
  getUserUrlById: (id: string) => string
}

export const useDefaultProjectCitation = ({
  project,
  getProjectUrlById,
  getProjectTypeUrlById,
  getUserUrlById,
}: DefaultProjectCitationProps): { text: string; markdown: string } => {
  const projectCreationDate = new Date(project.createdOn)
  const projectYear = projectCreationDate.getFullYear()

  const projectCreator = project.createdBy

  const projectUrl = new URL(getProjectUrlById(project.id), window.location.origin).toString()
  const projectTypeUrl = new URL(getProjectTypeUrlById(project.projectType.id), window.location.origin).toString()
  const userUrl = new URL(getUserUrlById(projectCreator.id), window.location.origin).toString()

  return {
    markdown: [
      `[${projectCreator.name}](${userUrl})`,
      `(${projectYear})`,
      `[${project.name}](${projectUrl})`,
      `A Riverscapes Project data set of type: [${project.projectType.name}](${projectTypeUrl})`,
      `Owned by: [${project.ownedBy.name}](${userUrl})`,
      `Available from: [${projectUrl}](${projectUrl})`,
    ].join('. '),
    text: [
      projectCreator.name,
      `(${projectYear})`,
      project.name,
      `A Riverscapes Project data set of type: ${project.projectType.name}`,
      `Owned by: ${project.createdBy.name}`,
      `Available from: ${projectUrl}`,
    ].join('. '),
  }
}

export interface DefaultCollectionCitationProps {
  collection: NonNullable<GetCollectionDetailQuery['collection']>
  getCollectionUrlById: (id: string) => string
  getUserUrlById: (id: string) => string
}

export const useDefaultCollectionCitation = ({
  collection,
  getCollectionUrlById,
  getUserUrlById,
}: DefaultCollectionCitationProps): { text: string; markdown: string } => {
  const projectCreationDate = new Date(collection.createdOn)
  const projectYear = projectCreationDate.getFullYear()

  const projectCreator = collection.createdBy

  const collectionUrl = new URL(getCollectionUrlById(collection.id), window.location.origin).toString()
  const userUrl = new URL(getUserUrlById(projectCreator.id), window.location.origin).toString()

  return {
    markdown: [
      `[${projectCreator.name}](${userUrl})`,
      `(${projectYear})`,
      `[${collection.name}](${collectionUrl})`,
      `A Riverscapes Collection of Projects`,
      `Owned by: [${collection.ownedBy.name}](${userUrl})`,
      `Available from: [${collectionUrl}](${collectionUrl})`,
    ].join('. '),
    text: [
      projectCreator.name,
      `(${projectYear})`,
      collection.name,
      `A Riverscapes Collection of Projects`,
      `Owned by: ${collection.createdBy.name}`,
      `Available from: ${collectionUrl}`,
    ].join('. '),
  }
}
