import { noop } from 'lodash'
import { useRef } from 'react'

type DeconstructedPromise<T> = [promise: Promise<T>, resolver: (value: T) => void, rejecter: (reason: any) => void]

export const useDeconstructedPromise = <T>(): DeconstructedPromise<T> => {
  return useRef(
    (() => {
      let resolver: (value: T) => void = noop // immediately assigned below
      let rejecter: (reason: any) => void = noop // immediately assigned below
      const promise = new Promise<T>((resolve, reject) => {
        resolver = resolve
        rejecter = reject
      })
      return [promise, resolver, rejecter] as DeconstructedPromise<T>
    })()
  ).current
}
