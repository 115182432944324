import { DatasetTypeEnum } from './gen/schema.types'

// The warehouse uses directory prefixes to know where to put/fetch things on S3
type ObjectValues<T> = T[keyof T]

// This is a string we can use in our development environment to
// Signal that we are in development mode. We use it in the SQS queue url
// to prevent Keepalive
export const DEV_STR = '__DEV__'

export const DirPrefixesEnum = {
  IMAGE: 'images',
  BOUNDS: 'geojsonBounds',
  PROJECT: 'projects',
  XMLBACKUP: 'xmlBackup',
  TILE_LOGS: 'tileMeta',
  TILES: 'tiles',
} as const
export type DirPrefixesEnum = ObjectValues<typeof DirPrefixesEnum>

export const getProjectDirKey = (projectId: string): string => [DirPrefixesEnum.PROJECT, projectId].join('/')
export const getProjectXMLKey = (projectId: string): string =>
  [DirPrefixesEnum.PROJECT, projectId, PROJECT_FILE].join('/')
export const getDatasetKey = (s3Path: string): string => [DirPrefixesEnum.PROJECT, s3Path].join('/')

export const getProjectXMLBackupPrefix = (projectId: string): string => [DirPrefixesEnum.XMLBACKUP, projectId].join('/')

export const getProjectXMLBackupKey = (projectId: string, date: string): string =>
  [getProjectXMLBackupPrefix(projectId), `${date}.xml`].join('/')

// Tiles themselves are stored in a directory structure that looks like this:
export const getProjectTilesKey = (projectId: string, projectType: string): string =>
  [DirPrefixesEnum.TILES, projectType.toLowerCase(), projectId].join('/')

export const getDatasetTilesKey = (projectId: string, projectType: string, rsXPath: string): string =>
  [getProjectTilesKey(projectId, projectType), rsXPath.replace(/#/g, '_')].join('/')

// THe tile meta data is stored in a directory structure that looks like this:
// This is so we keep the metadata away from the logs
export const getProjectTileMetaKey = (projectId: string, projectType: string): string =>
  [DirPrefixesEnum.TILE_LOGS, projectType.toLowerCase(), projectId].join('/')

export const getDatasetTileMetaKey = (projectId: string, projectType: string, rsXPath: string): string =>
  [getProjectTileMetaKey(projectId, projectType), rsXPath.replace(/#/g, '_')].join('/')

export const getDatasetTilesIndexKey = (projectId: string, projectType: string, rsXPath: string): string =>
  [getDatasetTileMetaKey(projectId, projectType, rsXPath.replace(/#/g, '_')), UPLOADS.TILE_INDEX_JSON].join('/')

export const keyToLocalPath = (key: string): string => key.split('/').slice(2).join('/')

export const getZipKey = (projectId: string): string => `${DirPrefixesEnum.PROJECT}/${projectId}.zip`
export const getZipPendingKey = (projectId: string): string => `${DirPrefixesEnum.PROJECT}/${projectId}.zip.pending`

export const DirPrefixesImagesEnum = {
  HERO: 'hero',
  LOGO: 'logo',
  AVATAR: 'avatar',
} as const
export type DirPrefixesImagesEnum = ObjectValues<typeof DirPrefixesImagesEnum>

/**
 * Right now there are limits to how many records ElasticSearch can return
 * TODO: Fix this
 * When users try to get CollectionProjectIds that are bigger than this they get the error:
 *   Collections larger than ${LargestSearchableCollection} cannot be searched this way yet. We hope to fix this soon
 */
export const LargestSearchableCollection = 20000

/**
 * List of valid sidecar files we need to account for on Geospatial datasets.
 * There may be more so this list may need to evolve
 * NOTE: These are RegEx fragments that we combine
 */
export const DasetSidecarFiles = {
  // https://desktop.arcgis.com/en/arcmap/latest/manage-data/shapefiles/shapefile-file-extensions.htm
  shp: ['shx', 'dbf', 'sbn', 'sbx', 'fbn', 'fbx', 'ain', 'aih', 'atx', 'ixs', 'mxs', 'prj', 'xml', 'cpg'],
  tiff: ['aux.xml', 'tiff\\..*', 'tfw'],
  tif: ['aux.xml', 'tif\\..*', 'tfw'],
  img: ['img.*'],
}

export const PROJECT_FILE = 'project.rs.xml'

// Various Regexes we use all over the place
export const VERIFY_STRINGS: Record<string, RegExp> = {
  // email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
  extDatasetRefs: /[0-9a-zA-Z-]{36,}:Project\/[A-Za-z0-9_/#-]+/,
}

export const TileableDatasetTypes: DatasetTypeEnum[] = [
  DatasetTypeEnum.Dem,
  DatasetTypeEnum.Geopackage,
  DatasetTypeEnum.HillShade,
  DatasetTypeEnum.Raster,
  DatasetTypeEnum.HtmlFile,
  DatasetTypeEnum.Vector,
]

export const ZIP_PENDING_EXPIRY = 600 // in seconds. default to the maximum time of a lambda function

export const UPLOADS = {
  MIN_BOUNDS_SIZE: 2, // anything less than 2bytes is not a valid file
  MAX_BOUNDS_SIZE: 204800, // 200Kb max for geojson bounds
  MAX_IMAGE_SIZE: 100000, //10Mb max for uploaded images
  TRIGGER_FILE: 'RAW', // this is the file the lambda function will use to trigger the upload processing (projects, bounds, images etc.)
  TILE_INDEX_JSON: 'index.json',
  // When uploading we create a file to list those files that need to be cleaned up remotely.
  FILE_INDEX_JSON: 'index.json',
  FILE_INDEX_DELETIONS: '.deletions',
  FILE_INDEX_ALTERATIONS: '.alter', // just create and updates
  FILE_INDEX_ALL: '.all', // Mostly for debugging. Every file and op
  // In order to calculate etags correctly we need the multipart file sizes to be exactly the same
  MULTIPART_CHUNK_SIZE: 50 * Math.pow(1024, 2),
  // On the S3 client the threshold can be different than the chnk size.
  MULTIPART_THRESHOLD: 50 * Math.pow(1024, 2),
}
