import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  projectHiddenNodesSelector,
  treeAllIdsSelector,
  treeContextMenuAtom,
  treeSelectedIdsAtom,
  treeExpandedIdsAtom,
  treeFilterTextAtom,
  treeBranchStateSelectorFamily,
  treeLeafStateSelector,
  treeInfoPaneIdAtom,
  treeInfoPaneOpenAtom,
  treeRootBranchId,
  projectAtom,
  symbologyToolAtom,
  SymbologyKey,
} from '../recoil'
import useMapLayers from '../hooks/useMapLayers'
import TreeLeaf from '../components/tree/TreeLeaf'
import TreeBranch from '../components/tree/TreeBranch'
import TreeControl from '../components/tree/TreeControl'
import { isEqual } from 'lodash'

import TreeContextMenuConnect from './TreeContextMenu.connect'
import TreeLayerInfoConnect from './TreeLayerInfo.connect'
import log from 'loglevel'
import { ProjectTreeLayerTypeEnum, useDownload } from '@riverscapes/react-common'
import { Box } from '@mui/material'
import SymbologyToolConnect from './SymbologyTool.connect'

interface TreeBranchProps {
  downloadFile: (fkey: string) => Promise<void>
  branchId: number
  depth: number
}

const TreeBranchConnector: React.FC<TreeBranchProps> = ({ branchId, depth, downloadFile }: TreeBranchProps) => {
  const branchState = useRecoilValue(treeBranchStateSelectorFamily(branchId))
  const filterText = useRecoilValue(treeFilterTextAtom)
  if (!branchState || !branchState.branch) return null

  const branches = branchState?.branch.children.branches
    .map((bid, idx) => (
      <TreeBranchConnector
        key={`${branchState?.branch.branch.label}-${bid}-${idx}`}
        downloadFile={downloadFile}
        branchId={bid}
        depth={depth + 1}
      />
    ))
    .filter((d) => d)

  const leaves = branchState?.branch.children.leaves
    .map((lid, idx) => (
      <TreeLeafConnector
        key={`${branchState?.branch.branch.label}-${lid}-${idx}`}
        downloadFile={downloadFile}
        leafId={lid}
        depth={depth + 1}
      />
    ))
    .filter((d) => d)

  // Clean up any dead ends with no leaves
  if (leaves.length === 0 && branches.length === 0) return null

  return (
    <TreeBranch
      id={branchId}
      label={branchState?.branch.branch.label}
      hidden={branchState?.hidden}
      searchText={filterText}
      isRoot={depth === 0}
    >
      {[...branches, ...leaves]}
    </TreeBranch>
  )
}

interface TreeLeafProps {
  downloadFile: (fkey: string) => Promise<void>
  leafId: number
  depth: number
}

const TreeLeafConnector: React.FC<TreeLeafProps> = ({ leafId, downloadFile }: TreeLeafProps) => {
  const treeLeafState = useRecoilValue(treeLeafStateSelector(leafId))
  const filterText = useRecoilValue(treeFilterTextAtom)
  const setCtxState = useSetRecoilState(treeContextMenuAtom)
  const [infoPanelId, setInfoPaneId] = useRecoilState(treeInfoPaneIdAtom)
  const [symbologyTool, setSymbologyTool] = useRecoilState(symbologyToolAtom)
  const { toggleLayer } = useMapLayers()

  const handleContext = (leafId: number, left: number, top: number) => {
    setCtxState({ leafId, position: { left, top } })
  }

  const doubleClick = () => {
    if (treeLeafState.leaf.layerType === ProjectTreeLayerTypeEnum.Report && treeLeafState.renderable) {
      const newWindow = window.open(treeLeafState.reportUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else if (treeLeafState.leaf.layerType === ProjectTreeLayerTypeEnum.File) {
      if (treeLeafState.leaf.filePath) downloadFile(treeLeafState.leaf.filePath)
    } else if (treeLeafState.renderable) toggleLayer(treeLeafState.leaf.id)
  }

  if (!treeLeafState || !treeLeafState.leaf || !treeLeafState.leaf.rsXPath) return null
  const { id, layerType, label } = treeLeafState.leaf
  return (
    <TreeLeaf
      id={id}
      layerType={layerType}
      label={label}
      active={treeLeafState.active}
      hidden={treeLeafState.hidden}
      tilesState={treeLeafState.tilesState}
      searchText={filterText}
      handleClick={(id) => {
        infoPanelId !== null && setInfoPaneId(id)
      }}
      handleDoubleClick={doubleClick}
      handleContext={handleContext}
    />
  )
}

const TreeControlConnect: React.FC = () => {
  const project = useRecoilValue(projectAtom)
  const setFilterText = useSetRecoilState(treeFilterTextAtom)
  const rootBranchId = useRecoilValue(treeRootBranchId)
  const [expandedIds, setExpandedIds] = useRecoilState(treeExpandedIdsAtom)
  const [selectedIds, setSelectedIds] = useRecoilState(treeSelectedIdsAtom)
  const [ctxState, setCtxState] = useRecoilState(treeContextMenuAtom)
  const infoPaneId = useRecoilValue(treeInfoPaneIdAtom)
  const symbologyTool = useRecoilValue(symbologyToolAtom)
  const allIds = useRecoilValue(treeAllIdsSelector)
  const { downloadFileFn } = useDownload()

  const hiddenNodes = useRecoilValue(projectHiddenNodesSelector)
  const [infoPaneOpen, setInfoPaneOpen] = useRecoilState(treeInfoPaneOpenAtom)

  const downloadFile = (fkey: string) => {
    if (!project?.id) {
      log.error('No project id found')
      return Promise.resolve()
    }
    return downloadFileFn(project?.id as string, fkey)
  }

  const setSelected = (ids) => {
    if (!isEqual(ids, selectedIds)) setSelectedIds(ids)
  }

  // If there's a search query on then everything is expanded
  const realExpanded = hiddenNodes.branches.length > 0 ? allIds : expandedIds
  const realSetExpanded =
    hiddenNodes.branches.length > 0
      ? () => {
          log.debug('collapsing turned off')
        }
      : setExpandedIds

  const lyrInfo = infoPaneOpen ? (
    infoPaneId !== null ? (
      <TreeLayerInfoConnect leafID={infoPaneId} handleClose={() => setInfoPaneOpen(false)} />
    ) : symbologyTool !== null ? (
      <SymbologyToolConnect symbologyKey={symbologyTool} handleClose={() => setInfoPaneOpen(false)} />
    ) : null
  ) : null

  const treeContrextMenu = ctxState && (
    <TreeContextMenuConnect ctxState={ctxState} onClose={() => setCtxState(null)} downloadFile={downloadFile} />
  )
  const rootBranch =
    rootBranchId !== null ? (
      <TreeBranchConnector branchId={rootBranchId} depth={0} downloadFile={downloadFile} />
    ) : (
      <Box />
    )

  return (
    <TreeControl
      lyrInfoOpen={infoPaneOpen}
      setSearchText={setFilterText}
      expanded={realExpanded}
      ctxMenu={treeContrextMenu}
      lyrInfo={lyrInfo}
      selected={selectedIds}
      setExpanded={realSetExpanded}
      setSelected={setSelected}
    >
      {rootBranch}
    </TreeControl>
  )
}

export default TreeControlConnect
