import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  useTheme,
  Box,
} from '@mui/material'
import { AutocompleteSelectField, AutocompleteSelectOption } from '../AutocompleteSelectField'
import { someValues, useFields } from '../../lib'
import { GetOrganizationDetailQuery, ProjectGroupVisibilityEnum } from '../../schema/base'
import { VisibilityField } from '../Visibility'

export interface CreateCollectionDialogProps {
  onConfirm: (properties: { name: string; orgId?: string }) => void
  onClose: () => void
  open: boolean
  organizationOptions?: AutocompleteSelectOption[]
  organization?: NonNullable<GetOrganizationDetailQuery['organization']>
  onlyMe?: boolean
}

export const CreateCollectionDialog: React.FC<CreateCollectionDialogProps> = ({
  onConfirm,
  onClose,
  open,
  organizationOptions,
  organization,
  onlyMe,
}) => {
  if ([organization, organizationOptions, onlyMe].filter(Boolean).length !== 1)
    throw new Error('Must provide exactly one of organization, organizationOptions, or onlyMe')
  const theme = useTheme()
  const [fields, setFields, preparedFields] = useFields(
    {
      name: '',
      belongsTo: organization ? 'org' : 'me',
      orgId: organization ? organization.id : (null as string | null),
      visibility: ProjectGroupVisibilityEnum.Public as ProjectGroupVisibilityEnum,
    },
    ({ name, orgId, belongsTo, visibility }) => ({
      name: name.trim(),
      orgId: belongsTo === 'org' && orgId ? orgId : undefined,
      visibility,
    }),
    [open]
  )

  const errors = someValues({
    name: !preparedFields.name,
    orgId: fields.belongsTo === 'org' && !preparedFields.orgId,
  })

  const handleConfirm = () => {
    onConfirm(preparedFields)
    onClose()
  }

  const getOrganizationOptionsFromText = (text: string): AutocompleteSelectOption[] => {
    if (!text.trim()) return organizationOptions || []
    return (organizationOptions || []).filter((option) => option.text.toLowerCase().includes(text.toLowerCase()))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          mb: 2,
        }}
      >
        Create Collection
      </DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Stack direction="column" gap={2}>
          <TextField
            sx={{ mt: 1 }}
            label="Name"
            onChange={({ target: { value } }) => setFields.$.name(value)}
            value={fields.name}
            error={errors?.name}
            autoFocus
          />
          {organization || onlyMe ? (
            <FormControl>
              <FormLabel>
                Belongs To: <strong>{organization ? organization.name : 'Me'}</strong>
              </FormLabel>
            </FormControl>
          ) : (
            <FormControl>
              <FormLabel>Belongs To</FormLabel>
              <RadioGroup value={fields.belongsTo} onChange={(e, newValue) => setFields.$.belongsTo(newValue)}>
                <FormControlLabel value="me" control={<Radio />} label="Me" />
                <FormControlLabel
                  control={<Radio />}
                  value="org"
                  componentsProps={{ typography: { sx: { flex: 1 } } }}
                  label={
                    <AutocompleteSelectField
                      disabled={fields.belongsTo !== 'org'}
                      noOptionsText="No organizations found (must be contributor or higher)"
                      value={fields.orgId}
                      onChange={(newValue) =>
                        setFields({
                          ...fields,
                          belongsTo: 'org',
                          orgId: newValue,
                        })
                      }
                      label="Organization"
                      getOptionsFromText={getOrganizationOptionsFromText}
                      error={Boolean(errors?.orgId)}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          )}

          <VisibilityField type="Collection" value={fields.visibility} onChange={setFields.$.visibility} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleConfirm} disabled={!!errors} color="secondary">
          Create Collection
        </Button>
      </DialogActions>
    </Dialog>
  )
}
