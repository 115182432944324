import { gql } from '@apollo/client'
import { GetProfileStarsProjectsQueryVariables, LogicError, operations } from '@riverscapes/react-common'
import { refetchQuery } from '../helpers'
import { GetProfileStarsProjectsDocument } from './users'

const { useUpdateStarMutation: _useUpdateStarMutation } = operations

export const useUpdateCollectionStarMutation = (): ReturnType<typeof _useUpdateStarMutation> =>
  _useUpdateStarMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      updateStar: {
        __typename: 'MutationResult',
        success: true,
      },
    },
    update: (cache, result, { variables }) => {
      if (!result.data?.updateStar?.success) return

      if (!variables) throw new LogicError()
      const { id, starred } = variables

      cache.writeQuery({
        query: gql`
          query UpdateCollectionStar($id: ID!) {
            collection(id: $id) {
              starred
            }
          }
        `,
        variables: { id },
        data: { collection: { id, __typename: 'Collection', starred } },
      })
    },
  })

export const useUpdateProjectStarMutation = (): ReturnType<typeof _useUpdateStarMutation> =>
  _useUpdateStarMutation({
    refetchQueries: [
      refetchQuery<GetProfileStarsProjectsQueryVariables>(GetProfileStarsProjectsDocument, { offset: 0 }),
    ],
    optimisticResponse: {
      __typename: 'Mutation',
      updateStar: {
        __typename: 'MutationResult',
        success: true,
      },
    },
    update: (cache, result, { variables }) => {
      if (!result.data?.updateStar?.success) return

      if (!variables) throw new LogicError()
      const { id, starred } = variables

      cache.writeQuery({
        query: gql`
          query UpdateProjectStar($id: ID!) {
            project(id: $id) {
              starred
            }
          }
        `,
        variables: { id },
        data: { project: { id, __typename: 'Project', starred } },
      })

      // updateCache<GetProfileStarsProjectsQuery, GetProfileStarsProjectsQueryVariables>({
      //   cache,
      //   query: GetProfileStarsProjectsDocument,
      //   variables: { offset: 0 },
      //   update: (draft) => {
      //     if (!draft.profile?.stars.projects) return
      //     if(starred) {

      //     } else {

      //     }
      //   },
      // })
    },
  })

export const useUpdateOrganizationStarMutation = (): ReturnType<typeof _useUpdateStarMutation> =>
  _useUpdateStarMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      updateStar: {
        __typename: 'MutationResult',
        success: true,
      },
    },
    update: (cache, result, { variables }) => {
      if (!result.data?.updateStar?.success) return

      if (!variables) throw new LogicError()
      const { id, starred } = variables

      cache.writeQuery({
        query: gql`
          query UpdateOrganizationStar($id: ID!) {
            organization(id: $id) {
              starred
            }
          }
        `,
        variables: { id },
        data: { organization: { id, __typename: 'Organization', starred } },
      })
    },
  })
