import { Description, InsertDriveFile } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { Multidot, Polygon, Polyline, Raster } from '.'
import { ProjectTreeLayerTypeEnum } from '../../../schema/base'

interface LayerIconProps {
  layerType: ProjectTreeLayerTypeEnum
  iconProps?: SvgIconProps
}

const LayerIcon: React.FC<LayerIconProps> = ({ layerType, iconProps }: LayerIconProps) => {
  let Icon = Multidot
  switch (layerType) {
    case ProjectTreeLayerTypeEnum.Point:
      Icon = Multidot
      break
    case ProjectTreeLayerTypeEnum.Line:
      Icon = Polyline
      break
    case ProjectTreeLayerTypeEnum.Polygon:
      Icon = Polygon
      break
    case ProjectTreeLayerTypeEnum.Raster:
      Icon = Raster
      break
    case ProjectTreeLayerTypeEnum.File:
      Icon = InsertDriveFile
      break
    case ProjectTreeLayerTypeEnum.Report:
      Icon = Description
      break
    default:
      break
  }
  return <Icon {...iconProps} />
}

export default LayerIcon
