/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useBooleanState } from '../../lib'
import { GetProfileDetailQuery, GetOrganizationDetailQuery, OrganizationRoleEnum } from '../../schema/base'
import { Lookups } from '../lookups'
import { ShiftRight } from '../ShiftRight'
import { UsersTable } from '../UsersTable'
import { UsersTableProps } from '../UsersTable/UsersTable'

export interface SettingsPanelProps {
  lookups: Lookups
  onDeleteOrganization: () => Promise<unknown>
  onInviteAction: UsersTableProps['onInviteAction']
  onInviteCreate: UsersTableProps['onInviteCreate']
  onInviteDelete: UsersTableProps['onInviteDelete']
  onInviteResend: UsersTableProps['onInviteResend']
  onMemberRemove: UsersTableProps['onMemberRemove']
  onMemberUpdate: UsersTableProps['onMemberUpdate']
  organization: NonNullable<GetOrganizationDetailQuery['organization']>
  organizationUserRowsByType: UsersTableProps['rowsByType']
  profile: NonNullable<GetProfileDetailQuery['profile']>
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({
  lookups,
  onDeleteOrganization,
  onInviteAction,
  onInviteCreate,
  onInviteDelete,
  onInviteResend,
  onMemberRemove,
  onMemberUpdate,
  organization,
  organizationUserRowsByType,
  profile,
}) => {
  const canDelete = !!organization.permissions.delete
  const theme = useTheme()
  const [isConfirmationOpen, openConfirmation, closeConfirmation] = useBooleanState()
  const [isDeleting, startDeleting, stopDeleting] = useBooleanState()

  const handleDeleteOrganization = async () => {
    try {
      closeConfirmation()
      startDeleting()
      await onDeleteOrganization()
    } finally {
      stopDeleting()
    }
  }

  return (
    <>
      <Stack direction="column" sx={{ gap: 3 }}>
        <UsersTable
          css={{ height: '50rem' }}
          lookups={lookups}
          myId={profile.id}
          myRole={organization.myRole}
          onInviteAction={onInviteAction}
          onInviteCreate={onInviteCreate}
          onInviteDelete={onInviteDelete}
          onInviteResend={onInviteResend}
          onMemberRemove={onMemberRemove}
          onMemberUpdate={onMemberUpdate}
          organizationName={organization.name}
          rowsByType={organizationUserRowsByType}
        />
        {organization.myRole === OrganizationRoleEnum.Owner && (
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>You are an owner of this organization.</Typography>
                <ShiftRight />
                {canDelete && <Button disabled>Transfer Organization Contents</Button>}
                {canDelete && (
                  <Button disabled={!canDelete} onClick={openConfirmation} color="error" variant="contained">
                    Delete Organization
                  </Button>
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>
      <Dialog open={isConfirmationOpen} onClose={closeConfirmation}>
        <DialogTitle
          sx={{
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
            mb: 3,
          }}
        >
          Delete Organization
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete <strong>{organization.name}</strong>?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmation}>Cancel</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleDeleteOrganization} color="error">
            Delete Organization
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleting}>
        <DialogContent>Deleting organization...</DialogContent>
      </Dialog>
    </>
  )
}
