import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { GetProfileDetailQuery, GetProjectDetailQuery, Owner } from '../../schema/base'
import { ItemRepresentation } from '../ItemImage'
import { Lookups } from '../lookups'
import { ShiftRight } from '../ShiftRight'
import { useBooleanState, withTooltip } from '../../lib'
import { Delete, DriveFileMove } from '@mui/icons-material'
import { OwnerInput } from '@riverscapes/common'
import { ProjectTransferDialog } from '../ProjectTransferDialog'
import { AutocompleteSelectOption } from '../AutocompleteSelectField'

export interface SettingsPanelProps {
  lookups: Lookups
  profile: NonNullable<GetProfileDetailQuery['profile']>
  project: NonNullable<GetProjectDetailQuery['project']>
  onDeleteProject: () => Promise<unknown>
  onOwnerChange: (newOwner: OwnerInput) => Promise<unknown>
  organizationTransferOptions: AutocompleteSelectOption[]
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({
  lookups,
  profile,
  project,
  onDeleteProject,
  onOwnerChange,
  organizationTransferOptions,
}) => {
  const theme = useTheme()
  const canEdit = Boolean(project.permissions.update)
  const canDelete = Boolean(project.permissions.delete)

  const [isDeleteConfirmationOpen, openDeleteConfirmation, closeDeleteConfirmation] = useBooleanState()
  const [isTransferConfirmationOpen, openTransferConfirmation, closeTransferConfirmation] = useBooleanState()
  const [isDeleting, startDeleting, stopDeleting] = useBooleanState()
  const [isTransferring, startTransferring, stopTransferring] = useBooleanState()

  const handleDeleteProject = async () => {
    try {
      closeDeleteConfirmation()
      startDeleting()
      await onDeleteProject()
    } finally {
      stopDeleting()
    }
  }
  const handleTransferProject = async (newOwner: OwnerInput) => {
    try {
      closeTransferConfirmation()
      startTransferring()
      await onOwnerChange(newOwner)
    } finally {
      stopTransferring()
    }
  }

  return (
    <>
      <Stack direction="column" sx={{ gap: 3 }}>
        <Card>
          <CardContent>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography component="div">
                Project belongs to{' '}
                {profile.id === project.ownedBy.id ? (
                  'you'
                ) : (
                  <ItemRepresentation item={project.ownedBy} lookups={lookups} link />
                )}
              </Typography>
              <ShiftRight />

              {withTooltip(
                <Box>
                  {canEdit && (
                    <Button
                      variant="contained"
                      color="warning"
                      disabled={!canDelete}
                      onClick={openTransferConfirmation}
                      startIcon={<DriveFileMove />}
                    >
                      Change Ownership
                    </Button>
                  )}
                </Box>,
                canDelete
                  ? 'Transfer this project to yourself or an Organization you contribute to.'
                  : 'You cannot transfer this proejct'
              )}
              {canEdit && (
                <Button
                  onClick={openDeleteConfirmation}
                  disabled={!canDelete}
                  variant="contained"
                  color="error"
                  startIcon={<Delete />}
                >
                  Delete Project
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Dialog open={isDeleteConfirmationOpen} onClose={closeDeleteConfirmation} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
            mb: 3,
          }}
        >
          Delete Project
        </DialogTitle>
        <DialogContent>
          Are you sure you want to <strong>PERMANENTLY</strong> delete {project.name}? This action is irrevocable.
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}>Cancel</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleDeleteProject} color="error">
            Delete Project
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleting}>
        <DialogContent>Deleting project...</DialogContent>
      </Dialog>
      <Dialog open={isTransferring}>
        <DialogContent>Transferring Project...</DialogContent>
      </Dialog>

      {/* Transfer Confirmation */}
      <ProjectTransferDialog
        currentOwner={project.ownedBy as Owner}
        open={isTransferConfirmationOpen}
        onClose={closeTransferConfirmation}
        onConfirm={handleTransferProject}
        profile={profile}
        organizationOptions={organizationTransferOptions}
      />
    </>
  )
}
