import React from 'react'
import { CardContent, CardHeader } from '@mui/material'

// import log from 'loglevel'
import { HomeCard } from './HomeCard'
import { useAuth } from '@riverscapes/react-api'
import { ProjectTypeList } from '../ProjectTypeDetail'
import { PendingItem } from '../PendingItem'
import { ProjectTypeItemFragment } from '../../schema/base'

export interface ProjectTypesCardProps {
  projectTypes: (ProjectTypeItemFragment | PendingItem)[]
  projectTypeStats: { [key: string]: number }
  onGotoProjectType: (projectTypeId: string) => void
  onGotoSearch: (projectTypeId: string) => void
}

export const ProjectTypesCard: React.FC<ProjectTypesCardProps> = ({
  projectTypes,
  projectTypeStats,
  onGotoProjectType,
  onGotoSearch,
}) => {
  const { isAuthenticated } = useAuth()
  if (!isAuthenticated) return null
  return (
    <HomeCard gridWidths={2}>
      <CardHeader title="Project Types" />
      <CardContent sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        <ProjectTypeList
          projectTypes={projectTypes}
          projectTypesSummary={projectTypeStats}
          onGotoProjectType={onGotoProjectType}
          onGotoSearch={onGotoSearch}
        />
      </CardContent>
    </HomeCard>
  )
}
