/** @jsxImportSource @emotion/react */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { AutocompleteSelectField } from '../AutocompleteSelectField'
import { LogicError, someValues, useFields } from '../../lib'
import { Lookups } from '../lookups'

export interface AddToCollectionFromResultsDialogProps {
  onConfirm: (properties: { collectionId: string }) => void
  onClose: () => void
  open: boolean
  lookups: Lookups
}

export const AddToCollectionFromResultsDialog: React.FC<AddToCollectionFromResultsDialogProps> = ({
  onConfirm,
  onClose,
  open,
  lookups,
}) => {
  const [fields, setFields] = useFields(
    {
      collectionId: null as string | null,
    },
    [open]
  )

  const errors = someValues({
    collectionId: !fields.collectionId,
  })

  const handleConfirm = () => {
    const { collectionId } = fields
    if (!collectionId) throw new LogicError()
    onConfirm({
      collectionId,
    })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add To Collection</DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Stack direction="column" gap={2}>
          <AutocompleteSelectField
            sx={{ mt: 1 }}
            noOptionsText="No collections found"
            value={fields.collectionId}
            onChange={(newValue) => setFields.$.collectionId(newValue)}
            label="Collection"
            getOptionsFromText={lookups.getEditableCollectionOptionsByName}
            error={errors?.collectionId}
          />
        </Stack>
        <Typography variant="body2" mt={1}>
          Note: Only projects which are not already in the collection will be added.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={!!errors}>
          Add To Collection
        </Button>
      </DialogActions>
    </Dialog>
  )
}
