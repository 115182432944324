import { Box, Container, ContainerProps, SxProps, Theme, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode } from 'react'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    height: '100%',
    maxHeight: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    flex: '1 1',
  },
  logoBlock: {
    textAlign: 'center',
  },
  logo: {},
  loadingContainer: {
    textAlign: 'center',
  },
})

interface Props {
  children?: ReactNode
  title?: ReactNode
  message?: ReactNode
  status?: ReactNode
  version?: ReactNode
  logoSVG?: ReactNode
  containerProps?: Partial<ContainerProps>
}

const Loading: FC<Props> = ({
  children,
  logoSVG,
  title,
  status,
  version,
  containerProps: containPropsORIG,
  message,
}: Props) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const VerticalSpacer: FC = () => <Box sx={styles.spacer} />

  const containPropsFb = containPropsORIG || {}
  const { sx: containerClass, ...containerProps } = containPropsFb
  return (
    <Container sx={{ ...styles.root }} maxWidth="xs" {...containerProps}>
      <VerticalSpacer />
      <Box sx={styles.logoBlock}>
        {logoSVG && <Box sx={styles.logo}>{logoSVG}</Box>}
        {title && (
          <Typography component="div" variant="h5">
            {title}
          </Typography>
        )}
        <Typography component="div" variant="caption">
          {version}
        </Typography>
      </Box>
      <Box sx={styles.loadingContainer}>
        {status || (
          <Typography variant="h3" component="div">
            Loading...
          </Typography>
        )}
        {message && <Typography variant="overline">{message}</Typography>}
      </Box>
      {children}
      <VerticalSpacer />
    </Container>
  )
}

export default Loading
