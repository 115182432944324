import {
  LogicError,
  operations,
  GetOrganizationInvitesQuery,
  GetOrganizationInvitesQueryVariables,
  GetOrganizationUsersQuery,
  GetOrganizationUsersQueryVariables,
  OrganizationInviteStateEnum,
  GetProfileOrganizationInvitesQuery,
  GetProfileOrganizationInvitesQueryVariables,
  MissingDataError,
  GetProfileOrganizationsQueryVariables,
} from '@riverscapes/react-common'
import produce from 'immer'
import { remove } from 'lodash'
import { refetchQuery, updateCache } from '../helpers'

const {
  GetProfileOrganizationInvitesDocument,
  GetOrganizationInvitesDocument,
  GetOrganizationUsersDocument,
  GetProfileOrganizationsDocument,
  UpdateOrganizationLogoImageDocument,
  useActionOrganizationInviteMutation: _useActionOrganizationInviteMutation,
  useCreateOrganizationInviteMutation: _useCreateOrganizationInviteMutation,
  useCreateOrganizationMutation,
  useDeleteOrganizationInviteMutation: _useDeleteOrganizationInviteMutation,
  useDeleteOrganizationMutation: _useDeleteOrganizationMutation,
  useGetOrganizationByIdLazyQuery,
  useGetOrganizationDetailQuery,
  useGetOrganizationInvitesQuery,
  useGetOrganizationUsersQuery,
  useGetOrganizationsByNameLazyQuery,
  useGetOrganizationProjectsQuery,
  useRemoveOrganizationMemberMutation: _useRemoveOrganizationMemberMutation,
  useRequestOrganizationInviteMutation: _useRequestOrganizationInviteMutation,
  useResendOrganizationInviteMutation,
  useUpdateOrganizationDescriptionMutation: _useUpdateOrganizationDescriptionMutation,
  useUpdateOrganizationLogoImageMutation: _useUpdateOrganizationLogoImageMutation,
  useUpdateOrganizationMemberMutation,
  useUpdateOrganizationNameMutation: _useUpdateOrganizationNameMutation,
  useUpdateOrganizationSummaryMutation: _useUpdateOrganizationSummaryMutation,
} = operations

export {
  useCreateOrganizationMutation,
  useGetOrganizationByIdLazyQuery,
  useGetOrganizationDetailQuery,
  useGetOrganizationInvitesQuery,
  useGetOrganizationUsersQuery,
  useGetOrganizationProjectsQuery,
  useGetOrganizationsByNameLazyQuery,
  useResendOrganizationInviteMutation,
  useUpdateOrganizationMemberMutation,
}

export const useUpdateOrganizationDescriptionMutation = (): ReturnType<
  typeof _useUpdateOrganizationDescriptionMutation
> =>
  _useUpdateOrganizationDescriptionMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateOrganization: {
        __typename: 'Organization',
        ...variables,
      },
    }),
  })

export const useUpdateOrganizationNameMutation = (): ReturnType<typeof _useUpdateOrganizationNameMutation> =>
  _useUpdateOrganizationNameMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateOrganization: {
        __typename: 'Organization',
        ...variables,
      },
    }),
  })

export const useUpdateOrganizationSummaryMutation = (): ReturnType<typeof _useUpdateOrganizationSummaryMutation> =>
  _useUpdateOrganizationSummaryMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateOrganization: {
        __typename: 'Organization',
        ...variables,
      },
    }),
  })

export const useUpdateOrganizationLogoImageMutation = (): ReturnType<typeof _useUpdateOrganizationLogoImageMutation> =>
  _useUpdateOrganizationLogoImageMutation({
    update: (cache, { data }) => {
      const modifiedData = produce(data, (draft) => {
        if (draft?.updateOrganization?.logo) {
          draft.updateOrganization.logo = `${draft.updateOrganization.logo}?u=${Date.now()}` // bust cache as url does not change
        }
      })
      cache.writeQuery({
        query: UpdateOrganizationLogoImageDocument,
        data: modifiedData,
      })
    },
  })

export const useCreateOrganizationInviteMutation = (): ReturnType<typeof _useCreateOrganizationInviteMutation> =>
  _useCreateOrganizationInviteMutation({
    update: (cache, { data }, { variables }) => {
      if (!data?.createOrganizationInvite) return

      const organizationInvite = data.createOrganizationInvite

      if (!variables) throw new LogicError()
      const { organizationId } = variables

      updateCache<GetOrganizationInvitesQuery, GetOrganizationInvitesQueryVariables>({
        cache,
        variables: { id: organizationId, offset: 0 },
        query: GetOrganizationInvitesDocument,
        update: (draft) => {
          if (!draft.organization?.organizationInvites) return
          draft.organization.organizationInvites.total += 1
          draft.organization.organizationInvites.items.push(organizationInvite)
        },
      })
    },
  })

export const useDeleteOrganizationInviteMutation = (): ReturnType<typeof _useDeleteOrganizationInviteMutation> =>
  _useDeleteOrganizationInviteMutation({
    update: (cache, { data }, { variables, context }) => {
      if (!data?.deleteOrganizationInvite?.success) return // TODO: should I get error on failure?

      if (!variables) throw new LogicError()
      const { id } = variables

      if (!context) throw new LogicError()
      const { organizationId } = context // TODO: can I ensure this?

      updateCache<GetOrganizationInvitesQuery, GetOrganizationInvitesQueryVariables>({
        cache,
        query: GetOrganizationInvitesDocument,
        variables: { id: organizationId, offset: 0 },
        update: (draft) => {
          if (!draft.organization?.organizationInvites) return
          if (remove(draft.organization.organizationInvites.items, (invite) => invite.id === id))
            draft.organization.organizationInvites.total -= 1
        },
      })

      cache.evict({
        id: cache.identify({ __typename: 'OrganizationInvite', id }),
      })
      cache.gc()
    },
  })

export const useDeleteProfileInviteMutation = (): ReturnType<typeof _useDeleteOrganizationInviteMutation> =>
  _useDeleteOrganizationInviteMutation({
    update: (cache, { data }, { variables }) => {
      if (!data?.deleteOrganizationInvite?.success) return // TODO: should I get error on failure?

      if (!variables) throw new LogicError()
      const { id } = variables

      updateCache<GetProfileOrganizationInvitesQuery, GetProfileOrganizationInvitesQueryVariables>({
        cache,
        query: GetProfileOrganizationInvitesDocument,
        variables: { offset: 0 },
        update: (draft) => {
          if (!draft.profile?.organizationInvites) return
          if (remove(draft.profile.organizationInvites.items, (invite) => invite.id === id))
            draft.profile.organizationInvites.total -= 1
        },
      })

      cache.evict({
        id: cache.identify({ __typename: 'OrganizationInvite', id }),
      })
      cache.gc()
    },
  })

export const useActionOrganizationInviteMutation = (): ReturnType<typeof _useActionOrganizationInviteMutation> =>
  _useActionOrganizationInviteMutation({
    update: (cache, { data }, { context }) => {
      if (!data?.actionOrganizationInvite) return

      const { id, state, role, invitee } = data.actionOrganizationInvite
      if (!invitee) throw new MissingDataError()

      if (!context) throw new LogicError()
      const { organizationId } = context // TODO: can I ensure this via TS?

      if (state !== OrganizationInviteStateEnum.Accepted) return // TODO: handle rejected

      updateCache<GetOrganizationInvitesQuery, GetOrganizationInvitesQueryVariables>({
        cache,
        query: GetOrganizationInvitesDocument,
        variables: { id: organizationId, offset: 0 },
        update: (draft) => {
          if (!draft.organization?.organizationInvites) return
          if (remove(draft.organization.organizationInvites.items, (invite) => invite.id === id).length)
            draft.organization.organizationInvites.total -= 1
        },
      })

      updateCache<GetOrganizationUsersQuery, GetOrganizationUsersQueryVariables>({
        cache,
        query: GetOrganizationUsersDocument,
        variables: { id: organizationId, offset: 0 },
        update: (draft) => {
          if (!draft.organization?.organizationUsers) return
          draft.organization.organizationUsers.items.push({
            __typename: 'OrganizationUser',
            user: invitee,
            organization: {
              __typename: 'Organization',
              id: organizationId,
            },
            role,
          })
          draft.organization.organizationUsers.total += 1
        },
      })
    },
  })

export const useActionProfileOrganizationInviteMutation = (): ReturnType<typeof _useActionOrganizationInviteMutation> =>
  _useActionOrganizationInviteMutation({
    refetchQueries: [
      refetchQuery<GetProfileOrganizationsQueryVariables>(GetProfileOrganizationsDocument, { offset: 0 }),
    ],
    update: (cache, { data }, { context }) => {
      if (!data?.actionOrganizationInvite) return

      const { id, state, invitee } = data.actionOrganizationInvite
      if (!invitee) throw new MissingDataError()

      if (!context) throw new LogicError()
      // const { organizationId } = context // TODO: can I ensure this via TS?

      if (state !== OrganizationInviteStateEnum.Accepted) return // TODO: handle rejected

      updateCache<GetProfileOrganizationInvitesQuery, GetProfileOrganizationInvitesQueryVariables>({
        cache,
        query: GetProfileOrganizationInvitesDocument,
        variables: { offset: 0 },
        update: (draft) => {
          if (!draft.profile?.organizationInvites) return
          if (remove(draft.profile.organizationInvites.items, (invite) => invite.id === id).length)
            draft.profile.organizationInvites.total -= 1
        },
      })
    },
  })

export const useRemoveOrganizationMemberMutation = (): ReturnType<typeof _useRemoveOrganizationMemberMutation> =>
  _useRemoveOrganizationMemberMutation({
    update: (cache, { data }, { variables }) => {
      if (!data?.removeOrganizationMember?.success) return // TODO: should I get error on failure?

      if (!variables) throw new LogicError()
      const { organizationId, userId } = variables

      updateCache<GetOrganizationUsersQuery, GetOrganizationUsersQueryVariables>({
        cache,
        query: GetOrganizationUsersDocument,
        variables: { id: organizationId, offset: 0 },
        update: (draft) => {
          if (!draft.organization?.organizationUsers) return
          draft.organization.organizationUsers.total -= 1
          remove(draft.organization.organizationUsers.items, ({ user }) => user.id === userId)
        },
      })
    },
  })

export const useDeleteOrganizationMutation = (): ReturnType<typeof _useDeleteOrganizationMutation> =>
  _useDeleteOrganizationMutation({
    update: (cache, _, { variables }) => {
      if (!variables) throw new LogicError()
      const { id } = variables
      cache.evict({ id: cache.identify({ __typename: 'Organization', id }) })
      cache.gc()
    },
  })

export const useRequestOrganizationInviteMutation = (): ReturnType<typeof _useRequestOrganizationInviteMutation> =>
  _useRequestOrganizationInviteMutation({
    refetchQueries: [
      refetchQuery<GetProfileOrganizationInvitesQueryVariables>(GetProfileOrganizationInvitesDocument, { offset: 0 }),
    ],
  })
