import {
  Box,
  Link,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { MetaData, MetaDataTypeEnum } from '@riverscapes/react-common'
import React from 'react'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    padding: theme.spacing(1),
  },
  table: {},
  section: {
    mb: theme.spacing(3),
  },
  sectionHeader: {
    borderBottom: '1px solid',
  },
  sectionDescription: {
    // borderBottom: '1px solid',
  },
  metaKey: { fontWeight: 'bold' },
  metaValue: {
    color: theme.palette.text.secondary,
    overflowWrap: 'anywhere',
    textAlign: 'left',
  },
})

interface MetaDataProps {
  title: string
  description?: string
  meta: MetaData[]
}

const SPECIAL_FORMATS: Record<string, (val: string) => string> = {
  datecreated: (val) => {
    // TODO: broken
    // const valDate = dates.HTML5InputToTimestamp(val)
    // return valDate > 0 ? `${dates.formatDate(valDate, 'lll')} (${dates.formatDateAgo(valDate)})` : 'unknown'
    return 'NOT_IMPLEMENTED'
  },
}
const formatString = (key: string, val: string, metaType?: MetaDataTypeEnum): React.ReactNode => {
  const lwrKey = key.toLowerCase()
  if (Object.keys(SPECIAL_FORMATS).indexOf(lwrKey) > -1) {
    return SPECIAL_FORMATS[lwrKey](val)
  } else if (
    val &&
    val.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/)
  ) {
    return (
      <Link href={val} target="_blank">
        {val}
      </Link>
    )
  } else return val
}

const MetaData: React.FC<MetaDataProps> = ({ title, description, meta }: MetaDataProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  if (!meta || meta.length === 0) return null
  return (
    <Box sx={styles.section}>
      <Typography variant="subtitle1" sx={styles.sectionHeader}>
        {title}
      </Typography>
      <Typography variant="caption" sx={styles.sectionDescription}>
        {description}
      </Typography>
      <TableContainer>
        <Table sx={styles.table} size="small" aria-label="a dense table">
          <TableBody>
            {meta.map(({ key: rowKey, value: rowValue, type: rowType }) => (
              <TableRow key={rowKey}>
                <TableCell component="th" scope="row" sx={styles.metaKey}>
                  {rowKey}
                </TableCell>
                <TableCell align="right" sx={styles.metaValue}>
                  {formatString(rowKey, rowValue, rowType as MetaDataTypeEnum)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default MetaData
