import { useRecoilCallback } from 'recoil'
import { mapSizeAtom, viewportAtom } from '../recoil'
import { zoomExtents } from '../lib/util'
import { BoundsObj } from '../types'

export type UseZoomExtentsReturn = (lyrBounds?: BoundsObj) => Promise<boolean>

const useZoomExtents = (): UseZoomExtentsReturn => {
  const zoomLayerExtent = useRecoilCallback(({ snapshot, set }) => async (lyrBounds?: BoundsObj): Promise<boolean> => {
    const mapSize = await snapshot.getPromise(mapSizeAtom)
    const viewport = await snapshot.getPromise(viewportAtom)
    if (mapSize && lyrBounds) {
      const zoomView = zoomExtents(lyrBounds, mapSize.width, mapSize.height, 0.5)
      set(viewportAtom, { ...viewport, ...zoomView })
      return true
    } else return false
  })
  return zoomLayerExtent
}

export default useZoomExtents
