import { RouteObject, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Home, HomeProps } from '../components/Home'
import { ProjectDetail, ProjectDetailProps } from '../components/ProjectDetail'
import { CollectionDetail, CollectionDetailProps } from '../components/CollectionDetail'
import { OrganizationDetail, OrganizationDetailProps } from '../components/OrganizationDetail'
import { UserDetail, UserDetailProps } from '../components/UserDetail'
import { SavedSearchDetail, SavedSearchDetailProps } from '../components/SavedSearchDetail'
import { Search, SearchProps } from '../components/Search'
import { AuthenticationRequired } from '../components/AuthenticationRequired'
import log from 'loglevel'
import {
  DateWithinEnum,
  isEnum,
  OwnerInputTypesEnum,
  SearchSortEnum,
  SearchViewTypeEnum,
} from '@riverscapes/react-common'
import { useAuth } from '@riverscapes/react-api'
import { ProfileDetailContainer, ProfileDetailContainerProps } from '../components/ProfileDetail'
import { WebRaveDetail, WebRaveDetailProps } from '../components/WebRaveDetail'
import { MigrationRedirect, MigrationRedirectProps } from './MigrationRedirect'
import { ProjectTypeDetail, ProjectTypeDetailProps } from '../components/ProjectTypeDetail'
import { LoginSuccess, LoginSuccessProps } from '../components/LoginSuccess'

const scrubNonStringValues = (o: Record<string, any>): Record<string, string> => {
  return Object.entries(o).reduce<Record<string, string>>((acc, [key, value]) => {
    if (typeof value !== 'string') return acc
    return { ...acc, [key]: value }
  }, {})
}

const parseWildcard = (pathParams: Record<string, string | undefined>, maxParams = Infinity) => {
  /* React Router 6 is pretty disappointing: https://github.com/remix-run/react-router/issues/8381 */
  const wildcardParam = pathParams['*']
  if (!wildcardParam) return []
  const split = wildcardParam.split('/').map((el) => el || undefined)
  if (!split[split.length - 1]) split.length -= 1 // deal with possible trailing slash
  if (split.length > maxParams) throw new Error('Too many parameters.')
  return split
}

export interface AppRoute<PropsT> {
  path: string
  getRouteObject: () => RouteObject
  getProps: (pathParams: Record<string, string | undefined>, searchParams: Record<string, string | undefined>) => PropsT
  getUrl: (props: PropsT) => string
}

const configureRoute = <PropsT extends JSX.IntrinsicAttributes>({
  path,
  Component,
  getProps,
  getUrl,
  AuthenticationRequired,
}: {
  path: string
  Component: React.FC<PropsT>
  getProps: (pathParams: Record<string, string | undefined>, searchParams: Record<string, string | undefined>) => PropsT
  getUrl: (props: PropsT) => string
  AuthenticationRequired?: React.FC
}) => {
  const Wrapped = () => {
    const { isAuthenticated } = useAuth()

    const params = useParams()
    const [urlSearchParams] = useSearchParams()

    const pathParams = Object.entries(params).reduce<Record<string, string>>(
      (acc, [key, value]) => ({ ...acc, [key]: value || '' }),
      {}
    )

    const searchParams: Record<string, string> = {}
    urlSearchParams.forEach((value, key) => {
      searchParams[key] = value
    })

    const props = getProps(pathParams, searchParams)

    if (AuthenticationRequired && !isAuthenticated) return <AuthenticationRequired />

    return <Component {...props} />
  }

  return {
    path,
    getRouteObject: () => ({
      path,
      element: <Wrapped />,
    }),
    getUrl,
    getProps,
  }
}

export const home = configureRoute<HomeProps>({
  path: '/',
  Component: Home,
  getProps: () => {
    return {}
  },
  getUrl: () => `/`,
})

export const projectDetail = configureRoute<ProjectDetailProps>({
  path: '/p/*', // Parsed as /p/:id/:tab?
  Component: ProjectDetail,
  getProps: (pathParams) => {
    try {
      const [id, tab] = parseWildcard(pathParams, 2)
      if (!id) throw new Error()
      return { id, tab }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
        tab: 'overview',
      }
    }
  },
  getUrl: ({ id, tab = '' }) => `/p/${id}/${tab}`, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const webRaveDetail = configureRoute<WebRaveDetailProps>({
  path: '/rv/*', // Parsed as /p/:id?
  Component: WebRaveDetail,
  getProps: (pathParams) => {
    try {
      const [id] = parseWildcard(pathParams, 1)
      if (!id) throw new Error()
      return { id }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
        tab: 'overview',
      }
    }
  },
  getUrl: ({ id }) => `/rv/${id}`, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const collectionDetail = configureRoute<CollectionDetailProps>({
  path: '/c/*',
  Component: CollectionDetail,
  getProps: (pathParams) => {
    try {
      const [id, tab, view] = parseWildcard(pathParams, 3)
      if (!id) throw new Error()
      if (view && !isEnum<SearchViewTypeEnum>(view, SearchViewTypeEnum)) throw new Error('Invalid view type')

      return { id, tab, viewType: view as SearchViewTypeEnum }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
        tab: 'overview',
      }
    }
  },
  getUrl: ({ id, tab = '', viewType = SearchViewTypeEnum.Map }) => {
    let baseUrl = `/c/${id}/${tab}`
    if (tab === 'projects') baseUrl += `/${viewType}`
    return baseUrl
  }, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const organizationDetail = configureRoute<OrganizationDetailProps>({
  path: '/o/*',
  Component: OrganizationDetail,
  getProps: (pathParams) => {
    try {
      const [id, tab, view] = parseWildcard(pathParams, 3)
      if (!id) throw new Error()
      if (view && !isEnum<SearchViewTypeEnum>(view, SearchViewTypeEnum)) throw new Error('Invalid view type')
      return { id, tab, viewType: view as SearchViewTypeEnum }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
        tab: 'overview',
      }
    }
  },
  getUrl: ({ id, tab = '', viewType = SearchViewTypeEnum.Map }) => {
    let baseUrl = `/o/${id}/${tab}`
    if (tab === 'projects') baseUrl += `/${viewType}`
    return baseUrl
  }, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const userDetail = configureRoute<UserDetailProps>({
  path: '/u/*',
  Component: UserDetail,
  getProps: (pathParams) => {
    try {
      const [id, tab, view] = parseWildcard(pathParams, 3)
      if (!id) throw new Error()
      if (view && !isEnum<SearchViewTypeEnum>(view, SearchViewTypeEnum)) throw new Error('Invalid view type')
      return { id, tab, viewType: view as SearchViewTypeEnum }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
        tab: 'overview',
      }
    }
  },
  getUrl: ({ id, tab = '', viewType = SearchViewTypeEnum.Map }) => {
    let baseUrl = `/u/${id}/${tab}`
    if (tab === 'projects') baseUrl += `/${viewType}`
    return baseUrl
  }, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const profileDetail = configureRoute<ProfileDetailContainerProps>({
  path: '/profile/*',
  Component: ProfileDetailContainer,
  getProps: (pathParams) => {
    try {
      const [tab, view] = parseWildcard(pathParams, 2)
      if (view && !isEnum<SearchViewTypeEnum>(view, SearchViewTypeEnum)) throw new Error('Invalid view type')
      return { tab, view }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        tab: 'overview',
      }
    }
  },
  getUrl: ({ tab = '', viewType = SearchViewTypeEnum.Map }) => {
    let baseUrl = `/profile/${tab}`
    if (tab === 'projects') baseUrl += `/${viewType}`
    return baseUrl
  }, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const savedSearchDetail = configureRoute<SavedSearchDetailProps>({
  path: '/d/*',
  Component: SavedSearchDetail,
  getProps: (pathParams) => {
    try {
      const [id, tab, view] = parseWildcard(pathParams, 2)
      if (!id) throw new Error()
      if (view && !isEnum<SearchViewTypeEnum>(view, SearchViewTypeEnum)) throw new Error('Invalid view type')
      return { id, tab, view }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
        tab: 'overview',
      }
    }
  },
  getUrl: ({ id, tab = '', viewType = SearchViewTypeEnum.Map }) => {
    let baseUrl = `/d/${id}/${tab}`
    if (tab === 'projects') baseUrl += `/${viewType}`
    return baseUrl
  }, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const projectTypeDetail = configureRoute<ProjectTypeDetailProps>({
  path: '/pt/*',
  Component: ProjectTypeDetail,
  getProps: (pathParams) => {
    try {
      const [id] = parseWildcard(pathParams, 2)
      if (!id) throw new Error()
      return { id }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        id: '',
      }
    }
  },
  getUrl: ({ id }) => `/pt/${id}`, // TODO: route without tab (but React Router doesn't support optional params!)
  AuthenticationRequired,
})

export const search = configureRoute<SearchProps>({
  path: '/s',
  Component: Search, // <Search />
  getProps: (pathParams, searchParams) => {
    try {
      const {
        bounded,
        boundsId,
        collection,
        createdFrom,
        createdTo,
        createdWithin,
        updatedFrom,
        updatedTo,
        keywords,
        name,
        meta,
        owner,
        page,
        projectTypeId,
        sort,
        type,
        view,
        tags,
        geo,
      } = searchParams
      if (
        type !== 'Project' &&
        type !== 'Collection' &&
        type !== 'Organization' &&
        type !== 'User' &&
        type !== 'SavedSearch'
      )
        throw new Error('Invalid search type')

      if (typeof sort !== 'undefined' && !isEnum<SearchSortEnum>(sort, SearchSortEnum)) throw new Error('Invalid sort')

      const geoProp = (() => {
        if (!geo) return undefined
        const geoParts = geo.split(',')
        return {
          lng: Number(geoParts[0]),
          lat: Number(geoParts[1]),
          zoom: Number(geoParts[2]),
        }
      })()

      const metaProp = (() => {
        if (!meta) return undefined
        const metaRows = meta.split(',')
        return metaRows.map((metaRow) => {
          const [key, value] = metaRow.split(':')
          return { key: decodeURIComponent(key), value: decodeURIComponent(value) }
        })
      })()

      const createdOn = (() => {
        if (!createdFrom && !createdTo) return undefined
        return {
          from: createdFrom,
          to: createdTo,
        }
      })()

      const updatedOn = (() => {
        if (!updatedFrom && !updatedTo) return undefined
        return {
          from: updatedFrom,
          to: updatedTo,
        }
      })()

      const ownedBy = (() => {
        if (!owner) return undefined
        const [ownedByType, ownedById] = owner.split(':')
        if (![OwnerInputTypesEnum.Organization, OwnerInputTypesEnum.User].includes(ownedByType as OwnerInputTypesEnum))
          return undefined
        if (!ownedById) return undefined
        return {
          type: ownedByType as OwnerInputTypesEnum,
          id: ownedById,
        }
      })()

      const createdWithinProp = Object.values(DateWithinEnum).includes(createdWithin as DateWithinEnum)
        ? (createdWithin as DateWithinEnum)
        : undefined

      const newView = (() => {
        if (view && !isEnum<SearchViewTypeEnum>(view, SearchViewTypeEnum)) throw new Error('Invalid view type')
        return view as SearchViewTypeEnum
      })()

      return {
        type,
        params: {
          bounded: bounded ? true : undefined,
          boundsId,
          collection,
          createdOn,
          createdWithin: createdWithinProp,
          keywords,
          meta: metaProp,
          name,
          ownedBy,
          projectTypeId,
          tags: tags?.split(','),
          updatedOn,
        },
        page: page ? Number(page) : undefined,
        view: newView,
        sort,
        geo: geoProp,
      }
    } catch (err) {
      log.error('Failed to parse route.')
      return {
        type: 'Project',
        params: {},
      }
    }
  },
  getUrl: ({ type, params, page, geo, sort, view }) => {
    const encodeObject: Record<string, string | undefined | null> = {}

    // common
    const { createdOn, createdWithin, keywords, meta, name, tags, updatedOn, ownedBy } = params
    Object.assign(encodeObject, {
      type, // keep type first
      createdFrom: createdOn?.from,
      createdTo: createdOn?.to,
      createdWithin,
      keywords,
      meta: meta && meta.map(({ key, value }) => `${encodeURIComponent(key)}:${encodeURIComponent(value)}`).join(','),
      name,
      owner: ownedBy && `${ownedBy.type}:${ownedBy.id}`,
      page: page ? String(page) : undefined,
      sort,
      tags: tags?.join(','),
      updatedFrom: updatedOn?.from,
      updatedTo: updatedOn?.to,
    })

    // project-only
    if (type === 'Project') {
      const { bounded, boundsId, collection, projectTypeId } = params
      Object.assign(encodeObject, {
        bounded: bounded ? '1' : undefined,
        boundsId,
        collection,
        projectTypeId,
        view,
        geo: geo && [geo.lng, geo.lat, geo.zoom].join(','),
      })
    }

    const searchParams = new URLSearchParams(scrubNonStringValues(encodeObject)).toString()
    return `/s?${searchParams}`
  },
  AuthenticationRequired,
})

const makeRouteObjects = (appRoutes: AppRoute<any>[]): RouteObject[] => {
  const routes = appRoutes.map((appRoute) => appRoute.getRouteObject())
  return routes
}

export const redirector = configureRoute<MigrationRedirectProps>({
  path: '/redirect/*',
  Component: MigrationRedirect,
  getProps: (pathParams) => {
    try {
      const [oldProgram, oldProjectId] = pathParams['*']?.split('/') || ['NOT_FOUND', 'NOT_FOUND']
      return { oldProgram, oldProjectId }
    } catch (err) {
      log.error('Failed to parse route.')
      // TODO: cannot actually show with no ID, use error component
      return {
        oldProjectId: 'NOT_FOUND',
        oldProgram: 'NOT_FOUND',
      }
    }
  },
  getUrl: () => `/`, // NB: There's no way back from this so we just redirect to home
})

/**
 * This is a special route that is used to redirect to the login page after a successful login.
 */
const loginSuccess = configureRoute<LoginSuccessProps>({
  path: '/login_success',
  Component: LoginSuccess,
  getProps: (pathParams, searchParams) => {
    console.log('loginSuccess', pathParams, searchParams)
    return {
      secret: searchParams.code || '',
    }
  },
  getUrl: () => {
    const baseUrl = `/login_success`
    return baseUrl
  },
})

export const routes: RouteObject[] = makeRouteObjects([
  home,
  profileDetail,
  collectionDetail,
  organizationDetail,
  projectDetail,
  projectTypeDetail,
  savedSearchDetail,
  search,
  userDetail,
  webRaveDetail,
  redirector,
  loginSuccess,
])

export const useGotoRoute = <PropsT,>(appRoute: AppRoute<PropsT>): ((props: PropsT, replace?: boolean) => void) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentUrl = `${location.pathname}${location.search}`
  return (props: PropsT, replace?: boolean) => {
    const url = appRoute.getUrl(props)
    if (url === currentUrl) return
    navigate(url, { replace })
  }
}

/**
 * When we return from deleting we want to go back to the previous page
 * @returns
 */
export const useGoBackAfterDelete = () => {
  const navigate = useNavigate()
  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      console.log(location)
      navigate(-1)
    } else {
      navigate('/', { replace: true }) // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }
  return goBack
}
