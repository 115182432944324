import xpath from 'xpath'
import { DOMParser, XMLSerializer as XMLDomSerializer } from '@xmldom/xmldom'

import {
  DatasetInput,
  DatasetLayerInput,
  DatasetTypeEnum,
  Link,
  MetaData,
  MetaDataInput,
  ProjectInput,
  QaqcEventInput,
  QaqcStateEnum,
} from '../gen/schema.types'
import { DasetSidecarFiles } from '../constants'
import { escapeRegExp, uniq } from 'lodash'
import { FileParts, RSXPath, WarehouseEl } from '../types'
import { getChildNodeAttr, getChildNodeText, getMetaFromNode, getNodeText } from '../lib/xml'
import { DSXpaths, getRSXPath, localPathParse, rsXpath2Obj } from '../lib/rsPaths'

export class ProjectXML {
  dom: any
  projNode: Node

  constructor(xmlStr: string) {
    this.dom = new DOMParser().parseFromString(xmlStr.toString(), 'text/xml')
    this.projNode = xpath.select1('Project', this.dom) as Node
  }

  _toString = (): string => {
    // This is an ugly string so don't use it for the final XML. Just to check and test the DOM
    const serializer = new XMLDomSerializer()
    const xmlStr = serializer.serializeToString(this.dom)

    return xmlStr
  }

  /**
   * Set the TextContent of a node safely using CDATA if necessary
   * @param node
   * @param content
   */
  setNodeContent = (node: Element, content: string): void => {
    if (content.includes('<') || content.includes('&')) {
      // Content contains characters that require CDATA
      const cdata = node.ownerDocument.createCDATASection(content.trim())
      node.textContent = ''
      node.appendChild(cdata)
    } else {
      // Content does not require CDATA
      node.textContent = content
    }
  }

  setTextNode = (parent: Element, TagName: string, content?: string): void => {
    const childNode = xpath.select1(TagName, parent) as Element

    const newContentExists = content && content.trim().length > 0
    const oldNodeExists = Boolean(childNode)
    console.log({
      childNode,
      newContentExists,
      oldNodeExists,
    })
    // if there isn't one then create it
    if (newContentExists) {
      if (!oldNodeExists) {
        const newNode = this.dom.createElement(TagName)
        this.setNodeContent(newNode, content)
        parent.appendChild(newNode)
      } else {
        this.setNodeContent(childNode, content)
      }
    } else {
      if (oldNodeExists) {
        parent.removeChild(childNode)
      } else {
        // do nothing
      }
    }
  }

  /**
   * Add the <Warehouse> tag to the project
   * @param newTag
   */
  addWarehouseEl = (newTag: WarehouseEl): void => {
    let whNode = xpath.select1('Project/Warehouse', this.dom) as Element
    if (!whNode) {
      const projectNode = xpath.select1('Project', this.dom) as Element
      whNode = this.dom.createElement('Warehouse')
      projectNode.appendChild(whNode)
      // Insert the new whNode after the ProjectType node
      const projectTypeNode = xpath.select1('ProjectType', projectNode) as Element
      projectNode.insertBefore(whNode, projectTypeNode.nextSibling)
    }
    Object.entries(newTag).forEach(([key, value]) => {
      whNode.setAttribute(key, value)
    })
  }

  /**
   * Remove the Warehouse element from the project
   * This is a noop if the Warehouse element does not exist
   */
  removeWarehouseEl = (): void => {
    const whNode = xpath.select1(`Project/Warehouse`, this.dom) as Element
    if (whNode) {
      const projectNode = xpath.select1('Project', this.dom) as Element
      projectNode.removeChild(whNode)
    }
  }

  setProjectName = (newName: string): void => {
    const nameNode = xpath.select1('Project/Name', this.dom) as Element
    if (!newName || newName.trim().length < 3) throw new Error('Project name must be at least 3 characters')
    if (!nameNode) throw new Error('Could not find Name node')
    this.setNodeContent(nameNode, newName.trim())
  }

  setProjectDescription = (newDescription?: string): void => {
    const proj = xpath.select1('Project', this.dom) as Element
    this.setTextNode(proj as Element, 'Description', newDescription)
  }

  setProjectSummary = (newSummary?: string): void => {
    const proj = xpath.select1('Project', this.dom) as Element
    this.setTextNode(proj as Element, 'Summary', newSummary)
  }

  setProjectCitation = (newCitation?: string): void => {
    const proj = xpath.select1('Project', this.dom) as Element
    this.setTextNode(proj as Element, 'Citation', newCitation)
  }

  setProjectMeta = (newMeta: MetaData[]): void => {
    const projectNode = xpath.select1('Project', this.dom) as Element
    const metaParentNode = xpath.select1('MetaData', projectNode) as Element

    // Check for duplicate keys
    const newKeys = newMeta.map((d) => d.key)
    if (newKeys.length !== new Set(newKeys).size) {
      throw new Error(`Duplicate keys in metadata: ${newKeys.filter((v, i, a) => a.indexOf(v) !== i)}`)
    }

    // Remove all existing meta nodes
    const metaNodes = xpath.select('Meta', metaParentNode) as Element[]
    metaNodes.forEach((metaNode) => {
      metaParentNode.removeChild(metaNode)
    })

    // Add new meta nodes
    newMeta.forEach(({ key, value, ext, locked, type }) => {
      const metaNode = this.dom.createElement('Meta')
      if (!key || key.length === 0) throw new Error('MetaData key cannot be empty')
      if (!value || value.length === 0) throw new Error('MetaData value cannot be empty')
      metaNode.setAttribute('name', key)
      if (type && type.toLowerCase() !== 'string') metaNode.setAttribute('type', type.toLowerCase())
      if (ext) metaNode.setAttribute('ext', ext)
      if (locked) metaNode.setAttribute('locked', locked ? 'true' : 'false')

      this.setNodeContent(metaNode, value)
      metaParentNode.appendChild(metaNode)
    })
  }

  getWarehouseEl = (): WarehouseEl | null => {
    const whNode = xpath.select1('Project/Warehouse', this.dom) as Element
    if (!whNode) return null
    const attrNode = Array.from(whNode.attributes).reduce((acc, it) => ({ ...acc, [it.name]: it.value.trim() }), {})
    return attrNode as unknown as WarehouseEl
  }

  getProjectType = (): string | null => {
    const whNode = xpath.select1('Project/ProjectType', this.dom) as Element
    if (!whNode) return null
    return whNode.textContent.trim().toLowerCase()
  }

  getBoundsPath = (): string | null => {
    const nameNode = xpath.select1('Project/ProjectBounds', this.dom) as Node
    if (!nameNode) return null
    else return getChildNodeText('Path', nameNode)
  }

  getProjectMeta = (): MetaDataInput[] => getMetaFromNode(this.projNode)

  getProjectInput = (): ProjectInput => {
    const projectType = this.getProjectType()
    if (!projectType) throw new Error('Could not find ProjectType node')
    return {
      name: getChildNodeText('Name', this.projNode),
      summary: getChildNodeText('Summary', this.projNode),
      description: getChildNodeText('Description', this.projNode),
      citation: getChildNodeText('Citation', this.projNode),
      meta: getMetaFromNode(this.projNode),
      // No support for sponsor input yet
      // sponsor: getChildNodeText('Sponsor', this.dom),
      // boundsToken: "",
      datasets: this.getDatasetInputs(),
      qaqc: this.getQAQCEvents(),

      // heroImageToken: getChildNodeText('HeroImage', this.dom),

      // Tags, visibility and totalsize come from extyernal
      // tags
      // visibility
      // totalSize
    }
  }

  /**
   *
   * @returns
   *
   *
   */

  getQAQCEvents = (): QaqcEventInput[] => {
    const qaqcNode = xpath.select1('Project/QAQC', this.dom) as Element
    if (!qaqcNode) return []
    const qaqcEventNodes = xpath.select('QAQCEvents/QAQCEvent', qaqcNode) as Element[]
    return qaqcEventNodes.map<QaqcEventInput>((eventNode): QaqcEventInput => {
      const supportingLinks: Link[] = xpath.select('Links/URL', eventNode).map((linkNode) => ({
        text: (linkNode as Element).getAttribute('text'),
        href: getNodeText(linkNode as Node),
      }))
      return {
        name: getChildNodeText('Name', eventNode),
        datePerformed: getChildNodeAttr(eventNode, 'dateCreated'),
        state: getChildNodeAttr(eventNode, 'state') as QaqcStateEnum,
        summary: getChildNodeText('Summary', eventNode),
        performedBy: getChildNodeText('PerformedBy', eventNode),
        description: getChildNodeText('Description', eventNode),
        supportingLinks,
        meta: getMetaFromNode(eventNode),
      }
    })
  }
  getDatasetInputs = (): DatasetInput[] => {
    const rawDSXMLNodes = DSXpaths.reduce((acc, partPath) => [...acc, ...xpath.select(`//${partPath}`, this.dom)], [])

    const datasets: DatasetInput[] = rawDSXMLNodes.map<DatasetInput>((dsNode): DatasetInput => {
      const nameNode = xpath.select1('Name', dsNode) as Node
      if (!nameNode) {
        // Internal references don't register as individual datasets
        if (dsNode.tagName === 'CommonDatasetRef') return
        else throw new Error(`Could not find <Name> node in dataset: \n${dsNode.toString()}`)
      }

      if (Object.values(DatasetTypeEnum).indexOf(dsNode.tagName) < 0)
        throw new Error(`Dataset Type not found: ${dsNode.tagName}`)

      let dsLayers: DatasetLayerInput[] = []

      // Geopackages have sublayers
      if (dsNode.tagName === DatasetTypeEnum.Geopackage) {
        const layerNodes: Node[] = xpath.select('Layers/*', dsNode).map((el) => el as Node)
        dsLayers = layerNodes.map((lyrNode): DatasetLayerInput => {
          const lyrName = (lyrNode as Element).getAttribute('lyrName')
          return {
            name: getChildNodeText('Name', lyrNode),
            summary: getChildNodeText('Summary', lyrNode),
            description: getChildNodeText('Description', lyrNode),
            citation: getChildNodeText('Citation', lyrNode),
            meta: getMetaFromNode(lyrNode as Node),
            lyrName,
          }
        })
      }
      const rsXPath = getRSXPath(dsNode)
      const retVal: DatasetInput = {
        // xmlId: getChildNodeAttr(dsNode, 'id', false),
        name: getChildNodeText('Name', dsNode),
        summary: getChildNodeText('Summary', dsNode),
        description: getChildNodeText('Description', dsNode),
        citation: getChildNodeText('Citation', dsNode),

        meta: getMetaFromNode(dsNode as Node),
        layers: dsLayers,
        // Making use of
        //        extRef="badfe8c1-0342-4876-8fac-b2eb5493a90f:Project/REALIZATION1/SLOPE#thing
        extRef: getChildNodeAttr(dsNode, 'extRef'),
        rsXPath,
        localPath: getChildNodeText('Path', dsNode) || 'INVALIDPATH',
      }
      return retVal
    })
    const filteredDs = datasets.filter((ds) => Boolean(ds))

    return filteredDs
  }
}

interface mapFilesToDatasetReturn {
  filesMap: Record<string, string> | null
  errors: string[]
}
type RSFileMap = Record<string, string[]>
/**
 * Try to equate a list of files with datasets
 * @param files
 * @param datasets
 * @returns
 */

export const mapFilesToDataset = (fileList: string[], datasets: DatasetInput[]): mapFilesToDatasetReturn => {
  // first, find the direct matches
  // This is { [LOCALFILEPATH]: DSRSXPATH }
  const errors: string[] = []
  const filesMap: RSFileMap = fileList.reduce((acc, fpath) => ({ ...acc, [fpath]: [] }), {})
  const dsMap: RSFileMap = datasets.reduce((acc, ds) => ({ ...acc, [ds.rsXPath]: [] }), {})
  const datasetMap: Record<string, DatasetInput> = datasets.reduce((acc, ds) => ({ ...acc, [ds.rsXPath]: ds }), {})
  const datasetTypeMap: Record<string, RSXPath> = datasets.reduce(
    (acc, ds) => ({ ...acc, [ds.rsXPath]: rsXpath2Obj(ds.rsXPath) }),
    {}
  )
  const filesParsed = fileList
    .map((fpath) => {
      try {
        return localPathParse(fpath)
      } catch (err) {
        errors.push(`Path Error: ${err.message}`)
        return null
      }
    })
    .filter((val) => Boolean(val))

  const addToMap = (rsXPath: string, fPath: string) => {
    dsMap[rsXPath].push(fPath)
    filesMap[fPath].push(rsXPath)
  }

  // find all the direct matches between files and datasets.
  // Throw errors for any duplicates
  fileList.forEach((fPath, idf) => {
    // If this file failed to parse then skip
    if (!filesParsed[idf]) return
    // Create a map of which files use which datasets and another map vise versa
    datasets.forEach((ds) => {
      if (ds.localPath === fPath) addToMap(ds.rsXPath, fPath)
    })
  })

  // Now iterate over the datasets and try to find sidecar files
  datasets.forEach(({ rsXPath, localPath }) => {
    let fParseParent: FileParts
    try {
      fParseParent = localPathParse(localPath)
    } catch {
      // If we can't parse the dataset localPath then that's a bigger problem but we won't solve that here.
      return
    }
    const escapedNoExt = escapeRegExp(fParseParent.pathNoExt)
    const datasetType: DatasetTypeEnum = datasetTypeMap[rsXPath].datasetType

    const unmappedFiles = Object.keys(filesMap).filter((k) => filesMap[k].length === 0)

    // Vectors have sidecars
    const vectorTypes: DatasetTypeEnum[] = [DatasetTypeEnum.Vector]
    const rasterTypes: DatasetTypeEnum[] = [DatasetTypeEnum.Dem, DatasetTypeEnum.Raster, DatasetTypeEnum.HillShade]
    if (vectorTypes.includes(datasetType)) {
      if (fParseParent.extLower === 'shp') {
        const testReg = new RegExp(`${escapedNoExt}\\.(${DasetSidecarFiles.shp.join('|')})$`)
        unmappedFiles.forEach((testFile) => {
          if (testFile.match(testReg)) addToMap(rsXPath, testFile)
        })
      }
    }

    // Rasters have sidecars
    else if (rasterTypes.includes(datasetType)) {
      if (fParseParent.extLower === 'tif') {
        const testReg = new RegExp(`${escapedNoExt}\\.(${DasetSidecarFiles.tif.join('|')})$`)
        unmappedFiles.forEach((testFile) => {
          if (testFile.match(testReg)) addToMap(rsXPath, testFile)
        })
      }
      if (fParseParent.extLower === 'tiff') {
        const testReg = new RegExp(`${escapedNoExt}\\.(${DasetSidecarFiles.tiff.join('|')})$`)
        unmappedFiles.forEach((testFile) => {
          if (testFile.match(testReg)) addToMap(rsXPath, testFile)
        })
      }
    }
  })
  const tinTypes: DatasetTypeEnum[] = [DatasetTypeEnum.Tin]
  // Now, once more, finally we catch all the folder types (like TIN and FileGeoDB)
  datasets
    .filter(({ rsXPath }) => {
      const datasetType = datasetTypeMap[rsXPath].datasetType
      return tinTypes.includes(datasetType)
    })
    .forEach((ds) => {
      const testReg = new RegExp(`${escapeRegExp(ds.localPath)}/.*`)
      Object.keys(filesMap)
        .filter((k) => filesMap[k].length === 0)
        .forEach((testFile) => {
          // this is the inner path relative to the parent folder. Should account for subdirectories
          if (testFile.match(testReg)) addToMap(ds.rsXPath, testFile)
        })
    })

  // Now we can perform tests to see what's there, what's duplicated etc.
  // Test if there are datasets with no files at all or if the root file is missing
  Object.keys(dsMap).forEach((rsXPath) => {
    const fpArr = dsMap[rsXPath]
    const datasetType = datasetTypeMap[rsXPath].datasetType
    const isFolderType = tinTypes.includes(datasetType)

    // Datasets that have no files are not allowed
    if (fpArr.length === 0) errors.push(`Dataset in XML had no files: ${rsXPath}`)
    // If the dataset isn't a folder type like TIN then its <Path> tag must match a real file
    else if (!isFolderType && !fpArr.find((fp) => fp === datasetMap[rsXPath].localPath)) {
      errors.push(`XML Dataset missing file referenced by its <Path>: ${rsXPath} --> ${datasetMap[rsXPath].localPath}`)
    }
  })

  // NOTE: QRIS uses the same gpkg file in multiple realizations so we need to relax this check
  // Object.entries(filesMap).forEach(([fPath]) => {
  //   const dsArr = filesMap[fPath]
  //   if (dsArr.length > 1)
  //     errors.push(
  //       `${dsArr.length} datasets reference the same file. File: ${fPath} Datasets: ${JSON.stringify(dsArr)} )`
  //     )
  // })

  // Only return a valid map if there aren't errors
  if (errors.length > 0)
    return {
      errors: uniq(errors),
      filesMap: null,
    }

  return {
    filesMap: Object.keys(filesMap)
      .filter((fkey) => filesMap[fkey].length === 1)
      .reduce((acc, fmkey) => ({ ...acc, [fmkey]: filesMap[fmkey][0] }), {}),
    errors,
  }
}
