import React from 'react'
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AvatarImage, Menu, Profile, RsLogo } from '@riverscapes/react-common'
import { useAuth } from '@riverscapes/react-api'

import { SmartSearchField } from './SmartSearchField'
import { home, profileDetail, useGotoRoute, search } from '../routing'
import { AppVersion } from './AppVersion'
import { useProfile } from '../lib'
import { AboutDialog } from './AboutDialog'
import { ProfileDetailProps } from './ProfileDetail'
import { Dashboard, InfoRounded, Logout, Map } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export const Header: React.FC = () => {
  const profile = useProfile()
  const { hostedSignIn, signOut, isAuthenticated } = useAuth()
  const gotoProfile = useGotoRoute(profileDetail)
  const navigate = useNavigate()

  return (
    <HeaderInner
      gotoProfile={gotoProfile}
      profile={profile as Profile}
      homeUrl={home.getUrl({})}
      hostedSignIn={hostedSignIn}
      signOut={signOut}
      navigate={navigate}
      isAuthenticated={Boolean(isAuthenticated)}
    />
  )
}

export interface HeaderInnerProps {
  gotoProfile?: (props: ProfileDetailProps, replace?: boolean | undefined) => void
  homeUrl?: string
  profile?: Profile
  hostedSignIn?: () => void
  signOut?: () => void
  navigate?: (url: string) => void
  isAuthenticated?: boolean
  loading?: boolean
}

export const HeaderInner: React.FC<HeaderInnerProps> = ({
  gotoProfile,
  profile,
  homeUrl,
  navigate,
  hostedSignIn,
  signOut,
  isAuthenticated,
  loading,
}) => {
  const theme = useTheme()

  const exploreUrl = search.getUrl({ type: 'Project', params: {} })
  const showTitleText = useMediaQuery('(min-width: 800px)')
  const [isAboutOpen, setAboutOpen] = React.useState<boolean>(false)

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(${process.env.PUBLIC_URL + '/banner.jpg'})`,
          backgroundPosition: '80% 100%',
          backgroundSize: 'cover',
          padding: 1,
          gap: 2,
        }}
      >
        <Link href={homeUrl}>
          <Stack direction="row" alignItems="center" gap={1}>
            <RsLogo size={40} />
            {showTitleText && (
              <img src={`${process.env.PUBLIC_URL + '/data-exchange-title-white.svg'}`} style={{ height: 18 }} />
            )}
          </Stack>
        </Link>
        {!loading && <SmartSearchField />}
        <div style={{ marginLeft: 'auto' }} />
        <AppVersion />
        {!loading && !isAuthenticated && (
          <Button variant="contained" onClick={hostedSignIn} color="secondary">
            Sign In
          </Button>
        )}
        {homeUrl && navigate && (
          <Tooltip title="Dashboard" placement="bottom">
            <IconButton
              color="inherit"
              onClick={() => {
                navigate(homeUrl)
              }}
            >
              <Dashboard />
            </IconButton>
          </Tooltip>
        )}
        {isAuthenticated && navigate && (
          <Tooltip title="Map Search" placement="bottom">
            <IconButton
              color="inherit"
              onClick={() => {
                navigate(exploreUrl)
              }}
            >
              <Map />
            </IconButton>
          </Tooltip>
        )}
        {isAuthenticated &&
          (profile ? (
            <>
              <Menu
                Button={(props) => (
                  <>
                    {/* <Button
                      {...props}
                      color="inherit"
                      sx={{
                        maxWidth: 250,
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {profile.name}
                      </span>
                    </Button> */}
                    <IconButton {...props} size="small">
                      <AvatarImage size={40} item={profile} />
                    </IconButton>
                  </>
                )}
                options={[
                  {
                    key: 'profile',
                    onClick: () => gotoProfile && gotoProfile({ id: profile.id }),
                    label: (
                      <>
                        <ListItemIcon>
                          <AvatarImage size={30} item={profile} />
                        </ListItemIcon>
                        Profile: {profile.name}
                      </>
                    ),
                  },
                  <Divider key="divider2" />,
                  {
                    key: 'about',
                    label: (
                      <>
                        <ListItemIcon>
                          <InfoRounded fontSize="small" />
                        </ListItemIcon>
                        About Riverscapes Data Exchange
                      </>
                    ),
                    onClick: () => setAboutOpen(true),
                  },
                  {
                    key: 'signOut',
                    label: (
                      <>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Sign Out
                      </>
                    ),
                    onClick: signOut,
                  },
                ]}
              />
            </>
          ) : (
            <IconButton disabled size="small">
              <CircularProgress size={40} color="warning" />
            </IconButton>
          ))}
      </Stack>
      <AboutDialog open={isAboutOpen} onClose={() => setAboutOpen(false)} />
    </>
  )
}
