/** @jsxImportSource @emotion/react */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useState } from 'react'
import { LogicError } from '../../lib'
import { AutocompleteSelectField, AutocompleteSelectOption } from '../AutocompleteSelectField'
import { Box, useTheme } from '@mui/system'

export interface AddToCollectionDialogProps {
  getCollectionOptionsByName: (name: string) => Promise<AutocompleteSelectOption[]>
  onChoose: (collectionId: string) => void
  onClose: () => void
  open: boolean
  projectName: string
}

export const AddToCollectionDialog: React.FC<AddToCollectionDialogProps> = ({
  getCollectionOptionsByName,
  onChoose,
  onClose,
  open,
  projectName,
}) => {
  const theme = useTheme()
  const [collectionId, setCollectionId] = useState<string | null>(null)

  const handleChoose = () => {
    if (!collectionId) throw new LogicError()
    onChoose(collectionId)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          mb: 2,
        }}
      >
        Add {projectName} to Collection
      </DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <AutocompleteSelectField
          placeholder="search for collection..."
          noOptionsText="No collections found"
          onChange={setCollectionId}
          value={collectionId}
          getOptionsFromText={getCollectionOptionsByName}
        />
        <Typography variant="body2" mt={1}>
          Note: If collection already contains the project no changes will be made.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleChoose} disabled={!collectionId}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
