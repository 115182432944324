import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <React.Fragment>
    <path d="M22,2l0,20l-20,0l0,-20l20,0Zm-2,2l-16,0l0,16l16,0l0,-16Z" />
    <rect x="5" y="5" width="4" height="4" />
    <rect x="15" y="5" width="4" height="4" />
    <rect x="5" y="15" width="4" height="4" />
    <rect x="10" y="10" width="1" height="5" />
    <rect x="11" y="10" width="6" height="2" />
    <rect x="11" y="12" width="2" height="2" />
    <rect x="13" y="7" width="1" height="2" />
    <rect x="14" y="9" width="1" height="1" />
    <rect x="8" y="10" width="1" height="4" />
    <rect x="7" y="11" width="1" height="3" />
    <rect x="9" y="11" width="1" height="1" />
    <rect x="10" y="6" width="2" height="3" />
    <rect x="12" y="7" width="1" height="1" />
    <rect x="15" y="14" width="2" height="4" />
    <rect x="14" y="14" width="1" height="1" />
    <rect x="17" y="15" width="2" height="2" />
    <rect x="11" y="16" width="1" height="3" />
    <rect x="12" y="15" width="2" height="2" />
    <rect x="10" y="18" width="1" height="1" />
  </React.Fragment>,
  'QRCode'
)
