import { SxProps, Theme, Typography, useTheme } from '@mui/material'
import { TreeItem } from '@mui/lab'
import React from 'react'

import TreeNodeLabel from './TreeNodeLabel'

export interface TreeBranchProps {
  id: number
  label: string
  hidden?: boolean
  searchText?: string
  isRoot?: boolean
  children?: React.ReactNode[]
}

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  branch: {
    mt: 0.5,
  },
  innerLabel: {
    fontSize: '0.8rem',
  },
})

export const TreeBranch: React.FC<TreeBranchProps> = ({
  id,
  hidden,
  label,
  searchText,
  children,
  isRoot,
}: TreeBranchProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  return (
    <TreeItem
      // this is IMPORTANT! 'b-##' denotes a branch
      nodeId={`b-${id}`}
      sx={styles.branch}
      onDoubleClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onContextMenu={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      hidden={hidden}
      label={
        <Typography variant="body1" sx={styles.innerLabel}>
          <TreeNodeLabel text={isRoot ? 'Project' : label} query={searchText} />
        </Typography>
      }
    >
      {children}
    </TreeItem>
  )
}

export default TreeBranch
