import { ReactElement, useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { identity, lowerCase } from 'lodash'

import { Lookups } from '../lookups'
import { useFormats } from '../../lib'
import { DateWithinEnum, SearchSpec } from './types'
import { AutocompleteSelectField } from '../AutocompleteSelectField'
import { Search } from '@mui/icons-material'
import { GetCollectionDetailQuery, OwnerInputTypesEnum } from '../../schema/base'
import { useNotifications } from '../AppNotifications'

export type SearchSpecExplainerProps = SearchSpec & {
  lookups: Lookups
  loading?: boolean
  collection?: GetCollectionDetailQuery['collection']
  onSearchChange: (newSearch: SearchSpec) => void
  onOpenModify: () => void
  resultsCount?: number
}

const InactiveSpan: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        margin: '0.1em',
        position: 'relative',
        border: '1px solid transparent',
      }}
    >
      {children}
    </span>
  )
}

export const SearchSpecExplainer: React.FC<SearchSpecExplainerProps> = ({
  type,
  params,
  lookups,
  loading,
  collection,
  onSearchChange,
  onOpenModify,
  resultsCount,
}) => {
  const { pluralize } = useFormats()
  const { catchError } = useNotifications()

  const { getProjectTypeOptionById, getProjectTypeOptionsByName, getOrganizationOptionById, getUserOptionById } =
    lookups

  const [ownedByName, setOwnedByName] = useState<string | undefined>()

  const itemDescriptor = lowerCase(type)

  const { createdOn, keywords, meta, name, ownedBy, tags, updatedOn } = params

  const hasCollection = type === 'Project' && !!params.collection

  const hasBoundsId = type === 'Project' && !!params.boundsId

  useEffect(() => {
    if (!ownedBy) return
    const fetchOwnedByName = async () => {
      setOwnedByName('loading')
      const collectionName =
        ownedBy.type === OwnerInputTypesEnum.Organization
          ? await getOrganizationOptionById(ownedBy.id).then((option) => option?.text)
          : await getUserOptionById(ownedBy.id).then((option) => option?.text)
      if (!collectionName) {
        catchError('Failed to find collection', true)('msg')
        return
      }
      setOwnedByName(collectionName)
    }

    fetchOwnedByName()
  }, [ownedBy])

  const advancedCriteriaCount = [
    createdOn,
    hasBoundsId,
    hasCollection,
    keywords,
    meta,
    name,
    ownedBy,
    tags,
    updatedOn,
  ].reduce((acc, value) => acc + (value ? 1 : 0), 0)

  const hasAnyCriteria = !!Object.values(params).filter(identity).length

  const explainer: ReactElement[] = []

  // The basic explainer is the same for all types and just enumerates the number of results
  if (!hasAnyCriteria)
    explainer.push(
      <InactiveSpan key="partOne">
        Exploring all <strong>{pluralize(itemDescriptor, resultsCount, true)}</strong>
      </InactiveSpan>
    )
  else
    explainer.push(
      typeof resultsCount === 'undefined' ? (
        <InactiveSpan key="partOne">Searching {pluralize(itemDescriptor)}</InactiveSpan>
      ) : (
        <InactiveSpan key="partOne">
          Found <strong>{pluralize(itemDescriptor, resultsCount, true)}</strong>
        </InactiveSpan>
      )
    )
  if (collection) {
    const collectionUrl = lookups.getCollectionUrlById(collection.id as string)
    explainer.push(
      <InactiveSpan key="partTwo">
        {' '}
        in collection:{' '}
        <strong>
          <Tooltip title={`Go to collection: ${collection.name}`}>
            <Link href={collectionUrl}>{collection.name}</Link>
          </Tooltip>
        </strong>
      </InactiveSpan>
    )
  }
  if (ownedBy) {
    const ownedByTypeName = ownedBy.type === OwnerInputTypesEnum.Organization ? 'organization' : 'user'
    const ownedByUrl =
      ownedBy.type === OwnerInputTypesEnum.Organization
        ? lookups.getOrganizationUrlById(ownedBy.id)
        : lookups.getUserUrlById(ownedBy.id)
    explainer.push(
      <InactiveSpan key="partFour">
        {' '}
        owned by {ownedByTypeName}:{' '}
        <strong>
          <Tooltip title={`Go to ${ownedByName}'s profile`}>
            <Link href={ownedByUrl}>{ownedByName}</Link>
          </Tooltip>
        </strong>
      </InactiveSpan>
    )
  }
  if (hasBoundsId) explainer.push(<InactiveSpan key="partThree"> within specified bounds</InactiveSpan>)

  return (
    <Stack flex={1} direction="row" gap="1em" alignItems="center">
      {loading ? (
        <Typography variant="h6">Loading Results...</Typography>
      ) : (
        <Typography variant="h6">{explainer}</Typography>
      )}
      <Box sx={{ flex: 1 }} />
      {type === 'Project' && (
        <Box sx={{ width: '20em' }}>
          <AutocompleteSelectField
            label="Project Type"
            value={params.projectTypeId ?? null}
            getOptionFromValue={getProjectTypeOptionById}
            getOptionsFromText={getProjectTypeOptionsByName}
            onChange={(nextValue) => {
              const nextValueId = nextValue || null
              const prevValue = params.projectTypeId || null
              if (nextValueId === prevValue) return
              onSearchChange({ type, params: { ...params, projectTypeId: nextValue } })
            }}
          />
        </Box>
      )}
      <FormControl sx={{ width: '10em' }} size="small">
        <InputLabel>Created Within</InputLabel>
        <Select
          size="small"
          value={params.createdWithin ?? ''}
          label="Created Within"
          onChange={(e) => {
            onSearchChange({
              type,
              params: {
                ...params,
                createdWithin: e.target.value as typeof params.createdWithin,
              },
            })
          }}
        >
          <MenuItem value={''}>
            <em>any</em>
          </MenuItem>
          <MenuItem value={DateWithinEnum.ONE_DAY}>last day</MenuItem>
          <MenuItem value={DateWithinEnum.ONE_WEEK}>last week</MenuItem>
          <MenuItem value={DateWithinEnum.ONE_MONTH}>last month</MenuItem>
          <MenuItem value={DateWithinEnum.SIX_MONTHS}>last 6 months</MenuItem>
        </Select>
      </FormControl>
      {type === 'Project' && (
        <FormControlLabel
          control={
            <Switch
              checked={!!params.bounded}
              onChange={(e) => onSearchChange({ type, params: { ...params, bounded: e.target.checked } })}
            />
          }
          label="Limit To Map"
        />
      )}
      <Badge badgeContent={advancedCriteriaCount} color="secondary">
        <Button onClick={onOpenModify} variant="contained" startIcon={<Search />}>
          Modify Search
        </Button>
      </Badge>
    </Stack>
  )
}
