/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  useTheme,
  Box,
  Typography,
} from '@mui/material'
import { someValues, useFields, validate } from '../../lib'
import { noop } from 'lodash'

export interface CreateOrganizationDialogProps {
  disableAll?: boolean
  onClose: () => void
  onConfirm: (properties: { name: string }) => void
  open: boolean
}

export const CreateOrganizationDialog: React.FC<CreateOrganizationDialogProps> = ({
  disableAll,
  onClose,
  onConfirm,
  open,
}) => {
  const theme = useTheme()
  const [fields, setFields, preparedFields] = useFields(
    {
      name: '',
    },
    ({ name }) => ({
      name: name.trim(),
    }),
    [open]
  )

  const errors = someValues({
    name: validate.name(preparedFields.name),
  })

  const handleConfirm = () => {
    onConfirm(preparedFields)
  }

  const handleClose = disableAll ? noop : onClose

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
          mb: 3,
        }}
      >
        Create Organization
      </DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Stack direction="column" gap={2}>
          <TextField
            autoFocus
            sx={{ mt: 1 }}
            label="Name"
            onChange={({ target: { value } }) => setFields.$.name(value)}
            value={fields.name}
            error={errors?.name}
            disabled={disableAll}
          />
        </Stack>
        <Typography variant="body2" mt={1} paragraph>
          An organization is a group of users that can share projects and collections. Users that are members of an
          organization can have one of 4 roles: Owner, Admin, Contributor, Viewer
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={disableAll} color="error">
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleConfirm} disabled={!!errors || disableAll}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
