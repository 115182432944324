import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import ReactMapGL, { ViewState, NavigationControl, ViewStateChangeEvent, MapboxStyle } from 'react-map-gl'
import MapLoading from './MapLoading'
import { geoFence } from '../../lib/util'
import { createInitialStyle } from '../../lib/MapManager'
import { viewportAtom, mapLoadedAtom, mapSizeAtom } from '../../recoil/map'
import { BaseLayer, BoundsObj } from '../../types'

import 'mapbox-gl/dist/mapbox-gl.css'

import _ from 'lodash'
import log from 'loglevel'
import { Box, CircularProgress, SxProps, Theme, useTheme } from '@mui/material'

interface MapProps {
  baseLayer: BaseLayer
  styleLoading?: boolean
  bounds: BoundsObj | null
  zoomBounds: [minZoom: number, maxZoom: number]
  children?: React.ReactNode | React.ReactNode[]
}

const navControlStyle = {
  left: 15,
  top: 60,
}

const mapboxToken = process.env.STORYBOOK_MAPBOX_TOKEN || process.env.REACT_APP_MAPBOX_TOKEN || ''

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    ml: theme.spacing(1),
  },
  container: {
    display: 'flex',
    height: '100%',
  },
  mapContainer: {
    position: 'relative',
    background: '#CCCCCC',
    height: '100%',
    width: '100%',
    '&>h1': {
      color: '#9999FF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& .mapboxgl-ctrl-top-right': {
      zIndex: '0 !important', // This is 1000 by default
      position: 'absolute',
      pointerEvents: 'none',
      right: 'initial',
      top: theme.spacing(6),
      left: theme.spacing(2),
    },
    '& .mapboxgl-ctrl-bottom-left': {
      zIndex: '0 !important', // This is 1000 by default
      position: 'absolute',
      pointerEvents: 'none',
      left: 'initial',
      right: theme.spacing(2),
      bottom: theme.spacing(3),
    },
  },
  drawerContents: {
    // border: '1px solid green',
    height: '100%',
    display: 'flex',
  },
  drawerSpacer: {
    flex: '0 0 0%',
  },
  drawerSpacerOpen: {
    // flex: `0 0 ${DRAWER_WIDTH}`
  },
  dialogContainer: {
    alignItems: 'left',
    justifyContent: 'left',
  },
  loaderSpinner: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    color: theme.palette.secondary.main,
  },
  dialog: {
    position: 'absolute',
    margin: 0,
    left: '20px',
    top: '20px',
  },
  subheaders: {
    background: 'white',
  },
  dummyText: {},
})

const debounceConsoleLog = _.debounce((...vals) => log.debug(...vals), 1500)

const Map: React.FC<MapProps> = ({ children, baseLayer, styleLoading, bounds, zoomBounds }: MapProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const [viewState, _setViewState] = useRecoilState(viewportAtom)
  const [boundsSet, setBoundsSet] = useState<boolean>(false)
  const setMapSize = useSetRecoilState(mapSizeAtom)
  const [mapLoaded, setMapLoaded] = useRecoilState(mapLoadedAtom)

  const viewStateRef = useRef<ViewState>()
  viewStateRef.current = viewState

  const loadHandler = useCallback(
    (e) => {
      const styleLoaded = e.target.isStyleLoaded()
      log.debug('Map::Map Loaded', styleLoaded)
      handleResize(e)
      if (mapLoaded !== styleLoaded) setMapLoaded(styleLoaded)
    },
    [mapLoaded]
  )

  const handleResize = (event: mapboxgl.MapboxEvent<undefined>) => {
    const { width, height } = event.target.getCanvas()
    log.debug('Map::resize map', width, height)
    setMapSize(width > 0 && height > 0 ? { width, height } : null)
  }

  const handleMove = (event: ViewStateChangeEvent) => {
    setViewState(event.viewState)
  }

  // Constrain the Bounds and zoom
  const setViewState = useCallback(
    (inV: ViewState) => {
      const outV = { ...viewStateRef.current, ...inV }
      // Constrict bounds
      if (zoomBounds && outV.zoom) {
        if (outV.zoom > zoomBounds[1]) {
          outV.zoom = zoomBounds[1]
        } else if (outV.zoom < zoomBounds[0]) {
          outV.zoom = zoomBounds[0]
        }
      }
      const geoFenceCorrect = geoFence(
        [outV.longitude, outV.latitude] as [number, number],
        // Bounds is an object or fall back to the world fence
        bounds,
        // We center the world if we need to
        Boolean(bounds && !boundsSet)
      )

      // If we're out of bounds then bring us back
      if (Array.isArray(geoFenceCorrect)) {
        outV.longitude = geoFenceCorrect[0]
        outV.latitude = geoFenceCorrect[1]
      }

      if (bounds && !boundsSet) setBoundsSet(true)

      debounceConsoleLog('setViewState DEBOUNCED', { inV, outV, bounds, center: bounds && !boundsSet })
      _setViewState(outV)
    },
    [bounds, zoomBounds, boundsSet]
  )

  // Just the first time we offer a default style
  const baseStyle = useMemo(() => createInitialStyle(baseLayer), [])

  // When loading we may get an initial zoom and/or bounds value
  useEffect(() => {
    debounceConsoleLog('setViewState useEffect', { bounds, zoomBounds, boundsSet })
    setViewState(viewStateRef.current as ViewState)
  }, [bounds, zoomBounds, boundsSet])

  const mapLoader = styleLoading && <CircularProgress size="2rem" thickness={6} sx={styles.loaderSpinner} />

  const onError = (e) => {
    // Hide those annoying non-error errors. Cloudfront hides 404 errors behind 403
    if (!e.error || !e.error.status || e.error.status < 400 || e.error.status > 500) {
      console.error('MAP ERROR', e)
    }
  }

  if (!mapboxToken) return <div>No Mapbox token found.</div>
  // NO HOOKS BELOW HERE
  if (!baseStyle || !bounds) return <MapLoading />

  return (
    <Box sx={styles.mapContainer}>
      <ReactMapGL
        // minZoom={zoomBounds[0] || 4}
        // maxZoom={zoomBounds[1] || 20}
        clickTolerance={2}
        onLoad={loadHandler}
        onMove={handleMove}
        onResize={handleResize}
        onError={onError}
        mapStyle={baseStyle as MapboxStyle}
        mapboxAccessToken={mapboxToken}
        dragRotate={false}
        pitchWithRotate={false}
        touchZoomRotate={false}
        touchPitch={false}
        interactive={true}
        {...viewState}
      >
        <NavigationControl style={navControlStyle} />
        {mapLoader}
        {children}
        {/* DEBUG ONLY FOR SHOWING THE BOUNDS */}
        {/* <BoundsDebugRect bounds={bounds} /> */}
      </ReactMapGL>
    </Box>
  )
}

export default Map
