import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Bbox,
  BboxRect,
  Bounds,
  Clusters,
  GetSearchMapBoundsQuery,
  GetSearchMapClustersQuery,
  InteractiveMapController,
  LinearProgress,
  LogicError,
  Lookups,
  Points,
  ProjectResultsProps,
  ProjectsMapPopup,
  ProjectsMapPopupProps,
  expandBbox,
  getAllHashes,
  getTargetPrecision,
  pendingItems,
  useNotifications,
  BoundsHighlight,
  ProjectSearchParamsInput,
} from '@riverscapes/react-common'
import produce from 'immer'
import { isEqual, sortBy } from 'lodash'
import {
  cache,
  GetProjectSearchResultsDocument,
  useGetProjectSearchResultsImperativeQuery,
  useGetSearchMapBoundsQuery,
  useGetSearchMapClustersQuery,
} from '../../data'
import { area, polygon } from '@turf/turf'
import { extractItem } from '../../lib'
import geohash from 'ngeohash'
import log from 'loglevel'
import Supercluster from 'supercluster'
import numeral from 'numeral'

const EMPTY_ARRAY = []

const BOUNDS_LIMIT = 100
const CLUSTER_LIMIT = 600

export type UseProjectsMapParams = {
  params: ProjectSearchParamsInput
  overrides?: {
    precision?: number
    skipQueries?: boolean
    mode?: 'auto' | 'clusters' | 'bounds'
    suppressClientCluster?: boolean
    clusterRadius?: number
    showClustersBbox?: boolean
    showBoundsBbox?: boolean
    showClustersGrid?: boolean
  }
  bbox: Bbox | undefined
  getProjectBoundsThumbProps: ProjectsMapPopupProps['getProjectBoundsThumbProps']
  highlightBoundsIds: string[]
  lookups: Lookups
  mapBoxRef: React.RefObject<HTMLDivElement>
  mapController: InteractiveMapController
}

export type ProjectsMapState = {
  bounds: {
    id: string
    count: number
    polygonUrl: string
    bbox: Bbox
    area: number
  }[]
  clusters: {
    id: string
    hash: string
    coords: number[]
    count: number
    bbox?: Bbox
  }[]
  info: {
    clusters: {
      precision: number
      count: number
      possibleCount: number
      remaining: number
      projects: number
      loading: boolean
      bbox?: Bbox
      radius: number
      // time: number
    }
    bounds: {
      count: number
      remaining: number
      loading: boolean
      bbox?: Bbox
      //  time: number
    }
    isBoundsMode: boolean
  }
  handleMapHover: ProjectResultsProps['onMapHover']
  handleMapClick: ProjectResultsProps['onMapClick']
  popupState: {
    x: number
    y: number
    ids: string[]
    open: boolean
    sticky: boolean
  }
  mapElements: React.ReactNode
}

const aggregateClustersToFeatureCollection = ({
  clusters,
  bbox,
  zoom,
  suppress,
  radius,
}: {
  clusters: ProjectsMapState['clusters']
  bbox: Bbox
  zoom: number
  suppress?: boolean
  radius: number
}) => {
  if (!bbox) return undefined

  const features = clusters.map((clusterProperties) => {
    const { coords: coordinates } = clusterProperties
    const feature = {
      type: 'Feature' as const,
      geometry: {
        type: 'Point' as const,
        coordinates,
      },
      properties: { ...clusterProperties, ids: clusterProperties.id },
    }
    return feature
  })

  if (suppress)
    return {
      type: 'FeatureCollection' as const,
      features,
    }

  const supercluster = new Supercluster<
    (typeof features)[number]['properties'],
    (typeof features)[number]['properties']
  >({
    radius,
    map: (properties) => ({ ...properties }),
    reduce: (accumulated, properties) => {
      accumulated.count += properties.count
      accumulated.ids = [accumulated.ids, properties.id].join(',') // only primitives are allowed in properties so we use a comma-delimited string for IDs
    },
  })

  supercluster.load(features)

  const clusterFeatures = supercluster.getClusters(bbox, zoom)
  // Now just add a friendly number formatter
  clusterFeatures.forEach((feature) => {
    feature.properties['countStr'] = numeral(feature.properties.count).format('0,0')
  })

  return {
    type: 'FeatureCollection' as const,
    features: clusterFeatures,
  }
}

export const useProjectsMap = ({
  params,
  overrides,
  mapBoxRef,
  lookups,
  mapController,
  bbox,
  getProjectBoundsThumbProps,
  highlightBoundsIds,
}: UseProjectsMapParams): ProjectsMapState => {
  const [statefulInfo, setStatefulInfo] = useState({
    clusters: { precision: 0, possibleCount: 0, remaining: 0, bbox: undefined as Bbox | undefined, zoom: 0 },
    bounds: { remaining: 0, bbox: undefined as Bbox | undefined },
  })

  const { catchError } = useNotifications()

  // STATE

  const [fetchedClusters, setFetchedClusters] =
    useState<GetSearchMapClustersQuery['searchMapClusters']['clusters']>(EMPTY_ARRAY)
  const [fetchedBounds, setFetchedBounds] = useState<GetSearchMapBoundsQuery['searchMapBounds']['bounds']>(EMPTY_ARRAY)
  // const [isBoundsMode, setIsBoundsMode] = useState(false)

  const clustersBbox = useMemo(() => {
    if (!bbox) return undefined
    return expandBbox(bbox, 0) // how much to expand bbox for clusters (already getting clusters outside of bounds so trying 0, i.e. no expansion)
  }, [bbox])

  // PARAMS

  const { bbox: _discardedBbox, ...paramsWithoutBbox } = params

  // CLUSTERS

  // precision calculation
  const [precision, maxClustersAtPrecision] = useMemo(() => {
    if (!clustersBbox) return [0, 0]
    if (overrides?.precision) return [overrides.precision, getAllHashes(clustersBbox, overrides.precision).length]
    return getTargetPrecision(clustersBbox, CLUSTER_LIMIT)
  }, [bbox, overrides?.precision])

  // const [gridWidth, gridHeight, clusterRadius] = useMemo(() => {
  //   if (!bbox || !mapBoxRef.current) return [0, 0, 0]
  //   const { width, height } = mapBoxRef.current.getBoundingClientRect()
  //   const [gridLng, gridLat] = getHashGridSize(bbox, precision)
  //   const w = width * gridLng
  //   const h = height * gridLat
  //   return [w, h, Math.max(w, h) * 1]
  // }, [bbox, mapBoxRef.current, precision])

  // query clusters
  const { data: clustersData, loading: clustersLoading } = useGetSearchMapClustersQuery({
    variables: {
      params: {
        ...paramsWithoutBbox,
        bbox: clustersBbox, // query does not work without this but due to skip condition this will always be present for query
      },
      limit: CLUSTER_LIMIT,
      precision,
    },
    skip: !clustersBbox || overrides?.skipQueries,
    onError: catchError('Failed to get map clusters', false),
    // NECESSARY TO AVOID CACHE BUILDUP. THIS IS USED IN CONJUNCTION WITH keyArgs: false inside cache.ts
    fetchPolicy: 'network-only',
    // onCompleted: () => {
    //   setLastFetchedPrecision(precision)
    // },
  })

  // CLUSTER RADIUS

  // clusters grid
  const clustersGrid = useMemo(() => {
    if (!overrides?.showClustersGrid || !statefulInfo.clusters.bbox) return undefined
    return getAllHashes(statefulInfo.clusters.bbox, statefulInfo.clusters.precision).map((hash) => {
      const { longitude, latitude } = geohash.decode(hash)
      return { id: hash, coords: [longitude, latitude] }
    })
  }, [overrides?.showClustersGrid, statefulInfo.clusters.bbox, statefulInfo.clusters.precision])

  useEffect(() => {
    // THIS SECTION WIP

    if (!statefulInfo.clusters.bbox) return

    const [minLng, minLat, maxLng, maxLat] = statefulInfo.clusters.bbox
    const swHash = geohash.encode(minLat, minLng, statefulInfo.clusters.precision)
    const sw = geohash.decode(swHash)
    const nsw = geohash.decode(geohash.neighbor(swHash, [1, 0]))
    const esw = geohash.decode(geohash.neighbor(swHash, [0, 1]))

    const neHash = geohash.encode(maxLat, maxLng, statefulInfo.clusters.precision)
    const ne = geohash.decode(neHash)
    const sne = geohash.decode(geohash.neighbor(neHash, [-1, 0]))
    const wne = geohash.decode(geohash.neighbor(neHash, [0, -1]))

    mapController.mapReady.then((mapRef) => {
      const swX = mapRef.project([esw.longitude, esw.latitude]).x - mapRef.project([sw.longitude, sw.latitude]).x
      const swY = mapRef.project([sw.longitude, sw.latitude]).y - mapRef.project([nsw.longitude, nsw.latitude]).y
      const neX = mapRef.project([ne.longitude, ne.latitude]).x - mapRef.project([wne.longitude, wne.latitude]).x
      const neY = mapRef.project([sne.longitude, sne.latitude]).y - mapRef.project([ne.longitude, ne.latitude]).y
      const potentialMinDistance = Math.min(Math.max(swX, neX), Math.max(swY, neY))
      log.debug('grid potential min distance:', potentialMinDistance, 'zoom:', statefulInfo.clusters.zoom)
    })
  }, [statefulInfo.clusters.bbox, statefulInfo.clusters.precision])

  // reset clusters on search change
  useEffect(() => {
    setFetchedClusters(EMPTY_ARRAY)
  }, [JSON.stringify(paramsWithoutBbox)])

  // process results
  useEffect(() => {
    setStatefulInfo(
      produce((draft) => {
        draft.clusters.precision = precision
        draft.clusters.possibleCount = maxClustersAtPrecision
        draft.clusters.bbox = clustersBbox
        draft.clusters.zoom = mapController.viewState.zoom
      })
    )
    if (!clustersData) return
    setFetchedClusters(clustersData.searchMapClusters.clusters)
    setStatefulInfo(
      produce((draft) => {
        draft.clusters.remaining = clustersData.searchMapClusters.remaining
      })
    )
  }, [clustersData])

  // BOUNDS

  // query bounds
  const { data: boundsData, loading: boundsLoading } = useGetSearchMapBoundsQuery({
    variables: {
      limit: BOUNDS_LIMIT,
      params: {
        ...paramsWithoutBbox,
        bbox, // query does not work without this but due to skip condition this will always be present for query
      },
    },
    // NECESSARY TO AVOID CACHE BUILDUP. THIS IS USED IN CONJUNCTION WITH keyArgs: false inside cache.ts
    fetchPolicy: 'network-only',
    skip: !bbox || overrides?.skipQueries,
    onError: catchError('Failed to get map polygons', false),
  })

  // reset bounds on search change (while ignoring boundsId for better UX)
  useEffect(() => {
    setFetchedBounds(EMPTY_ARRAY)
  }, [JSON.stringify({ ...paramsWithoutBbox, boundsId: undefined })])

  // set fetched bounds
  useEffect(() => {
    setStatefulInfo(
      produce((draft) => {
        draft.bounds.bbox = bbox
      })
    )
    if (!boundsData) return

    setStatefulInfo(
      produce((draft) => {
        draft.bounds.remaining = boundsData.searchMapBounds.remaining
      })
    )
    setFetchedBounds(boundsData.searchMapBounds.bounds)
  }, [boundsData])

  // OUTPUT CLUSTERS/BOUNDS

  // determine if bounds mode
  // useEffect(() => {
  //   switch (overrides?.mode) {
  //     case 'bounds':
  //       setIsBoundsMode(true)
  //       return
  //     case 'clusters':
  //       setIsBoundsMode(false)
  //       return
  //   }
  //   if (fetchedBounds.length > 0 && fetchedBounds.length < BOUNDS_LIMIT) {
  //     setIsBoundsMode(true)
  //   } else {
  //     setIsBoundsMode(false)
  //   }
  // }, [fetchedBounds, fetchedClusters, overrides?.mode])

  // produce output clusters and bounds for rendering
  const { clusters, bounds, isBoundsMode } = useMemo(() => {
    const nextIsBoundsMode =
      overrides?.mode === 'bounds' || (fetchedBounds.length > 0 && fetchedBounds.length < BOUNDS_LIMIT)

    if (!nextIsBoundsMode) {
      // CLUSTER MODE
      const clusters: ProjectsMapState['clusters'] = fetchedClusters.map(({ hash, coords, count }) => ({
        id: hash,
        hash,
        coords,
        count,
      }))

      return {
        clusters,
        isBoundsMode: nextIsBoundsMode,
        bounds: EMPTY_ARRAY,
      }
    }

    // BOUNDS MODE
    if (!bbox) throw new LogicError()
    const clientBounds: ProjectsMapState['bounds'] = []
    const clientClusters: ProjectsMapState['clusters'] = []

    const [minLng, minLat, maxLng, maxLat] = bbox
    const bboxArea = area(
      polygon([
        [
          [minLng, minLat],
          [minLng, maxLat],
          [maxLng, maxLat],
          [maxLng, minLat],
          [minLng, minLat],
        ],
      ])
    ) // m2
    const thresholdArea = bboxArea * 0.001 // when area is less than 1/1000th of map area, convert to cluster

    fetchedBounds.forEach(({ bounds, projectCount }) => {
      const { id, centroid, polygonUrl, area, geoHash, bbox } = bounds
      if (!area) return
      if (area < thresholdArea) {
        if (!geoHash) throw new Error('missing geoHash')
        clientClusters.push({ coords: centroid, count: projectCount, id, bbox: bbox as Bbox, hash: geoHash })
      } else {
        clientBounds.push({ id, polygonUrl, count: projectCount, bbox: bbox as Bbox, area })
      }
    })

    return {
      clusters: clientClusters,
      isBoundsMode: nextIsBoundsMode,
      bounds: sortBy(clientBounds, ({ area }) => area).reverse(), // sort largest to smallest so smallest is rendered last
    }
  }, [fetchedBounds, fetchedClusters, overrides?.mode])

  const { clustersFeatureCollection, clusterRadius } = useMemo(() => {
    const clusterRadius = (() => {
      if (overrides?.clusterRadius) return overrides.clusterRadius
      if (isBoundsMode) return 30 // always return small radius when any bounds visible
      if (!mapBoxRef.current) return 0 // element not mounted yet
      const { width, height } = mapBoxRef.current.getBoundingClientRect()
      return Math.floor(Math.max(width, height) * 0.12) // take greater of width and height, use proportion of that as radius (first step to avoid gridding and provide a reasonable quantity)
    })()

    const clustersFeatureCollection =
      bbox &&
      aggregateClustersToFeatureCollection({
        clusters,
        bbox,
        zoom: mapController.viewState.zoom,
        radius: clusterRadius,
        suppress: overrides?.suppressClientCluster,
      })

    // Add in a formatter for the numbers

    return {
      clusterRadius,
      clustersFeatureCollection,
    }
  }, [clusters, isBoundsMode, overrides?.suppressClientCluster, overrides?.clusterRadius])

  // POPUP

  // popup state
  const [popupState, setPopupState] = useState<ProjectsMapState['popupState']>({
    x: 0,
    y: 0,
    ids: [],
    open: false,
    sticky: false,
  })

  // popup contents
  const [targetGroups, setTargetGroups] = useState<ProjectsMapPopupProps['groups']>([])
  const getProjectSearchResults = useGetProjectSearchResultsImperativeQuery()
  useEffect(() => {
    if (!popupState.ids.length) return
    let cancel = false
    const targetBounds = sortBy(
      bounds.filter(({ id }) => popupState.ids.includes(id)),
      ({ area }) => area
    )
    setTargetGroups(
      targetBounds.map(({ id, count, polygonUrl, bbox }) => ({
        bounds: { id, polygonUrl, bbox },
        items: pendingItems(count), // set to all pending
      }))
    )
    targetBounds.forEach(async ({ id }, index) => {
      const variables = {
        limit: 100, // TODO: need to handle excess
        offset: 0,
        params: {
          ...paramsWithoutBbox,
          boundsId: id,
        },
      }

      if (!cache.readQuery({ query: GetProjectSearchResultsDocument, variables }))
        await new Promise((resolve) => window.setTimeout(resolve, 350)) // effectively debouncing hover without losing immediate hover response or showing cached data

      if (cancel) return
      const targetResults = await getProjectSearchResults({ variables })

      if (cancel) return
      const projects = targetResults.searchProjects.results.map(extractItem)
      setTargetGroups(
        produce((draft) => {
          draft[index].items = projects
        })
      )
    })
    return () => {
      cancel = true
    }
  }, [popupState.ids, bounds])

  // close popup when mouse leaves map area
  useEffect(() => {
    if (!mapBoxRef.current) return
    const handleMouseLeave = () => {
      // Comment this out to leave the popup open for styling
      setPopupState(
        produce((draft) => {
          draft.open = false
          draft.sticky = false
          draft.ids = EMPTY_ARRAY
        })
      )
    }
    mapBoxRef.current.addEventListener('mouseleave', handleMouseLeave)
    return () => {
      if (!mapBoxRef.current) return
      mapBoxRef.current.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [mapBoxRef.current])

  // MAP EVENTS

  // hover event
  const handleMapHover: ProjectResultsProps['onMapHover'] = (target) => {
    if (popupState.sticky) return // popup is currently open, ignore hover

    const ids = target?.ids ?? []
    const hoveredClusters = clusters.filter(({ id }) => ids.includes(id))
    const hoveredBounds = bounds.filter(({ id }) => ids.includes(id))

    if (!target || hoveredClusters.length || !hoveredBounds.length) {
      // setPopupState(
      //   produce((draft) => {
      //     draft.open = false
      //     draft.sticky = false
      //     draft.ids = EMPTY_ARRAY
      //   })
      // )
      return
    }

    const hoveredBoundsIds = hoveredBounds.map(({ id }) => id)

    setPopupState(
      produce((draft) => {
        draft.x = target.x
        draft.y = target.y
        draft.open = true
        if (isEqual(draft.ids, hoveredBoundsIds)) return // fetching is based on popupState.ids identity, do not set if same
        draft.ids = hoveredBoundsIds
      })
    )
  }

  // click event
  const handleMapClick: ProjectResultsProps['onMapClick'] = ({ x, y, ids }) => {
    const clickedClusters = clusters.filter(({ id }) => ids.includes(id))
    const clickedBounds = bounds.filter(({ id }) => ids.includes(id))

    if (clickedClusters.length) {
      const bboxes = clickedClusters.map((cluster) => {
        if (cluster.bbox) return cluster.bbox // is a purely client-side "cluster" based on a polygon
        if (!cluster.hash) throw new Error('missing geoHash') // must be a server-side cluster with a hash
        const [minLat, minLng, maxLat, maxLng] = geohash.decode_bbox(cluster.hash) // note order of ngeohash bbox is different than elsewhere
        return [minLng, minLat, maxLng, maxLat] as Bbox
      })
      mapController.fitBboxes(bboxes, { paddingFactor: -0.1 }) // use negative padding to ensure a minimum zoom achieved
      return
    }

    if (!clickedBounds.length) return

    const clickedBoundsIds = clickedBounds.map(({ id }) => id)

    setPopupState(
      produce((draft) => {
        draft.x = x
        draft.y = y
        draft.open = true
        const isSameIds = isEqual(draft.ids, clickedBoundsIds)
        if (isSameIds) {
          draft.sticky = !draft.sticky // this has the effect of moving the popup but keeping it sticky, because the popup onClose handler is un-stickying first
        } else {
          draft.ids = clickedBoundsIds
          draft.sticky = false
        }
      })
    )
  }

  // BOUNDS LOADING

  const [loadingCount, setLoadingCount] = useState(0)
  const handleBoundsLoading = useCallback(() => {
    setLoadingCount((c) => c + 1)
  }, [])
  const handleBoundsLoaded = useCallback(() => {
    setLoadingCount((c) => c - 1)
  }, [])

  const isLoading = clustersLoading || boundsLoading || loadingCount

  // INFO

  const info = {
    clusters: {
      precision: statefulInfo.clusters.precision,
      count: fetchedClusters.length,
      possibleCount: statefulInfo.clusters.possibleCount,
      projects: fetchedClusters.reduce((acc, cluster) => acc + cluster.count, 0),
      remaining: statefulInfo.clusters.remaining,
      loading: clustersLoading,
      bbox: statefulInfo.clusters.bbox,
      radius: clusterRadius,
    },
    bounds: {
      count: fetchedBounds.length,
      remaining: statefulInfo.bounds.remaining,
      loading: boundsLoading,
      bbox: statefulInfo.bounds.bbox,
    },
    isBoundsMode,
  }

  // MAP ELEMENTS

  const mapElements = (
    <>
      {isLoading && (
        <LinearProgress
          delayMs={250}
          sx={{ position: 'absolute', width: '100%', height: '5px', opacity: 0.75, zIndex: 100 }}
        />
      )}
      {/* <div
        css={{
          top: 300,
          left: 300,
          position: 'absolute',
          zIndex: 100,
          height: gridHeight,
          width: gridWidth,
          background: '#ff000088',
        }}
      /> */}
      {overrides?.showClustersBbox && info.clusters.bbox && <BboxRect bbox={info.clusters.bbox} color="#ff8800" />}
      {overrides?.showBoundsBbox && info.bounds.bbox && <BboxRect bbox={info.bounds.bbox} color="#ff0000" />}
      {bounds.map((boundsProps) => (
        <Bounds
          key={boundsProps.id}
          {...boundsProps}
          visibilityBbox={bbox}
          onLoading={handleBoundsLoading}
          onLoaded={handleBoundsLoaded}
        />
      ))}
      {highlightBoundsIds.map((boundsId) => (
        <BoundsHighlight key={boundsId} id={boundsId} />
      ))}
      {clustersFeatureCollection && <Clusters data={clustersFeatureCollection} small={clusterRadius < 40} />}
      {overrides?.showClustersGrid && clustersGrid && <Points points={clustersGrid} />}
      {popupState.open && (
        <ProjectsMapPopup
          boxRef={mapBoxRef}
          x={popupState.x}
          y={popupState.y}
          groups={targetGroups}
          sticky={popupState.sticky}
          lookups={lookups}
          onClose={(reason) => {
            setPopupState(
              produce((draft) => {
                draft.sticky = false
                if (reason === 'clickaway') {
                  draft.ids = EMPTY_ARRAY
                  draft.open = false
                }
              })
            )
          }}
          getProjectBoundsThumbProps={getProjectBoundsThumbProps}
        />
      )}
    </>
  )

  // RETURN

  return {
    bounds,
    clusters,
    info,
    handleMapClick,
    handleMapHover,
    popupState,
    mapElements,
  }
}
