import {
  MissingDataError,
  ProjectTypeDetail as Components,
  PageTitle,
  ProjectTypeDetailSkeleton,
  Footer,
} from '@riverscapes/react-common'
import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { useGetProjectTypeDetailQuery } from '../data'
import { search, useGotoRoute } from '../routing'

const { Header, OverviewPanel } = Components

export interface ProjectTypeDetailProps extends JSX.IntrinsicAttributes {
  id: string
}

export const ProjectTypeDetail: React.FC<ProjectTypeDetailProps> = ({ id }) => {
  // const gotoSearch = useGotoRoute(search)
  const theme = useTheme()
  const gotoSearch = useGotoRoute(search)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  // PROJECT QUERY

  const { data, loading, error } = useGetProjectTypeDetailQuery({ variables: { id } })

  // HEADER

  // queries

  // mutations

  // mutations

  // RENDER

  if (error) throw error
  if (loading) return <ProjectTypeDetailSkeleton />
  if (!data?.projectType) throw new MissingDataError()

  const { projectType, searchProjects } = data
  const numProjects: number = searchProjects.total || 0

  return (
    <>
      <PageTitle title={projectType.name} />
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header projectType={projectType} />
        <Container
          maxWidth="lg"
          sx={{
            flex: '1 1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <OverviewPanel
            projectType={projectType}
            numProjects={numProjects}
            onGotoSearch={() =>
              gotoSearch({
                type: 'Project',
                params: {
                  projectTypeId: projectType.id,
                },
              })
            }
          />
        </Container>
        <Footer />
      </Box>
    </>
  )
}
