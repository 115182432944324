import React from 'react'
import { FeatureCollection } from 'geojson'
import log from 'loglevel'
import { useEffect, useMemo, useState } from 'react'
import { max, min } from 'lodash'
import { useBooleanState, useDeterministicColor, withTooltip } from '../../lib'
import { Box, IconButton } from '@mui/material'
import { AddIcon, ZoomFitIcon } from '../Icons'
import { Bbox, getIsFeatureCollectionEdgeOutsideBbox } from '../InteractiveMap'
import { FilterList } from '@mui/icons-material'

export interface ProjectBoundsThumbProps {
  // color: string
  id: string
  polygonUrl: string
  highlight?: boolean
  onHoverChange?: (newHover: boolean) => void
  onZoomClick?: () => void
  onAddClick?: () => void
  visibilityBbox?: Bbox
}

export const ProjectBoundsThumb: React.FC<ProjectBoundsThumbProps> = ({
  id,
  polygonUrl,
  highlight,
  onHoverChange,
  onAddClick,
  onZoomClick,
  visibilityBbox,
}) => {
  const [isHovered, startHover, stopHover] = useBooleanState()

  const handleMouseOver = () => {
    startHover()
    if (onHoverChange) onHoverChange(true)
  }
  const handleMouseOut = () => {
    stopHover()
    if (onHoverChange) onHoverChange(false)
  }

  const color = useDeterministicColor(id)

  const [data, setData] = useState<FeatureCollection | undefined>()

  useEffect(() => {
    fetch(polygonUrl) // using native fetch to support local blob (Axios chokes)
      .then((r) => r.json())
      .then(setData)
      .catch(() => {
        log.error('Failed to fetch polygonUrl', polygonUrl)
      })
  }, [])

  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!data || !visibilityBbox) return
    const isEdgeOutside = getIsFeatureCollectionEdgeOutsideBbox(data, visibilityBbox)
    if (!visible && !isEdgeOutside) {
      setVisible(true)
    } else if (visible && isEdgeOutside) {
      setVisible(false)
    }
  }, [data, visibilityBbox])

  const svg = useMemo(() => {
    if (!data) return undefined
    if (data.features[0].geometry.type !== 'Polygon') return
    const coordinates = data.features[0].geometry.coordinates[0]

    const minLng = min(coordinates.map(([lng, _lat]) => lng)) || 0
    const minLat = min(coordinates.map(([_lng, lat]) => lat)) || 0
    const maxLng = max(coordinates.map(([lng, _lat]) => lng)) || 0
    const maxLat = max(coordinates.map(([_lng, lat]) => lat)) || 0

    const width = maxLng - minLng
    const height = maxLat - minLat

    const polygonPoints = coordinates.map(([lng, lat]) => `${lng},${-lat}`).join(' ')

    return (
      <svg
        viewBox={`${minLng} ${-maxLat} ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible', height: '98%', width: '98%' }}
      >
        {highlight && (
          <>
            (
            <defs>
              <mask id={`${id}-mask`}>
                <rect x={minLng - width} y={-maxLat - height} width={width * 3} height={height * 3} fill="white" />
                <polygon points={polygonPoints} fill="black" />
              </mask>
            </defs>
            <polygon
              points={polygonPoints}
              style={{
                fill: 'none',
                stroke: '#ffff00',
                strokeOpacity: 0.8,
                strokeWidth: '20%', // translates to 10% outside of mask
              }}
              mask={`url(#${id}-mask)`}
            />
            )
          </>
        )}
        <polygon
          points={polygonPoints}
          style={{
            fill: color,
            fillOpacity: visible ? 0.4 : 0,
            stroke: color,
            strokeOpacity: 0.8,
            strokeWidth: '2%',
          }}
        />
      </svg>
    )
  }, [data, highlight, isHovered, visible])

  if (!svg) return <Box sx={{ height: '100%', width: '100%', background: '#eeeeee' }} />

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        background: '#ffffff',
        border: '1px solid gray',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5%',
        overflow: 'hidden',
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {svg}
      {isHovered && (
        <Box sx={{ position: 'absolute', bottom: 0, display: 'flex' }}>
          {onZoomClick ? (
            withTooltip(
              <IconButton color="primary" size="small" onClick={onZoomClick}>
                <ZoomFitIcon />
              </IconButton>,
              'Zoom To Bounds Polygon'
            )
          ) : (
            <IconButton size="small" disabled sx={{ opacity: 0 }}>
              <ZoomFitIcon />
            </IconButton>
          )}
          {onAddClick ? (
            withTooltip(
              <IconButton size="small" color="primary" onClick={onAddClick}>
                <FilterList />
              </IconButton>,
              'Filter to projects using this Polygon'
            )
          ) : (
            <IconButton size="small" disabled sx={{ opacity: 0 }}>
              <AddIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  )
}
