import React from 'react'
import { TileService } from '@riverscapes/react-common'

import numeral from 'numeral'
import MetaData from './MetaData'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Paper, SxProps, Theme, useTheme } from '@mui/material'
import dayjs from 'dayjs'
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  sectionHeader: {
    borderBottom: '1px solid',
  },
  table: {},
  metaKey: { fontWeight: 'bold' },
  metaValue: {
    color: theme.palette.text.secondary,
  },
})

interface LayerTileInfoProps {
  tiles: TileService
}

const LayerTileInfo: React.FC<LayerTileInfoProps> = ({ tiles }: LayerTileInfoProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const meta: Record<string, string> = {
    Status: tiles.state as string,
    Started: tiles ? `${dayjs(tiles.started).format('lll')} (${dayjs(tiles.started).fromNow()})` : 'unknown',
    'Last status': tiles ? `${dayjs(tiles.lastState).format('lll')} (${dayjs(tiles.lastState).fromNow()})` : 'unknown',
    'Creation time': tiles ? `${((tiles.duration || 0) / 60).toFixed(0)} minutes` : 'unknown',
    'Original Size': tiles && tiles.originFile ? numeral(tiles.originFile.size).format('0.0 b') : 'unknown',
    Files: tiles && tiles.numFiles ? numeral(tiles.numFiles).format('0,0') : 'unknown',
    // 'Relative path': tiles.rsLayer?.path || 'unknown',
    'Physical path': tiles ? (tiles.originFile?.localPath as string) : 'unknown',
    // TODO: This has changed and it's debug only so we'll comment it out for now
    // 'Modified (origin)': tiles
    //   ? `${dates.formatDate(tiles.originFile?.lastModified, 'lll')} (${dates.formatDateAgo(
    //       tiles.originFile?.lastModified
    //     )})`
    //   : 'unknown',
  }
  if (tiles.errorMsg) meta.Error = tiles.errorMsg as string

  const metaArr: MetaData[] = Object.keys(meta).map((key) => ({
    key,
    value: meta[key],
    __typename: 'MetaData',
  }))
  return (
    <Paper sx={{ p: 1 }}>
      <MetaData
        title="Webtile information"
        meta={metaArr}
        description="Information about the webtiles that were derived from this layer"
      />
    </Paper>
  )
}

export default LayerTileInfo
