import React from 'react'
import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { GetOrganizationDetailQuery, OrganizationRoleEnum } from '../../schema/base'
import { DetailPageHeader } from '../DetailPage'
import { ShareButton } from '../Share'
import { FavoriteStar } from '../FavoriteStar'
import { ShiftRight } from '../ShiftRight'
import { LogoImage } from '../ItemImage'
import { RequestOrganizationInviteButton, RequestOrganizationInviteButtonProps, RoleChip } from '../Role'
import { Lookups } from '../lookups'
import { EditableTypography } from '../EditableTypography'
import { validate } from '../../lib'
import { ImageManagerDialogProps } from '../ImageManager'
import { HeroColor } from '../HeroImage'

export interface HeaderProps {
  getLogoUploadTarget: ImageManagerDialogProps['getUploadTarget']
  lookups: Lookups
  onLogoChange: (newToken: string | null) => Promise<unknown>
  onNameChange: (newValue: string) => void
  onRequestInvite: () => Promise<unknown>
  onStarChange: (newValue: boolean) => void
  onSummaryChange: (newValue: string) => void
  organization: NonNullable<GetOrganizationDetailQuery['organization']>
  profileOrganizationInvites: RequestOrganizationInviteButtonProps['profileOrganizationInvites']
}

export const Header: React.FC<HeaderProps> = ({
  getLogoUploadTarget,
  lookups,
  onLogoChange,
  onNameChange,
  onRequestInvite,
  onStarChange,
  onSummaryChange,
  organization,
  profileOrganizationInvites,
}) => {
  const canEdit = Boolean(organization.permissions.update)

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <DetailPageHeader>
      <HeroColor />
      <Container maxWidth="lg">
        <Typography variant="overline" sx={{ p: 0, m: 0 }}>
          Organization
        </Typography>
        <Stack direction="column" justifyContent="center" p={2} spacing={2}>
          <Stack direction={isMdUp ? 'row' : 'column'} spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <LogoImage
              item={organization}
              size={60}
              canEdit={canEdit}
              getUploadTarget={getLogoUploadTarget}
              onChange={onLogoChange}
              lookups={lookups}
            />
            <EditableTypography
              canEdit={canEdit}
              content={organization.name}
              getError={validate.name}
              name="Name"
              onChange={onNameChange}
              variant="h3"
            />
            {organization.myRole === OrganizationRoleEnum.None ? (
              <RequestOrganizationInviteButton
                onClick={onRequestInvite}
                organizationId={organization.id}
                profileOrganizationInvites={profileOrganizationInvites}
              />
            ) : (
              <RoleChip value={organization.myRole} />
            )}
            <ShiftRight />
            <Stack direction="row">
              <FavoriteStar value={organization.starred} size={24} onChange={onStarChange} />
              <ShareButton title={organization.name} url={lookups.getOrganizationUrlById(organization.id)} />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <EditableTypography
              content={organization.summary}
              canEdit={canEdit}
              getError={validate.summary}
              name="Summary"
              onChange={onSummaryChange}
              placeholder={canEdit && 'No summary.'}
            />
          </Stack>
        </Stack>
      </Container>
    </DetailPageHeader>
  )
}
