import { times } from 'lodash'
import {
  Collection,
  User,
  Project,
  OrganizationRoleEnum,
  ProjectVisibilityEnum,
  ProjectType,
  ProjectGroupVisibilityEnum,
  Organization,
  MetaDataTypeEnum,
  ProjectBounds,
} from '../schema/base'
import { faker } from '@faker-js/faker'

const paginatedEmpty = {
  // __typename: 'PaginatedItems' as const,
  items: [],
  limit: 0,
  offset: 0,
  total: 0,
}

const permissionsAll = {
  __typename: 'ObjectPermissions' as const,
  delete: true,
  update: true,
  view: true,
}

export const allUsers: User[] = [
  {
    __typename: 'User',
    id: `user1`,
    name: 'Some User',
    createdBy: {} as User,
    updatedBy: {} as User,
    affiliations: [],
    createdOn: '2022-01-01T00:00:00',
    updatedOn: '2022-01-01T00:00:00',
    collections: { __typename: 'PaginatedCollections', ...paginatedEmpty },
    description: 'User description',
    jobTitle: 'Job title',
    organizations: { __typename: 'PaginatedOrganizations', ...paginatedEmpty },
    projects: { __typename: 'PaginatedProjects', ...paginatedEmpty },
    avatar: 'https://via.placeholder.com/100x100.png/55ff55?text=Avatar',
    meta: [],
    summary: 'User summary',
    savedSearches: { __typename: 'PaginatedSavedSearches', ...paginatedEmpty },
    lastLogin: '2020-01-01T00:00:00',
    location: 'Earth',
    social: {
      __typename: 'SocialLinks',
    },
    starred: false,
    starredCount: 0,
  },
]

export const user = allUsers[0]

export const profile = {
  ...allUsers[0],
  __typename: 'Profile' as const,
  organizationInvites: {
    __typename: 'PaginatedOrganizationInvites' as const,
    items: [{ __typename: 'OrganizationInvites' as const }],
    total: 0,
  },
  stars: {
    __typename: 'MyStars' as const,
    projects: {
      __typename: 'PaginatedProjects' as const,
      total: 0,
    },
    collections: {
      __typename: 'PaginatedCollections' as const,
      total: 0,
    },
    organizations: {
      __typename: 'PaginatedOrganizations' as const,
      total: 0,
    },
    users: {
      __typename: 'PaginatedUsers' as const,
      total: 0,
    },
  },
}

export const findUsersByName = (name: string, limit: number): User[] => {
  return allUsers.filter((u) => u.name.toLowerCase().includes(name.toLowerCase())).slice(0, limit)
}

export const findUserById = (id: string): User | null => {
  return allUsers.find((u) => u.id === id) ?? null
}

export const findOrganizationsByName = (name: string, limit: number): Organization[] => {
  // return allOrganizations.filter((u) => u.name.toLowerCase().includes(name.toLowerCase())).slice(0, limit)
  return []
}

export const findOrganizationById = (id: string): Organization | null => {
  // return allOrganizations.find((u) => u.id === id) ?? null
  return null
}

export const makeOrganizations = (qty: number): Organization[] => {
  return times(qty).map((i) => ({
    __typename: 'Organization',
    id: `org${i + 1}`,
    name: 'My Organization',
    description: 'This is my organization.',
    myRole: OrganizationRoleEnum.Owner,
    collections: { __typename: 'PaginatedCollections', ...paginatedEmpty },
    createdBy: user,
    updatedBy: user,
    createdOn: '2022-01-01T00:00:00',
    updatedOn: '2022-01-01T00:00:00',
    starred: false,
    organizationInvites: { __typename: 'PaginatedOrganizationInvites', ...paginatedEmpty },
    meta: [],
    projects: { __typename: 'PaginatedProjects', ...paginatedEmpty },
    savedSearches: { __typename: 'PaginatedSavedSearches', ...paginatedEmpty },
    starredCount: 0,
    summary: 'Org summary',
    logo: 'https://via.placeholder.com/100x100.png/5555ff/?text=Logo',
    permissions: permissionsAll,
    organizationUsers: { __typename: 'PaginatedOrganizationUsers', ...paginatedEmpty },
    preferences: {},
    social: null,
  }))
}

export const organization = makeOrganizations(1)[0]

export const makeProjects = (qty: number): Project[] => {
  return times(qty).map((i) => ({
    __typename: 'Project',
    id: `proj${i + 1}`,
    sponsor: organization,
    name: `Some Project ${i}`,
    createdOn: '2022-01-01T00:00:00',
    updatedOn: '2022-01-01T00:00:00',
    createdBy: user,
    updatedBy: user,
    deleted: false,
    dirty: false,
    myRole: [OrganizationRoleEnum.Contributor, OrganizationRoleEnum.Owner, OrganizationRoleEnum.Viewer][i % 3],
    visibility: ProjectVisibilityEnum.Public,
    summary: `summary ${faker.lorem.words(20)}`,
    description: `description ${faker.lorem.words(100)}`,
    meta: [
      {
        __typename: 'MetaData',
        key: 'Somekey',
        type: MetaDataTypeEnum.String,
        value: 'some value here',
      },
    ],
    ownedBy: user,
    starred: true,
    starredCount: 0,
    heroImage: {
      __typename: 'HeroImage',
      lg: 'https://via.placeholder.com/1000x300.png?text=Hero+Image',
    },
    collections: { __typename: 'PaginatedCollections', ...paginatedEmpty },
    datasets: { __typename: 'PaginatedDatasets', ...paginatedEmpty },
    tags: ['TagA', 'OtherTag'],
    citation: null,
    totalSize: 5 * 10 ** 6,
    tree: {
      branches: [],
      leaves: [],
      views: [],
      // defaultView: null,
      description: 'Tree description',
      name: 'Tree name',
      __typename: 'ProjectTree',
    },
    qaqc: { __typename: 'PaginatedQAQCEvents', ...paginatedEmpty },
    projectType: {
      id: 'vbet',
      name: 'VBET',
      url: 'https://via.placeholder.com/100x100.png/dd0000/?text=VBET',
    } as ProjectType,
    permissions: {
      __typename: 'ObjectPermissions',
      delete: true,
      update: true,
      view: true,
    },
    ref: 'SOMEREFSTRING',
    files: [],
    bounds: {
      id: `bounds${i + 1}`,
      __typename: 'ProjectBounds',
    } as ProjectBounds,
  }))
}

export const makeProjectTypes = (qty: number): ProjectType[] => {
  return times(qty).map((i) => ({
    __typename: 'ProjectType',
    id: `type${i + 1}`,
    name: `Project Type ${i}`,
    description: `This is project type ${i}.`,
    url: 'https://via.placeholder.com/100x100.png/00dd00/?text=Project+Type',
    logo: 'https://via.placeholder.com/100x100.png/00dd00/?text=Project+Type',
    createdBy: user,
    createdOn: '2022-01-01T00:00:00',
    updatedBy: user,
    updatedOn: '2022-01-01T00:00:00',
  } as ProjectType))
}

export const allProjects = makeProjects(100)

export const project = allProjects[0]

export const makeCollections = (qty: number): Collection[] => {
  return times(qty).map((i) => ({
    __typename: 'Collection',
    id: `coll${i + 1}`,
    name: `My Collection ${i}`,
    description: 'This is a collection I made.',
    createdBy: user,
    updatedBy: user,
    createdOn: '2022-01-01T00:00:00',
    updatedOn: '2022-01-01T00:00:00',
    summary: 'Collection summary',
    ownedBy: organization,
    contact: user,
    starred: false,
    starredCount: 0,
    tags: [],
    projects: {
      __typename: 'PaginatedProjects',
      items: makeProjects(8),
      total: 8,
      limit: 0,
      offset: 0,
    },
    meta: [],
    visibility: ProjectGroupVisibilityEnum.Secret,
    myRole: OrganizationRoleEnum.Owner,
    users: {
      users: [
        {
          id: 'user1',
          name: 'User 1',
        },
        {
          id: 'user2',
          name: 'User 2',
        },
      ],
    },
    permissions: permissionsAll,
    heroImage: {
      __typename: 'HeroImage',
      sm: 'https://placehold.co/100x30',
      lg: 'https://placehold.co/1000x300',
    },
  }))
}

export const allCollections = makeCollections(100)

export const collection = allCollections[0]

export const findCollectionsByName = (name: string, limit: number): Collection[] => {
  return allCollections.filter((c) => c.name.toLowerCase().includes(name.toLowerCase())).slice(0, limit)
}

export const findCollectionById = (id: string): Collection | null => {
  return allCollections.find((c) => c.id === id) ?? null
}

export const makeProfile = () => {
  return profile
}
