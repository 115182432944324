import { Typography, Card, CardContent, Link, Stack, CardHeader, Box } from '@mui/material'
import { GetOrganizationCardQuery } from '../../schema/base'
import { LogoImage } from '../ItemImage'
import { Lookups } from '../lookups'
import { useTheme } from '@mui/system'
import { useFormats } from '../../lib'

export interface OrganizationCardProps {
  className?: string
  lookups: Lookups
  organization: NonNullable<GetOrganizationCardQuery['organization']>
}

export const OrganizationCard: React.FC<OrganizationCardProps> = ({ className, lookups, organization }) => {
  const theme = useTheme()
  const { formatNumber, pluralize } = useFormats()
  const logoImageUrl = organization.logo
  return (
    <Card sx={{ mb: 2 }} className={className} variant="elevation" elevation={2}>
      <CardHeader
        sx={{
          cursor: 'pointer',
          backgroundColor: theme.palette.primary.main,
        }}
        subheader={
          <Link href={lookups.getOrganizationUrlById(organization.id)}>
            <Box sx={{ position: 'relative' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Stack direction="row" alignItems="center" gap={1} sx={{ color: theme.palette.primary.contrastText }}>
                  <LogoImage size={30} item={organization} />
                  <Typography variant="h6" color="white" className={logoImageUrl ? 'heroText' : undefined}>
                    {organization.name}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        }
      />
      <CardContent>
        <Stack gap={1} direction="row">
          <Stack flex={1}>
            <Typography>{organization.summary}</Typography>
          </Stack>
          <Link href={lookups.getOrganizationUrlById(organization.id, 'projects')}>
            <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
              <Typography variant="h4">{formatNumber(organization.projects.total)}</Typography>
              <Typography variant="body2">{pluralize('project', organization.projects.total)}</Typography>
            </Stack>
          </Link>
        </Stack>
      </CardContent>
    </Card>
  )
}
