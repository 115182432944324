import { createRoot } from 'react-dom/client'
import log from 'loglevel'

import './index.css'
import { App } from './App'
import reportWebVitals from './reportWebVitals'

// THIS FIX IS NECESSARY FOR PRODUCTION AND WEBPACK
// https://docs.mapbox.com/mapbox-gl-js/api/#transpiling-v2
// https://github.com/mapbox/mapbox-gl-js/issues/10173
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const requestedLogLevel = process.env.REACT_APP_LOGLEVEL
switch (requestedLogLevel) {
  case 'trace':
  case 'debug':
  case 'info':
  case 'warn':
  case 'error':
  case 'silent':
    log.setLevel(requestedLogLevel)
    break
  default:
    log.setLevel(process.env.NODE_ENV === 'production' ? 'warn' : 'trace')
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // asserting non-null as per https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
