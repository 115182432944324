import React from 'react'
import { Box, CardProps, Container, Grid2Props, useTheme } from '@mui/material'
import { useAuth } from '@riverscapes/react-api'
import {
  CreateOrganizationDialog,
  MissingDataError,
  PageTitle,
  useFields,
  useNotifications,
  WelcomeCard,
  MyOrgsCard,
  Footer,
  MyInvitesCard,
  ProjectTypesCard,
  ExploreCard,
} from '@riverscapes/react-common'

import {
  useActionProfileOrganizationInviteMutation,
  useCreateOrganizationMutation,
  useDeleteProfileInviteMutation,
  useFetchAllWithPendingItems,
  useGetProfileOrganizationInvitesQuery,
  useGetProfileOrganizationsQuery,
} from '../data'
// import log from 'loglevel'
import { useLookups, useProfile } from '../lib'
import { organizationDetail, search, useGotoRoute } from '../routing'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router-dom'
import { BookmarkedCard } from '@riverscapes/react-common'
import { useAllMyStars } from '../lib/useAllMyStars'
import { useGetProjectTypesQuery } from '@riverscapes/react-common/dist/schema/operations'
import log from 'loglevel'

export interface HomeCardProps {
  gridWidths?: number
  children: React.ReactNode
  cardProps?: CardProps
  gridProps?: Grid2Props
}

export interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const theme = useTheme()
  const { isAuthenticated } = useAuth()
  const { catchError } = useNotifications()
  const gotoOrganization = useGotoRoute(organizationDetail)
  const profile = useProfile()
  const lookups = useLookups()
  const navigate = useNavigate()
  const allStarred = useAllMyStars({ profile })

  const [fields, setFields] = useFields({
    isWorking: false,
    isCreateOrganizationOpen: false,
  })

  // QUERIES

  const { data: profileOrganizationsData, fetchMore: fetchMoreProfileOrganizations } = useGetProfileOrganizationsQuery({
    variables: { offset: 0 },
    onError: catchError('Failed to get organizations', false),
    skip: !isAuthenticated,
  })

  const profileOrganizations = useFetchAllWithPendingItems(
    fetchMoreProfileOrganizations,
    profileOrganizationsData?.profile?.organizations.items,
    profile?.organizations.total,
    1
  )

  const { data: profileOrganizationInvitesData, fetchMore: fetchMoreProfileOrganizationInvites } =
    useGetProfileOrganizationInvitesQuery({
      variables: { offset: 0 },
      onError: catchError('Failed to get invites', false),
      skip: !isAuthenticated,
    })

  const profileOrganizationInvites = useFetchAllWithPendingItems(
    fetchMoreProfileOrganizationInvites,
    profileOrganizationInvitesData?.profile?.organizationInvites.items,
    profile?.organizationInvites.total,
    0
  )

  const { data: projectTypeData } = useGetProjectTypesQuery({
    onError: catchError('Failed to get project types', false),
    skip: !isAuthenticated,
  })
  const projectTypes = projectTypeData?.projectTypes?.items || []

  let projectTypeStats: Record<string, number> = {}
  let totalProjects = 0
  try {
    if (projectTypeData?.searchProjects?.stats?.__typename)
      projectTypeStats = (
        projectTypeData?.searchProjects?.stats as {
          __typename: 'ProjectSearchMeta'
          projectTypes?: Record<string, any> | null | undefined
        }
      ).projectTypes?.buckets.reduce((acc, { key, doc_count }) => {
        acc[key] = doc_count
        totalProjects += doc_count
        return acc
      }, {} as Record<string, number>)
  } catch (e) {
    log.error(e)
  }

  const [actionOrganizationInvite] = useActionProfileOrganizationInviteMutation()
  const handleInviteAction = ({
    id,
    organizationId,
    accept,
  }: {
    id: string
    organizationId: string
    accept: boolean
  }) =>
    actionOrganizationInvite({
      variables: { id, accept },
      context: { organizationId },
    }).catch(catchError(`Failed to ${accept ? 'accept' : 'reject'} invitation`, false))

  const [deleteOrganizationInvite] = useDeleteProfileInviteMutation()
  const handleInviteDelete = ({ id }: { id: string }) =>
    deleteOrganizationInvite({
      variables: { id },
    }).catch(catchError(`Failed to delete request`, false))

  // MUTATIONS

  const [createOrganization] = useCreateOrganizationMutation()
  const handleCreateOrganization = ({ name }: { name: string }) => {
    setFields.$.isWorking(true)
    createOrganization({
      variables: { name },
      refetchQueries: ['GetProfileOrganizations'],
    })
      .then(({ data }) => {
        if (!data?.createOrganization) throw new MissingDataError()
        gotoOrganization({ id: data.createOrganization.id })
      })
      .catch(catchError(`Failed to create organization`, false))
      .finally(() => {
        setFields.$.isWorking(false)
      })
  }

  return (
    <>
      <PageTitle title="Riverscapes Data Exchange" />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.background.default,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            flex: 1,
            background: theme.palette.background.default,
          }}
        >
          <Grid2 container spacing={3} padding={3}>
            <WelcomeCard />
            <ExploreCard getSearchUrl={search.getUrl} totalProjects={totalProjects} />
            <MyOrgsCard
              gotoOrganization={(orgId: string) => gotoOrganization({ id: orgId })}
              handleCreateOrganization={(name: string) => handleCreateOrganization({ name })}
              lookups={lookups}
              profileOrganizations={profileOrganizations}
            />
            <BookmarkedCard allBookmarked={allStarred} lookups={lookups} />
            <ProjectTypesCard
              onGotoProjectType={(projectTypeId) => {
                const projectTypeUrl = lookups.getProjectTypeUrlById(projectTypeId)
                navigate(projectTypeUrl)
              }}
              onGotoSearch={(projectTypeId) => {
                navigate(
                  search.getUrl({
                    type: 'Project',
                    params: {
                      projectTypeId,
                    },
                  })
                )
              }}
              projectTypeStats={projectTypeStats}
              projectTypes={projectTypes}
            />
            <MyInvitesCard
              lookups={lookups}
              profileOrganizationInvites={profileOrganizationInvites}
              handleInviteAction={handleInviteAction}
              handleInviteDelete={handleInviteDelete}
            />
          </Grid2>
        </Container>
        <Footer />
      </Box>
      <CreateOrganizationDialog
        disableAll={fields.isWorking}
        open={fields.isCreateOrganizationOpen}
        onClose={() => setFields.$.isCreateOrganizationOpen(false)}
        onConfirm={handleCreateOrganization}
      />
    </>
  )
}
