import { ProjectTreeLayerTypeEnum, SymbologyStateEnum } from '@riverscapes/react-common'
import { useEffect } from 'react'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import {
  assignedRandomColorsAtom,
  selectNextColor,
  layerStateSelectorFamily,
  leafRandomColAtomFamily,
  activeTreeLeavesSelector,
} from '../recoil'
// import log from 'loglevel'

const useColourFallback = (): void => {
  const activeTreeLeaves = useRecoilValue(activeTreeLeavesSelector)
  /**
   * We can use a Recoil Callback here to make sure we don't overfetch any state while we're assigning a color
   */
  const assignRndCol = useRecoilCallback(({ snapshot, set }) => async (lid: number) => {
    const colIds = await snapshot.getPromise(assignedRandomColorsAtom) // pre-fetch user info
    const currRndCol = await snapshot.getPromise(leafRandomColAtomFamily(lid)) // pre-fetch user info
    const layer = await snapshot.getPromise(layerStateSelectorFamily(lid))
    const nextCol = await snapshot.getPromise(selectNextColor) // pre-fetch user info

    let needRndCol = false
    // Symbology and tiles are loaded asynchronously so we might need a loading state here
    if (!layer.symbology || layer.symbology.state !== SymbologyStateEnum.Found) {
      needRndCol = true
    }

    // Only do this if we haven't already assigned a color. Should save on churn
    if (needRndCol && currRndCol === null) {
      set(assignedRandomColorsAtom, [...colIds, nextCol])
      set(leafRandomColAtomFamily(lid), nextCol)
    }
    // Unsetting isn't exactly just an Else case
    else if (!needRndCol && currRndCol !== null) {
      set(
        assignedRandomColorsAtom,
        colIds.filter((cid) => cid === currRndCol)
      )
      set(leafRandomColAtomFamily(lid), null)
    }
  })

  useEffect(() => {
    const exFunc = async () => {
      for (let idx = 0; idx < activeTreeLeaves.length; idx++) {
        if (activeTreeLeaves[idx].layerType !== ProjectTreeLayerTypeEnum.Raster)
          await assignRndCol(activeTreeLeaves[idx].id)
      }
    }
    exFunc()
  }, [activeTreeLeaves])
}

export default useColourFallback
