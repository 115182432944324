import React from 'react'
import { useRecoilState } from 'recoil'
import { drawerOpenAtom } from '../recoil'
import TreeToggleBtn from '../components/TreeToggleBtn'
import useZoomExtents from '../hooks/useZoomExtents'
import { boundsSelector } from '../recoil'
import { useRecoilValue } from 'recoil'
// import log from 'loglevel'

const TreeToggleConnect: React.FC = () => {
  const [drawerOpen, toggleDrawer] = useRecoilState(drawerOpenAtom)
  const zoomLayerExtent = useZoomExtents()
  const boundsSelect = useRecoilValue(boundsSelector)
  return (
    <TreeToggleBtn
      key="treeToggleBtn"
      drawerOpen={drawerOpen}
      bounds={boundsSelect.bounds}
      zoomExtents={() => {
        if (boundsSelect.bounds) zoomLayerExtent(boundsSelect.bounds)
      }}
      toggleDrawer={() => {
        toggleDrawer(!drawerOpen)
      }}
    />
  )
}

export default TreeToggleConnect
