import log from 'loglevel'

const stringValue = (envValue: string | undefined) => String(envValue || '')
const booleanValue = (envValue: string | undefined) => {
  if (!envValue) return false
  switch (envValue.trim().toLowerCase()) {
    case '1':
    case 'true':
      return true
    case '0':
    case 'false':
      return false
    default:
      log.debug('A config parameter has an unknown value.')
      return false
  }
}

export const endpointUrl = stringValue(process.env.REACT_APP_API_URL) // full redirect URL for GraphQL API endpoint
export const basePath = stringValue(process.env.PUBLIC_URL).replace(/^\/?/, '').replace(/\/?$/, '') // ensure no leading or trailing slash
export const redirectUrl = new URL(`/${basePath ? `${basePath}/` : ''}`, window.location.origin).toString() // full redirect URL for Cognito
export const showDevTools = booleanValue(process.env.REACT_APP_SHOW_DEV_TOOLS)
export const showWelcome = booleanValue(process.env.REACT_APP_SHOW_WELCOME)
