import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
  Box,
  Typography,
} from '@mui/material'
import { AutocompleteSelectField, AutocompleteSelectOption } from './AutocompleteSelectField'
import { someValues, useFields } from '../lib'
import { GetProfileDetailQuery, Owner, OwnerInput, OwnerInputTypesEnum } from '../schema/base'

export interface ProjectTransferDialogProps {
  onConfirm: (newOwner: OwnerInput) => void
  onClose: () => void
  currentOwner: Owner
  profile: NonNullable<GetProfileDetailQuery['profile']>
  open: boolean
  organizationOptions?: AutocompleteSelectOption[]
}

export const ProjectTransferDialog: React.FC<ProjectTransferDialogProps> = ({
  onConfirm,
  onClose,
  open,
  currentOwner,
  profile,
  organizationOptions,
}) => {
  const theme = useTheme()

  const canTransferSelf = currentOwner.__typename === 'Organization'
  const canTransferOrg = (organizationOptions || []).length > 0
  const canTransfer = canTransferSelf || canTransferOrg

  const [fields, setFields, preparedFields] = useFields(
    {
      belongsTo: canTransferOrg ? 'org' : 'me',
      orgId:
        organizationOptions && organizationOptions.length > 0 ? organizationOptions[0].value : (null as string | null),
    },
    ({ orgId, belongsTo }) => ({
      orgId: belongsTo === 'org' && orgId ? orgId : undefined,
    }),
    [open]
  )

  const errors = someValues({
    cannotTransfer: !canTransfer,
    orgId: fields.belongsTo === 'org' && !preparedFields.orgId,
  })

  const handleConfirm = () => {
    onConfirm(
      fields.belongsTo === 'me'
        ? { id: profile.id, type: OwnerInputTypesEnum.User }
        : { id: fields.orgId as string, type: OwnerInputTypesEnum.Organization }
    )
    onClose()
  }

  const getOrganizationOptionsFromText = (text: string): AutocompleteSelectOption[] => {
    if (!text.trim()) return organizationOptions || []
    return (organizationOptions || []).filter((option) => option.text.toLowerCase().includes(text.toLowerCase()))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.main,
          mb: 2,
        }}
      >
        Change Project Owner
      </DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Typography paragraph>
          {
            'You can transfer ownership of this project to yourself or an Organization you contribute to (i.e. you have "contributor" or greater permissions).'
          }
        </Typography>
        <Stack direction="column" gap={2}>
          <FormControl>
            <FormLabel>New Owner</FormLabel>
            <RadioGroup value={fields.belongsTo} onChange={(e, newValue) => setFields.$.belongsTo(newValue)}>
              <FormControlLabel value="me" control={<Radio />} label="Me" />
              <FormControlLabel
                control={<Radio />}
                value="org"
                componentsProps={{ typography: { sx: { flex: 1 } } }}
                label={
                  <AutocompleteSelectField
                    disabled={fields.belongsTo !== 'org'}
                    noOptionsText="No organizations found (must be contributor or higher)"
                    value={fields.orgId}
                    onChange={(newValue) =>
                      setFields({
                        ...fields,
                        belongsTo: 'org',
                        orgId: newValue,
                      })
                    }
                    label="Organization"
                    getOptionsFromText={getOrganizationOptionsFromText}
                    error={Boolean(errors?.orgId)}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flex: 1 }} />
        <Button onClick={handleConfirm} disabled={!!errors} color="warning">
          Transfer Project
        </Button>
      </DialogActions>
    </Dialog>
  )
}
