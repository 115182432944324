import React from 'react'
import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { GetProjectTypeDetailQuery } from '../../schema/base'
import { DetailPageHeader } from '../DetailPage'
import { ProjectTypeImage } from '../ItemImage'
import { HeroImage } from '../HeroImage'

export interface HeaderProps {
  projectType: NonNullable<GetProjectTypeDetailQuery['projectType']>
}

export const Header: React.FC<HeaderProps> = ({ projectType }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <DetailPageHeader>
      <HeroImage />
      <Container maxWidth="lg">
        <Typography variant="overline" sx={{ p: 0, m: 0 }} className="heroText">
          Project Type
        </Typography>
        <Stack direction="column" justifyContent="center" p={2} spacing={2}>
          <Stack direction={isMdUp ? 'row' : 'column'} spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <ProjectTypeImage item={projectType} size={60} />
            <Stack direction="column" spacing={1}>
              <Typography variant="h3" className="heroText">
                {projectType.name}
              </Typography>
              <Typography variant="subtitle1" className="heroText">
                {projectType.summary}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </DetailPageHeader>
  )
}
