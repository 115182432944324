import React from 'react'
import { CardContent, CardHeader } from '@mui/material'

// import log from 'loglevel'
import { HomeCard } from './HomeCard'
import { useAuth } from '@riverscapes/react-api'
import { ProfileInvitesTable } from '../UsersTable'
import { Lookups } from '../lookups'
import { ProfileInviteRow } from '../UsersTable/types'
import { PendingItem } from '../PendingItem'

export interface MyInvitesCardProps {
  profileOrganizationInvites: (ProfileInviteRow | PendingItem)[]
  lookups: Lookups
  handleInviteAction: (data: { id: string; organizationId: string; accept: boolean }) => Promise<unknown>
  handleInviteDelete: ({ id }: { id: string }) => Promise<unknown>
}

export const MyInvitesCard: React.FC<MyInvitesCardProps> = ({
  profileOrganizationInvites,
  lookups,
  handleInviteAction,
  handleInviteDelete,
}) => {
  const { isAuthenticated } = useAuth()
  if (!isAuthenticated || profileOrganizationInvites.length === 0) return null
  return (
    <HomeCard gridWidths={2}>
      <CardHeader title="Organization Invites" />
      <CardContent>
        <ProfileInvitesTable
          rows={profileOrganizationInvites}
          onInviteAction={handleInviteAction}
          onInviteDelete={handleInviteDelete}
          lookups={lookups}
        />
      </CardContent>
    </HomeCard>
  )
}
