import React, { useMemo } from 'react'
import { ProjectTreeView } from '@riverscapes/react-common'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'

const EMPTY_VID = 'custom'
export interface ViewListProps {
  activeView: string | null
  defaultView?: string
  projectViews: ProjectTreeView[]
  handleChange: (viewId: string) => void
}
const ViewList: React.FC<ViewListProps> = ({ activeView, defaultView, projectViews, handleChange }: ViewListProps) => {
  const realView = activeView === null ? EMPTY_VID : activeView
  const viewOptions = useMemo(() => {
    if (!projectViews || projectViews.length === 0)
      return [
        <MenuItem key={EMPTY_VID} value="custom" disabled>
          <Typography variant="subtitle1" color="textSecondary">
            <em>No custom views</em>
          </Typography>
        </MenuItem>,
      ]
    const viewOpts =
      projectViews.map((v, idx) => {
        const isDefault = v.id === defaultView
        const defaultString = isDefault ? '(default)' : ''
        return (
          <MenuItem key={`view-${idx}`} value={v.id}>
            {v.name} {defaultString}
          </MenuItem>
        )
      }) || []
    return [
      ...viewOpts,
      activeView === null && (
        <MenuItem key={EMPTY_VID} value="custom">
          <Typography variant="subtitle1" color="textSecondary">
            <em>Custom View</em>
          </Typography>
        </MenuItem>
      ),
    ]
  }, [projectViews, activeView])

  return (
    <FormControl fullWidth size="small" variant="filled">
      <InputLabel htmlFor="grouped-native-select">Curated Views</InputLabel>
      <Select
        id="view"
        value={realView}
        disabled={!projectViews || projectViews.length === 0}
        variant="filled"
        onChange={(event) => event.target.value !== EMPTY_VID && handleChange(event.target.value as string)}
      >
        {viewOptions}
      </Select>
    </FormControl>
  )
}

export default ViewList
