import React, { useMemo } from 'react'
import { Box, Button, CardActions, CardContent, CardHeader, Stack, Tooltip, Typography, useTheme } from '@mui/material'

// import log from 'loglevel'
import { GroupAdd, Groups } from '@mui/icons-material'
import { useAuth } from '@riverscapes/react-api'
import { useFields } from '../../lib'
import { HomeCard } from './HomeCard'
import { ItemRepresentation } from '../ItemImage'
import { RoleChip } from '../Role'
import { CreateOrganizationDialog } from '../OrganizationDetail'
import { PendingItem } from '../PendingItem'
import { OrganizationCardFragment } from '../../schema/base'
import { Lookups } from '../lookups'

export interface MyOrgsCardProps {
  lookups: Lookups
  gotoOrganization: (id: string) => void
  handleCreateOrganization: (name: string) => void
  profileOrganizations: (PendingItem | OrganizationCardFragment)[]
}

export const MyOrgsCard: React.FC<MyOrgsCardProps> = ({ profileOrganizations, lookups, handleCreateOrganization }) => {
  const theme = useTheme()
  const { isAuthenticated } = useAuth()

  const sortedOrgs = useMemo(
    () =>
      [...profileOrganizations].sort((a, b) => {
        const aName = a?.name ?? ''
        const bName = b?.name ?? ''
        return aName.localeCompare(bName)
      }),
    [profileOrganizations]
  )

  const [fields, setFields] = useFields({
    isWorking: false,
    isCreateOrganizationOpen: false,
  })

  if (!isAuthenticated) return null
  return (
    <>
      <HomeCard
        cardProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Groups sx={{ mr: 1 }} /> My Organizations
            </Box>
          }
        />

        <CardContent
          sx={{
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <Stack gap={1}>
            {sortedOrgs.length === 0 && (
              <Typography
                component="div"
                variant="body1"
                paragraph
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: theme.palette.grey[500],
                  textDecoration: 'italic',
                }}
              >
                You are not a member of an organization.
              </Typography>
            )}
            {sortedOrgs.map((organization, idx) => (
              <Box
                key={organization.id}
                sx={{ display: 'flex', backgroundColor: idx % 2 === 0 ? undefined : theme.palette.grey[100] }}
              >
                <Box sx={{ flex: '1 1', overflow: 'hidden' }}>
                  <ItemRepresentation key={organization.id} link item={organization} lookups={lookups} ellipses />
                </Box>
                {organization && organization.__typename === 'Organization' && (
                  <RoleChip value={organization.myRole} small />
                )}
              </Box>
            ))}
          </Stack>
        </CardContent>
        <CardActions>
          <Tooltip title="Create a new organization">
            <Button
              startIcon={<GroupAdd />}
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setFields.$.isCreateOrganizationOpen(true)}
            >
              Create Organization
            </Button>
          </Tooltip>
        </CardActions>
      </HomeCard>
      <CreateOrganizationDialog
        disableAll={fields.isWorking}
        open={fields.isCreateOrganizationOpen}
        onClose={() => setFields.$.isCreateOrganizationOpen(false)}
        onConfirm={({ name }: { name: string }) => handleCreateOrganization(name)}
      />
    </>
  )
}
