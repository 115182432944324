/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from 'react'
import {
  SmartSearchField as Component,
  SmartSearchFieldOption,
  useDebounce,
  SearchIcon,
  ItemRepresentationProps,
  ItemRepresentation,
  pendingItems,
} from '@riverscapes/react-common'
import { useGetSmartSearchResultsQuery } from '../data'
import { identity, noop } from 'lodash'
import { collectionDetail, organizationDetail, projectDetail, search, useGotoRoute, userDetail } from '../routing'
import { useActivity, extractItem } from '../lib'
import log from 'loglevel'
import { Stack } from '@mui/material'

export interface SmartSearchFieldProps {}

type ListableItem = ItemRepresentationProps['item'] & {
  highlight?: string
}

// const isListableItem = (target: Partial<ListableItem>): target is ListableItem => {
//   if (target.highlight) return true
//   return false
// }

const flattenResultNameHighlight = <T,>({
  item,
  highlights,
}: {
  item: T
  highlights?: any
}): T & { highlight?: string } => {
  let highlight: string | undefined
  try {
    highlight = highlights?.name[0]
  } catch (err) {
    // leave highlight undefined
  }
  return {
    ...item,
    highlight,
  }
}

export const SmartSearchField: React.FC<SmartSearchFieldProps> = () => {
  const gotoProjectDetail = useGotoRoute(projectDetail)
  const gotoCollectionDetail = useGotoRoute(collectionDetail)
  const gotoOrganizationDetail = useGotoRoute(organizationDetail)
  const gotoUserDetail = useGotoRoute(userDetail)
  const gotoSearch = useGotoRoute(search)

  const [value, setValue] = useState('')
  const [debouncedValue] = useDebounce(value, 250)
  const trimmedDebouncedValue = debouncedValue.trim()

  const { activities } = useActivity()
  const recentItems = activities.slice(0, 8).map(extractItem)

  const transformItemToFieldOption = useCallback((item: ListableItem): SmartSearchFieldOption => {
    const {
      __typename,
      id,
      // highlight, // TODO: reinstate highlight
    } = item
    const onChoose = () => {
      switch (__typename) {
        case 'Project':
          gotoProjectDetail({ id })
          break
        case 'Collection':
          gotoCollectionDetail({ id })
          break
        case 'Organization':
          gotoOrganizationDetail({ id })
          break
        case 'User':
          gotoUserDetail({ id })
          break
        default:
          log.error('Not implemented')
      }
    }

    return {
      key: id,
      label: <ItemRepresentation item={item} />,
      onChoose,
    }
  }, [])

  const { data } = useGetSmartSearchResultsQuery({
    variables: {
      text: trimmedDebouncedValue,
    },
    skip: !trimmedDebouncedValue,
  })

  const suggestedItems = useMemo<ListableItem[]>(() => {
    if (!trimmedDebouncedValue) return []
    if (!data) return pendingItems(3)

    const projectsResult = data.searchProjects.results.map(flattenResultNameHighlight)
    const collectionsResult = data.searchCollections.results.map(flattenResultNameHighlight)
    const organizationsResult = data.searchOrganizations.results.map(flattenResultNameHighlight)
    const usersResult = data.searchUsers.results.map(flattenResultNameHighlight)

    return [...projectsResult, ...collectionsResult, ...organizationsResult, ...usersResult].filter(identity)
  }, [data, trimmedDebouncedValue])

  const options = useMemo(() => {
    if (!trimmedDebouncedValue) {
      if (recentItems.length === 0) {
        const noRecentItems: SmartSearchFieldOption = {
          key: 'no-recent-items',
          label: 'No recent items',
          onChoose: noop, // TODO: disable this option instead
        }
        return [noRecentItems]
      }
      return recentItems.map(transformItemToFieldOption)
    } else {
      return [
        {
          key: 'search-projects',
          label: (
            <Stack direction="row">
              <SearchIcon /> {trimmedDebouncedValue}&nbsp;<em>in Projects</em>
            </Stack>
          ),
          onChoose: () => gotoSearch({ type: 'Project', params: { keywords: value } }),
        },
        {
          key: 'search-collections',
          icon: <SearchIcon />,
          label: (
            <Stack direction="row">
              <SearchIcon /> {trimmedDebouncedValue}&nbsp;<em>in Collections</em>
            </Stack>
          ),
          onChoose: () => gotoSearch({ type: 'Collection', params: { keywords: value } }),
        },
        {
          key: 'search-organizations',
          icon: <SearchIcon />,
          label: (
            <Stack direction="row">
              <SearchIcon /> {trimmedDebouncedValue}&nbsp;<em>in Organizations</em>
            </Stack>
          ),
          onChoose: () => gotoSearch({ type: 'Organization', params: { keywords: value } }),
        },
        {
          key: 'search-users',
          icon: <SearchIcon />,
          label: (
            <Stack direction="row">
              <SearchIcon /> {trimmedDebouncedValue}&nbsp;<em>in Users</em>
            </Stack>
          ),
          onChoose: () => gotoSearch({ type: 'User', params: { keywords: value } }),
        },
        // {
        //   key: 'search-saved-searches',
        //   icon: <SearchIcon />,
        //   label: (
        //     <Stack direction="row">
        //       <SearchIcon /> {trimmedDebouncedValue}&nbsp;<em>in Saved Searches</em>
        //     </Stack>
        //   ),
        //   onChoose: () => gotoSearch({ type: 'SavedSearch', params: { keywords: value } }),
        // },
        ...suggestedItems.map(transformItemToFieldOption),
      ]
    }
  }, [trimmedDebouncedValue, recentItems, suggestedItems])

  return <Component value={value} onChange={setValue} options={options} />
}
