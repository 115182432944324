export const validate = {
  /** Returns true if value is invalid name. */
  name: (value: string): boolean => value.length < 3 || value.length > 250,
  /** Returns true if value is invalid summary. */
  summary: (value: string): boolean => value.length > 500,
  /** Returns true if value is invalid description. */
  description: (value: string): boolean => value.length > 100 * 1000,
  /** Returns true if value is invalid description. */
  citation: (value: string): boolean => value.length > 800 * 5,
}
