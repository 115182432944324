import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  IconButton,
  Popper,
  Box,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { identity } from 'lodash'
import { useBooleanState, useDebounce } from '../../lib'
import { ClearIcon } from '../Icons'

export interface AutocompleteSelectOption {
  label: React.ReactNode
  text: string
  value: string
  data?: any
}

interface MyProps {
  disabled?: boolean
  label?: string
  onChange: (newValue: string | null, selectedOption?: AutocompleteSelectOption | null) => void
  getOptionsFromText: (text: string) => AutocompleteSelectOption[] | Promise<AutocompleteSelectOption[]>
  getOptionFromValue?: (value: string) => AutocompleteSelectOption | Promise<AutocompleteSelectOption | null>
  placeholder?: string
  value: string | null
  error?: boolean
}

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 'auto' }} placement="bottom-start" />
}

export type AutocompleteSelectFieldProps = Omit<
  AutocompleteProps<AutocompleteSelectOption, undefined, undefined, undefined>,
  keyof (MyProps & {
    renderInput: never
    options: never
  })
> &
  MyProps

export const AutocompleteSelectField: React.FC<AutocompleteSelectFieldProps> = ({
  disabled,
  label,
  onChange,
  getOptionsFromText,
  getOptionFromValue,
  placeholder,
  value,
  error,
  ...restProps
}) => {
  const [isSuspended, startSuspended, stopSuspended] = useBooleanState(false)
  const [isOpen, handleOpen, handleClose] = useBooleanState()
  const [isFocused, startFocus, stopFocus] = useBooleanState()

  const [options, setOptions] = useState<AutocompleteSelectOption[]>([])

  const [inputText, setInputText] = useState('')
  const [debouncedInputText] = useDebounce(inputText, 250)

  const [selectedOption, setSelectedOption] = useState<AutocompleteSelectOption | null>(null)

  useEffect(() => {
    if (!isOpen || inputText) return
    // empty text: get options immediately (presumably recent items, or none)
    let cancel = false
    Promise.resolve(getOptionsFromText('')).then((fetchedOptions) => {
      if (cancel) return
      setOptions(fetchedOptions)
    })
    return () => {
      cancel = true
    }
  }, [isOpen, inputText])

  useEffect(() => {
    if (!isOpen || !debouncedInputText) return
    // have text: get options from debounced text (presumably searched)
    let cancel = false
    Promise.resolve(getOptionsFromText(debouncedInputText)).then((fetchedOptions) => {
      if (cancel) return
      setOptions(fetchedOptions)
    })
    return () => {
      cancel = true
    }
  }, [isOpen, debouncedInputText])

  useEffect(() => {
    if (value === selectedOption?.value) return
    if (!getOptionFromValue) {
      setSelectedOption(null) // when getOptionFromValue not supplied, cannot be initialized with a value
      return
    }
    startSuspended()
    Promise.resolve(value ? getOptionFromValue(value) : null).then((option) => {
      if (!option) {
        setSelectedOption(null)
        onChange(null)
      } else {
        setInputText(option.text)
        setOptions([option])
        setSelectedOption(option)
      }
      stopSuspended()
    })
  }, [value, selectedOption])

  if (isSuspended)
    return (
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value="skeleton"
          size="small"
          label={label}
          disabled
          autoWidth
          endAdornment={
            <IconButton
              onClick={() => {
                //
              }}
            >
              <ClearIcon />
            </IconButton>
          }
        >
          <MenuItem value="skeleton">
            <Skeleton />
          </MenuItem>
        </Select>
      </FormControl>
    )

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      fullWidth
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(event, selectedOption) => {
        if (selectedOption) {
          setInputText(selectedOption.text)
          setOptions([selectedOption])
          setSelectedOption(selectedOption)
          onChange(selectedOption.value, selectedOption)
        } else {
          setSelectedOption(null)
          onChange(null, null)
        }
      }}
      onFocus={startFocus}
      onBlur={stopFocus}
      value={selectedOption}
      isOptionEqualToValue={(o) => o.value === selectedOption?.value} // TODO: logs error when value isn't in available options, but need to be able to preserve value :-/
      options={options}
      getOptionLabel={(option) => option.text} // this is required by control; becomes the input text when control is focused
      filterOptions={identity}
      onInputChange={(event, newInputValue) => {
        setInputText(newInputValue)
      }}
      PopperComponent={CustomPopper}
      renderInput={(params) => {
        const selectedAndUnFocused = selectedOption && !isFocused && !disabled
        const inputProps = selectedAndUnFocused
          ? { ...params.inputProps, style: { color: 'transparent', width: '100px' } }
          : params.inputProps
        return (
          <Box sx={{ position: 'relative' }}>
            <TextField {...params} inputProps={inputProps} label={label} placeholder={placeholder} error={error} />
            {selectedAndUnFocused && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 12,
                  right: 60,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {selectedOption.label}
              </Box>
            )}
          </Box>
        )
      }}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.value}>
          {option.label}
        </ListItem>
      )}
      blurOnSelect
      disabled={disabled}
      {...restProps}
    />
  )
}
