import { Typography, Card, CardContent, Link, Stack, CardHeader, Box, useTheme } from '@mui/material'
import { GetUserCardQuery } from '../../schema/base'
import { AvatarImage } from '../ItemImage'
import { Lookups } from '../lookups'
import { useFormats } from '../../lib'

export interface UserCardProps {
  className?: string
  lookups: Lookups
  user: NonNullable<GetUserCardQuery['user']>
}

export const UserCard: React.FC<UserCardProps> = ({ className, lookups, user }) => {
  const theme = useTheme()
  const { formatNumber, pluralize } = useFormats()
  const avatarImageUrl = user.avatar
  return (
    <Card sx={{ mb: 2 }} className={className} variant="elevation" elevation={2}>
      <CardHeader
        sx={{
          cursor: 'pointer',
          backgroundColor: theme.palette.primary.main,
        }}
        subheader={
          <Link href={lookups.getUserUrlById(user.id)}>
            <Box sx={{ position: 'relative' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Stack direction="row" alignItems="center" gap={1} sx={{ color: theme.palette.primary.contrastText }}>
                  <AvatarImage size={30} item={user} />
                  <Typography variant="h6" color="white" className={avatarImageUrl ? 'heroText' : undefined}>
                    {user.name}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Link>
        }
      />
      <CardContent>
        <Stack gap={1} direction="row">
          <Stack flex={1}>
            <Typography>{user.summary}</Typography>
          </Stack>
          <Link href={lookups.getUserUrlById(user.id, 'projects')}>
            <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
              <Typography variant="h4">{formatNumber(user.projects.total)}</Typography>
              <Typography variant="body2">{pluralize('project', user.projects.total)}</Typography>
            </Stack>
          </Link>
        </Stack>
      </CardContent>
    </Card>
  )
}
