import { appDefaults } from '../config'
import { Project, ProjectTreeLayerTypeEnum, ProjectTreeLeaf } from '@riverscapes/react-common'
import { isNull, isUndefined } from 'lodash'
import { decomposeColor, recomposeColor } from '@mui/material'
import { WebMercatorViewport } from 'viewport-mercator-project'
import { ViewState } from 'react-map-gl'
import { BoundsObj } from '../types'

export type FallbackColor = { fg: string; bg: string }

// It's annoying that MaterialUI doesn't expose Alpha function
// Hopefully this stays put over the next few versions
export function addColorAlpha(inColor: string, alpha: number): string {
  const bgTmp = decomposeColor(inColor)
  if (bgTmp.type === 'rgb' || bgTmp.type === 'hsl') {
    bgTmp.type += 'a'
  }
  bgTmp.values[3] = alpha
  const bg = recomposeColor(bgTmp)
  return bg
}

/**
 *
 * @param point
 * @param rect
 * @returns
 */
export function pointInRect(point: [number, number], rect: BoundsObj): boolean {
  return point[0] >= rect[0] && point[0] <= rect[2] && point[1] >= rect[1] && point[1] <= rect[3]
}

/**
 *
 * @param bounds1
 * @param bounds2
 * @returns
 */
export function boundsOverlap(bounds1: BoundsObj, bounds2: BoundsObj): boolean {
  const xOverlap = bounds1[0] <= bounds2[2] && bounds1[2] >= bounds2[0]
  const yOverlap = bounds1[1] <= bounds2[3] && bounds1[3] >= bounds2[1]
  return xOverlap && yOverlap
}

/**
 * Get a latitude, longitude and zoom for a bounds and frame size
 * @param cornersArr
 * @param width
 * @param height
 * @returns
 */
export const zoomExtents = (
  cornersArr: BoundsObj,
  width: number,
  height: number,
  buffer?: number
): Partial<ViewState> => {
  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const newCornersArr = [...cornersArr]
  if (buffer) {
    const width = cornersArr[2] - cornersArr[0]
    const height = cornersArr[3] - cornersArr[1]
    newCornersArr[0] -= width * buffer
    newCornersArr[1] -= height * buffer
    newCornersArr[2] += width * buffer
    newCornersArr[3] += height * buffer
  }

  const viewport = new WebMercatorViewport({ width, height }).fitBounds(
    [
      [newCornersArr[0], newCornersArr[1]],
      [newCornersArr[2], newCornersArr[3]],
    ],
    { padding: 200 }
  ) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = viewport
  // Jump back inside cornersArr if we're outside
  let finalLong = longitude
  let finalLat = latitude
  if (finalLong <= cornersArr[0]) finalLong = cornersArr[0] - 0.0001
  if (finalLong >= cornersArr[2]) finalLong = cornersArr[2] + 0.0001
  if (finalLat <= cornersArr[1]) finalLat = cornersArr[1] - 0.0001
  if (finalLat >= cornersArr[3]) finalLat = cornersArr[3] + 0.0001

  return { longitude, latitude, zoom }
}

/**
 * Just a function to grow a bounding rectangle around a set of tiled bounds
 * @param oldBounds [minx, miny, maxx, maxy]
 * @param newRect
 * @returns
 */
export function newBounds(oldBounds: BoundsObj | null, newRect: BoundsObj | null): BoundsObj | null {
  let newBounds: BoundsObj | null = null
  if (!newRect && !oldBounds) newBounds = null
  else if (newRect && !oldBounds) newBounds = newRect
  else if (oldBounds && !newRect) newRect = oldBounds
  else if (oldBounds && newRect) {
    newBounds = [
      // Note the extra wrapper function. We do this to keep really wonky values out
      Math.min(newRect[0], oldBounds[0]),
      Math.min(newRect[1], oldBounds[1]),
      Math.max(newRect[2], oldBounds[2]),
      Math.max(newRect[3], oldBounds[3]),
    ]
  }
  return newBounds
}

export function calculateOpacity(transparency = 0): number {
  const sanitized = Math.max(0, Math.min(100, transparency))
  return 1 - sanitized / 100
}

/**
 * This function will return a new set of coordinates if the current coordinates are outside the bounds
 * of the system. If the current coordinates are inside the bounds it will return null.
 * @param coords The [lon,lat] we're testing
 * @param bounds The bounds of the system (or null if we don't have them)
 * @param center Jump to the center of the bounds if true
 * @returns
 */
export function geoFence(
  coords: [number | undefined | null, number | undefined | null] | null,
  bounds: BoundsObj | null,
  center = false
): [number, number] | null {
  // console.log('geoFence', coords, bounds, center)
  // If we have bounds but not coords then we can still send back a correction if the
  // center is requested
  if (!coords && bounds && center === true) {
    return [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2]
  }
  // If there's nothing to compare there's nothing to correct
  if (!bounds || !coords || isUndefined(coords[0]) || isNull(coords[0]) || isUndefined(coords[1]) || isNull(coords[1]))
    return null
  // Snap back to inside the rectangle if we are outside it
  const newCoords = [
    Math.max(bounds[0], Math.min(bounds[2], coords[0])),
    Math.max(bounds[1], Math.min(bounds[3], coords[1])),
  ] as [number, number]

  if (newCoords[0] === coords[0] && newCoords[1] === coords[1]) {
    return null
  } else if (center) {
    return [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2]
  } else {
    return newCoords
  }
}

export function newZoomBounds(
  oldZBounds: [number, number],
  newZbounds?: [number | undefined, number | undefined]
): [number, number] {
  const fallback = appDefaults.zoomRange as [number, number]
  if (!oldZBounds && !newZbounds) return fallback
  else if (!newZbounds) return oldZBounds

  // The min zoom is always set at the APP level
  const min = oldZBounds ? oldZBounds[0] : appDefaults.zoomRange[0]
  const max = Math.min(appDefaults.zoomRange[1], Math.max(oldZBounds[1], newZbounds[1] || oldZBounds[1]))

  return [min, max]
}

export type TreeNodeID = { branches: number[]; leaves: number[] }

/**
 * MAterial UI uses strings as treenodes. This is a bit awkward for us trying to differentiate branches and leaves
 * @param ids
 */
export function treeNodeIDsParse(rawIds: string[]): TreeNodeID {
  return {
    branches: rawIds.filter((ids) => ids.indexOf('b-') > -1).map((ids) => parseInt(ids.replace('b-', ''), 10)),
    leaves: rawIds.filter((ids) => ids.indexOf('l-') > -1).map((ids) => parseInt(ids.replace('l-', ''), 10)),
  }
}

/**
 * This is the return trip function for taking our TreeNodeID and squooshing it back to an array of strings
 * @param betterIds
 * @returns
 */
export function treeNodeIDCreate(betterIds: TreeNodeID): string[] {
  return [...betterIds.branches.map((bid) => `b-${bid}`), ...betterIds.leaves.map((lid) => `l-${lid}`)]
}

/**
 * Create an ancestry array with search text we can use to filter the tree
 * it is ordered deepest (current) --> shallowest (root)
 * @param project
 * @param bid
 * @returns
 */
export function projectLeafSearchAncestry(project: Project, leaf: ProjectTreeLeaf): [number, string][] {
  let parent = leaf.pid as number
  // Let's just track what we've already found so we don't get circles
  const ancestry: number[] = []
  const searchStrings: string[] = []

  // The touchedIds is basically just to insulate against
  // Everything in  a tree can only have one parent so work down
  const branches = project.tree?.branches || []
  while (parent > -1 && ancestry.length < branches.length) {
    const foundBranch = branches.find((br) => br?.bid === parent)
    // If we've found a valid parent then add its label to the search terms
    if (foundBranch && ancestry.indexOf(foundBranch.bid) < 0) {
      ancestry.push(foundBranch.bid)
      searchStrings.push(foundBranch.label.toLowerCase().trim())
      parent = foundBranch.pid
    }
    // Insulate ourselves from endless searching
    else {
      parent = -1
    }
  }
  // We put it back int
  return ancestry.map((bid, idx) => [bid, searchStrings[idx]])
}

const SORT_ORDER: ProjectTreeLayerTypeEnum[] = [
  ProjectTreeLayerTypeEnum.Point,
  ProjectTreeLayerTypeEnum.Line,
  ProjectTreeLayerTypeEnum.Polygon,
  ProjectTreeLayerTypeEnum.Tin,
  ProjectTreeLayerTypeEnum.Raster,
]

export function layerSort(leaves: ProjectTreeLeaf[]): ProjectTreeLeaf[] {
  const newArr = [...leaves]
  newArr.sort((lyrA, lyrB) => {
    // First we sort by the layer type
    if (lyrA.layerType !== lyrB.layerType)
      return SORT_ORDER.indexOf(lyrA.layerType) - SORT_ORDER.indexOf(lyrB.layerType)
    // Then we sort by the treePath id order. The way the BusinessLogic file is parsed should be the
    // same as tree order (TODO: Verify this?!?)
    else {
      return lyrA.id - lyrB.id
    }
  })
  return newArr
}
