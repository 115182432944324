/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState, memo } from 'react'
import { FeatureCollection } from 'geojson'
import { Layer, Source } from 'react-map-gl'
import { Bbox, useInteractiveMapContext } from './useInteractiveMapController'
import { useDeterministicColor } from '../../lib'
import log from 'loglevel'
import produce from 'immer'
import { getIsFeatureCollectionEdgeOutsideBbox } from './util'

export interface BoundsProps {
  id: string
  count?: number
  polygonUrl: string
  onLoading?: () => void
  onLoaded?: () => void
  visibilityBbox?: Bbox
}

const EMPTY_DATA: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
}

export const Bounds = memo<BoundsProps>(function Bounds({ id, polygonUrl, onLoading, onLoaded, visibilityBbox }) {
  const color = useDeterministicColor(id)

  const { registerLayerId, unregisterLayerId } = useInteractiveMapContext()

  const [data, setData] = useState<FeatureCollection>(EMPTY_DATA)

  const [visible, setVisible] = useState(true)

  useEffect(() => {
    let onLoadedCalled = false

    const handleFinished = () => {
      if (onLoadedCalled || !onLoaded) return
      onLoadedCalled = true
      onLoaded()
    }

    if (onLoading) onLoading()

    fetch(polygonUrl) // using native fetch to support local blob (Axios chokes)
      .then((r) => r.json())
      .then(
        produce<FeatureCollection>((draft) => {
          if (!draft.features[0].properties) return
          draft.features[0].properties.id = id
        })
      )
      .then(setData)
      .catch(() => {
        log.error('Failed to fetch polygonUrl', polygonUrl)
      })
      .finally(handleFinished)

    return handleFinished
  }, [])

  useEffect(() => {
    if (!data || !visible) return
    registerLayerId(id)
    return () => {
      unregisterLayerId(id)
    }
  }, [data, visible])

  useEffect(() => {
    if (!data || !visibilityBbox) return
    const isEdgeOutside = getIsFeatureCollectionEdgeOutsideBbox(data, visibilityBbox)
    if (!visible && !isEdgeOutside) {
      setVisible(true)
    } else if (visible && isEdgeOutside) {
      setVisible(false)
    }
  }, [data, visibilityBbox])

  const [fillPaint, linePaint] = useMemo(() => {
    return [
      {
        'fill-color': color,
        'fill-opacity': visible ? 0.4 : 0,
      },
      {
        'line-color': color,
        'line-opacity': 0.8,
        'line-width': 2,
      },
    ]
  }, [color, visible])

  return (
    <Source id={id} type="geojson" data={data}>
      <Layer id={id} type="fill" paint={fillPaint} />
      <Layer id={`${id}-line`} type="line" paint={linePaint} />
    </Source>
  )
})
