import React from 'react'
import { useMemo } from 'react'
import { OrganizationInviteStateEnum } from '../../schema/base'
import { DataGrid } from '@mui/x-data-grid'
import { isPendingItem, withTooltip } from '../../lib'
import { Box, IconButton } from '@mui/material'
import { AcceptIcon, DeleteIcon } from '../Icons'
import { profileInvitesColumns } from './components'
import { PendingItem } from '../PendingItem'
import { Lookups } from '../lookups'
import { ActionableRow, ProfileInviteRow } from './types'

export interface ProfileInvitesTableProps {
  className?: string
  lookups: Lookups
  rows: (ProfileInviteRow | PendingItem)[]
  onInviteDelete: (invite: { id: string }) => Promise<unknown>
  onInviteAction: (invite: { id: string; organizationId: string; accept: boolean }) => Promise<unknown>
}

export const ProfileInvitesTable: React.FC<ProfileInvitesTableProps> = ({
  lookups,
  onInviteAction,
  onInviteDelete,
  rows,
}) => {
  const decoratedInviteRows: ((ProfileInviteRow & ActionableRow) | PendingItem)[] = useMemo(() => {
    return rows.map((row) => {
      if (isPendingItem(row)) return row
      return {
        ...row,
        actions: (
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            {row.state === OrganizationInviteStateEnum.Invited &&
              withTooltip(
                <IconButton
                  onClick={() => {
                    onInviteAction({ id: row.id, organizationId: row.organization.id, accept: true })
                  }}
                >
                  <AcceptIcon />
                </IconButton>,
                'Accept Invitation'
              )}
            {(() => {
              switch (row.state) {
                // case OrganizationInviteStateEnum.Expired:
                //   return withTooltip(
                //     <IconButton
                //       onClick={() => {
                //         // delete no prompt
                //       }}
                //     >
                //       <DeleteIcon />
                //     </IconButton>,
                //     'Delete Expired Invitation'
                //   )
                case OrganizationInviteStateEnum.Invited:
                  return withTooltip(
                    <IconButton
                      onClick={() => {
                        onInviteAction({ id: row.id, organizationId: row.organization.id, accept: false })
                        // setTargetRow(row)
                        // setPromptAction('deleteInvite')
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>,
                    'Decline Invitation'
                  )
                case OrganizationInviteStateEnum.Requested:
                  return withTooltip(
                    <IconButton
                      onClick={() => {
                        onInviteDelete({ id: row.id })
                        // setTargetRow(row)
                        // setPromptAction('deleteRequest')
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>,
                    'Withdraw Request'
                  )
                // case OrganizationInviteStateEnum.Rejected:
                //   return withTooltip(
                //     <IconButton
                //       onClick={() => {
                //         onInviteDelete({ id: row.id })
                //       }}
                //     >
                //       <DeleteIcon />
                //     </IconButton>,
                //     'Delete Rejected Invitation'
                //   )
                default:
                  return null
              }
            })()}
          </Box>
        ),
        lookups,
      }
    })
  }, [rows])

  return (
    <Box sx={{ height: 260, width: '100%', mb: 20 }}>
      <DataGrid
        autoPageSize
        loading={decoratedInviteRows.length === 0}
        density="compact"
        rows={decoratedInviteRows}
        columns={profileInvitesColumns}
        disableSelectionOnClick
        disableColumnSelector
      />
    </Box>
  )
}
