/** @jsxImportSource @emotion/react */
import { Button, Stack, Typography } from '@mui/material'
import { useAuth } from '@riverscapes/react-api'

export const AuthenticationRequired: React.FC = () => {
  const { hostedSignIn } = useAuth()

  return (
    <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="center" gap={1}>
      <Typography>Sign In is required.</Typography>
      <Button variant="contained" onClick={hostedSignIn}>
        Sign In
      </Button>
    </Stack>
  )
}
