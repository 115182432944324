import { Box } from '@mui/material'
import { GetProjectDetailQuery, MetaDataInput } from '../../schema/base'

import { MetadataTable } from '../MetadataTable'

export interface MetadataPanelProps {
  project: NonNullable<GetProjectDetailQuery['project']>
  onMetadataChange: (newValue: MetaDataInput[]) => Promise<unknown>
}

export const MetadataPanel: React.FC<MetadataPanelProps> = ({ project, onMetadataChange }) => {
  const canEdit = Boolean(project.permissions.update)
  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <MetadataTable value={project.meta} canEdit={canEdit} onChange={onMetadataChange} />
    </Box>
  )
}
