import { Button, Chip, ChipProps, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { ProjectGroupVisibilityEnum, ProjectVisibilityEnum } from '../../schema/base'
import { LogicError, useBooleanState, useFields, withTooltip } from '../../lib'
import { VisibilityField } from '../Visibility'
import { EditIcon } from '../Icons'
import { theme } from '@riverscapes/react-theme'
import { Box, useTheme } from '@mui/system'

const backgroundColor: Record<ProjectVisibilityEnum | ProjectGroupVisibilityEnum, string> = {
  [ProjectVisibilityEnum.Public]: theme.palette.info.main,
  [ProjectVisibilityEnum.Private]: '#ce844c',
  [ProjectVisibilityEnum.Secret]: '#ce4ca1',
}
const borderColor: Record<ProjectVisibilityEnum | ProjectGroupVisibilityEnum, string> = {
  [ProjectVisibilityEnum.Public]: '#7fa6cb',
  [ProjectVisibilityEnum.Private]: '#934a16',
  [ProjectVisibilityEnum.Secret]: '#961669',
}
const fgColor: Record<ProjectVisibilityEnum | ProjectGroupVisibilityEnum, string> = {
  [ProjectVisibilityEnum.Public]: theme.palette.info.contrastText,
  [ProjectVisibilityEnum.Private]: '#ffe9d9',
  [ProjectVisibilityEnum.Secret]: '#fff8fc',
}

const tooltips: Record<ProjectVisibilityEnum | ProjectGroupVisibilityEnum, string> = {
  [ProjectVisibilityEnum.Public]: 'PUBLIC means visible and searchable to everyone.',
  [ProjectVisibilityEnum.Private]: 'PRIVATE means searchable but not visible except to collaborators.',
  [ProjectVisibilityEnum.Secret]: 'SECRET means not searchable or visible except to collaborators.',
}

export type VisibilityChipProps = (
  | {
      canEdit?: false
      type: 'Project'
      value: ProjectVisibilityEnum
      onChange?: (newValue: ProjectVisibilityEnum) => void
    }
  | {
      canEdit: true
      type: 'Project'
      value: ProjectVisibilityEnum
      onChange: (newValue: ProjectVisibilityEnum) => void
    }
  | {
      canEdit?: false
      type: 'Collection' | 'SavedSearch'
      value: ProjectGroupVisibilityEnum
      onChange?: (newValue: ProjectGroupVisibilityEnum) => void
    }
  | {
      canEdit: true
      type: 'Collection' | 'SavedSearch'
      value: ProjectGroupVisibilityEnum
      onChange: (newValue: ProjectGroupVisibilityEnum) => void
    }
) &
  Omit<ChipProps, 'onChange'>

export const VisibilityChip: React.FC<VisibilityChipProps> = ({
  canEdit = false,
  onChange,
  type,
  value: valueProp,
  ...chipProps
}) => {
  const theme = useTheme()
  const [isEditing, startEditing, stopEditing] = useBooleanState()
  const [isHovering, startHovering, stopHovering] = useBooleanState()
  const [fields, setFields] = useFields(
    {
      projectValue: type === 'Project' ? valueProp : ProjectVisibilityEnum.Secret,
      projectGroupValue: type !== 'Project' ? valueProp : ProjectGroupVisibilityEnum.Secret,
    },
    [valueProp, isEditing]
  )

  const handleSave = async () => {
    if (!onChange) throw new LogicError()
    stopEditing()
    if (type === 'Project') {
      onChange(fields.projectValue)
    } else {
      onChange(fields.projectGroupValue)
    }
  }

  const styledChip = withTooltip(
    <Chip
      {...chipProps}
      label={valueProp}
      sx={{
        '& .MuiChip-label': {
          px: 0.7,
          py: 0,
          fontSize: '0.75rem',
        },
        height: 25,
        fontWeight: 'bold',
        color: fgColor[valueProp],
        backgroundColor: backgroundColor[valueProp],
        borderStyle: 'solid',
        borderRadius: 2,
        borderWidth: 1,
        borderColor: borderColor[valueProp],
      }}
    />,
    tooltips[valueProp]
  )

  if (!canEdit) return styledChip

  return (
    <>
      <div
        style={{ position: 'relative', display: 'inline-block', overflow: 'visible', paddingRight: '1.5rem' }}
        onMouseOver={startHovering}
        onMouseOut={stopHovering}
      >
        {withTooltip(
          <IconButton
            sx={{
              position: 'absolute',
              right: 0,
              top: '-0.5rem',
              zIndex: 1,
              opacity: isHovering ? 1 : 0.5,
              transition: '250ms opacity',
            }}
            onClick={startEditing}
          >
            <EditIcon color="primary" />
          </IconButton>,
          'Edit Visibility'
        )}
        {styledChip}
      </div>
      <Dialog open={isEditing} onClose={stopEditing}>
        <DialogTitle
          sx={{
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            mb: 2,
          }}
        >
          Change Project Visibility
        </DialogTitle>
        <DialogContent>
          {fields.projectValue && type === 'Project' && (
            <VisibilityField type={type} value={fields.projectValue} onChange={setFields.$.projectValue} />
          )}
          {fields.projectGroupValue && type !== 'Project' && (
            <VisibilityField type={type} value={fields.projectGroupValue} onChange={setFields.$.projectGroupValue} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={stopEditing}>Cancel</Button>
          <Box sx={{ flex: 1 }} />
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
