import dayjs from 'dayjs'
import { MetaDataTypeEnum } from '../../schema/base'

const anyValueAllowed = (): false => false
const validateUrl = (value: string): boolean => !value.match(/^https?:\/\/.+/)

/** Returns true if value is invalid of type. */
export const validateMetadata: Record<MetaDataTypeEnum, (value: string) => string | false> = {
  [MetaDataTypeEnum.Boolean]: (value: string) => {
    if (!['true', 'false'].includes(value.trim().toLowerCase())) return 'Boolean value must be "true" or "false".'
    return false
  },
  [MetaDataTypeEnum.Filepath]: anyValueAllowed,
  [MetaDataTypeEnum.Float]: (value: string) => {
    if (!isFinite(Number(value))) return 'Float value must be a number.'
    return false
  },
  [MetaDataTypeEnum.Guid]: (value: string) => {
    if (!value.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/))
      return 'GUID value must be a properly-formatted Globally Unique Identifier string.'
    return false
  },
  [MetaDataTypeEnum.Int]: (value: string) => {
    const valueAsNumber = Number(value)
    if (!isFinite(valueAsNumber) || String(Math.trunc(valueAsNumber)) !== value)
      return 'Int value must be a non-fractional number.'
    return false
  },
  [MetaDataTypeEnum.Isodate]: (value: string) => {
    if (!dayjs(value).isValid()) return 'ISO Date value must be a parsable ISO 8601 date string.'
    return false
  },
  [MetaDataTypeEnum.Image]: (value: string) => {
    if (validateUrl(value)) return 'Image value must be a valid URL.'
    return false
  },
  [MetaDataTypeEnum.Json]: (value: string) => {
    try {
      JSON.parse(value)
      return false
    } catch {
      return 'JSON value must be valid JSON.'
    }
  },
  [MetaDataTypeEnum.Markdown]: anyValueAllowed,
  [MetaDataTypeEnum.Richtext]: anyValueAllowed,
  [MetaDataTypeEnum.String]: anyValueAllowed,
  [MetaDataTypeEnum.Hidden]: anyValueAllowed,
  [MetaDataTypeEnum.Timestamp]: (value: string) => {
    if (isNaN(new Date(Number(value)).valueOf()))
      return 'Timestamp value must be valid UNIX timestamp (including milliseconds).'
    return false
  },
  [MetaDataTypeEnum.Url]: (value: string) => {
    if (validateUrl(value)) return 'URL value must be a valid URL.'
    return false
  },
  [MetaDataTypeEnum.Video]: (value: string) => {
    if (validateUrl(value)) return 'Video value must be a valid URL.'
    return false
  },
}
