import React from 'react'
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { CloudDownload } from '@mui/icons-material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    '& *': {
      cursor: 'pointer',
    },
  },
  disabledButton: {
    opacity: 0.5,
  },
})

interface Props {
  title?: string
  pendingSince?: number
  downloadZipFile: () => Promise<void>
  btnProps?: ButtonProps | IconButtonProps
}

const DownloadZipBtn: React.FC<Props> = ({ title, pendingSince, btnProps, downloadZipFile }: Props) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  let btnRender = (
    <Tooltip
      title={
        <React.Fragment>
          <Typography variant="body1">Download Project Zip</Typography>
          <Typography variant="caption" paragraph>
            Click to download this project as a single zip file
          </Typography>
          <Typography variant="caption" paragraph>
            There may be a delay if the zip file needs to be built.
          </Typography>
        </React.Fragment>
      }
    >
      {title ? (
        <Button
          variant="contained"
          color="primary"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            e.preventDefault()
            e.stopPropagation()
            downloadZipFile()
          }}
          startIcon={<CloudDownload />}
          {...(btnProps as ButtonProps)}
        >
          {title || 'ZIP'}
        </Button>
      ) : (
        <IconButton
          color="primary"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            e.preventDefault()
            e.stopPropagation()
            downloadZipFile()
          }}
          {...(btnProps as IconButtonProps)}
        >
          <CloudDownload />
        </IconButton>
      )}
    </Tooltip>
  )
  if (pendingSince) {
    btnRender = (
      <Tooltip
        title={
          <React.Fragment>
            <Typography variant="body1">Preparing Zip</Typography>
            <Typography variant="caption" paragraph>
              The data exchange is building your zip file for you.
            </Typography>
            <Typography variant="caption" paragraph>
              started {dayjs(pendingSince).fromNow()}
            </Typography>
            <Typography variant="caption" paragraph>
              This usually takes only a minute or two, depending heavily on the size of your project. When it&#39;s
              ready, you should get a &#39;download file&#39; popup.
            </Typography>
          </React.Fragment>
        }
      >
        <Button
          variant="outlined"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          color="primary"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            e.preventDefault()
            e.stopPropagation()
          }}
          sx={styles.disabledButton}
          startIcon={
            <CircularProgress
              color={btnProps && btnProps.color && btnProps.color === 'secondary' ? 'primary' : 'secondary'}
              size={24}
              thickness={6}
            />
          }
          {...(btnProps as ButtonProps)}
        >
          {title || 'ZIP'}
        </Button>
      </Tooltip>
    )
  }

  return <Box sx={styles.root}>{btnRender}</Box>
}

export default DownloadZipBtn
