import { memo } from 'react'
import { Layer, LineLayer } from 'react-map-gl'

export interface BoundsHighlightProps {
  id: string
}

const highlightPaint: LineLayer['paint'] = {
  'line-color': '#ffff00',
  'line-opacity': 1,
  'line-width': 10,
  'line-offset': -3,
  'line-blur': 5,
}

export const BoundsHighlight = memo<BoundsHighlightProps>(function BoundsHighlight({ id }) {
  return <Layer id={`${id}-highlight`} source={id} type="line" paint={highlightPaint} />
})
