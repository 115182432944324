import { Stack, Typography, IconButton, Container, useMediaQuery } from '@mui/material'
import { FavoriteStar } from '../FavoriteStar'
import { ShiftRight } from '../ShiftRight'
import { ShareButton } from '../Share'
import { GetProjectDetailQuery } from '../../schema/base'
import { HeroImage, HeroImageEditButton, HeroImageEditButtonProps } from '../HeroImage'
import { DetailPageHeader } from '../DetailPage'
import { ItemRepresentation, ProjectTypeImage } from '../ItemImage'
import { Lookups } from '../lookups'
import { OpenProjectIcon } from '../Icons'
import { withTooltip } from '../../lib'
import { DownloadButton } from '../Download'
import { PendingZips } from '../../types'
import { validate } from '../../lib'
import { EditableTypography } from '../EditableTypography'
import { useTheme } from '@mui/system'

export interface HeaderProps {
  getHeroUploadTarget: HeroImageEditButtonProps['getUploadTarget']
  lookups: Lookups
  onHeroChange: HeroImageEditButtonProps['onChange']
  onNameChange: (newValue: string) => Promise<unknown>
  onSummaryChange: (newValue: string) => Promise<unknown>
  onStarChange: (newValue: boolean) => void
  downloadZipFile: () => Promise<void>
  pendingZips: PendingZips
  project: NonNullable<GetProjectDetailQuery['project']>
}

export const Header: React.FC<HeaderProps> = ({
  getHeroUploadTarget,
  lookups,
  onHeroChange,
  onStarChange,
  onNameChange,
  onSummaryChange,
  downloadZipFile,
  pendingZips,
  project,
}) => {
  const theme = useTheme()
  const webRaveUrl = lookups.getWebRaveUrl(project.id)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const canEdit = Boolean(project.permissions.update)
  const pendingSince = project && pendingZips[project.id]
  return (
    <DetailPageHeader>
      <HeroImage url={project.heroImage?.lg} />
      <Container maxWidth="lg">
        <Typography variant="overline" sx={{ p: 0, m: 0 }} className="heroText">
          Riverscape Project
        </Typography>
        <Stack direction="column" justifyContent="center" p={2} spacing={2} height="100%">
          <Stack direction={isMdUp ? 'row' : 'column'} spacing={1} alignItems="center" sx={{ color: 'white' }}>
            <ProjectTypeImage item={project.projectType} size={40} tooltip />
            <EditableTypography
              className="heroText"
              canEdit={canEdit}
              content={project.name}
              getError={validate.name}
              name="Name"
              headerMode
              onChange={onNameChange}
              variant="h3"
            />
            <ShiftRight />
            <Stack direction="row">
              <FavoriteStar value={project.starred} size={24} onChange={onStarChange} className="heroIcon" />
              <DownloadButton
                onClick={downloadZipFile}
                pendingSince={pendingSince}
                title={project.name}
                className="heroIcon"
              />
              <ShareButton title={project.name} url={lookups.getProjectUrlById(project.id)} className="heroIcon" />
              {withTooltip(
                <IconButton size="large" color="primary" href={webRaveUrl}>
                  <OpenProjectIcon className="heroIcon" />
                </IconButton>,
                'Open Map Viewer'
              )}
            </Stack>
          </Stack>
          <Stack direction={isMdUp ? 'row' : 'column'} spacing={2}>
            {project.sponsor && (
              <Stack direction="column" alignItems="center">
                <Typography variant="caption" className="heroText">
                  Sponsor
                </Typography>
                <ItemRepresentation
                  lookups={lookups}
                  item={project.sponsor}
                  link
                  size="large"
                  color="white"
                  hideName
                  tooltipPrefix="Project sponsor:"
                />
              </Stack>
            )}
            <Stack direction="column" alignItems="center">
              <Typography variant="caption" className="heroText">
                Owner
              </Typography>
              <ItemRepresentation
                lookups={lookups}
                item={project.ownedBy}
                link
                size="large"
                color="white"
                hideName
                tooltipPrefix="Project owned by:"
              />
            </Stack>
            <EditableTypography
              className="heroText"
              content={project.summary}
              canEdit={canEdit}
              getError={validate.summary}
              name="Summary"
              onChange={onSummaryChange}
              headerMode
              placeholder={canEdit && <></>}
            />
          </Stack>
        </Stack>
      </Container>
      {canEdit && (
        <HeroImageEditButton
          sx={{ position: 'absolute', right: 0, top: 0 }}
          className="heroIcon"
          getUploadTarget={getHeroUploadTarget}
          lookups={lookups}
          onChange={onHeroChange}
          url={project.heroImage?.lg}
        />
      )}
    </DetailPageHeader>
  )
}
