/** @jsxImportSource @emotion/react */
import { memo, useMemo } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { MetaDataTypeEnum } from '../../schema/base'

const STRING_NULL_VALUE = 'NULL'

const allOptions = [
  { value: STRING_NULL_VALUE, label: <em>unspecified</em> },
  { value: MetaDataTypeEnum.Boolean, label: 'Boolean' },
  { value: MetaDataTypeEnum.Filepath, label: 'File Path' },
  { value: MetaDataTypeEnum.Float, label: 'Float' },
  { value: MetaDataTypeEnum.Guid, label: 'GUID' },
  { value: MetaDataTypeEnum.Image, label: 'Image' },
  { value: MetaDataTypeEnum.Int, label: 'Int' },
  { value: MetaDataTypeEnum.Isodate, label: 'ISO Date' },
  { value: MetaDataTypeEnum.Json, label: 'JSON' },
  { value: MetaDataTypeEnum.Markdown, label: 'Markdown' },
  { value: MetaDataTypeEnum.Richtext, label: 'Rich Text' },
  { value: MetaDataTypeEnum.String, label: 'String' },
  { value: MetaDataTypeEnum.Hidden, label: 'System' },
  { value: MetaDataTypeEnum.Timestamp, label: 'Timestamp' },
  { value: MetaDataTypeEnum.Url, label: 'URL' },
  { value: MetaDataTypeEnum.Video, label: 'Video' },
]

const legacyValues = [MetaDataTypeEnum.Richtext] as MetaDataTypeEnum[]

export interface MetadataTypeFieldProps {
  value: MetaDataTypeEnum | null
  disabled?: boolean
  onChange: (newValue: MetaDataTypeEnum | null) => void
}

export const MetadataTypeField = memo<MetadataTypeFieldProps>(function MetadataTypeField({
  value,
  disabled,
  onChange,
}) {
  const handleChange = ({ target: { value: newValue } }: SelectChangeEvent) => {
    onChange(newValue === STRING_NULL_VALUE ? null : (newValue as MetaDataTypeEnum))
  }

  const options = useMemo(() => {
    if (value && legacyValues.includes(value)) return allOptions
    return allOptions.filter((option) => !legacyValues.includes(option.value as MetaDataTypeEnum))
  }, [value])

  return (
    <Select fullWidth disabled={disabled} value={value ?? STRING_NULL_VALUE} onChange={handleChange} size="small">
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
})
