import { useState, ReactNode } from 'react'
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { ModifySearchDialog, ProjectResults, ProjectResultsProps, SearchSpec } from '../Search'
import { GetCollectionDetailQuery } from '../../schema/base'
import { LogicError, useBooleanState, useFormats } from '../../lib'
import { AddProjectDialog } from './AddProjectDialog'
import { Lookups } from '../lookups'
import { RemoveProjectDialog } from './RemoveProjectDialog'
import { Search as SearchIcon } from '@mui/icons-material'

export type ProjectsPanelProps = {
  collection: NonNullable<GetCollectionDetailQuery['collection']>
  lookups: Lookups
  mapElements: ReactNode
  onAddProjectById: (id: string) => void
  onRemoveProjectById: (id: string) => void
  onSearch: (newSearch: SearchSpec) => void
  pageOffset?: number
} & Omit<
  ProjectResultsProps,
  keyof {
    NoResults: never
    children: never
    getProjectUrlById: never
    type: never
  }
>

export const ProjectsPanel: React.FC<ProjectsPanelProps> = ({
  collection,
  getProjectBoundsThumbProps,
  items,
  lookups,
  mapBoxRef,
  mapController,
  mapElements,
  onGotoWebRave,
  onAddProjectById,
  onRemoveProjectById,
  onMapClick,
  onMapHover,
  onSearch,
  onSortChange,
  onFetchMoreProjects,
  loadingHasMore,
  loadingProjects,
  onViewTypeChange,
  pageOffset,
  viewType,
  sort,
}) => {
  const [isModifySearchOpen, openModifySearch, closeModifySearch] = useBooleanState()
  const [isAddProjectOpen, openAddProject, closeAddProject] = useBooleanState()
  const [isRemoveProjectOpen, openRemoveProject, closeRemoveProject] = useBooleanState()
  const [targetProjectId, setTargetProjectId] = useState<null | string>(null)

  const { getProjectOptionsByName } = lookups
  const { formatNumber, pluralize } = useFormats()

  const projectsTotal = collection.projects.total

  const handleProjectRemoveClick = collection.permissions.update
    ? (projectId: string) => {
        setTargetProjectId(projectId)
        openRemoveProject()
      }
    : null

  const removeTargetProject = () => {
    if (!targetProjectId) throw new LogicError()
    onRemoveProjectById(targetProjectId)
  }

  // RENDER
  let showingText
  const loadedItems = (items || []).length
  if (loadedItems === pageOffset) {
    if (loadedItems > 0)
      showingText = (
        <Typography variant="caption" color="text.secondary">
          {loadedItems} loaded.
        </Typography>
      )
  } else {
    showingText = (
      <Typography variant="caption" color="text.secondary">
        Loaded {loadedItems} and {(pageOffset || loadedItems) - loadedItems} are invisible to you.
      </Typography>
    )
  }

  return (
    <>
      <Stack sx={{ height: '100%', gap: 1 }}>
        <Stack direction="row" sx={{ p: 2, borderBottom: 1, gap: 2, borderColor: 'divider' }}>
          <Stack direction="column" sx={{ flex: 1 }}>
            <Typography variant="h6">
              <strong>{formatNumber(projectsTotal)}</strong> total {pluralize('project', projectsTotal)} in{' '}
              <strong>{collection.name}</strong>
            </Typography>
            {showingText}
            <Box>
              <Button disabled={!projectsTotal} onClick={openModifySearch}>
                Search Within Collection
              </Button>
            </Box>
          </Stack>
          <Stack>
            <Button disabled={!collection.permissions.update} onClick={openAddProject}>
              Add a Project to This Collection
            </Button>
            <Tooltip title="Search for projects within this collection using the fullscreen map">
              <Button
                startIcon={<SearchIcon />}
                onClick={() =>
                  onSearch({
                    params: {
                      collection: collection.id,
                    },
                    type: 'Project',
                  })
                }
              >
                Full map search
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Box sx={{ flex: 1, height: 0 }}>
          <ProjectResults
            maxHeight={600}
            contained
            onViewTypeChange={onViewTypeChange}
            viewType={viewType}
            onFetchMoreProjects={onFetchMoreProjects}
            loadingHasMore={loadingHasMore}
            loadingProjects={loadingProjects}
            NoResults={() => (
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '2em',
                }}
              >
                Collection does not contain any projects.
              </Box>
            )}
            getProjectBoundsThumbProps={getProjectBoundsThumbProps}
            onGotoWebRave={onGotoWebRave}
            items={items}
            pageOffset={pageOffset}
            lookups={lookups}
            mapBoxRef={mapBoxRef}
            mapController={mapController}
            onMapClick={onMapClick}
            onMapHover={onMapHover}
            onProjectRemoveClick={handleProjectRemoveClick}
            onSortChange={onSortChange}
            sort={sort}
            type="Project"
          >
            {mapElements}
          </ProjectResults>
        </Box>
      </Stack>
      <ModifySearchDialog
        open={isModifySearchOpen}
        onClose={closeModifySearch}
        lookups={lookups}
        onSearch={onSearch}
        type="Project"
        params={{
          collection: collection.id,
        }}
        disabled={{ type: true, collection: true }}
        canSearchClean
      />
      <AddProjectDialog
        collectionName={collection.name}
        getProjectOptionsByName={getProjectOptionsByName}
        open={isAddProjectOpen}
        onClose={closeAddProject}
        onChoose={onAddProjectById}
      />
      <RemoveProjectDialog
        collectionName={collection.name}
        open={isRemoveProjectOpen}
        onClose={closeRemoveProject}
        onConfirm={removeTargetProject}
      />
    </>
  )
}
