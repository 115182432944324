/** @jsxImportSource @emotion/react */
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { OrganizationRoleEnum, OrganizationInviteRoleEnum } from '../../schema/base'
import { WarningIcon } from '../Icons'

export type RoleFieldProps =
  | {
      isInvite?: false
      allowOwner: boolean
      value: OrganizationRoleEnum
      onChange: (newValue: OrganizationRoleEnum) => void
    }
  | {
      isInvite: true
      allowOwner?: never
      value: OrganizationInviteRoleEnum
      onChange: (newValue: OrganizationInviteRoleEnum) => void
    }

const Explainer = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" sx={{ ml: '2rem' }}>
    {children}
  </Typography>
)

export const RoleField: React.FC<RoleFieldProps> = ({ value, onChange, isInvite, allowOwner }) => {
  const handleChange = isInvite
    ? (newValue: string) => onChange(newValue as OrganizationInviteRoleEnum)
    : (newValue: string) => onChange(newValue as OrganizationRoleEnum)

  return (
    <FormControl>
      <FormLabel>Role</FormLabel>
      <RadioGroup value={value} onChange={(e, newValue) => handleChange(newValue)}>
        {allowOwner && (
          <>
            <FormControlLabel value={OrganizationRoleEnum.Owner} control={<Radio />} label="Owner" />
            <Explainer>
              User has full control of organization including deleting organization and assiging owners.
            </Explainer>
            {value === OrganizationRoleEnum.Owner && (
              <Explainer>
                <WarningIcon color="warning" />
                Caution: This user will be able to delete the organization and assign new owners.
              </Explainer>
            )}
          </>
        )}
        <FormControlLabel value={OrganizationRoleEnum.Admin} control={<Radio />} label="Administrator" />
        <Explainer>Can edit organization (including membership) and everything belonging to organization.</Explainer>
        <FormControlLabel value={OrganizationRoleEnum.Contributor} control={<Radio />} label="Contributor" />
        <Explainer>Can upload and delete projects and collections belonging to organization.</Explainer>
        <FormControlLabel value={OrganizationRoleEnum.Viewer} control={<Radio />} label="Viewer" />
        <Explainer>Can see and download all projects (Public, Private AND Secret) for a given organization.</Explainer>
      </RadioGroup>
    </FormControl>
  )
}
