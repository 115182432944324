import { IconButton } from '@mui/material'
import { useBooleanState, withTooltip } from '../../lib'
import { Bookmark, BookmarkBorder } from '@mui/icons-material'

export interface FavoriteStarProps {
  className?: string
  value: boolean
  onChange: (newValue: boolean) => void
  size: number
}

export const FavoriteStar: React.FC<FavoriteStarProps> = ({ value, size, onChange, className }) => {
  const Icon = value ? Bookmark : BookmarkBorder
  const [isHovering, startHovering, stopHovering] = useBooleanState()
  return withTooltip(
    <IconButton onClick={() => onChange(!value)}>
      <Icon
        sx={{ height: size, width: size, cursor: 'pointer' }}
        color={isHovering ? 'info' : value ? 'secondary' : undefined}
        onMouseOver={startHovering}
        onMouseOut={stopHovering}
        className={className}
      />
    </IconButton>,
    value ? 'Remove Bookmark' : 'Add Bookmark'
  )
}
