import React from 'react'

import { Project, ProjectTreeLeaf, TileService } from '@riverscapes/react-common'

import LayerMeta from './LayerMeta'
import LayerTileInfo from './LayerTileInfo'
import { Box, IconButton, SxProps, Theme, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import { Close, ContentCopy } from '@mui/icons-material'
import { LayerIcon, MetaData } from '@riverscapes/react-common'
import { DSProjectRef } from '../../types'
import { useSnackbar } from 'notistack'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    flex: '0 0 ',
    '&.MuiToolbar-root': {
      pl: 2,
      pr: 0,
      py: 1,
    },
    minHeight: theme.spacing(6),
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  spacer: { flexGrow: 1 },
  layerTitle: {},
  layerIcon: {},
  layerMeta: {
    flexGrow: 1,
    overflowY: 'scroll',
    userSelect: 'text',
    '& *': {
      userSelect: 'text',
    },
  },
  typeContainer: {
    // borderRadius: theme.spacing(1),
    // background: '#FFFFFF22',
    // margin: theme.spacing(1),
    // padding: theme.spacing(0, 1),
    // border: '1px solid #FFFFFF22'
  },
  copyButton: {
    // ml: theme.spacing(2)
  },
  closeButton: {
    // ml: theme.spacing(2)
  },
})

interface LayerInfoProps {
  leaf: ProjectTreeLeaf
  tiles?: TileService
  dsMeta: MetaData[]
  dsProjectRef?: DSProjectRef | null
  handleClose: () => void
  project: Project | null
  gotoWHProject: (projId: string) => void
}

const LayerInfo: React.FC<LayerInfoProps> = ({
  leaf,
  tiles,
  handleClose,
  dsMeta,
  dsProjectRef,
  project,
  gotoWHProject,
}: LayerInfoProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box sx={styles.root}>
      <Toolbar sx={styles.toolbar}>
        <Tooltip title={`This is a ${leaf.layerType} layer`}>
          <IconButton edge="start" sx={styles.layerIcon} color="inherit" aria-label="menu">
            <LayerIcon layerType={leaf.layerType} />
          </IconButton>
        </Tooltip>
        <Typography variant="body2" sx={styles.layerTitle}>
          {leaf.label}
        </Typography>
        <Box sx={styles.spacer} />
        <Tooltip title={`Copy layer meta to clipboard (as JSON)`}>
          <IconButton
            edge="start"
            sx={styles.copyButton}
            color="inherit"
            aria-label="copy"
            onClick={() => {
              if (!project) return
              // Copy all metadata to clipboard
              const meta = dsMeta.map(({ __typename, ...rest }) => rest)
              const newObj = {
                projectId: project?.id,
                projectName: project?.name,
                layerName: leaf.lyrName,
                originalProject: dsProjectRef
                  ? {
                      id: dsProjectRef.id,
                      name: dsProjectRef.name,
                      projectTypeId: dsProjectRef.projectType.id,
                      projectTypeName: dsProjectRef.projectType.name,
                    }
                  : null,
                label: leaf.label,
                filePath: leaf.filePath,
                symbology: leaf.symbology,
                transparency: leaf.transparency,
                rsXPath: leaf.rsXPath,
                meta,
              }
              const metadataString = JSON.stringify(newObj, null, 2)
              navigator.clipboard.writeText(metadataString)
              enqueueSnackbar('Layer Metadata copied to clipboard!', { variant: 'info', autoHideDuration: 1500 })
            }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
        <IconButton edge="start" sx={styles.closeButton} color="inherit" aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </Toolbar>
      <Box sx={styles.layerMeta}>
        {leaf && <LayerMeta leaf={leaf} dsMeta={dsMeta} dsProjectRef={dsProjectRef} gotoWHProject={gotoWHProject} />}
        {tiles && <LayerTileInfo tiles={tiles} />}
      </Box>
    </Box>
  )
}

export default LayerInfo
