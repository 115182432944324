import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import Loading from '../Loading'

const MapLoading: React.FC = () => {
  return (
    <Loading
      logoSVG={<CircularProgress size="3rem" thickness={4} />}
      status={
        <Typography variant="h5" component="div">
          Loading Map...
        </Typography>
      }
      containerProps={{
        maxWidth: 'xl',
        style: {
          position: 'absolute',
          zIndex: 1000,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: '#FFFFFF33',
        },
      }}
    />
  )
}

export default MapLoading
