import { ApolloCache, FieldPolicy, InMemoryCache } from '@apollo/client'
import { StrictTypedTypePolicies } from '@riverscapes/react-common'

/**
 * This is a helper function to evict all items from the cache. It is useful for
 * when you want to ensure that the cache is up to date with the server.
 *
 * This is a little too 'nuclear' but it does the job. Since mutations are not
 * super common in this system hopefully wiping all queries is not too big a deal.
 * @param cache
 */
export function evictAllQueries(cache: ApolloCache<any>) {
  cache.evict({ id: 'ROOT_QUERY' })
}

/*
  Use paginatedItemsFieldPolicy where field is paginated items which are serially retrieved until complete. e.g. { items: T[], ...rest }
  
  Use paginatedKeyedItemsFieldPolicy where the field is an object and each property is paginated items which are serially retrieved until complete. e.g. Record<string, { items: T[], ...rest }>

  In both cases don't forget to get the root item `id` when querying the paged items!
*/

const paginatedItemsFieldPolicy: FieldPolicy = {
  keyArgs: false, // include these arguments in the cache key,
  merge: (existing = {}, incoming, { args }) => {
    // If limit is 0, return the existing data without merging
    if (args && args.limit === 0) {
      return existing
    }
    const merged = {
      ...existing,
      ...incoming,
      items: [...(existing.items || []), ...(incoming.items || [])],
      total: incoming.total,
      // N.B. offset and limit are meaningless to cache but must be included to ensure a cache hit
      limit: 0,
      offset: 0,
    }
    return merged
  },
}

const paginatedSearchResultsFieldPolicy: FieldPolicy = {
  keyArgs: () => {
    return ['type', 'page', 'sort', 'minScore', 'params']
  },
  merge: (existing = {}, incoming) => {
    const merged = { ...existing, ...incoming }
    if (
      existing.results &&
      incoming.results &&
      (typeof incoming.total === 'undefined' || incoming.results.length < incoming.total)
    ) {
      /* 
        We have existing results and incoming results, and total is less than incoming count.
        Having incoming item count which matches the a total indicates we should skip the
        merge (very possibly because it is a cache update, but should be safe regardless.)
      */
      merged.results = [...existing.results, ...incoming.results]
    }
    // N.B. offset and limit are meaningless to cache but must be included to ensure a cache hit
    return {
      ...merged,
      limit: 0,
      offset: 0,
    }
  },
}

const paginatedKeyedItemsFieldPolicy: FieldPolicy = {
  keyArgs: false,
  merge: (existing = {}, incoming) => {
    const merged = { ...existing, ...incoming }
    Object.keys(incoming).forEach((key) => {
      if (existing[key] && existing[key].results && incoming[key].results)
        merged[key].results = [...existing[key].results, ...incoming[key].results]
    })
    return merged
  },
}

const typePolicies: StrictTypedTypePolicies = {
  Project: {
    keyFields: ['id'],
    fields: {
      datasets: paginatedItemsFieldPolicy,
      qaqc: paginatedItemsFieldPolicy,
    },
  },
  Profile: {
    keyFields: [], // singleton type, no ID
    fields: {
      projects: paginatedItemsFieldPolicy,
      organizations: paginatedItemsFieldPolicy,
      organizationInvites: paginatedItemsFieldPolicy,
      stars: paginatedKeyedItemsFieldPolicy,
    },
  },
  User: {
    keyFields: ['id'],
    fields: {
      projects: paginatedItemsFieldPolicy,
      collections: paginatedItemsFieldPolicy,
      organizations: paginatedItemsFieldPolicy,
      // savedSearches: paginatedItemsFieldPolicy,
    },
  },
  Collection: {
    keyFields: ['id'],
    fields: {
      projects: paginatedItemsFieldPolicy,
    },
  },
  Organization: {
    keyFields: ['id'],
    fields: {
      projects: paginatedItemsFieldPolicy,
      organizationInvites: paginatedItemsFieldPolicy,
      organizationUsers: paginatedItemsFieldPolicy,
    },
  },
  OrganizationUser: {
    keyFields: ['user', 'organization'],
  },
  Query: {
    fields: {
      searchProjects: paginatedSearchResultsFieldPolicy,
      // using keyArgs: false here because we want all searches to be unique
      // and not build up in the cache:
      // NOTE: THIS MUST BE USED WITH:     fetchPolicy: 'network-only',
      // OR THE MAP WILL STOP WORKING
      searchMapClusters: {
        keyArgs: false,
      },
      searchMapBounds: {
        keyArgs: false,
      },
    },
  },
}

export const cache = new InMemoryCache({
  typePolicies,
})
