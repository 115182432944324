import React from 'react'
import { Button, Skeleton } from '@mui/material'
import { GetProfileOrganizationInvitesQuery } from '../../schema/base'

export interface RequestOrganizationInviteButtonProps {
  onClick: () => void
  organizationName?: string
  organizationId: string
  profileOrganizationInvites:
    | NonNullable<GetProfileOrganizationInvitesQuery['profile']>['organizationInvites']['items']
    | null
}

export const RequestOrganizationInviteButton: React.FC<RequestOrganizationInviteButtonProps> = ({
  onClick,
  organizationName,
  organizationId,
  profileOrganizationInvites,
}) => {
  if (!profileOrganizationInvites) return <Skeleton sx={{ width: '5em' }} />
  if (profileOrganizationInvites.find((invite) => invite.organization.id === organizationId))
    return (
      <Button variant="outlined" disabled>
        Membership Requested{organizationName ? ` for ${organizationName}` : ''}
      </Button>
    )
  return (
    <Button variant="outlined" onClick={onClick}>
      Request Membership{organizationName ? ` to ${organizationName}` : ''}
    </Button>
  )
}
