import { Box, Button, Stack, Typography } from '@mui/material'
import { useBooleanState, useFormats } from '../../lib'
import { GetProjectDetailQuery } from '../../schema/base'
import { OtherResults, OtherResultsProps } from '../Search'
import { AddToCollectionDialog } from './AddToCollectionDialog'
import { Lookups } from '../lookups'
import { Menu } from '../Menu'
import { CreateCollectionDialog, CreateCollectionDialogProps } from '../CreateCollectionDialog'
import { AutocompleteSelectOption } from '../AutocompleteSelectField'
import { AddCircle, AddToPhotos } from '@mui/icons-material'

export type CollectionsPanelProps = {
  lookups: Lookups
  project: NonNullable<GetProjectDetailQuery['project']>
  onAddToCollectionById: (id: string) => void
  onCreateCollection: CreateCollectionDialogProps['onConfirm']
  onRemoveProjectFromCollection: (collectionId: string) => void
  organizationOptions: AutocompleteSelectOption[]
} & Omit<
  OtherResultsProps,
  keyof {
    NoResults: never
    getItemUrlById: never
    type: never
  }
>

export const CollectionsPanel: React.FC<CollectionsPanelProps> = ({
  lookups,
  onAddToCollectionById,
  onCreateCollection,
  organizationOptions,
  onRemoveProjectFromCollection,
  project,
  ...otherResultsProps
}) => {
  const [isAddToCollectionOpen, openAddToCollection, closeAddToCollection] = useBooleanState()
  const [isCreateCollectionOpen, openCreateCollection, closeCreateCollection] = useBooleanState()

  const { getEditableCollectionOptionsByName } = lookups
  const { formatNumber, pluralize } = useFormats()

  const collectionsTotal = project.collections.total

  return (
    <>
      <Stack sx={{ height: '100%', gap: 1 }}>
        <Stack direction="row" alignItems="center" sx={{ p: 2, borderBottom: 1, gap: 2, borderColor: 'divider' }}>
          <Stack direction="column" sx={{ flex: 1 }}>
            <Typography variant="h6">
              This project appears in <strong>{formatNumber(collectionsTotal)}</strong>{' '}
              {pluralize('collection', collectionsTotal)}
            </Typography>
          </Stack>
          <Box>
            <Menu
              Button={(buttonProps) => <Button {...buttonProps}>Add to Collection</Button>}
              options={[
                {
                  key: 'openCreateCollection',
                  label: (
                    <>
                      <AddCircle color="primary" sx={{ mr: 1 }} /> {`Create new collection containing this project`}
                    </>
                  ),
                  onClick: openCreateCollection,
                },
                {
                  key: 'openAddToCollection',
                  label: (
                    <>
                      <AddToPhotos color="secondary" sx={{ mr: 1 }} /> {`Add this project to existing collection`}
                    </>
                  ),
                  onClick: openAddToCollection,
                },
              ]}
            />
          </Box>
        </Stack>
        <Box sx={{ flex: 1, height: 0 }}>
          <OtherResults
            NoResults={() => <div>Project is not included in any collections.</div>}
            lookups={lookups}
            onRemoveFromCollectionClick={onRemoveProjectFromCollection}
            type="Collection"
            {...otherResultsProps}
          />
        </Box>
        <AddToCollectionDialog
          getCollectionOptionsByName={getEditableCollectionOptionsByName}
          onChoose={onAddToCollectionById}
          onClose={closeAddToCollection}
          open={isAddToCollectionOpen}
          projectName={project.name}
        />
        <CreateCollectionDialog
          onClose={closeCreateCollection}
          onConfirm={onCreateCollection}
          open={isCreateCollectionOpen}
          organizationOptions={organizationOptions}
        />
      </Stack>
    </>
  )
}
