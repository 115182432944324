import React, { useState } from 'react'
import { viewportAtom, renderableMapLayersSelector } from '../recoil'
import { useRecoilValue } from 'recoil'
import ZoomIndicator from '../components/map/ZoomIndicator'
import OverzoomedHelp from '../components/map/OverzoomedHelp'
import { useSnackbar } from 'notistack'
import { SxProps, Theme, useTheme } from '@mui/material'
import { ProjectTreeLayerTypeEnum } from '@riverscapes/react-common'
// import log from 'loglevel'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
})

const TreeToggleConnect: React.FC = () => {
  const [overZoomDlg, setOverZoomDlg] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const viewport = useRecoilValue(viewportAtom)
  const mapLayers = useRecoilValue(renderableMapLayersSelector)

  const minMaxRasterZoom: number = mapLayers
    .filter((lyr) => lyr.leaf.layerType === ProjectTreeLayerTypeEnum.Raster)
    .reduce<number>((acc, lyr) => {
      const lyrMaxZoom = lyr.tiles && lyr.tiles.maxZoom && lyr.tiles.maxZoom > 0 ? lyr.tiles.maxZoom : 100
      return Math.min(lyrMaxZoom, acc)
    }, 100)

  const overZoomed = Boolean(viewport && viewport.zoom && minMaxRasterZoom && viewport.zoom > minMaxRasterZoom)

  const cbCopy = () => {
    const { zoom, latitude, longitude, bearing, pitch } = viewport
    navigator.clipboard.writeText(JSON.stringify({ zoom, latitude, longitude, bearing, pitch }, null, 2))
    enqueueSnackbar('Coordinates copied to clipboard!', { variant: 'info', autoHideDuration: 1000 })
  }

  return (
    <>
      <ZoomIndicator
        sx={styles.root}
        viewport={viewport}
        onCopyClick={cbCopy}
        onHelpClick={() => setOverZoomDlg(true)}
        overZoomed={overZoomed}
      />
      <OverzoomedHelp open={overZoomDlg} onClose={() => setOverZoomDlg(false)} />
    </>
  )
}

export default TreeToggleConnect
