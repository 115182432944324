import { Stack, Card, CardContent, Typography, Link, useMediaQuery } from '@mui/material'
import { useFormats, validate } from '../../lib'
import { GetOrganizationDetailQuery } from '../../schema/base'
import { EditableTypography } from '../EditableTypography'
import { Lookups } from '../lookups'
import { CSSProperties } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useTheme } from '@mui/system'
import { OverviewCard } from '../Overview/OverviewCard'
import { OverviewBasicProperties } from '../Overview/OverviewBasicProperties'

export interface OverviewPanelProps {
  organization: NonNullable<GetOrganizationDetailQuery['organization']>
  onDescriptionChange: (newDescription: string) => Promise<unknown>
  // onTagsChange: (newValue: string[]) => void
  lookups: Lookups
}

export const OverviewPanel: React.FC<OverviewPanelProps> = ({ organization, onDescriptionChange, lookups }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const canEdit = Boolean(organization.permissions.update)
  const { formatNumber } = useFormats()

  const organizationProjectsUrl = lookups.getOrganizationUrlById(organization.id, 'projects')
  const organizationCollectionsUrl = lookups.getOrganizationUrlById(organization.id, 'collections')

  const scrollContainer: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
      }
    : {}
  const scrollObjInner: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
      }
    : {}

  return (
    <Grid2 container spacing={3} sx={scrollContainer}>
      <Grid2 xs={12} sm={12} md={8} sx={scrollObjInner}>
        <OverviewCard title="Basic Properties">
          <OverviewBasicProperties
            propTable={[
              { label: 'Organization Name', value: organization.name },
              { label: 'Organization Id', value: <code>{organization.id}</code> },
            ]}
          />
        </OverviewCard>
        <OverviewCard title="Description">
          <EditableTypography
            canEdit={canEdit}
            content={organization.description}
            getError={validate.description}
            multiline
            name="Description"
            onChange={onDescriptionChange}
            placeholder={'No description.'}
            variant="markdown"
          />
        </OverviewCard>
      </Grid2>
      <Grid2 xs={12} sm={12} md={4}>
        <Card>
          <CardContent sx={{ pt: 3 }}>
            <Stack
              sx={{ minWidth: 70, height: 50, flexShrink: 0 }}
              alignItems="center"
              justifyContent="space-around"
              direction={'row'}
            >
              <Link href={organizationProjectsUrl}>
                <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
                  <Typography variant="overline">Projects Owned</Typography>
                  <Typography variant="h3">{formatNumber(organization.projects.total)}</Typography>
                  {/* <Typography variant="overline">{pluralize('project', collection.projects.total)}</Typography> */}
                </Stack>
              </Link>
              <Link href={organizationCollectionsUrl}>
                <Stack sx={{ minWidth: 70, height: 50, flexShrink: 0 }} alignItems="center" justifyContent="center">
                  <Typography variant="overline">Collections</Typography>
                  <Typography variant="h3">{formatNumber(organization.collections.total)}</Typography>
                  {/* <Typography variant="overline">{pluralize('project', collection.projects.total)}</Typography> */}
                </Stack>
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  )
}
