import { Box, Theme, SxProps } from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'

export interface MarkdownProps {
  content: string
  typographySx?: SxProps<Theme>
}

export const Markdown = ({ content, typographySx }: MarkdownProps): React.ReactElement => {
  return (
    <Box sx={{ flex: 1, ...typographySx }}>
      {/* Use wrapper div as ReactMarkdown renders an array of real elements */}
      <ReactMarkdown
        components={{
          a: ({ children, ...props }) => (
            <a {...props} target="_blank">
              {children}
            </a>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </Box>
  )
}
