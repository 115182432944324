import { OrganizationRoleEnum, OrganizationInviteRoleEnum, OrganizationInviteStateEnum } from '../../schema/base'
import { Lookups } from '../lookups'

export type OrganizationMemberRow = {
  id: string
  user: { id: string; name: string; avatar?: string | null }
  role: OrganizationRoleEnum
}

export type OrganizationInviteRow = {
  id: string
  invitee: { id?: string; name: string; avatar?: string | null }
  role: OrganizationInviteRoleEnum
  state: OrganizationInviteStateEnum
  inviter: { name: string; avatar?: string | null }
}

export type ProfileInviteRow = {
  id: string
  // invitee: { id?: string; name: string; avatar?: string | null }
  role: OrganizationInviteRoleEnum
  state: OrganizationInviteStateEnum
  inviter: { name: string; avatar?: string | null }
  organization: { id: string; name: string; logo?: string | null }
}

export type ActionableRow = {
  actions: React.ReactNode
  lookups: Lookups
}

export const isOrganizationMemberRow = (
  row: OrganizationMemberRow | OrganizationInviteRow | null
): row is OrganizationMemberRow => {
  return !!row && !!(row as OrganizationMemberRow).user
}

export const isOrganizationInviteRow = (
  row: OrganizationMemberRow | OrganizationInviteRow | null
): row is OrganizationInviteRow => {
  return !!row && !!(row as OrganizationInviteRow).invitee
}

export const isProfileInviteRow = (
  row: OrganizationInviteRow | ProfileInviteRow | null
): row is OrganizationInviteRow => {
  return !!row && !!(row as ProfileInviteRow).organization
}
