import {
  DetailPageTabs,
  EntitiesWithImagesEnum,
  MissingDataError,
  PageTitle,
  ProjectDetailSkeleton,
  useNotifications,
  UserDetail as Components,
  OwnerInputTypesEnum,
  useActiveResults,
  pendingItems,
  PAGE_SIZE,
  SortOrderFieldProps,
  Bbox,
  useInteractiveMapController,
  ProjectBoundsThumbProps,
  CollectionInput,
  LogicError,
  StarredPanel,
  Footer,
  INFINISCROLLER_PAGE_SIZE,
  SearchViewTypeEnum,
} from '@riverscapes/react-common'
import { useLookups, useProfile } from '../lib'
import { profileDetail, search, useGotoRoute, webRaveDetail } from '../routing'
import {
  evictAllQueries,
  useGetImageUploadTargetLazyQuery,
  useGetProfileDetailQuery,
  useUpdateProfileAvatarImageMutation,
  useUpdateProfileNameMutation,
  useUpdateProfileSummaryMutation,
} from '../data'
import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { useAuth } from '@riverscapes/react-api'
import { useProjectsMap } from './Search/useProjectsMap'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useCreateCollectionMutation,
  useGetUserCollectionsQuery,
  useGetUserProjectsQuery,
  useUpdateProfileDescriptionMutation,
} from '@riverscapes/react-common/dist/schema/operations'
import { useAllMyStars } from '../lib/useAllMyStars'

const { Header, OverviewPanel, SettingsPanel, ProjectsPanel, CollectionsPanel } = Components

export interface ProfileDetailContainerProps extends JSX.IntrinsicAttributes {
  tab?: string
  viewType?: SearchViewTypeEnum
}

export const ProfileDetailContainer: React.FC<ProfileDetailContainerProps> = ({ tab = 'overview' }) => {
  const basicProfile = useProfile()
  if (!basicProfile) return null
  return <ProfileDetail id={basicProfile?.id ?? ''} tab={tab} />
}

export interface ProfileDetailProps extends JSX.IntrinsicAttributes {
  id: string
  tab?: string
  viewType?: SearchViewTypeEnum
}

export const ProfileDetail: React.FC<ProfileDetailProps> = ({
  id,
  tab = 'overview',
  viewType = SearchViewTypeEnum.Map,
}) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const gotoWebRave = useGotoRoute(webRaveDetail)

  const gotoSelf = useGotoRoute(profileDetail)
  const { changePassword, email } = useAuth()
  const { catchError, show } = useNotifications()
  const gotoSearch = useGotoRoute(search)
  const [projectPaginationOffset, setProjectPaginationOffset] = useState(INFINISCROLLER_PAGE_SIZE) // Remember that this doesn't get used until page 2

  const lookups = useLookups()
  const basicProfile = useProfile()

  // HEADER

  // queries
  const [getImageUploadTarget] = useGetImageUploadTargetLazyQuery()
  const getAvatarUploadTarget = async () => {
    const entityId = basicProfile?.id ?? '' // TODO: more elegant way?
    const result = await getImageUploadTarget({
      variables: { entityId, entityType: EntitiesWithImagesEnum.User },
    })
    const target = result.data?.requestUploadImage
    if (!target) throw new MissingDataError()
    return target
  }

  // MUTABLES

  const [updateProfileName] = useUpdateProfileNameMutation()
  const handleNameChange = (newName: string) =>
    updateProfileName({ variables: { name: newName } }).catch(catchError('Failed to update profile name', false))

  const [updateProfileSummary] = useUpdateProfileSummaryMutation()
  const handleSummaryChange = (newSummary: string) =>
    updateProfileSummary({ variables: { summary: newSummary } }).catch(
      catchError('Failed to update profile summary', false)
    )

  const [updateProfileDescription] = useUpdateProfileDescriptionMutation()
  const handleDescriptionChange = (newDescription: string) =>
    updateProfileDescription({ variables: { description: newDescription } }).catch(
      catchError('Failed to update profile description', false)
    )

  const [updateProfileLogoImage] = useUpdateProfileAvatarImageMutation()
  const handleAvatarChange = (newToken: string | null) =>
    updateProfileLogoImage({ variables: { token: newToken as string | undefined, clear: !newToken } }).catch(
      catchError(`Failed to ${newToken ? 'update' : 'remove'} profile avatar`, false)
    )

  const setTab = (newTab: string) => gotoSelf({ tab: newTab }, true)
  const setViewType = (newViewType: SearchViewTypeEnum) => gotoSelf({ tab, viewType: newViewType }, true)

  // USER QUERY

  const { data, loading, error } = useGetProfileDetailQuery({ variables: {} })

  // PASSWORD
  const handleSetPassword = (): Promise<boolean> => {
    return changePassword()
      .then((retval) => {
        if (!retval) return false
        show('Email Sent!', {
          variant: 'success',
        })
        return true
      })
      .catch(() => {
        catchError('Failed to set password', true)
        return false
      })
  }

  // Project PANEL

  // projects (paged)
  const [projectsSort, setProjectsSort] = useState<SortOrderFieldProps['sort']>(null)
  const mapBoxRef = useRef<HTMLDivElement>(null)
  const {
    data: projectsData,
    fetchMore: fetchMoreProjects,
    loading: searchLoadingProjects,
  } = useGetUserProjectsQuery({
    variables: {
      id,
      limit: INFINISCROLLER_PAGE_SIZE,
      offset: 0,
      sort: projectsSort && [projectsSort],
    },
    notifyOnNetworkStatusChange: true,
    skip: tab !== 'projects',
    onError: catchError('Failed to get collection projects', false),
  })
  // Fetch the next page of projects
  const theoreticalTotal = projectsData?.user?.projects?.total ?? 0
  const projectsHasNext = Boolean(theoreticalTotal > projectPaginationOffset)
  const fetchNextPage = () => {
    if (projectsHasNext && !searchLoadingProjects) {
      fetchMoreProjects({
        variables: {
          offset: projectPaginationOffset,
        },
      }).then(() => {
        setProjectPaginationOffset((prevOffset) => {
          return prevOffset + INFINISCROLLER_PAGE_SIZE > theoreticalTotal
            ? theoreticalTotal
            : prevOffset + INFINISCROLLER_PAGE_SIZE
        })
      })
    }
  }

  const projectStats = useActiveResults({}, data?.profile?.projects.total)
  const displayItems = projectsData?.user?.projects.items ?? pendingItems(projectStats.expectedCount)

  const [bbox, setBbox] = useState<Bbox | undefined>()
  const [hoveredBoundsIds, setHoveredBoundsIds] = useState<string[]>([])
  const mapController = useInteractiveMapController({
    onBboxChange: setBbox,
  })

  const getProjectBoundsThumbProps = useCallback(
    (bounds: { id: string; polygonUrl: string; bbox: Bbox }): Partial<ProjectBoundsThumbProps> => ({
      onZoomClick: () => mapController.fitBboxes([bounds.bbox]),
      onHoverChange: (newHover: boolean) => {
        setHoveredBoundsIds(newHover ? [bounds.id] : [])
      },
      highlight: hoveredBoundsIds.includes(bounds.id),
      visibilityBbox: bbox,
    }),
    [hoveredBoundsIds, bbox]
  )
  const {
    handleMapClick,
    handleMapHover,
    mapElements,
    popupState: { ids: popupBoundsIds },
  } = useProjectsMap({
    params: {
      ownedBy: {
        id: basicProfile?.id as string,
        type: OwnerInputTypesEnum.Organization,
      },
    },
    mapBoxRef,
    lookups,
    bbox,
    mapController,
    getProjectBoundsThumbProps,
    highlightBoundsIds: hoveredBoundsIds,
  })

  useEffect(() => {
    setHoveredBoundsIds(popupBoundsIds)
  }, [popupBoundsIds])

  // COLLECTIONS PANEL

  const [collectionsPage, setCollectionsPage] = useState(1)
  const [collectionsSort, setCollectionsSort] = useState<SortOrderFieldProps['sort']>(null)

  const { data: collectionsData } = useGetUserCollectionsQuery({
    variables: {
      id,
      limit: PAGE_SIZE,
      offset: (collectionsPage - 1) * PAGE_SIZE,
      sort: collectionsSort && [collectionsSort],
    },
    skip: tab !== 'collections',
    onError: catchError('Failed to get project collections', false),
  })

  const collectionStats = useActiveResults({ page: collectionsPage }, data?.profile?.collections.total)

  const collections = collectionsData?.user?.collections.items ?? pendingItems(collectionStats.expectedCount)

  const [createCollection] = useCreateCollectionMutation()
  const handleCreateCollection = async ({ ...restCollection }: { orgId?: string } & Partial<CollectionInput>) => {
    let collectionId: string | undefined

    // create collection
    try {
      const newCollection = await createCollection({
        variables: {
          collection: restCollection,
        },
        update: evictAllQueries,
      })
      if (!newCollection.data?.createCollection) throw new MissingDataError()
      collectionId = newCollection.data.createCollection.id
    } catch (err) {
      catchError('Failed to create new collection', true)(err)
    }

    if (!collectionId) throw new LogicError()
  }

  // Bookmarks PANEL
  const allStarred = useAllMyStars({ profile: data?.profile, skip: tab !== 'bookmarks' })

  // RENDER

  if (error) throw error
  if (loading) return <ProjectDetailSkeleton />
  if (!data?.profile) throw new MissingDataError()

  const { profile } = data

  return (
    <>
      <PageTitle title="Profile" />
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header
          getAvatarUploadTarget={getAvatarUploadTarget}
          onAvatarChange={handleAvatarChange}
          onNameChange={handleNameChange}
          onSummaryChange={handleSummaryChange}
          user={profile}
          email={email}
          lookups={lookups}
        />
        <Container
          maxWidth="lg"
          sx={{
            flexGrow: 1,
            minHeight: 600,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DetailPageTabs
            value={tab}
            onChange={setTab}
            tabs={[
              {
                name: 'overview',
                content: (
                  <OverviewPanel user={profile} lookups={lookups} onDescriptionChange={handleDescriptionChange} />
                ),
              },
              {
                name: 'bookmarks',
                content: <StarredPanel user={profile} lookups={lookups} allStarred={allStarred} />,
              },
              {
                name: 'projects',
                content: (
                  <ProjectsPanel
                    user={profile}
                    onFetchMoreProjects={fetchNextPage}
                    loadingHasMore={projectsHasNext}
                    loadingProjects={searchLoadingProjects}
                    getProjectBoundsThumbProps={getProjectBoundsThumbProps}
                    onGotoWebRave={(id: string) => gotoWebRave({ id })}
                    items={displayItems}
                    pageOffset={projectPaginationOffset > theoreticalTotal ? theoreticalTotal : projectPaginationOffset}
                    lookups={lookups}
                    mapBoxRef={mapBoxRef}
                    mapController={mapController}
                    mapElements={mapElements}
                    onMapClick={handleMapClick}
                    onMapHover={handleMapHover}
                    onSearch={gotoSearch}
                    onSortChange={setProjectsSort}
                    sort={projectsSort}
                    onViewTypeChange={setViewType}
                    viewType={viewType}
                  />
                ),
              },
              {
                name: 'collections',
                content: (
                  <CollectionsPanel
                    onCreateCollection={handleCreateCollection}
                    items={collections}
                    lookups={lookups}
                    onPageChange={setCollectionsPage}
                    onSortChange={setCollectionsSort}
                    page={collectionsPage}
                    pageCount={collectionStats.pageCount}
                    user={profile}
                    sort={collectionsSort}
                  />
                ),
              },
              {
                name: 'settings',
                content: <SettingsPanel user={profile} changePassword={handleSetPassword} />,
              },
            ]}
          />
        </Container>
        <Footer />
      </Box>
    </>
  )
}
