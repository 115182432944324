import { useState, ReactElement } from 'react'
import { Box, Chip, ChipProps, IconButton, Stack, Tooltip } from '@mui/material'
import { LogicError, useBooleanState } from '../../lib'
import { EditIcon } from '../Icons'
import { EditableTypography } from '../EditableTypography'
import { uniq } from 'lodash'
import { useTheme } from '@mui/system'

export type TagChipsProps = {
  value: string[]
  noTags?: ReactElement
  gotoTagSearch?: (tag: string) => void
  startElement?: ReactElement
  endElement?: ReactElement
} & (
  | {
      canEdit?: false
      onChange?: (newTags: string[]) => void
    }
  | {
      canEdit: true
      onChange: (newTags: string[]) => void
    }
) &
  Omit<ChipProps, 'value' | 'onChange'>

const TagChip: React.FC<ChipProps> = (props) => {
  return <Chip {...props} />
}

export const TagChips: React.FC<TagChipsProps> = ({
  canEdit = false,
  value,
  onChange,
  gotoTagSearch,
  noTags,
  startElement,
  endElement,
  ...chipProps
}) => {
  const theme = useTheme()
  const [isEditing, startEditing, stopEditing] = useBooleanState()
  const [isHovering, startHovering, stopHovering] = useBooleanState()
  const [textValue, setTextValue] = useState('')

  const handleStartEdit = () => {
    setTextValue(value.join(', '))
    startEditing()
  }

  const handleChange = (newTextValue: string) => {
    if (!onChange) throw new LogicError()
    onChange(
      uniq(
        newTextValue
          .split(',')
          .map((tag) => tag.trim())
          .filter((tag) => tag)
      )
    )
    stopEditing()
  }

  if (!isEditing)
    return (
      <Box sx={{ position: 'relative', display: 'flex' }} onMouseOver={startHovering} onMouseOut={stopHovering}>
        <Stack direction="row" gap={1} flexWrap="wrap" alignItems={'center'}>
          {startElement}
          {!value.length && noTags}
          {value.map((tag) => (
            <Tooltip key={tag} title={gotoTagSearch ? `Search for ${tag}` : ''}>
              <Box>
                <TagChip
                  label={tag}
                  {...chipProps}
                  sx={{
                    cursor: gotoTagSearch ? 'pointer' : 'default',
                    backgroundColor: gotoTagSearch ? theme.palette.secondary.main : theme.palette.grey[100],
                    borderColor: gotoTagSearch ? theme.palette.secondary.dark : theme.palette.grey[200],
                    color: gotoTagSearch ? theme.palette.secondary.contrastText : 'black',
                    '&:hover': {
                      backgroundColor: gotoTagSearch ? theme.palette.secondary.light : theme.palette.grey[100],
                      borderColor: gotoTagSearch ? theme.palette.secondary.light : theme.palette.grey[200],
                      color: gotoTagSearch ? theme.palette.secondary.contrastText : 'black',
                    },
                  }}
                  onClick={() => gotoTagSearch && gotoTagSearch(tag)}
                />
              </Box>
            </Tooltip>
          ))}
          {endElement}
        </Stack>
        {canEdit && (
          <Box sx={{ position: 'relative', top: '-0.5em', opacity: isHovering ? 1 : 0, transition: '250ms opacity' }}>
            <IconButton onClick={handleStartEdit} color="primary">
              <EditIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    )

  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <EditableTypography content={textValue} onChange={handleChange} editing canEdit onStopEdit={stopEditing} />
    </Box>
  )
}
