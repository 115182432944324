import { isUndefined } from 'lodash'

/**
 * Takes a UTC unix timestamp integer or string (milliseconds)
 * and a valid moment format string and returns a formatted
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * date string
 * @param {*} utctimestamp
 * @param {*} format
 */
export function formatDate(
  utctimestamp?: number,
  locales: string | string[] = 'en-US',
  options?: Intl.DateTimeFormatOptions
): string {
  const val: number = typeof utctimestamp === 'string' ? parseInt(utctimestamp, 10) : utctimestamp
  const date = isUndefined(val) ? new Date() : new Date(val)

  return new Intl.DateTimeFormat(locales, options).format(date)
}

/**
 * format a number of seconds into "2h2m2s" as appropriate
 * @param seconds
 * @param roundToMultipleOf
 * @returns
 */
export function formatSeconds(seconds: number, roundToMultipleOf = 1): string {
  function round(input: number): number {
    if (roundToMultipleOf) {
      return roundToMultipleOf * Math.round(input / roundToMultipleOf)
    } else {
      return input
    }
  }
  if (seconds > 3600) {
    return Math.floor(seconds / 3600) + 'h' + round((seconds % 3600) / 60) + 'm'
  } else if (seconds > 60) {
    return Math.floor(seconds / 60) + 'm' + round(seconds % 60) + 's'
  } else if (seconds > 10) {
    return round(seconds) + 's'
  } else {
    return seconds + 's'
  }
}
