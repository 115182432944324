import React from 'react'
import { Symbology } from '@riverscapes/react-common'
import { Box, IconButton, SxProps, Theme, Toolbar, Typography, useTheme } from '@mui/material'
import { Close, Terminal } from '@mui/icons-material'
import JSONEditorReact from '../lib/JSONEditor'
import { TextContent, JSONContent, createAjvValidator, JSONValue } from 'vanilla-jsoneditor'
import log from 'loglevel'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    '&.MuiToolbar-root': {
      px: 1,
      py: 1,
    },
    flex: '0 0',
    minHeight: theme.spacing(6),
    background: theme.palette.warning.dark,
    color: theme.palette.warning.contrastText,
  },
  layerMeta: {
    flex: '1 1',
    overflow: 'hidden',
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  closeButton: {
    // ml: theme.spacing(2)
  },
})

interface SymbologyToolProps {
  symbology: Symbology
  setSymbology?: (symbology: Symbology) => void
  handleClose: () => void
}

const schema = {
  type: 'object',
  properties: {
    legend: {
      type: 'array',
      items: {
        type: 'array',
        items: [{ type: 'string' }, { type: 'string' }],
      },
    },
    layerStyles: {
      type: 'array',
      items: [
        {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            type: {
              type: 'string',
            },
            source: {
              type: 'string',
            },
            'source-layer': {
              type: 'string',
            },
            layout: {
              type: 'object',
            },
            paint: {
              type: 'object',
            },
          },
          required: ['id', 'type', 'source', 'source-layer', 'paint'],
        },
      ],
    },
  },
  required: ['legend', 'layerStyles'],
}

const SymbologyTool: React.FC<SymbologyToolProps> = ({ symbology, setSymbology, handleClose }) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const initJSON: JSONValue = React.useMemo(
    () =>
      ({
        legend: symbology.legend,
        layerStyles: symbology.mapboxJson,
      } as JSONValue),
    []
  )

  return (
    <Box sx={styles.root}>
      <Toolbar sx={styles.toolbar}>
        <Terminal />
        <Typography variant="body2" sx={styles.layerTitle}>
          Symbology Editor:{' '}
          <span
            style={{
              fontFamily: 'monospace',
            }}
          >
            {symbology.name}
          </span>
        </Typography>
        <Box sx={styles.spacer} />
        <IconButton edge="start" sx={styles.closeButton} color="inherit" aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </Toolbar>
      <Box sx={styles.layerMeta}>
        {symbology && symbology.mapboxJson ? (
          // Schema Validation
          // https://github.com/josdejong/svelte-jsoneditor/blob/main/examples/browser/json_schema_validation.html
          <JSONEditorReact
            initJSON={initJSON}
            navigationBar={false}
            // mode={Mode.tree}
            validator={createAjvValidator({ schema })}
            onChange={(updatedContent, previousContent, { contentErrors, patchResult }) => {
              console.log('updatedContent', { updatedContent, previousContent, contentErrors, patchResult })
              if (contentErrors) return
              try {
                const retValJSON = updatedContent as JSONContent
                const newJson = retValJSON.json || JSON.parse((updatedContent as TextContent).text)
                setSymbology &&
                  setSymbology({
                    ...symbology,
                    legend: newJson.legend as string[][],
                    mapboxJson: newJson.layerStyles as unknown as Record<string, unknown>,
                  })
              } catch (err) {
                log.error(err)
              }
            }}
          />
        ) : (
          <div>loading...</div>
        )}
      </Box>
    </Box>
  )
}

export default SymbologyTool
