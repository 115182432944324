import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { GetProjectDatasetsQuery, GetProjectDetailQuery } from '../../schema/base'
import { PendingItem, PendingCellRenderer } from '../PendingItem'
import { isPendingItem, useFormats } from '../../lib'
import { ItemRepresentation } from '../ItemImage'
import { Lookups } from '../lookups'
import { DatasetTypeEnum } from '@riverscapes/common'
import { useTheme } from '@mui/system'

type DatasetItem = Extract<
  NonNullable<GetProjectDatasetsQuery['project']>['datasets']['items'][number],
  { __typename: 'Dataset' }
>

type Item = DatasetItem | PendingItem

type DecoratedItem = (DatasetItem & { lookups: Lookups; linkProject: boolean }) | PendingItem

export const PreRenderer: React.FC<GridRenderCellParams<string>> = ({ value }) => {
  return <pre>{value}</pre>
}

export const LayerCountRenderer: React.FC<GridRenderCellParams<void, DecoratedItem>> = ({ row }) => {
  if (isPendingItem(row)) return null
  if (row.datasetType !== DatasetTypeEnum.Geopackage) return <pre>{1}</pre>
  return <pre>{row.layers?.length}</pre>
}

export const ProjectRenderer: React.FC<GridRenderCellParams<void, DecoratedItem>> = ({ row }) => {
  if (isPendingItem(row)) return null
  return <ItemRepresentation item={row.project} lookups={row.lookups} link={row.linkProject} />
}

export type DatasetsProps = {
  project: NonNullable<GetProjectDetailQuery['project']>
  items: Item[]
  lookups: Lookups
}

export const Datasets = ({ project, items, lookups }: DatasetsProps): React.ReactElement => {
  const { formatDate } = useFormats()
  const theme = useTheme()

  const colDefs = useMemo<GridColDef<DecoratedItem>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: PendingCellRenderer,
      },
      {
        field: 'datasetType',
        headerName: 'Type',
        flex: 1,
        renderCell: PreRenderer,
      },
      {
        field: 'layers',
        headerName: '# Layers',
        width: 100,
        renderCell: LayerCountRenderer,
      },
      // {
      //   field: 'project',
      //   headerName: 'Project',
      //   flex: 1,
      //   renderCell: ProjectRenderer,
      // },
      {
        field: 'rsXPath',
        headerName: 'RS XPath',
        flex: 3,
        renderCell: PreRenderer,
      },
    ],
    [formatDate]
  )

  const rows = useMemo<DecoratedItem[]>(() => {
    return items.map((item) => {
      if (isPendingItem(item)) return item
      const linkProject = item.project.id !== project.id
      return { ...item, linkProject, lookups }
    })
  }, [items])

  return (
    <Box
      sx={
        {
          // height: '600px',
          // border: '1px solid green'
        }
      }
    >
      <DataGrid
        rows={rows}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        }}
        autoHeight
        columns={colDefs}
        hideFooterPagination
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => <Typography sx={{ fontStyle: 'italic', m: 2 }}>No datasets.</Typography>,
        }}
      />
    </Box>
  )
}
