import React, { useEffect } from 'react'
import { PageTitle } from '@riverscapes/react-common'
import { Alert, AlertTitle, Box, Container, Link, Stack, Typography } from '@mui/material'
import log from 'loglevel'

const REDIRECT_URL = `${process.env.PUBLIC_URL}/redirect.tsv`
const REDIRECT_TIMEOUT = 5000

export interface MigrationRedirectProps extends JSX.IntrinsicAttributes {
  oldProjectId: string
  oldProgram: string
}

export const MigrationRedirect: React.FC<MigrationRedirectProps> = ({ oldProjectId, oldProgram }) => {
  // const { catchError } = useNotifications()
  const [[foundUrl, success], setFoundUrl] = React.useState<[string, boolean]>(['', false])
  const [timer, setTimer] = React.useState<number>(Math.round(REDIRECT_TIMEOUT / 1000))
  const [loading, setLoading] = React.useState<boolean>(true)
  const timerRef = React.useRef<NodeJS.Timeout>()
  const timerInterval = React.useRef<NodeJS.Timeout>()

  const doRedirect = (url) => window.location.replace(url)

  // use useEffect to fetch a CSV file from the old server
  // if the CSV file contains a new project ID, redirect to the new project
  // if the CSV file contains an error, display the error
  useEffect(() => {
    fetch(REDIRECT_URL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response
          .text()
          .then((text) => text.split('\n').map((line) => line.split('\t').map((cell) => cell.trim())))
      })
      .then((csvFile) => {
        setLoading(false)
        const allFound = csvFile.filter((row) => row.length === 3 && row[0] === oldProjectId)
        if (allFound && allFound.length > 0) {
          const successFound = allFound.find((row) => row[2].toLowerCase().trim() === 'true')
          const theFoundUrl = successFound ? successFound[1] : allFound[0][1]
          setFoundUrl([theFoundUrl, Boolean(successFound)])
          if (successFound) {
            timerRef.current = setTimeout(() => {
              doRedirect(`/p/${theFoundUrl}`)
            }, REDIRECT_TIMEOUT)
            timerInterval.current = setInterval(() => setTimer((t) => (t > 0 ? t - 1 : t)), 1000)
          }
        }
      })
      .catch((error) => {
        log.error(error)
        setLoading(false)
      })
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        clearTimeout(timerInterval.current)
      }
    }
  }, [])

  return (
    <>
      <PageTitle title="REDIRECT" />
      <Container maxWidth="md" sx={{ height: '100%', maxHeight: 500 }}>
        <Stack sx={{ height: '100%' }}>
          <Box sx={{ height: '100%' }}></Box>
          <Box sx={{ height: '100%' }}>
            {loading && (
              <>
                <Typography variant="h3" paragraph>
                  Loading lookups...
                </Typography>
                <Typography variant="h4">Please wait</Typography>
              </>
            )}
            {/* The success case */}
            {!loading && foundUrl && success && (
              <Alert severity="success">
                <AlertTitle>Found URL Redirecting...</AlertTitle>
                <Typography variant="h4">Please update your bookmarks</Typography>
                <Typography variant="h5">Redirecting in {timer} seconds...</Typography>
                <Typography variant="caption">
                  If you are not redirected please click{' '}
                  <Link href={foundUrl} onClick={() => doRedirect(`/p/${foundUrl}`)}>
                    here
                  </Link>
                </Typography>
              </Alert>
            )}
            {/* No entry found */}
            {!loading && !foundUrl && (
              <Alert severity="error">
                <AlertTitle>Project not found</AlertTitle>
                <Typography paragraph>
                  This url corresonds to the old warehouse, however no record exists of a project ever existing at this
                  address on the old system.
                </Typography>
                <Typography paragraph>
                  If you think this is a mistake please contact the exchange administrators with the url below and they
                  can look into it.
                </Typography>
                <Typography>
                  <code
                    style={{
                      textAlign: 'left',
                      display: 'block',
                      padding: '1rem',
                      backgroundColor: '#eee',
                      borderRadius: '0.5rem',
                      margin: '1rem',
                      wordWrap: 'break-word',
                    }}
                  >
                    <pre>
                      Possible Urls:
                      <br />
                      {`https://data.riverscapes.xyz/#/${oldProgram}/${oldProjectId}`}
                      <br />
                      {`https://data.riverscapes.net/#/${oldProgram}/${oldProjectId}`}
                    </pre>
                  </code>
                </Typography>
              </Alert>
            )}
            {/* Entry found */}
            {!loading && foundUrl && !success && (
              <Alert severity="error">
                <AlertTitle>Project migration missing</AlertTitle>
                <Typography paragraph>
                  This project existed in the old data warehouse but was not moved. This could be because the migration
                  script failed.
                </Typography>
                <Typography paragraph>
                  If you need this project please contact the exchange administrators and they should be able to either
                  manually migrate it or provide it to you from backups.
                </Typography>
                <Typography>
                  <code
                    style={{
                      textAlign: 'left',
                      display: 'block',
                      padding: '1rem',
                      backgroundColor: '#eee',
                      borderRadius: '0.5rem',
                      margin: '1rem',
                      wordWrap: 'break-word',
                    }}
                  >
                    <pre>
                      Old Url:
                      <br />
                      {`https://data.riverscapes.net/#/${oldProgram}/${oldProjectId}`}
                    </pre>
                  </code>
                </Typography>
              </Alert>
            )}
          </Box>
        </Stack>
      </Container>
    </>
  )
}
