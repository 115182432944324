import React from 'react'
import { Alert, AlertTitle, Box, Container, Typography } from '@mui/material'
import log from 'loglevel'
import { useLookups, useProfile } from '../../lib'
import { OwnerInputTypesEnum, isOrganizationInvite, useNotifications } from '@riverscapes/react-common'
import { Link } from 'react-router-dom'
import { RequestOrganizationInviteButton } from '@riverscapes/react-common/dist/components/Role'
import {
  useFetchAllWithPendingItems,
  useGetOrganizationDetailQuery,
  useGetProfileOrganizationInvitesQuery,
  useRequestOrganizationInviteMutation,
} from '../../data'
import { useAuth } from '@riverscapes/react-api'

export interface NoPermissionProps {
  errorMsg: string
}

export const NoPermission: React.FC<NoPermissionProps> = ({ errorMsg }) => {
  const lookups = useLookups()
  const { catchError } = useNotifications()
  const { isAuthenticated } = useAuth()
  const profile = useProfile()

  // "User d07b4c70-f754-4ffc-809a-11518c125991 does not have permission to view project ccfe823a-2ab6-47bd-8554-c1af5a8502be owned by O:b8e04c19-ec63-40b2-aadc-b81cccbab4ba"
  // Parse out who owns this project
  const [ownerType, ownerId, ownerUrl] = React.useMemo(() => {
    let ownerType: OwnerInputTypesEnum = OwnerInputTypesEnum.Organization
    let ownerUrl = ''
    let ownerId = ''
    try {
      const results = errorMsg.match(/owned by (O:[a-f0-9-]+)/)?.[1].split(':')
      if (!results || results.length < 2) throw new Error('Could not parse owner id from permission error message')
      ownerType = results[0] === 'O' ? OwnerInputTypesEnum.Organization : OwnerInputTypesEnum.User
      ownerId = results[1]
      ownerUrl =
        ownerType === OwnerInputTypesEnum.Organization
          ? lookups.getOrganizationUrlById(results[1])
          : lookups.getUserUrlById(results[1])
    } catch {
      throw new Error('Could not parse owner id from permission error message')
    }

    return [ownerType, ownerId, ownerUrl]
  }, [errorMsg])
  const { data: orgData, loading: orgLoading } = useGetOrganizationDetailQuery({
    variables: {
      id: ownerId,
    },
    skip: ownerType === OwnerInputTypesEnum.User,
  })

  const { data: profileOrganizationInvitesData, fetchMore: fetchMoreProfileOrganizationInvites } =
    useGetProfileOrganizationInvitesQuery({
      variables: { offset: 0 },
      onError: catchError('Failed to get invites', false),
      skip: !isAuthenticated,
    })

  const profileOrganizationInvites = useFetchAllWithPendingItems(
    fetchMoreProfileOrganizationInvites,
    profileOrganizationInvitesData?.profile?.organizationInvites.items,
    profile?.organizationInvites.total,
    0
  ).filter(isOrganizationInvite)

  const [requestOrganizationInvite] = useRequestOrganizationInviteMutation()
  const handleRequestInvite = () =>
    requestOrganizationInvite({ variables: { id: ownerId } }).catch(catchError('Failed to request membership', false))

  const hasAllProfileOrganizationInvites =
    profileOrganizationInvitesData?.profile?.organizationInvites.total === profileOrganizationInvites.length

  return (
    <Container maxWidth="md" sx={{ p: 2, height: '100%', maxHeight: 500 }}>
      <Alert severity="error">
        <AlertTitle>No Permission</AlertTitle>
        <Typography variant="h4" paragraph>
          You do not have permission to view this project.
        </Typography>
        {ownerType === OwnerInputTypesEnum.Organization ? (
          <>
            <Typography variant="body1" paragraph>
              {'The project is owned by an organization named: '}
              <Link to={ownerUrl}>{orgLoading ? '...' : orgData?.organization?.name || 'unknown'}</Link>.{' '}
              {'Click the button below to request access to the organization that owns this project.'}
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <RequestOrganizationInviteButton
                onClick={handleRequestInvite}
                organizationName={orgLoading ? '...' : orgData?.organization?.name || 'unknown'}
                organizationId={ownerId}
                profileOrganizationInvites={hasAllProfileOrganizationInvites ? profileOrganizationInvites : null}
              />
            </Box>

            <Typography variant="body2" paragraph sx={{ pt: 2 }}>
              If you have already requested access, you may need to contact the organization directly to expedite your
              request.
            </Typography>
          </>
        ) : (
          <Typography variant="body1">
            If you know the user who owns this project please contact them directly to request access.
          </Typography>
        )}
      </Alert>
    </Container>
  )
}
