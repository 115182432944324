import { PageTitle, RsLogo } from '@riverscapes/react-common'
import { Box, CircularProgress, CssBaseline, Stack, ThemeProvider, Typography, useTheme } from '@mui/material'
import { HeaderInner } from './Header'
import { theme } from '@riverscapes/react-theme'

export const AppLoader: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppLoaderInner />
    </ThemeProvider>
  )
}

export const AppLoaderInner: React.FC = () => {
  const theme = useTheme()
  return (
    <>
      <PageTitle title={'Loading...'} />
      <Stack sx={{ height: '100%', background: theme.palette.background.default }}>
        <HeaderInner loading />
        <Box sx={{ position: 'relative', flexGrow: 1, maxHeight: 600 }}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box sx={{ position: 'relative', margin: 'auto', width: 130, mb: 2 }}>
              <CircularProgress
                variant="indeterminate"
                size={130}
                thickness={6}
                sx={{
                  margin: 'auto',
                  color: theme.palette.warning.main,
                }}
              />
              <Box
                sx={{
                  top: 10,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <RsLogo size={120} />
              </Box>
            </Box>
            <Typography variant="h4" sx={{ textAlign: 'center' }} color="text.secondary">
              Loading...
            </Typography>
            <Typography variant="overline" sx={{ textAlign: 'center' }} color="text.secondary">
              Checking authentication parameters
            </Typography>
          </Box>
        </Box>
      </Stack>
    </>
  )
}
