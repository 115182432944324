import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Bbox,
  CollectionDetail as Components,
  DetailPageTabs,
  EntitiesWithImagesEnum,
  MissingDataError,
  PageTitle,
  ProjectGroupVisibilityEnum,
  StarrableTypesEnum,
  useActiveResults,
  useInteractiveMapController,
  SortOrderFieldProps,
  pendingItems,
  useNotifications,
  ProjectDetailSkeleton,
  ProjectBoundsThumbProps,
  Footer,
  INFINISCROLLER_PAGE_SIZE,
  SearchViewTypeEnum,
} from '@riverscapes/react-common'
import { collectionDetail, search, useGoBackAfterDelete, useGotoRoute, webRaveDetail } from '../routing'
import { useActivity, useLookups, useProfile } from '../lib'
import { Box, Container, useTheme } from '@mui/material'
import {
  useAddCollectionProjectsMutation,
  useDeleteCollectionMutation,
  useGetCollectionDetailQuery,
  useGetCollectionProjectsQuery,
  useGetImageUploadTargetLazyQuery,
  useRemoveCollectionProjectsMutation,
  useUpdateCollectionDescriptionMutation,
  useUpdateCollectionHeroImageMutation,
  useUpdateCollectionMetaMutation,
  useUpdateCollectionNameMutation,
  useUpdateCollectionStarMutation,
  useUpdateCollectionSummaryMutation,
  useUpdateCollectionTagsMutation,
  useUpdateCollectionVisibilityMutation,
} from '../data'
import { useProjectsMap } from './Search/useProjectsMap'
import { NotFound } from './NotFound'
import { MetaDataInput } from '@riverscapes/react-common'
import { useUpdateCollectionCitationMutation } from '@riverscapes/react-common/dist/schema/operations'

const { Header, OverviewPanel, ProjectsPanel, MetadataPanel, SettingsPanel } = Components

export interface CollectionDetailProps extends JSX.IntrinsicAttributes {
  id: string
  tab?: string
  viewType?: SearchViewTypeEnum
}

export const CollectionDetail: React.FC<CollectionDetailProps> = ({
  id,
  tab = 'overview',
  viewType = SearchViewTypeEnum.Map,
}) => {
  const theme = useTheme()

  const gotoSelf = useGotoRoute(collectionDetail)
  const gotoSearch = useGotoRoute(search)
  const goBackAfterDelete = useGoBackAfterDelete()
  const gotoWebRave = useGotoRoute(webRaveDetail)
  const [projectPaginationOffset, setProjectPaginationOffset] = useState(INFINISCROLLER_PAGE_SIZE) // Remember that this doesn't get used until page 2

  const lookups = useLookups()
  const profile = useProfile()

  const { catchError } = useNotifications()

  // TABS

  const setTab = (newTab: string) => gotoSelf({ id, tab: newTab }, true)
  const setViewType = (newViewType: SearchViewTypeEnum) => gotoSelf({ id, tab, viewType: newViewType }, true)

  // COLLECTION QUERY

  const { data, loading, error } = useGetCollectionDetailQuery({ variables: { id } })

  // ACTIVITY

  const { addActivity } = useActivity()
  useEffect(() => {
    if (!data?.collection) return
    addActivity({ operation: 'view', item: data.collection })
  }, [data?.collection])

  // HEADER

  // queries
  const [getImageUploadTarget] = useGetImageUploadTargetLazyQuery()
  const getHeroUploadTarget = async () => {
    const result = await getImageUploadTarget({
      variables: { entityId: id, entityType: EntitiesWithImagesEnum.Collection },
    })
    const target = result.data?.requestUploadImage
    if (!target) throw new MissingDataError()
    return target
  }

  // mutations

  const [updateStar] = useUpdateCollectionStarMutation()
  const handleStarChange = (newValue: boolean) => {
    updateStar({
      variables: { type: StarrableTypesEnum.Collection, id, starred: newValue },
    }).catch(catchError('Failed to update star', false))
  }

  const [updateCollectionName] = useUpdateCollectionNameMutation()
  const handleNameChange = (newName: string) =>
    updateCollectionName({ variables: { id, name: newName } }).catch(
      catchError('Failed to update collection name', false)
    )

  const [updateCollectionSummary] = useUpdateCollectionSummaryMutation()
  const handleSummaryChange = (newSummary: string) =>
    updateCollectionSummary({ variables: { id, summary: newSummary } }).catch(
      catchError('Failed to update collection summary', false)
    )

  const [updateCollectionDescription] = useUpdateCollectionDescriptionMutation()
  const handleDescriptionChange = (newDescription: string) =>
    updateCollectionDescription({ variables: { id, description: newDescription } }).catch(
      catchError('Failed to update collection description', false)
    )

  const [updateCollectionCitation] = useUpdateCollectionCitationMutation()
  const handleCitationChange = (newCitation: string) =>
    updateCollectionCitation({ variables: { id, citation: newCitation } }).catch(
      catchError('Failed to update collection citation', false)
    )

  const [updateCollectionVisibility] = useUpdateCollectionVisibilityMutation()
  const handleVisibilityChange = (newVisibility: ProjectGroupVisibilityEnum) =>
    updateCollectionVisibility({ variables: { id, visibility: newVisibility } }).catch(
      catchError('Failed to update collection visibility', false)
    )

  const [updateTags] = useUpdateCollectionTagsMutation()
  const handleTagsChange = (newValue: string[]) => {
    updateTags({
      variables: { id, tags: newValue },
    }).catch(catchError('Failed to update tags', false))
  }

  const [updateCollectionHeroImage] = useUpdateCollectionHeroImageMutation()
  const handleHeroChange = (newToken: string | null) =>
    updateCollectionHeroImage({ variables: { id, token: newToken as string | undefined, clear: !newToken } }).catch(
      catchError(`Failed to ${newToken ? 'update' : 'remove'} collection image`, false)
    )

  const [deleteCollection] = useDeleteCollectionMutation()
  const handleDeleteCollection = () =>
    deleteCollection({ variables: { id } })
      .then(goBackAfterDelete)
      .catch(catchError('Failed to delete collection', false))

  // PROJECTS PANEL

  const [bbox, setBbox] = useState<Bbox | undefined>()
  const mapController = useInteractiveMapController({
    onBboxChange: setBbox,
  })

  // paging and sorting
  const [projectsSort, setProjectsSort] = useState<SortOrderFieldProps['sort']>(null)

  // projects (paged)
  const {
    data: projectsData,
    fetchMore,
    loading: searchLoading,
  } = useGetCollectionProjectsQuery({
    variables: {
      id,
      limit: INFINISCROLLER_PAGE_SIZE,
      offset: 0,
      sort: projectsSort && [projectsSort],
    },
    notifyOnNetworkStatusChange: true,
    skip: tab !== 'projects',
    onError: catchError('Failed to get collection projects', false),
  })
  // Fetch the next page of projects
  const theoreticalTotal = projectsData?.collection?.projects?.total ?? 0
  const projectsHasNext = Boolean(theoreticalTotal > projectPaginationOffset)
  const fetchNextPage = () => {
    if (projectsHasNext && !searchLoading) {
      fetchMore({
        variables: {
          offset: projectPaginationOffset,
        },
      }).then(() => {
        setProjectPaginationOffset((prevOffset) => {
          return prevOffset + INFINISCROLLER_PAGE_SIZE > theoreticalTotal
            ? theoreticalTotal
            : prevOffset + INFINISCROLLER_PAGE_SIZE
        })
      })
    }
  }

  const projectStats = useActiveResults({}, data?.collection?.projects.total)
  const displayItems = projectsData?.collection?.projects.items ?? pendingItems(projectStats.expectedCount)

  // map
  const mapBoxRef = useRef<HTMLDivElement>(null)

  const [hoveredBoundsIds, setHoveredBoundsIds] = useState<string[]>([])

  const getProjectBoundsThumbProps = useCallback(
    (bounds: { id: string; polygonUrl: string; bbox: Bbox }): Partial<ProjectBoundsThumbProps> => ({
      onZoomClick: () => mapController.fitBboxes([bounds.bbox]),
      onHoverChange: (newHover: boolean) => {
        setHoveredBoundsIds(newHover ? [bounds.id] : [])
      },
      highlight: hoveredBoundsIds.includes(bounds.id),
      visibilityBbox: bbox,
    }),
    [hoveredBoundsIds, bbox]
  )

  const {
    handleMapClick,
    handleMapHover,
    mapElements,
    popupState: { ids: popupBoundsIds },
  } = useProjectsMap({
    params: {
      collection: id,
    },
    mapBoxRef,
    lookups,
    bbox,
    mapController,
    getProjectBoundsThumbProps,
    highlightBoundsIds: hoveredBoundsIds,
  })

  useEffect(() => {
    setHoveredBoundsIds(popupBoundsIds)
  }, [popupBoundsIds])

  // mutations
  const [addCollectionProjects] = useAddCollectionProjectsMutation()
  const handleAddProjectById = (projectId: string) => {
    addCollectionProjects({
      variables: { collectionId: id, projectIds: [projectId] },
    }).catch(catchError('Failed to add project to collection', false))
  }

  const [removeCollectionProjects] = useRemoveCollectionProjectsMutation()
  const handleRemoveProjectById = (projectId: string) => {
    removeCollectionProjects({
      variables: { collectionId: id, projectIds: [projectId] },
    }).catch(catchError('Failed to remove project from collection', false))
  }

  // METADATA PANEL

  const [updateMeta] = useUpdateCollectionMetaMutation()
  const handleMetadataChange = (newValue: MetaDataInput[]) =>
    updateMeta({
      variables: { id, meta: newValue },
    }).catch(catchError('Failed to update metadata', false))

  // RENDER

  if (error?.message === 'Collection not found') return <NotFound id={id} descriptor="Collection" />
  if (error) throw error
  if (loading || !profile) return <ProjectDetailSkeleton />
  if (!data?.collection) throw new MissingDataError()

  const { collection } = data

  return (
    <>
      <PageTitle title={collection.name} />
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header
          collection={collection}
          getHeroUploadTarget={getHeroUploadTarget}
          lookups={lookups}
          onHeroChange={handleHeroChange}
          onNameChange={handleNameChange}
          onStarChange={handleStarChange}
          onSummaryChange={handleSummaryChange}
        />
        <Container
          maxWidth="lg"
          sx={{
            flex: '1 1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DetailPageTabs
            value={tab}
            onChange={setTab}
            tabs={[
              {
                name: 'overview',
                content: (
                  <OverviewPanel
                    collection={collection}
                    lookups={lookups}
                    onDescriptionChange={handleDescriptionChange}
                    onVisibilityChange={handleVisibilityChange}
                    onGotoTagSearch={(tag: string) => gotoSearch({ type: 'Collection', params: { tags: [tag] } })}
                    onCitationChange={handleCitationChange}
                    onTagsChange={handleTagsChange}
                  />
                ),
              },
              {
                name: 'projects',
                padding: 0,
                content: (
                  <ProjectsPanel
                    collection={collection}
                    onGotoWebRave={(id: string) =>
                      gotoWebRave({
                        id: id,
                      })
                    }
                    onFetchMoreProjects={fetchNextPage}
                    loadingHasMore={projectsHasNext}
                    loadingProjects={searchLoading}
                    getProjectBoundsThumbProps={getProjectBoundsThumbProps}
                    pageOffset={projectPaginationOffset > theoreticalTotal ? theoreticalTotal : projectPaginationOffset}
                    items={displayItems}
                    lookups={lookups}
                    mapBoxRef={mapBoxRef}
                    mapController={mapController}
                    mapElements={mapElements}
                    onAddProjectById={handleAddProjectById}
                    onRemoveProjectById={handleRemoveProjectById}
                    onMapClick={handleMapClick}
                    onMapHover={handleMapHover}
                    onSearch={gotoSearch}
                    onSortChange={setProjectsSort}
                    sort={projectsSort}
                    onViewTypeChange={setViewType}
                    viewType={viewType}
                  />
                ),
              },
              {
                name: 'metadata',
                content: <MetadataPanel collection={collection} onMetadataChange={handleMetadataChange} />,
              },
              collection.permissions.update && {
                name: 'settings',
                content: (
                  <SettingsPanel
                    collection={collection}
                    lookups={lookups}
                    profile={profile}
                    onDeleteCollection={handleDeleteCollection}
                  />
                ),
              },
            ]}
          />
          <Box
            sx={{
              flex: '0 0 20px',
            }}
          />
        </Container>
        <Footer />
      </Box>
    </>
  )
}
