import React from 'react'
import { Box, Stack, Typography, Button, useTheme, Paper, useMediaQuery } from '@mui/material'
import { ProjectVisibilityEnum } from '@riverscapes/common'

import { useBooleanState, useFormats, validate } from '../../lib'
import { GetProjectDetailQuery } from '../../schema/base'
import { TagChips } from '../Tags'
import { Bbox, Bounds, InteractiveMap, InteractivityEnum, useInteractiveMapController } from '../InteractiveMap'
import { ItemRepresentation } from '../ItemImage'
import { Lookups } from '../lookups'
import { CopyIcon, OpenProjectIcon } from '../Icons'
import { VisibilityChip } from '../Visibility'
import { EditableTypography } from '../EditableTypography'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { CSSProperties } from 'react'
import { OverviewCard } from '../Overview/OverviewCard'
import { OverviewBasicProperties } from '../Overview/OverviewBasicProperties'
import { useDefaultProjectCitation } from './defaultCitation'
import { useNotifications } from '../AppNotifications'
import numeral from 'numeral'

export interface OverviewPanelProps {
  onNameChange: (newValue: string) => Promise<unknown>
  onTagsChange: (newValue: string[]) => void
  onVisibilityChange: (newValue: ProjectVisibilityEnum) => void
  onDescriptionChange: (newDescription: string) => Promise<unknown>
  onCitationChange: (newCitation: string) => Promise<unknown>
  onGotoTagSearch: (tag: string) => void
  project: NonNullable<GetProjectDetailQuery['project']>
  lookups: Lookups
}

export const OverviewPanel: React.FC<OverviewPanelProps> = ({
  onNameChange,
  onTagsChange,
  onVisibilityChange,
  onDescriptionChange,
  onCitationChange,
  onGotoTagSearch,
  project,
  lookups,
}) => {
  const theme = useTheme()
  const { show } = useNotifications()
  const [citationText, setCitationText] = React.useState<string>('')
  const webRaveUrl = lookups.getWebRaveUrl(project.id)
  const copyRef = React.useRef<HTMLDivElement>(null)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const defaultCitation = useDefaultProjectCitation({
    project,
    getProjectTypeUrlById: lookups.getProjectTypeUrlById,
    getProjectUrlById: lookups.getProjectUrlById,
    getUserUrlById: lookups.getUserUrlById,
  })
  const { formatDateTime } = useFormats()
  const projectTypeUrl = lookups.getProjectTypeUrlById(project.projectType.id)

  const canEdit = Boolean(project.permissions.update)

  const [isBoundsLoaded, onBoundsLoaded] = useBooleanState()

  const mapController = useInteractiveMapController({
    initialBbox: project.bounds?.bbox as Bbox,
    suspend: !isBoundsLoaded,
  })

  const scrollContainer: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
      }
    : {}
  const scrollObjInner: CSSProperties = isMdUp
    ? {
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
      }
    : {}

  const hasCitation = project.citation && project.citation.trim().length > 0
  const citation: string = hasCitation ? (project.citation as string) : defaultCitation.markdown

  React.useEffect(() => {
    if (copyRef.current) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(copyRef.current.innerHTML, 'text/html')
      const text = doc.body.textContent || ''
      setCitationText(text)
    }
  }, [citation])

  return (
    <Grid2 container spacing={3} sx={scrollContainer}>
      <Grid2 xs={12} sm={12} md={8} sx={scrollObjInner}>
        <OverviewCard title="Basic Properties">
          <OverviewBasicProperties
            propTable={[
              {
                label: 'Project Name',
                value: (
                  <EditableTypography
                    canEdit={canEdit}
                    content={project.name}
                    getError={validate.name}
                    name="Name"
                    typographySx={{ pt: 0.5 }}
                    onChange={onNameChange}
                  />
                ),
              },
              {
                label: 'Project Type',
                value: (
                  <Stack direction="row" spacing={1}>
                    <Button href={projectTypeUrl}>
                      <ItemRepresentation item={project.projectType} />
                    </Button>
                  </Stack>
                ),
              },
              {
                label: 'Visibility',
                value: (
                  <VisibilityChip
                    canEdit={canEdit}
                    type="Project"
                    value={project.visibility}
                    onChange={onVisibilityChange}
                  />
                ),
              },
              {
                label: 'Owned By',
                value: (
                  <ItemRepresentation lookups={lookups} item={project.ownedBy} link tooltipPrefix="Project owned by:" />
                ),
              },
              {
                label: 'Created by',
                value: (
                  <Stack direction="row" spacing={1} alignItems="baseline">
                    <ItemRepresentation
                      lookups={lookups}
                      item={project.createdBy}
                      link
                      tooltipPrefix="Project created by:"
                    />
                    <Typography variant="caption">on {formatDateTime(project.createdOn)}</Typography>
                  </Stack>
                ),
              },
              {
                label: 'Updated by',
                value: (
                  <Stack direction="row" spacing={1} alignItems="baseline">
                    <ItemRepresentation
                      lookups={lookups}
                      item={project.updatedBy}
                      link
                      tooltipPrefix="Project updated by:"
                    />
                    <Typography variant="caption">on {formatDateTime(project.updatedOn)}</Typography>
                  </Stack>
                ),
              },
              {
                label: 'Total Size',
                value: (
                  <Typography variant="body1">
                    {numeral(project.totalSize || 0).format('0.0 b')} (uncompressed)
                  </Typography>
                ),
              },
            ]}
          />
        </OverviewCard>
        <OverviewCard title="Tags">
          <TagChips
            value={project.tags}
            canEdit={canEdit}
            gotoTagSearch={onGotoTagSearch}
            onChange={onTagsChange}
            noTags={<em>No tags.</em>}
          />
        </OverviewCard>
        <OverviewCard title="Citation">
          <Box ref={copyRef} sx={{ width: '100%', display: 'flex' }}>
            <EditableTypography
              canEdit={canEdit}
              content={citation || ''}
              getError={validate.citation}
              multiline
              name="Citation"
              typographySx={{
                fontFamily: 'Roboto Mono, Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
                whiteSpace: 'pre-line',
                fontSize: '0.7rem',
                borderRadius: 2,
                px: 1,
                py: 1,
                backgroundColor: '#f5f5f5',
                border: '1px solid #AAA',
              }}
              onChange={onCitationChange}
              placeholder={'No citation.'}
              variant="markdown"
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              {hasCitation
                ? 'Note: This is a custom citation created by the project owner'
                : 'Note: This is a default citation created by the Riverscapes Data Exchange.'}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={<CopyIcon />}
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(citation)
                show('Copied to clipboard as Markdown.', {
                  variant: 'info',
                  autoHideDuration: 2000,
                })
              }}
            >
              Markdown
            </Button>
            <Button
              startIcon={<CopyIcon />}
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(citationText)
                show('Copied to clipboard as plain text.', {
                  variant: 'info',
                  autoHideDuration: 2000,
                })
              }}
            >
              TXT
            </Button>
          </Stack>
        </OverviewCard>
        <OverviewCard title="Description">
          <EditableTypography
            canEdit={canEdit}
            content={project.description}
            getError={validate.description}
            multiline
            name="Description"
            onChange={onDescriptionChange}
            placeholder={'No description.'}
            variant="markdown"
          />
        </OverviewCard>
      </Grid2>
      <Grid2 xs={12} sm={12} md={4}>
        <Paper sx={{ width: '100%', height: 300, position: 'relative' }} elevation={4}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            {project.bounds ? (
              <Box
                sx={{
                  visibility: isBoundsLoaded ? 'visible' : 'hidden',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
              >
                <InteractiveMap
                  interactive={InteractivityEnum.ZOOM_ONLY}
                  controller={mapController}
                  hasHomeButton
                  hasGeoCoder={false}
                >
                  <Bounds {...project.bounds} onLoaded={onBoundsLoaded} />
                </InteractiveMap>
              </Box>
            ) : (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100%', height: '100%', border: '1px solid grey' }}
              >
                <Typography>
                  <em>No project extents to display.</em>
                </Typography>
              </Stack>
            )}
            <Button
              variant="contained"
              size="small"
              sx={{ position: 'absolute', right: -12, top: -8 }}
              startIcon={<OpenProjectIcon />}
              href={webRaveUrl}
            >
              Open Map Viewer
            </Button>
          </div>
        </Paper>
      </Grid2>
    </Grid2>
  )
}
