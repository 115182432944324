import { Button, SxProps, Theme, useTheme } from '@mui/material'
import { RsLogo } from '@riverscapes/react-common'
import React from 'react'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {},
  whBtnLogo: {
    mr: theme.spacing(1),
    '& img': { maxWidth: 30 },
  },
})

interface WarehouseButtonProps {
  title: string
  onClick?: () => void
}

const WarehouseButton: React.FC<WarehouseButtonProps> = ({ title, onClick }: WarehouseButtonProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  return (
    <Button
      sx={styles.whBtnLogo}
      variant="contained"
      startIcon={<RsLogo size={40} />}
      onClick={() => onClick && onClick()}
      color="secondary"
      fullWidth
    >
      {title || 'Back to warehouse'}
    </Button>
  )
}

export default WarehouseButton
