import {
  DetailPageTabs,
  EntitiesWithImagesEnum,
  MissingDataError,
  OrganizationDetail as Components,
  OrganizationInviteRoleEnum,
  OrganizationRoleEnum,
  PageTitle,
  ProjectDetailSkeleton,
  StarrableTypesEnum,
  useNotifications,
  isPendingItem,
  isOrganizationInvite,
  UsersTableProps,
  SortOrderFieldProps,
  PAGE_SIZE,
  useActiveResults,
  pendingItems,
  CollectionInput,
  LogicError,
  Bbox,
  ProjectBoundsThumbProps,
  useInteractiveMapController,
  OwnerInputTypesEnum,
  Footer,
  INFINISCROLLER_PAGE_SIZE,
  SearchViewTypeEnum,
} from '@riverscapes/react-common'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useActivity, useLookups, useProfile } from '../lib'
import { useGotoRoute, organizationDetail, useGoBackAfterDelete, search, webRaveDetail } from '../routing'
import {
  useGetImageUploadTargetLazyQuery,
  useGetOrganizationDetailQuery,
  useGetOrganizationUsersQuery,
  useUpdateOrganizationDescriptionMutation,
  useUpdateOrganizationLogoImageMutation,
  useUpdateOrganizationNameMutation,
  useUpdateOrganizationStarMutation,
  useUpdateOrganizationSummaryMutation,
  useActionOrganizationInviteMutation,
  useCreateOrganizationInviteMutation,
  useDeleteOrganizationInviteMutation,
  useRemoveOrganizationMemberMutation,
  useResendOrganizationInviteMutation,
  useUpdateOrganizationMemberMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationInvitesQuery,
  useFetchAllWithPendingItems,
  useCreateCollectionMutation,
  useGetOrganizationCollectionsQuery,
  useRequestOrganizationInviteMutation,
  useGetProfileOrganizationInvitesQuery,
  useGetOrganizationProjectsQuery,
  evictAllQueries,
} from '../data'
import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { NotFound } from './NotFound'
import { useAuth } from '@riverscapes/react-api'
import { useProjectsMap } from './Search/useProjectsMap'

const { Header, OverviewPanel, SettingsPanel, CollectionsPanel, ProjectsPanel } = Components

export interface OrganizationDetailProps extends JSX.IntrinsicAttributes {
  id: string
  tab?: string
  viewType?: SearchViewTypeEnum
}

export const OrganizationDetail: React.FC<OrganizationDetailProps> = ({
  id,
  tab = 'overview',
  viewType = SearchViewTypeEnum.Map,
}) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const gotoSelf = useGotoRoute(organizationDetail)
  const gotoWebRave = useGotoRoute(webRaveDetail)
  const gotoSearch = useGotoRoute(search)
  const { catchError } = useNotifications()
  const goBackAfterDelete = useGoBackAfterDelete()
  const [projectPaginationOffset, setProjectPaginationOffset] = useState(INFINISCROLLER_PAGE_SIZE) // Remember that this doesn't get used until page 2

  const lookups = useLookups()
  const profile = useProfile()

  // HEADER

  // queries
  const [getImageUploadTarget] = useGetImageUploadTargetLazyQuery()
  const getLogoUploadTarget = async () => {
    const result = await getImageUploadTarget({
      variables: { entityId: organization.id, entityType: EntitiesWithImagesEnum.Organization },
    })
    const target = result.data?.requestUploadImage
    if (!target) throw new MissingDataError()
    return target
  }

  // MUTABLES

  const [updateStar] = useUpdateOrganizationStarMutation()
  const handleStarChange = (newValue: boolean) => {
    updateStar({
      variables: { type: StarrableTypesEnum.Organization, id, starred: newValue },
    }).catch(catchError('Failed to update star', false))
  }

  const [updateOrganizationName] = useUpdateOrganizationNameMutation()
  const handleNameChange = (newName: string) =>
    updateOrganizationName({ variables: { id, name: newName } }).catch(
      catchError('Failed to update organization name', false)
    )

  const [updateOrganizationSummary] = useUpdateOrganizationSummaryMutation()
  const handleSummaryChange = (newSummary: string) =>
    updateOrganizationSummary({ variables: { id, summary: newSummary } }).catch(
      catchError('Failed to update organization summary', false)
    )

  const [updateOrganizationDescription] = useUpdateOrganizationDescriptionMutation()
  const handleDescriptionChange = (newDescription: string) =>
    updateOrganizationDescription({ variables: { id, description: newDescription } }).catch(
      catchError('Failed to update organization description', false)
    )

  const [updateOrganizationLogoImage] = useUpdateOrganizationLogoImageMutation()
  const handleLogoChange = (newToken: string | null) =>
    updateOrganizationLogoImage({ variables: { id, token: newToken as string | undefined, clear: !newToken } }).catch(
      catchError(`Failed to ${newToken ? 'update' : 'remove'} organization logo`, false)
    )

  const [createOrganizationInvite] = useCreateOrganizationInviteMutation()
  const handleInviteCreate = (variables: { userId?: string; email?: string; role: OrganizationInviteRoleEnum }) =>
    createOrganizationInvite({
      variables: { organizationId: id, ...variables },
    }).catch(catchError(`Failed to invite user`, false))

  const [resendOrganizationInvite] = useResendOrganizationInviteMutation()
  const handleInviteResend = (variables: { id: string }) =>
    resendOrganizationInvite({
      variables,
    }).catch(catchError(`Failed to resend invitation`, false))

  const [deleteOrganizationInvite] = useDeleteOrganizationInviteMutation()
  const handleInviteDelete = (variables: { id: string }) =>
    deleteOrganizationInvite({
      variables,
      context: { organizationId: id },
    }).catch(catchError(`Failed to delete invitation`, false))

  const [actionOrganizationInvite] = useActionOrganizationInviteMutation()
  const handleInviteAction = (variables: { id: string; accept: boolean }) => {
    return actionOrganizationInvite({
      variables,
      context: { organizationId: id },
    }).catch(catchError(`Failed to ${variables.accept ? 'accept' : 'reject'} invitation`, false))
  }

  const [updateOrganizationMember] = useUpdateOrganizationMemberMutation()
  const handleMemberUpdate = ({ id: userId, role }: { id: string; role: OrganizationRoleEnum }) =>
    updateOrganizationMember({
      variables: { organizationId: id, userId, role },
    }).catch(catchError(`Failed to update member`, false))

  const [removeOrganizationMember] = useRemoveOrganizationMemberMutation()
  const handleMemberRemove = ({ id: userId }: { id: string }) =>
    removeOrganizationMember({
      variables: { organizationId: id, userId },
    }).catch(catchError(`Failed to remove member`, false))

  const [deleteOrganization] = useDeleteOrganizationMutation()
  const handleDeleteOrganization = () =>
    deleteOrganization({ variables: { id } })
      .then(goBackAfterDelete)
      .catch(catchError('Failed to delete organization', false))

  const [requestOrganizationInvite] = useRequestOrganizationInviteMutation()
  const handleRequestInvite = () =>
    requestOrganizationInvite({ variables: { id } }).catch(catchError('Failed to request membership', false))

  const setTab = (newTab: string) => gotoSelf({ id, tab: newTab }, true)
  const setViewType = (newViewType: SearchViewTypeEnum) => gotoSelf({ id, tab, viewType: newViewType }, true)

  // ORGANIZATION QUERY

  const { data, loading, error } = useGetOrganizationDetailQuery({ variables: { id } })

  // ACTIVITY

  const { addActivity } = useActivity()
  useEffect(() => {
    if (!data?.organization) return
    addActivity({ operation: 'view', item: data.organization })
  }, [data?.organization])

  // Project PANEL

  // projects (paged)
  const [projectsSort, setProjectsSort] = useState<SortOrderFieldProps['sort']>(null)
  const mapBoxRef = useRef<HTMLDivElement>(null)
  const {
    data: projectsData,
    fetchMore,
    loading: searchLoading,
  } = useGetOrganizationProjectsQuery({
    variables: {
      id,
      limit: INFINISCROLLER_PAGE_SIZE,
      offset: 0,
      sort: projectsSort && [projectsSort],
    },
    notifyOnNetworkStatusChange: true,
    skip: tab !== 'projects',
    onError: catchError('Failed to get collection projects', false),
  })
  // Fetch the next page of projects
  const theoreticalTotal = projectsData?.organization?.projects?.total ?? 0
  const projectsHasNext = Boolean(theoreticalTotal > projectPaginationOffset)
  const fetchNextPage = () => {
    if (projectsHasNext && !searchLoading) {
      fetchMore({
        variables: {
          offset: projectPaginationOffset,
        },
      }).then(() => {
        setProjectPaginationOffset((prevOffset) => {
          return prevOffset + INFINISCROLLER_PAGE_SIZE > theoreticalTotal
            ? theoreticalTotal
            : prevOffset + INFINISCROLLER_PAGE_SIZE
        })
      })
    }
  }

  const projectStats = useActiveResults({}, data?.organization?.projects.total)
  const displayItems = projectsData?.organization?.projects.items ?? pendingItems(projectStats.expectedCount)

  const [bbox, setBbox] = useState<Bbox | undefined>()
  const [hoveredBoundsIds, setHoveredBoundsIds] = useState<string[]>([])
  const mapController = useInteractiveMapController({
    onBboxChange: setBbox,
  })

  const getProjectBoundsThumbProps = useCallback(
    (bounds: { id: string; polygonUrl: string; bbox: Bbox }): Partial<ProjectBoundsThumbProps> => ({
      onZoomClick: () => mapController.fitBboxes([bounds.bbox]),
      onHoverChange: (newHover: boolean) => {
        setHoveredBoundsIds(newHover ? [bounds.id] : [])
      },
      highlight: hoveredBoundsIds.includes(bounds.id),
      visibilityBbox: bbox,
    }),
    [hoveredBoundsIds, bbox]
  )
  const {
    handleMapClick,
    handleMapHover,
    mapElements,
    popupState: { ids: popupBoundsIds },
  } = useProjectsMap({
    params: {
      ownedBy: {
        id: id,
        type: OwnerInputTypesEnum.Organization,
      },
    },
    mapBoxRef,
    lookups,
    bbox,
    mapController,
    getProjectBoundsThumbProps,
    highlightBoundsIds: hoveredBoundsIds,
  })

  useEffect(() => {
    setHoveredBoundsIds(popupBoundsIds)
  }, [popupBoundsIds])

  // COLLECTIONS PANEL

  const [collectionsPage, setCollectionsPage] = useState(1)
  const [collectionsSort, setCollectionsSort] = useState<SortOrderFieldProps['sort']>(null)

  const { data: collectionsData } = useGetOrganizationCollectionsQuery({
    variables: {
      id,
      limit: PAGE_SIZE,
      offset: (collectionsPage - 1) * PAGE_SIZE,
      sort: collectionsSort && [collectionsSort],
    },
    skip: tab !== 'collections',
    onError: catchError('Failed to get project collections', false),
  })

  const collectionStats = useActiveResults({ page: collectionsPage }, data?.organization?.collections.total)

  const collections = collectionsData?.organization?.collections.items ?? pendingItems(collectionStats.expectedCount)

  const [createCollection] = useCreateCollectionMutation()
  const handleCreateCollection = async ({
    orgId,
    ...restCollection
  }: { orgId?: string } & Partial<CollectionInput>) => {
    let collectionId: string | undefined

    // create collection
    try {
      const newCollection = await createCollection({
        variables: {
          collection: restCollection,
          orgId: orgId,
        },
        update: evictAllQueries,
      })
      if (!newCollection.data?.createCollection) throw new MissingDataError()
      collectionId = newCollection.data.createCollection.id
    } catch (err) {
      catchError('Failed to create new collection', true)(err)
    }

    if (!collectionId) throw new LogicError()
  }

  // SETTINGS PANEL

  const { data: orgUsersData, fetchMore: fetchMoreOrgUsers } = useGetOrganizationUsersQuery({
    variables: {
      id,
      offset: 0,
    },
    skip: tab !== 'settings',
  })

  const organizationMembers = useFetchAllWithPendingItems(
    fetchMoreOrgUsers,
    orgUsersData?.organization?.organizationUsers.items,
    data?.organization?.organizationUsers.total,
    1
  )

  const { data: orgInvitesData, fetchMore: fetchMoreOrgInvites } = useGetOrganizationInvitesQuery({
    variables: {
      id,
      offset: 0,
    },
    skip: tab !== 'settings',
  })

  const organizationInvites = useFetchAllWithPendingItems(
    fetchMoreOrgInvites,
    orgInvitesData?.organization?.organizationInvites.items,
    data?.organization?.organizationInvites.total,
    0
  )

  const organizationUserRowsByType: UsersTableProps['rowsByType'] = useMemo(() => {
    return {
      members: organizationMembers.map((item) => {
        if (isPendingItem(item)) return item
        return { id: item.user.id, ...item }
      }),
      invites: organizationInvites.map((item) => {
        if (isPendingItem(item)) return item
        if (!item.invitee && !item.email) throw new MissingDataError()
        const invitee = (() => {
          if (item.invitee) return item.invitee
          if (!item.email) throw new MissingDataError()
          return {
            name: item.email,
          }
        })()
        const { id, state: state, role, inviter } = item
        return {
          id,
          invitee,
          state,
          role,
          inviter,
        }
      }),
    }
  }, [organizationMembers, organizationInvites])

  const { isAuthenticated } = useAuth()

  const { data: profileOrganizationInvitesData, fetchMore: fetchMoreProfileOrganizationInvites } =
    useGetProfileOrganizationInvitesQuery({
      variables: { offset: 0 },
      onError: catchError('Failed to get invites', false),
      skip: !isAuthenticated,
    })

  const profileOrganizationInvites = useFetchAllWithPendingItems(
    fetchMoreProfileOrganizationInvites,
    profileOrganizationInvitesData?.profile?.organizationInvites.items,
    profile?.organizationInvites.total,
    0
  ).filter(isOrganizationInvite)

  const hasAllProfileOrganizationInvites =
    profileOrganizationInvitesData?.profile?.organizationInvites.total === profileOrganizationInvites.length

  // RENDER

  if (error?.message === 'Organization not found') return <NotFound id={id} descriptor="Organization" />
  if (error) throw error
  if (loading || !profile) return <ProjectDetailSkeleton />
  if (!data?.organization) throw new MissingDataError()

  const { organization } = data

  return (
    <>
      <PageTitle title={organization.name} />
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header
          getLogoUploadTarget={getLogoUploadTarget}
          lookups={lookups}
          onLogoChange={handleLogoChange}
          onNameChange={handleNameChange}
          onRequestInvite={handleRequestInvite}
          onStarChange={handleStarChange}
          onSummaryChange={handleSummaryChange}
          organization={organization}
          profileOrganizationInvites={hasAllProfileOrganizationInvites ? profileOrganizationInvites : null}
        />
        <Container
          maxWidth="lg"
          sx={{
            flex: '1 1',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DetailPageTabs
            value={tab}
            onChange={setTab}
            scrollable
            tabs={[
              {
                name: 'overview',
                content: (
                  <OverviewPanel
                    lookups={lookups}
                    onDescriptionChange={handleDescriptionChange}
                    organization={organization}
                  />
                ),
              },
              {
                name: 'projects',
                content: (
                  <ProjectsPanel
                    organization={organization}
                    onFetchMoreProjects={fetchNextPage}
                    loadingProjects={searchLoading}
                    loadingHasMore={projectsHasNext}
                    getProjectBoundsThumbProps={getProjectBoundsThumbProps}
                    pageOffset={projectPaginationOffset > theoreticalTotal ? theoreticalTotal : projectPaginationOffset}
                    onGotoWebRave={(id: string) => gotoWebRave({ id })}
                    items={displayItems}
                    lookups={lookups}
                    mapBoxRef={mapBoxRef}
                    mapController={mapController}
                    mapElements={mapElements}
                    onMapClick={handleMapClick}
                    onMapHover={handleMapHover}
                    onSearch={gotoSearch}
                    onSortChange={setProjectsSort}
                    sort={projectsSort}
                    viewType={viewType}
                    onViewTypeChange={setViewType}
                  />
                ),
              },
              {
                name: 'collections',
                content: (
                  <CollectionsPanel
                    onCreateCollection={handleCreateCollection}
                    items={collections}
                    lookups={lookups}
                    onPageChange={setCollectionsPage}
                    onSortChange={setCollectionsSort}
                    page={collectionsPage}
                    pageCount={collectionStats.pageCount}
                    organization={organization}
                    sort={collectionsSort}
                  />
                ),
              },
              organization.permissions.update && {
                name: 'settings',
                content: (
                  <SettingsPanel
                    onDeleteOrganization={handleDeleteOrganization}
                    onInviteAction={handleInviteAction}
                    onInviteCreate={handleInviteCreate}
                    onInviteDelete={handleInviteDelete}
                    onInviteResend={handleInviteResend}
                    onMemberRemove={handleMemberRemove}
                    onMemberUpdate={handleMemberUpdate}
                    organization={organization}
                    organizationUserRowsByType={organizationUserRowsByType}
                    profile={profile}
                    lookups={lookups}
                  />
                ),
              },
            ]}
          />
        </Container>
        <Footer />
      </Box>
    </>
  )
}
