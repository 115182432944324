import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { LogicError, useBooleanState, withTooltip } from '../../lib'
import { useTheme, Box, SxProps, Theme } from '@mui/material'
import { CancelEditIcon, EditIcon, SaveEditIcon } from '../Icons'
import { Markdown } from '../Markdown'
import { Variant } from '@mui/material/styles/createTypography'

export interface EditableTypographyProps {
  canEdit: boolean
  className?: string
  content: string
  editing?: boolean // when undefined, is uncontrolled
  name?: string
  typographySx?: SxProps<Theme>
  getError?: (targetContent: string) => boolean
  onChange?: (newContent: string) => void
  onStartEdit?: () => void
  onStopEdit?: () => void
  headerMode?: boolean
  promptDialogContent?: React.ReactNode
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'markdown'
  placeholder?: React.ReactNode
  multiline?: boolean
}

export const EditableTypography: React.FC<EditableTypographyProps> = ({
  canEdit,
  className,
  content,
  editing,
  typographySx = {},
  getError = () => false,
  onChange,
  onStartEdit,
  onStopEdit,
  name,
  headerMode,
  multiline,
  promptDialogContent = <Typography>Do you want to save your changes?</Typography>,
  variant = 'body1',
  placeholder = '',
}) => {
  const inputRef = useRef<HTMLInputElement>()

  const [editedContent, setEditedContent] = useState('')
  const [isEditing, startEditing, stopEditing] = useBooleanState()
  const [isPromptOpen, openPrompt, closePrompt] = useBooleanState()
  const [isHovering, startHovering, stopHovering] = useBooleanState()

  const error = isEditing && getError(editedContent)
  const isDirty = editedContent !== content

  const handleStartEdit = () => {
    if (!onChange) return
    if (onStartEdit) onStartEdit()
    setEditedContent(content)
    stopHovering()
    startEditing()
  }

  const handleCancelEdit = () => {
    if (onStopEdit) onStopEdit()
    stopEditing()
    closePrompt()
  }

  useEffect(() => {
    if (typeof editing === 'undefined') return

    // controlled editing state
    if (editing) {
      handleStartEdit()
    } else {
      handleCancelEdit()
    }
  }, [editing])

  const handleKeepEditing = () => {
    window.setTimeout(() => {
      // allow handleClickAway to fire before closing prompt
      closePrompt()
      window.setTimeout(() => {
        // allow prompt to close before attempting focus change
        inputRef.current?.focus()
      })
    })
  }

  const handleFinishEdit = () => {
    if (onStopEdit) onStopEdit()
    stopEditing()
    if (isPromptOpen) closePrompt()
    if (!isDirty) return
    onChange && onChange(editedContent)
  }

  const editRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isPromptOpen || !isEditing) return

    const handleClickOutside = (event: MouseEvent) => {
      if (editRef.current && event.target && !editRef.current.contains(event.target as any)) {
        if (!isDirty) {
          handleCancelEdit()
          return
        }

        event.stopPropagation()
        event.preventDefault()
        openPrompt()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [isPromptOpen, isEditing, isDirty])

  const handleChange: TextFieldProps['onChange'] = (e) => setEditedContent(e.target.value)

  const btnSx = headerMode ? { color: 'white', opacity: 0.8 } : {}

  const handleKeyDown: TextFieldProps['onKeyDown'] = (e) => {
    switch (e.key) {
      case 'Escape': {
        handleCancelEdit()
        break
      }
      case 'Enter': {
        if (!e.shiftKey) {
          if (multiline) {
            if (e.ctrlKey || e.metaKey) {
              e.preventDefault()
              if (!error) handleFinishEdit()
            }
          } else {
            e.preventDefault()
            if (!error) handleFinishEdit()
          }
        }
        break
      }
    }
  }

  useEffect(() => {
    // move cursor to end of field
    if (!isEditing || !onChange) return
    if (!inputRef.current) throw new LogicError()
    const el = inputRef.current
    el.focus()
    el.selectionStart = 100000 // aribitrary value greater than max length
    el.selectionEnd = 100000
  }, [isEditing])

  const { fontSize, fontFamily, lineHeight, fontWeight, letterSpacing } =
    useTheme().typography[variant !== 'markdown' ? variant : 'body1']

  if (!isEditing)
    return (
      <Box
        sx={{ position: 'relative', display: 'flex', flex: 1 }}
        onMouseOver={startHovering}
        onMouseOut={stopHovering}
      >
        {variant === 'markdown' && content ? (
          <Markdown content={content} typographySx={typographySx} />
        ) : (
          <Typography
            component={'div'}
            variant={variant as Variant}
            sx={{
              width: '100%',
              whiteSpace: 'pre-wrap',
              ...typographySx,
            }}
            className={className}
          >
            {content || placeholder}
          </Typography>
        )}
        {canEdit && (
          <Box sx={{ position: 'relative', top: '-0.5em', opacity: isHovering ? 1 : 0, transition: '250ms opacity' }}>
            {withTooltip(
              <IconButton onClick={handleStartEdit} sx={btnSx} size="small">
                <EditIcon />
              </IconButton>,
              name ? `Edit ${name}` : 'Edit'
            )}
          </Box>
        )}
      </Box>
    )

  return (
    <>
      <div ref={editRef} style={{ flex: 1 }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
          }}
        >
          <TextField
            sx={{ position: 'relative' }}
            margin="none"
            InputProps={{
              className,
              sx: {
                py: 0,
                px: 0.5,
                fontSize,
                fontFamily,
                lineHeight,
                fontWeight,
                letterSpacing,
              },
            }}
            value={editedContent}
            onChange={handleChange}
            multiline
            minRows={multiline ? 3 : undefined}
            maxRows={20}
            fullWidth
            error={error}
            inputRef={inputRef}
            onKeyDown={handleKeyDown}
          />
          <div style={{ flexShrink: 0, position: 'relative', top: '-0.5em' }}>
            {withTooltip(
              <IconButton onClick={handleCancelEdit} sx={btnSx}>
                <CancelEditIcon />
              </IconButton>,
              'Discard Changes'
            )}
            {withTooltip(
              <IconButton disabled={error} onClick={handleFinishEdit} sx={btnSx}>
                <SaveEditIcon color="primary" />
              </IconButton>,
              'Save Changes',
              error
            )}
          </div>
        </div>
        {variant === 'markdown' && (
          <Typography sx={{ fontStyle: 'italic' }}>
            <a href="https://commonmark.org/" target="__blank">
              Markdown
            </a>{' '}
            is supported.
          </Typography>
        )}
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </div>
      <Dialog open={isPromptOpen} onClose={closePrompt}>
        <DialogTitle>Finish Editing {name}</DialogTitle>
        <DialogContent>{promptDialogContent}</DialogContent>
        <DialogActions>
          <Button startIcon={<CancelEditIcon />} onClick={handleCancelEdit}>
            Discard Changes
          </Button>
          <Button startIcon={<EditIcon />} onClick={handleKeepEditing}>
            Keep Editing
          </Button>
          <Button startIcon={<SaveEditIcon color="primary" />} onClick={handleFinishEdit} disabled={error}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
