import { Box, Container, Stack } from '@mui/material'

import {
  CollectionCardFragment,
  OrganizationCardFragment,
  SavedSearchCardFragment,
  UserCardFragment,
} from '../../schema/base'
import { CommonSearchProps } from './types'
import { ShiftRight } from '../ShiftRight'
import { CollectionCard, CollectionCardSkeleton, OrganizationCard, UserCard } from '../ItemCards'
import { SortOrderField } from '../SortOrderField'
import { PaginationField } from '../PaginationField'
import { PendingItem } from '../PendingItem'
import { Lookups } from '../lookups'

export type OtherResultsProps = CommonSearchProps & {
  items: (
    | CollectionCardFragment
    | OrganizationCardFragment
    | UserCardFragment
    | SavedSearchCardFragment
    | PendingItem
  )[]
  lookups: Lookups
  NoResults: React.FC
  onRemoveFromCollectionClick?: (collectionId: string) => void
  type: 'Collection' | 'Organization' | 'User' | 'SavedSearch'
}

export const OtherResults: React.FC<OtherResultsProps> = ({
  NoResults,
  items,
  lookups,
  onRemoveFromCollectionClick,
  onPageChange,
  onSortChange,
  page,
  pageCount,
  resultsCount,
  sort,
}) => {
  return (
    <Stack sx={{ height: '100%', gap: 1 }}>
      <Stack
        direction="row"
        sx={{
          height: '0%',
          flex: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ borderBottom: 1, p: 1, paddingTop: 0, borderColor: 'divider', gap: 1 }}
            >
              <ShiftRight />
              <SortOrderField sort={sort} onSortChange={onSortChange} />
            </Stack>
            <Container
              maxWidth="md"
              sx={{
                flex: 1,
                overflow: 'scroll',
                padding: 1,
                gap: 1,
              }}
            >
              {resultsCount === 0 && <NoResults />}
              {items.map((item) => (
                // Box prevents card collapse
                <Box key={item.id}>
                  {(() => {
                    switch (item.__typename) {
                      case 'PendingItem':
                        return <CollectionCardSkeleton /> // TODO: add other skeletons
                      case 'Collection':
                        return (
                          <CollectionCard
                            collection={item}
                            lookups={lookups}
                            onRemoveFromCollectionClick={onRemoveFromCollectionClick}
                          />
                        )
                      case 'Organization':
                        return <OrganizationCard organization={item} lookups={lookups} />
                      case 'User':
                        return <UserCard user={item} lookups={lookups} />
                      case 'SavedSearch':
                        return <div>TODO</div>
                      // return <SavedSearchCard savedSearch={item} savedSearchUrl={itemUrl} />
                    }
                  })()}
                </Box>
              ))}
            </Container>
            <PaginationField onPageChange={onPageChange} page={page} pageCount={pageCount} />
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}
