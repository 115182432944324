import React from 'react'
import { Pagination } from '@mui/material'
import { Box } from '@mui/system'

export interface PaginationFieldProps {
  pageCount?: number
  onPageChange: (newPage: number) => void
  page?: number
  resultsCount?: number // not consumed within PaginationField, but this is a common prop everywhere pagination occurs
}

export const PaginationField: React.FC<PaginationFieldProps> = ({ pageCount = 0, onPageChange, page = 1 }) => {
  return (
    <Box sx={{ borderTop: 1, paddingY: 1, borderColor: 'divider' }}>
      <Pagination
        color="primary"
        count={pageCount}
        disabled={!pageCount}
        onChange={(e, newPage) => onPageChange(newPage)}
        page={page}
      />
    </Box>
  )
}
