import React from 'react'
import { ProjectTreeView } from '@riverscapes/react-common'
import ViewList from './ViewList'
import BaseLayerPicker from './BaseLayerPicker'
import LegendError from './LegendError'
import { BaseLayer } from '../../types'
import { Box, List, SxProps, Theme, useTheme } from '@mui/material'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    zIndex: 1000,
    padding: theme.spacing(0),
    border: '1px solid #EEEEEE',
    borderRadius: 0.5,
    position: 'absolute',
    background: 'white',
    width: 270,
    maxHeight: '90%',
    overflow: 'hidden',
    boxShadow: '3px 3px 3px #00000066',
    top: 15,
    right: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  subheaders: {
    background: 'white',
  },
  legendList: {
    height: '100%',
    flex: '1 1',
    overflow: 'scroll',
  },
})

export interface LegendProps {
  defaultView: string
  views: ProjectTreeView[]
  error?: string
  viewId: string | null
  setViewId: (viewId: string) => void
  baseLayer: BaseLayer
  baseLayers: BaseLayer[]
  setBaseLayer: (baselayer: BaseLayer) => void
  children: React.ReactElement[]
}

const Legend: React.FC<LegendProps> = ({
  views,
  defaultView,
  children,
  baseLayer,
  baseLayers,
  setBaseLayer,
  error,
  viewId,
  setViewId,
}: LegendProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  return (
    <Box sx={styles.root}>
      <BaseLayerPicker baseLayer={baseLayer} baseLayers={baseLayers} setBaseLayer={setBaseLayer} />
      <ViewList activeView={viewId} defaultView={defaultView} handleChange={setViewId} projectViews={views} />
      <List dense disablePadding sx={styles.legendList}>
        {children}
      </List>
      <LegendError error={error} />
    </Box>
  )
}

export default Legend
