import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <React.Fragment>
    <g>
      <g transform="matrix(1,0,0,1,-1.77636e-15,3.97768)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,0,11.9777)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,8,4)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,8,12)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,4,-0.0223232)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,4,7.97768)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,12,0)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
      <g transform="matrix(1,0,0,1,12,8)">
        <rect x="4" y="4.022" width="4" height="3.978" />
      </g>
    </g>
    <g id="Raster">
      <path
        d="M21,5.432C21,4.09 19.91,3 18.568,3L5.432,3C4.09,3 3,4.09 3,5.432L3,18.568C3,19.91 4.09,21 5.432,21L18.568,21C19.91,21 21,19.91 21,18.568L21,5.432Z"
        style={{ fillOpacity: 0 }}
      />
      <path d="M22,5.432C22,3.538 20.462,2 18.568,2L5.432,2C3.538,2 2,3.538 2,5.432L2,18.568C2,20.462 3.538,22 5.432,22L18.568,22C20.462,22 22,20.462 22,18.568L22,5.432ZM20,5.432L20,18.568C20,19.358 19.358,20 18.568,20C18.568,20 5.432,20 5.432,20C4.642,20 4,19.358 4,18.568C4,18.568 4,5.432 4,5.432C4,4.642 4.642,4 5.432,4C5.432,4 18.568,4 18.568,4C19.358,4 20,4.642 20,5.432Z" />
    </g>
  </React.Fragment>,
  'Multidot'
)
