import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { JSONEditor, JSONEditorPropsOptional, JSONValue } from 'vanilla-jsoneditor'

export interface JSONEditorProps extends JSONEditorPropsOptional {
  initJSON: JSONValue
}

const JSONEditorReact: React.FC<JSONEditorProps> = ({ initJSON, ...otherProps }) => {
  const refContainer = useRef<HTMLDivElement>(null)
  const refEditor = useRef<JSONEditor | null>(null)

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current as HTMLDivElement,
      props: {
        content: {
          json: initJSON,
        },
        ...otherProps,
      },
    })

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy()
        refEditor.current = null
      }
    }
  }, [])

  useEffect(() => {
    // update props
    if (refEditor.current) {
      refEditor.current.updateProps(otherProps)
    }
  }, [otherProps])

  return (
    <Box
      id="jsoneditor"
      ref={refContainer}
      sx={{
        flex: '1 1',
        height: '100%',
      }}
    />
  )
}

export default JSONEditorReact
