/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { GetCollectionDetailQuery, GetProfileDetailQuery } from '../../schema/base'
import { ItemRepresentation } from '../ItemImage'
import { ShiftRight } from '../ShiftRight'
import { Lookups } from '../lookups'
import { useBooleanState } from '../../lib'
import { Delete } from '@mui/icons-material'

export interface SettingsPanelProps {
  collection: NonNullable<GetCollectionDetailQuery['collection']>
  lookups: Lookups
  profile: NonNullable<GetProfileDetailQuery['profile']>
  onDeleteCollection: () => Promise<unknown>
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({ collection, lookups, profile, onDeleteCollection }) => {
  const theme = useTheme()
  // const users = collection.users?.items ?? []
  const canEdit = Boolean(collection.permissions.update)
  const canDelete = Boolean(collection.permissions.delete)

  const [isConfirmationOpen, openDeleteConfirmation, closeDeleteConfirmation] = useBooleanState()
  const [isDeleting, startDeleting, stopDeleting] = useBooleanState()

  const handleDeleteCollection = async () => {
    try {
      closeDeleteConfirmation()
      startDeleting()
      await onDeleteCollection()
    } finally {
      stopDeleting()
    }
  }

  return (
    <>
      <Stack direction="column" sx={{ gap: 3 }}>
        <Card>
          <CardContent>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography>
                Collection belongs to{' '}
                {profile.id === collection.ownedBy.id ? (
                  'you'
                ) : (
                  <ItemRepresentation item={collection.ownedBy} lookups={lookups} link />
                )}
              </Typography>
              <ShiftRight />
              {/* {canEdit && <Button disabled>Transfer Collection Ownership</Button>} */}
              {canEdit && (
                <Button
                  onClick={openDeleteConfirmation}
                  disabled={!canDelete}
                  variant="contained"
                  color="error"
                  startIcon={<Delete />}
                >
                  Delete Collection
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Dialog open={isConfirmationOpen} onClose={closeDeleteConfirmation}>
        <DialogTitle
          sx={{
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
            mb: 3,
          }}
        >
          Delete Collection
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete <strong>{collection.name}</strong>?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation} color="error">
            Cancel
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleDeleteCollection} color="error" variant="contained">
            Delete Collection
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleting}>
        <DialogContent>Deleting collection...</DialogContent>
      </Dialog>
    </>

    //   <SettingsTitle>Who can see this collection</SettingsTitle>
    //   <SettingsTitle>Manage access</SettingsTitle>
    //   <li>As a [role], you can [membership permissions]</li>
    //   <List
    //     sx={{
    //       width: '100%',
    //       maxWidth: 600,
    //       border: 1,
    //       borderColor: 'text.primary',
    //       borderRadius: 3,
    //     }}
    //   >
    //     {/* {users.map((user) => {
    //       return (
    //         <ListItem
    //           key={user.id}
    //           alignItems="flex-start"
    //           divider
    //           secondaryAction={
    //             <Stack direction="row" sx={{ gap: 1 }}>
    //               <Select size="small" value="contributor" fullWidth>
    //                 <MenuItem value="contributor">Role: Contributor</MenuItem>
    //               </Select>
    //               <Button>Remove</Button>
    //             </Stack>
    //           }
    //           disablePadding
    //         >
    //           <ListItemAvatar>
    //             <Avatar alt={user.name ?? ''} src="" />
    //           </ListItemAvatar>
    //           <ListItemText primary={user.name} secondary={`ID: ${user.id}`} />
    //         </ListItem>
    //       )
    //     })} */}
    //     {/* <Pagination count={Math.ceil(users.length / 10)} color="primary" /> */}
    //   </List>
    //   <SettingsTitle>Danger Zone</SettingsTitle>
    //   <Box sx={{ border: 1, borderColor: 'text.primary', borderRadius: 3 }}>
    //     <li>As a [role], you can [danger zone permissions]</li>
    //     <li>danger: Change visibility</li>
    //     <li>danger: Delete collection</li>
    //   </Box>
    // </Box>
  )
}
