import { Color } from '@mui/material'
import { TileService, ProjectTreeLeaf } from '@riverscapes/react-common'
import { LayerProps } from 'react-map-gl'
import { calculateOpacity } from '../../lib/util'

function commonFeatures(layerId?: string, tiles?: TileService): Partial<LayerProps> {
  const lyrProps: Partial<LayerProps> = {
    minzoom: tiles?.minZoom || 0,
    maxzoom: 24,
    layout: {},
  }
  // if (tiles?.rsLayer?.subLayers) {
  //   const sublyr = tiles.rsLayer.subLayers.find((sl) => sl.id === layerId) as GISTileLayer
  //   lyrProps['source-layer'] = sublyr.path
  // } else {
  //   lyrProps['source-layer'] = tiles?.rsLayer?.id
  // }
  return lyrProps
}

function getPolygonOutlineLayer(leaf: ProjectTreeLeaf, rndColor: Color, tiles?: TileService): Partial<LayerProps> {
  const lyrProps: Partial<LayerProps> = {
    ...commonFeatures(leaf.rsXPath, tiles),
    type: 'line',
    paint: {
      'line-color': rndColor[500],
      'line-opacity': calculateOpacity(leaf.transparency || 0),
    },
  }
  return lyrProps
}

function getPolygonLayer(leaf: ProjectTreeLeaf, rndColor: Color, tiles?: TileService): Partial<LayerProps> {
  const lyrProps: Partial<LayerProps> = {
    ...commonFeatures(leaf.rsXPath, tiles),
    type: 'fill',
    paint: {
      'fill-opacity': calculateOpacity(leaf.transparency || 80),
      'fill-color': rndColor[700],
    },
  }
  return lyrProps
}

function getPointLayer(leaf: ProjectTreeLeaf, rndColor: Color, tiles?: TileService): Partial<LayerProps> {
  const lyrProps: Partial<LayerProps> = {
    ...commonFeatures(leaf.rsXPath, tiles),
    type: 'circle',
    paint: {
      'circle-stroke-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color': rndColor[500],
      'circle-opacity': calculateOpacity(leaf.transparency || 0),
      'circle-radius': 2,
      'circle-color': rndColor[700],
    },
  }
  return lyrProps
}

function getLineLayer(leaf: ProjectTreeLeaf, rndColor: Color, tiles?: TileService): Partial<LayerProps> {
  const lyrProps: Partial<LayerProps> = {
    ...commonFeatures(leaf.rsXPath, tiles),
    type: 'line',
    paint: {
      'line-color': rndColor[500],
      'line-opacity': calculateOpacity(leaf.transparency || 0),
    },
  }
  return lyrProps
}

export default {
  line: getLineLayer,
  point: getPointLayer,
  polygon: getPolygonLayer,
  outline: getPolygonOutlineLayer,
}
