import React from 'react'
import { Card, CardContent, CardHeader, useTheme } from '@mui/material'

export interface OverviewCardProps {
  title?: string
}

export const OverviewCard: React.FC<React.PropsWithChildren & OverviewCardProps> = ({ title, children }) => {
  const theme = useTheme()

  return (
    <Card sx={{ mb: 2 }}>
      {title && <CardHeader title={title} sx={{ fontSize: '0.5rem', color: theme.palette.text.secondary }} />}
      <CardContent>{children}</CardContent>
    </Card>
  )
}
