import { atom, selector } from 'recoil'
import { appDefaults } from '../config'
import { layerSort } from '../lib/util'
import { Project, ProjectTreeLeaf, ProjectTreeLayerTileTypes } from '@riverscapes/react-common'

/**
 * This is the SOURCE OF TRUTH for the project tree. In some ways it's the MOST IMPORTANT ATOM
 */
export const projectAtom = atom<Project | null>({
  key: 'project',
  default: null,
})

/**
 * Whether the tree pane is open or closed.
 * TODO: this might be way better using useState. Not sure any other components need this
 */
export const drawerOpenAtom = atom<boolean>({
  key: 'drawerOpen',
  default: appDefaults.drawerOpen,
})

/**
 * When setting active layers or when order isn't important use this one
 */
export const activeIdsAtom = atom<number[]>({
  key: 'map/activeLayerIds',
  default: [],
})

/**
 * Which layer ids are currently on the map and highlighted in the tree
 */
export const activeTreeLeavesSelector = selector<ProjectTreeLeaf[]>({
  key: 'activeTreeLeaves',
  get: ({ get }) => {
    const proj = get(projectAtom)
    const activeIds = get(activeIdsAtom)
    if (!proj || !proj.tree) return []
    return proj?.tree.leaves.filter((leaf) => activeIds.indexOf(leaf.id) > -1) || []
  },
})

/**
 * Get the active map layer ids in the correct order. Use this in the map or legend, anywhere order
 * is important
 */
export const activeLeavesSortedSelector = selector<ProjectTreeLeaf[]>({
  key: 'map/activeLeavesSorted',
  get: ({ get }) => {
    const activeTreeLeaves = get(activeTreeLeavesSelector)
    const sorted = layerSort(activeTreeLeaves)
    return sorted
  },
})

/**
 * Utility selector for getting just the leaves we can add to a map
 */
export const projectMapLayerLeafsSelector = selector<ProjectTreeLeaf[]>({
  key: 'project/mapLayerLeaves',
  get: ({ get }) => {
    const proj = get(projectAtom)
    if (!proj || !proj.tree) return []
    return proj.tree.leaves.filter(({ layerType, rsXPath }) => {
      return ProjectTreeLayerTileTypes.includes(layerType) && rsXPath && rsXPath.length > 0
    })
  },
})

export const projectDSXPathsSelector = selector<string[]>({
  key: 'project/dsXPaths',
  get: ({ get }) => {
    const mapLyrs = get(projectMapLayerLeafsSelector)
    return mapLyrs.reduce<string[]>((acc, leaf) => {
      // NOTE: This appending of the lyrName makes this technically NOT a valid rsXPath.
      const newPath = leaf.rsXPath || 'unknown'
      return !leaf.rsXPath || acc.indexOf(newPath) > -1 ? acc : [...acc, newPath]
    }, [])
  },
})

/**
 * Get all the unique layer paths in a project
 * Use to initilize project-level downloader
 */
export const projectLayerPathsSelector = selector<string[]>({
  key: 'project/layerPaths',
  get: ({ get }) => {
    const mapLyrs = get(projectMapLayerLeafsSelector)
    return mapLyrs.reduce<string[]>((acc, leaf) => {
      // NOTE: This appending of the lyrName makes this technically NOT a valid rsXPath.
      const newPath = leaf.lyrName ? `${leaf.rsXPath}/${leaf.lyrName}` : leaf.rsXPath || 'unknown'
      return !leaf.rsXPath || acc.indexOf(newPath) > -1 ? acc : [...acc, newPath]
    }, [])
  },
})
