import React from 'react'
import { Stack, ToggleButtonGroup, ToggleButton, Button } from '@mui/material'
import { GridRenderCellParams, GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { OrganizationRoleEnum, OrganizationInviteStateEnum } from '../../schema/base'
import { AddIcon } from '../Icons'
import { ItemRepresentation } from '../ItemImage'
import { ShiftRight } from '../ShiftRight'
import {
  OrganizationMemberRow,
  OrganizationInviteRow,
  ActionableRow,
  ProfileInviteRow,
  isProfileInviteRow,
} from './types'
import { isPendingItem } from '../../lib'
import { PendingCell, PendingItem } from '../PendingItem'

const MemberName = ({
  row,
}: GridRenderCellParams<void, (OrganizationMemberRow & ActionableRow) | PendingItem>): JSX.Element => {
  if (isPendingItem(row)) return <PendingCell />
  return <ItemRepresentation item={{ ...row.user, __typename: 'User' }} link lookups={row.lookups} />
}

const InviteeName = ({
  row,
}: GridRenderCellParams<void, (OrganizationInviteRow & ActionableRow) | PendingItem>): JSX.Element => {
  if (isPendingItem(row)) return <PendingCell />
  return (
    <ItemRepresentation
      item={{ __typename: 'User', id: 'invitee', ...row.invitee }}
      link={!!row.invitee.id}
      lookups={row.lookups}
    />
  )
}

const OrganizationName = ({
  row,
}: GridRenderCellParams<void, (ProfileInviteRow & ActionableRow) | PendingItem>): JSX.Element => {
  if (isPendingItem(row)) return <PendingCell />
  return <ItemRepresentation item={{ ...row.organization, __typename: 'Organization' }} link lookups={row.lookups} />
}

export const getRoleDescriptor = (value: OrganizationRoleEnum): string => {
  return {
    [OrganizationRoleEnum.Owner]: 'Owner',
    [OrganizationRoleEnum.Admin]: 'Administrator',
    [OrganizationRoleEnum.Contributor]: 'Contributor',
    [OrganizationRoleEnum.Viewer]: 'Viewer',
    [OrganizationRoleEnum.None]: 'None',
  }[value]
}

const Role = ({ value }: GridRenderCellParams<OrganizationRoleEnum>): React.ReactNode => {
  if (!value) return null
  return getRoleDescriptor(value)
}

const InviteState = ({
  row,
}: GridRenderCellParams<any, OrganizationInviteRow | ProfileInviteRow | PendingItem>): React.ReactNode => {
  if (isPendingItem(row) || !row.state) return null
  return {
    [OrganizationInviteStateEnum.Accepted]: 'Accepted',
    [OrganizationInviteStateEnum.Expired]: 'Expired',
    [OrganizationInviteStateEnum.Invited]: `Invited by ${row.inviter.name}`,
    [OrganizationInviteStateEnum.Rejected]: 'Rejected',
    [OrganizationInviteStateEnum.Requested]: isProfileInviteRow(row)
      ? 'Awaiting request response'
      : 'Requested by user',
  }[row.state]
}

const Actions = ({ row }: GridRenderCellParams<void, ActionableRow | PendingItem>): React.ReactNode => {
  if (isPendingItem(row)) return null
  return row.actions
}

const getRoleRank = (role: OrganizationRoleEnum) => {
  return {
    [OrganizationRoleEnum.Owner]: 1,
    [OrganizationRoleEnum.Admin]: 2,
    [OrganizationRoleEnum.Contributor]: 3,
    [OrganizationRoleEnum.Viewer]: 4,
    [OrganizationRoleEnum.None]: 5,
  }[role]
}

export const organizationMembersColumns: GridColDef<(OrganizationMemberRow & ActionableRow) | PendingItem>[] = [
  {
    field: 'user',
    headerName: 'User',
    flex: 1,
    renderCell: MemberName,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    renderCell: Role,
    sortComparator: (value1, value2) => getRoleRank(value2) - getRoleRank(value1),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    width: 100,
    hideable: false,
    headerAlign: 'center',
    renderCell: Actions,
    disableColumnMenu: true,
  },
]

export const organizationInvitesColumns: GridColDef<(OrganizationInviteRow & ActionableRow) | PendingItem>[] = [
  {
    field: 'invitee',
    headerName: 'User',
    flex: 1,
    renderCell: InviteeName,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    renderCell: Role,
    sortComparator: (value1, value2) => getRoleRank(value2) - getRoleRank(value1),
  },
  {
    field: 'state',
    headerName: 'Status',
    flex: 1,
    renderCell: InviteState,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    width: 100,
    hideable: false,
    headerAlign: 'center',
    renderCell: Actions,
    disableColumnMenu: true,
  },
]

export const profileInvitesColumns: GridColDef<(ProfileInviteRow & ActionableRow) | PendingItem>[] = [
  {
    field: 'organization',
    headerName: 'Organization',
    flex: 1,
    renderCell: OrganizationName,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    renderCell: Role,
    sortComparator: (value1, value2) => getRoleRank(value2) - getRoleRank(value1),
  },
  {
    field: 'state',
    headerName: 'Status',
    flex: 1,
    renderCell: InviteState,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    width: 100,
    hideable: false,
    headerAlign: 'center',
    renderCell: Actions,
    disableColumnMenu: true,
  },
]

export const Toolbar = ({
  mode,
  setMode,
  counts,
  onInviteClick,
}: {
  mode: 'members' | 'requests'
  setMode: (newValue: 'members' | 'requests') => void
  counts: { members: number; requests: number }
  onInviteClick: () => void
}): JSX.Element => {
  const { members, requests } = counts

  const handleModeChange = (newMode: string | null) => {
    if (!newMode) return
    setMode(newMode as 'members' | 'requests')
  }

  return (
    <GridToolbarContainer>
      <Stack direction="row" alignItems="center" width="100%">
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          onChange={(e, newValue) => handleModeChange(newValue)}
        >
          <ToggleButton value="members">Members ({members})</ToggleButton>
          <ToggleButton value="requests" disabled={!requests}>
            Requests ({requests})
          </ToggleButton>
        </ToggleButtonGroup>
        <ShiftRight />
        <Button startIcon={<AddIcon />} onClick={onInviteClick}>
          Invite User
        </Button>
      </Stack>
    </GridToolbarContainer>
  )
}
