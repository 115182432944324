import React, { useEffect, useState } from 'react'
import LegendConnect from './Legend.connect'
import ZoomIndicatorConnect from './ZoomIndicator.connect'
import { useRecoilValue } from 'recoil'
import { baseLayerAtom, boundsSelector, mapLoadedAtom } from '../recoil'
import Map from '../components/map/Map'
import TreeToggleConnect from '../connectors/TreeToggle.connect'
import MapLayerConnect from './MapLayer.connect'
import useZoomExtents from '../hooks/useZoomExtents'

// We're letting notistack handle all our snackbars
// https://v4.mui.com/components/snackbars/#complementary-projects
import { SnackbarProvider } from 'notistack'

import log from 'loglevel'

const MapConnect: React.FC = () => {
  const zoomLayerExtent = useZoomExtents()
  const bounds = useRecoilValue(boundsSelector)
  const mapLoaded = useRecoilValue(mapLoadedAtom)
  const baseLayer = useRecoilValue(baseLayerAtom)

  // The only use of this is to detect when it goes from null to a value
  const [boundsSet, setBoundsSet] = useState<boolean>(false)
  // log.debug('RENDER MapConnect')
  useEffect(() => {
    const run = async () => {
      if (!boundsSet && bounds.bounds) {
        const success = await zoomLayerExtent(bounds.bounds)
        log.info('MapConnect: Trying to center map the first time', success)
        if (success) setBoundsSet(true)
      }
    }
    run()
  }, [bounds, boundsSet])

  return (
    <SnackbarProvider
      maxSnack={3}
      dense
      preventDuplicate
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <LegendConnect />
      <Map baseLayer={baseLayer} zoomBounds={bounds.zoom} bounds={bounds.bounds} styleLoading={!mapLoaded}>
        {[
          // This is the button that turns off the tree
          <TreeToggleConnect key="toggleBtn" />,
          <MapLayerConnect key="mapLayers" />,
        ]}
      </Map>
      <ZoomIndicatorConnect />
    </SnackbarProvider>
  )
}

export default MapConnect
