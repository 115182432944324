import { operations } from '@riverscapes/react-common'
import produce from 'immer'

const {
  GetProfileStarsProjectsDocument,
  UpdateProfileAvatarImageDocument,
  useGetProfileDetailQuery,
  useGetProfileOrganizationInvitesQuery,
  useGetProfileOrganizationsQuery,
  useGetProfileStarsProjectsQuery,
  useGetUserByIdLazyQuery,
  useGetUserDetailQuery,
  useGetUsersByNameLazyQuery,
  useUpdateProfileAvatarImageMutation: _useUpdateProfileAvatarImageMutation,
  useUpdateProfileNameMutation: _useUpdateProfileNameMutation,
  useUpdateProfileSummaryMutation: _useUpdateProfileSummaryMutation,
} = operations

export {
  GetProfileStarsProjectsDocument,
  useGetProfileDetailQuery,
  useGetProfileOrganizationInvitesQuery,
  useGetProfileOrganizationsQuery,
  useGetProfileStarsProjectsQuery,
  useGetUserByIdLazyQuery,
  useGetUserDetailQuery,
  useGetUsersByNameLazyQuery,
}

export const useUpdateProfileNameMutation = (): ReturnType<typeof _useUpdateProfileNameMutation> =>
  _useUpdateProfileNameMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateProfile: {
        __typename: 'Profile',
        initialized: true,
        ...variables,
      },
    }),
  })

export const useUpdateProfileSummaryMutation = (): ReturnType<typeof _useUpdateProfileSummaryMutation> =>
  _useUpdateProfileSummaryMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateProfile: {
        __typename: 'Profile',
        ...variables,
      },
    }),
  })

export const useUpdateProfileAvatarImageMutation = (): ReturnType<typeof _useUpdateProfileAvatarImageMutation> =>
  _useUpdateProfileAvatarImageMutation({
    update: (cache, { data }) => {
      const modifiedData = produce(data, (draft) => {
        if (draft?.updateProfile?.avatar) {
          draft.updateProfile.avatar = `${draft.updateProfile.avatar}?u=${Date.now()}` // bust cache as url does not change
        }
      })
      cache.writeQuery({
        query: UpdateProfileAvatarImageDocument,
        data: modifiedData,
      })
    },
  })
