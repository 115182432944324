import { FormControl, InputLabel, MenuItem, Select, SxProps, Theme, Typography, useTheme } from '@mui/material'
import React from 'react'
import { BaseLayer } from '../../types'

interface BaseLayerPickerProps {
  baseLayer: BaseLayer
  baseLayers: BaseLayer[]
  setBaseLayer: (baseLyr: BaseLayer) => void
}

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    // margin: theme.spacing(1, 0)
  },
  select: {
    '& .MuiSelect-select': {
      display: 'flex',
    },
  },
  menuIcon: {
    mr: theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
  },
})

const BaseLayerPicker: React.FC<BaseLayerPickerProps> = ({
  baseLayer,
  baseLayers,
  setBaseLayer,
}: BaseLayerPickerProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)

  const viewOptions = baseLayers.map((v, idx) => {
    const Icon = v.Icon
    return (
      <MenuItem key={`view-${idx}`} value={v.id} sx={styles.menuItem}>
        <Icon sx={styles.menuIcon} />
        <Typography>{v.title}</Typography>
      </MenuItem>
    )
  })

  return (
    <FormControl sx={styles.root} fullWidth size="small" variant="filled">
      <InputLabel>Basemap</InputLabel>
      <Select
        id="baselayer"
        value={baseLayer.id}
        variant="filled"
        sx={styles.select}
        onChange={(event) => {
          const newBl = baseLayers.find((bl) => bl.id === event.target.value)
          if (newBl) setBaseLayer(newBl)
        }}
      >
        {viewOptions}
      </Select>
    </FormControl>
  )
}

export default BaseLayerPicker
