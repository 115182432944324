import { LogicError, operations } from '@riverscapes/react-common'
import { evictAllQueries } from '../cache'

const {
  GetProjectDetailDocument,
  useGetProjectCollectionsQuery,
  useGetProjectDatasetsQuery,
  useGetProjectDetailQuery,
  useGetProjectQaqcQuery,
  useGetProjectSearchResultsLazyQuery,
  useGetProjectSearchResultsQuery,
  useGetProjectTypesLazyQuery,
  useGetProjectsByNameLazyQuery,
  useUpdateProjectHeroImageMutation,
  useUpdateProjectMetaMutation: _useUpdateProjectMetaMutation,
  useUpdateProjectTagsMutation: _useUpdateProjectTagsMutation,
  useUpdateProjectVisibilityMutation: _useUpdateProjectVisibilityMutation,
  useDeleteProjectMutation: _useDeleteProjectMutation,
  useChangeProjectOwnerMutation: _useChangeProjectOwnerMutation,
  useUpdateProjectCitationMutation,
  useUpdateProjectDescriptionMutation,
  useUpdateProjectNameMutation,
  useUpdateProjectSummaryMutation,
} = operations

export {
  useGetProjectCollectionsQuery,
  useGetProjectDatasetsQuery,
  useGetProjectDetailQuery,
  useGetProjectQaqcQuery,
  useGetProjectSearchResultsLazyQuery,
  useGetProjectSearchResultsQuery,
  useGetProjectTypesLazyQuery,
  useGetProjectsByNameLazyQuery,
  useUpdateProjectHeroImageMutation,
  useUpdateProjectCitationMutation,
  useUpdateProjectDescriptionMutation,
  useUpdateProjectNameMutation,
  useUpdateProjectSummaryMutation,
}

export const useChangeProjectOwnerMutation = (): ReturnType<typeof _useChangeProjectOwnerMutation> =>
  _useChangeProjectOwnerMutation({
    refetchQueries: [GetProjectDetailDocument],
    update: evictAllQueries,
  })

export const useDeleteProjectMutation = (): ReturnType<typeof _useDeleteProjectMutation> =>
  _useDeleteProjectMutation({
    update: (cache, _, { variables }) => {
      if (!variables?.id) throw new LogicError()
      cache.evict({
        id: cache.identify({ __typename: 'Project', id: variables.id }),
      })
      cache.gc()
    },
  })

export const useUpdateProjectVisibilityMutation = (): ReturnType<typeof _useUpdateProjectVisibilityMutation> =>
  _useUpdateProjectVisibilityMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateProject: {
        __typename: 'Project',
        ...variables,
      },
    }),
  })

export const useUpdateProjectTagsMutation = (): ReturnType<typeof _useUpdateProjectTagsMutation> =>
  _useUpdateProjectTagsMutation({
    optimisticResponse: (variables) => ({
      __typename: 'Mutation',
      updateProject: {
        __typename: 'Project',
        ...variables,
      },
    }),
  })

export const useUpdateProjectMetaMutation = (): ReturnType<typeof _useUpdateProjectMetaMutation> =>
  _useUpdateProjectMetaMutation({
    optimisticResponse: ({ id, meta }) => ({
      __typename: 'Mutation',
      updateProject: {
        __typename: 'Project',
        id,
        meta: meta.map((row) => ({ __typename: 'MetaData', ...row })),
      },
    }),
  })
