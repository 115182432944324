import { Box, ClickAwayListener, Divider, List, Popper, Stack } from '@mui/material'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { GetProjectCardQuery } from '../../schema/base'
import {
  MiniExcessProjectsCard,
  MiniProjectCard,
  MiniProjectCardSkeleton,
  ProjectBoundsThumb,
  ProjectBoundsThumbProps,
} from '../ItemCards'
import { PendingItem } from '../PendingItem'
import { Lookups } from '../lookups'
import { Bbox } from './useInteractiveMapController'

export interface ProjectsMapPopupProps {
  groups: {
    bounds: { id: string; polygonUrl: string; bbox: Bbox }
    items: NonNullable<GetProjectCardQuery['project'] | PendingItem>[]
  }[]
  sticky?: boolean
  x: number
  y: number
  lookups: Lookups
  onClose: (reason: 'escape' | 'clickaway') => void
  boxRef: RefObject<HTMLDivElement>
  getProjectBoundsThumbProps?: (bounds: {
    id: string
    polygonUrl: string
    bbox: Bbox
  }) => Partial<ProjectBoundsThumbProps>
}

const THUMB_SIZE = 75
const THUMB_MARGIN = 10
const DIVIDER_HEIGHT = 7
const POPUP_PADDING_Y = 16
const POPUP_PADDING_X = 8

export const ProjectsMapPopup: React.FC<ProjectsMapPopupProps> = ({
  boxRef, // map area container element
  groups,
  lookups,
  onClose,
  sticky,
  x,
  y,
  getProjectBoundsThumbProps,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null)
  const rowHeightRef = useRef<HTMLDivElement>(null)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  useEffect(() => {
    setAnchorEl(anchorRef.current)
  }, [])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose('escape')
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [open])

  const handleClickAway = () => {
    if (!sticky) return
    onClose('clickaway')
  }

  const processedGroups = useMemo(() => {
    const totalItems = groups.reduce((acc, { items }) => acc + items.length, 0)
    let maxRowsPerGroup = Infinity

    if (boxRef.current && rowHeightRef.current) {
      const maxContentHeight = boxRef.current.getBoundingClientRect().height / 2 - POPUP_PADDING_Y * 2
      const rowHeight = rowHeightRef.current.getBoundingClientRect().height

      const estimatedMaxGroupsFit = Math.floor(maxContentHeight / (THUMB_SIZE + DIVIDER_HEIGHT))
      const estimatedMaxItemsFit = Math.floor(maxContentHeight / rowHeight)
      if (totalItems > estimatedMaxItemsFit) {
        if (groups.length > estimatedMaxGroupsFit) {
          maxRowsPerGroup = 3 // groups may not fit anyway; 3 ensures items don't exceed thumb space
        } else {
          maxRowsPerGroup = Math.max(Math.floor(estimatedMaxItemsFit / groups.length), 3)
        }
      }
    }

    return groups.map(({ bounds, items }) => {
      const displayItems = [...items]
      let excessCount = 0
      if (displayItems.length > maxRowsPerGroup) {
        displayItems.length = maxRowsPerGroup - 1 // less 1 as we now need a row for the excess count
        excessCount = items.length - displayItems.length
      }
      return { bounds, displayItems, excessCount }
    })
  }, [groups, sticky])

  const totalExcessCount = processedGroups.reduce((acc, { excessCount }) => acc + excessCount, 0)

  const maxPopupWidth = useMemo(() => {
    if (!boxRef.current) return
    return boxRef.current.getBoundingClientRect().width * 0.8 // max out at 80% of box width
  }, [groups])

  return (
    <>
      <div ref={rowHeightRef} style={{ position: 'absolute', left: -10000, top: -10000, paddingBottom: '1px' }}>
        <MiniProjectCardSkeleton />
      </div>
      <div
        ref={anchorRef}
        style={{
          top: y,
          left: x - (THUMB_SIZE + THUMB_MARGIN) / 2,
          position: 'absolute',
        }}
      />
      {anchorEl && (
        <Popper
          disablePortal
          open
          anchorEl={anchorEl}
          sx={{
            pointerEvents: sticky ? 'all' : 'none',
            zIndex: 1000, // necessary value not researched (just need to clear map controls)
          }}
          placement="bottom"
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: !!boxRef.current,
              options: {
                boundary: boxRef.current,
                altBoundary: true,
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onPointerDown">
            <div
              style={{
                background: sticky ? '#ffffff88' : 'transparent',
                transition: 'background 200ms linear',
                padding: '0 3px',
                borderRadius: '5px',
                maxWidth: maxPopupWidth,
              }}
            >
              <Stack
                style={{
                  paddingTop: POPUP_PADDING_Y,
                  paddingRight: POPUP_PADDING_X,
                  paddingBottom: POPUP_PADDING_Y,
                  paddingLeft: POPUP_PADDING_X,
                }}
              >
                {processedGroups.map(({ bounds, displayItems, excessCount }, groupIndex) => {
                  const isLastGroup = groupIndex === groups.length - 1
                  const projectBoundsThumbProps = getProjectBoundsThumbProps?.(bounds) ?? {}
                  const { onAddClick, onZoomClick, ...restThumbProps } = projectBoundsThumbProps
                  const handleAddClick =
                    onAddClick &&
                    (() => {
                      handleClickAway()
                      onAddClick()
                    })
                  const handleZoomClick =
                    onZoomClick &&
                    (() => {
                      handleClickAway()
                      onZoomClick()
                    })
                  return (
                    <Box key={bounds.id}>
                      {!!groupIndex && (
                        <div
                          style={{
                            height: sticky ? DIVIDER_HEIGHT : 0,
                            overflow: 'hidden',
                            transition: 'height 200ms linear',
                          }}
                        >
                          <Divider style={{ position: 'relative', top: (DIVIDER_HEIGHT - 1) / 2 }} />
                        </div>
                      )}
                      <Stack direction="row">
                        <div
                          style={{
                            flex: '0 0 auto',
                            minHeight: sticky ? THUMB_SIZE : '1rem',
                            transition: 'min-height 200ms linear',
                            width: THUMB_SIZE + THUMB_MARGIN,
                            overflow: 'hidden',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              height: THUMB_SIZE,
                              width: THUMB_SIZE,
                              transition: 'opacity 200ms linear',
                              opacity: sticky ? 1 : 0,
                            }}
                          >
                            <ProjectBoundsThumb
                              id={bounds.id}
                              polygonUrl={bounds.polygonUrl}
                              onAddClick={handleAddClick}
                              onZoomClick={handleZoomClick}
                              {...restThumbProps}
                              highlight
                              onHoverChange={undefined}
                            />
                          </div>
                        </div>
                        <Stack
                          sx={{
                            flex: '1 1 auto',
                            pb: 0.5,
                          }}
                        >
                          <List dense disablePadding sx={{ pb: 0.5 }}>
                            {displayItems.map((item) =>
                              item.__typename === 'PendingItem' ? (
                                <MiniProjectCardSkeleton key={item.id} />
                              ) : (
                                <MiniProjectCard key={item.id} project={item} lookups={lookups} link={!!sticky} />
                              )
                            )}
                          </List>
                          {((sticky && excessCount > 0) || // sticky mode: show this group's excess count
                            (!sticky && totalExcessCount > 0 && isLastGroup)) && ( // floating mode: show totalExcessCount on last group
                            <MiniExcessProjectsCard
                              excessCount={
                                sticky
                                  ? excessCount // sticky mode: show this group's excess count, plus 1 as final row was converted to this excess count
                                  : totalExcessCount // floating mode: show totalExcessCount
                              }
                              onLimitBounds={sticky ? handleAddClick : undefined}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                  )
                })}
              </Stack>
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  )
}
