import { JSONObject } from '../types'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  EmailAddress: { input: string; output: string; }
  JSONObject: { input: JSONObject; output: JSONObject; }
  URL: { input: string; output: string; }
};

export type Collection = DbObj & HasOwner & HasStar & HasTags & {
  __typename?: 'Collection';
  /** Citation string */
  citation?: Maybe<Scalars['String']['output']>;
  /** (Optional) Who to contact (might be different from owner) */
  contact?: Maybe<User>;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** Banner image (optional) */
  heroImage?: Maybe<HeroImage>;
  id: Scalars['ID']['output'];
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  ownedBy: Owner;
  permissions: ObjectPermissions;
  /** Paginated, ordered list of projects this collection contains */
  projects: PaginatedProjects;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
  visibility: ProjectGroupVisibilityEnum;
};


export type CollectionProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type CollectionInput = {
  /** Citation string */
  citation?: InputMaybe<Scalars['String']['input']>;
  /** Remove the contact for this collection */
  clearContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** Resets/deletes the user's avatar back to the default */
  clearHeroImage?: InputMaybe<Scalars['Boolean']['input']>;
  /** User ID of the contact for this collection (Optional) */
  contact?: InputMaybe<OwnerInput>;
  /** Verbose description of the object (< SOME_LIMIT kb of text) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The Code you were given from the checkUploadImage endpoint */
  heroImageToken?: InputMaybe<Scalars['String']['input']>;
  /** Metadata for this object */
  meta?: InputMaybe<Array<MetaDataInput>>;
  /** Name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Brief tagline or intro sentence for the item (<500 characters) */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Tags for this collection */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Collection visibility settings: Either PUBLIC or SECRET */
  visibility?: InputMaybe<ProjectGroupVisibilityEnum>;
};

export type DbObj = {
  /** User who created object */
  createdBy: User;
  /** Identifies the date and time when the object was created. */
  createdOn: Scalars['DateTime']['output'];
  /** Verbose description of the object (< SOME_LIMIT kb of text) */
  description: Scalars['String']['output'];
  /** Db GUID */
  id: Scalars['ID']['output'];
  /** Key-value pairs for attaching metadata to objects */
  meta: Array<MetaData>;
  /** Name of the object */
  name: Scalars['String']['output'];
  /** Brief tagline or intro sentence for the item (<500 characters) */
  summary: Scalars['String']['output'];
  /** User who last updated object */
  updatedBy: User;
  /** Identifies the date and time when the object was updated. */
  updatedOn: Scalars['DateTime']['output'];
};

/** Notifications get a stripped-down version of the DBObj */
export type DbObjNotifications = {
  createdBy: User;
  /**
   * User who created object
   * NOTE: We need to account for users that are no longer there,
   * That's why we have the duplicated fields
   */
  createdById: Scalars['ID']['output'];
  createdByName: Scalars['String']['output'];
  /** Identifies the date and time when the object was created. */
  createdOn: Scalars['DateTime']['output'];
  /** Db GUID */
  id: Scalars['ID']['output'];
  /** Name of the object */
  name: Scalars['String']['output'];
  /** Brief tagline or intro sentence for the item (<500 characters) */
  summary: Scalars['String']['output'];
  updatedBy: User;
  /**
   * User who last updated object
   * NOTE: We need to account for users that are no longer there,
   * That's why we have the duplicated fields
   */
  updatedById: Scalars['ID']['output'];
  updatedByName: Scalars['String']['output'];
  /** Identifies the date and time when the object was updated. */
  updatedOn: Scalars['DateTime']['output'];
};

export type DbObjNotificationsInput = {
  /** User who created object */
  createdById: Scalars['ID']['input'];
  createdByName: Scalars['String']['input'];
  /** Identifies the date and time when the object was created. */
  createdOn: Scalars['DateTime']['input'];
  /** Db GUID */
  id: Scalars['ID']['input'];
  /** Name of the object */
  name: Scalars['String']['input'];
  /** Brief tagline or intro sentence for the item (<500 characters) */
  summary: Scalars['String']['input'];
  /** User who last updated object */
  updatedById: Scalars['ID']['input'];
  updatedByName: Scalars['String']['input'];
  /** Identifies the date and time when the object was updated. */
  updatedOn: Scalars['DateTime']['input'];
};

export type DbSimpleObj = {
  /** User who created object */
  createdBy: User;
  /** Identifies the date and time when the object was created. */
  createdOn: Scalars['DateTime']['output'];
  /** Db GUID */
  id: Scalars['ID']['output'];
  /** User who last updated object */
  updatedBy: User;
  /** Identifies the date and time when the object was updated. */
  updatedOn: Scalars['DateTime']['output'];
};

export type Dataset = DbObj & {
  __typename?: 'Dataset';
  analysisId?: Maybe<Scalars['String']['output']>;
  /** These are the projects that use this layer */
  attachedDatasets: PaginatedDatasets;
  citation?: Maybe<Scalars['String']['output']>;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  datasetContainer: DatasetContainerTypesEnum;
  /**
   * All of these fields are actually derivatives of the rsXPath
   *
   * The type of dataset helps us understand how to work with it
   * These types are derived directly from the XML enumeration
   */
  datasetType: DatasetTypeEnum;
  datasetXMLId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /**
   * For Direct S3 File listings. This is a query that depends on the type
   * of dataset. Sometimes we also need sidecars to come back
   */
  files: Array<FileDownloadMeta>;
  /**
   * The ID for datasets takes the form 'PROJ_ORIGIN_GUID/REALIZATION_ID/DATASET_ID'
   * and corresponds to the S3 path we store things
   *
   * NOTE: ID is different from the dsId (which is the id attribute on the XML node)
   */
  id: Scalars['ID']['output'];
  /** Some datasets can have sublayers (mainly just Geopackages) */
  layers?: Maybe<Array<Maybe<DatasetLayer>>>;
  localPath: Scalars['String']['output'];
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  /** The original dataset (if there is one). THis is null if this is the origin */
  origin?: Maybe<Dataset>;
  /** Backwards lookup of the project this dataset belongs to */
  project: Project;
  realizationXMLId?: Maybe<Scalars['String']['output']>;
  /** Backwards lookup to the project referenced b */
  refProject?: Maybe<Project>;
  rsXPath: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};


export type DatasetAttachedDatasetsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

/** These are the XML tag containers where you can find datasets */
export const DatasetContainerTypesEnum = {
  CommonDatasets: 'CommonDatasets',
  Configuration: 'Configuration',
  Datasets: 'Datasets',
  Inputs: 'Inputs',
  Intermediates: 'Intermediates',
  Logs: 'Logs',
  Outputs: 'Outputs',
  Products: 'Products'
} as const;

export type DatasetContainerTypesEnum = typeof DatasetContainerTypesEnum[keyof typeof DatasetContainerTypesEnum];
export type DatasetInput = {
  citation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optional. If this is a linked dataset then its extRef should be present. this is an attribute on the dataset node in the XML
   * and it takes the form:
   *   badfe8c1-0342-4876-8fac-b2eb5493a90f:Project/REALIZATION/SLOPE
   *   PROJECT_UUID:rsXPath
   */
  extRef?: InputMaybe<Scalars['ID']['input']>;
  /** Geopackages can have sublayers */
  layers?: InputMaybe<Array<DatasetLayerInput>>;
  /** local path on the user's system. This comes directly from the <Path> node in the project xml */
  localPath: Scalars['String']['input'];
  meta?: InputMaybe<Array<MetaDataInput>>;
  name: Scalars['String']['input'];
  /**
   * This is the rsXPath to the node in the project (not the origin project)
   * It is a proprietary path system to uniquely identify datasets in our XML files
   * This rsXPath takes the form: 'Project/Realizations/Realization#REAL1/Intermediates/Geopackage#GEO1'
   * where the hashes are shorthand for IDS
   */
  rsXPath: Scalars['String']['input'];
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Geopackages can have sublayers */
export type DatasetLayer = {
  __typename?: 'DatasetLayer';
  citation?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  lyrName: Scalars['String']['output'];
  meta?: Maybe<Array<MetaData>>;
  name: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
};

/** Geopackages can have sublayers */
export type DatasetLayerInput = {
  citation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  lyrName: Scalars['String']['input'];
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type DatasetLayerUpdate = {
  citation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

/**
 * This mirrors the RiverscapesXML XSD Schema
 * Unfortunately we need to keep them in sync
 */
export const DatasetTypeEnum = {
  AuxInstrumentFile: 'AuxInstrumentFile',
  Csv: 'CSV',
  ConfigFile: 'ConfigFile',
  Dem: 'DEM',
  DataTable: 'DataTable',
  Database: 'Database',
  File: 'File',
  Geopackage: 'Geopackage',
  HtmlFile: 'HTMLFile',
  HillShade: 'HillShade',
  Image: 'Image',
  InstrumentFile: 'InstrumentFile',
  LogFile: 'LogFile',
  MsAccessDb: 'MSAccessDB',
  Pdf: 'PDF',
  Raster: 'Raster',
  SqLiteDb: 'SQLiteDB',
  SurveyQualityDb: 'SurveyQualityDB',
  Tin: 'TIN',
  Vector: 'Vector',
  Video: 'Video',
  ZipFile: 'ZipFile'
} as const;

export type DatasetTypeEnum = typeof DatasetTypeEnum[keyof typeof DatasetTypeEnum];
export type DatasetUpdate = {
  citation?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dsId?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export const EntitiesWithImagesEnum = {
  Collection: 'COLLECTION',
  Organization: 'ORGANIZATION',
  Project: 'PROJECT',
  ProjectType: 'PROJECT_TYPE',
  User: 'USER'
} as const;

export type EntitiesWithImagesEnum = typeof EntitiesWithImagesEnum[keyof typeof EntitiesWithImagesEnum];
export const EntityDeleteActionsEnum = {
  Delete: 'DELETE',
  DeleteComplete: 'DELETE_COMPLETE',
  MakePublic: 'MAKE_PUBLIC',
  RequestTransfer: 'REQUEST_TRANSFER'
} as const;

export type EntityDeleteActionsEnum = typeof EntityDeleteActionsEnum[keyof typeof EntityDeleteActionsEnum];
export type EntityDeletionOptions = {
  /**
   * TotalDelete means This item has been cleaned up completely including
   * shared dataset: this will break links for shared projects so it should
   * be the exception and not the rule.
   */
  totalDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional: request transfer of all projects and collections to another Owner in the system */
  transfer?: InputMaybe<TransferEntityItemsInput>;
};

export type FileDownloadMeta = FileDownloadMetaInterface & {
  __typename?: 'FileDownloadMeta';
  contentType?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  localPath?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
};

export type FileDownloadMetaInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  localPath: Scalars['String']['input'];
  md5: Scalars['String']['input'];
  size?: InputMaybe<Scalars['BigInt']['input']>;
};

export type FileDownloadMetaInterface = {
  contentType?: Maybe<Scalars['String']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  localPath?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
};

export type FileUploadMeta = {
  __typename?: 'FileUploadMeta';
  downloadUrl: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type HasOwner = {
  ownedBy: Owner;
};

export type HasStar = {
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
};

export type HasTags = {
  tags: Array<Scalars['String']['output']>;
};

/** Small medium and large image sizes */
export type HeroImage = {
  __typename?: 'HeroImage';
  lg?: Maybe<Scalars['URL']['output']>;
  md?: Maybe<Scalars['URL']['output']>;
  sm?: Maybe<Scalars['URL']['output']>;
};

export const ImageTypeEnum = {
  Avatar: 'AVATAR',
  Hero: 'HERO',
  Logo: 'LOGO'
} as const;

export type ImageTypeEnum = typeof ImageTypeEnum[keyof typeof ImageTypeEnum];
export const JobStatusEnum = {
  Failed: 'FAILED',
  Processing: 'PROCESSING',
  Ready: 'READY',
  Success: 'SUCCESS',
  Unknown: 'UNKNOWN'
} as const;

export type JobStatusEnum = typeof JobStatusEnum[keyof typeof JobStatusEnum];
/** JobStatusObj: The shape of the JSON file that stores the state of the uploaded item. */
export type JobStatusObj = {
  __typename?: 'JobStatusObj';
  /**
   * Jobs may store different settings. leave a data param as JSONObject so we can extend it
   * without needing to modify the schema
   */
  data?: Maybe<Scalars['JSONObject']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  metaData?: Maybe<Scalars['JSONObject']['output']>;
  percentComplete?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  status: JobStatusEnum;
};

export type Link = {
  __typename?: 'Link';
  /** alt text */
  alt?: Maybe<Scalars['String']['output']>;
  /** Link to the object */
  href: Scalars['URL']['output'];
  /** Link text */
  text?: Maybe<Scalars['String']['output']>;
};

export type LinkInput = {
  /** alt text */
  alt?: InputMaybe<Scalars['String']['input']>;
  /** Link to the object */
  href: Scalars['URL']['input'];
  /** Link text */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type MapCluster = {
  __typename?: 'MapCluster';
  /** [longitude, latitude] */
  coords: Array<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  /** check out npm's 'ngeogrid' for how to turn this back into coordinates  */
  hash: Scalars['String']['output'];
};

export type MetaData = {
  __typename?: 'MetaData';
  ext?: Maybe<MetaDataExtEnum>;
  key: Scalars['String']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<MetaDataTypeEnum>;
  value: Scalars['String']['output'];
};

export const MetaDataExtEnum = {
  Dataset: 'DATASET',
  Project: 'PROJECT',
  Warehouse: 'WAREHOUSE'
} as const;

export type MetaDataExtEnum = typeof MetaDataExtEnum[keyof typeof MetaDataExtEnum];
/** When submitting metadata use this object */
export type MetaDataInput = {
  /**
   * If this metadata was impored from an external project then list what kind of MetaData it is.
   *   Options: PROJECT, DATASET, WAREHOUSE
   */
  ext?: InputMaybe<MetaDataExtEnum>;
  /** Metadata key string */
  key: Scalars['String']['input'];
  /** Optional locked flag. Defaults to false. If true then this metadata value cannot be edited by the UI */
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional meta type value. Defaults to String */
  type?: InputMaybe<MetaDataTypeEnum>;
  /** Strignified metadata value */
  value: Scalars['String']['input'];
};

/**
 * This mirrors the RiverscapesXML XSD Schema
 * Unfortunately we need to keep them in sync
 * find it here: RiverscapesXML/Projects/XSD/V2/RiverscapesProject.xsd
 */
export const MetaDataTypeEnum = {
  Boolean: 'BOOLEAN',
  Filepath: 'FILEPATH',
  Float: 'FLOAT',
  Guid: 'GUID',
  Hidden: 'HIDDEN',
  Image: 'IMAGE',
  Int: 'INT',
  Isodate: 'ISODATE',
  Json: 'JSON',
  Markdown: 'MARKDOWN',
  Richtext: 'RICHTEXT',
  String: 'STRING',
  Timestamp: 'TIMESTAMP',
  Url: 'URL',
  Video: 'VIDEO'
} as const;

export type MetaDataTypeEnum = typeof MetaDataTypeEnum[keyof typeof MetaDataTypeEnum];
export type Mutation = {
  __typename?: 'Mutation';
  /** Accept or reject an org invite. State afterward will be ACCEPTED or REJECTED */
  actionOrganizationInvite?: Maybe<OrganizationInvite>;
  addCollectionProjects?: Maybe<Collection>;
  /** ADMIN: Scan over the dynamoDB and create appropriate OpenSearch entries */
  adminReIndexOpenSearch?: Maybe<Scalars['String']['output']>;
  /** ADMIN: Recreate/empty opensearch indeces (you will need to run adminRebuildOpenSearch after this) */
  adminRecreateOpenSearch?: Maybe<Scalars['Boolean']['output']>;
  /** ADMIN: Wipe DynamoDB except for users */
  adminWipeDynamo?: Maybe<Scalars['Int']['output']>;
  changeProjectOwner?: Maybe<Project>;
  /** Create a collection of projects */
  createCollection?: Maybe<Collection>;
  createNotification?: Maybe<Notification>;
  createOrganization?: Maybe<Organization>;
  /**
   * Organization admins/owners can invite others. the invite will have the state: INVITED
   * One of userId or email must be provided.
   */
  createOrganizationInvite?: Maybe<OrganizationInvite>;
  /** For user when logging in for the first time */
  createProfile?: Maybe<Profile>;
  /**
   * Create a new project. This is the main mutation for creating a new project
   * @param projectId: (optional) The ID of the project. If not given then a new one is generated. If it exists in the system then an error is thrown.
   * @param project: The project object
   * @param projectTypeId: The ID of the project type
   * @param orgId: The ID of the organization. This will become the project owner. This field is mutually exclusive with userId
   * @param userId: The ID of the user. This will become the project owner. This field is mutually exclusive with orgId
   * @param agentId: The ID of the agent. This will become the createdBy field in the project
   */
  createProject?: Maybe<Project>;
  /**
   * For most users "createProject" is actually "sugest a project type" and will be created
   * with state "SUGGESTED". Only admins can create a ProjectType that starts with state "ACTIVE"
   */
  createProjectType?: Maybe<ProjectType>;
  createSavedSearch?: Maybe<SavedSearch>;
  createTransfer?: Maybe<Transfer>;
  deleteCollection?: Maybe<MutationResult>;
  /**
   * This is the equivalent of dismissing notifications. It is permanent
   *   - if ids is specified then specific notifications will be deleted
   *   - if all: True then all notifications for this user will be deleted
   */
  deleteNotifications?: Maybe<MutationResult>;
  deleteOrganization?: Maybe<MutationResult>;
  /** Organization admins/owners can dis-invite others. */
  deleteOrganizationInvite?: Maybe<MutationResult>;
  /** Delete your user profile from the system */
  deleteProfile?: Maybe<MutationResult>;
  /**
   * Delete a project. This will mark a project for deletion so it stops showing in search results. The actual deletion
   * will happen in the background and only complete once the purgeProject() call has been fired.
   */
  deleteProject?: Maybe<MutationResult>;
  /**
   * Delete is a super-rare, super high-privilege command that will only work for admins
   * because it means deleting a project type and all projects with that type
   */
  deleteProjectType?: Maybe<MutationResult>;
  deleteSavedSearch?: Maybe<MutationResult>;
  deleteTransfer?: Maybe<MutationResult>;
  /** When all files have been uploaded you can call this to start processing the project */
  finalizeProjectUpload?: Maybe<JobStatusObj>;
  /** Purge a project. This will delete a project from the system. This is a machine-only call used by the janitor */
  purgeProject?: Maybe<MutationResult>;
  reIndexProjectXML?: Maybe<WatcherOutput>;
  rebuildWebTiles: RebuildWebTilesResponse;
  removeCollectionProjects?: Maybe<Collection>;
  /** Kick a user (or yourself) from the org (Owners cannot be removed until they are downgraded) */
  removeOrganizationMember?: Maybe<MutationResult>;
  /** Users can request access to an organization with initial role of VIEWER. the invite will have the state: REQUESTED */
  requestOrganizationInvite?: Maybe<OrganizationInvite>;
  /** Organization admins/owners can resend expired invitiations. */
  resendOrganizationInvite?: Maybe<OrganizationInvite>;
  /** updateCollection: Note that with projectIds, order matters */
  updateCollection?: Maybe<Collection>;
  /** ids: Notification ids (large interger timestamp) */
  updateNotifications?: Maybe<MutationResult>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationMember?: Maybe<OrganizationUser>;
  /** Updating your user proile */
  updateProfile?: Maybe<Profile>;
  /**
   * Update a project. This is the main mutation for updating a project
   * @param projectId: The ID of the project
   * @param project: The project object
   * @param skipXMLUpdate: If true then the XML file is not updated. This is used by the uploader
   * @param agentId: The ID of the agent. This will become the updatedBy field in the project
   */
  updateProject?: Maybe<Project>;
  updateProjectDataset?: Maybe<Dataset>;
  updateProjectDatasetLayer?: Maybe<DatasetLayer>;
  updateProjectQAQC?: Maybe<QaqcEvent>;
  /**
   * This call allows you to alter the project type object (including the ID)
   * If the ID is changed then we'll need to go through all projects of that type
   * and rename them. This should be a super rare operation but it's here for completeness
   */
  updateProjectType?: Maybe<ProjectType>;
  updateSavedSearch?: Maybe<SavedSearch>;
  updateStar?: Maybe<MutationResult>;
  /** Transfers are fairly immutable. You can only change the note and the state */
  updateTransfer?: Maybe<Transfer>;
  zipRebuild?: Maybe<Project>;
};


export type MutationActionOrganizationInviteArgs = {
  accept: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  role?: InputMaybe<OrganizationInviteRoleEnum>;
};


export type MutationAddCollectionProjectsArgs = {
  collectionId: Scalars['ID']['input'];
  projectIds: Array<Scalars['ID']['input']>;
};


export type MutationAdminReIndexOpenSearchArgs = {
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChangeProjectOwnerArgs = {
  owner: OwnerInput;
  projectId: Scalars['ID']['input'];
};


export type MutationCreateCollectionArgs = {
  collection: CollectionInput;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateNotificationArgs = {
  notification?: InputMaybe<NotificationInput>;
};


export type MutationCreateOrganizationArgs = {
  organization: OrganizationInput;
};


export type MutationCreateOrganizationInviteArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  role?: InputMaybe<OrganizationInviteRoleEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  profile: ProfileInput;
};


export type MutationCreateProjectArgs = {
  agentId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  project: ProjectInput;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectTypeId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateProjectTypeArgs = {
  id: Scalars['String']['input'];
  projectType: ProjectTypeInput;
  state?: InputMaybe<ProjectTypeStateEnum>;
};


export type MutationCreateSavedSearchArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
  savedSearch: SavedSearchInput;
};


export type MutationCreateTransferArgs = {
  transfer: TransferInput;
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNotificationsArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID']['input'];
  options?: InputMaybe<EntityDeletionOptions>;
};


export type MutationDeleteOrganizationInviteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProfileArgs = {
  options?: InputMaybe<EntityDeletionOptions>;
};


export type MutationDeleteProjectArgs = {
  options?: InputMaybe<EntityDeletionOptions>;
  projectId: Scalars['ID']['input'];
};


export type MutationDeleteProjectTypeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSavedSearchArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTransferArgs = {
  transferId: Scalars['ID']['input'];
};


export type MutationFinalizeProjectUploadArgs = {
  token: Scalars['String']['input'];
};


export type MutationPurgeProjectArgs = {
  projectId: Scalars['ID']['input'];
};


export type MutationReIndexProjectXmlArgs = {
  projectId: Scalars['ID']['input'];
};


export type MutationRebuildWebTilesArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  rsXPaths?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationRemoveCollectionProjectsArgs = {
  collectionId: Scalars['ID']['input'];
  projectIds: Array<Scalars['ID']['input']>;
};


export type MutationRemoveOrganizationMemberArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRequestOrganizationInviteArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationResendOrganizationInviteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCollectionArgs = {
  collection?: InputMaybe<CollectionInput>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateNotificationsArgs = {
  ids: Array<Scalars['ID']['input']>;
  markAs: NotificationOperationEnum;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID']['input'];
  organization: OrganizationInput;
};


export type MutationUpdateOrganizationMemberArgs = {
  organizationId: Scalars['ID']['input'];
  role?: InputMaybe<OrganizationRoleEnum>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateProfileArgs = {
  profile: ProfileInput;
};


export type MutationUpdateProjectArgs = {
  agentId?: InputMaybe<Scalars['ID']['input']>;
  project: ProjectInput;
  projectId: Scalars['ID']['input'];
  skipXMLUpdate?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateProjectDatasetArgs = {
  dataset: DatasetUpdate;
  projectId: Scalars['ID']['input'];
  rsXPath: Scalars['String']['input'];
};


export type MutationUpdateProjectDatasetLayerArgs = {
  datasetLayer: DatasetLayerUpdate;
  lyrName: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  rsXPath: Scalars['String']['input'];
};


export type MutationUpdateProjectQaqcArgs = {
  projectId: Scalars['ID']['input'];
  qaqc: QaqcEventInput;
  qaqcId: Scalars['ID']['input'];
};


export type MutationUpdateProjectTypeArgs = {
  id: Scalars['String']['input'];
  projectType: ProjectTypeInput;
  state?: InputMaybe<ProjectTypeStateEnum>;
};


export type MutationUpdateSavedSearchArgs = {
  id: Scalars['ID']['input'];
  savedSearch: SavedSearchInput;
};


export type MutationUpdateStarArgs = {
  id: Scalars['ID']['input'];
  starred: Scalars['Boolean']['input'];
  type: StarrableTypesEnum;
};


export type MutationUpdateTransferArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<TransferStateEnum>;
  transferId: Scalars['ID']['input'];
};


export type MutationZipRebuildArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
};

/** Generic Mutation results */
export type MutationResult = {
  __typename?: 'MutationResult';
  /** The error (if there is one) */
  error?: Maybe<Scalars['String']['output']>;
  /** the ID of the object being mutated */
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  /** Any non-error message returned by the mutation for use in the UI */
  message?: Maybe<Scalars['String']['output']>;
  /** did this mutation succeed? */
  success: Scalars['Boolean']['output'];
};

export type MyStars = {
  __typename?: 'MyStars';
  collections: PaginatedCollections;
  organizations: PaginatedOrganizations;
  projects: PaginatedProjects;
  savedSearches: PaginatedSavedSearches;
  users: PaginatedUsers;
};


export type MyStarsCollectionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type MyStarsOrganizationsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type MyStarsProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type MyStarsSavedSearchesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type MyStarsUsersArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID']['output'];
  object: DbObjNotifications;
  seen: Scalars['Boolean']['output'];
  /** Same as Obj. this is a stripped-down user object */
  subject: DbObjNotifications;
  time: Scalars['DateTime']['output'];
  type: NotificationTypesEnum;
  verb: NotificationActionsEnum;
};

export const NotificationActionsEnum = {
  Created: 'CREATED',
  Deleted: 'DELETED',
  Renamed: 'RENAMED',
  Transferred: 'TRANSFERRED',
  Updated: 'UPDATED'
} as const;

export type NotificationActionsEnum = typeof NotificationActionsEnum[keyof typeof NotificationActionsEnum];
export type NotificationInput = {
  object: DbObjNotificationsInput;
  subject: DbObjNotificationsInput;
  type: NotificationTypesEnum;
  verb: NotificationActionsEnum;
};

export const NotificationOperationEnum = {
  Delete: 'DELETE',
  MarkRead: 'MARK_READ',
  MarkUnread: 'MARK_UNREAD'
} as const;

export type NotificationOperationEnum = typeof NotificationOperationEnum[keyof typeof NotificationOperationEnum];
export const NotificationTypesEnum = {
  Collection: 'COLLECTION',
  Organization: 'ORGANIZATION',
  Project: 'PROJECT',
  SavedSearch: 'SAVED_SEARCH',
  User: 'USER'
} as const;

export type NotificationTypesEnum = typeof NotificationTypesEnum[keyof typeof NotificationTypesEnum];
/** On a per-object basis, tell me what I can and can't do */
export type ObjectPermissions = {
  __typename?: 'ObjectPermissions';
  delete?: Maybe<Scalars['Boolean']['output']>;
  update?: Maybe<Scalars['Boolean']['output']>;
  view?: Maybe<Scalars['Boolean']['output']>;
};

export type Organization = DbObj & HasStar & {
  __typename?: 'Organization';
  collections: PaginatedCollections;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['URL']['output']>;
  meta: Array<MetaData>;
  /** This is the role of the user making the query (or the role of the requested user) */
  myRole: OrganizationRoleEnum;
  name: Scalars['String']['output'];
  /** List of invites to manage */
  organizationInvites: PaginatedOrganizationInvites;
  /** List of this organization's approved user (Does not include pending invites) */
  organizationUsers: PaginatedOrganizationUsers;
  /** This is my relation to this project. This is relatively easy to calculate if I own this object. */
  permissions: ObjectPermissions;
  preferences?: Maybe<Scalars['JSONObject']['output']>;
  projects: PaginatedProjects;
  savedSearches: PaginatedSavedSearches;
  social?: Maybe<SocialLinks>;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};


export type OrganizationCollectionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type OrganizationOrganizationInvitesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type OrganizationOrganizationUsersArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  role?: InputMaybe<OrganizationRoleEnum>;
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type OrganizationProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type OrganizationSavedSearchesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type OrganizationInput = {
  /** Resets/deletes the organization logo back to the default */
  clearLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Long text (may include markdown) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The Code you were given from the checkUploadImage endpoint */
  logoToken?: InputMaybe<Scalars['String']['input']>;
  /** Organization Metadata */
  meta?: InputMaybe<Array<MetaDataInput>>;
  /** Name of the organization */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Organization Preferences Object */
  preferences?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Social Links */
  social?: InputMaybe<SocialLinksInput>;
  /** Summary or tagline */
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationInvite = DbSimpleObj & {
  __typename?: 'OrganizationInvite';
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The user being invited. Null if they don't have an account in the system yet */
  invitee?: Maybe<User>;
  inviter: User;
  organization: Organization;
  retries: Scalars['Int']['output'];
  role: OrganizationInviteRoleEnum;
  state: OrganizationInviteStateEnum;
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};

export const OrganizationInviteRoleEnum = {
  Admin: 'ADMIN',
  Contributor: 'CONTRIBUTOR',
  Viewer: 'VIEWER'
} as const;

export type OrganizationInviteRoleEnum = typeof OrganizationInviteRoleEnum[keyof typeof OrganizationInviteRoleEnum];
export const OrganizationInviteStateEnum = {
  Accepted: 'ACCEPTED',
  Expired: 'EXPIRED',
  Invited: 'INVITED',
  Rejected: 'REJECTED',
  Requested: 'REQUESTED'
} as const;

export type OrganizationInviteStateEnum = typeof OrganizationInviteStateEnum[keyof typeof OrganizationInviteStateEnum];
export const OrganizationRoleEnum = {
  Admin: 'ADMIN',
  Contributor: 'CONTRIBUTOR',
  None: 'NONE',
  Owner: 'OWNER',
  Viewer: 'VIEWER'
} as const;

export type OrganizationRoleEnum = typeof OrganizationRoleEnum[keyof typeof OrganizationRoleEnum];
export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  organization: Organization;
  role: OrganizationRoleEnum;
  user: User;
};

export type Owner = Organization | User;

export type OwnerInput = {
  id: Scalars['String']['input'];
  type: OwnerInputTypesEnum;
};

export const OwnerInputTypesEnum = {
  Organization: 'ORGANIZATION',
  User: 'USER'
} as const;

export type OwnerInputTypesEnum = typeof OwnerInputTypesEnum[keyof typeof OwnerInputTypesEnum];
export type Owners = PaginatedUsers;

export type PaginatedCollections = Pagination & {
  __typename?: 'PaginatedCollections';
  items: Array<Collection>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDatasets = Pagination & {
  __typename?: 'PaginatedDatasets';
  items: Array<Dataset>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedFileDownloadMeta = Pagination & {
  __typename?: 'PaginatedFileDownloadMeta';
  items: Array<FileDownloadMeta>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedNotifications = Pagination & {
  __typename?: 'PaginatedNotifications';
  items: Array<Notification>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedOrganizationInvites = Pagination & {
  __typename?: 'PaginatedOrganizationInvites';
  items: Array<OrganizationInvite>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedOrganizationUsers = Pagination & {
  __typename?: 'PaginatedOrganizationUsers';
  items: Array<OrganizationUser>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedOrganizations = Pagination & {
  __typename?: 'PaginatedOrganizations';
  items: Array<Organization>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedProjectTypes = Pagination & {
  __typename?: 'PaginatedProjectTypes';
  items: Array<ProjectType>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedProjects = Pagination & {
  __typename?: 'PaginatedProjects';
  items: Array<Project>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedQaqcEvents = Pagination & {
  __typename?: 'PaginatedQAQCEvents';
  items: Array<QaqcEvent>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedSavedSearches = Pagination & {
  __typename?: 'PaginatedSavedSearches';
  items: Array<SavedSearch>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedUsers = Pagination & {
  __typename?: 'PaginatedUsers';
  items: Array<User>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Generic Pagination Interface */
export type Pagination = {
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** A Profile is MY User object and there are extra fields I can look at */
export type Profile = DbObj & UserInterface & {
  __typename?: 'Profile';
  affiliations: Array<Maybe<UserAffiliation>>;
  avatar?: Maybe<Scalars['URL']['output']>;
  /** Collections this user owns. Filtered if the querying user is someone else */
  collections: PaginatedCollections;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** If the user has never logged in here this is uninitialized */
  initialized?: Maybe<Scalars['Boolean']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  lastLogin: Scalars['DateTime']['output'];
  location: Scalars['String']['output'];
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  notifications: PaginatedNotifications;
  organizationInvites: PaginatedOrganizationInvites;
  /** Organizations this user is a member of. */
  organizations: PaginatedOrganizations;
  preferences: Scalars['JSONObject']['output'];
  /** Projects this user owns. Filtered if the querying user is someone else */
  projects: PaginatedProjects;
  /** Saved Searches this user owns. Filtered if the querying user is someone else */
  savedSearches: PaginatedSavedSearches;
  social: SocialLinks;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  stars: MyStars;
  summary: Scalars['String']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};


/** A Profile is MY User object and there are extra fields I can look at */
export type ProfileCollectionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A Profile is MY User object and there are extra fields I can look at */
export type ProfileNotificationsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  op?: InputMaybe<NotificationOperationEnum>;
};


/** A Profile is MY User object and there are extra fields I can look at */
export type ProfileOrganizationInvitesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A Profile is MY User object and there are extra fields I can look at */
export type ProfileOrganizationsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A Profile is MY User object and there are extra fields I can look at */
export type ProfileProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A Profile is MY User object and there are extra fields I can look at */
export type ProfileSavedSearchesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type ProfileInput = {
  /** Any organizational affiliation(s) the user specifies */
  affiliations?: InputMaybe<Array<UserAffiliationInput>>;
  /** The Code you were given from the checkUploadImage endpoint */
  avatarToken?: InputMaybe<Scalars['String']['input']>;
  /** Resets/deletes the user's avatar back to the default */
  clearAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  /** Long text (may include markdown) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Simple job title string field */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Simple location string (Optional) eg: "Vancouver, Canada" */
  location?: InputMaybe<Scalars['String']['input']>;
  /** Organization Metadata */
  meta?: InputMaybe<Array<MetaDataInput>>;
  /** Name of the organization */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Organization Preferences Object */
  preferences?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Specify a set of connection strings for Social media */
  socialLinks?: InputMaybe<SocialLinksInput>;
  /** Summary or tagline */
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type Project = DbObj & HasOwner & HasStar & {
  __typename?: 'Project';
  /** Banner image (optional) */
  bounds?: Maybe<ProjectBounds>;
  citation?: Maybe<Scalars['String']['output']>;
  collections: PaginatedCollections;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  datasets: PaginatedDatasets;
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  dirty: Scalars['Boolean']['output'];
  files: Array<FileDownloadMeta>;
  /** Banner image (optional) */
  heroImage?: Maybe<HeroImage>;
  id: Scalars['ID']['output'];
  /**
   * This meta derives from the XML. When we change it we need to write back tot he XML
   * The user should be warned that if the XML changes it will overwrite these values
   */
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  ownedBy: Owner;
  /** This is my relation to this project. This is relatively easy to calculate if I own this object. */
  permissions: ObjectPermissions;
  projectType: ProjectType;
  qaqc: PaginatedQaqcEvents;
  ref: Scalars['String']['output'];
  sponsor?: Maybe<Owner>;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  /** Bytesize of the unzipped project */
  totalSize?: Maybe<Scalars['BigInt']['output']>;
  tree: ProjectTree;
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
  visibility: ProjectVisibilityEnum;
};


export type ProjectCollectionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type ProjectDatasetsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type ProjectQaqcArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type ProjectBounds = DbSimpleObj & {
  __typename?: 'ProjectBounds';
  /** Calculated area of  */
  area?: Maybe<Scalars['Float']['output']>;
  /** bbox: [minLng, minLat, maxLng, maxLat] */
  bbox: Array<Scalars['Float']['output']>;
  /** centroid: [longitude, latitude] */
  centroid: Array<Scalars['Float']['output']>;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  /**
   * Geohash is how we do clustering in ElasticSearch
   * https://github.com/sunng87/node-geohash
   * https://www.npmjs.com/package/ngeohash
   * https://www.npmjs.com/package/@mapbox/geojson-area
   * https://www.npmjs.com/package/@mapbox/geojson-extent
   * https://www.npmjs.com/package/@mapbox/geojson-normalize
   * https://github.com/hackergrrl/geojson-polygons-equal/blob/master/index.js
   * https://github.com/jczaplew/geojson-precision/blob/master/index.js
   * https://www.elastic.co/guide/en/elasticsearch/reference/current/search-aggregations-bucket-geohashgrid-aggregation.html
   */
  geoHash?: Maybe<Scalars['String']['output']>;
  /**
   * This id is unique to specific shapes in the DB
   * Useful for grouping multiple projects with the same bounding polygon
   * It is a GUID
   */
  id: Scalars['ID']['output'];
  /** polygon: Simplified GeoJSON of project outputs. */
  polygonUrl: Scalars['URL']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};

export const ProjectDeleteChoicesEnum = {
  Delete: 'DELETE',
  DeleteComplete: 'DELETE_COMPLETE'
} as const;

export type ProjectDeleteChoicesEnum = typeof ProjectDeleteChoicesEnum[keyof typeof ProjectDeleteChoicesEnum];
export const ProjectGroupVisibilityEnum = {
  Public: 'PUBLIC',
  Secret: 'SECRET'
} as const;

export type ProjectGroupVisibilityEnum = typeof ProjectGroupVisibilityEnum[keyof typeof ProjectGroupVisibilityEnum];
export type ProjectInput = {
  /** Project bounds object code you were given from the checkUploadBounds endpoint */
  boundsToken?: InputMaybe<Scalars['String']['input']>;
  /** Scientitfic or accademic citation (string) */
  citation?: InputMaybe<Scalars['String']['input']>;
  /** Resets/deletes the project's bounds */
  clearBounds?: InputMaybe<Scalars['Boolean']['input']>;
  /** Resets/deletes the user's avatar back to the default */
  clearHeroImage?: InputMaybe<Scalars['Boolean']['input']>;
  /** remove the sponsor for the proejct */
  clearSponsor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Attach new datasets or add/remove linked datasets */
  datasets?: InputMaybe<Array<DatasetInput>>;
  /** ONLY FOR UPDATING: Specify dataset ids (and by that I mean rsXPaths) to be deleted */
  deleteDatasets?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Long text (may include markdown) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The Code you were given from the checkUploadImage endpoint */
  heroImageToken?: InputMaybe<Scalars['String']['input']>;
  /** Organization Metadata */
  meta?: InputMaybe<Array<MetaDataInput>>;
  /** Name of the project. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Attache any QAQC tasks in order */
  qaqc?: InputMaybe<Array<QaqcEventInput>>;
  /** Optional: Sponsor ID */
  sponsor?: InputMaybe<OwnerInput>;
  /** Summary or tagline */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Tags for this collection */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Byte size of entire project uncompressed */
  totalSize?: InputMaybe<Scalars['BigInt']['input']>;
  /** Project visibility settings: Either PUBLIC, PRIVATE or SECRET */
  visibility?: InputMaybe<ProjectVisibilityEnum>;
};

export type ProjectSearchMeta = {
  __typename?: 'ProjectSearchMeta';
  /**
   * The area covered by all projects returned
   * (all overlaps are included)
   */
  area?: Maybe<Scalars['Float']['output']>;
  /**
   * bbox: [minLng, minLat, maxLng, maxLat]
   * The geo bounds of all possible results
   * (Applies to projects only)
   */
  bbox?: Maybe<Array<Scalars['Float']['output']>>;
  /**
   * Histogram of distances
   * (Applies to projects only)
   */
  geoBuckets?: Maybe<Scalars['JSONObject']['output']>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  metaDataBuckets: Scalars['JSONObject']['output'];
  /**
   * ProjectTypes: Key-balue pairs of project types and counts
   * (Applies to projects only)
   */
  projectTypes?: Maybe<Scalars['JSONObject']['output']>;
  /** OpenSearch time to retrieve results (ms) */
  searchTime?: Maybe<Scalars['Float']['output']>;
};

/**
 * For searching projects we use this for both the searchProjects query AND
 * the SavedSearchInput type
 */
export type ProjectSearchParams = {
  __typename?: 'ProjectSearchParams';
  /** bbox: [minLng, minLat, maxLng, maxLat] */
  bbox?: Maybe<Array<Scalars['Float']['output']>>;
  /** Filter to projects inside a collection */
  collection?: Maybe<Scalars['ID']['output']>;
  /**
   * Filter to projects between two dates {from, to}
   * You can specify one of 'from' OR 'to' to find projects before 'to' date or after 'from' date
   * or you can specify both 'from' AND 'to' to specify projects BETWEEN two dates
   */
  createdOn?: Maybe<SearchDate>;
  /** Only projects that I can edit as a user  */
  editableOnly?: Maybe<Scalars['Boolean']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Array<MetaData>>;
  name?: Maybe<Scalars['String']['output']>;
  ownedBy?: Maybe<SearchOwner>;
  /** The id (machineName) of the project type. Case insensitive. */
  projectTypeId?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Filter to projects between two dates {from, to}
   * You can specify one of 'from' OR 'to' to find projects before 'to' date or after 'from' date
   * or you can specify both 'from' AND 'to' to specify projects BETWEEN two dates
   */
  updatedOn?: Maybe<SearchDate>;
};

/**
 * For searching projects we use this for both the searchProjects query AND
 * the SavedSearchInput type
 */
export type ProjectSearchParamsInput = {
  /** bbox: [minLng, minLat, maxLng, maxLat] */
  bbox?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Filter to projects that have this particular Bound polygon assoicated with them by id */
  boundsId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter to projects inside a collection */
  collection?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Filter to projects between two dates {from, to}
   * You can specify one of 'from' OR 'to' to find projects before 'to' date or after 'from' date
   * or you can specify both 'from' AND 'to' to specify projects BETWEEN two dates
   */
  createdOn?: InputMaybe<SearchDateInput>;
  /** Only projects that I can edit as a user  */
  editableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Filter to projects owned by a user or organization */
  ownedBy?: InputMaybe<OwnerInput>;
  /** The id (machineName) of the project type. Case insensitive. */
  projectTypeId?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter to projects between two dates {from, to}
   * You can specify one of 'from' OR 'to' to find projects before 'to' date or after 'from' date
   * or you can specify both 'from' AND 'to' to specify projects BETWEEN two dates
   */
  updatedOn?: InputMaybe<SearchDateInput>;
};

export type ProjectTree = {
  __typename?: 'ProjectTree';
  branches: Array<ProjectTreeBranch>;
  defaultView?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  leaves: Array<ProjectTreeLeaf>;
  name?: Maybe<Scalars['String']['output']>;
  views: Array<ProjectTreeView>;
};

export type ProjectTreeBranch = {
  __typename?: 'ProjectTreeBranch';
  /** Branch ID (generated at parse time) */
  bid: Scalars['Int']['output'];
  collapsed?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  /** Parent ID (generated at parse time) */
  pid: Scalars['Int']['output'];
};

/**
 * ProjectTreeLayerTypes is an enumeration to help us display layers with the
 * correct icon and assign them to the right slot
 */
export const ProjectTreeLayerTypeEnum = {
  File: 'FILE',
  Line: 'LINE',
  Point: 'POINT',
  Polygon: 'POLYGON',
  Raster: 'RASTER',
  Report: 'REPORT',
  Tin: 'TIN'
} as const;

export type ProjectTreeLayerTypeEnum = typeof ProjectTreeLayerTypeEnum[keyof typeof ProjectTreeLayerTypeEnum];
export type ProjectTreeLeaf = {
  __typename?: 'ProjectTreeLeaf';
  /**
   * blLayerId: The id on the Node element from the business logic that we use in Views to
   * reference leaves in the tree
   */
  blLayerId?: Maybe<Scalars['String']['output']>;
  /** This is going to let us find the actual file we need to download (if applicable) */
  filePath?: Maybe<Scalars['String']['output']>;
  /** Tree id of this leaf. This is generated at parse-time */
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  /** from the BL (Optional) */
  labelxpath?: Maybe<Scalars['String']['output']>;
  /** from BL. Renderable WebRave Types (Not DS Types) */
  layerType: ProjectTreeLayerTypeEnum;
  /** This is going to let us find the DasetLayer (if applicaple) */
  lyrName?: Maybe<Scalars['String']['output']>;
  /** Business Logic id="" attribute */
  nodeId?: Maybe<Scalars['ID']['output']>;
  /**
   * parent ID (relates to bid from ProjectTreeBranch)
   * generated at parse-time
   */
  pid: Scalars['Int']['output'];
  /**
   * This is going to let us find the Dataset
   * corresponding to the leaf we're trying to render
   */
  rsXPath: Scalars['String']['output'];
  symbology?: Maybe<Scalars['String']['output']>;
  transparency?: Maybe<Scalars['Int']['output']>;
};

export type ProjectTreeView = {
  __typename?: 'ProjectTreeView';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layers: Array<ProjectTreeViewLayer>;
  name: Scalars['String']['output'];
};

export type ProjectTreeViewLayer = {
  __typename?: 'ProjectTreeViewLayer';
  id: Scalars['ID']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectType = DbObj & {
  __typename?: 'ProjectType';
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['URL']['output']>;
  machineName: Scalars['String']['output'];
  /**
   * This meta derives from the XML. When we change it we need to write back tot he XML
   * The user should be warned that if the XML changes it will overwrite these values
   */
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  state: ProjectTypeStateEnum;
  summary: Scalars['String']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['URL']['output']>;
};

export type ProjectTypeInput = {
  /** Resets/deletes the organization logo back to the default */
  clearLogo?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** The Code you were given from the checkUploadImage endpoint */
  logoToken?: InputMaybe<Scalars['String']['input']>;
  /**
   * This meta derives from the XML. When we change it we need to write back tot he XML
   * The user should be warned that if the XML changes it will overwrite these values
   */
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export const ProjectTypeStateEnum = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  Suggested: 'SUGGESTED'
} as const;

export type ProjectTypeStateEnum = typeof ProjectTypeStateEnum[keyof typeof ProjectTypeStateEnum];
export type ProjectUploadUrl = {
  __typename?: 'ProjectUploadUrl';
  fields?: Maybe<Scalars['JSONObject']['output']>;
  origPath?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ProjectValidation = {
  __typename?: 'ProjectValidation';
  errors?: Maybe<Array<Maybe<ProjectValidationError>>>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectValidationError = {
  __typename?: 'ProjectValidationError';
  code?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  severity?: Maybe<SeverityEnum>;
};

export const ProjectVisibilityEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
  Secret: 'SECRET'
} as const;

export type ProjectVisibilityEnum = typeof ProjectVisibilityEnum[keyof typeof ProjectVisibilityEnum];
export type QaqcEvent = DbObj & {
  __typename?: 'QAQCEvent';
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  /**
   * The date this task was performed. This will help us sort out a timeline
   * for the QA/QC workflow
   */
  datePerformed: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** QA/QC Nodes may have extra metadata but it is not mandatory */
  meta: Array<MetaData>;
  /** The QAQC Task Name */
  name: Scalars['String']['output'];
  /**
   * performed by: Some string indicating who is responsible for this task
   * does NOT link to Riverscapes User accounts
   */
  performedBy: Scalars['String']['output'];
  /** The state of this QAQC Task  */
  state: QaqcStateEnum;
  summary: Scalars['String']['output'];
  /** Any supporting links to Github issues or anything else */
  supportingLinks?: Maybe<Array<Link>>;
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};

export type QaqcEventInput = {
  datePerformed: Scalars['DateTime']['input'];
  /** Long text (may include markdown) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Organization Metadata */
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * performed by: Some string indicating who is responsible for this task
   * does NOT link to Riverscapes User accounts
   */
  performedBy: Scalars['String']['input'];
  /** The state of this QA/QC Task */
  state: QaqcStateEnum;
  /** Summary or tagline */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Any supporting links to Github issues or anything else */
  supportingLinks?: InputMaybe<Array<LinkInput>>;
};

export const QaqcStateEnum = {
  Failed: 'FAILED',
  Passed: 'PASSED',
  Provisional: 'PROVISIONAL'
} as const;

export type QaqcStateEnum = typeof QaqcStateEnum[keyof typeof QaqcStateEnum];
export type Query = {
  __typename?: 'Query';
  /** Works for images, bounds and projects. Checks if they have been processed and are ready for use */
  checkUpload?: Maybe<JobStatusObj>;
  collection?: Maybe<Collection>;
  dirtyProjects?: Maybe<PaginatedProjects>;
  downloadFile?: Maybe<FileDownloadMeta>;
  downloadZip?: Maybe<ZipFileDownloadMeta>;
  getLayerTiles?: Maybe<TileService>;
  /** getWebSymbology for a given project type. These types will be retrived from the curated WebRave Symbology repo */
  getWebSymbology?: Maybe<Symbology>;
  /** There are some useful properties that are useful for the CLI and other apps */
  info?: Maybe<WarehouseInfo>;
  organization?: Maybe<Organization>;
  /** Profile includes the User object but also specific fields to the person logged in */
  profile?: Maybe<Profile>;
  project?: Maybe<Project>;
  projectType?: Maybe<ProjectType>;
  /** projectTypes list. Default state is ACTIVE */
  projectTypes?: Maybe<PaginatedProjectTypes>;
  requestUploadBounds?: Maybe<UploadUrl>;
  requestUploadImage?: Maybe<UploadUrl>;
  /**
   * Request an upload start. Writes a manifest file on the upload bucket
   * and creates upload urls for all the files we want to add.
   * This is also wher ethe referenved/unreferenced sorting happens
   *
   * Notes:
   *
   *   - If projectId is given then this projet is uploaded as an UPDATE operation
   *   - If token is given then we just return a previously calculated download object
   *   - If neither token nor projectId is given then this is processed as a new project
   *
   * Arguments:
   *
   *   - owner: (optional) The intended owner of the new project. If left blank then UPDATE is assumed and the original owner is used.
   *   - files: a string list of filepaths to local files
   *   - etags: a string list of etags (S3 analog to MD5) for each file. List size must match "files: [String!]!"
   *   - sizes: a string list of file sizes (S3 analog to MD5) for each file. List size must match "files: [String!]!"
   *   - noDelete: indicates that we do NOT want to touch remote files that are not also present on the local server
   *   - tags: a list of tags to add to the project (these are the data exchange tags)
   *   - visibility: the visibility of the project. Defaults to PUBLIC
   */
  requestUploadProject?: Maybe<UploadProjectRequest>;
  /**
   * Using requestUploadProject() you can get a valid upload token. From there you can ask for presigned urls for any file you
   * have specified above
   */
  requestUploadProjectFilesUrl?: Maybe<Array<Maybe<UploadProjectFileUrls>>>;
  savedSearch?: Maybe<SavedSearch>;
  searchCollections: SearchCollectionPagination;
  /**
   * This is a complementary search to "searchProjects". It can be run with essentially the same
   * parameters
   *
   * Note: params.bbox MUST be included for the searchProjectsMap
   * clusters: the number of clusters we should aim for
   */
  searchMapBounds: SearchMapBoundsResult;
  /**
   * Discussion here https://github.com/Riverscapes/rs-web-monorepo/discussions/251
   * limit: the maximum number of clusters to get back. After this number the list is truncated and any remaining projects are simply represented by the "remaining" property
   * precision: The size of the grid to place the clusters on. We use npm's ngeohash for gridding
   */
  searchMapClusters: SearchMapClusterResult;
  searchOrganizations: SearchOrganizationPagination;
  searchProjects: SearchProjectPagination;
  searchSavedSearches: SearchSavedSearchPagination;
  /**
   * As-you-type suggestions:
   * TODO: this needs to be typed but for now it's just pure JSON
   */
  searchSuggest?: Maybe<SearchSuggestionResults>;
  searchUsers: SearchUserPagination;
  /** A User is any user in the system */
  user?: Maybe<User>;
  /**
   * Validate a raw project.rs.xml file to check if it is a valida candidate for uploading
   * It is highly recommended that you call this before requestUploadProjectFileUrls or uploading
   * files to prevent a lengthy process with a preventable error at the end
   *
   * - xml: String contents of the project.rs.xml file
   * - owner: (optional) The intended owner of the new project. If left blank then UPDATE is assumed and the original owner is used.
   * - files: a string list of filepaths to local files
   */
  validateProject?: Maybe<ProjectValidation>;
};


export type QueryCheckUploadArgs = {
  token: Scalars['String']['input'];
};


export type QueryCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDirtyProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryDownloadFileArgs = {
  filePath: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryDownloadZipArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryGetLayerTilesArgs = {
  projectId: Scalars['ID']['input'];
  projectTypeId?: InputMaybe<Scalars['String']['input']>;
  rsXPath: Scalars['String']['input'];
};


export type QueryGetWebSymbologyArgs = {
  isRaster?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  projectTypeId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryProjectTypesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  state?: InputMaybe<ProjectTypeStateEnum>;
};


export type QueryRequestUploadImageArgs = {
  entityId: Scalars['ID']['input'];
  entityType: EntitiesWithImagesEnum;
};


export type QueryRequestUploadProjectArgs = {
  etags: Array<Scalars['String']['input']>;
  files: Array<Scalars['String']['input']>;
  noDelete?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<OwnerInput>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  sizes: Array<Scalars['BigInt']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<ProjectVisibilityEnum>;
};


export type QueryRequestUploadProjectFilesUrlArgs = {
  files: Array<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type QuerySavedSearchArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchCollectionsArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  params: SearchParamsInput;
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type QuerySearchMapBoundsArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  params: ProjectSearchParamsInput;
};


export type QuerySearchMapClustersArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  params: ProjectSearchParamsInput;
  precision: Scalars['Int']['input'];
};


export type QuerySearchOrganizationsArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  params: SearchParamsInput;
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type QuerySearchProjectsArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  params: ProjectSearchParamsInput;
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type QuerySearchSavedSearchesArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  params: SearchParamsInput;
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type QuerySearchSuggestArgs = {
  minScore?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
  type?: InputMaybe<StarrableTypesEnum>;
};


export type QuerySearchUsersArgs = {
  limit: Scalars['Int']['input'];
  minScore?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  params: SearchParamsInput;
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryValidateProjectArgs = {
  files: Array<Scalars['String']['input']>;
  owner?: InputMaybe<OwnerInput>;
  xml: Scalars['String']['input'];
};

export const RampTypeEnum = {
  Discrete: 'DISCRETE',
  Exact: 'EXACT',
  Interpolated: 'INTERPOLATED'
} as const;

export type RampTypeEnum = typeof RampTypeEnum[keyof typeof RampTypeEnum];
export type RebuildWebTilesResponse = {
  __typename?: 'RebuildWebTilesResponse';
  queued?: Maybe<Array<Scalars['String']['output']>>;
  skipped?: Maybe<Array<Scalars['String']['output']>>;
};

export type SavedSearch = DbObj & HasOwner & HasStar & HasTags & {
  __typename?: 'SavedSearch';
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  /** The default sort to use. The UI may offer a way to change this on the fly */
  defaultSort?: Maybe<Array<SearchSortEnum>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  ownedBy: Owner;
  permissions: ObjectPermissions;
  projects: SearchProjectPagination;
  /** The project search fields this saved search uses  */
  searchParams: ProjectSearchParams;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
  visibility: ProjectGroupVisibilityEnum;
};


export type SavedSearchProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type SavedSearchInput = {
  /** The default sort to use. The UI may offer a way to change this on the fly */
  defaultSort?: InputMaybe<Array<SearchSortEnum>>;
  /** Verbose description of the object (< SOME_LIMIT kb of text) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Metadata for this object */
  meta?: InputMaybe<Array<MetaDataInput>>;
  /** Name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The project search fields this saved search uses  */
  searchParams?: InputMaybe<ProjectSearchParamsInput>;
  /** Brief tagline or intro sentence for the item (<500 characters) */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Tags for this object */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Saved Search visibility settings: Either PUBLIC or SECRET */
  visibility?: InputMaybe<ProjectGroupVisibilityEnum>;
};

export type SearchCollection = SearchResult & {
  __typename?: 'SearchCollection';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: Collection;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchCollectionPagination = Pagination & SearchPagination & {
  __typename?: 'SearchCollectionPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchCollection>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchDataset = SearchResult & {
  __typename?: 'SearchDataset';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: Dataset;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchDatasetPagination = Pagination & SearchPagination & {
  __typename?: 'SearchDatasetPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchDataset>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchDate = {
  __typename?: 'SearchDate';
  /** (Optional) , only use for BETWEEN operator */
  from?: Maybe<Scalars['DateTime']['output']>;
  /** (Optional), only use for BETWEEN operator */
  to?: Maybe<Scalars['DateTime']['output']>;
};

export type SearchDateInput = {
  /** (Optional) , only use for BETWEEN operator */
  from?: InputMaybe<Scalars['DateTime']['input']>;
  /** (Optional), only use for BETWEEN operator */
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SearchMapBoundsItem = {
  __typename?: 'SearchMapBoundsItem';
  bounds: ProjectBounds;
  clusters: Array<MapCluster>;
  projectCount: Scalars['Int']['output'];
};

export type SearchMapBoundsResult = {
  __typename?: 'SearchMapBoundsResult';
  bounds: Array<SearchMapBoundsItem>;
  /** The number of valid search results outstanding */
  remaining: Scalars['Int']['output'];
};

export type SearchMapClusterResult = {
  __typename?: 'SearchMapClusterResult';
  clusters: Array<MapCluster>;
  /** The number of valid search results outstanding */
  remaining: Scalars['Int']['output'];
};

export type SearchMeta = {
  __typename?: 'SearchMeta';
  maxScore?: Maybe<Scalars['Float']['output']>;
  metaDataBuckets: Scalars['JSONObject']['output'];
  /** OpenSearch time to retrieve results (ms) */
  searchTime?: Maybe<Scalars['Float']['output']>;
};

/** Search Meta that are common to all types */
export type SearchMetaInterface = {
  /** The maximum relevance score */
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Collection of stats about the Metadata key value pairs for this object */
  metaDataBuckets: Scalars['JSONObject']['output'];
  /** OpenSearch time to retrieve results (ms) */
  searchTime?: Maybe<Scalars['Float']['output']>;
};

export type SearchNotification = SearchResult & {
  __typename?: 'SearchNotification';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: Notification;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchNotificationPagination = Pagination & SearchPagination & {
  __typename?: 'SearchNotificationPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchNotification>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchOrganization = SearchResult & {
  __typename?: 'SearchOrganization';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: Organization;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchOrganizationInvite = SearchResult & {
  __typename?: 'SearchOrganizationInvite';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: OrganizationInvite;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchOrganizationInvitePagination = Pagination & SearchPagination & {
  __typename?: 'SearchOrganizationInvitePagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchOrganizationInvite>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchOrganizationPagination = Pagination & SearchPagination & {
  __typename?: 'SearchOrganizationPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchOrganization>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchOwner = {
  __typename?: 'SearchOwner';
  id: Scalars['String']['output'];
  type: OwnerInputTypesEnum;
};

export type SearchPagination = {
  /** The limit used for this query. */
  limit: Scalars['Int']['output'];
  /** The record offset for this query. */
  offset: Scalars['Int']['output'];
  /** The maximum score if this is a search result. Null otherwise */
  stats?: Maybe<SearchStats>;
  /** The total objects in the system. OpenSearch can return this easily so it's useful for building pagination UI. */
  total: Scalars['Int']['output'];
};

/** SearchParams is used for search queries for everything except projects */
export type SearchParamsInput = {
  /**
   * Filter to items between two dates {from, to}
   * You can specify one of 'from' OR 'to' to find items before 'to' date or after 'from' date
   * or you can specify both 'from' AND 'to' to specify items BETWEEN two dates
   */
  createdOn?: InputMaybe<SearchDateInput>;
  /** Only items that I can edit as a user  */
  editableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Array<MetaDataInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Filter to items owned by a user or organization */
  ownedBy?: InputMaybe<OwnerInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter to items between two dates {from, to}
   * You can specify one of 'from' OR 'to' to find items before 'to' date or after 'from' date
   * or you can specify both 'from' AND 'to' to specify items BETWEEN two dates
   */
  updatedOn?: InputMaybe<SearchDateInput>;
};

export type SearchProject = SearchResult & {
  __typename?: 'SearchProject';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: Project;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchProjectPagination = Pagination & SearchPagination & {
  __typename?: 'SearchProjectPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchProject>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchProjectType = SearchResult & {
  __typename?: 'SearchProjectType';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: ProjectType;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchProjectTypePagination = Pagination & SearchPagination & {
  __typename?: 'SearchProjectTypePagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchProjectType>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchQaqcEvent = SearchResult & {
  __typename?: 'SearchQAQCEvent';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: QaqcEvent;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchQaqcEventPagination = Pagination & SearchPagination & {
  __typename?: 'SearchQAQCEventPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchQaqcEvent>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export type SearchResult = {
  /**
   * Takes the form:
   *       "highlights" : {
   *         "name" : [
   *           "Riverscapes <span>Context</span> for HUC 16040102"
   *         ],
   *         "fieldName" : [
   *           "match1 <span>with</span> highlight",
   *           "match2 <span>with</span> highlight"
   *         ]
   *       }
   */
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

/** Search Meta that appears on individual items */
export type SearchResultMeta = {
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  score: Scalars['Float']['output'];
};

export type SearchSavedSearch = SearchResult & {
  __typename?: 'SearchSavedSearch';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: SavedSearch;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchSavedSearchPagination = Pagination & SearchPagination & {
  __typename?: 'SearchSavedSearchPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchSavedSearch>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

/**
 * These are the ways you can sort search results
 *
 * Ranked sorting of return results. If NULL then the score will be the only factor
 * If specified then score is implicitly the last sort criteria
 */
export const SearchSortEnum = {
  AreaDesc: 'AREA_DESC',
  DateCreatedAsc: 'DATE_CREATED_ASC',
  DateCreatedDesc: 'DATE_CREATED_DESC',
  DateUpdatedAsc: 'DATE_UPDATED_ASC',
  DateUpdatedDesc: 'DATE_UPDATED_DESC',
  Mine: 'MINE',
  NameAsc: 'NAME_ASC',
  NameDesc: 'NAME_DESC'
} as const;

export type SearchSortEnum = typeof SearchSortEnum[keyof typeof SearchSortEnum];
export type SearchStats = ProjectSearchMeta | SearchMeta;

export type SearchSuggestionResult = SearchResult & {
  __typename?: 'SearchSuggestionResult';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item?: Maybe<DbObj>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchSuggestionResults = {
  __typename?: 'SearchSuggestionResults';
  metaKey: Array<SearchSuggestions>;
  metaValues: Array<SearchSuggestions>;
  results: Array<SearchSuggestionResult>;
  tags: Array<SearchSuggestions>;
};

export type SearchSuggestions = {
  __typename?: 'SearchSuggestions';
  freq?: Maybe<Scalars['Int']['output']>;
  score: Scalars['Float']['output'];
  text: Scalars['String']['output'];
};

export type SearchUser = SearchResult & {
  __typename?: 'SearchUser';
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  item: User;
  score?: Maybe<Scalars['Float']['output']>;
};

export type SearchUserPagination = Pagination & SearchPagination & {
  __typename?: 'SearchUserPagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  results: Array<SearchUser>;
  stats?: Maybe<SearchStats>;
  total: Scalars['Int']['output'];
};

export const SearchableTypesEnum = {
  Collection: 'COLLECTION',
  Organization: 'ORGANIZATION',
  Project: 'PROJECT',
  SavedSearch: 'SAVED_SEARCH',
  User: 'USER'
} as const;

export type SearchableTypesEnum = typeof SearchableTypesEnum[keyof typeof SearchableTypesEnum];
export const SeverityEnum = {
  Critical: 'CRITICAL',
  Debug: 'DEBUG',
  Error: 'ERROR',
  Info: 'INFO',
  Warning: 'WARNING'
} as const;

export type SeverityEnum = typeof SeverityEnum[keyof typeof SeverityEnum];
/** Links to various social networks */
export type SocialLinks = {
  __typename?: 'SocialLinks';
  facebook?: Maybe<Scalars['URL']['output']>;
  instagram?: Maybe<Scalars['URL']['output']>;
  linkedIn?: Maybe<Scalars['URL']['output']>;
  tiktok?: Maybe<Scalars['URL']['output']>;
  twitter?: Maybe<Scalars['URL']['output']>;
  website?: Maybe<Scalars['URL']['output']>;
};

export type SocialLinksInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['URL']['input']>;
};

export const StarrableTypesEnum = {
  Collection: 'COLLECTION',
  Organization: 'ORGANIZATION',
  Project: 'PROJECT',
  SavedSearch: 'SAVED_SEARCH',
  User: 'USER'
} as const;

export type StarrableTypesEnum = typeof StarrableTypesEnum[keyof typeof StarrableTypesEnum];
export type Symbology = {
  __typename?: 'Symbology';
  error?: Maybe<Scalars['String']['output']>;
  legend?: Maybe<Array<Array<Scalars['String']['output']>>>;
  mapboxJson?: Maybe<Scalars['JSONObject']['output']>;
  name: Scalars['String']['output'];
  rampType?: Maybe<RampTypeEnum>;
  state: SymbologyStateEnum;
  url?: Maybe<Scalars['String']['output']>;
};

/** SymbologyStateEnum */
export const SymbologyStateEnum = {
  Error: 'ERROR',
  Fetching: 'FETCHING',
  Found: 'FOUND',
  Missing: 'MISSING',
  NotApplicable: 'NOT_APPLICABLE',
  Unknown: 'UNKNOWN'
} as const;

export type SymbologyStateEnum = typeof SymbologyStateEnum[keyof typeof SymbologyStateEnum];
export type TileIndexOriginFile = FileDownloadMetaInterface & {
  __typename?: 'TileIndexOriginFile';
  contentType?: Maybe<Scalars['String']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  localPath?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
};

export type TileService = {
  __typename?: 'TileService';
  bounds?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  duration?: Maybe<Scalars['Float']['output']>;
  errorMsg?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  indexUrl?: Maybe<Scalars['String']['output']>;
  lastState?: Maybe<Scalars['DateTime']['output']>;
  layers?: Maybe<Array<Scalars['String']['output']>>;
  localPath?: Maybe<Scalars['String']['output']>;
  maxZoom?: Maybe<Scalars['Int']['output']>;
  minZoom?: Maybe<Scalars['Int']['output']>;
  numFiles?: Maybe<Scalars['Int']['output']>;
  originFile?: Maybe<TileIndexOriginFile>;
  projectId?: Maybe<Scalars['String']['output']>;
  rasterStats?: Maybe<Scalars['JSONObject']['output']>;
  rsXPath: Scalars['String']['output'];
  started?: Maybe<Scalars['DateTime']['output']>;
  state: TilingStateEnum;
  symbologies?: Maybe<Array<Scalars['String']['output']>>;
  tileType?: Maybe<TileTypesEnum>;
  url?: Maybe<Scalars['String']['output']>;
};

export const TileTypesEnum = {
  Html: 'HTML',
  Raster: 'RASTER',
  VectorGpkg: 'VECTOR_GPKG',
  VectorShp: 'VECTOR_SHP'
} as const;

export type TileTypesEnum = typeof TileTypesEnum[keyof typeof TileTypesEnum];
export const TilingStateEnum = {
  Creating: 'CREATING',
  Fetching: 'FETCHING',
  FetchError: 'FETCH_ERROR',
  IndexNotFound: 'INDEX_NOT_FOUND',
  LayerNotFound: 'LAYER_NOT_FOUND',
  NotApplicable: 'NOT_APPLICABLE',
  NoGeometries: 'NO_GEOMETRIES',
  Queued: 'QUEUED',
  Success: 'SUCCESS',
  TilingError: 'TILING_ERROR',
  Timeout: 'TIMEOUT',
  Unknown: 'UNKNOWN'
} as const;

export type TilingStateEnum = typeof TilingStateEnum[keyof typeof TilingStateEnum];
export type Transfer = DbSimpleObj & {
  __typename?: 'Transfer';
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  includeProjects?: Maybe<Scalars['Boolean']['output']>;
  /** Allows the request to have an attached note */
  note: Scalars['String']['output'];
  state: TransferStateEnum;
  /** Sorting and pagination are not available for these items */
  transferObjects: Array<TransferObject>;
  transferTo: Owner;
  transferType: TransferrableTypesEnum;
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};

export type TransferEntityItemsInput = {
  /** Attach a note to the transfer */
  note: Scalars['String']['input'];
  /** Organization or user to transfer this to */
  transferTo: OwnerInput;
};

export type TransferInput = {
  /** (Optional) When transferring a collection you can choose to transfer any projects in that collection (that you own) as well */
  includeProjects?: InputMaybe<Scalars['Boolean']['input']>;
  /** Attach a note to the transfer */
  note: Scalars['String']['input'];
  /** DBIds of the items to transfer */
  objectIds: Array<Scalars['ID']['input']>;
  /** Organization or user to transfer this to */
  transferTo: OwnerInput;
  /**
   * What type of transfer is this:
   *   - project(s): one or more projects
   *   - collection: Transfer the colletion with an option for any owned projects
   *   - organization: this is different from simply making someone else the owner
   *                   this means move all projects / collections to another user or org.
   *   - user: Transfer all my projects/collections to another org/user
   */
  transferType: TransferrableTypesEnum;
};

export type TransferObject = Collection | Organization | Project | User;

export const TransferStateEnum = {
  Accepted: 'ACCEPTED',
  Expired: 'EXPIRED',
  InProgress: 'IN_PROGRESS',
  Proposed: 'PROPOSED',
  Rejected: 'REJECTED'
} as const;

export type TransferStateEnum = typeof TransferStateEnum[keyof typeof TransferStateEnum];
export const TransferrableTypesEnum = {
  Collection: 'COLLECTION',
  Organization: 'ORGANIZATION',
  Project: 'PROJECT',
  User: 'USER'
} as const;

export type TransferrableTypesEnum = typeof TransferrableTypesEnum[keyof typeof TransferrableTypesEnum];
export type UploadProjectFileUrls = {
  __typename?: 'UploadProjectFileUrls';
  relPath: Scalars['String']['output'];
  urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UploadProjectRequest = {
  __typename?: 'UploadProjectRequest';
  create: Array<Scalars['String']['output']>;
  delete: Array<Scalars['String']['output']>;
  newId?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  update: Array<Scalars['String']['output']>;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  fields: Scalars['JSONObject']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** A User object is any user in the system */
export type User = DbObj & UserInterface & {
  __typename?: 'User';
  /** User affiliations (usually external to the data exchange) */
  affiliations: Array<Maybe<UserAffiliation>>;
  avatar?: Maybe<Scalars['URL']['output']>;
  /** Collections this user owns. Filtered if the querying user is someone else */
  collections: PaginatedCollections;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  lastLogin: Scalars['DateTime']['output'];
  location: Scalars['String']['output'];
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  /** Organizations this user is a member of. */
  organizations: PaginatedOrganizations;
  /** Projects this user owns. Filtered if the querying user is someone else */
  projects: PaginatedProjects;
  /** Saved Searches this user owns. Filtered if the querying user is someone else */
  savedSearches: PaginatedSavedSearches;
  social: SocialLinks;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};


/** A User object is any user in the system */
export type UserCollectionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A User object is any user in the system */
export type UserOrganizationsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A User object is any user in the system */
export type UserProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


/** A User object is any user in the system */
export type UserSavedSearchesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

/**
 * Affiliation can be anything as long as it fits as a list of
 * strings with URLs
 */
export type UserAffiliation = {
  __typename?: 'UserAffiliation';
  /** Who am I to this affiliation (Optional) */
  affiliationRole?: Maybe<Scalars['String']['output']>;
  /** Name of the affiliated entity or organization. Can be external to the data exchange */
  name: Scalars['String']['output'];
  /** (Optional) this affiliation may have a link */
  url?: Maybe<Scalars['URL']['output']>;
};

export type UserAffiliationInput = {
  /** Who am I to this affiliation (Optional) */
  affiliationRole?: InputMaybe<Scalars['String']['input']>;
  /** Name of the affiliated entity or organization. Can be external to the data exchange */
  name: Scalars['String']['input'];
  /** (Optional) this affiliation may have a link */
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type UserInterface = {
  /** Any organizational affiliation(s) the user specifies */
  affiliations: Array<Maybe<UserAffiliation>>;
  /** URL To Avatar image */
  avatar?: Maybe<Scalars['URL']['output']>;
  /** Collections this user owns. Filtered if the querying user is someone else */
  collections: PaginatedCollections;
  createdBy: User;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Simple job title string field */
  jobTitle: Scalars['String']['output'];
  /** When did we last see the user? */
  lastLogin: Scalars['DateTime']['output'];
  /** Simple location string (Optional) eg: "Vancouver, Canada" */
  location: Scalars['String']['output'];
  meta: Array<MetaData>;
  name: Scalars['String']['output'];
  /** Projects this user owns. Filtered if the querying user is someone else */
  projects: PaginatedProjects;
  /** Saved Searches this user owns. Filtered if the querying user is someone else */
  savedSearches: PaginatedSavedSearches;
  /** Object containing URLS to social media sites */
  social: SocialLinks;
  starred: Scalars['Boolean']['output'];
  starredCount: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  updatedBy: User;
  updatedOn: Scalars['DateTime']['output'];
};


export type UserInterfaceCollectionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type UserInterfaceProjectsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};


export type UserInterfaceSavedSearchesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
};

export type WarehouseInfo = {
  __typename?: 'WarehouseInfo';
  api?: Maybe<Scalars['String']['output']>;
  businessLogic?: Maybe<Scalars['String']['output']>;
  projectFile?: Maybe<Scalars['String']['output']>;
  warehouse?: Maybe<Scalars['String']['output']>;
  xml?: Maybe<Scalars['String']['output']>;
  xsd?: Maybe<Scalars['String']['output']>;
};

export type WatcherOutput = {
  __typename?: 'WatcherOutput';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ZipFileDownloadMeta = FileDownloadMetaInterface & {
  __typename?: 'ZipFileDownloadMeta';
  contentType?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  localPath?: Maybe<Scalars['String']['output']>;
  pendingSince?: Maybe<Scalars['DateTime']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
};

export type AdminReIndexOpenSearchMutationVariables = Exact<{
  paginationToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type AdminReIndexOpenSearchMutation = { __typename?: 'Mutation', adminReIndexOpenSearch?: string | null };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  options?: InputMaybe<EntityDeletionOptions>;
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject?: { __typename?: 'MutationResult', error?: string | null, ids?: Array<string> | null, message?: string | null, success: boolean } | null };

export type DirtyProjectsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type DirtyProjectsQuery = { __typename?: 'Query', dirtyProjects?: { __typename?: 'PaginatedProjects', limit: number, offset: number, total: number, items: Array<{ __typename?: 'Project', id: string, name: string, description: string, citation?: string | null, summary: string, updatedOn: string, createdOn: string, deleted: boolean, dirty: boolean, ref: string, bounds?: { __typename?: 'ProjectBounds', id: string, polygonUrl: string } | null, meta: Array<{ __typename?: 'MetaData', ext?: MetaDataExtEnum | null, key: string, value: string, locked?: boolean | null, type?: MetaDataTypeEnum | null }> }> } | null };

export type GetDirtyProjectQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type GetDirtyProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, description: string, citation?: string | null, summary: string, updatedOn: string, createdOn: string, deleted: boolean, dirty: boolean, ref: string, bounds?: { __typename?: 'ProjectBounds', id: string, polygonUrl: string } | null, meta: Array<{ __typename?: 'MetaData', ext?: MetaDataExtEnum | null, key: string, value: string, locked?: boolean | null, type?: MetaDataTypeEnum | null }> } | null };

export type DirtyProjectsFragmentFragment = { __typename?: 'Project', id: string, name: string, description: string, citation?: string | null, summary: string, updatedOn: string, createdOn: string, deleted: boolean, dirty: boolean, ref: string, bounds?: { __typename?: 'ProjectBounds', id: string, polygonUrl: string } | null, meta: Array<{ __typename?: 'MetaData', ext?: MetaDataExtEnum | null, key: string, value: string, locked?: boolean | null, type?: MetaDataTypeEnum | null }> };

export type Auth_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Auth_QueryQuery = { __typename?: 'Query', info?: { __typename?: 'WarehouseInfo', api?: string | null, businessLogic?: string | null, projectFile?: string | null, warehouse?: string | null, xml?: string | null, xsd?: string | null } | null };

export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = { __typename?: 'Query', profile?: { __typename?: 'Profile', id: string, name: string, initialized?: boolean | null } | null };

export type DownloadFileQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  filePath: Scalars['String']['input'];
}>;


export type DownloadFileQuery = { __typename?: 'Query', downloadFile?: { __typename?: 'FileDownloadMeta', localPath?: string | null, etag?: string | null, size?: number | null, contentType?: string | null, downloadUrl?: string | null } | null };

export type CreateProjectTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  projectType: ProjectTypeInput;
}>;


export type CreateProjectTypeMutation = { __typename?: 'Mutation', createProjectType?: { __typename?: 'ProjectType', id: string } | null };

export type UpdateProjectTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  projectType: ProjectTypeInput;
}>;


export type UpdateProjectTypeMutation = { __typename?: 'Mutation', updateProjectType?: { __typename?: 'ProjectType', id: string } | null };

export type CreateProjectMutationVariables = Exact<{
  projectId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  project: ProjectInput;
  projectTypeId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject?: { __typename?: 'Project', id: string, ref: string, createdOn: string, updatedOn: string, tags: Array<string>, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string }, projectType: { __typename?: 'ProjectType', id: string } } | null };

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  project: ProjectInput;
  agentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject?: { __typename?: 'Project', id: string, ref: string, createdOn: string, updatedOn: string, tags: Array<string>, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string }, projectType: { __typename?: 'ProjectType', id: string } } | null };

export type PurgeProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type PurgeProjectMutation = { __typename?: 'Mutation', purgeProject?: { __typename?: 'MutationResult', ids?: Array<string> | null, error?: string | null, success: boolean, message?: string | null } | null };

export type UpdateProfileMutationVariables = Exact<{
  profile: ProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename?: 'Profile', name: string } | null };

export type CreateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization?: { __typename?: 'Organization', id: string } | null };

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  organization: OrganizationInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string } | null };

export type CreateCollectionMutationVariables = Exact<{
  collection: CollectionInput;
  orgId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CreateCollectionMutation = { __typename?: 'Mutation', createCollection?: { __typename?: 'Collection', id: string } | null };

export type UpdateCollectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  organization: OrganizationInput;
}>;


export type UpdateCollectionMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string } | null };

export type GetProjectForUploadCheckQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dsLimit: Scalars['Int']['input'];
  dsOffset: Scalars['Int']['input'];
}>;


export type GetProjectForUploadCheckQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, summary: string, deleted: boolean, createdOn: string, updatedOn: string, ref: string, tags: Array<string>, projectType: { __typename?: 'ProjectType', id: string }, meta: Array<{ __typename?: 'MetaData', key: string, value: string, type?: MetaDataTypeEnum | null, locked?: boolean | null, ext?: MetaDataExtEnum | null }>, createdBy: { __typename?: 'User', id: string, name: string }, updatedBy: { __typename?: 'User', id: string, name: string }, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string }, datasets: { __typename?: 'PaginatedDatasets', total: number, items: Array<{ __typename?: 'Dataset', id: string, name: string, rsXPath: string }> } } | null };

export type ProjectDownloadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectDownloadQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, ref: string, tags: Array<string>, createdOn: string, updatedOn: string, deleted: boolean, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string }, projectType: { __typename?: 'ProjectType', id: string }, meta: Array<{ __typename?: 'MetaData', key: string, value: string, type?: MetaDataTypeEnum | null, locked?: boolean | null, ext?: MetaDataExtEnum | null }>, files: Array<{ __typename?: 'FileDownloadMeta', contentType?: string | null, downloadUrl?: string | null, etag?: string | null, localPath?: string | null, size?: number | null }> } | null };

export type ProjectExistsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectExistsQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, ref: string, deleted: boolean, createdOn: string, updatedOn: string, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string }, projectType: { __typename?: 'ProjectType', id: string }, meta: Array<{ __typename?: 'MetaData', key: string, value: string, type?: MetaDataTypeEnum | null, locked?: boolean | null, ext?: MetaDataExtEnum | null }> } | null };

export type ProjectTree_QueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectTree_QueryQuery = { __typename?: 'Query', project?: { __typename?: 'Project', deleted: boolean, tree: { __typename?: 'ProjectTree', defaultView?: string | null, description?: string | null, leaves: Array<{ __typename?: 'ProjectTreeLeaf', id: number, pid: number, label: string, labelxpath?: string | null, nodeId?: string | null, layerType: ProjectTreeLayerTypeEnum, blLayerId?: string | null, symbology?: string | null, transparency?: number | null, rsXPath: string, lyrName?: string | null }>, branches: Array<{ __typename?: 'ProjectTreeBranch', bid: number, collapsed?: boolean | null, label: string, pid: number }>, views: Array<{ __typename?: 'ProjectTreeView', id: string, name: string, description?: string | null, layers: Array<{ __typename?: 'ProjectTreeViewLayer', id: string, visible?: boolean | null }> }> } } | null };

export type GetProjectForWatcherQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetProjectForWatcherQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, createdOn: string, updatedOn: string, ref: string, deleted: boolean, projectType: { __typename?: 'ProjectType', id: string }, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string } } | null };

export type RequestUploadBoundsQueryVariables = Exact<{ [key: string]: never; }>;


export type RequestUploadBoundsQuery = { __typename?: 'Query', requestUploadBounds?: { __typename?: 'UploadUrl', fields: JSONObject, token: string, url: string } | null };

export type GetProjectFilesForDownloadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dsLimit: Scalars['Int']['input'];
  dsOffset: Scalars['Int']['input'];
}>;


export type GetProjectFilesForDownloadQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, name: string, summary: string, deleted: boolean, createdOn: string, updatedOn: string, ref: string, tags: Array<string>, projectType: { __typename?: 'ProjectType', id: string }, meta: Array<{ __typename?: 'MetaData', key: string, value: string, type?: MetaDataTypeEnum | null, locked?: boolean | null, ext?: MetaDataExtEnum | null }>, createdBy: { __typename?: 'User', id: string }, updatedBy: { __typename?: 'User', id: string }, ownedBy: { __typename: 'Organization', id: string, name: string } | { __typename: 'User', id: string, name: string }, datasets: { __typename?: 'PaginatedDatasets', total: number, items: Array<{ __typename?: 'Dataset', id: string, name: string, rsXPath: string }> }, files: Array<{ __typename?: 'FileDownloadMeta', localPath?: string | null, etag?: string | null, size?: number | null, contentType?: string | null, downloadUrl?: string | null }> } | null };

export type RebuildWebTilesMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  rsXPaths?: InputMaybe<Array<Scalars['String']['input']>>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RebuildWebTilesMutation = { __typename?: 'Mutation', rebuildWebTiles: { __typename?: 'RebuildWebTilesResponse', skipped?: Array<string> | null, queued?: Array<string> | null } };

export type ZipRebuildMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  force?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ZipRebuildMutation = { __typename?: 'Mutation', zipRebuild?: { __typename?: 'Project', id: string } | null };

export type ProjectSearchQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchSortEnum>>;
  params: ProjectSearchParamsInput;
  minScore?: InputMaybe<Scalars['Float']['input']>;
}>;


export type ProjectSearchQuery = { __typename?: 'Query', searchProjects: { __typename?: 'SearchProjectPagination', total: number, results: Array<{ __typename?: 'SearchProject', score?: number | null, item: { __typename: 'Project', id: string, name: string, deleted: boolean, visibility: ProjectVisibilityEnum, summary: string, projectType: { __typename?: 'ProjectType', id: string, name: string, logo?: string | null }, meta: Array<{ __typename?: 'MetaData', ext?: MetaDataExtEnum | null, key: string, locked?: boolean | null, type?: MetaDataTypeEnum | null, value: string }>, bounds?: { __typename?: 'ProjectBounds', id: string, geoHash?: string | null, polygonUrl: string, bbox: Array<number> } | null } }>, stats?: { __typename: 'ProjectSearchMeta', bbox?: Array<number> | null, projectTypes?: JSONObject | null } | { __typename: 'SearchMeta' } | null } };

export type ValidateProjectQueryVariables = Exact<{
  xml: Scalars['String']['input'];
  owner?: InputMaybe<OwnerInput>;
  files: Array<Scalars['String']['input']>;
}>;


export type ValidateProjectQuery = { __typename?: 'Query', validateProject?: { __typename?: 'ProjectValidation', valid?: boolean | null, errors?: Array<{ __typename: 'ProjectValidationError', code?: string | null, message: string, severity?: SeverityEnum | null } | null> | null } | null };

export type RequestUploadProjectQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['String']['input']>;
  etags: Array<Scalars['String']['input']>;
  sizes: Array<Scalars['BigInt']['input']>;
  noDelete?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<OwnerInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  visibility?: InputMaybe<ProjectVisibilityEnum>;
}>;


export type RequestUploadProjectQuery = { __typename?: 'Query', requestUploadProject?: { __typename: 'UploadProjectRequest', newId?: string | null, create: Array<string>, delete: Array<string>, token: string, update: Array<string> } | null };

export type CheckUploadQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CheckUploadQuery = { __typename?: 'Query', checkUpload?: { __typename: 'JobStatusObj', data?: JSONObject | null, errors?: Array<string> | null, metaData?: JSONObject | null, percentComplete?: number | null, status: JobStatusEnum } | null };

export type RequestUploadProjectFilesUrlQueryVariables = Exact<{
  files: Array<Scalars['String']['input']>;
  token: Scalars['String']['input'];
}>;


export type RequestUploadProjectFilesUrlQuery = { __typename?: 'Query', requestUploadProjectFilesUrl?: Array<{ __typename: 'UploadProjectFileUrls', relPath: string, urls?: Array<string | null> | null } | null> | null };

export type FinalizeProjectUploadMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type FinalizeProjectUploadMutation = { __typename?: 'Mutation', finalizeProjectUpload?: { __typename: 'JobStatusObj' } | null };

export type RequestUploadImageQueryVariables = Exact<{
  entityId: Scalars['ID']['input'];
  entityType: EntitiesWithImagesEnum;
}>;


export type RequestUploadImageQuery = { __typename?: 'Query', requestUploadImage?: { __typename?: 'UploadUrl', fields: JSONObject, token: string, url: string } | null };

export const DirtyProjectsFragment = gql`
    fragment DirtyProjectsFragment on Project {
  id
  name
  description
  citation
  summary
  updatedOn
  createdOn
  deleted
  dirty
  ref
  bounds {
    id
    polygonUrl
  }
  meta {
    ext
    key
    value
    locked
    type
  }
}
    `;
export const AdminReIndexOpenSearch = gql`
    mutation adminReIndexOpenSearch($paginationToken: String) {
  adminReIndexOpenSearch(paginationToken: $paginationToken)
}
    `;
export const DeleteProject = gql`
    mutation deleteProject($projectId: ID!, $options: EntityDeletionOptions) {
  deleteProject(projectId: $projectId, options: $options) {
    error
    ids
    message
    success
  }
}
    `;
export const DirtyProjects = gql`
    query dirtyProjects($limit: Int!, $offset: Int!) {
  dirtyProjects(limit: $limit, offset: $offset) {
    items {
      ...DirtyProjectsFragment
    }
    limit
    offset
    total
  }
}
    ${DirtyProjectsFragment}`;
export const GetDirtyProject = gql`
    query getDirtyProject($projectId: ID!) {
  project(id: $projectId) {
    ...DirtyProjectsFragment
  }
}
    ${DirtyProjectsFragment}`;
export const Auth_Query = gql`
    query auth_query {
  info {
    api
    businessLogic
    projectFile
    warehouse
    xml
    xsd
  }
}
    `;
export const MyProfile = gql`
    query myProfile {
  profile {
    id
    name
    initialized
  }
}
    `;
export const DownloadFile = gql`
    query downloadFile($projectId: ID!, $filePath: String!) {
  downloadFile(projectId: $projectId, filePath: $filePath) {
    localPath
    etag
    size
    contentType
    downloadUrl
  }
}
    `;
export const CreateProjectType = gql`
    mutation createProjectType($id: String!, $projectType: ProjectTypeInput!) {
  createProjectType(id: $id, projectType: $projectType, state: ACTIVE) {
    id
  }
}
    `;
export const UpdateProjectType = gql`
    mutation updateProjectType($id: String!, $projectType: ProjectTypeInput!) {
  updateProjectType(id: $id, projectType: $projectType, state: ACTIVE) {
    id
  }
}
    `;
export const CreateProject = gql`
    mutation createProject($projectId: ID, $orgId: ID, $userId: ID, $project: ProjectInput!, $projectTypeId: String!, $agentId: ID) {
  createProject(
    projectId: $projectId
    orgId: $orgId
    userId: $userId
    project: $project
    projectTypeId: $projectTypeId
    agentId: $agentId
  ) {
    id
    ref
    createdOn
    updatedOn
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
    projectType {
      id
    }
    tags
  }
}
    `;
export const UpdateProject = gql`
    mutation updateProject($projectId: ID!, $project: ProjectInput!, $agentId: ID) {
  updateProject(projectId: $projectId, project: $project, agentId: $agentId) {
    id
    ref
    createdOn
    updatedOn
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
    projectType {
      id
    }
    tags
  }
}
    `;
export const PurgeProject = gql`
    mutation purgeProject($projectId: ID!) {
  purgeProject(projectId: $projectId) {
    ids
    error
    success
    message
  }
}
    `;
export const UpdateProfile = gql`
    mutation updateProfile($profile: ProfileInput!) {
  updateProfile(profile: $profile) {
    name
  }
}
    `;
export const CreateOrganization = gql`
    mutation createOrganization($organization: OrganizationInput!) {
  createOrganization(organization: $organization) {
    id
  }
}
    `;
export const UpdateOrganization = gql`
    mutation updateOrganization($id: ID!, $organization: OrganizationInput!) {
  updateOrganization(id: $id, organization: $organization) {
    id
  }
}
    `;
export const CreateCollection = gql`
    mutation createCollection($collection: CollectionInput!, $orgId: ID) {
  createCollection(collection: $collection, orgId: $orgId) {
    id
  }
}
    `;
export const UpdateCollection = gql`
    mutation updateCollection($id: ID!, $organization: OrganizationInput!) {
  updateOrganization(id: $id, organization: $organization) {
    id
  }
}
    `;
export const GetProjectForUploadCheck = gql`
    query getProjectForUploadCheck($id: ID!, $dsLimit: Int!, $dsOffset: Int!) {
  project(id: $id) {
    id
    name
    summary
    deleted
    projectType {
      id
    }
    meta {
      key
      value
      type
      locked
      ext
    }
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
    createdOn
    updatedOn
    ref
    datasets(limit: $dsLimit, offset: $dsOffset) {
      items {
        id
        name
        rsXPath
      }
      total
    }
    tags
  }
}
    `;
export const ProjectDownload = gql`
    query projectDownload($id: ID!) {
  project(id: $id) {
    id
    name
    ref
    tags
    createdOn
    updatedOn
    deleted
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
    projectType {
      id
    }
    meta {
      key
      value
      type
      locked
      ext
    }
    files {
      contentType
      downloadUrl
      etag
      localPath
      size
    }
  }
}
    `;
export const ProjectExists = gql`
    query projectExists($id: ID!) {
  project(id: $id) {
    id
    name
    ref
    deleted
    createdOn
    updatedOn
    deleted
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
    projectType {
      id
    }
    meta {
      key
      value
      type
      locked
      ext
    }
  }
}
    `;
export const ProjectTree_Query = gql`
    query projectTree_query($id: ID!) {
  project(id: $id) {
    deleted
    tree {
      defaultView
      description
      leaves {
        id
        pid
        label
        labelxpath
        nodeId
        layerType
        blLayerId
        symbology
        transparency
        rsXPath
        lyrName
      }
      branches {
        bid
        collapsed
        label
        pid
      }
      defaultView
      views {
        id
        name
        description
        layers {
          id
          visible
        }
      }
    }
  }
}
    `;
export const GetProjectForWatcher = gql`
    query getProjectForWatcher($id: ID!) {
  project(id: $id) {
    id
    name
    createdOn
    updatedOn
    ref
    deleted
    projectType {
      id
    }
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
  }
}
    `;
export const RequestUploadBounds = gql`
    query requestUploadBounds {
  requestUploadBounds {
    fields
    token
    url
  }
}
    `;
export const GetProjectFilesForDownload = gql`
    query getProjectFilesForDownload($id: ID!, $dsLimit: Int!, $dsOffset: Int!) {
  project(id: $id) {
    id
    name
    summary
    deleted
    projectType {
      id
    }
    meta {
      key
      value
      type
      locked
      ext
    }
    createdBy {
      id
    }
    updatedBy {
      id
    }
    ownedBy {
      ... on User {
        id
        name
      }
      ... on Organization {
        id
        name
      }
      __typename
    }
    createdOn
    updatedOn
    ref
    datasets(limit: $dsLimit, offset: $dsOffset) {
      items {
        id
        name
        rsXPath
      }
      total
    }
    tags
    files {
      localPath
      etag
      size
      contentType
      downloadUrl
    }
  }
}
    `;
export const RebuildWebTiles = gql`
    mutation rebuildWebTiles($projectId: ID!, $rsXPaths: [String!], $force: Boolean) {
  rebuildWebTiles(projectId: $projectId, rsXPaths: $rsXPaths, force: $force) {
    skipped
    queued
  }
}
    `;
export const ZipRebuild = gql`
    mutation zipRebuild($projectId: ID!, $force: Boolean) {
  zipRebuild(projectId: $projectId, force: $force) {
    id
  }
}
    `;
export const ProjectSearch = gql`
    query projectSearch($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: ProjectSearchParamsInput!, $minScore: Float) {
  searchProjects(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        __typename
        ... on Project {
          id
          name
          deleted
          projectType {
            id
            name
            logo
          }
          visibility
          summary
          meta {
            ext
            key
            locked
            type
            value
          }
          bounds {
            id
            geoHash
            polygonUrl
            bbox
          }
        }
      }
    }
    stats {
      __typename
      ... on ProjectSearchMeta {
        bbox
        projectTypes
      }
    }
  }
}
    `;
export const ValidateProject = gql`
    query validateProject($xml: String!, $owner: OwnerInput, $files: [String!]!) {
  validateProject(xml: $xml, owner: $owner, files: $files) {
    valid
    errors {
      code
      message
      severity
      __typename
    }
  }
}
    `;
export const RequestUploadProject = gql`
    query requestUploadProject($projectId: String, $token: String, $files: [String!]!, $etags: [String!]!, $sizes: [BigInt!]!, $noDelete: Boolean, $owner: OwnerInput, $tags: [String!], $visibility: ProjectVisibilityEnum) {
  requestUploadProject(
    projectId: $projectId
    token: $token
    files: $files
    etags: $etags
    sizes: $sizes
    noDelete: $noDelete
    owner: $owner
    tags: $tags
    visibility: $visibility
  ) {
    newId
    create
    delete
    token
    update
    __typename
  }
}
    `;
export const CheckUpload = gql`
    query checkUpload($token: String!) {
  checkUpload(token: $token) {
    data
    errors
    metaData
    percentComplete
    status
    __typename
  }
}
    `;
export const RequestUploadProjectFilesUrl = gql`
    query requestUploadProjectFilesUrl($files: [String!]!, $token: String!) {
  requestUploadProjectFilesUrl(files: $files, token: $token) {
    relPath
    urls
    __typename
  }
}
    `;
export const FinalizeProjectUpload = gql`
    mutation finalizeProjectUpload($token: String!) {
  finalizeProjectUpload(token: $token) {
    __typename
  }
}
    `;
export const RequestUploadImage = gql`
    query requestUploadImage($entityId: ID!, $entityType: EntitiesWithImagesEnum!) {
  requestUploadImage(entityId: $entityId, entityType: $entityType) {
    fields
    token
    url
  }
}
    `;