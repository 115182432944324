import React from 'react'

import { MetaDataExtEnum, MetaDataTypeEnum, ProjectTreeLeaf } from '@riverscapes/react-common'
import { Box, Paper, SxProps, Theme, Typography, useTheme } from '@mui/material'
import WarehouseButton from '../WarehouseButton'
import { MetaData } from '@riverscapes/react-common'
import MetaDataComponent from './MetaData'
import { DSProjectRef } from '../../types'

const DO_NOT_SHOW = ['_rs_lyr_origin_url']

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    padding: theme.spacing(1),
  },
  minimal: {
    // background:
  },
  whNote: {
    padding: theme.spacing(2, 1),
  },
})

interface LayerMetaProps {
  leaf: ProjectTreeLeaf
  dsMeta: MetaData[]
  dsProjectRef?: DSProjectRef | null
  minimal?: boolean
  gotoWHProject: (projId: string) => void
}

function makeSections(meta: MetaData[]): Record<string, MetaData[]> {
  const showableMeta = meta.filter(({ key }) => !DO_NOT_SHOW.includes(key))

  const upstreamWarehouse = showableMeta.filter(({ ext }) => ext && ext === MetaDataExtEnum.Warehouse)
  const upstreamProject = showableMeta.filter(({ ext }) => ext && ext === MetaDataExtEnum.Project)
  const upstreamLayer = showableMeta.filter(({ ext }) => ext && ext === MetaDataExtEnum.Dataset)
  const Layer = showableMeta.filter(({ ext }) => !ext)

  return {
    upstreamWarehouse,
    upstreamProject,
    upstreamLayer,
    Layer,
  }
}

const LayerMeta: React.FC<LayerMetaProps> = ({
  leaf,
  minimal,
  dsMeta,
  gotoWHProject,
  dsProjectRef,
}: LayerMetaProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const sections = makeSections(dsMeta)

  const otherMeta: MetaData[] = [
    { key: 'Symbology', value: leaf.symbology || 'None', __typename: 'MetaData' },
    { key: 'Layer Name', value: leaf.lyrName || 'None', __typename: 'MetaData' },
    { key: 'Layer Id', value: leaf.nodeId || 'None', __typename: 'MetaData' },
    { key: 'rsXPath', value: leaf.rsXPath || 'None', __typename: 'MetaData', type: MetaDataTypeEnum.Hidden },
    { key: 'Default Transparency', value: `${leaf.transparency || 'None'}`, __typename: 'MetaData' },
  ]

  return (
    <Paper sx={minimal ? styles.minimal : styles.root}>
      {Object.keys(sections.Layer).length > 0 && <MetaDataComponent title="Layer Metadata" meta={sections.Layer} />}
      {dsProjectRef && (
        <Box sx={styles.whNote}>
          <Typography variant="caption" component="div">
            This layer was imported from a <strong>{dsProjectRef.projectType.name}</strong> project in the Riverscapes
            warehouse. You can visit the original project using the button below.
          </Typography>
          <WarehouseButton
            title={`Original ${dsProjectRef.projectType.name} Project`}
            onClick={() => gotoWHProject(dsProjectRef.id)}
          />
        </Box>
      )}
      {Object.keys(sections.upstreamProject).length > 0 && (
        <MetaDataComponent
          title="Original Project Metadata"
          meta={sections.upstreamProject}
          description="This is the project-level metadata from the original project"
        />
      )}
      {Object.keys(sections.upstreamLayer).length > 0 && (
        <MetaDataComponent
          title="Original Layer Metadata"
          meta={sections.upstreamLayer}
          description="This is the dataset-level metadata from the original project"
        />
      )}
      <MetaDataComponent title="Other" meta={otherMeta} description="Miscellaneous properties of this dataset layer" />
    </Paper>
  )
}

export default LayerMeta
