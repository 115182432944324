import React from 'react'
import { Box, ListItem, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material'
import { RampTypeEnum } from '@riverscapes/react-common'
// import log from 'loglevel'

const stylesThunk = (theme: Theme): Record<string, SxProps<Theme>> => ({
  root: {
    flexDirection: 'column',
    paddingLeft: theme.spacing(5),
  },
  tooltip: {
    display: 'flex',
    '&>div': {
      mr: theme.spacing(1),
    },
  },
  row: {
    width: '100%',
    display: 'flex',
  },
  colorBox: {
    flexGrow: 0,
    flexShrink: 0,
    border: '1px solid #eaeaea',
    overflow: 'hidden',
    color: '#FFFFFF00',
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  value: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export interface ColorRampProps {
  max?: number
  min?: number
  rampType?: RampTypeEnum
  legend: [string, string][]
}
const ColorRamp: React.FC<ColorRampProps> = ({ max, min, rampType, legend }: ColorRampProps) => {
  const theme = useTheme()
  const styles = stylesThunk(theme)
  const pRamp = parseRamp(legend, max, min, rampType)
  return (
    <ListItem divider dense disableGutters sx={styles.root}>
      {pRamp.map((row, idr) => (
        <Box key={`cr-${idr}`} sx={styles.row}>
          <Box sx={styles.colorBox} style={{ background: row[0] }}>
            color
          </Box>
          <Tooltip
            enterDelay={2000}
            title={
              <Box sx={styles.tooltip}>
                <Box sx={styles.colorBox} style={{ background: row[0] }}>
                  color
                </Box>
                {row[1]}
              </Box>
            }
          >
            <Typography variant="caption" component="div" sx={styles.value}>
              {row[1]}
            </Typography>
          </Tooltip>
        </Box>
      ))}
    </ListItem>
  )
}

export default ColorRamp

function parseRamp(ramp: [string, string][], max?: number, min?: number, rampType?: RampTypeEnum): [string, string][] {
  let newRamp: [string, string][] = []
  if (!max || !min || rampType !== RampTypeEnum.Interpolated) newRamp = [...ramp]
  else {
    newRamp = ramp.map(([color, value]) => {
      const regMatch = value.match(/^([0-9.]+)%$/)
      if (regMatch) {
        const percent = parseFloat(regMatch[1])
        const interp = (min + ((max - min) * percent) / 100).toFixed(2)
        return [color, interp]
      } else return [color, value]
    })
  }
  const newRampDict = newRamp.reduce((acc, r) => ({ ...acc, [`${r[0]}${r[1]}`]: r }), {})
  return Object.values(newRampDict)
}
