/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Stack,
} from '@mui/material'
import { AutocompleteSelectField, AutocompleteSelectOption } from '../AutocompleteSelectField'
import { someValues, useFields } from '../../lib'
import { VisibilityField } from '../Visibility'
import { ProjectGroupVisibilityEnum } from '../../schema/base'

export interface CreateSavedSearchDialogProps {
  onConfirm: (properties: { name: string; orgId?: string }) => void
  onClose: () => void
  open: boolean
  organizationOptions: AutocompleteSelectOption[]
}

export const CreateSavedSearchDialog: React.FC<CreateSavedSearchDialogProps> = ({
  onConfirm,
  onClose,
  open,
  organizationOptions,
}) => {
  const [fields, setFields, preparedFields] = useFields(
    {
      name: '',
      belongsTo: 'me',
      orgId: null as string | null,
      visibility: ProjectGroupVisibilityEnum.Public as ProjectGroupVisibilityEnum,
    },
    ({ name, orgId, belongsTo, visibility }) => ({
      name: name.trim(),
      orgId: belongsTo === 'org' && orgId ? orgId : undefined,
      visibility,
    }),
    [open]
  )

  const errors = someValues({
    name: !preparedFields.name,
    orgId: fields.belongsTo === 'org' && !preparedFields.orgId,
  })

  const handleConfirm = () => {
    onConfirm(preparedFields)
    onClose()
  }

  const getOrganizationOptionsFromText = (text: string): AutocompleteSelectOption[] => {
    if (!text.trim()) return organizationOptions
    return organizationOptions.filter((option) => option.text.toLowerCase().includes(text.toLowerCase()))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save Search</DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Stack direction="column" gap={2}>
          <TextField
            sx={{ mt: 1 }}
            label="Name"
            onChange={({ target: { value } }) => setFields.$.name(value)}
            value={fields.name}
            error={errors?.name}
          />
          <FormControl>
            <FormLabel>Belongs To</FormLabel>
            <RadioGroup value={fields.belongsTo} onChange={(e, newValue) => setFields.$.belongsTo(newValue)}>
              <FormControlLabel value="me" control={<Radio />} label="Me" />
              <FormControlLabel
                control={<Radio />}
                value="org"
                componentsProps={{ typography: { sx: { flex: 1 } } }}
                label={
                  <AutocompleteSelectField
                    disabled={fields.belongsTo !== 'org'}
                    noOptionsText="No organizations found"
                    value={fields.orgId}
                    onChange={(newValue) =>
                      setFields({
                        ...fields,
                        belongsTo: 'org',
                        orgId: newValue,
                      })
                    }
                    label="Organization"
                    getOptionsFromText={getOrganizationOptionsFromText}
                    error={errors?.orgId}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
          <VisibilityField type="SavedSearch" value={fields.visibility} onChange={setFields.$.visibility} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={!!errors}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
