import { Box, Button, Stack, Typography } from '@mui/material'
import { useBooleanState, useFormats } from '../../lib'
import { GetOrganizationDetailQuery } from '../../schema/base'
import { OtherResults, OtherResultsProps } from '../Search'
import { Lookups } from '../lookups'
import { CreateCollectionDialog, CreateCollectionDialogProps } from '../CreateCollectionDialog'
import { AddCircle } from '@mui/icons-material'

export type CollectionsPanelProps = {
  lookups: Lookups
  organization: NonNullable<GetOrganizationDetailQuery['organization']>
  onCreateCollection: CreateCollectionDialogProps['onConfirm']
} & Omit<
  OtherResultsProps,
  keyof {
    NoResults: never
    getItemUrlById: never
    type: never
  }
>

export const CollectionsPanel: React.FC<CollectionsPanelProps> = ({
  lookups,
  organization,
  onCreateCollection,
  ...otherResultsProps
}) => {
  const [isCreateCollectionOpen, openCreateCollection, closeCreateCollection] = useBooleanState()
  const { formatNumber, pluralize } = useFormats()

  const collectionsTotal = organization.collections.total
  return (
    <>
      <Stack sx={{ height: '100%', gap: 1 }}>
        <Stack direction="row" alignItems="center" sx={{ p: 2, borderBottom: 1, gap: 2, borderColor: 'divider' }}>
          <Stack direction="column" sx={{ flex: 1 }}>
            <Typography variant="h6">
              This organization has <strong>{formatNumber(collectionsTotal)}</strong>{' '}
              {pluralize('collection', collectionsTotal)}
            </Typography>
          </Stack>
          <div>
            <Button variant="text" startIcon={<AddCircle />} onClick={openCreateCollection}>
              Create Collection
            </Button>
          </div>
        </Stack>
        <Box sx={{ flex: 1, height: 0 }}>
          <OtherResults
            NoResults={() => <div>Project is not included in any collections.</div>}
            lookups={lookups}
            type="Collection"
            {...otherResultsProps}
          />
        </Box>
        <CreateCollectionDialog
          onClose={closeCreateCollection}
          onConfirm={onCreateCollection}
          open={isCreateCollectionOpen}
          organization={organization}
        />
      </Stack>
    </>
  )
}
