import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { debounce, merge } from 'lodash'
import { MapRef, ViewState } from 'react-map-gl'
import { LngLatBounds, FitBoundsOptions } from 'mapbox-gl'
import { useDeconstructedPromise } from '../../lib'
import { expandBbox } from './util'

export type Bbox = [minLng: number, minLat: number, maxLng: number, maxLat: number]

export const InteractiveMapControllerContext = createContext<
  Pick<InteractiveMapController, 'registerLayerId' | 'unregisterLayerId' | 'fitBboxes' | 'flyTo'> | undefined
>(undefined)

export interface UseInteractiveMapControllerParams {
  initialViewState?: Partial<ViewState>
  initialBbox?: Bbox
  onBboxChange?: (newBbox: Bbox) => void
  onViewStateChange?: (newViewState: ViewState) => void
  suspend?: boolean
}

export interface InteractiveMapController {
  fitBboxes: (targets: Bbox[], options?: FitBoundsOptions & { paddingFactor?: number }) => Promise<void>
  flyTo: (target: { longitude: number; latitude: number; zoom?: number }) => void
  interactiveLayerIds: string[]
  initialBbox?: Bbox
  onBboxChange: (newBbox: Bbox) => void
  onReady: (mapRef: MapRef) => void
  registerLayerId: (id: string) => void
  setViewState: (newViewStateOrUpdater: ViewState | ((viewState: ViewState) => ViewState), duration?: number) => void
  suspend: boolean
  unregisterLayerId: (id: string) => void
  viewState: ViewState
  mapReady: Promise<MapRef>
}

export const DEFAULT_VIEW_STATE: ViewState = {
  longitude: -97.0,
  latitude: 39.0,
  zoom: 3, // crude approximation as what you see depends on window size; this fits Lower 48 @ 600px
  bearing: 0,
  pitch: 0,
  padding: {
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
  },
}

export const MAX_ZOOM = 22 // closest we ever want to be
export const IDEAL_CLOSE_ZOOM = 9 // good zoom for single point, and closest we ever want to be on map loading
const DEBOUNCE_MS = 500

export const useInteractiveMapController = ({
  initialBbox,
  initialViewState,
  onBboxChange,
  onViewStateChange,
  suspend: suspendProp,
}: UseInteractiveMapControllerParams): InteractiveMapController => {
  const instance = useRef<Partial<UseInteractiveMapControllerParams> & { fetchCount: number; loadingCount: number }>({
    fetchCount: 0,
    loadingCount: 0,
  }).current
  instance.onBboxChange = onBboxChange
  instance.onViewStateChange = onViewStateChange

  const [mapReady, setMap] = useDeconstructedPromise<MapRef>()
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([])

  const [viewState, setViewState] = useState<ViewState>(merge({ ...DEFAULT_VIEW_STATE }, initialViewState))
  const [suspend, setSuspend] = useState(!!initialBbox)

  const debouncedHandleViewStateChange = useCallback(
    debounce((newViewState: ViewState) => {
      if (instance.onViewStateChange) instance.onViewStateChange(newViewState)
    }, DEBOUNCE_MS),
    []
  )

  const handleViewStateChange = (newViewStateOrUpdater: ViewState | ((viewState: ViewState) => ViewState)) => {
    const newViewState =
      typeof newViewStateOrUpdater === 'function' ? newViewStateOrUpdater(viewState) : newViewStateOrUpdater
    setViewState(newViewState)
    debouncedHandleViewStateChange(newViewState)
  }

  const debouncedHandleBboxChange = useCallback(
    debounce((newBbox: Bbox) => {
      if (!instance.onBboxChange) return
      const [minLng, minLat, maxLng, maxLat] = newBbox
      instance.onBboxChange([
        Math.max(minLng, -180),
        Math.max(minLat, -90),
        Math.min(maxLng, 180),
        Math.min(maxLat, 90),
      ] as Bbox)
    }, DEBOUNCE_MS),
    []
  )

  const fitBboxes = useCallback(async (targets: Bbox[], options?: FitBoundsOptions & { paddingFactor?: number }) => {
    const [first, ...rest] = targets
    const lngLatBounds = new LngLatBounds(first)
    rest.forEach((bbox) => lngLatBounds.extend(bbox))
    const [[minLng, minLat], [maxLng, maxLat]] = lngLatBounds.toArray()
    lngLatBounds.extend(expandBbox([minLng, minLat, maxLng, maxLat], options?.paddingFactor ?? 0.2)) // apply padding
    const mapRef = await mapReady
    mapRef.fitBounds(lngLatBounds, { duration: 1000, ...options })
  }, [])

  const flyTo = useCallback(
    async ({ longitude, latitude, zoom }: { longitude: number; latitude: number; zoom?: number }) => {
      const mapRef = await mapReady
      mapRef.flyTo({ center: { lng: longitude, lat: latitude }, zoom, padding: 40 })
    },
    []
  )

  const registerLayerId = useCallback((registerId: string) => {
    setInteractiveLayerIds((current) => [...current, registerId])
  }, [])

  const unregisterLayerId = useCallback((unregisterId: string) => {
    setInteractiveLayerIds((current) => current.filter((id) => id !== unregisterId))
  }, [])

  useEffect(() => {
    if (!initialBbox) return
    fitBboxes([initialBbox], { duration: 0 }).then(() => setSuspend(false))
  }, [])

  return {
    fitBboxes,
    flyTo,
    interactiveLayerIds,
    mapReady,
    initialBbox,
    onBboxChange: debouncedHandleBboxChange,
    onReady: setMap,
    registerLayerId,
    setViewState: handleViewStateChange,
    suspend: suspend || !!suspendProp,
    unregisterLayerId,
    viewState,
  }
}

export const useInteractiveMapContext = (): Pick<
  InteractiveMapController,
  'registerLayerId' | 'unregisterLayerId' | 'fitBboxes' | 'flyTo'
> => {
  const controllerContextMethods = useContext(InteractiveMapControllerContext)
  if (!controllerContextMethods) throw new Error('Component must appear as a child of InteractiveMap.')
  return controllerContextMethods
}
