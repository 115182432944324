import { PendingItem, pendingItems } from '@riverscapes/react-common'
import { useEffect, useMemo } from 'react'

/**
 * This is a hook that will fetch more items from a paginated query
 * (It is a direct copy from the warehouse-app)
 * @param fetchMore
 * @param currentItems
 * @param expectedCount
 * @param defaultExpectedCount
 * @returns
 */
export const useFetchAllWithPendingItems = <T>(
  fetchMore: ({ variables: { offset } }) => void, // Apollo fetchMore function
  currentItems: T[] | undefined, // what items are already retrieved
  expectedCount: number | undefined, // how many items are we expecting?
  defaultExpectedCount = 1
): (T | PendingItem)[] => {
  const currentItemsCount = currentItems?.length

  useEffect(() => {
    if (typeof currentItemsCount === 'undefined' || typeof expectedCount === 'undefined') return
    if (currentItemsCount < expectedCount) fetchMore({ variables: { offset: currentItemsCount } })
  }, [currentItemsCount, expectedCount])

  return useMemo(() => {
    if (typeof expectedCount === 'undefined') return pendingItems(defaultExpectedCount)
    if (!currentItems) return pendingItems(expectedCount)
    return [...currentItems, ...pendingItems(expectedCount - currentItems.length)]
  }, [currentItems, expectedCount, defaultExpectedCount])
}
