import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { GetProjectCardQuery } from '../../schema/base'
import { Lookups } from '../lookups'
import { ProjectTypeImage } from '../ItemImage'
import React, { useMemo } from 'react'
import { getSpecialMeta } from '../../lib/meta'
import { withTooltip } from '../../lib'
import { FilterList } from '@mui/icons-material'

export interface MiniProjectCardProps {
  // active?: boolean
  className?: string
  lookups: Lookups
  project: NonNullable<GetProjectCardQuery['project']>
  link: boolean
}

export const MiniProjectCard: React.FC<MiniProjectCardProps> = ({ project, lookups, link }) => {
  const secondary: React.ReactNode[] = []
  const { huc, modelVersion } = useMemo(() => getSpecialMeta(project), [project.meta])
  if (huc) secondary.push(`HUC: ${huc}`)
  if (modelVersion) secondary.push(`Model Version: ${modelVersion}`)
  const interior = (
    <>
      <ListItemAvatar sx={{ p: 0, minWidth: 30 }}>
        <ProjectTypeImage size={25} item={project.projectType} tooltip />
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{ component: 'div' }}
        primary={project.name}
        sx={{ m: 0 }}
        secondary={
          secondary.length > 0 && (
            <Stack direction={'row'} spacing={1}>
              {secondary.map((s, i) => (
                <Typography key={i} variant="caption" color="text.secondary">
                  {s}
                </Typography>
              ))}
            </Stack>
          )
        }
      />
    </>
  )

  return link ? (
    <ListItemButton
      sx={{
        width: '100%',
        px: 0.5,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
          backgroundColor: '#d4fdff',
        },
      }}
      href={lookups.getProjectUrlById(project.id)}
    >
      {interior}
    </ListItemButton>
  ) : (
    <ListItem
      sx={{
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      {interior}
    </ListItem>
  )
}

export const MiniProjectCardSkeleton: React.FC = () => {
  return (
    <ListItem
      sx={{
        width: '15rem',
        maxWidth: '20rem',
        px: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      }}
    >
      <ListItemAvatar sx={{ p: 0, minWidth: 30 }}>
        <Skeleton variant="circular" width={25} height={25} />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton variant="text" width="100%" />} />
    </ListItem>
  )
}

export const MiniExcessProjectsCard: React.FC<{
  excessCount: number
  onLimitBounds?: () => void
}> = ({ excessCount, onLimitBounds }) => {
  return (
    <Paper sx={{ minWidth: '10rem', px: 1, height: 'auto', display: 'flex' }}>
      <Typography variant="caption">
        <em>+ {excessCount} more</em>{' '}
      </Typography>
      <div style={{ flex: 1 }} />
      {onLimitBounds &&
        withTooltip(
          <Button
            size="small"
            onClick={onLimitBounds}
            endIcon={<FilterList />}
            sx={{
              p: 0,
              fontSize: '0.65rem',
              lineHeight: 1.5,
            }}
          >
            Filter
          </Button>,
          'Filter to projects using this Polygon'
        )}
    </Paper>
  )
}
