import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material'
import { identity, uniqueId } from 'lodash'
import { useMemo, useState, isValidElement } from 'react'

export interface MenuProps extends Omit<MuiMenuProps, 'open'> {
  Button: (buttonProps: {
    id: string
    onClick: (e: React.MouseEvent<HTMLElement>) => void
    disabled: boolean
  }) => React.ReactElement
  disabled?: boolean
  options: (OptionProps | undefined | false | null | React.ReactNode)[] // falsey will render nothing (to simplify conditional rendering)
}

interface OptionProps extends MuiMenuItemProps {
  label: React.ReactNode
  onClick?: () => void
  key: string
}

export const Menu: React.FC<MenuProps> = ({ options, Button, disabled, ...restMenuProps }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuId = useMemo(uniqueId, [])

  const open = !!anchorEl

  const noOptions = !options.some(identity)

  return (
    <div>
      <Button
        onClick={handleClick}
        id={`Menu-button-${menuId}`}
        aria-controls={open ? `Menu-list-${menuId}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled || noOptions}
      />

      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id={`Menu-list-${menuId}`}
        MenuListProps={{
          'aria-labelledby': `Menu-button-${menuId}`,
        }}
        {...restMenuProps}
      >
        {options.map((option) => {
          if (!option) return null
          if (isValidElement(option)) return option
          const { key, label, onClick, ...restOptionProps } = option as OptionProps
          return (
            <MuiMenuItem
              key={key}
              onClick={
                onClick &&
                (() => {
                  onClick()
                  handleClose()
                })
              }
              {...restOptionProps}
            >
              {label}
            </MuiMenuItem>
          )
        })}
      </MuiMenu>
    </div>
  )
}
