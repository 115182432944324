/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { GetProjectDetailQuery } from '../../schema/base'
import { Qaqc, QaqcProps } from '../Qaqc'

export interface QaPanelProps {
  project: NonNullable<GetProjectDetailQuery['project']>
  items: QaqcProps['items']
}

export const QaPanel: React.FC<QaPanelProps> = ({ items }) => {
  return (
    <Box sx={{ height: '100%' }}>
      <Qaqc items={items} />
    </Box>
  )
}
