import { useRef, useState } from 'react'
import { Box, ClickAwayListener, MenuItem, MenuList, Popper, TextField, useTheme } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'

export interface SmartSearchFieldProps {
  onChange: (newValue: string) => void
  options: SmartSearchFieldOption[]
  value: string
}

export interface SmartSearchFieldOption {
  key: string
  label: React.ReactNode
  onChoose: () => void
}

const Option = ({
  label,
  last,
  onChoose,
  onMouseMove,
  selected,
}: SmartSearchFieldOption & {
  last?: boolean
  onMouseMove: () => void
  selected?: boolean
}) => {
  return (
    <MenuItem sx={{ p: 1 }} divider={!last} selected={selected} onClick={onChoose} onMouseMove={onMouseMove}>
      {label}
    </MenuItem>
  )
}

const FULL_WIDTH = '500px'

export const SmartSearchField: React.FC<SmartSearchFieldProps> = ({ onChange, options, value }) => {
  const ref = useRef<HTMLInputElement>()
  const theme = useTheme()
  const listRef = useRef<HTMLUListElement | null>(null)

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  // HANDLERS

  const handleFocus = () => {
    if (!ref.current) return
    setSelectedIndex(0)
    setAnchorEl(ref.current)
  }
  const handleClose = () => {
    setAnchorEl(null)
    ref.current?.blur()
  }
  const handleChooseOption = (option: SmartSearchFieldOption) => {
    option.onChoose()

    window.setTimeout(() => {
      handleClose()
      onChange('')
    }, 0) // TODO: stagger so we can see the ripple feedback
  }
  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Escape': {
        handleClose()
        break
      }
      case 'Enter': {
        if (!options[selectedIndex]) break
        handleChooseOption(options[selectedIndex])
        break
      }
      case 'ArrowDown': {
        event.preventDefault()
        if (selectedIndex > options.length - 2) {
          setSelectedIndex(0)
        } else {
          setSelectedIndex(selectedIndex + 1)
        }
        break
      }
      case 'ArrowUp': {
        event.preventDefault()
        if (selectedIndex < 1) {
          setSelectedIndex(options.length - 1)
        } else {
          setSelectedIndex(selectedIndex - 1)
        }
        break
      }
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const isOpen = !!anchorEl

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ display: 'inline-block', flex: 1 }}>
        <TextField
          fullWidth
          inputRef={ref}
          variant="outlined"
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder="Search or jump to..."
          onFocus={handleFocus}
          InputProps={{
            startAdornment: <SearchIcon />,
            // sx: { width: isOpen ? FULL_WIDTH : undefined, backgroundColor: isOpen ? 'background.paper' : undefined },
            sx: {
              maxWidth: FULL_WIDTH,
              border: `1px solid ${theme.palette.primary.light}`,
              backgroundColor: isOpen ? 'background.paper' : theme.palette.primary.dark,
              color: isOpen ? theme.palette.text.primary : theme.palette.primary.contrastText,
              //
            },
            autoComplete: 'off',
          }}
        />
        <Popper
          anchorEl={anchorEl}
          open={isOpen}
          placement="bottom-start"
          sx={{
            zIndex: 2000,
          }}
        >
          <MenuList
            sx={{
              border: 1,
              borderColor: 'divider',
              width: FULL_WIDTH,
              backgroundColor: 'background.paper',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              p: 0,
            }}
            ref={listRef}
          >
            {options.map((option, index) => {
              const { key, label } = option
              return (
                <Option
                  key={key}
                  label={label}
                  onChoose={() => handleChooseOption(option)}
                  selected={index === selectedIndex}
                  onMouseMove={() => setSelectedIndex(index)}
                />
              )
            })}
          </MenuList>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}
