import {
  DetailPageTabs,
  MissingDataError,
  PageTitle,
  ProjectDetailSkeleton,
  UserDetail as Components,
  SortOrderFieldProps,
  PAGE_SIZE,
  useActiveResults,
  pendingItems,
  Bbox,
  useInteractiveMapController,
  ProjectBoundsThumbProps,
  OwnerInputTypesEnum,
  useNotifications,
  CollectionInput,
  LogicError,
  Footer,
  INFINISCROLLER_PAGE_SIZE,
  SearchViewTypeEnum,
} from '@riverscapes/react-common'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useActivity, useLookups, useProfile } from '../lib'
import { search, useGotoRoute, userDetail, webRaveDetail } from '../routing'
import { evictAllQueries, useGetUserDetailQuery } from '../data'
import { Box, Container } from '@mui/material'
import { NotFound } from './NotFound'
import {
  useCreateCollectionMutation,
  useGetUserCollectionsQuery,
  useGetUserProjectsQuery,
} from '@riverscapes/react-common/dist/schema/operations'
import { useProjectsMap } from './Search/useProjectsMap'

const { Header, OverviewPanel, CollectionsPanel, ProjectsPanel } = Components

export interface UserDetailProps extends JSX.IntrinsicAttributes {
  id: string
  tab?: string
  viewType?: SearchViewTypeEnum
}

export const UserDetail: React.FC<UserDetailProps> = ({ id, tab = 'overview', viewType = SearchViewTypeEnum.Map }) => {
  const gotoSelf = useGotoRoute(userDetail)
  const gotoWebRave = useGotoRoute(webRaveDetail)
  const { catchError } = useNotifications()
  const gotoSearch = useGotoRoute(search)
  const [projectPaginationOffset, setProjectPaginationOffset] = useState(INFINISCROLLER_PAGE_SIZE) // Remember that this doesn't get used until page 2

  const lookups = useLookups()
  const profile = useProfile()

  // MUTABLES

  const setTab = (newTab: string) => gotoSelf({ id, tab: newTab }, true)
  const setViewType = (newViewType: SearchViewTypeEnum) => gotoSelf({ id, tab, viewType: newViewType }, true)

  // USER QUERY

  const { data, loading, error } = useGetUserDetailQuery({ variables: { id } })

  // ACTIVITY

  const { addActivity } = useActivity()
  useEffect(() => {
    if (!data?.user) return
    addActivity({ operation: 'view', item: data.user })
  }, [data?.user])

  // Project PANEL

  // projects (paged)
  const [projectsSort, setProjectsSort] = useState<SortOrderFieldProps['sort']>(null)
  const mapBoxRef = useRef<HTMLDivElement>(null)
  const {
    data: projectsData,
    fetchMore: fetchMoreProjects,
    loading: searchLoadingProjects,
  } = useGetUserProjectsQuery({
    variables: {
      id,
      limit: INFINISCROLLER_PAGE_SIZE,
      offset: 0,
      sort: projectsSort && [projectsSort],
    },
    notifyOnNetworkStatusChange: true,
    skip: tab !== 'projects',
    onError: catchError('Failed to get collection projects', false),
  })

  // Fetch the next page of projects
  const theoreticalTotal = projectsData?.user?.projects?.total ?? 0
  const projectsHasNext = Boolean(theoreticalTotal > projectPaginationOffset)
  const fetchNextPage = () => {
    if (projectsHasNext && !searchLoadingProjects) {
      fetchMoreProjects({
        variables: {
          offset: projectPaginationOffset,
        },
      }).then(() => {
        setProjectPaginationOffset((prevOffset) => {
          return prevOffset + INFINISCROLLER_PAGE_SIZE > theoreticalTotal
            ? theoreticalTotal
            : prevOffset + INFINISCROLLER_PAGE_SIZE
        })
      })
    }
  }

  const projectStats = useActiveResults({}, data?.user?.projects.total)
  const displayItems = projectsData?.user?.projects.items ?? pendingItems(projectStats.expectedCount)

  const [bbox, setBbox] = useState<Bbox | undefined>()
  const [hoveredBoundsIds, setHoveredBoundsIds] = useState<string[]>([])
  const mapController = useInteractiveMapController({
    onBboxChange: setBbox,
  })

  const getProjectBoundsThumbProps = useCallback(
    (bounds: { id: string; polygonUrl: string; bbox: Bbox }): Partial<ProjectBoundsThumbProps> => ({
      onZoomClick: () => mapController.fitBboxes([bounds.bbox]),
      onHoverChange: (newHover: boolean) => {
        setHoveredBoundsIds(newHover ? [bounds.id] : [])
      },
      highlight: hoveredBoundsIds.includes(bounds.id),
      visibilityBbox: bbox,
    }),
    [hoveredBoundsIds, bbox]
  )
  const {
    handleMapClick,
    handleMapHover,
    mapElements,
    popupState: { ids: popupBoundsIds },
  } = useProjectsMap({
    params: {
      ownedBy: {
        id: id,
        type: OwnerInputTypesEnum.Organization,
      },
    },
    mapBoxRef,
    lookups,
    bbox,
    mapController,
    getProjectBoundsThumbProps,
    highlightBoundsIds: hoveredBoundsIds,
  })

  useEffect(() => {
    setHoveredBoundsIds(popupBoundsIds)
  }, [popupBoundsIds])

  // COLLECTIONS PANEL

  const [collectionsPage, setCollectionsPage] = useState(1)
  const [collectionsSort, setCollectionsSort] = useState<SortOrderFieldProps['sort']>(null)

  const { data: collectionsData } = useGetUserCollectionsQuery({
    variables: {
      id,
      limit: PAGE_SIZE,
      offset: (collectionsPage - 1) * PAGE_SIZE,
      sort: collectionsSort && [collectionsSort],
    },
    skip: tab !== 'collections',
    onError: catchError('Failed to get project collections', false),
  })

  const collectionStats = useActiveResults({ page: collectionsPage }, data?.user?.collections.total)

  const collections = collectionsData?.user?.collections.items ?? pendingItems(collectionStats.expectedCount)

  const [createCollection] = useCreateCollectionMutation()
  const handleCreateCollection = async ({ ...restCollection }: { orgId?: string } & Partial<CollectionInput>) => {
    let collectionId: string | undefined

    // create collection
    try {
      const newCollection = await createCollection({
        variables: {
          collection: restCollection,
        },
        update: evictAllQueries,
      })
      if (!newCollection.data?.createCollection) throw new MissingDataError()
      collectionId = newCollection.data.createCollection.id
    } catch (err) {
      catchError('Failed to create new collection', true)(err)
    }

    if (!collectionId) throw new LogicError()
  }

  // RENDER

  if (error?.message === 'User not found') return <NotFound id={id} descriptor="User" />
  if (error) throw error
  if (loading || !profile) return <ProjectDetailSkeleton />
  if (!data?.user) throw new MissingDataError()

  const { user } = data

  return (
    <>
      <PageTitle title={user.name} />
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Header user={user} profile={profile} lookups={lookups} />
        <Container
          maxWidth="lg"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <DetailPageTabs
            value={tab}
            onChange={setTab}
            tabs={[
              {
                name: 'overview',
                content: <OverviewPanel user={user} lookups={lookups} />,
              },
              {
                name: 'projects',
                content: (
                  <ProjectsPanel
                    user={user}
                    onFetchMoreProjects={fetchNextPage}
                    loadingHasMore={projectsHasNext}
                    loadingProjects={searchLoadingProjects}
                    getProjectBoundsThumbProps={getProjectBoundsThumbProps}
                    pageOffset={projectPaginationOffset > theoreticalTotal ? theoreticalTotal : projectPaginationOffset}
                    items={displayItems}
                    onGotoWebRave={(id: string) => gotoWebRave({ id })}
                    lookups={lookups}
                    mapBoxRef={mapBoxRef}
                    mapController={mapController}
                    mapElements={mapElements}
                    onMapClick={handleMapClick}
                    onMapHover={handleMapHover}
                    onSearch={gotoSearch}
                    onSortChange={setProjectsSort}
                    sort={projectsSort}
                    viewType={viewType}
                    onViewTypeChange={setViewType}
                  />
                ),
              },
              {
                name: 'collections',
                content: (
                  <CollectionsPanel
                    onCreateCollection={handleCreateCollection}
                    items={collections}
                    lookups={lookups}
                    onPageChange={setCollectionsPage}
                    onSortChange={setCollectionsSort}
                    page={collectionsPage}
                    pageCount={collectionStats.pageCount}
                    user={user}
                    sort={collectionsSort}
                  />
                ),
              },
            ]}
          />
        </Container>
        <Footer />
      </Box>
    </>
  )
}
