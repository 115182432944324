import { Avatar, Badge, useTheme } from '@mui/material'
import { useBooleanState, withTooltip } from '../../lib'
import { EditIcon } from '../Icons'
import { ImageManagerDialogProps, ImageManagerDialog } from '../ImageManager'
import { Lookups } from '../lookups'
import { Person } from '@mui/icons-material'
import { noop } from 'lodash'

export type AvatarImageProps = {
  item: {
    __typename: 'User' | 'Profile'
    name: string
    avatar?: string | null
  }
  size: number
  tooltip?: boolean
} & (
  | { canEdit?: false }
  | {
      canEdit: true
      getUploadTarget: ImageManagerDialogProps['getUploadTarget']
      lookups: Lookups
      onChange: (newToken: string | null) => Promise<unknown>
    }
)

export const AvatarImage: React.FC<AvatarImageProps> = ({ item: { name, avatar }, size, tooltip, ...editProps }) => {
  const theme = useTheme()
  const [isEditing, startEditing, stopEditing] = useBooleanState()
  const [isHovering, startHovering, stopHovering] = useBooleanState()

  let finalTooltip = tooltip ? name : ''
  if (editProps.canEdit) {
    finalTooltip += ' (click to edit image)'
  }

  return (
    <>
      {withTooltip(
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onMouseOver={startHovering}
          onMouseOut={stopHovering}
          badgeContent={
            editProps.canEdit && (
              <EditIcon
                color="inherit"
                sx={{
                  opacity: isHovering ? 1 : 0,
                  transition: '250ms opacity',
                  fontSize: '0.9rem',
                }}
              />
            )
          }
        >
          <Avatar
            src={avatar as string}
            onClick={editProps.canEdit ? startEditing : noop}
            sx={{
              width: size,
              height: size,
              backgroundColor: theme.palette.primary.light,
              border: `2px solid ${theme.palette.primary.dark}`,
            }}
          >
            <Person sx={{ width: '80%', height: '80%' }} />
          </Avatar>
        </Badge>,
        finalTooltip
      )}
      {editProps.canEdit && (
        <ImageManagerDialog
          title="Edit Avatar Image"
          getUploadTarget={editProps.getUploadTarget}
          open={isEditing}
          onClose={stopEditing}
          lookups={editProps.lookups}
          Preview={({ src }: { src: string }) => (
            <img
              src={src}
              style={{
                height: 200,
                width: 200,
                borderRadius: 200 / 2,
                border: '1px solid black',
                objectFit: 'cover',
              }}
            />
          )}
          onTokenChange={editProps.onChange}
          url={avatar as string}
        />
      )}
    </>
  )
}
