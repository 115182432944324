import { SearchViewTypeEnum } from '@riverscapes/react-common'

export interface SavedSearchDetailProps extends JSX.IntrinsicAttributes {
  id: string
  tab?: string
  viewType?: SearchViewTypeEnum
}

// export const SavedSearchDetail: React.FC<SavedSearchDetailProps> = ({ id, tab = 'overview' }) => {
export const SavedSearchDetail: React.FC = () => {
  return <div>TODO: SavedSearch</div>
}
