import { Box, ButtonProps, CircularProgress, IconButton, Typography, useTheme } from '@mui/material'
import { withTooltip } from '../../lib'
import { DownloadIcon } from '../Icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export interface DownloadButtonProps extends ButtonProps {
  className?: string
  pendingSince?: number
  onClick: () => void
  sizePx?: number
  title: string
}

export interface DownloadTooltipProps {
  btnPending: boolean
  pendingSince?: number
}

export const DownloadTooltip: React.FC<DownloadTooltipProps> = ({ btnPending, pendingSince }) => {
  return btnPending ? (
    <>
      <Typography variant="body1">Preparing Zip</Typography>
      <Typography variant="caption" paragraph>
        The exchange is building your zip file for you.
      </Typography>
      <Typography variant="caption" paragraph>
        started {dayjs(pendingSince).fromNow()}
      </Typography>
      <Typography variant="caption" paragraph>
        This usually takes only a minute or two, depending heavily on the size of your project. When it&#39;s ready, you
        you should get a &#39;download file&#39; popup.
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="body1">Download Project Zip</Typography>
      <Typography variant="caption" paragraph>
        Click to download this project as a single zip file
      </Typography>
      <Typography variant="caption" paragraph>
        There may be a delay if the zip file needs to be built.
      </Typography>
    </>
  )
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  title: _title,
  className,
  onClick,
  sizePx,
  pendingSince,
  ...restProps
}) => {
  const theme = useTheme()
  const btnPending = Boolean(pendingSince)

  if (btnPending)
    return withTooltip(
      <IconButton
        size={'large'}
        {...restProps}
        onClick={onClick}
        disabled
        color="primary"
        sx={{ p: 0, ...restProps.sx, width: sizePx, height: sizePx }}
      >
        <CircularProgress
          variant="indeterminate"
          size={sizePx}
          sx={{
            color: theme.palette.warning.light,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DownloadIcon
            sx={{
              color: theme.palette.warning.light,
              width: sizePx,
              height: sizePx,
            }}
          />
        </Box>
      </IconButton>,
      <DownloadTooltip btnPending={btnPending} pendingSince={pendingSince} />,
      true
    )
  else
    return withTooltip(
      <IconButton size="large" color="primary" {...restProps} onClick={onClick}>
        <DownloadIcon className={className} />
      </IconButton>,
      <DownloadTooltip btnPending={btnPending} pendingSince={pendingSince} />
    )
}
