import { selector } from 'recoil'
import { viewportAtom, renderableMapLayersSelector, activeViewAtom, baseLayerAtom } from './map'
import { drawerOpenAtom } from './project'
import { AppUrlState } from '../types'
import { appDefaults } from '../config'
import log from 'loglevel'
/**
 * This is the selector for divining the URL params we use in our share
 */
export const urlParamsSelector = selector<AppUrlState>({
  key: 'url/params',
  get: ({ get }) => {
    const viewport = get(viewportAtom)
    const baseLayer = get(baseLayerAtom)
    const currentView = get(activeViewAtom)
    const drawerOpen = get(drawerOpenAtom)
    const layers = get(renderableMapLayersSelector)

    const retVal: AppUrlState = {}
    if (!drawerOpen) retVal.d = 0
    if (viewport) retVal.c = [viewport.longitude, viewport.latitude, viewport.zoom, viewport.bearing, viewport.pitch]
    if (baseLayer && baseLayer.id !== appDefaults.baseLayer.id) retVal.b = baseLayer.id
    if (currentView) {
      retVal.v = currentView
    } else {
      // if this is not a view then we need to enumerate the layers and their order
      retVal.l = layers.map((lyr) => lyr.leaf.id)
    }
    log.debug('URLPARAMS:: urlParamsSelector fire', retVal)
    return retVal
  },
})
