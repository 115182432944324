import { AtomEffect } from 'recoil'

export const localStorageAtomEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet, trigger }) => {
    if (trigger === 'get') {
      try {
        const storedValue = localStorage.getItem(key)
        if (storedValue) setSelf(JSON.parse(storedValue))
      } catch (e) {
        // ignore error (default will be used)
      }
    }

    onSet((newValue) => {
      localStorage.setItem(key, JSON.stringify(newValue))
    })
  }
