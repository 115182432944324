import React from 'react'
import highlightWords from 'highlight-words'

interface TreeNodeLabelProps {
  text: string
  query?: string
}

export const TreeNodeLabel: React.FC<TreeNodeLabelProps> = ({ text, query }: TreeNodeLabelProps) => {
  if (!query || query.length === 0) return <span>{text}</span>
  const sanitized = query
    .split(' ')
    .map((t) => t.trim())
    .filter((t) => t && t.length > 0)
    .join(' ')
  const chunks = highlightWords({ text, query: sanitized })

  return (
    <span>
      {chunks.map((c, idx) => (
        <span
          key={`textMatch-${idx}`}
          style={
            c.match
              ? {
                  fontWeight: 'bold',
                }
              : {
                  fontStyle: 'italic',
                  opacity: 0.8,
                }
          }
        >
          {c.text}
        </span>
      ))}
    </span>
  )
}

export default TreeNodeLabel
