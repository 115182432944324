import * as types from './base'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const CollectionCardFragmentDoc = gql`
    fragment CollectionCard on Collection {
  __typename
  id
  name
  projects(offset: 0, limit: 0) {
    total
  }
  visibility
  permissions {
    delete
    update
    view
  }
  summary
  heroImage {
    sm
  }
}
    `;
export const OrganizationCardFragmentDoc = gql`
    fragment OrganizationCard on Organization {
  __typename
  id
  name
  logo
  myRole
  summary
  projects(offset: 0, limit: 0) {
    total
  }
  organizationUsers(offset: 0, limit: 0) {
    total
  }
  permissions {
    delete
    update
    view
  }
}
    `;
export const ProjectCardFragmentDoc = gql`
    fragment ProjectCard on Project {
  __typename
  id
  name
  deleted
  projectType {
    id
    name
    logo
  }
  visibility
  summary
  bounds {
    id
    polygonUrl
    bbox
  }
  meta {
    key
    value
  }
  tags
  totalSize
  updatedOn
  ownedBy {
    __typename
    ... on User {
      id
      name
      avatar
    }
    ... on Organization {
      id
      name
      logo
    }
  }
  heroImage {
    sm
  }
}
    `;
export const SavedSearchCardFragmentDoc = gql`
    fragment SavedSearchCard on SavedSearch {
  __typename
  id
  name
}
    `;
export const UserCardFragmentDoc = gql`
    fragment UserCard on User {
  __typename
  id
  name
  summary
  avatar
  projects(offset: 0, limit: 0) {
    total
  }
}
    `;
export const ProjectTypeItemFragmentDoc = gql`
    fragment ProjectTypeItem on ProjectType {
  id
  name
  summary
  description
  machineName
  logo
  state
  url
  createdOn
  updatedOn
  meta {
    key
    type
    value
    locked
    ext
  }
  __typename
}
    `;
export const AddCollectionProjectsDocument = gql`
    mutation AddCollectionProjects($collectionId: ID!, $projectIds: [ID!]!) {
  addCollectionProjects(collectionId: $collectionId, projectIds: $projectIds) {
    __typename
    id
    projects(limit: 0, offset: 0) {
      total
    }
  }
}
    `;
export type AddCollectionProjectsMutationFn = Apollo.MutationFunction<types.AddCollectionProjectsMutation, types.AddCollectionProjectsMutationVariables>;

/**
 * __useAddCollectionProjectsMutation__
 *
 * To run a mutation, you first call `useAddCollectionProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollectionProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollectionProjectsMutation, { data, loading, error }] = useAddCollectionProjectsMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useAddCollectionProjectsMutation(baseOptions?: Apollo.MutationHookOptions<types.AddCollectionProjectsMutation, types.AddCollectionProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.AddCollectionProjectsMutation, types.AddCollectionProjectsMutationVariables>(AddCollectionProjectsDocument, options);
      }
export type AddCollectionProjectsMutationHookResult = ReturnType<typeof useAddCollectionProjectsMutation>;
export type AddCollectionProjectsMutationResult = Apollo.MutationResult<types.AddCollectionProjectsMutation>;
export type AddCollectionProjectsMutationOptions = Apollo.BaseMutationOptions<types.AddCollectionProjectsMutation, types.AddCollectionProjectsMutationVariables>;
export const CreateCollectionDocument = gql`
    mutation CreateCollection($collection: CollectionInput!, $orgId: ID) {
  createCollection(collection: $collection, orgId: $orgId) {
    id
  }
}
    `;
export type CreateCollectionMutationFn = Apollo.MutationFunction<types.CreateCollectionMutation, types.CreateCollectionMutationVariables>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      collection: // value for 'collection'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateCollectionMutation, types.CreateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateCollectionMutation, types.CreateCollectionMutationVariables>(CreateCollectionDocument, options);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<types.CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<types.CreateCollectionMutation, types.CreateCollectionMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation DeleteCollection($id: ID!) {
  deleteCollection(id: $id) {
    success
  }
}
    `;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<types.DeleteCollectionMutation, types.DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteCollectionMutation, types.DeleteCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteCollectionMutation, types.DeleteCollectionMutationVariables>(DeleteCollectionDocument, options);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<types.DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<types.DeleteCollectionMutation, types.DeleteCollectionMutationVariables>;
export const GetCollectionDetailDocument = gql`
    query GetCollectionDetail($id: ID!) {
  collection(id: $id) {
    __typename
    id
    name
    description
    citation
    visibility
    summary
    starred
    heroImage {
      lg
    }
    createdBy {
      id
      name
      avatar
    }
    updatedBy {
      id
      name
      avatar
    }
    createdOn
    updatedOn
    ownedBy {
      __typename
      ... on User {
        id
        name
        avatar
      }
      ... on Organization {
        id
        name
        logo
      }
    }
    contact {
      __typename
      id
      name
      avatar
    }
    projects(limit: 0, offset: 0) {
      items {
        id
      }
      total
    }
    permissions {
      delete
      update
      view
    }
    meta {
      key
      type
      value
      locked
      ext
    }
    tags
  }
}
    `;

/**
 * __useGetCollectionDetailQuery__
 *
 * To run a query within a React component, call `useGetCollectionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionDetailQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionDetailQuery, types.GetCollectionDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionDetailQuery, types.GetCollectionDetailQueryVariables>(GetCollectionDetailDocument, options);
      }
export function useGetCollectionDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionDetailQuery, types.GetCollectionDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionDetailQuery, types.GetCollectionDetailQueryVariables>(GetCollectionDetailDocument, options);
        }
export type GetCollectionDetailQueryHookResult = ReturnType<typeof useGetCollectionDetailQuery>;
export type GetCollectionDetailLazyQueryHookResult = ReturnType<typeof useGetCollectionDetailLazyQuery>;
export type GetCollectionDetailQueryResult = Apollo.QueryResult<types.GetCollectionDetailQuery, types.GetCollectionDetailQueryVariables>;
export const GetCollectionMapBoundsDocument = gql`
    query GetCollectionMapBounds($id: ID!, $limit: Int!, $bbox: [Float!]!, $minScore: Float) {
  searchMapBounds(
    limit: $limit
    params: {collection: $id, bbox: $bbox}
    minScore: $minScore
  ) {
    bounds {
      bounds {
        __typename
        id
        polygonUrl
        bbox
      }
      projectCount
    }
    remaining
  }
}
    `;

/**
 * __useGetCollectionMapBoundsQuery__
 *
 * To run a query within a React component, call `useGetCollectionMapBoundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionMapBoundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionMapBoundsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      bbox: // value for 'bbox'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetCollectionMapBoundsQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionMapBoundsQuery, types.GetCollectionMapBoundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionMapBoundsQuery, types.GetCollectionMapBoundsQueryVariables>(GetCollectionMapBoundsDocument, options);
      }
export function useGetCollectionMapBoundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionMapBoundsQuery, types.GetCollectionMapBoundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionMapBoundsQuery, types.GetCollectionMapBoundsQueryVariables>(GetCollectionMapBoundsDocument, options);
        }
export type GetCollectionMapBoundsQueryHookResult = ReturnType<typeof useGetCollectionMapBoundsQuery>;
export type GetCollectionMapBoundsLazyQueryHookResult = ReturnType<typeof useGetCollectionMapBoundsLazyQuery>;
export type GetCollectionMapBoundsQueryResult = Apollo.QueryResult<types.GetCollectionMapBoundsQuery, types.GetCollectionMapBoundsQueryVariables>;
export const GetCollectionMapClustersDocument = gql`
    query GetCollectionMapClusters($id: ID!, $bbox: [Float!]!, $precision: Int!, $minScore: Float) {
  searchMapClusters(
    params: {collection: $id, bbox: $bbox}
    limit: 20
    precision: $precision
    minScore: $minScore
  ) {
    clusters {
      coords
      count
      hash
    }
    remaining
  }
}
    `;

/**
 * __useGetCollectionMapClustersQuery__
 *
 * To run a query within a React component, call `useGetCollectionMapClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionMapClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionMapClustersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      bbox: // value for 'bbox'
 *      precision: // value for 'precision'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetCollectionMapClustersQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionMapClustersQuery, types.GetCollectionMapClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionMapClustersQuery, types.GetCollectionMapClustersQueryVariables>(GetCollectionMapClustersDocument, options);
      }
export function useGetCollectionMapClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionMapClustersQuery, types.GetCollectionMapClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionMapClustersQuery, types.GetCollectionMapClustersQueryVariables>(GetCollectionMapClustersDocument, options);
        }
export type GetCollectionMapClustersQueryHookResult = ReturnType<typeof useGetCollectionMapClustersQuery>;
export type GetCollectionMapClustersLazyQueryHookResult = ReturnType<typeof useGetCollectionMapClustersLazyQuery>;
export type GetCollectionMapClustersQueryResult = Apollo.QueryResult<types.GetCollectionMapClustersQuery, types.GetCollectionMapClustersQueryVariables>;
export const GetCollectionProjectsDocument = gql`
    query GetCollectionProjects($id: ID!, $limit: Int!, $offset: Int!, $sort: [SearchSortEnum!]) {
  collection(id: $id) {
    id
    projects(limit: $limit, offset: $offset, sort: $sort) {
      items {
        ...ProjectCard
      }
      total
    }
  }
}
    ${ProjectCardFragmentDoc}`;

/**
 * __useGetCollectionProjectsQuery__
 *
 * To run a query within a React component, call `useGetCollectionProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCollectionProjectsQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionProjectsQuery, types.GetCollectionProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionProjectsQuery, types.GetCollectionProjectsQueryVariables>(GetCollectionProjectsDocument, options);
      }
export function useGetCollectionProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionProjectsQuery, types.GetCollectionProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionProjectsQuery, types.GetCollectionProjectsQueryVariables>(GetCollectionProjectsDocument, options);
        }
export type GetCollectionProjectsQueryHookResult = ReturnType<typeof useGetCollectionProjectsQuery>;
export type GetCollectionProjectsLazyQueryHookResult = ReturnType<typeof useGetCollectionProjectsLazyQuery>;
export type GetCollectionProjectsQueryResult = Apollo.QueryResult<types.GetCollectionProjectsQuery, types.GetCollectionProjectsQueryVariables>;
export const RemoveCollectionProjectsDocument = gql`
    mutation RemoveCollectionProjects($collectionId: ID!, $projectIds: [ID!]!) {
  removeCollectionProjects(collectionId: $collectionId, projectIds: $projectIds) {
    __typename
    id
    projects(limit: 0, offset: 0) {
      total
    }
  }
}
    `;
export type RemoveCollectionProjectsMutationFn = Apollo.MutationFunction<types.RemoveCollectionProjectsMutation, types.RemoveCollectionProjectsMutationVariables>;

/**
 * __useRemoveCollectionProjectsMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionProjectsMutation, { data, loading, error }] = useRemoveCollectionProjectsMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useRemoveCollectionProjectsMutation(baseOptions?: Apollo.MutationHookOptions<types.RemoveCollectionProjectsMutation, types.RemoveCollectionProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RemoveCollectionProjectsMutation, types.RemoveCollectionProjectsMutationVariables>(RemoveCollectionProjectsDocument, options);
      }
export type RemoveCollectionProjectsMutationHookResult = ReturnType<typeof useRemoveCollectionProjectsMutation>;
export type RemoveCollectionProjectsMutationResult = Apollo.MutationResult<types.RemoveCollectionProjectsMutation>;
export type RemoveCollectionProjectsMutationOptions = Apollo.BaseMutationOptions<types.RemoveCollectionProjectsMutation, types.RemoveCollectionProjectsMutationVariables>;
export const UpdateCollectionDescriptionDocument = gql`
    mutation UpdateCollectionDescription($id: ID!, $description: String!) {
  updateCollection(id: $id, collection: {description: $description}) {
    __typename
    id
    description
  }
}
    `;
export type UpdateCollectionDescriptionMutationFn = Apollo.MutationFunction<types.UpdateCollectionDescriptionMutation, types.UpdateCollectionDescriptionMutationVariables>;

/**
 * __useUpdateCollectionDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionDescriptionMutation, { data, loading, error }] = useUpdateCollectionDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCollectionDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionDescriptionMutation, types.UpdateCollectionDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionDescriptionMutation, types.UpdateCollectionDescriptionMutationVariables>(UpdateCollectionDescriptionDocument, options);
      }
export type UpdateCollectionDescriptionMutationHookResult = ReturnType<typeof useUpdateCollectionDescriptionMutation>;
export type UpdateCollectionDescriptionMutationResult = Apollo.MutationResult<types.UpdateCollectionDescriptionMutation>;
export type UpdateCollectionDescriptionMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionDescriptionMutation, types.UpdateCollectionDescriptionMutationVariables>;
export const UpdateCollectionNameDocument = gql`
    mutation UpdateCollectionName($id: ID!, $name: String!) {
  updateCollection(id: $id, collection: {name: $name}) {
    __typename
    id
    name
  }
}
    `;
export type UpdateCollectionNameMutationFn = Apollo.MutationFunction<types.UpdateCollectionNameMutation, types.UpdateCollectionNameMutationVariables>;

/**
 * __useUpdateCollectionNameMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionNameMutation, { data, loading, error }] = useUpdateCollectionNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCollectionNameMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionNameMutation, types.UpdateCollectionNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionNameMutation, types.UpdateCollectionNameMutationVariables>(UpdateCollectionNameDocument, options);
      }
export type UpdateCollectionNameMutationHookResult = ReturnType<typeof useUpdateCollectionNameMutation>;
export type UpdateCollectionNameMutationResult = Apollo.MutationResult<types.UpdateCollectionNameMutation>;
export type UpdateCollectionNameMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionNameMutation, types.UpdateCollectionNameMutationVariables>;
export const UpdateCollectionSummaryDocument = gql`
    mutation UpdateCollectionSummary($id: ID!, $summary: String!) {
  updateCollection(id: $id, collection: {summary: $summary}) {
    __typename
    id
    summary
  }
}
    `;
export type UpdateCollectionSummaryMutationFn = Apollo.MutationFunction<types.UpdateCollectionSummaryMutation, types.UpdateCollectionSummaryMutationVariables>;

/**
 * __useUpdateCollectionSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionSummaryMutation, { data, loading, error }] = useUpdateCollectionSummaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateCollectionSummaryMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionSummaryMutation, types.UpdateCollectionSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionSummaryMutation, types.UpdateCollectionSummaryMutationVariables>(UpdateCollectionSummaryDocument, options);
      }
export type UpdateCollectionSummaryMutationHookResult = ReturnType<typeof useUpdateCollectionSummaryMutation>;
export type UpdateCollectionSummaryMutationResult = Apollo.MutationResult<types.UpdateCollectionSummaryMutation>;
export type UpdateCollectionSummaryMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionSummaryMutation, types.UpdateCollectionSummaryMutationVariables>;
export const UpdateCollectionCitationDocument = gql`
    mutation UpdateCollectionCitation($id: ID!, $citation: String!) {
  updateCollection(id: $id, collection: {citation: $citation}) {
    __typename
    id
    citation
  }
}
    `;
export type UpdateCollectionCitationMutationFn = Apollo.MutationFunction<types.UpdateCollectionCitationMutation, types.UpdateCollectionCitationMutationVariables>;

/**
 * __useUpdateCollectionCitationMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionCitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionCitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionCitationMutation, { data, loading, error }] = useUpdateCollectionCitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      citation: // value for 'citation'
 *   },
 * });
 */
export function useUpdateCollectionCitationMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionCitationMutation, types.UpdateCollectionCitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionCitationMutation, types.UpdateCollectionCitationMutationVariables>(UpdateCollectionCitationDocument, options);
      }
export type UpdateCollectionCitationMutationHookResult = ReturnType<typeof useUpdateCollectionCitationMutation>;
export type UpdateCollectionCitationMutationResult = Apollo.MutationResult<types.UpdateCollectionCitationMutation>;
export type UpdateCollectionCitationMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionCitationMutation, types.UpdateCollectionCitationMutationVariables>;
export const UpdateCollectionVisibilityDocument = gql`
    mutation UpdateCollectionVisibility($id: ID!, $visibility: ProjectGroupVisibilityEnum!) {
  updateCollection(id: $id, collection: {visibility: $visibility}) {
    __typename
    id
    visibility
  }
}
    `;
export type UpdateCollectionVisibilityMutationFn = Apollo.MutationFunction<types.UpdateCollectionVisibilityMutation, types.UpdateCollectionVisibilityMutationVariables>;

/**
 * __useUpdateCollectionVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionVisibilityMutation, { data, loading, error }] = useUpdateCollectionVisibilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useUpdateCollectionVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionVisibilityMutation, types.UpdateCollectionVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionVisibilityMutation, types.UpdateCollectionVisibilityMutationVariables>(UpdateCollectionVisibilityDocument, options);
      }
export type UpdateCollectionVisibilityMutationHookResult = ReturnType<typeof useUpdateCollectionVisibilityMutation>;
export type UpdateCollectionVisibilityMutationResult = Apollo.MutationResult<types.UpdateCollectionVisibilityMutation>;
export type UpdateCollectionVisibilityMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionVisibilityMutation, types.UpdateCollectionVisibilityMutationVariables>;
export const UpdateCollectionTagsDocument = gql`
    mutation UpdateCollectionTags($id: ID!, $tags: [String!]!) {
  updateCollection(id: $id, collection: {tags: $tags}) {
    __typename
    id
    tags
  }
}
    `;
export type UpdateCollectionTagsMutationFn = Apollo.MutationFunction<types.UpdateCollectionTagsMutation, types.UpdateCollectionTagsMutationVariables>;

/**
 * __useUpdateCollectionTagsMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionTagsMutation, { data, loading, error }] = useUpdateCollectionTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateCollectionTagsMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionTagsMutation, types.UpdateCollectionTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionTagsMutation, types.UpdateCollectionTagsMutationVariables>(UpdateCollectionTagsDocument, options);
      }
export type UpdateCollectionTagsMutationHookResult = ReturnType<typeof useUpdateCollectionTagsMutation>;
export type UpdateCollectionTagsMutationResult = Apollo.MutationResult<types.UpdateCollectionTagsMutation>;
export type UpdateCollectionTagsMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionTagsMutation, types.UpdateCollectionTagsMutationVariables>;
export const UpdateCollectionHeroImageDocument = gql`
    mutation UpdateCollectionHeroImage($id: ID!, $token: String, $clear: Boolean) {
  updateCollection(
    id: $id
    collection: {heroImageToken: $token, clearHeroImage: $clear}
  ) {
    __typename
    id
    heroImage {
      sm
      md
      lg
    }
  }
}
    `;
export type UpdateCollectionHeroImageMutationFn = Apollo.MutationFunction<types.UpdateCollectionHeroImageMutation, types.UpdateCollectionHeroImageMutationVariables>;

/**
 * __useUpdateCollectionHeroImageMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionHeroImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionHeroImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionHeroImageMutation, { data, loading, error }] = useUpdateCollectionHeroImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *      clear: // value for 'clear'
 *   },
 * });
 */
export function useUpdateCollectionHeroImageMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionHeroImageMutation, types.UpdateCollectionHeroImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionHeroImageMutation, types.UpdateCollectionHeroImageMutationVariables>(UpdateCollectionHeroImageDocument, options);
      }
export type UpdateCollectionHeroImageMutationHookResult = ReturnType<typeof useUpdateCollectionHeroImageMutation>;
export type UpdateCollectionHeroImageMutationResult = Apollo.MutationResult<types.UpdateCollectionHeroImageMutation>;
export type UpdateCollectionHeroImageMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionHeroImageMutation, types.UpdateCollectionHeroImageMutationVariables>;
export const UpdateCollectionMetaDocument = gql`
    mutation UpdateCollectionMeta($id: ID!, $meta: [MetaDataInput!]!) {
  updateCollection(id: $id, collection: {meta: $meta}) {
    __typename
    id
    meta {
      key
      type
      value
      locked
      ext
    }
  }
}
    `;
export type UpdateCollectionMetaMutationFn = Apollo.MutationFunction<types.UpdateCollectionMetaMutation, types.UpdateCollectionMetaMutationVariables>;

/**
 * __useUpdateCollectionMetaMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMetaMutation, { data, loading, error }] = useUpdateCollectionMetaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useUpdateCollectionMetaMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCollectionMetaMutation, types.UpdateCollectionMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCollectionMetaMutation, types.UpdateCollectionMetaMutationVariables>(UpdateCollectionMetaDocument, options);
      }
export type UpdateCollectionMetaMutationHookResult = ReturnType<typeof useUpdateCollectionMetaMutation>;
export type UpdateCollectionMetaMutationResult = Apollo.MutationResult<types.UpdateCollectionMetaMutation>;
export type UpdateCollectionMetaMutationOptions = Apollo.BaseMutationOptions<types.UpdateCollectionMetaMutation, types.UpdateCollectionMetaMutationVariables>;
export const GetProjectDatasetsDocument = gql`
    query GetProjectDatasets($id: ID!, $offset: Int!, $limit: Int!) {
  project(id: $id) {
    id
    deleted
    datasets(limit: $limit, offset: $offset) {
      items {
        __typename
        id
        name
        datasetType
        layers {
          name
        }
        project {
          id
          name
          projectType {
            id
            name
            logo
          }
        }
        rsXPath
        datasetType
        datasetContainer
        realizationXMLId
        datasetXMLId
        analysisId
      }
      total
    }
  }
}
    `;

/**
 * __useGetProjectDatasetsQuery__
 *
 * To run a query within a React component, call `useGetProjectDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDatasetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProjectDatasetsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectDatasetsQuery, types.GetProjectDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectDatasetsQuery, types.GetProjectDatasetsQueryVariables>(GetProjectDatasetsDocument, options);
      }
export function useGetProjectDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectDatasetsQuery, types.GetProjectDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectDatasetsQuery, types.GetProjectDatasetsQueryVariables>(GetProjectDatasetsDocument, options);
        }
export type GetProjectDatasetsQueryHookResult = ReturnType<typeof useGetProjectDatasetsQuery>;
export type GetProjectDatasetsLazyQueryHookResult = ReturnType<typeof useGetProjectDatasetsLazyQuery>;
export type GetProjectDatasetsQueryResult = Apollo.QueryResult<types.GetProjectDatasetsQuery, types.GetProjectDatasetsQueryVariables>;
export const UpdateStarDocument = gql`
    mutation UpdateStar($id: ID!, $type: StarrableTypesEnum!, $starred: Boolean!) {
  updateStar(id: $id, type: $type, starred: $starred) {
    success
  }
}
    `;
export type UpdateStarMutationFn = Apollo.MutationFunction<types.UpdateStarMutation, types.UpdateStarMutationVariables>;

/**
 * __useUpdateStarMutation__
 *
 * To run a mutation, you first call `useUpdateStarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStarMutation, { data, loading, error }] = useUpdateStarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useUpdateStarMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateStarMutation, types.UpdateStarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateStarMutation, types.UpdateStarMutationVariables>(UpdateStarDocument, options);
      }
export type UpdateStarMutationHookResult = ReturnType<typeof useUpdateStarMutation>;
export type UpdateStarMutationResult = Apollo.MutationResult<types.UpdateStarMutation>;
export type UpdateStarMutationOptions = Apollo.BaseMutationOptions<types.UpdateStarMutation, types.UpdateStarMutationVariables>;
export const GetImageUploadStatusDocument = gql`
    query GetImageUploadStatus($token: String!) {
  checkUpload(token: $token) {
    errors
    status
  }
}
    `;

/**
 * __useGetImageUploadStatusQuery__
 *
 * To run a query within a React component, call `useGetImageUploadStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageUploadStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageUploadStatusQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetImageUploadStatusQuery(baseOptions: Apollo.QueryHookOptions<types.GetImageUploadStatusQuery, types.GetImageUploadStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetImageUploadStatusQuery, types.GetImageUploadStatusQueryVariables>(GetImageUploadStatusDocument, options);
      }
export function useGetImageUploadStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetImageUploadStatusQuery, types.GetImageUploadStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetImageUploadStatusQuery, types.GetImageUploadStatusQueryVariables>(GetImageUploadStatusDocument, options);
        }
export type GetImageUploadStatusQueryHookResult = ReturnType<typeof useGetImageUploadStatusQuery>;
export type GetImageUploadStatusLazyQueryHookResult = ReturnType<typeof useGetImageUploadStatusLazyQuery>;
export type GetImageUploadStatusQueryResult = Apollo.QueryResult<types.GetImageUploadStatusQuery, types.GetImageUploadStatusQueryVariables>;
export const GetImageUploadTargetDocument = gql`
    query GetImageUploadTarget($entityType: EntitiesWithImagesEnum!, $entityId: ID!) {
  requestUploadImage(entityId: $entityId, entityType: $entityType) {
    url
    token
    fields
  }
}
    `;

/**
 * __useGetImageUploadTargetQuery__
 *
 * To run a query within a React component, call `useGetImageUploadTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageUploadTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageUploadTargetQuery({
 *   variables: {
 *      entityType: // value for 'entityType'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetImageUploadTargetQuery(baseOptions: Apollo.QueryHookOptions<types.GetImageUploadTargetQuery, types.GetImageUploadTargetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetImageUploadTargetQuery, types.GetImageUploadTargetQueryVariables>(GetImageUploadTargetDocument, options);
      }
export function useGetImageUploadTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetImageUploadTargetQuery, types.GetImageUploadTargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetImageUploadTargetQuery, types.GetImageUploadTargetQueryVariables>(GetImageUploadTargetDocument, options);
        }
export type GetImageUploadTargetQueryHookResult = ReturnType<typeof useGetImageUploadTargetQuery>;
export type GetImageUploadTargetLazyQueryHookResult = ReturnType<typeof useGetImageUploadTargetLazyQuery>;
export type GetImageUploadTargetQueryResult = Apollo.QueryResult<types.GetImageUploadTargetQuery, types.GetImageUploadTargetQueryVariables>;
export const GetCollectionCardDocument = gql`
    query GetCollectionCard($id: ID!) {
  collection(id: $id) {
    ...CollectionCard
  }
}
    ${CollectionCardFragmentDoc}`;

/**
 * __useGetCollectionCardQuery__
 *
 * To run a query within a React component, call `useGetCollectionCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionCardQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionCardQuery, types.GetCollectionCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionCardQuery, types.GetCollectionCardQueryVariables>(GetCollectionCardDocument, options);
      }
export function useGetCollectionCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionCardQuery, types.GetCollectionCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionCardQuery, types.GetCollectionCardQueryVariables>(GetCollectionCardDocument, options);
        }
export type GetCollectionCardQueryHookResult = ReturnType<typeof useGetCollectionCardQuery>;
export type GetCollectionCardLazyQueryHookResult = ReturnType<typeof useGetCollectionCardLazyQuery>;
export type GetCollectionCardQueryResult = Apollo.QueryResult<types.GetCollectionCardQuery, types.GetCollectionCardQueryVariables>;
export const GetOrganizationCardDocument = gql`
    query GetOrganizationCard($id: ID!) {
  organization(id: $id) {
    ...OrganizationCard
  }
}
    ${OrganizationCardFragmentDoc}`;

/**
 * __useGetOrganizationCardQuery__
 *
 * To run a query within a React component, call `useGetOrganizationCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationCardQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationCardQuery, types.GetOrganizationCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationCardQuery, types.GetOrganizationCardQueryVariables>(GetOrganizationCardDocument, options);
      }
export function useGetOrganizationCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationCardQuery, types.GetOrganizationCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationCardQuery, types.GetOrganizationCardQueryVariables>(GetOrganizationCardDocument, options);
        }
export type GetOrganizationCardQueryHookResult = ReturnType<typeof useGetOrganizationCardQuery>;
export type GetOrganizationCardLazyQueryHookResult = ReturnType<typeof useGetOrganizationCardLazyQuery>;
export type GetOrganizationCardQueryResult = Apollo.QueryResult<types.GetOrganizationCardQuery, types.GetOrganizationCardQueryVariables>;
export const GetProjectCardDocument = gql`
    query GetProjectCard($id: ID!) {
  project(id: $id) {
    ...ProjectCard
  }
}
    ${ProjectCardFragmentDoc}`;

/**
 * __useGetProjectCardQuery__
 *
 * To run a query within a React component, call `useGetProjectCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectCardQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectCardQuery, types.GetProjectCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectCardQuery, types.GetProjectCardQueryVariables>(GetProjectCardDocument, options);
      }
export function useGetProjectCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectCardQuery, types.GetProjectCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectCardQuery, types.GetProjectCardQueryVariables>(GetProjectCardDocument, options);
        }
export type GetProjectCardQueryHookResult = ReturnType<typeof useGetProjectCardQuery>;
export type GetProjectCardLazyQueryHookResult = ReturnType<typeof useGetProjectCardLazyQuery>;
export type GetProjectCardQueryResult = Apollo.QueryResult<types.GetProjectCardQuery, types.GetProjectCardQueryVariables>;
export const GetSavedSearchCardDocument = gql`
    query GetSavedSearchCard($id: ID!) {
  savedSearch(id: $id) {
    __typename
    id
    name
  }
}
    `;

/**
 * __useGetSavedSearchCardQuery__
 *
 * To run a query within a React component, call `useGetSavedSearchCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedSearchCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedSearchCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSavedSearchCardQuery(baseOptions: Apollo.QueryHookOptions<types.GetSavedSearchCardQuery, types.GetSavedSearchCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSavedSearchCardQuery, types.GetSavedSearchCardQueryVariables>(GetSavedSearchCardDocument, options);
      }
export function useGetSavedSearchCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSavedSearchCardQuery, types.GetSavedSearchCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSavedSearchCardQuery, types.GetSavedSearchCardQueryVariables>(GetSavedSearchCardDocument, options);
        }
export type GetSavedSearchCardQueryHookResult = ReturnType<typeof useGetSavedSearchCardQuery>;
export type GetSavedSearchCardLazyQueryHookResult = ReturnType<typeof useGetSavedSearchCardLazyQuery>;
export type GetSavedSearchCardQueryResult = Apollo.QueryResult<types.GetSavedSearchCardQuery, types.GetSavedSearchCardQueryVariables>;
export const GetUserCardDocument = gql`
    query GetUserCard($id: ID!) {
  user(id: $id) {
    ...UserCard
  }
}
    ${UserCardFragmentDoc}`;

/**
 * __useGetUserCardQuery__
 *
 * To run a query within a React component, call `useGetUserCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserCardQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserCardQuery, types.GetUserCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserCardQuery, types.GetUserCardQueryVariables>(GetUserCardDocument, options);
      }
export function useGetUserCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserCardQuery, types.GetUserCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserCardQuery, types.GetUserCardQueryVariables>(GetUserCardDocument, options);
        }
export type GetUserCardQueryHookResult = ReturnType<typeof useGetUserCardQuery>;
export type GetUserCardLazyQueryHookResult = ReturnType<typeof useGetUserCardLazyQuery>;
export type GetUserCardQueryResult = Apollo.QueryResult<types.GetUserCardQuery, types.GetUserCardQueryVariables>;
export const ActionOrganizationInviteDocument = gql`
    mutation ActionOrganizationInvite($id: ID!, $accept: Boolean!, $role: OrganizationInviteRoleEnum) {
  actionOrganizationInvite(id: $id, accept: $accept, role: $role) {
    id
    state
    email
    role
    invitee {
      id
      name
      avatar
    }
  }
}
    `;
export type ActionOrganizationInviteMutationFn = Apollo.MutationFunction<types.ActionOrganizationInviteMutation, types.ActionOrganizationInviteMutationVariables>;

/**
 * __useActionOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useActionOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOrganizationInviteMutation, { data, loading, error }] = useActionOrganizationInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accept: // value for 'accept'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useActionOrganizationInviteMutation(baseOptions?: Apollo.MutationHookOptions<types.ActionOrganizationInviteMutation, types.ActionOrganizationInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ActionOrganizationInviteMutation, types.ActionOrganizationInviteMutationVariables>(ActionOrganizationInviteDocument, options);
      }
export type ActionOrganizationInviteMutationHookResult = ReturnType<typeof useActionOrganizationInviteMutation>;
export type ActionOrganizationInviteMutationResult = Apollo.MutationResult<types.ActionOrganizationInviteMutation>;
export type ActionOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<types.ActionOrganizationInviteMutation, types.ActionOrganizationInviteMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($name: String!) {
  createOrganization(organization: {name: $name}) {
    __typename
    id
    name
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<types.CreateOrganizationMutation, types.CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateOrganizationMutation, types.CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateOrganizationMutation, types.CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<types.CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<types.CreateOrganizationMutation, types.CreateOrganizationMutationVariables>;
export const CreateOrganizationInviteDocument = gql`
    mutation CreateOrganizationInvite($organizationId: ID!, $userId: ID, $email: String, $role: OrganizationInviteRoleEnum!) {
  createOrganizationInvite(
    organizationId: $organizationId
    email: $email
    role: $role
    userId: $userId
  ) {
    id
    email
    invitee {
      __typename
      id
      name
      avatar
    }
    inviter {
      id
      name
    }
    state
    role
  }
}
    `;
export type CreateOrganizationInviteMutationFn = Apollo.MutationFunction<types.CreateOrganizationInviteMutation, types.CreateOrganizationInviteMutationVariables>;

/**
 * __useCreateOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationInviteMutation, { data, loading, error }] = useCreateOrganizationInviteMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateOrganizationInviteMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateOrganizationInviteMutation, types.CreateOrganizationInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateOrganizationInviteMutation, types.CreateOrganizationInviteMutationVariables>(CreateOrganizationInviteDocument, options);
      }
export type CreateOrganizationInviteMutationHookResult = ReturnType<typeof useCreateOrganizationInviteMutation>;
export type CreateOrganizationInviteMutationResult = Apollo.MutationResult<types.CreateOrganizationInviteMutation>;
export type CreateOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<types.CreateOrganizationInviteMutation, types.CreateOrganizationInviteMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($id: ID!) {
  deleteOrganization(id: $id) {
    success
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<types.DeleteOrganizationMutation, types.DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteOrganizationMutation, types.DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteOrganizationMutation, types.DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<types.DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<types.DeleteOrganizationMutation, types.DeleteOrganizationMutationVariables>;
export const DeleteOrganizationInviteDocument = gql`
    mutation DeleteOrganizationInvite($id: ID!) {
  deleteOrganizationInvite(id: $id) {
    success
  }
}
    `;
export type DeleteOrganizationInviteMutationFn = Apollo.MutationFunction<types.DeleteOrganizationInviteMutation, types.DeleteOrganizationInviteMutationVariables>;

/**
 * __useDeleteOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationInviteMutation, { data, loading, error }] = useDeleteOrganizationInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationInviteMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteOrganizationInviteMutation, types.DeleteOrganizationInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteOrganizationInviteMutation, types.DeleteOrganizationInviteMutationVariables>(DeleteOrganizationInviteDocument, options);
      }
export type DeleteOrganizationInviteMutationHookResult = ReturnType<typeof useDeleteOrganizationInviteMutation>;
export type DeleteOrganizationInviteMutationResult = Apollo.MutationResult<types.DeleteOrganizationInviteMutation>;
export type DeleteOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<types.DeleteOrganizationInviteMutation, types.DeleteOrganizationInviteMutationVariables>;
export const GetOrganizationCollectionsDocument = gql`
    query GetOrganizationCollections($id: ID!, $limit: Int!, $offset: Int!, $sort: [SearchSortEnum!]) {
  organization(id: $id) {
    __typename
    id
    collections(limit: $limit, offset: $offset, sort: $sort) {
      items {
        ...CollectionCard
      }
      total
    }
  }
}
    ${CollectionCardFragmentDoc}`;

/**
 * __useGetOrganizationCollectionsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationCollectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetOrganizationCollectionsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationCollectionsQuery, types.GetOrganizationCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationCollectionsQuery, types.GetOrganizationCollectionsQueryVariables>(GetOrganizationCollectionsDocument, options);
      }
export function useGetOrganizationCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationCollectionsQuery, types.GetOrganizationCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationCollectionsQuery, types.GetOrganizationCollectionsQueryVariables>(GetOrganizationCollectionsDocument, options);
        }
export type GetOrganizationCollectionsQueryHookResult = ReturnType<typeof useGetOrganizationCollectionsQuery>;
export type GetOrganizationCollectionsLazyQueryHookResult = ReturnType<typeof useGetOrganizationCollectionsLazyQuery>;
export type GetOrganizationCollectionsQueryResult = Apollo.QueryResult<types.GetOrganizationCollectionsQuery, types.GetOrganizationCollectionsQueryVariables>;
export const GetOrganizationDetailDocument = gql`
    query GetOrganizationDetail($id: ID!) {
  organization(id: $id) {
    __typename
    id
    name
    description
    summary
    starred
    createdBy {
      id
      name
    }
    projects(offset: 0, limit: 0) {
      total
    }
    collections(offset: 0, limit: 0) {
      total
    }
    logo
    myRole
    permissions {
      update
      delete
      view
    }
    organizationUsers(offset: 0, limit: 0) {
      total
    }
    organizationInvites(offset: 0, limit: 0) {
      total
    }
  }
}
    `;

/**
 * __useGetOrganizationDetailQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDetailQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationDetailQuery, types.GetOrganizationDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationDetailQuery, types.GetOrganizationDetailQueryVariables>(GetOrganizationDetailDocument, options);
      }
export function useGetOrganizationDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationDetailQuery, types.GetOrganizationDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationDetailQuery, types.GetOrganizationDetailQueryVariables>(GetOrganizationDetailDocument, options);
        }
export type GetOrganizationDetailQueryHookResult = ReturnType<typeof useGetOrganizationDetailQuery>;
export type GetOrganizationDetailLazyQueryHookResult = ReturnType<typeof useGetOrganizationDetailLazyQuery>;
export type GetOrganizationDetailQueryResult = Apollo.QueryResult<types.GetOrganizationDetailQuery, types.GetOrganizationDetailQueryVariables>;
export const GetOrganizationInvitesDocument = gql`
    query GetOrganizationInvites($id: ID!, $offset: Int!) {
  organization(id: $id) {
    __typename
    id
    organizationInvites(limit: 100, offset: $offset) {
      total
      items {
        id
        email
        invitee {
          __typename
          id
          name
          avatar
        }
        inviter {
          id
          name
        }
        state
        role
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationInvitesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationInvitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrganizationInvitesQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationInvitesQuery, types.GetOrganizationInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationInvitesQuery, types.GetOrganizationInvitesQueryVariables>(GetOrganizationInvitesDocument, options);
      }
export function useGetOrganizationInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationInvitesQuery, types.GetOrganizationInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationInvitesQuery, types.GetOrganizationInvitesQueryVariables>(GetOrganizationInvitesDocument, options);
        }
export type GetOrganizationInvitesQueryHookResult = ReturnType<typeof useGetOrganizationInvitesQuery>;
export type GetOrganizationInvitesLazyQueryHookResult = ReturnType<typeof useGetOrganizationInvitesLazyQuery>;
export type GetOrganizationInvitesQueryResult = Apollo.QueryResult<types.GetOrganizationInvitesQuery, types.GetOrganizationInvitesQueryVariables>;
export const GetOrganizationProjectsDocument = gql`
    query GetOrganizationProjects($id: ID!, $limit: Int!, $offset: Int!, $sort: [SearchSortEnum!]) {
  organization(id: $id) {
    __typename
    id
    projects(limit: $limit, offset: $offset, sort: $sort) {
      items {
        ...ProjectCard
      }
      total
    }
  }
}
    ${ProjectCardFragmentDoc}`;

/**
 * __useGetOrganizationProjectsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetOrganizationProjectsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationProjectsQuery, types.GetOrganizationProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationProjectsQuery, types.GetOrganizationProjectsQueryVariables>(GetOrganizationProjectsDocument, options);
      }
export function useGetOrganizationProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationProjectsQuery, types.GetOrganizationProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationProjectsQuery, types.GetOrganizationProjectsQueryVariables>(GetOrganizationProjectsDocument, options);
        }
export type GetOrganizationProjectsQueryHookResult = ReturnType<typeof useGetOrganizationProjectsQuery>;
export type GetOrganizationProjectsLazyQueryHookResult = ReturnType<typeof useGetOrganizationProjectsLazyQuery>;
export type GetOrganizationProjectsQueryResult = Apollo.QueryResult<types.GetOrganizationProjectsQuery, types.GetOrganizationProjectsQueryVariables>;
export const GetOrganizationUsersDocument = gql`
    query GetOrganizationUsers($id: ID!, $offset: Int!) {
  organization(id: $id) {
    __typename
    id
    organizationUsers(limit: 100, offset: $offset) {
      items {
        user {
          id
          name
          avatar
        }
        organization {
          id
        }
        role
      }
      limit
      total
    }
  }
}
    `;

/**
 * __useGetOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useGetOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationUsersQuery, types.GetOrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationUsersQuery, types.GetOrganizationUsersQueryVariables>(GetOrganizationUsersDocument, options);
      }
export function useGetOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationUsersQuery, types.GetOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationUsersQuery, types.GetOrganizationUsersQueryVariables>(GetOrganizationUsersDocument, options);
        }
export type GetOrganizationUsersQueryHookResult = ReturnType<typeof useGetOrganizationUsersQuery>;
export type GetOrganizationUsersLazyQueryHookResult = ReturnType<typeof useGetOrganizationUsersLazyQuery>;
export type GetOrganizationUsersQueryResult = Apollo.QueryResult<types.GetOrganizationUsersQuery, types.GetOrganizationUsersQueryVariables>;
export const RemoveOrganizationMemberDocument = gql`
    mutation RemoveOrganizationMember($organizationId: ID!, $userId: ID!) {
  removeOrganizationMember(organizationId: $organizationId, userId: $userId) {
    success
  }
}
    `;
export type RemoveOrganizationMemberMutationFn = Apollo.MutationFunction<types.RemoveOrganizationMemberMutation, types.RemoveOrganizationMemberMutationVariables>;

/**
 * __useRemoveOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationMemberMutation, { data, loading, error }] = useRemoveOrganizationMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveOrganizationMemberMutation(baseOptions?: Apollo.MutationHookOptions<types.RemoveOrganizationMemberMutation, types.RemoveOrganizationMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RemoveOrganizationMemberMutation, types.RemoveOrganizationMemberMutationVariables>(RemoveOrganizationMemberDocument, options);
      }
export type RemoveOrganizationMemberMutationHookResult = ReturnType<typeof useRemoveOrganizationMemberMutation>;
export type RemoveOrganizationMemberMutationResult = Apollo.MutationResult<types.RemoveOrganizationMemberMutation>;
export type RemoveOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<types.RemoveOrganizationMemberMutation, types.RemoveOrganizationMemberMutationVariables>;
export const RequestOrganizationInviteDocument = gql`
    mutation RequestOrganizationInvite($id: ID!) {
  requestOrganizationInvite(organizationId: $id) {
    id
    inviter {
      id
      name
      avatar
    }
    organization {
      id
      name
      logo
    }
    role
    state
  }
}
    `;
export type RequestOrganizationInviteMutationFn = Apollo.MutationFunction<types.RequestOrganizationInviteMutation, types.RequestOrganizationInviteMutationVariables>;

/**
 * __useRequestOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useRequestOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOrganizationInviteMutation, { data, loading, error }] = useRequestOrganizationInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestOrganizationInviteMutation(baseOptions?: Apollo.MutationHookOptions<types.RequestOrganizationInviteMutation, types.RequestOrganizationInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RequestOrganizationInviteMutation, types.RequestOrganizationInviteMutationVariables>(RequestOrganizationInviteDocument, options);
      }
export type RequestOrganizationInviteMutationHookResult = ReturnType<typeof useRequestOrganizationInviteMutation>;
export type RequestOrganizationInviteMutationResult = Apollo.MutationResult<types.RequestOrganizationInviteMutation>;
export type RequestOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<types.RequestOrganizationInviteMutation, types.RequestOrganizationInviteMutationVariables>;
export const ResendOrganizationInviteDocument = gql`
    mutation ResendOrganizationInvite($id: ID!) {
  resendOrganizationInvite(id: $id) {
    id
    state
    retries
  }
}
    `;
export type ResendOrganizationInviteMutationFn = Apollo.MutationFunction<types.ResendOrganizationInviteMutation, types.ResendOrganizationInviteMutationVariables>;

/**
 * __useResendOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useResendOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOrganizationInviteMutation, { data, loading, error }] = useResendOrganizationInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendOrganizationInviteMutation(baseOptions?: Apollo.MutationHookOptions<types.ResendOrganizationInviteMutation, types.ResendOrganizationInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ResendOrganizationInviteMutation, types.ResendOrganizationInviteMutationVariables>(ResendOrganizationInviteDocument, options);
      }
export type ResendOrganizationInviteMutationHookResult = ReturnType<typeof useResendOrganizationInviteMutation>;
export type ResendOrganizationInviteMutationResult = Apollo.MutationResult<types.ResendOrganizationInviteMutation>;
export type ResendOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<types.ResendOrganizationInviteMutation, types.ResendOrganizationInviteMutationVariables>;
export const UpdateOrganizationDescriptionDocument = gql`
    mutation UpdateOrganizationDescription($id: ID!, $description: String!) {
  updateOrganization(id: $id, organization: {description: $description}) {
    __typename
    id
    description
  }
}
    `;
export type UpdateOrganizationDescriptionMutationFn = Apollo.MutationFunction<types.UpdateOrganizationDescriptionMutation, types.UpdateOrganizationDescriptionMutationVariables>;

/**
 * __useUpdateOrganizationDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDescriptionMutation, { data, loading, error }] = useUpdateOrganizationDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateOrganizationDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateOrganizationDescriptionMutation, types.UpdateOrganizationDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateOrganizationDescriptionMutation, types.UpdateOrganizationDescriptionMutationVariables>(UpdateOrganizationDescriptionDocument, options);
      }
export type UpdateOrganizationDescriptionMutationHookResult = ReturnType<typeof useUpdateOrganizationDescriptionMutation>;
export type UpdateOrganizationDescriptionMutationResult = Apollo.MutationResult<types.UpdateOrganizationDescriptionMutation>;
export type UpdateOrganizationDescriptionMutationOptions = Apollo.BaseMutationOptions<types.UpdateOrganizationDescriptionMutation, types.UpdateOrganizationDescriptionMutationVariables>;
export const UpdateOrganizationNameDocument = gql`
    mutation UpdateOrganizationName($id: ID!, $name: String!) {
  updateOrganization(id: $id, organization: {name: $name}) {
    __typename
    id
    name
  }
}
    `;
export type UpdateOrganizationNameMutationFn = Apollo.MutationFunction<types.UpdateOrganizationNameMutation, types.UpdateOrganizationNameMutationVariables>;

/**
 * __useUpdateOrganizationNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNameMutation, { data, loading, error }] = useUpdateOrganizationNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateOrganizationNameMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateOrganizationNameMutation, types.UpdateOrganizationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateOrganizationNameMutation, types.UpdateOrganizationNameMutationVariables>(UpdateOrganizationNameDocument, options);
      }
export type UpdateOrganizationNameMutationHookResult = ReturnType<typeof useUpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationResult = Apollo.MutationResult<types.UpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationOptions = Apollo.BaseMutationOptions<types.UpdateOrganizationNameMutation, types.UpdateOrganizationNameMutationVariables>;
export const UpdateOrganizationSummaryDocument = gql`
    mutation UpdateOrganizationSummary($id: ID!, $summary: String!) {
  updateOrganization(id: $id, organization: {summary: $summary}) {
    __typename
    id
    summary
  }
}
    `;
export type UpdateOrganizationSummaryMutationFn = Apollo.MutationFunction<types.UpdateOrganizationSummaryMutation, types.UpdateOrganizationSummaryMutationVariables>;

/**
 * __useUpdateOrganizationSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSummaryMutation, { data, loading, error }] = useUpdateOrganizationSummaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateOrganizationSummaryMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateOrganizationSummaryMutation, types.UpdateOrganizationSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateOrganizationSummaryMutation, types.UpdateOrganizationSummaryMutationVariables>(UpdateOrganizationSummaryDocument, options);
      }
export type UpdateOrganizationSummaryMutationHookResult = ReturnType<typeof useUpdateOrganizationSummaryMutation>;
export type UpdateOrganizationSummaryMutationResult = Apollo.MutationResult<types.UpdateOrganizationSummaryMutation>;
export type UpdateOrganizationSummaryMutationOptions = Apollo.BaseMutationOptions<types.UpdateOrganizationSummaryMutation, types.UpdateOrganizationSummaryMutationVariables>;
export const UpdateOrganizationLogoImageDocument = gql`
    mutation UpdateOrganizationLogoImage($id: ID!, $token: String, $clear: Boolean) {
  updateOrganization(
    id: $id
    organization: {logoToken: $token, clearLogo: $clear}
  ) {
    __typename
    id
    logo
  }
}
    `;
export type UpdateOrganizationLogoImageMutationFn = Apollo.MutationFunction<types.UpdateOrganizationLogoImageMutation, types.UpdateOrganizationLogoImageMutationVariables>;

/**
 * __useUpdateOrganizationLogoImageMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationLogoImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationLogoImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationLogoImageMutation, { data, loading, error }] = useUpdateOrganizationLogoImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *      clear: // value for 'clear'
 *   },
 * });
 */
export function useUpdateOrganizationLogoImageMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateOrganizationLogoImageMutation, types.UpdateOrganizationLogoImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateOrganizationLogoImageMutation, types.UpdateOrganizationLogoImageMutationVariables>(UpdateOrganizationLogoImageDocument, options);
      }
export type UpdateOrganizationLogoImageMutationHookResult = ReturnType<typeof useUpdateOrganizationLogoImageMutation>;
export type UpdateOrganizationLogoImageMutationResult = Apollo.MutationResult<types.UpdateOrganizationLogoImageMutation>;
export type UpdateOrganizationLogoImageMutationOptions = Apollo.BaseMutationOptions<types.UpdateOrganizationLogoImageMutation, types.UpdateOrganizationLogoImageMutationVariables>;
export const UpdateOrganizationMemberDocument = gql`
    mutation UpdateOrganizationMember($organizationId: ID!, $userId: ID!, $role: OrganizationRoleEnum!) {
  updateOrganizationMember(
    organizationId: $organizationId
    userId: $userId
    role: $role
  ) {
    organization {
      id
    }
    user {
      id
    }
    role
  }
}
    `;
export type UpdateOrganizationMemberMutationFn = Apollo.MutationFunction<types.UpdateOrganizationMemberMutation, types.UpdateOrganizationMemberMutationVariables>;

/**
 * __useUpdateOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMemberMutation, { data, loading, error }] = useUpdateOrganizationMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateOrganizationMemberMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateOrganizationMemberMutation, types.UpdateOrganizationMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateOrganizationMemberMutation, types.UpdateOrganizationMemberMutationVariables>(UpdateOrganizationMemberDocument, options);
      }
export type UpdateOrganizationMemberMutationHookResult = ReturnType<typeof useUpdateOrganizationMemberMutation>;
export type UpdateOrganizationMemberMutationResult = Apollo.MutationResult<types.UpdateOrganizationMemberMutation>;
export type UpdateOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<types.UpdateOrganizationMemberMutation, types.UpdateOrganizationMemberMutationVariables>;
export const GetProfileDetailDocument = gql`
    query GetProfileDetail {
  profile {
    __typename
    initialized
    id
    name
    avatar
    summary
    description
    createdOn
    jobTitle
    location
    affiliations {
      affiliationRole
      name
      url
    }
    projects(offset: 0, limit: 0) {
      total
    }
    collections(offset: 0, limit: 0) {
      total
    }
    organizations(limit: 0, offset: 0) {
      total
    }
    organizationInvites(limit: 0, offset: 0) {
      total
    }
    stars {
      projects(limit: 0, offset: 0) {
        total
      }
      collections(limit: 0, offset: 0) {
        total
      }
      organizations(limit: 0, offset: 0) {
        total
      }
      users(limit: 0, offset: 0) {
        total
      }
    }
  }
}
    `;

/**
 * __useGetProfileDetailQuery__
 *
 * To run a query within a React component, call `useGetProfileDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileDetailQuery(baseOptions?: Apollo.QueryHookOptions<types.GetProfileDetailQuery, types.GetProfileDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileDetailQuery, types.GetProfileDetailQueryVariables>(GetProfileDetailDocument, options);
      }
export function useGetProfileDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileDetailQuery, types.GetProfileDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileDetailQuery, types.GetProfileDetailQueryVariables>(GetProfileDetailDocument, options);
        }
export type GetProfileDetailQueryHookResult = ReturnType<typeof useGetProfileDetailQuery>;
export type GetProfileDetailLazyQueryHookResult = ReturnType<typeof useGetProfileDetailLazyQuery>;
export type GetProfileDetailQueryResult = Apollo.QueryResult<types.GetProfileDetailQuery, types.GetProfileDetailQueryVariables>;
export const GetProfileOrganizationInvitesDocument = gql`
    query GetProfileOrganizationInvites($offset: Int!) {
  profile {
    organizationInvites(limit: 100, offset: $offset) {
      items {
        id
        inviter {
          id
          name
          avatar
        }
        organization {
          id
          name
          logo
        }
        role
        state
      }
      total
    }
  }
}
    `;

/**
 * __useGetProfileOrganizationInvitesQuery__
 *
 * To run a query within a React component, call `useGetProfileOrganizationInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileOrganizationInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileOrganizationInvitesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProfileOrganizationInvitesQuery(baseOptions: Apollo.QueryHookOptions<types.GetProfileOrganizationInvitesQuery, types.GetProfileOrganizationInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileOrganizationInvitesQuery, types.GetProfileOrganizationInvitesQueryVariables>(GetProfileOrganizationInvitesDocument, options);
      }
export function useGetProfileOrganizationInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileOrganizationInvitesQuery, types.GetProfileOrganizationInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileOrganizationInvitesQuery, types.GetProfileOrganizationInvitesQueryVariables>(GetProfileOrganizationInvitesDocument, options);
        }
export type GetProfileOrganizationInvitesQueryHookResult = ReturnType<typeof useGetProfileOrganizationInvitesQuery>;
export type GetProfileOrganizationInvitesLazyQueryHookResult = ReturnType<typeof useGetProfileOrganizationInvitesLazyQuery>;
export type GetProfileOrganizationInvitesQueryResult = Apollo.QueryResult<types.GetProfileOrganizationInvitesQuery, types.GetProfileOrganizationInvitesQueryVariables>;
export const GetProfileOrganizationsDocument = gql`
    query GetProfileOrganizations($offset: Int!) {
  profile {
    organizations(limit: 100, offset: $offset) {
      items {
        ...OrganizationCard
      }
      total
    }
  }
}
    ${OrganizationCardFragmentDoc}`;

/**
 * __useGetProfileOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetProfileOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileOrganizationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProfileOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProfileOrganizationsQuery, types.GetProfileOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileOrganizationsQuery, types.GetProfileOrganizationsQueryVariables>(GetProfileOrganizationsDocument, options);
      }
export function useGetProfileOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileOrganizationsQuery, types.GetProfileOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileOrganizationsQuery, types.GetProfileOrganizationsQueryVariables>(GetProfileOrganizationsDocument, options);
        }
export type GetProfileOrganizationsQueryHookResult = ReturnType<typeof useGetProfileOrganizationsQuery>;
export type GetProfileOrganizationsLazyQueryHookResult = ReturnType<typeof useGetProfileOrganizationsLazyQuery>;
export type GetProfileOrganizationsQueryResult = Apollo.QueryResult<types.GetProfileOrganizationsQuery, types.GetProfileOrganizationsQueryVariables>;
export const GetProfileStarsCollectionsDocument = gql`
    query GetProfileStarsCollections($offset: Int!) {
  profile {
    stars {
      collections(offset: $offset, limit: 100) {
        items {
          ...CollectionCard
        }
        total
      }
    }
  }
}
    ${CollectionCardFragmentDoc}`;

/**
 * __useGetProfileStarsCollectionsQuery__
 *
 * To run a query within a React component, call `useGetProfileStarsCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStarsCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStarsCollectionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProfileStarsCollectionsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProfileStarsCollectionsQuery, types.GetProfileStarsCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileStarsCollectionsQuery, types.GetProfileStarsCollectionsQueryVariables>(GetProfileStarsCollectionsDocument, options);
      }
export function useGetProfileStarsCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileStarsCollectionsQuery, types.GetProfileStarsCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileStarsCollectionsQuery, types.GetProfileStarsCollectionsQueryVariables>(GetProfileStarsCollectionsDocument, options);
        }
export type GetProfileStarsCollectionsQueryHookResult = ReturnType<typeof useGetProfileStarsCollectionsQuery>;
export type GetProfileStarsCollectionsLazyQueryHookResult = ReturnType<typeof useGetProfileStarsCollectionsLazyQuery>;
export type GetProfileStarsCollectionsQueryResult = Apollo.QueryResult<types.GetProfileStarsCollectionsQuery, types.GetProfileStarsCollectionsQueryVariables>;
export const GetProfileStarsOrganizationsDocument = gql`
    query GetProfileStarsOrganizations($offset: Int!) {
  profile {
    stars {
      organizations(offset: $offset, limit: 100) {
        items {
          ...OrganizationCard
        }
        total
      }
    }
  }
}
    ${OrganizationCardFragmentDoc}`;

/**
 * __useGetProfileStarsOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetProfileStarsOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStarsOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStarsOrganizationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProfileStarsOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProfileStarsOrganizationsQuery, types.GetProfileStarsOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileStarsOrganizationsQuery, types.GetProfileStarsOrganizationsQueryVariables>(GetProfileStarsOrganizationsDocument, options);
      }
export function useGetProfileStarsOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileStarsOrganizationsQuery, types.GetProfileStarsOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileStarsOrganizationsQuery, types.GetProfileStarsOrganizationsQueryVariables>(GetProfileStarsOrganizationsDocument, options);
        }
export type GetProfileStarsOrganizationsQueryHookResult = ReturnType<typeof useGetProfileStarsOrganizationsQuery>;
export type GetProfileStarsOrganizationsLazyQueryHookResult = ReturnType<typeof useGetProfileStarsOrganizationsLazyQuery>;
export type GetProfileStarsOrganizationsQueryResult = Apollo.QueryResult<types.GetProfileStarsOrganizationsQuery, types.GetProfileStarsOrganizationsQueryVariables>;
export const GetProfileStarsProjectsDocument = gql`
    query GetProfileStarsProjects($offset: Int!) {
  profile {
    stars {
      projects(offset: $offset, limit: 100) {
        items {
          ...ProjectCard
        }
        total
      }
    }
  }
}
    ${ProjectCardFragmentDoc}`;

/**
 * __useGetProfileStarsProjectsQuery__
 *
 * To run a query within a React component, call `useGetProfileStarsProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStarsProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStarsProjectsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProfileStarsProjectsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProfileStarsProjectsQuery, types.GetProfileStarsProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileStarsProjectsQuery, types.GetProfileStarsProjectsQueryVariables>(GetProfileStarsProjectsDocument, options);
      }
export function useGetProfileStarsProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileStarsProjectsQuery, types.GetProfileStarsProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileStarsProjectsQuery, types.GetProfileStarsProjectsQueryVariables>(GetProfileStarsProjectsDocument, options);
        }
export type GetProfileStarsProjectsQueryHookResult = ReturnType<typeof useGetProfileStarsProjectsQuery>;
export type GetProfileStarsProjectsLazyQueryHookResult = ReturnType<typeof useGetProfileStarsProjectsLazyQuery>;
export type GetProfileStarsProjectsQueryResult = Apollo.QueryResult<types.GetProfileStarsProjectsQuery, types.GetProfileStarsProjectsQueryVariables>;
export const GetProfileStarsUsersDocument = gql`
    query GetProfileStarsUsers($offset: Int!) {
  profile {
    stars {
      users(offset: $offset, limit: 100) {
        items {
          ...UserCard
        }
        total
      }
    }
  }
}
    ${UserCardFragmentDoc}`;

/**
 * __useGetProfileStarsUsersQuery__
 *
 * To run a query within a React component, call `useGetProfileStarsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStarsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStarsUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProfileStarsUsersQuery(baseOptions: Apollo.QueryHookOptions<types.GetProfileStarsUsersQuery, types.GetProfileStarsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProfileStarsUsersQuery, types.GetProfileStarsUsersQueryVariables>(GetProfileStarsUsersDocument, options);
      }
export function useGetProfileStarsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProfileStarsUsersQuery, types.GetProfileStarsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProfileStarsUsersQuery, types.GetProfileStarsUsersQueryVariables>(GetProfileStarsUsersDocument, options);
        }
export type GetProfileStarsUsersQueryHookResult = ReturnType<typeof useGetProfileStarsUsersQuery>;
export type GetProfileStarsUsersLazyQueryHookResult = ReturnType<typeof useGetProfileStarsUsersLazyQuery>;
export type GetProfileStarsUsersQueryResult = Apollo.QueryResult<types.GetProfileStarsUsersQuery, types.GetProfileStarsUsersQueryVariables>;
export const ChangeProjectOwnerDocument = gql`
    mutation ChangeProjectOwner($id: ID!, $owner: OwnerInput!) {
  changeProjectOwner(projectId: $id, owner: $owner) {
    ...ProjectCard
  }
}
    ${ProjectCardFragmentDoc}`;
export type ChangeProjectOwnerMutationFn = Apollo.MutationFunction<types.ChangeProjectOwnerMutation, types.ChangeProjectOwnerMutationVariables>;

/**
 * __useChangeProjectOwnerMutation__
 *
 * To run a mutation, you first call `useChangeProjectOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectOwnerMutation, { data, loading, error }] = useChangeProjectOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useChangeProjectOwnerMutation(baseOptions?: Apollo.MutationHookOptions<types.ChangeProjectOwnerMutation, types.ChangeProjectOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ChangeProjectOwnerMutation, types.ChangeProjectOwnerMutationVariables>(ChangeProjectOwnerDocument, options);
      }
export type ChangeProjectOwnerMutationHookResult = ReturnType<typeof useChangeProjectOwnerMutation>;
export type ChangeProjectOwnerMutationResult = Apollo.MutationResult<types.ChangeProjectOwnerMutation>;
export type ChangeProjectOwnerMutationOptions = Apollo.BaseMutationOptions<types.ChangeProjectOwnerMutation, types.ChangeProjectOwnerMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: ID!) {
  deleteProject(projectId: $id, options: {totalDelete: true}) {
    success
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<types.DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<types.DeleteProjectMutation, types.DeleteProjectMutationVariables>;
export const GetProjectCollectionsDocument = gql`
    query GetProjectCollections($id: ID!, $limit: Int!, $offset: Int!, $sort: [SearchSortEnum!]) {
  project(id: $id) {
    id
    deleted
    collections(limit: $limit, offset: $offset, sort: $sort) {
      items {
        ...CollectionCard
      }
      total
    }
  }
}
    ${CollectionCardFragmentDoc}`;

/**
 * __useGetProjectCollectionsQuery__
 *
 * To run a query within a React component, call `useGetProjectCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCollectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProjectCollectionsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectCollectionsQuery, types.GetProjectCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectCollectionsQuery, types.GetProjectCollectionsQueryVariables>(GetProjectCollectionsDocument, options);
      }
export function useGetProjectCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectCollectionsQuery, types.GetProjectCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectCollectionsQuery, types.GetProjectCollectionsQueryVariables>(GetProjectCollectionsDocument, options);
        }
export type GetProjectCollectionsQueryHookResult = ReturnType<typeof useGetProjectCollectionsQuery>;
export type GetProjectCollectionsLazyQueryHookResult = ReturnType<typeof useGetProjectCollectionsLazyQuery>;
export type GetProjectCollectionsQueryResult = Apollo.QueryResult<types.GetProjectCollectionsQuery, types.GetProjectCollectionsQueryVariables>;
export const GetProjectDetailDocument = gql`
    query GetProjectDetail($id: ID!) {
  project(id: $id) {
    __typename
    id
    name
    summary
    citation
    description
    visibility
    starred
    deleted
    heroImage {
      lg
    }
    projectType {
      id
      name
      summary
      logo
    }
    createdBy {
      __typename
      id
      name
      avatar
    }
    updatedBy {
      __typename
      id
      name
      avatar
    }
    createdOn
    updatedOn
    totalSize
    collections(limit: 0, offset: 0) {
      total
    }
    permissions {
      delete
      update
      view
    }
    sponsor {
      __typename
      ... on User {
        id
        name
        avatar
      }
      ... on Organization {
        id
        name
        logo
      }
    }
    ownedBy {
      __typename
      ... on User {
        id
        name
        avatar
      }
      ... on Organization {
        id
        name
        logo
      }
    }
    meta {
      key
      type
      value
      locked
      ext
    }
    tags
    bounds {
      id
      bbox
      polygonUrl
      centroid
    }
    datasets(limit: 0, offset: 0) {
      total
    }
    qaqc(limit: 0, offset: 0) {
      total
    }
  }
}
    `;

/**
 * __useGetProjectDetailQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectDetailQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectDetailQuery, types.GetProjectDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectDetailQuery, types.GetProjectDetailQueryVariables>(GetProjectDetailDocument, options);
      }
export function useGetProjectDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectDetailQuery, types.GetProjectDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectDetailQuery, types.GetProjectDetailQueryVariables>(GetProjectDetailDocument, options);
        }
export type GetProjectDetailQueryHookResult = ReturnType<typeof useGetProjectDetailQuery>;
export type GetProjectDetailLazyQueryHookResult = ReturnType<typeof useGetProjectDetailLazyQuery>;
export type GetProjectDetailQueryResult = Apollo.QueryResult<types.GetProjectDetailQuery, types.GetProjectDetailQueryVariables>;
export const UpdateProjectVisibilityDocument = gql`
    mutation UpdateProjectVisibility($id: ID!, $visibility: ProjectVisibilityEnum!) {
  updateProject(projectId: $id, project: {visibility: $visibility}) {
    __typename
    id
    visibility
  }
}
    `;
export type UpdateProjectVisibilityMutationFn = Apollo.MutationFunction<types.UpdateProjectVisibilityMutation, types.UpdateProjectVisibilityMutationVariables>;

/**
 * __useUpdateProjectVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateProjectVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectVisibilityMutation, { data, loading, error }] = useUpdateProjectVisibilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useUpdateProjectVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectVisibilityMutation, types.UpdateProjectVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectVisibilityMutation, types.UpdateProjectVisibilityMutationVariables>(UpdateProjectVisibilityDocument, options);
      }
export type UpdateProjectVisibilityMutationHookResult = ReturnType<typeof useUpdateProjectVisibilityMutation>;
export type UpdateProjectVisibilityMutationResult = Apollo.MutationResult<types.UpdateProjectVisibilityMutation>;
export type UpdateProjectVisibilityMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectVisibilityMutation, types.UpdateProjectVisibilityMutationVariables>;
export const UpdateProjectTagsDocument = gql`
    mutation UpdateProjectTags($id: ID!, $tags: [String!]!) {
  updateProject(projectId: $id, project: {tags: $tags}) {
    __typename
    id
    tags
  }
}
    `;
export type UpdateProjectTagsMutationFn = Apollo.MutationFunction<types.UpdateProjectTagsMutation, types.UpdateProjectTagsMutationVariables>;

/**
 * __useUpdateProjectTagsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTagsMutation, { data, loading, error }] = useUpdateProjectTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateProjectTagsMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectTagsMutation, types.UpdateProjectTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectTagsMutation, types.UpdateProjectTagsMutationVariables>(UpdateProjectTagsDocument, options);
      }
export type UpdateProjectTagsMutationHookResult = ReturnType<typeof useUpdateProjectTagsMutation>;
export type UpdateProjectTagsMutationResult = Apollo.MutationResult<types.UpdateProjectTagsMutation>;
export type UpdateProjectTagsMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectTagsMutation, types.UpdateProjectTagsMutationVariables>;
export const UpdateProjectNameDocument = gql`
    mutation UpdateProjectName($id: ID!, $name: String!) {
  updateProject(projectId: $id, project: {name: $name}) {
    __typename
    id
    name
  }
}
    `;
export type UpdateProjectNameMutationFn = Apollo.MutationFunction<types.UpdateProjectNameMutation, types.UpdateProjectNameMutationVariables>;

/**
 * __useUpdateProjectNameMutation__
 *
 * To run a mutation, you first call `useUpdateProjectNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectNameMutation, { data, loading, error }] = useUpdateProjectNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectNameMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectNameMutation, types.UpdateProjectNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectNameMutation, types.UpdateProjectNameMutationVariables>(UpdateProjectNameDocument, options);
      }
export type UpdateProjectNameMutationHookResult = ReturnType<typeof useUpdateProjectNameMutation>;
export type UpdateProjectNameMutationResult = Apollo.MutationResult<types.UpdateProjectNameMutation>;
export type UpdateProjectNameMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectNameMutation, types.UpdateProjectNameMutationVariables>;
export const UpdateProjectSummaryDocument = gql`
    mutation UpdateProjectSummary($id: ID!, $summary: String!) {
  updateProject(projectId: $id, project: {summary: $summary}) {
    __typename
    id
    summary
  }
}
    `;
export type UpdateProjectSummaryMutationFn = Apollo.MutationFunction<types.UpdateProjectSummaryMutation, types.UpdateProjectSummaryMutationVariables>;

/**
 * __useUpdateProjectSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSummaryMutation, { data, loading, error }] = useUpdateProjectSummaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateProjectSummaryMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectSummaryMutation, types.UpdateProjectSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectSummaryMutation, types.UpdateProjectSummaryMutationVariables>(UpdateProjectSummaryDocument, options);
      }
export type UpdateProjectSummaryMutationHookResult = ReturnType<typeof useUpdateProjectSummaryMutation>;
export type UpdateProjectSummaryMutationResult = Apollo.MutationResult<types.UpdateProjectSummaryMutation>;
export type UpdateProjectSummaryMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectSummaryMutation, types.UpdateProjectSummaryMutationVariables>;
export const UpdateProjectCitationDocument = gql`
    mutation UpdateProjectCitation($id: ID!, $citation: String!) {
  updateProject(projectId: $id, project: {citation: $citation}) {
    __typename
    id
    citation
  }
}
    `;
export type UpdateProjectCitationMutationFn = Apollo.MutationFunction<types.UpdateProjectCitationMutation, types.UpdateProjectCitationMutationVariables>;

/**
 * __useUpdateProjectCitationMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCitationMutation, { data, loading, error }] = useUpdateProjectCitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      citation: // value for 'citation'
 *   },
 * });
 */
export function useUpdateProjectCitationMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectCitationMutation, types.UpdateProjectCitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectCitationMutation, types.UpdateProjectCitationMutationVariables>(UpdateProjectCitationDocument, options);
      }
export type UpdateProjectCitationMutationHookResult = ReturnType<typeof useUpdateProjectCitationMutation>;
export type UpdateProjectCitationMutationResult = Apollo.MutationResult<types.UpdateProjectCitationMutation>;
export type UpdateProjectCitationMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectCitationMutation, types.UpdateProjectCitationMutationVariables>;
export const UpdateProjectDescriptionDocument = gql`
    mutation UpdateProjectDescription($id: ID!, $description: String!) {
  updateProject(projectId: $id, project: {description: $description}) {
    __typename
    id
    description
  }
}
    `;
export type UpdateProjectDescriptionMutationFn = Apollo.MutationFunction<types.UpdateProjectDescriptionMutation, types.UpdateProjectDescriptionMutationVariables>;

/**
 * __useUpdateProjectDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectDescriptionMutation, { data, loading, error }] = useUpdateProjectDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateProjectDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectDescriptionMutation, types.UpdateProjectDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectDescriptionMutation, types.UpdateProjectDescriptionMutationVariables>(UpdateProjectDescriptionDocument, options);
      }
export type UpdateProjectDescriptionMutationHookResult = ReturnType<typeof useUpdateProjectDescriptionMutation>;
export type UpdateProjectDescriptionMutationResult = Apollo.MutationResult<types.UpdateProjectDescriptionMutation>;
export type UpdateProjectDescriptionMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectDescriptionMutation, types.UpdateProjectDescriptionMutationVariables>;
export const UpdateProjectHeroImageDocument = gql`
    mutation UpdateProjectHeroImage($id: ID!, $token: String, $clear: Boolean) {
  updateProject(
    projectId: $id
    project: {heroImageToken: $token, clearHeroImage: $clear}
  ) {
    __typename
    id
    heroImage {
      sm
      md
      lg
    }
  }
}
    `;
export type UpdateProjectHeroImageMutationFn = Apollo.MutationFunction<types.UpdateProjectHeroImageMutation, types.UpdateProjectHeroImageMutationVariables>;

/**
 * __useUpdateProjectHeroImageMutation__
 *
 * To run a mutation, you first call `useUpdateProjectHeroImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectHeroImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectHeroImageMutation, { data, loading, error }] = useUpdateProjectHeroImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *      clear: // value for 'clear'
 *   },
 * });
 */
export function useUpdateProjectHeroImageMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectHeroImageMutation, types.UpdateProjectHeroImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectHeroImageMutation, types.UpdateProjectHeroImageMutationVariables>(UpdateProjectHeroImageDocument, options);
      }
export type UpdateProjectHeroImageMutationHookResult = ReturnType<typeof useUpdateProjectHeroImageMutation>;
export type UpdateProjectHeroImageMutationResult = Apollo.MutationResult<types.UpdateProjectHeroImageMutation>;
export type UpdateProjectHeroImageMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectHeroImageMutation, types.UpdateProjectHeroImageMutationVariables>;
export const UpdateProjectMetaDocument = gql`
    mutation UpdateProjectMeta($id: ID!, $meta: [MetaDataInput!]!) {
  updateProject(projectId: $id, project: {meta: $meta}) {
    __typename
    id
    meta {
      key
      type
      value
      locked
      ext
    }
  }
}
    `;
export type UpdateProjectMetaMutationFn = Apollo.MutationFunction<types.UpdateProjectMetaMutation, types.UpdateProjectMetaMutationVariables>;

/**
 * __useUpdateProjectMetaMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMetaMutation, { data, loading, error }] = useUpdateProjectMetaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useUpdateProjectMetaMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProjectMetaMutation, types.UpdateProjectMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProjectMetaMutation, types.UpdateProjectMetaMutationVariables>(UpdateProjectMetaDocument, options);
      }
export type UpdateProjectMetaMutationHookResult = ReturnType<typeof useUpdateProjectMetaMutation>;
export type UpdateProjectMetaMutationResult = Apollo.MutationResult<types.UpdateProjectMetaMutation>;
export type UpdateProjectMetaMutationOptions = Apollo.BaseMutationOptions<types.UpdateProjectMetaMutation, types.UpdateProjectMetaMutationVariables>;
export const GetProjectTypeDetailDocument = gql`
    query GetProjectTypeDetail($id: String!) {
  projectType(id: $id) {
    ...ProjectTypeItem
  }
  searchProjects(offset: 0, limit: 0, params: {projectTypeId: $id}) {
    total
  }
}
    ${ProjectTypeItemFragmentDoc}`;

/**
 * __useGetProjectTypeDetailQuery__
 *
 * To run a query within a React component, call `useGetProjectTypeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTypeDetailQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectTypeDetailQuery, types.GetProjectTypeDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectTypeDetailQuery, types.GetProjectTypeDetailQueryVariables>(GetProjectTypeDetailDocument, options);
      }
export function useGetProjectTypeDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectTypeDetailQuery, types.GetProjectTypeDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectTypeDetailQuery, types.GetProjectTypeDetailQueryVariables>(GetProjectTypeDetailDocument, options);
        }
export type GetProjectTypeDetailQueryHookResult = ReturnType<typeof useGetProjectTypeDetailQuery>;
export type GetProjectTypeDetailLazyQueryHookResult = ReturnType<typeof useGetProjectTypeDetailLazyQuery>;
export type GetProjectTypeDetailQueryResult = Apollo.QueryResult<types.GetProjectTypeDetailQuery, types.GetProjectTypeDetailQueryVariables>;
export const GetProjectQaqcDocument = gql`
    query GetProjectQaqc($id: ID!, $offset: Int!, $limit: Int!) {
  project(id: $id) {
    id
    deleted
    qaqc(limit: $limit, offset: $offset) {
      items {
        __typename
        ... on QAQCEvent {
          id
          name
          state
          performedBy
          datePerformed
          summary
        }
      }
      total
    }
  }
}
    `;

/**
 * __useGetProjectQaqcQuery__
 *
 * To run a query within a React component, call `useGetProjectQaqcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQaqcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQaqcQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProjectQaqcQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectQaqcQuery, types.GetProjectQaqcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectQaqcQuery, types.GetProjectQaqcQueryVariables>(GetProjectQaqcDocument, options);
      }
export function useGetProjectQaqcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectQaqcQuery, types.GetProjectQaqcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectQaqcQuery, types.GetProjectQaqcQueryVariables>(GetProjectQaqcDocument, options);
        }
export type GetProjectQaqcQueryHookResult = ReturnType<typeof useGetProjectQaqcQuery>;
export type GetProjectQaqcLazyQueryHookResult = ReturnType<typeof useGetProjectQaqcLazyQuery>;
export type GetProjectQaqcQueryResult = Apollo.QueryResult<types.GetProjectQaqcQuery, types.GetProjectQaqcQueryVariables>;
export const CreateSavedSearchDocument = gql`
    mutation CreateSavedSearch($savedSearch: SavedSearchInput!, $orgId: ID) {
  createSavedSearch(savedSearch: $savedSearch, orgId: $orgId) {
    __typename
    id
    name
  }
}
    `;
export type CreateSavedSearchMutationFn = Apollo.MutationFunction<types.CreateSavedSearchMutation, types.CreateSavedSearchMutationVariables>;

/**
 * __useCreateSavedSearchMutation__
 *
 * To run a mutation, you first call `useCreateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedSearchMutation, { data, loading, error }] = useCreateSavedSearchMutation({
 *   variables: {
 *      savedSearch: // value for 'savedSearch'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useCreateSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateSavedSearchMutation, types.CreateSavedSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateSavedSearchMutation, types.CreateSavedSearchMutationVariables>(CreateSavedSearchDocument, options);
      }
export type CreateSavedSearchMutationHookResult = ReturnType<typeof useCreateSavedSearchMutation>;
export type CreateSavedSearchMutationResult = Apollo.MutationResult<types.CreateSavedSearchMutation>;
export type CreateSavedSearchMutationOptions = Apollo.BaseMutationOptions<types.CreateSavedSearchMutation, types.CreateSavedSearchMutationVariables>;
export const GetCollectionByIdDocument = gql`
    query GetCollectionById($id: ID!) {
  collection(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionByIdQuery, types.GetCollectionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionByIdQuery, types.GetCollectionByIdQueryVariables>(GetCollectionByIdDocument, options);
      }
export function useGetCollectionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionByIdQuery, types.GetCollectionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionByIdQuery, types.GetCollectionByIdQueryVariables>(GetCollectionByIdDocument, options);
        }
export type GetCollectionByIdQueryHookResult = ReturnType<typeof useGetCollectionByIdQuery>;
export type GetCollectionByIdLazyQueryHookResult = ReturnType<typeof useGetCollectionByIdLazyQuery>;
export type GetCollectionByIdQueryResult = Apollo.QueryResult<types.GetCollectionByIdQuery, types.GetCollectionByIdQueryVariables>;
export const GetCollectionSearchResultsDocument = gql`
    query GetCollectionSearchResults($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: SearchParamsInput!, $minScore: Float) {
  searchCollections(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        ...CollectionCard
      }
    }
  }
}
    ${CollectionCardFragmentDoc}`;

/**
 * __useGetCollectionSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetCollectionSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionSearchResultsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetCollectionSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionSearchResultsQuery, types.GetCollectionSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionSearchResultsQuery, types.GetCollectionSearchResultsQueryVariables>(GetCollectionSearchResultsDocument, options);
      }
export function useGetCollectionSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionSearchResultsQuery, types.GetCollectionSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionSearchResultsQuery, types.GetCollectionSearchResultsQueryVariables>(GetCollectionSearchResultsDocument, options);
        }
export type GetCollectionSearchResultsQueryHookResult = ReturnType<typeof useGetCollectionSearchResultsQuery>;
export type GetCollectionSearchResultsLazyQueryHookResult = ReturnType<typeof useGetCollectionSearchResultsLazyQuery>;
export type GetCollectionSearchResultsQueryResult = Apollo.QueryResult<types.GetCollectionSearchResultsQuery, types.GetCollectionSearchResultsQueryVariables>;
export const GetCollectionsByNameDocument = gql`
    query GetCollectionsByName($name: String!, $editableOnly: Boolean, $minScore: Float) {
  searchCollections(
    limit: 20
    offset: 0
    params: {name: $name, editableOnly: $editableOnly}
    minScore: $minScore
  ) {
    results {
      score
      item {
        __typename
        ... on Collection {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCollectionsByNameQuery__
 *
 * To run a query within a React component, call `useGetCollectionsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      editableOnly: // value for 'editableOnly'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetCollectionsByNameQuery(baseOptions: Apollo.QueryHookOptions<types.GetCollectionsByNameQuery, types.GetCollectionsByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCollectionsByNameQuery, types.GetCollectionsByNameQueryVariables>(GetCollectionsByNameDocument, options);
      }
export function useGetCollectionsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCollectionsByNameQuery, types.GetCollectionsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCollectionsByNameQuery, types.GetCollectionsByNameQueryVariables>(GetCollectionsByNameDocument, options);
        }
export type GetCollectionsByNameQueryHookResult = ReturnType<typeof useGetCollectionsByNameQuery>;
export type GetCollectionsByNameLazyQueryHookResult = ReturnType<typeof useGetCollectionsByNameLazyQuery>;
export type GetCollectionsByNameQueryResult = Apollo.QueryResult<types.GetCollectionsByNameQuery, types.GetCollectionsByNameQueryVariables>;
export const GetOrganizationByIdDocument = gql`
    query GetOrganizationById($id: ID!) {
  organization(id: $id) {
    id
    name
    logo
  }
}
    `;

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationByIdQuery, types.GetOrganizationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationByIdQuery, types.GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
      }
export function useGetOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationByIdQuery, types.GetOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationByIdQuery, types.GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
        }
export type GetOrganizationByIdQueryHookResult = ReturnType<typeof useGetOrganizationByIdQuery>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationByIdLazyQuery>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<types.GetOrganizationByIdQuery, types.GetOrganizationByIdQueryVariables>;
export const GetOrganizationSearchResultsDocument = gql`
    query GetOrganizationSearchResults($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: SearchParamsInput!, $minScore: Float) {
  searchOrganizations(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        ...OrganizationCard
      }
    }
  }
}
    ${OrganizationCardFragmentDoc}`;

/**
 * __useGetOrganizationSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSearchResultsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetOrganizationSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationSearchResultsQuery, types.GetOrganizationSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationSearchResultsQuery, types.GetOrganizationSearchResultsQueryVariables>(GetOrganizationSearchResultsDocument, options);
      }
export function useGetOrganizationSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationSearchResultsQuery, types.GetOrganizationSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationSearchResultsQuery, types.GetOrganizationSearchResultsQueryVariables>(GetOrganizationSearchResultsDocument, options);
        }
export type GetOrganizationSearchResultsQueryHookResult = ReturnType<typeof useGetOrganizationSearchResultsQuery>;
export type GetOrganizationSearchResultsLazyQueryHookResult = ReturnType<typeof useGetOrganizationSearchResultsLazyQuery>;
export type GetOrganizationSearchResultsQueryResult = Apollo.QueryResult<types.GetOrganizationSearchResultsQuery, types.GetOrganizationSearchResultsQueryVariables>;
export const GetOrganizationsByNameDocument = gql`
    query GetOrganizationsByName($name: String!, $minScore: Float) {
  searchOrganizations(
    limit: 20
    offset: 0
    params: {name: $name}
    minScore: $minScore
  ) {
    results {
      score
      item {
        __typename
        ... on Organization {
          id
          name
          logo
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationsByNameQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetOrganizationsByNameQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganizationsByNameQuery, types.GetOrganizationsByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganizationsByNameQuery, types.GetOrganizationsByNameQueryVariables>(GetOrganizationsByNameDocument, options);
      }
export function useGetOrganizationsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganizationsByNameQuery, types.GetOrganizationsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganizationsByNameQuery, types.GetOrganizationsByNameQueryVariables>(GetOrganizationsByNameDocument, options);
        }
export type GetOrganizationsByNameQueryHookResult = ReturnType<typeof useGetOrganizationsByNameQuery>;
export type GetOrganizationsByNameLazyQueryHookResult = ReturnType<typeof useGetOrganizationsByNameLazyQuery>;
export type GetOrganizationsByNameQueryResult = Apollo.QueryResult<types.GetOrganizationsByNameQuery, types.GetOrganizationsByNameQueryVariables>;
export const GetProjectSearchResultsDocument = gql`
    query GetProjectSearchResults($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: ProjectSearchParamsInput!, $minScore: Float) {
  searchProjects(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        ...ProjectCard
      }
    }
    stats {
      __typename
      ... on ProjectSearchMeta {
        bbox
        projectTypes
      }
    }
  }
}
    ${ProjectCardFragmentDoc}`;

/**
 * __useGetProjectSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetProjectSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSearchResultsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetProjectSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectSearchResultsQuery, types.GetProjectSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectSearchResultsQuery, types.GetProjectSearchResultsQueryVariables>(GetProjectSearchResultsDocument, options);
      }
export function useGetProjectSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectSearchResultsQuery, types.GetProjectSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectSearchResultsQuery, types.GetProjectSearchResultsQueryVariables>(GetProjectSearchResultsDocument, options);
        }
export type GetProjectSearchResultsQueryHookResult = ReturnType<typeof useGetProjectSearchResultsQuery>;
export type GetProjectSearchResultsLazyQueryHookResult = ReturnType<typeof useGetProjectSearchResultsLazyQuery>;
export type GetProjectSearchResultsQueryResult = Apollo.QueryResult<types.GetProjectSearchResultsQuery, types.GetProjectSearchResultsQueryVariables>;
export const GetProjectTypesDocument = gql`
    query GetProjectTypes {
  projectTypes(limit: 100, offset: 0) {
    items {
      __typename
      ... on ProjectType {
        ...ProjectTypeItem
      }
    }
  }
  searchProjects(offset: 0, limit: 0, params: {}) {
    stats {
      __typename
      ... on ProjectSearchMeta {
        projectTypes
      }
    }
  }
}
    ${ProjectTypeItemFragmentDoc}`;

/**
 * __useGetProjectTypesQuery__
 *
 * To run a query within a React component, call `useGetProjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectTypesQuery(baseOptions?: Apollo.QueryHookOptions<types.GetProjectTypesQuery, types.GetProjectTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectTypesQuery, types.GetProjectTypesQueryVariables>(GetProjectTypesDocument, options);
      }
export function useGetProjectTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectTypesQuery, types.GetProjectTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectTypesQuery, types.GetProjectTypesQueryVariables>(GetProjectTypesDocument, options);
        }
export type GetProjectTypesQueryHookResult = ReturnType<typeof useGetProjectTypesQuery>;
export type GetProjectTypesLazyQueryHookResult = ReturnType<typeof useGetProjectTypesLazyQuery>;
export type GetProjectTypesQueryResult = Apollo.QueryResult<types.GetProjectTypesQuery, types.GetProjectTypesQueryVariables>;
export const GetProjectsByNameDocument = gql`
    query GetProjectsByName($name: String!, $minScore: Float) {
  searchProjects(
    limit: 20
    offset: 0
    params: {name: $name}
    sort: [MINE]
    minScore: $minScore
  ) {
    results {
      score
      item {
        __typename
        ... on Project {
          id
          name
          deleted
          projectType {
            id
            name
            logo
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectsByNameQuery__
 *
 * To run a query within a React component, call `useGetProjectsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetProjectsByNameQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectsByNameQuery, types.GetProjectsByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectsByNameQuery, types.GetProjectsByNameQueryVariables>(GetProjectsByNameDocument, options);
      }
export function useGetProjectsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectsByNameQuery, types.GetProjectsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectsByNameQuery, types.GetProjectsByNameQueryVariables>(GetProjectsByNameDocument, options);
        }
export type GetProjectsByNameQueryHookResult = ReturnType<typeof useGetProjectsByNameQuery>;
export type GetProjectsByNameLazyQueryHookResult = ReturnType<typeof useGetProjectsByNameLazyQuery>;
export type GetProjectsByNameQueryResult = Apollo.QueryResult<types.GetProjectsByNameQuery, types.GetProjectsByNameQueryVariables>;
export const GetSavedSearchSearchResultsDocument = gql`
    query GetSavedSearchSearchResults($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: SearchParamsInput!, $minScore: Float) {
  searchSavedSearches(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        ...SavedSearchCard
      }
    }
  }
}
    ${SavedSearchCardFragmentDoc}`;

/**
 * __useGetSavedSearchSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSavedSearchSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedSearchSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedSearchSearchResultsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetSavedSearchSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<types.GetSavedSearchSearchResultsQuery, types.GetSavedSearchSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSavedSearchSearchResultsQuery, types.GetSavedSearchSearchResultsQueryVariables>(GetSavedSearchSearchResultsDocument, options);
      }
export function useGetSavedSearchSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSavedSearchSearchResultsQuery, types.GetSavedSearchSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSavedSearchSearchResultsQuery, types.GetSavedSearchSearchResultsQueryVariables>(GetSavedSearchSearchResultsDocument, options);
        }
export type GetSavedSearchSearchResultsQueryHookResult = ReturnType<typeof useGetSavedSearchSearchResultsQuery>;
export type GetSavedSearchSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSavedSearchSearchResultsLazyQuery>;
export type GetSavedSearchSearchResultsQueryResult = Apollo.QueryResult<types.GetSavedSearchSearchResultsQuery, types.GetSavedSearchSearchResultsQueryVariables>;
export const GetSearchMapBoundsDocument = gql`
    query GetSearchMapBounds($limit: Int!, $params: ProjectSearchParamsInput!, $minScore: Float) {
  searchMapBounds(limit: $limit, params: $params, minScore: $minScore) {
    bounds {
      bounds {
        __typename
        id
        polygonUrl
        area
        centroid
        bbox
        geoHash
      }
      projectCount
    }
    remaining
  }
}
    `;

/**
 * __useGetSearchMapBoundsQuery__
 *
 * To run a query within a React component, call `useGetSearchMapBoundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchMapBoundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchMapBoundsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetSearchMapBoundsQuery(baseOptions: Apollo.QueryHookOptions<types.GetSearchMapBoundsQuery, types.GetSearchMapBoundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSearchMapBoundsQuery, types.GetSearchMapBoundsQueryVariables>(GetSearchMapBoundsDocument, options);
      }
export function useGetSearchMapBoundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSearchMapBoundsQuery, types.GetSearchMapBoundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSearchMapBoundsQuery, types.GetSearchMapBoundsQueryVariables>(GetSearchMapBoundsDocument, options);
        }
export type GetSearchMapBoundsQueryHookResult = ReturnType<typeof useGetSearchMapBoundsQuery>;
export type GetSearchMapBoundsLazyQueryHookResult = ReturnType<typeof useGetSearchMapBoundsLazyQuery>;
export type GetSearchMapBoundsQueryResult = Apollo.QueryResult<types.GetSearchMapBoundsQuery, types.GetSearchMapBoundsQueryVariables>;
export const GetSearchMapClustersDocument = gql`
    query GetSearchMapClusters($params: ProjectSearchParamsInput!, $precision: Int!, $limit: Int!, $minScore: Float) {
  searchMapClusters(
    params: $params
    limit: $limit
    precision: $precision
    minScore: $minScore
  ) {
    clusters {
      coords
      count
      hash
    }
    remaining
  }
}
    `;

/**
 * __useGetSearchMapClustersQuery__
 *
 * To run a query within a React component, call `useGetSearchMapClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchMapClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchMapClustersQuery({
 *   variables: {
 *      params: // value for 'params'
 *      precision: // value for 'precision'
 *      limit: // value for 'limit'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetSearchMapClustersQuery(baseOptions: Apollo.QueryHookOptions<types.GetSearchMapClustersQuery, types.GetSearchMapClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSearchMapClustersQuery, types.GetSearchMapClustersQueryVariables>(GetSearchMapClustersDocument, options);
      }
export function useGetSearchMapClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSearchMapClustersQuery, types.GetSearchMapClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSearchMapClustersQuery, types.GetSearchMapClustersQueryVariables>(GetSearchMapClustersDocument, options);
        }
export type GetSearchMapClustersQueryHookResult = ReturnType<typeof useGetSearchMapClustersQuery>;
export type GetSearchMapClustersLazyQueryHookResult = ReturnType<typeof useGetSearchMapClustersLazyQuery>;
export type GetSearchMapClustersQueryResult = Apollo.QueryResult<types.GetSearchMapClustersQuery, types.GetSearchMapClustersQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: ID!) {
  user(id: $id) {
    id
    name
    avatar
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>;
export const GetUserSearchResultsDocument = gql`
    query GetUserSearchResults($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: SearchParamsInput!, $minScore: Float) {
  searchUsers(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        ...UserCard
      }
    }
  }
}
    ${UserCardFragmentDoc}`;

/**
 * __useGetUserSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetUserSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSearchResultsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetUserSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserSearchResultsQuery, types.GetUserSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserSearchResultsQuery, types.GetUserSearchResultsQueryVariables>(GetUserSearchResultsDocument, options);
      }
export function useGetUserSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserSearchResultsQuery, types.GetUserSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserSearchResultsQuery, types.GetUserSearchResultsQueryVariables>(GetUserSearchResultsDocument, options);
        }
export type GetUserSearchResultsQueryHookResult = ReturnType<typeof useGetUserSearchResultsQuery>;
export type GetUserSearchResultsLazyQueryHookResult = ReturnType<typeof useGetUserSearchResultsLazyQuery>;
export type GetUserSearchResultsQueryResult = Apollo.QueryResult<types.GetUserSearchResultsQuery, types.GetUserSearchResultsQueryVariables>;
export const GetUsersByNameDocument = gql`
    query GetUsersByName($name: String!, $minScore: Float) {
  searchUsers(limit: 20, offset: 0, params: {name: $name}, minScore: $minScore) {
    results {
      score
      item {
        __typename
        ... on User {
          id
          name
          avatar
        }
      }
    }
  }
}
    `;

/**
 * __useGetUsersByNameQuery__
 *
 * To run a query within a React component, call `useGetUsersByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetUsersByNameQuery(baseOptions: Apollo.QueryHookOptions<types.GetUsersByNameQuery, types.GetUsersByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUsersByNameQuery, types.GetUsersByNameQueryVariables>(GetUsersByNameDocument, options);
      }
export function useGetUsersByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUsersByNameQuery, types.GetUsersByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUsersByNameQuery, types.GetUsersByNameQueryVariables>(GetUsersByNameDocument, options);
        }
export type GetUsersByNameQueryHookResult = ReturnType<typeof useGetUsersByNameQuery>;
export type GetUsersByNameLazyQueryHookResult = ReturnType<typeof useGetUsersByNameLazyQuery>;
export type GetUsersByNameQueryResult = Apollo.QueryResult<types.GetUsersByNameQuery, types.GetUsersByNameQueryVariables>;
export const GetProjectSearchResultsIdsDocument = gql`
    query GetProjectSearchResultsIds($offset: Int!, $limit: Int!, $sort: [SearchSortEnum!], $params: ProjectSearchParamsInput!, $minScore: Float) {
  searchProjects(
    offset: $offset
    limit: $limit
    sort: $sort
    params: $params
    minScore: $minScore
  ) {
    total
    results {
      score
      item {
        __typename
        ... on Project {
          id
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectSearchResultsIdsQuery__
 *
 * To run a query within a React component, call `useGetProjectSearchResultsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSearchResultsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSearchResultsIdsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      params: // value for 'params'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetProjectSearchResultsIdsQuery(baseOptions: Apollo.QueryHookOptions<types.GetProjectSearchResultsIdsQuery, types.GetProjectSearchResultsIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetProjectSearchResultsIdsQuery, types.GetProjectSearchResultsIdsQueryVariables>(GetProjectSearchResultsIdsDocument, options);
      }
export function useGetProjectSearchResultsIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetProjectSearchResultsIdsQuery, types.GetProjectSearchResultsIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetProjectSearchResultsIdsQuery, types.GetProjectSearchResultsIdsQueryVariables>(GetProjectSearchResultsIdsDocument, options);
        }
export type GetProjectSearchResultsIdsQueryHookResult = ReturnType<typeof useGetProjectSearchResultsIdsQuery>;
export type GetProjectSearchResultsIdsLazyQueryHookResult = ReturnType<typeof useGetProjectSearchResultsIdsLazyQuery>;
export type GetProjectSearchResultsIdsQueryResult = Apollo.QueryResult<types.GetProjectSearchResultsIdsQuery, types.GetProjectSearchResultsIdsQueryVariables>;
export const GetSmartSearchResultsDocument = gql`
    query GetSmartSearchResults($text: String!, $minScore: Float) {
  searchProjects(params: {name: $text}, limit: 2, offset: 0, minScore: $minScore) {
    results {
      score
      item {
        __typename
        id
        name
        deleted
        projectType {
          id
          name
          logo
        }
      }
      highlights
    }
  }
  searchCollections(
    params: {name: $text}
    limit: 2
    offset: 0
    minScore: $minScore
  ) {
    results {
      item {
        __typename
        id
        name
      }
      highlights
    }
  }
  searchOrganizations(
    params: {name: $text}
    limit: 2
    offset: 0
    minScore: $minScore
  ) {
    results {
      item {
        __typename
        id
        name
        logo
      }
      highlights
    }
  }
  searchUsers(params: {name: $text}, limit: 2, offset: 0, minScore: $minScore) {
    results {
      item {
        __typename
        id
        name
        avatar
      }
      highlights
    }
  }
}
    `;

/**
 * __useGetSmartSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSmartSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartSearchResultsQuery({
 *   variables: {
 *      text: // value for 'text'
 *      minScore: // value for 'minScore'
 *   },
 * });
 */
export function useGetSmartSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<types.GetSmartSearchResultsQuery, types.GetSmartSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSmartSearchResultsQuery, types.GetSmartSearchResultsQueryVariables>(GetSmartSearchResultsDocument, options);
      }
export function useGetSmartSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSmartSearchResultsQuery, types.GetSmartSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSmartSearchResultsQuery, types.GetSmartSearchResultsQueryVariables>(GetSmartSearchResultsDocument, options);
        }
export type GetSmartSearchResultsQueryHookResult = ReturnType<typeof useGetSmartSearchResultsQuery>;
export type GetSmartSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSmartSearchResultsLazyQuery>;
export type GetSmartSearchResultsQueryResult = Apollo.QueryResult<types.GetSmartSearchResultsQuery, types.GetSmartSearchResultsQueryVariables>;
export const GetUserCollectionsDocument = gql`
    query GetUserCollections($id: ID!, $limit: Int!, $offset: Int!, $sort: [SearchSortEnum!]) {
  user(id: $id) {
    __typename
    id
    collections(limit: $limit, offset: $offset, sort: $sort) {
      items {
        ...CollectionCard
      }
      total
    }
  }
}
    ${CollectionCardFragmentDoc}`;

/**
 * __useGetUserCollectionsQuery__
 *
 * To run a query within a React component, call `useGetUserCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCollectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetUserCollectionsQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserCollectionsQuery, types.GetUserCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserCollectionsQuery, types.GetUserCollectionsQueryVariables>(GetUserCollectionsDocument, options);
      }
export function useGetUserCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserCollectionsQuery, types.GetUserCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserCollectionsQuery, types.GetUserCollectionsQueryVariables>(GetUserCollectionsDocument, options);
        }
export type GetUserCollectionsQueryHookResult = ReturnType<typeof useGetUserCollectionsQuery>;
export type GetUserCollectionsLazyQueryHookResult = ReturnType<typeof useGetUserCollectionsLazyQuery>;
export type GetUserCollectionsQueryResult = Apollo.QueryResult<types.GetUserCollectionsQuery, types.GetUserCollectionsQueryVariables>;
export const GetUserDetailDocument = gql`
    query GetUserDetail($id: ID!) {
  user(id: $id) {
    __typename
    id
    name
    avatar
    summary
    description
    createdOn
    jobTitle
    location
    affiliations {
      affiliationRole
      name
      url
    }
    projects(offset: 0, limit: 0) {
      total
    }
    collections(offset: 0, limit: 0) {
      total
    }
    avatar
  }
}
    `;

/**
 * __useGetUserDetailQuery__
 *
 * To run a query within a React component, call `useGetUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserDetailQuery, types.GetUserDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserDetailQuery, types.GetUserDetailQueryVariables>(GetUserDetailDocument, options);
      }
export function useGetUserDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserDetailQuery, types.GetUserDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserDetailQuery, types.GetUserDetailQueryVariables>(GetUserDetailDocument, options);
        }
export type GetUserDetailQueryHookResult = ReturnType<typeof useGetUserDetailQuery>;
export type GetUserDetailLazyQueryHookResult = ReturnType<typeof useGetUserDetailLazyQuery>;
export type GetUserDetailQueryResult = Apollo.QueryResult<types.GetUserDetailQuery, types.GetUserDetailQueryVariables>;
export const GetUserProjectsDocument = gql`
    query GetUserProjects($id: ID!, $limit: Int!, $offset: Int!, $sort: [SearchSortEnum!]) {
  user(id: $id) {
    __typename
    id
    projects(limit: $limit, offset: $offset, sort: $sort) {
      items {
        ...ProjectCard
      }
      total
    }
  }
}
    ${ProjectCardFragmentDoc}`;

/**
 * __useGetUserProjectsQuery__
 *
 * To run a query within a React component, call `useGetUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetUserProjectsQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserProjectsQuery, types.GetUserProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserProjectsQuery, types.GetUserProjectsQueryVariables>(GetUserProjectsDocument, options);
      }
export function useGetUserProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserProjectsQuery, types.GetUserProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserProjectsQuery, types.GetUserProjectsQueryVariables>(GetUserProjectsDocument, options);
        }
export type GetUserProjectsQueryHookResult = ReturnType<typeof useGetUserProjectsQuery>;
export type GetUserProjectsLazyQueryHookResult = ReturnType<typeof useGetUserProjectsLazyQuery>;
export type GetUserProjectsQueryResult = Apollo.QueryResult<types.GetUserProjectsQuery, types.GetUserProjectsQueryVariables>;
export const UpdateProfileNameDocument = gql`
    mutation UpdateProfileName($name: String!) {
  updateProfile(profile: {name: $name}) {
    __typename
    name
    initialized
  }
}
    `;
export type UpdateProfileNameMutationFn = Apollo.MutationFunction<types.UpdateProfileNameMutation, types.UpdateProfileNameMutationVariables>;

/**
 * __useUpdateProfileNameMutation__
 *
 * To run a mutation, you first call `useUpdateProfileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileNameMutation, { data, loading, error }] = useUpdateProfileNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProfileNameMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProfileNameMutation, types.UpdateProfileNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProfileNameMutation, types.UpdateProfileNameMutationVariables>(UpdateProfileNameDocument, options);
      }
export type UpdateProfileNameMutationHookResult = ReturnType<typeof useUpdateProfileNameMutation>;
export type UpdateProfileNameMutationResult = Apollo.MutationResult<types.UpdateProfileNameMutation>;
export type UpdateProfileNameMutationOptions = Apollo.BaseMutationOptions<types.UpdateProfileNameMutation, types.UpdateProfileNameMutationVariables>;
export const UpdateProfileSummaryDocument = gql`
    mutation UpdateProfileSummary($summary: String!) {
  updateProfile(profile: {summary: $summary}) {
    __typename
    summary
  }
}
    `;
export type UpdateProfileSummaryMutationFn = Apollo.MutationFunction<types.UpdateProfileSummaryMutation, types.UpdateProfileSummaryMutationVariables>;

/**
 * __useUpdateProfileSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateProfileSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileSummaryMutation, { data, loading, error }] = useUpdateProfileSummaryMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateProfileSummaryMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProfileSummaryMutation, types.UpdateProfileSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProfileSummaryMutation, types.UpdateProfileSummaryMutationVariables>(UpdateProfileSummaryDocument, options);
      }
export type UpdateProfileSummaryMutationHookResult = ReturnType<typeof useUpdateProfileSummaryMutation>;
export type UpdateProfileSummaryMutationResult = Apollo.MutationResult<types.UpdateProfileSummaryMutation>;
export type UpdateProfileSummaryMutationOptions = Apollo.BaseMutationOptions<types.UpdateProfileSummaryMutation, types.UpdateProfileSummaryMutationVariables>;
export const UpdateProfileDescriptionDocument = gql`
    mutation UpdateProfileDescription($description: String!) {
  updateProfile(profile: {description: $description}) {
    __typename
    id
    description
  }
}
    `;
export type UpdateProfileDescriptionMutationFn = Apollo.MutationFunction<types.UpdateProfileDescriptionMutation, types.UpdateProfileDescriptionMutationVariables>;

/**
 * __useUpdateProfileDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateProfileDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileDescriptionMutation, { data, loading, error }] = useUpdateProfileDescriptionMutation({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateProfileDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProfileDescriptionMutation, types.UpdateProfileDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProfileDescriptionMutation, types.UpdateProfileDescriptionMutationVariables>(UpdateProfileDescriptionDocument, options);
      }
export type UpdateProfileDescriptionMutationHookResult = ReturnType<typeof useUpdateProfileDescriptionMutation>;
export type UpdateProfileDescriptionMutationResult = Apollo.MutationResult<types.UpdateProfileDescriptionMutation>;
export type UpdateProfileDescriptionMutationOptions = Apollo.BaseMutationOptions<types.UpdateProfileDescriptionMutation, types.UpdateProfileDescriptionMutationVariables>;
export const UpdateProfileAvatarImageDocument = gql`
    mutation UpdateProfileAvatarImage($token: String, $clear: Boolean) {
  updateProfile(profile: {avatarToken: $token, clearAvatar: $clear}) {
    __typename
    avatar
  }
}
    `;
export type UpdateProfileAvatarImageMutationFn = Apollo.MutationFunction<types.UpdateProfileAvatarImageMutation, types.UpdateProfileAvatarImageMutationVariables>;

/**
 * __useUpdateProfileAvatarImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileAvatarImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileAvatarImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileAvatarImageMutation, { data, loading, error }] = useUpdateProfileAvatarImageMutation({
 *   variables: {
 *      token: // value for 'token'
 *      clear: // value for 'clear'
 *   },
 * });
 */
export function useUpdateProfileAvatarImageMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateProfileAvatarImageMutation, types.UpdateProfileAvatarImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateProfileAvatarImageMutation, types.UpdateProfileAvatarImageMutationVariables>(UpdateProfileAvatarImageDocument, options);
      }
export type UpdateProfileAvatarImageMutationHookResult = ReturnType<typeof useUpdateProfileAvatarImageMutation>;
export type UpdateProfileAvatarImageMutationResult = Apollo.MutationResult<types.UpdateProfileAvatarImageMutation>;
export type UpdateProfileAvatarImageMutationOptions = Apollo.BaseMutationOptions<types.UpdateProfileAvatarImageMutation, types.UpdateProfileAvatarImageMutationVariables>;
export const DownloadFileDocument = gql`
    query downloadFile($projectId: ID!, $filePath: String!) {
  downloadFile(projectId: $projectId, filePath: $filePath) {
    localPath
    etag
    size
    contentType
    downloadUrl
  }
}
    `;

/**
 * __useDownloadFileQuery__
 *
 * To run a query within a React component, call `useDownloadFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFileQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function useDownloadFileQuery(baseOptions: Apollo.QueryHookOptions<types.DownloadFileQuery, types.DownloadFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.DownloadFileQuery, types.DownloadFileQueryVariables>(DownloadFileDocument, options);
      }
export function useDownloadFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.DownloadFileQuery, types.DownloadFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.DownloadFileQuery, types.DownloadFileQueryVariables>(DownloadFileDocument, options);
        }
export type DownloadFileQueryHookResult = ReturnType<typeof useDownloadFileQuery>;
export type DownloadFileLazyQueryHookResult = ReturnType<typeof useDownloadFileLazyQuery>;
export type DownloadFileQueryResult = Apollo.QueryResult<types.DownloadFileQuery, types.DownloadFileQueryVariables>;
export const DownloadZipDocument = gql`
    query downloadZip($projectId: ID!) {
  downloadZip(projectId: $projectId) {
    localPath
    etag
    size
    contentType
    downloadUrl
    pendingSince
    progress
  }
}
    `;

/**
 * __useDownloadZipQuery__
 *
 * To run a query within a React component, call `useDownloadZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadZipQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDownloadZipQuery(baseOptions: Apollo.QueryHookOptions<types.DownloadZipQuery, types.DownloadZipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.DownloadZipQuery, types.DownloadZipQueryVariables>(DownloadZipDocument, options);
      }
export function useDownloadZipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.DownloadZipQuery, types.DownloadZipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.DownloadZipQuery, types.DownloadZipQueryVariables>(DownloadZipDocument, options);
        }
export type DownloadZipQueryHookResult = ReturnType<typeof useDownloadZipQuery>;
export type DownloadZipLazyQueryHookResult = ReturnType<typeof useDownloadZipLazyQuery>;
export type DownloadZipQueryResult = Apollo.QueryResult<types.DownloadZipQuery, types.DownloadZipQueryVariables>;