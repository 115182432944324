import { Typography } from '@mui/material'

import { useAppVersion } from '../lib'

export const AppVersion: React.FC = () => {
  const { version, commit, stage } = useAppVersion()

  if (stage === 'production') return null

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'yellow',
        margin: '0 0.5rem',
        fontWeight: 'bold',
        fontFamily: 'Roboto Mono, Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
        fontSize: '0.8rem',
      }}
    >
      {stage && <Typography variant="body2">{stage}</Typography>}
      {<Typography variant="body2">{version}</Typography>}
      {commit && <Typography variant="body2">{commit}</Typography>}
    </div>
  )
}
