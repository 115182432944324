import React, { RefObject, useRef } from 'react'
import {
  Box,
  Grid,
  GridProps,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material'

import { InteractiveMap, InteractiveMapController, InteractiveMapProps } from '../InteractiveMap'
import { GetProjectCardQuery } from '../../schema/base'
import { CommonProjectSearchProps, SearchViewTypeEnum } from './types'
import { ProjectCard, ProjectCardProps, ProjectCardSkeleton } from '../ItemCards'
import { PendingItem } from '../PendingItem'
import { SortOrderField } from '../SortOrderField'
import { Lookups } from '../lookups'
import { List, Map } from '@mui/icons-material'
import { InfiniscrollerWidget } from './InfiniscrollerWidget'

export type ProjectResultsProps = CommonProjectSearchProps & {
  NoResults: React.FC
  children?: React.ReactNode
  maxHeight?: number
  contained?: boolean
  geo?: { lng: number; lat: number; zoom: number }
  getProjectBoundsThumbProps?: ProjectCardProps['getProjectBoundsThumbProps']
  items: NonNullable<GetProjectCardQuery['project'] | PendingItem>[] | null // null is "Explore Mode"
  pageOffset?: number
  lookups: Lookups
  mapBoxRef?: RefObject<HTMLDivElement>
  mapController: InteractiveMapController
  onGotoWebRave: (id: string) => void
  onMapClick?: InteractiveMapProps['onClick']
  onMapHover?: InteractiveMapProps['onHover']
  onProjectRemoveClick?: ((projectId: string) => void) | null
  onFetchMoreProjects: () => void
  loadingProjects?: boolean
  loadingHasMore?: boolean
  viewType?: SearchViewTypeEnum
  onViewTypeChange: (viewType: SearchViewTypeEnum) => void
  type: 'Project' // for symmetry with OtherResults
}

export const ProjectResults: React.FC<ProjectResultsProps> = ({
  NoResults,
  children,
  maxHeight,
  getProjectBoundsThumbProps,
  items,
  pageOffset,
  contained,
  lookups,
  mapBoxRef,
  mapController,
  onMapClick,
  onGotoWebRave,
  onMapHover,
  onProjectRemoveClick,
  onFetchMoreProjects,
  loadingProjects,
  loadingHasMore,
  onSortChange,
  viewType = SearchViewTypeEnum.Map,
  onViewTypeChange,
  sort,
}) => {
  const listRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  const isMap = viewType === SearchViewTypeEnum.Map
  const isGrid = viewType !== SearchViewTypeEnum.Map

  const projListMapRowSx: SxProps<Theme> = isMap
    ? {
        overflow: 'hidden',
        height: '100%',
        minHeight: 600,
        maxHeight: maxHeight,
        flex: 1,
      }
    : {
        height: '100%',
        flex: 1,
      }
  const projListColumnSx: SxProps<Theme> = isMap
    ? {
        flex: 1,
        minWidth: '15rem',
        maxWidth: '24rem',
        display: 'flex',
        flexDirection: 'column',
      }
    : {
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }

  const cardGridProps: GridProps = isGrid
    ? contained
      ? {
          sm: 12,
          md: 6,
          lg: 4,
          xl: 4,
        }
      : {
          sm: 12,
          md: 6,
          lg: 4,
          xl: 3,
        }
    : {
        sm: 12,
      }

  return (
    <Box sx={{ height: '100%' }}>
      {/* This is the row with the Project column and (sometimes) the map */}
      <Stack direction="row" sx={projListMapRowSx}>
        {/* Project Card column */}
        <Box sx={projListColumnSx}>
          {/* Sort, map toggle buttons */}
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              borderBottom: 1,
              p: 1,
              paddingTop: 0,
              borderColor: 'divider',
              gap: 1,
            }}
          >
            <Tooltip title="Map/List View">
              <ToggleButtonGroup
                size="small"
                value={viewType}
                exclusive
                onChange={(_, value) => {
                  if (value) onViewTypeChange(value)
                }}
              >
                <ToggleButton value={SearchViewTypeEnum.Map}>
                  <Map />
                </ToggleButton>
                <ToggleButton value={SearchViewTypeEnum.List}>
                  <List />
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
            <Box sx={{ flexGrow: 1 }} />
            <SortOrderField sort={sort} onSortChange={onSortChange} />
          </Stack>
          {/* Here's our card grid */}
          <Box
            sx={{
              flex: 1,
              overflow: 'scroll',
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid
              ref={listRef}
              container
              spacing={2}
              padding={1}
              sx={
                {
                  // flex: '1 1 auto',
                }
              }
            >
              {(!items || items.length === 0) && <NoResults />}
              {items &&
                items.map((item) => {
                  if (item.__typename === 'PendingItem')
                    return (
                      // Box prevents card collapse
                      <Grid item key={item.id} {...cardGridProps}>
                        <ProjectCardSkeleton />
                      </Grid>
                    )
                  return (
                    // Grid prevents card collapse
                    <Grid item key={item.id} {...cardGridProps}>
                      <ProjectCard
                        getProjectBoundsThumbProps={
                          viewType === SearchViewTypeEnum.Map ? getProjectBoundsThumbProps : undefined
                        }
                        isGrid={isGrid}
                        onGotoWebRave={onGotoWebRave}
                        lookups={lookups}
                        onRemoveClick={onProjectRemoveClick}
                        project={item}
                      />
                    </Grid>
                  )
                })}
              {/* {!items && <div>TODO: explore mode explainer</div>} */}
              {items && items.length > 0 && (
                <Grid item sm={12}>
                  <InfiniscrollerWidget
                    hasMore={loadingHasMore}
                    loading={loadingProjects}
                    onFetchMore={onFetchMoreProjects}
                    pageTotal={items.length}
                    pageOffset={pageOffset}
                  />
                </Grid>
              )}
            </Grid>
            {/* Spacer Box */}
            <Box sx={{ flex: '1 1 0%' }} />
          </Box>
        </Box>

        {/* Map */}
        {viewType === SearchViewTypeEnum.Map && (
          <Box sx={{ flex: 1 }} ref={mapBoxRef}>
            <InteractiveMap controller={mapController} onClick={onMapClick} onHover={onMapHover}>
              {children}
            </InteractiveMap>
          </Box>
        )}
      </Stack>
    </Box>
  )
}
