import { ReactNode } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ModifySearchDialog, ProjectResults, ProjectResultsProps, SearchSpec } from '../Search'
import { GetProfileDetailQuery, GetUserDetailQuery, OwnerInputTypesEnum } from '../../schema/base'
import { useBooleanState, useFormats } from '../../lib'
import { Lookups } from '../lookups'
import { Search } from '@mui/icons-material'

export type ProjectsPanelProps = {
  user: NonNullable<GetUserDetailQuery['user']> | NonNullable<GetProfileDetailQuery['profile']>
  lookups: Lookups
  mapElements: ReactNode
  onSearch: (newSearch: SearchSpec) => void
  pageOffset?: number
} & Omit<
  ProjectResultsProps,
  keyof {
    NoResults: never
    children: never
    getProjectUrlById: never
    type: never
  }
>

export const ProjectsPanel: React.FC<ProjectsPanelProps> = ({
  user,
  getProjectBoundsThumbProps,
  items,
  lookups,
  mapBoxRef,
  mapController,
  mapElements,
  onMapClick,
  onGotoWebRave,
  onMapHover,
  onSearch,
  onSortChange,
  onFetchMoreProjects,
  loadingHasMore,
  loadingProjects,
  onViewTypeChange,
  pageOffset,
  viewType,
  sort,
}) => {
  const [isModifySearchOpen, openModifySearch, closeModifySearch] = useBooleanState()
  const { formatNumber, pluralize } = useFormats()

  const projectsTotal = user.projects.total

  // RENDER
  let showingText
  const loadedItems = (items || []).length
  if (loadedItems === pageOffset) {
    if (loadedItems > 0)
      showingText = (
        <Typography variant="caption" color="text.secondary">
          {loadedItems} loaded.
        </Typography>
      )
  } else {
    showingText = (
      <Typography variant="caption" color="text.secondary">
        Loaded {loadedItems} and {(pageOffset || loadedItems) - loadedItems} are invisible to you.
      </Typography>
    )
  }

  return (
    <>
      <Stack sx={{ height: '100%', gap: 1 }}>
        <Stack direction="row" sx={{ p: 2, borderBottom: 1, gap: 2, borderColor: 'divider' }}>
          <Stack direction="column" sx={{ flex: 1 }}>
            <Typography variant="h6">
              {user.name} owns <strong>{formatNumber(projectsTotal)}</strong> total{' '}
              {pluralize('project', projectsTotal)}
            </Typography>
            {showingText}
            <div>
              <Button disabled={!projectsTotal} onClick={openModifySearch}>
                {"Search This User's Projects"}
              </Button>
            </div>
          </Stack>
          <Stack>
            <Button
              startIcon={<Search />}
              onClick={() =>
                onSearch({
                  params: {
                    ownedBy: {
                      id: user.id,
                      type: OwnerInputTypesEnum.User,
                    },
                  },
                  type: 'Project',
                })
              }
            >
              Full map search
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ flex: 1, height: 0 }}>
          <ProjectResults
            maxHeight={600}
            contained
            onViewTypeChange={onViewTypeChange}
            viewType={viewType}
            onFetchMoreProjects={onFetchMoreProjects}
            loadingHasMore={loadingHasMore}
            loadingProjects={loadingProjects}
            NoResults={() => (
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '2em',
                }}
              >
                User does not own any projects.
              </Box>
            )}
            getProjectBoundsThumbProps={getProjectBoundsThumbProps}
            onGotoWebRave={onGotoWebRave}
            items={items}
            pageOffset={pageOffset}
            lookups={lookups}
            mapBoxRef={mapBoxRef}
            mapController={mapController}
            onMapClick={onMapClick}
            onMapHover={onMapHover}
            onSortChange={onSortChange}
            sort={sort}
            type="Project"
          >
            {mapElements}
          </ProjectResults>
        </Box>
      </Stack>
      <ModifySearchDialog
        open={isModifySearchOpen}
        onClose={closeModifySearch}
        lookups={lookups}
        onSearch={onSearch}
        type="Project"
        params={{
          ownedBy: {
            id: user.id,
            type: OwnerInputTypesEnum.User,
          },
        }}
        disabled={{ type: true }}
        canSearchClean
      />
    </>
  )
}
