import { createTheme } from '@mui/material'

export const fontFamilies = {
  jetBrains: 'JetBrains Mono, Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace',
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#004793',
      light: '#0661c1',
    },
    background: {
      default: '#edf1f5',
      paper: '#ffffff',
    },
    secondary: {
      main: '#21BFD4',
      contrastText: '#ffffff',
    },
    divider: '#88888888',
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeightRegular: 300,
    fontWeightLight: 300,
    h1: {
      fontSize: '3em',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h2: {
      fontSize: '2.6em',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h3: {
      fontSize: '2em',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.8em',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.5em',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.0em',
      lineHeight: 1.2,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1em',
      lineHeight: 1.5,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          background: '#edf1f5',
          height: '100%',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off', // this is actually browser auto-fill
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off', // this is actually browser auto-fill
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            padding: 1,
          }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            padding: 1,
          }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            padding: 1,
          }),
      },
    },
  },
})
