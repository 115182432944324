import { FeatureCollection } from 'geojson'
import { uniqueId } from 'lodash'
import { useEffect, useMemo } from 'react'
import { Layer, Source, CircleLayer, SymbolLayer } from 'react-map-gl'
import { useInteractiveMapContext } from './useInteractiveMapController'
import { useTheme } from '@mui/material'

export interface ClustersProps {
  data: FeatureCollection
  small?: boolean
}

export const Clusters: React.FC<ClustersProps> = ({ data, small }) => {
  const theme = useTheme()
  const { registerLayerId, unregisterLayerId } = useInteractiveMapContext()

  const countLayout: SymbolLayer['layout'] = {
    'text-field': '{countStr}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  }
  const countPaint: SymbolLayer['paint'] = {
    'text-color': theme.palette.primary.contrastText,
  }

  const countProps = {
    layout: countLayout,
    paint: countPaint,
  }

  const smallCirclePaint: CircleLayer['paint'] = {
    'circle-color': theme.palette.primary.light,
    'circle-stroke-color': theme.palette.primary.contrastText,
    'circle-stroke-width': 1,
    'circle-radius': 20,
    'circle-blur': 0.1,
    'circle-opacity': 0.6,
  }

  const variableCirclePaint: CircleLayer['paint'] = {
    'circle-color': theme.palette.primary.light,
    'circle-stroke-color': theme.palette.primary.contrastText,
    'circle-stroke-width': 1,
    'circle-radius': [
      'interpolate',
      ['exponential', 1],
      ['coalesce', ['get', 'total'], ['get', 'count']],
      1,
      20,
      2000,
      60,
    ],
    'circle-opacity': 0.8,
  }

  const layerIdPrefix = useMemo(() => `cluster-${uniqueId()}`, [])

  useEffect(() => {
    registerLayerId(`${layerIdPrefix}-cluster`)
    return () => {
      unregisterLayerId(`${layerIdPrefix}-cluster`)
    }
  }, [layerIdPrefix])

  return (
    <Source id={`${layerIdPrefix}-source`} type="geojson" data={data}>
      <Layer id={`${layerIdPrefix}-cluster`} type="circle" paint={small ? smallCirclePaint : variableCirclePaint} />
      <Layer id={`${layerIdPrefix}-cluster-count`} type="symbol" {...countProps} />
    </Source>
  )
}
