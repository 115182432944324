import { DateWithinEnum, SearchDateInput } from '@riverscapes/react-common'
import log from 'loglevel'
import dayjs from 'dayjs'

export const searchDateInputOrWithin = (
  searchDateInput: SearchDateInput | null | undefined,
  dateWithin: DateWithinEnum | null | undefined
): SearchDateInput | undefined => {
  if (searchDateInput) {
    if (dateWithin) log.warn('search date range and date within are both set, ignoring date within')
    return searchDateInput
  }
  if (dateWithin) {
    const today = dayjs(dayjs().format('YYYY-MM-DD'))
    switch (dateWithin) {
      case DateWithinEnum.ONE_DAY:
        return { from: today.subtract(1, 'day').format() }
      case DateWithinEnum.ONE_WEEK:
        return { from: today.subtract(1, 'week').format() }
      case DateWithinEnum.ONE_MONTH:
        return { from: today.subtract(1, 'month').format() }
      case DateWithinEnum.SIX_MONTHS:
        return { from: today.subtract(6, 'month').format() }
      default: {
        const exhaustiveCheck: never = dateWithin
        throw new Error(exhaustiveCheck)
      }
    }
  }
  return undefined
}
