import React from 'react'
import TreeContextMenu, { CtxMenuItemType } from '../components/tree/TreeContextMenu'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  layerStateSelectorFamily,
  symbologyToolAtom,
  TreeCtxState,
  treeInfoPaneIdAtom,
  treeInfoPaneOpenAtom,
} from '../recoil'
import useMapLayers from '../hooks/useMapLayers'
import useZoomExtents from '../hooks/useZoomExtents'

// Icons for our menu items
import {
  ZoomOutMap as ZoomOutMapIcon,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
  Download as DownloadIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Assessment as AssessmentIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Terminal,
} from '@mui/icons-material'
import { ProjectTreeLayerTypeEnum, SymbologyStateEnum, TilingStateEnum } from '@riverscapes/react-common'
import { BoundsObj } from '../types'

interface Props {
  ctxState: TreeCtxState
  downloadFile: (fkey: string) => Promise<void>
  onClose: () => void
}

const TreeContextMenuConnect: React.FC<Props> = ({ ctxState, onClose, downloadFile }: Props) => {
  const layer = useRecoilValue(layerStateSelectorFamily(ctxState.leafId))
  const setTreeInfoPaneId = useSetRecoilState(treeInfoPaneIdAtom)
  const setTreeeInfoPaneOpen = useSetRecoilState(treeInfoPaneOpenAtom)
  const setSymbologyToolAtom = useSetRecoilState(symbologyToolAtom)
  const { addLayer, removeLayer } = useMapLayers()
  const zoomLayerExtent = useZoomExtents()
  const menuItems: CtxMenuItemType = []

  if (layer.leaf.layerType === ProjectTreeLayerTypeEnum.Report) {
    if (layer.tileState === TilingStateEnum.Fetching || layer.tileState === TilingStateEnum.Queued) {
      menuItems.push({
        label: 'Report queued for upload...',
        Icon: HourglassEmptyIcon,
        disabled: true,
      })
    } else {
      menuItems.push({
        label: 'Open Report',
        Icon: AssessmentIcon,
        disabled: !(layer.tiles?.url && layer.tileState === TilingStateEnum.Success),
        handleClick: (): void => {
          const newWindow = window.open(layer.tiles?.url + 'index.html', '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
          onClose()
        },
      })
    }
  } else if (layer.leaf.layerType === ProjectTreeLayerTypeEnum.File) {
    menuItems.push({
      label: 'Download file',
      Icon: DownloadIcon,
      handleClick: (): void => {
        if (layer.leaf.filePath) downloadFile(layer.leaf.filePath)
        onClose()
      },
    })
  } else if (layer.tileState === TilingStateEnum.Fetching || layer.symbologyState === SymbologyStateEnum.Fetching) {
    menuItems.push({
      label: 'Loading tile data...',
      Icon: HourglassEmptyIcon,
      disabled: true,
    })
  } else {
    // Now we add some layers
    if (layer.tileState === TilingStateEnum.Success) {
      if (layer.legendActive) {
        menuItems.push(
          {
            label: 'Remove from map',
            Icon: RemoveCircleIcon,
            handleClick: (): void => {
              removeLayer(layer.leaf.id)
              onClose()
            },
          },
          {
            label: 'Zoom layer extents',
            Icon: ZoomOutMapIcon,
            // disabled: Boolean(layer.tiles?.bounds),
            handleClick: (): void => {
              zoomLayerExtent(layer.tiles?.bounds as BoundsObj)
              onClose()
            },
          }
        )
      } else if (!layer.legendActive && layer.renderable) {
        menuItems.push(
          {
            label: 'Add to map',
            Icon: AddCircleIcon,
            handleClick: (): void => {
              addLayer(layer.leaf.id)
              onClose()
            },
          },
          {
            label: 'Zoom layer extents (add to map first)',
            Icon: ZoomOutMapIcon,
            disabled: true,
          }
        )
      }
    } else if (layer.tileState === TilingStateEnum.Queued) {
      menuItems.push({
        label: 'Queued for tiling...',
        Icon: HourglassEmptyIcon,
        disabled: true,
      })
    } else if (layer.tileState === TilingStateEnum.NotApplicable) {
      menuItems.push({
        label: 'Tiling is not applicable to this layer',
        Icon: ErrorIcon,
        disabled: true,
      })
    } else if (layer.tileState === TilingStateEnum.NoGeometries) {
      menuItems.push({
        label: 'No Geometries were found',
        Icon: ErrorIcon,
        disabled: true,
      })
    } else if (layer.tileState === TilingStateEnum.Creating) {
      menuItems.push({
        label: 'Currently building tiles...',
        Icon: HourglassEmptyIcon,
        disabled: true,
      })
    } else if (layer.tileState === TilingStateEnum.TilingError) {
      menuItems.push({
        label: 'Tiles did not build',
        Icon: ErrorIcon,
        disabled: true,
        error: true,
      })
    } else if (layer.tileState === TilingStateEnum.Timeout) {
      menuItems.push({
        label: 'Tiling timed out',
        Icon: ErrorIcon,
        disabled: true,
        error: true,
      })
    } else if (layer.tileState === TilingStateEnum.IndexNotFound) {
      menuItems.push({
        label: 'Error fetching tile info',
        Icon: ErrorIcon,
        disabled: true,
        error: true,
      })
    } else if (layer.tileState === TilingStateEnum.LayerNotFound) {
      menuItems.push({
        label: 'The original GIS layer is missing',
        Icon: ErrorIcon,
        disabled: true,
        error: true,
      })
    } else if (layer.tileState === TilingStateEnum.FetchError) {
      menuItems.push({
        label: 'Error fetching tile info',
        Icon: ErrorIcon,
        disabled: true,
        error: true,
      })
    }
    menuItems.push({
      label: 'Layer Information',
      Icon: InfoIcon,
      handleClick: (): void => {
        setTreeInfoPaneId(ctxState.leafId)
        setSymbologyToolAtom(null)
        setTreeeInfoPaneOpen(true)
        onClose()
      },
    })

    if (layer.tileState === TilingStateEnum.Success) {
      const canHaveSymbology: ProjectTreeLayerTypeEnum[] = [
        ProjectTreeLayerTypeEnum.Point,
        ProjectTreeLayerTypeEnum.Line,
        ProjectTreeLayerTypeEnum.Polygon,
      ]
      if (layer.symbology && layer.leaf.layerType && canHaveSymbology.includes(layer.leaf.layerType)) {
        menuItems.push({
          label: 'Symbology Tool',
          Icon: Terminal,
          handleClick: (): void => {
            setTreeeInfoPaneOpen(true)
            setTreeInfoPaneId(null)
            setSymbologyToolAtom([layer.symbology?.name as string, false])
            onClose()
          },
        })
      }
    }
  }

  return (
    <TreeContextMenu open={Boolean(ctxState)} position={ctxState.position} onClose={onClose} menuItems={menuItems} />
  )
}

export default TreeContextMenuConnect
