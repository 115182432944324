/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-unused-vars */
import MapboxGL, { AnySourceData } from 'mapbox-gl'
import { BaseLayer } from '../types'
import { SourceProps, LayerProps, MapRef } from 'react-map-gl'
import { RasterLayer, FillLayer, CircleLayer, LineLayer } from 'mapbox-gl'
import log from 'loglevel'
import defaultSymbology from '../components/map/defaultSymbology'
import { TileService, SymbologyStateEnum, ProjectTreeLayerTypeEnum } from '@riverscapes/react-common'

import { legendColors, initMapStyle } from '../config'
import { calculateOpacity } from '../lib/util'
import { RenderableMapLayer, MapLayerState } from '../recoil'
import { isUndefined } from 'lodash'
import uuid4 from 'uuid/v4'
import { ObjectValues } from '@riverscapes/react-common'
// Sources accumulate but layers get swapped in and out

export type FixedMapBoxExport = {
  sources: MapboxGL.Sources
  layers: MapboxGL.AnyLayer[]
  glyphs?: string
  sprite?: string
}

export type MapLayerMeta = {
  mType: string
  mIdx: number
  mRef: string
  symbologyName?: string
  symbologyCount?: number
}

export const MapLayerTypesEnum = {
  bgLayer: 'BGL',
  userContent: 'USRLYR',
  // These don't sort
  bgSource: 'BGSRC',
  layerSource: 'SRC',
  // DEBUG LAYERS always are on top of everything
  debug: 'DEBUG',
}
export type MapLayerTypesEnum = ObjectValues<typeof MapLayerTypesEnum>

// Helper functions to make sure we name layers and sources consistently
const sourceIdGen = (lpath?: string, sName?: string): string => {
  return `${MapLayerTypesEnum.layerSource}_${lpath || 'NOPATH'}_${sName || 'SRC'}`
}

const layerIdGen = (lpath?: string, sName?: string, subIdx = 0): string =>
  `${MapLayerTypesEnum.userContent}_${lpath || 'UNKNOWN'}_${sName || 'NOSYMB'}_${subIdx}`

const checkLoaded = (map: any): boolean => Boolean(map && map.isStyleLoaded())
const getMapLayerIds = (map: any, filter?: MapLayerTypesEnum): string[] => {
  const mStyle = map.getStyle().layers
  return mStyle.filter(({ metadata }) => isUndefined(filter) || metadata.mType === filter).map(({ id }) => id)
}

const metaMaker = (
  mType: MapLayerTypesEnum,
  mIdx = 0,
  mRef = '',
  symbologyName?: string,
  symbologyCount?: number
): MapLayerMeta => ({
  mType,
  mIdx,
  mRef,
  symbologyName,
  symbologyCount,
})

/**
 * This function changes Riverscapes layer order if we need to move any slots around
 * @param newLayers
 */
export function userLayerRecalc(map: MapRef, renderablelayers: RenderableMapLayer[]): boolean {
  if (!checkLoaded(map)) return false
  let mapInst = map.getMap()
  const start = Date.now()

  // Keep track of everything that's supposed to be on the map so we can delete everything else
  const validLayerIds: string[] = []
  let beforeId: string | undefined

  // If symbology is still loading or anything else goes wrong we want to keep this boolean dirty so
  // changes will continue to cause map refreshes
  let succeeded = true
  // First make sure we have a divider layer
  renderablelayers
    .slice() // renderablelayers is immutable so make a sliced copy before reversing
    .reverse()
    .forEach((lyr) => {
      // We tightly control the Ids. Rasters have one source per symbology.
      // Vectors share a source but have different layers
      const sourceId =
        lyr.leaf.layerType === ProjectTreeLayerTypeEnum.Raster
          ? sourceIdGen(lyr.leaf.rsXPath, lyr.leaf.symbology as string)
          : sourceIdGen(lyr.leaf.rsXPath, lyr.leaf.lyrName || lyr.leaf.nodeId || uuid4())

      // Add the source (if necessary)
      if (!mapInst.getSource(sourceId)) {
        if (lyr.leaf.layerType === ProjectTreeLayerTypeEnum.Raster) {
          mapInst = mapInst.addSource(sourceId, getRasterSource(lyr.tiles as TileService, lyr.leaf.symbology as string))
        } else {
          mapInst = mapInst.addSource(
            sourceId,
            getVectorSource(lyr.tiles as TileService, lyr.leaf.lyrName || lyr.leaf.nodeId || uuid4())
          )
        }
      }

      // If this is a RASTER layer then it's a lot easier
      if (lyr.leaf.layerType === ProjectTreeLayerTypeEnum.Raster) {
        const layerId = layerIdGen(lyr.leaf.rsXPath, lyr.leaf.symbology as string)
        validLayerIds.push(layerId)
        if (!mapInst.getLayer(layerId)) {
          log.debug(`DEBUG::MM: Adding raster layer "${layerId}" before "${beforeId}"`)
          mapInst = mapInst.addLayer(
            {
              ...getRasterLayerProps(lyr, layerId, sourceId),
              metadata: metaMaker(
                MapLayerTypesEnum.userContent,
                undefined,
                `${lyr.leaf.rsXPath}/${lyr.leaf.symbology}`
              ),
            } as FillLayer | CircleLayer | LineLayer,
            beforeId
          )
          beforeId = layerId
        }
      } else {
        if (lyr.symbologyState === SymbologyStateEnum.Fetching) {
          succeeded = false
          return
        }

        // Layer has already been added. We can set the layer order but otherwise do nothing
        // fetch the symbology from the state object
        let symbJSONArr = (lyr.symbology?.mapboxJson || []) as Partial<LayerProps>[]
        // If we need a default fallback symbology then go create one
        if ((!symbJSONArr || symbJSONArr.length === 0) && lyr.randomColor !== null) {
          const rndCol = legendColors[lyr.randomColor]
          switch (lyr.leaf.layerType) {
            case ProjectTreeLayerTypeEnum.Line:
              symbJSONArr = [defaultSymbology.line(lyr.leaf, rndCol, lyr.tiles)]
              break
            case ProjectTreeLayerTypeEnum.Polygon:
              symbJSONArr = [
                defaultSymbology.polygon(lyr.leaf, rndCol, lyr?.tiles),
                defaultSymbology.outline(lyr.leaf, rndCol, lyr?.tiles),
              ]
              break
            case ProjectTreeLayerTypeEnum.Point:
              symbJSONArr = [defaultSymbology.point(lyr.leaf, rndCol, lyr.tiles)]
              break
            default:
              break
          }
        }
        // Vector user layers can have multiple mapbox layers.
        symbJSONArr.forEach((symb, subIdx) => {
          const subLyrId = layerIdGen(
            `${lyr.leaf.rsXPath}/${lyr.leaf.lyrName || lyr.leaf.nodeId}`,
            lyr.leaf.symbology as string,
            subIdx
          )
          validLayerIds.push(subLyrId)
          // If this is the last item (idx== 0 because of reverse) then tuck it behind the slot placeholder
          // Otherwise put it behind its predecessor
          const mapLyr: MapboxGL.AnyLayer = mapInst.getLayer(subLyrId)
          if (!mapLyr) {
            log.debug(`DEBUG::MM: Adding vector sublayer "${subLyrId}" before "${beforeId}"`)
            mapInst = mapInst.addLayer(
              {
                ...getVectorLayerProps(lyr, symb, subLyrId, sourceId),
                metadata: metaMaker(
                  MapLayerTypesEnum.userContent,
                  subIdx,
                  `${lyr.leaf.rsXPath}/${lyr.leaf.symbology}`,
                  lyr.symbology?.name,
                  lyr.symbologyCounter
                ),
              } as RasterLayer,
              beforeId
            )
            beforeId = subLyrId
          } else {
            if (
              (((mapLyr as MapboxGL.LineLayer).metadata as MapLayerMeta) || {}).symbologyCount !== lyr.symbologyCounter
            ) {
              mapInst = mapInst.removeLayer(subLyrId).addLayer(
                {
                  ...getVectorLayerProps(lyr, symb, subLyrId, sourceId),
                  metadata: metaMaker(
                    MapLayerTypesEnum.userContent,
                    subIdx,
                    `${lyr.leaf.rsXPath}/${lyr.leaf.symbology}`,
                    lyr.symbology?.name,
                    lyr.symbologyCounter
                  ),
                } as RasterLayer,
                beforeId
              )
            }
            log.debug(`DEBUG::MM: Already found "${subLyrId}" in the map`)
          }
        })
      }
    })
  // Now we need to clean up anything that shouldn't be on the map
  getMapLayerIds(mapInst, MapLayerTypesEnum.userContent).forEach((lyrId) => {
    if (validLayerIds.indexOf(lyrId) < 0) {
      log.debug(`DEBUG::MM: Removing unused layer: ${lyrId}`, { validLayerIds })
      mapInst = mapInst.removeLayer(lyrId)
    }
  })
  // Finally if anything has changed call the sort function to put everything in the right order
  log.debug(`DEBUG::MM: userLayerRecalc took ${Date.now() - start} ms`)
  return succeeded
}

/**
 * Here we build up the array we want to have for layer order and then correct it, making the smallest number of moves
 * possible
 * @param map
 * @param renderablelayers
 * @returns
 */
export function verifyLayerOrder(map: any, renderablelayers: RenderableMapLayer[]): boolean {
  if (!checkLoaded(map)) return false
  let mapInst = map

  const lMeta = getMapLayerIds(mapInst).reduce<Record<string, MapLayerMeta>>(
    (acc, lyrId) => ({ ...acc, [lyrId]: mapInst.getLayer(lyrId).metadata as MapLayerMeta }),
    {}
  )

  const usrLyrOrder = renderablelayers.map(({ leaf }) => `${leaf.rsXPath}/${leaf.symbology}`).reverse()

  // The background stack
  const bgIds = getMapLayerIds(mapInst, MapLayerTypesEnum.bgLayer)
  bgIds.sort((a: string, b: string) => lMeta[a].mIdx - lMeta[b].mIdx)

  // The debug stack
  const debugIds = getMapLayerIds(mapInst, MapLayerTypesEnum.debug)
  debugIds.sort((a: string, b: string) => lMeta[a].mIdx - lMeta[b].mIdx)

  const slotIds = getMapLayerIds(mapInst, MapLayerTypesEnum.userContent)
  slotIds.sort((a: string, b: string) => {
    // If we're not in the same slot then the sort is easy
    const delta = usrLyrOrder.indexOf(lMeta[a].mRef) - usrLyrOrder.indexOf(lMeta[b].mRef)
    if (delta !== 0) return delta
    // If we're in the same slot then sort by index
    else {
      return lMeta[a].mIdx - lMeta[b].mIdx
    }
  })

  // This will be the truth
  const correctStack: string[] = [...bgIds, ...slotIds, ...debugIds]
  correctStack.reverse()

  // Now we have the layer order and the intended layer order
  log.debug('DEBUG::MM: verifyLayerOrder', { have: getMapLayerIds(mapInst).reverse(), need: correctStack })

  let allLayers = getMapLayerIds(mapInst)
  allLayers.reverse()
  correctStack.forEach((needId, needIdx) => {
    if (needIdx !== allLayers.indexOf(needId)) {
      const beforeId = needIdx === 0 ? null : correctStack[needIdx - 1]
      log.debug(`DEBUG::MM: Move Needed ${needId} to before ${beforeId}`)
      mapInst = mapInst.moveLayer(needId, beforeId)
      allLayers = getMapLayerIds(mapInst)
      allLayers.reverse()
    }
  })
  return true
}

/**
 * When the user changes or initializes a base layer we need to swap out all the base layers that
 * may be affected
 * @param newBase
 * @returns
 */
export function changeBaseLayer(map: any, newBase: BaseLayer): boolean {
  if (!checkLoaded(map)) return false
  log.debug('DEBUG::MM: changeBaseLayer', newBase.id)
  const start = Date.now()
  let mapInst = map.getMap()

  const validBgLayers: string[] = []

  // 1. Add any new sources we might need
  Object.keys(newBase.sources).forEach((newSourceKey) => {
    if (!mapInst.getSource(newSourceKey)) mapInst = mapInst.addSource(newSourceKey, newBase.sources[newSourceKey])
  })

  // 2. Add any background layers Back in reverse order
  let beforeId: string | null = null
  let addCounter = 0
  newBase.layers.forEach((lyr) => {
    // Layer names should be unique so if they are just move them
    if (!mapInst.getLayer(lyr.id)) {
      mapInst = mapInst.addLayer(lyr, beforeId)
      addCounter++
    }
    validBgLayers.push(lyr.id)
    beforeId = lyr.id
  })
  log.debug(`DEBUG::MM: changeBaseLayer Added ${addCounter} background layers`)

  // 3. Find any old background layers and remove them
  let removeCounter = 0
  getMapLayerIds(mapInst)
    .filter((lyrId) => lyrId.indexOf(MapLayerTypesEnum.bgLayer) === 0)
    .filter((lyrId) => validBgLayers.indexOf(lyrId) < 0)
    .forEach((lyrId) => {
      removeCounter++
      mapInst = mapInst.removeLayer(lyrId)
    })
  if (removeCounter > 0) log.debug(`DEBUG::MM: changeBaseLayer Removed ${removeCounter} unneeded background layers`)

  // Now we need to Put All the placeholders back where they should be if anything has changed
  log.debug(`DEBUG::MM: userLayerRecalc took ${Date.now() - start} ms`)
  return true
}

export function createInitialStyle(baseLayer: BaseLayer): Partial<MapboxGL.Style> {
  return {
    ...initMapStyle,
    sources: baseLayer.sources,
    layers: baseLayer.layers,
  }
}

/**
 * This is just stripping out all the useful information from a direct export from Mapbox
 * We prefix the IDs too so that we can find them easier for map shuffling
 * @param sourcePrefix
 * @param rawStyle
 * @returns
 */
export function fixMapboxExport(sourcePrefix: string, rawStyle: MapboxGL.Style): FixedMapBoxExport {
  if (!rawStyle.sources) return { sources: {}, layers: [] }
  const rawSources = rawStyle.sources || {}
  const rawLayers = rawStyle.layers || []
  const glyphs = rawStyle.glyphs
  const sprite = rawStyle.sprite
  const sourceLookup: Record<string, string> = {}
  const sources = Object.keys(rawStyle.sources).reduce((acc, sKey) => {
    const newId = `${MapLayerTypesEnum.bgSource}_${sourcePrefix}_${sKey}`
    const source = rawSources[sKey]
    // Add to the lookup so we can find this in the layers later
    sourceLookup[sKey] = newId
    return {
      ...acc,
      [newId]: source,
    }
  }, {})
  const layers = rawLayers.map<MapboxGL.AnyLayer>((lyr, idx) => {
    const lyrMeta = (lyr.type !== 'custom' && lyr.metadata) || {}
    const newLyr = {
      ...lyr,
      metadata: { ...lyrMeta, ...metaMaker(MapLayerTypesEnum.bgLayer, idx) },
      id: `${MapLayerTypesEnum.bgLayer}_${idx}_${lyr.id}`,
    }
    // IF the source is specified (and it is not a complex type) then go get it from the lookup
    if (
      newLyr.type !== 'background' &&
      newLyr.type !== 'circle' &&
      newLyr.type !== 'custom' &&
      newLyr.source &&
      typeof newLyr.type === 'string'
    )
      newLyr.source = sourceLookup[newLyr.source.toString()]
    return newLyr
  })
  return { sources, layers, glyphs, sprite }
}

function getVectorSource(tiles: TileService, layerId?: string): AnySourceData {
  const layerIdPath = `${layerId}/`
  const finalUrl = `${tiles.url}${layerIdPath}{z}/{x}/{y}.${tiles.format || 'pbf'}`
  return {
    type: 'vector',
    maxzoom: tiles.maxZoom || 20,
    minzoom: tiles.minZoom || 4,
    tiles: [finalUrl],
  }
}
function getRasterSource(tiles: TileService, symbologyName?: string): AnySourceData {
  return {
    type: 'raster',
    scheme: 'tms',
    maxzoom: tiles.maxZoom || 20,
    minzoom: tiles.minZoom || 4,
    tiles: [`${tiles.url}${symbologyName || 'raster'}/{z}/{x}/{y}.${tiles.format || 'png'}`],
  }
}

function getRasterLayerProps(layer: MapLayerState, id: string, sourceId: string): LayerProps {
  const lyrProps: LayerProps = {
    type: 'raster',
    id,
    source: sourceId,
    minzoom: layer.tiles?.minZoom || 0,
    maxzoom: 24,
    layout: {},
    paint: {},
  }
  if (layer.leaf.transparency && layer.leaf.transparency > 0 && lyrProps.paint) {
    lyrProps.paint['raster-opacity'] = calculateOpacity(layer.leaf.transparency)
  }
  return lyrProps
}

function getVectorLayerProps(
  layer: MapLayerState,
  symb: Record<string, unknown>,
  id: string,
  sourceId: string
): LayerProps {
  const lyrProps: Partial<LayerProps> = {
    // this will get overridden by
    minzoom: layer.tiles?.minZoom || 0,
    maxzoom: 24, // hardcoded to the max value possible
    layout: {},
    paint: {},
    ...symb,
    id,
    source: sourceId,
    'source-layer': layer.leaf.lyrName || layer.leaf.nodeId || uuid4(),
  }
  const lineLayer = lyrProps as LineLayer
  const circleLayer = lyrProps as CircleLayer
  if (layer.leaf.transparency && layer.leaf.transparency > 0) {
    const opacity = calculateOpacity(layer.leaf.transparency)
    switch (lyrProps.type) {
      case 'fill':
        lyrProps.paint = {
          ...lyrProps.paint,
          ['fill-opacity']: lyrProps.paint && lyrProps.paint['fill-opacity'] ? lyrProps.paint['fill-opacity'] : opacity,
        }
        break
      case 'line':
        lineLayer.paint = {
          ...lineLayer.paint,
          ['line-opacity']:
            lineLayer.paint && lineLayer.paint['line-opacity'] ? lineLayer.paint['line-opacity'] : opacity,
        }
        break
      case 'circle':
        circleLayer.paint = {
          ...circleLayer.paint,
          ['circle-opacity']:
            circleLayer.paint && circleLayer.paint['circle-opacity'] ? circleLayer.paint['circle-opacity'] : opacity,
        }
        break
      default:
        break
    }
  }
  return lyrProps as LayerProps
}
