/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import MuiSpeedDial from '@mui/material/SpeedDial'
import MuiSpeedDialAction from '@mui/material/SpeedDialAction'
import SatelliteIcon from '@mui/icons-material/Satellite'

import { MapStyleKey, mapStylesThumbs } from './styles'

interface StyleControlProps {
  value: MapStyleKey
  onChange: (updated: MapStyleKey) => void
}

const styleKeys = Object.keys(mapStylesThumbs) as MapStyleKey[]
const tooltips: Record<MapStyleKey, string> = {
  basic: 'Basic',
  sat: 'Satellite',
  topo: 'Topographic',
}

const StyleThumb = ({ value, styleKey, onChange }: StyleControlProps & { styleKey: MapStyleKey }) => (
  <img
    src={mapStylesThumbs[styleKey]}
    css={{
      cursor: 'pointer',
      height: 40,
      width: 40,
      border: value === styleKey ? '2px solid blue' : '2px solid white',
      borderRadius: 10,
      margin: 2,
    }}
    onClick={(e) => {
      e.stopPropagation()
      if (value !== styleKey) onChange(styleKey)
    }}
  />
)

export const StyleControl: React.FC<StyleControlProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false) // using controlled state to prevent large empty div blocking map hover when closed

  return (
    <div>
      <MuiSpeedDial
        ariaLabel="Style"
        hidden={false}
        FabProps={{ size: 'small' }}
        icon={<SatelliteIcon />}
        direction="right"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        css={{ minHeight: '56px' }} // hack to prevent collapse
      >
        {isOpen &&
          styleKeys.map((styleKey) => (
            <MuiSpeedDialAction
              key={styleKey}
              tooltipTitle={tooltips[styleKey]}
              icon={<StyleThumb styleKey={styleKey} value={value} onChange={onChange} />}
            />
          ))}
      </MuiSpeedDial>
    </div>
  )
}
