/**
 * Footer component
 */

import React from 'react'
import { Container, Stack, Typography } from '@mui/material'

export const Copyright: React.FC = () => {
  return (
    <Container maxWidth={'xl'}>
      <Stack
        direction="row"
        justifyContent={'flex-end'}
        alignItems={'center'}
        gap={3}
        onClick={() => window.open(`https://creativecommons.org/licenses/by/4.0/`, '_blank')}
        sx={{
          borderTop: `1px solid rgba(255, 255, 255, 0.1)`,
          cursor: 'pointer',
          color: 'white',
          p: [2, 'auto'],
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/footer/icon-copyright.png`}
          width={36}
          alt="copyright logo"
          placeholder="none"
        />
        <img src={`${process.env.PUBLIC_URL}/footer/icon-person.png`} width={36} alt="Person logo" placeholder="none" />
        <Typography variant="body1" sx={{ '&, & *': { color: 'white' } }}>
          Riverscapes Consortium
        </Typography>
      </Stack>
    </Container>
  )
}
