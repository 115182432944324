import React, { ReactElement } from 'react'
import { Box, CardContent, CardHeader, Tooltip, TooltipProps, Typography, useTheme } from '@mui/material'

import { ProjectTypeItemFragment } from '../../schema/base'
import { Stack } from '@mui/system'
import { ItemRepresentation, ItemRepresentationProps } from '../ItemImage'
import { useFormats } from '../../lib'
import { EditableTypography } from '../EditableTypography'
import { PendingItem } from '../PendingItem'

export interface ProjectTypeTooltipCardProps {
  projectType: PendingItem | Partial<ProjectTypeItemFragment>
  projectCount?: number
  children: ReactElement<any, any>
  tooltipProps?: TooltipProps
}

export const ProjectTypeTooltipCard: React.FC<ProjectTypeTooltipCardProps> = ({
  projectType,
  projectCount,
  children,
  tooltipProps,
}) => {
  const theme = useTheme()
  const { formatNumber } = useFormats()

  return (
    <Tooltip
      arrow
      {...tooltipProps}
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: 400,
            '& a': {
              color: theme.palette.secondary.main,
            },
          },
        },
      }}
      title={
        <Box>
          <CardHeader
            title={
              <Stack direction="column" spacing={1}>
                <Typography variant="overline">Riverscapes Project Type:</Typography>
                <ItemRepresentation item={projectType as ItemRepresentationProps['item']} />
                {projectCount && projectCount > -1 && (
                  <Typography variant="caption">{formatNumber(projectCount)} projects</Typography>
                )}
              </Stack>
            }
          />
          {(projectType as ProjectTypeItemFragment).description && (
            <CardContent
              sx={{
                maxHeight: 100,
                overflow: 'hidden',
              }}
            >
              <EditableTypography
                canEdit={false}
                content={(projectType as ProjectTypeItemFragment).description || ''}
                multiline
                name="Description"
                variant="markdown"
              />
            </CardContent>
          )}
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}
