import { selector, atomFamily, atom } from 'recoil'
// import { orange } from '@material-ui/core/colors'
import { Symbology, ProjectTreeLayerTypeEnum, ProjectTreeLeaf } from '@riverscapes/react-common'
import { activeTreeLeavesSelector, projectMapLayerLeafsSelector } from './project'
import { isEqual } from 'lodash'

/****************************************
 * Tile Vector Symbology Library
 ****************************************/
/**
 * Selector for all the unique symbologies currently on the map
 */
export type SymbologyKey = [name: string, isRaster: boolean] // [name, isRaster]

const createSymbologyKeys = (leaves: ProjectTreeLeaf[]): SymbologyKey[] => {
  return leaves.reduce<SymbologyKey[]>((acc, leaf) => {
    if (!leaf.symbology) return acc
    const symbKey: SymbologyKey = [leaf.symbology, leaf.layerType === ProjectTreeLayerTypeEnum.Raster]
    if (!acc.find((sk) => isEqual(sk, symbKey))) return [...acc, symbKey]
    else return acc
  }, [])
}

/**
 * Selector for all the unique symbologies in the whole project
 * Use to initilize project-level downloader
 */
export const projectSymbologiesSelector = selector<SymbologyKey[]>({
  key: 'symbology/uniqueSymb',
  get: ({ get }) => {
    const mapLyrs = get(projectMapLayerLeafsSelector)
    return createSymbologyKeys(mapLyrs)
  },
})

// A unique symbology key consists of a string name and whether or not it is a raster
export const activeMapSymbologiesSelector = selector<SymbologyKey[]>({
  key: 'symbology/activeSymbologies',
  get: ({ get }) => {
    const activeSymb = get(activeTreeLeavesSelector)
    return createSymbologyKeys(activeSymb)
  },
})

/**
 * Atom for storing symbology. We use the apollo cache and a null component to do the work here.
 */
export const symbologyAtomFamily = atomFamily<Symbology | null, SymbologyKey>({
  key: 'symbology/symbology',
  default: null,
})
export const symbologyChangeCounterAtomFamily = atomFamily<number, SymbologyKey>({
  key: 'symbology/symbologyChangeCounter',
  default: 0,
})

export const symbologyToolAtom = atom<SymbologyKey | null>({
  key: 'tree/symbologyTool',
  default: null,
})
