import React from 'react'
import { Card, CardProps, Grid2Props, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { fontFamilies } from '@riverscapes/react-theme'

export interface HomeCardProps {
  gridWidths?: number
  children: React.ReactNode
  cardProps?: CardProps
  gridProps?: Grid2Props
}

export const HomeCard: React.FC<HomeCardProps> = ({ cardProps, gridProps, gridWidths, children }) => {
  const theme = useTheme()
  const finalGridWidths = gridWidths ?? 1
  const sm = finalGridWidths * 6 > 12 ? 12 : finalGridWidths * 6
  const md = finalGridWidths * 4 > 12 ? 12 : finalGridWidths * 4
  const lg = finalGridWidths * 3 > 12 ? 12 : finalGridWidths * 3
  return (
    <Grid2 xs={12} sm={sm} md={md} lg={lg} {...gridProps}>
      <Card
        {...cardProps}
        elevation={2}
        variant="elevation"
        sx={{
          position: 'relative',
          height: '100%',
          maxHeight: 350,
          display: 'flex',
          flexDirection: 'column',
          ...(cardProps || { sx: {} }).sx,
          '& .MuiCardHeader-root .MuiTypography-root': {
            fontFamily: fontFamilies.jetBrains,
            fontSize: '0.85rem',
          },
          '& .MuiCardHeader-root': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
        }}
      >
        {children}
      </Card>
    </Grid2>
  )
}
