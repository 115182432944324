import {
  Dataset,
  DatasetContainerTypesEnum,
  DatasetLayer,
  DatasetTypeEnum,
  ProjectTreeBranch,
  ProjectTreeLayerTypeEnum,
  ProjectTreeLeaf,
  ProjectTreeView,
} from './gen/schema.types'

export type JSONObject = string | number | boolean | { [x: string]: JSONObject } | Array<JSONObject>
type ObjectValues<T> = T[keyof T]

export type RSXPath = {
  datasetContainer: DatasetContainerTypesEnum
  datasetType: DatasetTypeEnum
  datasetXMLId: string
  realizationXMLId?: string
  analysisId?: string
}

export type WarehouseEl = {
  id: string
  ref?: string
  apiUrl: string
}

export interface BusinessLogicInterface {
  name: string
  description?: string
  projectType: string
  node: BusinessLogicNode
  defaultView?: string
  views?: ProjectTreeView[]
  // TODO: implement this.
  leaves?: any
  branches?: any
}

export type ProjectBLTree = BusinessLogicInterface & {
  leaves: ProjectTreeLeaf[]
  branches: ProjectTreeBranch[]
}

/**
 * This is just a useful object when we're trying to validate file paths
 */
export type FileParts = {
  ext: string // file extension (includes multiples like aux.xml)
  pathNoExt: string // the path with everything but the extension
  pathLower: string // lowercase path
  extLower: string //lowercase file extension
}

// Recursive node structure
export type BusinessLogicNode = {
  id?: string
  datatype?: ProjectTreeLayerTypeEnum
  symbology?: string
  transparency?: number
  label?: string
  xpath?: string
  xpathlabel?: string
  // URL for this node's TMS tiles
  tileservice?: string
  isRepeater?: boolean
  collapsed?: boolean
  children?: BusinessLogicNode[]
}

export const SQSJobEngineEnum = {
  TILERIZER: 'TILERIZER',
  JANITOR: 'JANITOR',
  ZIPPER: 'ZIPPER',
} as const
export type SQSJobEngineEnum = ObjectValues<typeof SQSJobEngineEnum>

export type SQSJob = {
  engine: SQSJobEngineEnum
  projectId: string
  projectType: string | null
  key: string
  // Used by the tilerizer to figure out if we need to do a run
  etag?: string
  // Used to find tilerizer paths (not used by other engiens)
  rsXPath?: string
  // These two get populated AFTER submission
  ReceiptHandle?: string
  queueUrl?: string
}

export type SQSQueueJobsResponse = {
  successful: SQSJob[]
  failed: SQSJob[]
}
