import { Box, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery } from '@mui/material'

import { ObjectValues } from '../Search'
import { Article, Groups, LibraryBooks, Person } from '@mui/icons-material'
import React, { useState } from 'react'
import { useTheme } from '@mui/system'

export interface StarredFiltersProps {
  filter: StarredModesEnum
  onChange: (newFilter: StarredModesEnum) => void
}

export const StarredModesEnum = {
  ALL: 'ALL',
  PROJECTS: 'PROJECTS',
  COLLECTIONS: 'COLLECTIONS',
  ORGANIZATIONS: 'ORGANIZATIONS',
  USERS: 'USERS',
} as const
export type StarredModesEnum = ObjectValues<typeof StarredModesEnum>

export const tooltips: Record<StarredModesEnum, string> = {
  [StarredModesEnum.ALL]: 'Show all items',
  [StarredModesEnum.PROJECTS]: 'Show only projects',
  [StarredModesEnum.COLLECTIONS]: 'Show only collections',
  [StarredModesEnum.ORGANIZATIONS]: 'Show only organizations',
  [StarredModesEnum.USERS]: 'Show only users',
}

export const StarredFilters: React.FC<StarredFiltersProps> = ({ filter, onChange }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const [hovering, setHovering] = useState<StarredModesEnum>(StarredModesEnum.ALL)
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newFilter: StarredModesEnum) => {
    onChange(newFilter)
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={tooltips[hovering]} placement="top">
        <ToggleButtonGroup value={filter} exclusive onChange={handleAlignment} size="small" sx={{ mb: 1 }}>
          <ToggleButton value={StarredModesEnum.ALL} onMouseOver={() => setHovering(StarredModesEnum.ALL)}>
            All
          </ToggleButton>
          <ToggleButton value={StarredModesEnum.PROJECTS} onMouseOver={() => setHovering(StarredModesEnum.PROJECTS)}>
            <Article />
            {matches && ' Projects'}
          </ToggleButton>
          <ToggleButton
            value={StarredModesEnum.COLLECTIONS}
            onMouseOver={() => setHovering(StarredModesEnum.COLLECTIONS)}
          >
            <LibraryBooks />
            {matches && ' Collections'}
          </ToggleButton>
          {/* <ToggleButton value={StarredModesEnum.USERS} onMouseOver={() => setHovering(StarredModesEnum.USERS)}>
            <Person />
            {matches && ' Users'}
          </ToggleButton> */}
          <ToggleButton
            value={StarredModesEnum.ORGANIZATIONS}
            onMouseOver={() => setHovering(StarredModesEnum.ORGANIZATIONS)}
          >
            <Groups />
            {matches && ' Orgs.'}
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  )
}
