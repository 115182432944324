import { useEffect } from 'react'

export const PageTitle = ({ title }: { title: string }): null => {
  useEffect(() => {
    const lastTitle = document.title
    document.title = title
    return () => {
      document.title = lastTitle
    }
  }, [title])

  return null
}
