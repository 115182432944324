import React, { useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue, useRecoilState } from 'recoil'
import { zoomBoundsAtom, tilesAtomFamily, projectAtom } from '../recoil'
import { appDefaults } from '../config'
import { boundsOverlap, newZoomBounds } from '../lib/util'
import { TileService, TilingStateEnum } from '@riverscapes/react-common'
// import log from 'loglevel'
import { isEqual } from 'lodash'
import { useGetLayerTilesQuery } from '../schema/operations'
import { ApolloError } from '@apollo/client'
import { BoundsObj } from '../types'

interface MPDProps {
  rsXPath: string
}

const MAX_RETRIES = 5
const RETRY_SECONDS = 20

const NORETRYSTATES: TilingStateEnum[] = [
  TilingStateEnum.Success,
  TilingStateEnum.TilingError,
  TilingStateEnum.Fetching,
  TilingStateEnum.NotApplicable,
]

const MapTilesConnect: React.FC<MPDProps> = ({ rsXPath }: MPDProps) => {
  const [pollInterval, setPollInterval] = useState(0)
  const [retries, setRetries] = useState(0)
  const [tLoad, setTiles] = useRecoilState(tilesAtomFamily(rsXPath))
  const project = useRecoilValue(projectAtom)

  const onRetryableError = (error?: ApolloError) => {
    // Fetching errors mean we either can't locate the file or some network problem occurred.
    // In these cases we want to retry only N times
    setRetries(retries + 1)
    setTiles({
      errorMsg: retries > MAX_RETRIES ? 'Max retries exceeded' : 'Fetch Error: retrying in 10 seconds',
      state: TilingStateEnum.FetchError,
      rsXPath: rsXPath,
      __typename: 'TileService',
    })
    if (retries > MAX_RETRIES) {
      setPollInterval(0)
    } else {
      setPollInterval(RETRY_SECONDS * 1000)
    }
  }

  // This is a hook that will fetch the tiles for this layer
  const layerTileQuery = useGetLayerTilesQuery({
    variables: {
      projectId: project?.id as string,
      projectTypeId: project?.projectType?.id as string,
      rsXPath: rsXPath,
    },
    // Tiles that are in an uncertain state like: `Tiling` or `Fetching` should be polled until they are ready
    // We can recheck them every N seconds
    pollInterval,
    onCompleted: (res) => {
      // A successful response with no data means NOT FOUND
      if (!res || !res.getLayerTiles) {
        return onRetryableError()
      }

      // If we get here we have a response with data we can use the tiles directly
      setTiles(res.getLayerTiles as TileService)
      if (NORETRYSTATES.includes(res.getLayerTiles.state)) {
        setPollInterval(0)
      } else {
        setPollInterval(RETRY_SECONDS * 1000)
      }
    },
    onError: onRetryableError,
    // If there is is no project then we can't fetch tiles
    skip: !project?.projectType?.id || !project?.id || !rsXPath,
  })
  /**
   * We use the `useGetLayerTilesQuery` hook to
   * fetch the tiles for this layer. We then use the `useRecoilCallback` hook to
   * update the `tilesAtomFamily` with the results of the query.
   */
  useEffect(() => {
    if (layerTileQuery.loading) {
      setTiles({
        errorMsg: 'Fetching',
        state: TilingStateEnum.Fetching,
        rsXPath: rsXPath,
        __typename: 'TileService',
      })
    }
  }, [layerTileQuery])

  /**
   * When a layer loads it can affect the overall bounds of the project.
   */
  const updateBoundsState = useRecoilCallback(({ snapshot, set }) => async () => {
    // Now we have to carefully decide whether to queue up another fetch or give up
    // Use the `snapshot` to get the current state of the atoms we care about
    const tiles = await snapshot.getPromise(tilesAtomFamily(rsXPath))
    if (!tiles) return
    const zoomBounds = await snapshot.getPromise(zoomBoundsAtom)

    if (
      tiles.state === TilingStateEnum.Success &&
      tiles.bounds &&
      boundsOverlap(tiles.bounds as BoundsObj, appDefaults.worldBounds)
    ) {
      // Here's where the zoom bounds gets calculated. The actual geofencing happens separately.
      const newZoomBoundsCalc = newZoomBounds(zoomBounds, [tiles.minZoom as number, tiles.maxZoom as number])
      // If the new zoom bounds are different from the old zoom bounds, update the zoom bounds atom
      if (!isEqual(zoomBounds, newZoomBoundsCalc)) {
        set(zoomBoundsAtom, newZoomBoundsCalc)
      }
    }
    // Otherwise there's nothing left to do:
    // (SUCCESS, NOT_APPLICABLE, NO_GEOMETRIES, TILING_ERROR, INDEX_NOT_FOUND, TIMEOUT)
  })
  useEffect(() => {
    updateBoundsState()
  }, [tLoad])

  return null
}

export default MapTilesConnect
