import { ProjectSearchParamsInput, SearchParamsInput, SearchSortEnum } from '../../schema/base'
import { PaginationFieldProps } from '../PaginationField'
import { SortOrderFieldProps } from '../SortOrderField'

export type CommonSearchProps = SortOrderFieldProps & PaginationFieldProps
export type CommonProjectSearchProps = SortOrderFieldProps
export type ObjectValues<T> = T[keyof T]

export enum DateWithinEnum {
  ONE_DAY = '1D',
  ONE_WEEK = '1W',
  ONE_MONTH = '1M',
  SIX_MONTHS = '6M',
}

export const SearchViewTypeEnum = {
  Map: 'map',
  List: 'list',
} as const
export type SearchViewTypeEnum = ObjectValues<typeof SearchViewTypeEnum>

type CommonSearchSpecParams = {
  createdWithin?: DateWithinEnum
}

export type ProjectSearchSpecParams = ProjectSearchParamsInput &
  CommonSearchSpecParams & {
    bounded?: boolean
  }

export const AccountVerifyReturnEnum = {
  SUCCESS: 'Success', // The code was found on the page
  NOT_VALID: 'NotValid', // The code was not found on the page
  USER_NOT_FOUND: 'UserNotFound', // The user was not found (404)
  UNKNOWN_ERROR: 'UnknownError', // any other error
} as const
export type AccountVerifyReturnEnum = ObjectValues<typeof AccountVerifyReturnEnum>

export type OtherSearchSpecParams = SearchParamsInput & CommonSearchSpecParams

export type SearchSpec = (
  | {
      type: 'Project'
      params: ProjectSearchSpecParams
    }
  | {
      type: 'Collection' | 'Organization' | 'User' | 'SavedSearch'
      params: OtherSearchSpecParams
    }
) & { page?: number; sort?: SearchSortEnum }

export type GeoParams = {
  lng: number
  lat: number
  zoom: number
}
