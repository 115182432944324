import { createSvgIcon } from '@mui/material'

/**
 * Note: This icon is in MUI 5 but we're not ready to upgrade yet so here it is
 * https://raw.githubusercontent.com/mui-org/material-ui/512896973499adbbda057e7f3685d1b23cc02de9/packages/mui-icons-material/material-icons/content_copy_24px.svg
 */
export default createSvgIcon(
  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />,
  'Multidot'
)
