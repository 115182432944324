/** @jsxImportSource @emotion/react */
import { uniqueId } from 'lodash'
import { useMemo } from 'react'
import { Layer, Source } from 'react-map-gl'
import { Bbox } from './useInteractiveMapController'

export interface BboxRectProps {
  bbox: Bbox
  color: string
}

export const BboxRect: React.FC<BboxRectProps> = ({ bbox, color }) => {
  const [minLng, minLat, maxLng, maxLat] = bbox

  const layerIdPrefix = useMemo(() => `bboxrect-${uniqueId()}`, [])

  return (
    <Source
      id={`${layerIdPrefix}-source`}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [
                [minLng, minLat],
                [minLng, maxLat],
                [maxLng, maxLat],
                [maxLng, minLat],
                [minLng, minLat],
              ],
            },
          },
        ],
      }}
    >
      <Layer
        id={`${layerIdPrefix}-line`}
        type="line"
        paint={{
          'line-color': color,
          'line-width': 2,
        }}
      />
    </Source>
  )
}
