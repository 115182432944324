import { useMemo } from 'react'

export const useAppVersion = () =>
  useMemo(() => {
    // It's helpful to know what version is in package.json.
    const versionContent = document.querySelector<HTMLMetaElement>('meta[name=version]')?.content
    const version = versionContent && !versionContent.startsWith('%') ? `v${versionContent}` : 'dev'
    // The git commit is for debugging
    const commitMeta = document.querySelector<HTMLMetaElement>('meta[name=commit]')?.content
    const commit = commitMeta && !commitMeta.startsWith('%') ? commitMeta : undefined

    // The stage helps us know what server we're on
    const stageMeta = document.querySelector<HTMLMetaElement>('meta[name=stage]')?.content
    const stage = stageMeta && !stageMeta.startsWith('%') ? stageMeta : undefined
    return { version, commit, stage }
  }, [])
