import { useCallback, useMemo, useRef } from 'react'

// hastily generated using https://medialab.github.io/iwanthue/
// H = 137-86; C = 40-100; L = 0-80; 25 colors
const palettes = {
  default: [
    '#301e76',
    '#581a5d',
    '#912f24',
    '#77329d',
    '#952b56',
    '#5d39c7',
    '#43529e',
    '#90428a',
    '#b83fac',
    '#a36528',
    '#5e6add',
    '#df3761',
    '#dc4b28',
    '#bd47e2',
    '#e546b4',
    '#da6a92',
    '#b078e2',
    '#6291df',
    '#e07560',
    '#48ae69',
    '#34b18f',
    '#d789d1',
    '#e0983b',
    '#43cc5f',
    '#48d8c9',
  ],
  references: ['#90e3df', '#dfdb83', '#ddc5ea', '#9be8a6', '#e6d2b3'],
}

type StableColorInstance = {
  colorsById: Record<string, string>
  nextColorIndex: number
}

export const useStableColor = (
  paletteName: 'default' | 'references' = 'default'
): [(id: string) => string, () => void] => {
  const initialState = {
    colorsById: {},
    nextColorIndex: 0,
  }

  const instance = useRef<StableColorInstance>(initialState).current

  const assignStableColor = useCallback(
    (id: string) => {
      const palette = palettes[paletteName]

      if (!instance.colorsById[id]) {
        instance.colorsById[id] = palette[instance.nextColorIndex++]
        if (instance.nextColorIndex === palette.length) instance.nextColorIndex = 0
      }
      return instance.colorsById[id]
    },
    [paletteName]
  )

  const resetStableColors = useCallback(() => {
    Object.assign(instance, initialState)
  }, [])

  return [assignStableColor, resetStableColors]
}

export const useDeterministicColor = (key: string): string => {
  const color = useMemo(() => {
    const value = key.split('').reduce((acc, char) => acc + (char.codePointAt(0) ?? 0), 0)
    return palettes.default[value % palettes.default.length]
  }, [key])
  return color
}
