/* eslint-disable react/prop-types */
import { Component } from 'react'
import { Box } from '@mui/material'
import { PageTitle } from '../PageTitle'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorState {
  hasError: boolean
  message: string
}

const noError = { hasError: false, message: '' }

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: ErrorState

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = noError

    window.addEventListener('hashchange', () => {
      if (this.state.hasError) this.setState(noError)
    })
    window.addEventListener('popstate', () => {
      if (this.state.hasError) this.setState(noError)
    })
  }

  static getDerivedStateFromError(error: Error): ErrorState {
    return { hasError: true, message: error.toString() }
  }

  // componentDidCatch(error, errorInfo) {
  // logErrorToMyService(error, errorInfo)
  // }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <>
          <PageTitle title={this.state.message} />
          <Box p={2}>
            <h1>An error has occurred.</h1>
            <div>{this.state.message}</div>
          </Box>
        </>
      )
    }

    return this.props.children
  }
}
