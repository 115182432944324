import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <React.Fragment>
    <circle vectorEffect="non-scaling-stroke" cx="4" cy="13" r="2" />
    <circle vectorEffect="non-scaling-stroke" cx="12" cy="9" r="2" />
    <circle vectorEffect="non-scaling-stroke" cx="20" cy="13" r="2" />
    <path d=" M 4.45 13.89 L 3.55 14.34 L 2.66 12.55 L 3.55 12.11 L 12 7.88 L 20.45 12.11 L 21.34 12.55 L 20.45 14.34 L 19.55 13.89 L 12 10.12 L 4.45 13.89 Z " />
  </React.Fragment>,
  'Polyline'
)
