/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { SxProps, TextField } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const DATE_FIELD_INVALID_VALUE = Symbol()

export type DateFieldValue = string | null | typeof DATE_FIELD_INVALID_VALUE

export type DateFieldProps = {
  endOfDay?: boolean
  error?: boolean
  label?: string
  onChange: (newValue: DateFieldValue) => void
  placeholder?: string
  sx?: SxProps
  value: DateFieldValue
}

export const getDateTimeOffsetDifference = (value: DateFieldValue): number | null => {
  if (value === null || value === DATE_FIELD_INVALID_VALUE) return null
  return dayjs(value).diff(dayjs(value.slice(0, 'YYYY-MM-DD'.length)), 'hour')
}

export const DateField: React.FC<DateFieldProps> = ({
  endOfDay,
  error: errorProp,
  label,
  onChange,
  placeholder,
  sx,
  value,
}) => {
  const valueAsDate =
    value && value !== DATE_FIELD_INVALID_VALUE
      ? dayjs(value)
          .subtract(endOfDay ? 1 : 0, 'day')
          .toDate()
      : null
  const error = errorProp || value === DATE_FIELD_INVALID_VALUE

  const handleChange = (newValue: Date | null) => {
    if (!newValue) {
      onChange(null)
      return
    }
    const newValueAsDate = dayjs(newValue).add(endOfDay ? 1 : 0, 'day')
    onChange(newValueAsDate.isValid() ? newValueAsDate.utc().format() : DATE_FIELD_INVALID_VALUE)
  }

  const [focused, setFocused] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={valueAsDate}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            sx={sx}
            {...params}
            error={error}
            label={label || (!focused && placeholder && !value ? placeholder : undefined)}
            onFocus={() => {
              setFocused(true)
            }}
            onBlur={() => {
              setFocused(false)
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
