import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <React.Fragment>
    <circle vectorEffect="non-scaling-stroke" cx="5.5" cy="5.5" r="2.5" />
    <circle vectorEffect="non-scaling-stroke" cx="5.5" cy="18.5" r="2.5" />
    <circle vectorEffect="non-scaling-stroke" cx="12" cy="12" r="2.5" />
    <circle vectorEffect="non-scaling-stroke" cx="18.5" cy="10" r="2.5" />
    <circle vectorEffect="non-scaling-stroke" cx="17.5" cy="16.5" r="2.5" />
  </React.Fragment>,
  'Multidot'
)
